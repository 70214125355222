const protocol = () => window && window.location && window.location.protocol;

interface EnvironmentConfig {
  ENV: string;
  POSTHOG_SEND_KEY: string;
  ANALYTICS_WRITE_KEY: string;
  RUM_ID?: string;
  RUM_ROLE?: string;
  RUM_IDENTITY_POOL?: string;
  RUM_REGION?: string;
  TAKTILE_API_SERVER_HOST: string;
  FLOW_API_SERVER_HOST: string;

  GITHUB_SHA?: string;
  NO_TILT?: string;
  BASE_URL?: string;
}

declare global {
  interface Window {
    environment: EnvironmentConfig;
  }
}

const environmentConfig: () => EnvironmentConfig = () => {
  return {
    ENV: window.environment.ENV,
    POSTHOG_SEND_KEY:
      window.environment.POSTHOG_SEND_KEY ||
      process.env.REACT_APP_POSTHOG_SEND_KEY ||
      "",
    ANALYTICS_WRITE_KEY:
      window.environment.ANALYTICS_WRITE_KEY ||
      process.env.REACT_APP_ANALYTICS_WRITE_KEY ||
      "",
    RUM_ID: window.environment.RUM_ID || process.env.REACT_APP_RUM_ID,
    RUM_ROLE: window.environment.RUM_ROLE || process.env.REACT_APP_RUM_ROLE,
    RUM_IDENTITY_POOL:
      window.environment.RUM_IDENTITY_POOL ||
      process.env.REACT_APP_RUM_IDENTITY_POOL,
    RUM_REGION:
      window.environment.RUM_REGION || process.env.REACT_APP_RUM_REGION,
    TAKTILE_API_SERVER_HOST:
      window.environment.TAKTILE_API_SERVER_HOST ||
      import.meta.env.REACT_APP_TAKTILE_API_SERVER_HOST ||
      process.env
        .REACT_APP_TAKTILE_API_SERVER_HOST /* needed by storybook's webpack dependancy */ ||
      "",
    FLOW_API_SERVER_HOST:
      window.environment.FLOW_API_SERVER_HOST ||
      import.meta.env.REACT_APP_FLOW_API_SERVER_HOST ||
      process.env
        .REACT_APP_FLOW_API_SERVER_HOST /* needed by storybook's webpack dependancy */ ||
      "",
    GITHUB_SHA: window.environment.GITHUB_SHA || process.env.GITHUB_SHA || "",
    NO_TILT:
      import.meta.env.REACT_APP_NO_TILT || process.env.REACT_APP_NO_TILT || "",
    BASE_URL: window.environment.BASE_URL,
  };
};

export const posthogSendKey = () => environmentConfig().POSTHOG_SEND_KEY;
export const analyticsWriteKey = () => environmentConfig().ANALYTICS_WRITE_KEY;
export const rumId = () => environmentConfig().RUM_ID;
export const rumRole = () => environmentConfig().RUM_ROLE;
export const rumIdentityPool = () => environmentConfig().RUM_IDENTITY_POOL;
export const githubSha = () => environmentConfig().GITHUB_SHA;
export const noTilt = () => environmentConfig().NO_TILT;
export const baseUrl = () => environmentConfig().BASE_URL;

export const taktileApiUrl = () => {
  const url = new URL(environmentConfig().TAKTILE_API_SERVER_HOST);
  if (url.hostname.match(/taktile-api-.+\.eu\.ngrok\.io/)) {
    // We assume only local development (with tilt) is using http instead of https
    url.protocol = protocol();
  }
  const urlHref = url.href;
  if (urlHref.endsWith("/")) {
    return urlHref.slice(0, -1);
  }
  return url.href;
};

export const flowApiUrl = () => {
  const url = new URL(environmentConfig().FLOW_API_SERVER_HOST);
  if (url.hostname.match(/flow-api-.+\.eu\.ngrok\.io/)) {
    // We assume only local development (with tilt) is using http instead of https
    url.protocol = protocol();
  }
  const urlHref = url.href;
  if (urlHref.endsWith("/")) {
    return urlHref.slice(0, -1);
  }
  return urlHref;
};
