import React, { Children, forwardRef } from "react";
import { twJoin } from "tailwind-merge";

/**
 * Design System Component: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/Design-System-v2?node-id=8927-13615&m=dev
 */

type PillButtonProps<T = unknown> = {
  children: React.ReactNode;
  value: T;
  onChange?: (value: T) => void;
  selected?: boolean;
  disabled?: boolean;
};

type PillToggleProps<T = unknown> = {
  value: T;
  onChange?: (value: T) => void;
  children: React.ReactElement<PillButtonProps<T>>[];
};

const PillButton = forwardRef<HTMLButtonElement, PillButtonProps>(
  ({ children, value, onChange, selected = false, disabled = false }, ref) => (
    <button
      ref={ref}
      className={twJoin(
        "font-inter-medium-12px first:rounded-l-3xl last:rounded-r-3xl",
        "border-y border-l border-r px-3 not-first:-ml-px",
        selected
          ? "z-10 border border-r-[0.5px] border-indigo-200 bg-indigo-50 text-indigo-600"
          : "border-gray-200 text-gray-600",
        disabled && "opacity-50",
      )}
      data-loc={`pill-toggle-${value}`}
      disabled={disabled}
      type="button"
      onClick={() => onChange?.(value)}
    >
      {children}
    </button>
  ),
);

/**
 * Displays stacked pill-buttons. Only one button is active at a time.
 * @component
 * @param {object} props
 *   @param {T} props.value The currently selected value.
 *   @param {(value: T) => void} props.onChange Callback for when the selected value changes.
 *   @param {React.ReactElement<PillButtonProps<T>>[]} props.children The buttons to render.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <PillToggle value="a" onChange={() => {}}>
 *   <PillToggle.Button value="a">A</PillToggle.Button>
 *   <PillToggle.Button value="b">B</PillToggle.Button>
 * </PillToggle>
 * ```
 */
export const PillToggle = <T,>({
  value,
  onChange,
  children,
}: PillToggleProps<T>) => {
  return (
    <div className="isolate flex" data-loc={`pill-toggle-selected-${value}`}>
      {Children.map(children, (child) =>
        React.cloneElement(child, {
          selected: value === child.props.value,
          onChange,
        }),
      )}
    </div>
  );
};

/**
 * Button of PillToggle component. Must be used inside PillToggle component.
 * @component
 * @param {object} props
 *   @param {T} props.value The value of the button.
 *   @param {React.ReactNode} props.children The content of the button.
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <PillToggle value="a" onChange={() => {}}>
 *   <PillToggle.Button value="a">A</PillToggle.Button>
 *   <PillToggle.Button value="b">B</PillToggle.Button>
 * </PillToggle>
 * ```
 */
PillToggle.Button = PillButton;
