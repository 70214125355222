import { ProviderT, ResourceT } from "src/api/connectApi/types";
import {
  DecisionHistoryIntegrationDuration,
  DecisionsOutcomeFilter,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  CreateDatasetFromHistory,
  EnumOptionsBET,
  GenericObjectT,
} from "src/api/flowTypes";
import { S3PreSignedUrlDataT } from "src/api/s3";
import {
  FlowMeta,
  FlowReviewConfiguration,
  TypingInfo,
  WorkspaceDataplane,
  WorkspaceSettings,
} from "src/clients/flow-api";
import { MLNodeMetadataT } from "src/constants/NodeDataTypes";
import { JSONValue } from "src/datasets/DatasetTable/types";

export type FieldErrorsT = { [id: string]: string };
export type FieldErrorsBET = {
  field_id: string;
  field_name: string;
  message: string;
}[];

export enum GraphRunResultType {
  SUCCESS = "success",
  ERROR = "error",
}

export type AuxDataEntryT = Record<string, any>;

export type DataRow<T = any> = Record<string, T>;

export type NodeExecutionMetadata = {
  [nodeID: string]: {
    variables?: {
      [key: string]: { value: JSONValue; type_: string | null | undefined };
    };
    accessed_fields?: string[];
    updated_fields?: string[];
    data_source?: "empty_response" | "mock_data" | "connect";
  } | null;
};

export type IndexedDataRow = {
  index: number;
  data: DataRow;
  aux_data: DataRow;
  node_execution_metadata?: NodeExecutionMetadata;
  /**
   * Only available for Flow nodes or Loop nodes. The data provided to the child flow.
   */
  flow_nodes_inputs?: DataRow | DataRow[];
  expected_output_data?: DataRow;
  expected_output_keys_mismatch?: string[];
};

export type ErrorBaseInfo = {
  msg: string | GenericObjectT | React.ReactNode;
  node_id?: string;
  status_code?: number;
  loc_type?: "field" | "line" | "node";
  loc?: string | number;
  field_errors?: FieldErrorsBET;
  integration_durations?: DecisionHistoryIntegrationDuration[];
  exc_type:
    | "parse"
    | "compile"
    | "runtime"
    | "runtime_http_error"
    | "data_integration_error";
};

export type DecisionHistoryError = ErrorBaseInfo & {
  data?: Record<any, any>;
  aux_data?: AuxDataEntryT;
  params: Record<any, any>;
};

export type ErroredRow = IndexedDataRow &
  ErrorBaseInfo & {
    error_hash: string;
    provenance?: Record<string, GenericObjectT>;
  };

export type IgnoredRow = IndexedDataRow &
  ErrorBaseInfo & {
    provenance?: Record<string, GenericObjectT>;
  };

export type ErroredRowGroup = {
  failure_count: number;
  example_failure: ErroredRow;
  error_hash: string;
};

export type NodeTestRunResult = {
  success_count: number;
  failure_count: number;
  ignored_count: number;
  errors_count: number;
  total_count: number;
  expected_output_mismatch_count?: number;
  data_columns: string[];
  aux_data_columns: string[];
  expected_output_columns?: string[];
  success_pages_urls: string[];
  failure_pages_urls: string[];
  ignored_pages_urls: string[];
  errors_pages_urls: string[];
  success_match_pages_urls: string[];
  success_mismatch_pages_urls: string[];
  node_id: string;
  version_id: string;
  test_run_id: string;
  typing: TypingInfo | null;
};

// As this type might diverge from the other uses of ErrorBaseInfo we already split it semantically
export type GraphLevelTestError = ErrorBaseInfo;

export type FlowRunnerResultV2 =
  | {
      type: GraphRunResultType.SUCCESS;
      nodes: { [nodeId: string]: NodeTestRunResult };
    }
  | {
      type: GraphRunResultType.ERROR;
      error: GraphLevelTestError;
      errors?: GraphLevelTestError[];
    };

export type FlowRunnerAsyncRunResultT = {
  run_id: string;
};

export type FlowAnalyzeParametersResultBET = { [nodeId: string]: string[] };

export enum DecisionEnvironment {
  LIVE = "live",
  SANDBOX = "sandbox",
}
export enum DecideDecisionStatus {
  SUCCESS = "success",
  ERROR = "error",
  PENDING = "pending",
}

type DecisionMetadata = {
  job_id?: string;
  job_run_id?: string;
  version?: string;
  entity_id?: string;
};

export type DecideDecisionSuccess<ResponseData extends GenericObjectT> = {
  data: ResponseData;
  status: DecideDecisionStatus.SUCCESS;
  metadata: DecisionMetadata;
  raw_provider_responses: unknown;
};
export type DecideDecisionError = {
  detail: ErrorBaseInfo;
  status: DecideDecisionStatus.ERROR;
  metadata: DecisionMetadata;
  raw_provider_responses: unknown;
};
/**
 * Use to check whether catched errors are DecideDecisionErrors
 */
export const isDecideDecisionError = (error: any) => {
  const castedError = error as DecideDecisionError;
  return (
    castedError.status === DecideDecisionStatus.ERROR &&
    typeof castedError.detail?.exc_type === "string" &&
    typeof castedError.detail?.msg === "string"
  );
};

type FlowAnalyzeErrorBET = {
  type: "error";
};

type FlowAnalyzeSuccessBET = {
  type: "success";
  params: FlowAnalyzeParametersResultBET;
};

export type FlowAnalyzeResultBET = FlowAnalyzeErrorBET | FlowAnalyzeSuccessBET;

export type FlowCreateT = {
  name: string;
  description: string;
  wsId: string;
  slug: string;
  flow_folder_id?: string | null;
  initial_version_description: string;
  initial_version_name: string;
  created_by_id: string;
  review_configuration?: FlowReviewConfiguration;
};

export type FlowUpdateT = {
  flow_folder_id?: string | null;
  flowId: string;
  name?: string;
  meta?: FlowMeta;
  default_version?: string;
  default_sandbox_version?: string;
  chart_order?: string[];
  review_configuration?: FlowReviewConfiguration;
  ui_default_decision_fields_exclude?: string[];
  ui_default_inputs_include?: string[];
  ui_default_outputs_include?: string[];
  ui_default_outcomes_include?: string[];
} & (
  | {
      // When moving the flow to a new folder, both flow_folder_id and old_folder_id are required
      flow_folder_id: string | null;
      old_folder_id: string | null;
    }
  | {
      flow_folder_id?: never;
      old_folder_id?: never;
    }
);

export type FlowVersionCreateT = {
  flowId: string;
  name: string;
  release_note: string;
  created_by_id: string;
};

export type FlowVersionDuplicateT = {
  flowVersionId: string;
  name: string;
  release_note: string;
  created_by_id: string;
  include_comments: boolean;
  targetFlowId?: string;
};

export type UploadModelDataT = {
  baseUrl: string;
  flowSlug: string;
  file: File;
};

export type UploadModelSignedUrlDataT = {
  model_id: string;
} & S3PreSignedUrlDataT;

export type ParsedModelDataPendingT = {
  type: "pending";
};

export type ParsedModelDataSuccessT = {
  type: "success";
  model_id: string;
  metadata: MLNodeMetadataT;
};

export type ParsedModelDataErrorT = {
  type: "error";
  model_id: string;
  error: { [key: string]: string };
};

export type ParsedModelDataBET = {
  result:
    | ParsedModelDataPendingT
    | ParsedModelDataSuccessT
    | ParsedModelDataErrorT;
};

export type SummaryBE = {
  decisions: number;
  errors: number;
  latency_p90: number;
};

export type MetricsSummaryResponse = {
  current_period: SummaryBE;
  previous_period: SummaryBE;
};

type MetricsDecisionBin = {
  bin: string;
  value: number;
  version_id: string;
};

export type MetricsDecisionsResponse = {
  by_version: MetricsDecisionBin[];
};

type MetricsBin = {
  bin: string;
  value: number;
};

type MetricsErrorBinWithCode = MetricsBin & {
  code: string;
};

export type MetricsErrorsResponse = {
  by_error: MetricsErrorBinWithCode[];
  error_rate: MetricsBin[];
};

type MetricsLatencyBin = MetricsBin & {
  version_id: string;
};

export type MetricsLatencyResponse = {
  by_version: MetricsLatencyBin[];
  total: MetricsBin[];
};

export type DecisionSummaryResponse = {
  groups: {
    flow?: {
      id: string;
    };
    count?: number;
  }[];
};
export const GLOBAL_FEATURES_TYPE = "features";

export const DesiredTypes = [
  "string",
  "boolean",
  "number",
  "integer",
  "object",
  "array",
  "datetime",
  "date",
  "any",
  "enum",
] as const;
export type DesiredType = (typeof DesiredTypes)[number];

export const DesiredTypeValues: Record<DesiredType, DesiredType> =
  DesiredTypes.reduce(
    (acc, type) => {
      acc[type] = type;
      return acc;
    },
    {} as Record<DesiredType, DesiredType>,
  );

export type DatasetColumn = {
  name: string;
  desired_type: DesiredType;
  use_subflow_mocks: boolean;
};

export type DatasetRow = {
  id: string;
} & DatasetRowData;

type DatasetRowData = {
  input_data: Record<string, unknown>;
  mock_data?: Record<string, unknown>;
  output_data?: Record<string, unknown>;
  outcome_data?: Record<string, unknown>;
};

export type DatasetRowDataGroup = keyof DatasetRowData;

export type DatasetPurpose = "test_run" | "job_source";

export type Dataset = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  flow_id: string;
  etag: string;
  source: "file" | "history" | "scratch";
  purpose: DatasetPurpose;
  created_by_id?: string;
  updated_by_id?: string;
  row_count?: number;
} & {
  [k in DatasetColumnGroups]: DatasetColumn[];
};

export type DatasetColumnGroups =
  | "input_columns"
  | "mock_columns"
  | "output_columns"
  | "outcome_columns";

export const DatasetColumnGroupValues: Record<
  DatasetColumnGroups,
  DatasetColumnGroups
> = {
  input_columns: "input_columns",
  mock_columns: "mock_columns",
  output_columns: "output_columns",
  outcome_columns: "outcome_columns",
} as const;

export type ExtendedDatasetColumnGroups =
  | DatasetColumnGroups
  | "additional_columns";

export const DatasetColumnGroupToRowDataGroupMap = {
  input_columns: "input_data",
  mock_columns: "mock_data",
  output_columns: "output_data",
  outcome_columns: "outcome_data",
} as const satisfies Record<DatasetColumnGroups, DatasetRowDataGroup>;

export const DatasetRowDataGroupToColumnGroupMap = {
  input_data: "input_columns",
  mock_data: "mock_columns",
  output_data: "output_columns",
  outcome_data: "outcome_columns",
} as const satisfies Record<DatasetRowDataGroup, DatasetColumnGroups>;

export type DatasetResponse = {
  dataset: Dataset;
  from_: number;
  size: number;
  total: number;
  rows: DatasetRow[];
};

export type DatasetPage = Pick<
  DatasetResponse,
  "from_" | "rows" | "size" | "total"
> & { fetchedAt: number };

export type DatasetFileUploadStatus = "PENDING" | "COMPLETED" | "FAILED";

export type DatasetFileUpload = {
  flow_id: string;
  dataset_id: string;
  file_name: string;
  created_by_id: string;
  s3_presigned_url: string;
  s3_presigned_fields: Record<string, string>;
  id: string;
  status: DatasetFileUploadStatus;
  error_metadata: string;
  created_at: string;
  updated_at: string;
};

export type DatasetFileFormat = "csv" | "json" | "xlsx";

type ReviewCaseDecision = {
  id: string;
  entity_id: Nullable<string>;
  start_time: string;
  is_sandbox: boolean;
};

type ReviewCaseFlowMetadata = {
  id: string;
  slug: string;
  version_id: string;
  version_name: string;
  version_etag: string;
};

export type ReviewCaseResponseSchemaProperty = { description: string } & (
  | {
      type: "integer" | "number" | "boolean";
    }
  | {
      type: "string";
      $comment?: string;
    }
  | { enum: EnumOptionsBET }
);

export type ReviewCaseResponseSchema = {
  $schema?: string;
  type: "object";
  properties: Record<string, ReviewCaseResponseSchemaProperty>;
  required: string[];
  order?: string[];
  description: string;
};

export type ReviewCaseResponseData = Record<
  string,
  string | number | boolean | undefined
>;

export type ReviewCaseResponse = {
  schema: ReviewCaseResponseSchema;
  data: Nullable<ReviewCaseResponseData>;
};

export type ReviewCaseStatusPending = "needs_review" | "in_progress";
export type ReviewCaseStatus = ReviewCaseStatusPending | "completed";

export type ReviewCaseHighlightsDivider = { type: "divider" };

export type ReviewCaseHighlightsDescription = {
  type: "description";
  value: string;
};

export type ReviewCaseHighlightsField = {
  type: "field";
  value: JSONValue;
  readable_name: string;
};

export type ReviewCaseHighlightsElement =
  | ReviewCaseHighlightsDivider
  | ReviewCaseHighlightsDescription
  | ReviewCaseHighlightsField;

export type ReviewCase = {
  id: string;
  number: number;
  created_at: string;
  status: ReviewCaseStatus;
  assignee: string | null;
  node_name: string;
  response: ReviewCaseResponse;
  flow: ReviewCaseFlowMetadata;
  decision: ReviewCaseDecision;
  highlights: ReviewCaseHighlightsElement[];
  connection_id: string;
  etag: string;
  updated_by: Nullable<string>;
  updated_at: string;
};

export type ReviewCaseExternalReport = {
  provider: ProviderT;
  resource: ResourceT;
  connection_name?: string;
  node_name: string;
  response: GenericObjectT;
};

export type ReviewCaseWithDecisionData = ReviewCase & {
  /* This data will be there eventually
   but it can be undefined if the
  decision processing is delayed */
  inspect_data?: GenericObjectT;
  external_data?: ReviewCaseExternalReport[];
};

export type ListReviewCasesResponse = {
  cases: ReviewCase[];

  page_min_timestamp: Nullable<string>;
  page_max_timestamp: Nullable<string>;
};

export type ReviewCasesAvailableFiltersResponse = {
  assignees: string[];
  node_names: string[];
  flow_versions: string[];
};

export type ReviewSummaryResponse = {
  open_count: number;
  open_and_assigned_to_me_count: number;
  in_progress_count: number;
  long_time_open_count: number;
  completed_recently_count: number;
};

type DatasetJobCommon = {
  id: string;
  created_at: string;
  updated_at: string;
  progress: number;
  created_by: string;
  status: "FAILED" | "COMPLETED" | "PENDING";
  flow_id: string;
};

export type DuplicateDatasetJobRequest = {
  source_dataset_id: string;
  new_dataset_name?: string;
  row_limit?: number;
};

export type DownloadDatasetJobRequest = {
  dataset_id: string;
  format: DatasetFileFormat;
};

export type EventUploadDatasetJobRequest = {
  event_type: string;
  environment: "live" | "sandbox";
  file_name: string;
};

export type HistoryExportDatasetJobRequest = {
  flow_slug: string;
  input_filters: {
    end_date?: string;
    entity_id?: string;
    flow_versions?: string[];
    is_sandbox?: boolean;
    job_id?: string;
    job_run_id?: string;
    start_date?: string;
    status_codes?: string[];
    traffic_policy_id?: string;
  };
  output_filters: {
    include_extra_fields: boolean;
    include_response_data: boolean;
    include_traffic_policy: boolean;
    include_request_data: boolean;
  };
  format: DatasetFileFormat;
};

export type ExportDecisionsDatasetJobRequest = {
  format: "csv" | "json";
  flow_slug: string;
  start_date: string;
  end_date: string;
  flow_versions: string[];
  status_codes: string[];
  traffic_policy_id?: string;
  sub_sampling_size?: number;
  outcome_filters?: DecisionsOutcomeFilter[];
};

export type CreateDuplicateDatasetJob = {
  flow_id: string;
  request: DuplicateDatasetJobRequest;
};
export type CreateDownloadDatasetJob = {
  flow_id: string;
  request: DownloadDatasetJobRequest;
};
export type CreateHistoryExportDatasetJob = {
  flow_id: string;
  request: HistoryExportDatasetJobRequest;
};

export type DuplicateDatasetJob = DatasetJobCommon & {
  type: "duplicate";
  request: DuplicateDatasetJobRequest;
  error?: { message: string };
};

export type AssembleDatasetJob = DatasetJobCommon & {
  type: "assemble";
  request: CreateDatasetFromHistory;
  error?: { message: string };
};

export type DownloadDatasetJob = DatasetJobCommon & {
  type: "download";
  request: DownloadDatasetJobRequest;
  error?: { message: string };
  response?: { s3_url: string };
};

export type ExportDecisionsDatasetJob = DatasetJobCommon & {
  type: "export_decisions";
  request: ExportDecisionsDatasetJobRequest;
  error?: { message: string };
  response?: { s3_url: string };
};

export type HistoryExportDatasetJob = DatasetJobCommon & {
  type: "history_export";
  request: HistoryExportDatasetJobRequest;
  error: { message: string };
  response?: { s3_url: string };
};
export type EventUploadDatasetJob = DatasetJobCommon & {
  type: "event_upload";
  request: EventUploadDatasetJobRequest;
  error?: { message: string };
  response?: {
    s3_presigned_url: string;
    s3_presigned_fields: Record<string, string>;
  };
};

export type DatasetJob =
  | DuplicateDatasetJob
  | AssembleDatasetJob
  | DownloadDatasetJob
  | HistoryExportDatasetJob
  | EventUploadDatasetJob;

// Make settings non-optional
export type WorkspaceWithSettings = Omit<WorkspaceDataplane, "settings"> & {
  settings: WorkspaceSettings;
};

export type OutcomeReport = {
  created_at: string;
  decision_id: string;
  entity_id: string;
  flow_id: string;
  id: string;
  outcome_type_id: string;
  outcome_type_key: string;
  payload: Record<string, any>;
};
