import { transformToTreeData } from "src/entities/entityView/DecisionFieldsTree";
import { Tree, TreeProps } from "src/entities/entityView/Tree";
import { useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type Props = Pick<TreeProps, "loading"> & {
  data: Record<string, unknown> | undefined;
};

export const DecisionDataTree: React.FC<Props> = ({ data, loading }) => {
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();
  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url ?? "",
    options: { enabled: featureGates.entitiesEnabled },
  });
  const { data: events } = useEventSchemas({
    enabled: featureGates.featuresEventsEnabled,
  });
  const treeData = transformToTreeData(
    data,
    entitySchemas?.entities ?? [],
    events ?? [],
  );
  return <Tree data={treeData} loading={loading} />;
};
