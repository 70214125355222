import React from "react";

import { Checkbox } from "src/base-components/Checkbox";
import { ApiKeyType } from "src/clients/taktile-api";

type Props = {
  value: Nullable<ApiKeyType>[];
  onChange: (value: Nullable<ApiKeyType>[]) => void;
};

const Label: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <label className="flex items-center gap-x-2 text-gray-700 font-inter-semibold-14px">
    {children}
  </label>
);

export const KeyTypeSelector: React.FC<Props> = ({ value, onChange }) => {
  const isChecked = (type: ApiKeyType) => value?.includes(type) ?? false;
  const handleChange = (type: ApiKeyType) => {
    if (isChecked(type)) {
      onChange(value.filter((t) => t !== type));
    } else {
      onChange([...value, type]);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label>
          <Checkbox
            checked={isChecked(ApiKeyType.SANDBOX)}
            name="sandbox"
            onChange={() => handleChange(ApiKeyType.SANDBOX)}
          />
          Sandbox decisions
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can make
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /run/.../sandbox/decide
          </span>
          calls to either draft or published flow versions, and run Jobs in the
          sandbox environment.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={isChecked(ApiKeyType.LIVE)}
            name="production"
            onChange={() => handleChange(ApiKeyType.LIVE)}
          />
          Live decisions
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can make
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /run/.../decide
          </span>
          calls to published flow versions, and run Jobs in the live
          environment.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={isChecked(ApiKeyType.JOB_ADMIN)}
            name="jobs"
            onChange={() => handleChange(ApiKeyType.JOB_ADMIN)}
          />
          Job management
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can manage Jobs and make calls to the{" "}
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /jobs
          </span>{" "}
          API. Note that in order to run Jobs, this API key must also have the
          sandbox or live decisions key permission.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={isChecked(ApiKeyType.HISTORY_ADMIN)}
            name="history"
            onChange={() => handleChange(ApiKeyType.HISTORY_ADMIN)}
          />
          Decision history management
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can manage decision history and make calls to the{" "}
          <span className="font-fira-code mx-1 rounded bg-gray-100 px-1">
            /history
          </span>{" "}
          API.
        </p>
      </div>

      <div>
        <Label>
          <Checkbox
            checked={isChecked(ApiKeyType.AUDIT_LOGS)}
            name="audit_logs"
            onChange={() => handleChange(ApiKeyType.AUDIT_LOGS)}
          />
          Audit Logs
        </Label>
        <p className="pl-5 text-gray-600 font-inter-normal-12px">
          Can access audit logs and system activity.
        </p>
      </div>
    </div>
  );
};
