import React from "react";

import {
  useWorkspaceFeatureGates,
  WorkspaceFeatureGates,
} from "src/hooks/useWorkspaceFeatureGates";

type FeatureGatedProps = {
  requiredGate: keyof WorkspaceFeatureGates;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const FeatureGated: React.FC<FeatureGatedProps> = ({
  requiredGate,
  children,
  fallback = null,
}) => {
  const featureGates = useWorkspaceFeatureGates();

  if (featureGates[requiredGate]) {
    return children;
  }

  return fallback;
};
