import React from "react";

import { Pill } from "src/base-components/Pill";
import { EnvironmentSelect } from "src/baseConnectionNode/EnvironmentSelect";
import {
  ConnectionDataSources,
  ConnectionDataSourcesValues,
} from "src/baseConnectionNode/types";

const ENV_DOCS_LINK = "https://docs.taktile.com/todo"; // TODO: INT-4993 add docs link

type AvailableSources =
  | ConnectionDataSourcesValues.mock_data
  | ConnectionDataSourcesValues.production;

const AI_OPTIONS_LABEL_MAP: Record<AvailableSources, string> = {
  [ConnectionDataSourcesValues.mock_data]: "Mock data",
  [ConnectionDataSourcesValues.production]: "Live AI response",
} as const;

const PRODUCTION_OPTION = {
  key: ConnectionDataSourcesValues.production,
  value: ConnectionDataSourcesValues.production,
} as const;

const ENVIRONMENT_OPTIONS: Array<{
  key: AvailableSources;
  value: AvailableSources;
}> = [
  {
    key: ConnectionDataSourcesValues.mock_data,
    value: ConnectionDataSourcesValues.mock_data,
  },
  PRODUCTION_OPTION,
];

const getEnvironmentOptionLabel = (value: AvailableSources | undefined) => {
  if (!value) return undefined;

  return AI_OPTIONS_LABEL_MAP[value];
};

type EnvironmentConfigPropsT = {
  disabled: boolean;
};

export const EnvironmentConfig: React.FC<EnvironmentConfigPropsT> = ({
  disabled,
}) => {
  const commonProps = {
    dataLocPrefix: "ai-node",
    docsLink: ENV_DOCS_LINK,
    getOptionLabel: getEnvironmentOptionLabel,
  };

  return (
    <div className="flex flex-col flex-wrap">
      <EnvironmentSelect
        description="Test runs using the button in the UI"
        disabled={disabled}
        environment={ConnectionDataSources.authoring_mode_data_source}
        options={ENVIRONMENT_OPTIONS}
        title="For test runs, use"
        {...commonProps}
      />
      <EnvironmentSelect
        description={
          <>
            API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/sandbox/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        disabled={disabled}
        environment={ConnectionDataSources.sandbox_mode_data_source}
        options={ENVIRONMENT_OPTIONS}
        title="For sandbox API calls, use"
        {...commonProps}
      />
      {/* The live setting remains the same no matter what */}
      <EnvironmentSelect
        defaultOption={PRODUCTION_OPTION["key"]}
        description={
          <>
            Production API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        environment={ConnectionDataSources.live}
        options={[PRODUCTION_OPTION]}
        title="For live API calls, use"
        tooltipTitle="For live API calls to the /decide endpoint, Taktile always uses production AI calls"
        disabled
        {...commonProps}
      />
    </div>
  );
};
