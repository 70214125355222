import { faRefresh, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useMemo } from "react";
import { twJoin } from "tailwind-merge";

import { useReviewCases, useReviewSummary } from "src/api/queries";
import { useWorkspaceUsers } from "src/api/taktile/queries";
import { EmptyState } from "src/design-system/EmptyState";
import { SubHeader } from "src/flow/SubHeader";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { maxTableWidth } from "src/layout/constants";
import { useReviewCasesRefresh } from "src/manualReview/ReviewCasesRefresh";
import { ReviewQueueTable } from "src/manualReview/ReviewQueueTable";
import { SummaryPane } from "src/manualReview/SummaryPane";
import { ReviewCasesOrdering } from "src/manualReview/types";
import { useReviewQueueFilters } from "src/manualReview/useReviewFilters";
import {
  convertFiltersToParams,
  isDefaultFiltersState,
} from "src/manualReview/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { formatDate } from "src/utils/datetime";

export const ReviewQueueContent: React.FC = () => {
  const { workspace, flow, orgId } = useFlowContext();
  const { entitiesEnabled } = useWorkspaceFeatureGates();
  const {
    refresh: refreshCases,
    refreshing: isRefreshingCases,
    dataUpdatedAt,
  } = useReviewCasesRefresh();

  const [filters, setFilter] = useReviewQueueFilters();

  const users = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });
  const cases = useReviewCases(
    workspace.base_url,
    flow.slug,
    convertFiltersToParams(filters),
  );
  const summary = useReviewSummary(
    workspace.base_url,
    flow.slug,
    filters.environment,
  );

  // We memoize the these values to avoid re-renders of cases table
  // tanstack remounts rows on every render, so we lose open dropdowns state
  const rows = useMemo(() => {
    return cases.data?.pages.flatMap((page) => page.cases) ?? [];
  }, [cases.data?.pages]);
  const onChangeOrder = useCallback(
    (order: ReviewCasesOrdering) => setFilter({ order }),
    [setFilter],
  );

  const areFiltersDefault = isDefaultFiltersState(filters);
  const isDataEmpty = cases.isFetched && users.isFetched && rows.length === 0;

  const handleSearchChange = useCallback(
    (value: string) => {
      setFilter({ entityOrDecisionId: value });
    },
    [setFilter],
  );

  return (
    <>
      <SubHeader
        title="Review queue"
        titleAction={
          <SubHeader.LiveSandboxSwitch
            value={filters.environment}
            onChange={(environment) => setFilter({ environment })}
          />
        }
        paddedParent
      >
        <SubHeader.SearchBox
          dataLoc="manual-review-search-box"
          defaultValue={filters.entityOrDecisionId}
          placeholder={
            entitiesEnabled
              ? "Search by Decision ID ..."
              : "Search by Decision ID or Entity ID ..."
          }
          onChange={handleSearchChange}
        />
        <SubHeader.DatePicker
          placeholder="All time"
          value={filters.dateRange}
          onChange={(range) => setFilter({ dateRange: range })}
        />
        <SubHeader.Button
          disabled={isRefreshingCases}
          icon={faRefresh}
          spin={isRefreshingCases}
          tooltip={
            dataUpdatedAt
              ? `Last updated at ${formatDate(dataUpdatedAt, "h:mm aaa")}`
              : "Refetch"
          }
          onClick={refreshCases}
        />
      </SubHeader>

      {/*Uses the fixed height of the subheader component */}
      <div
        className={twJoin(
          "mx-auto flex h-[calc(100%-56px)] w-full flex-col",
          maxTableWidth,
        )}
      >
        <SummaryPane summary={summary.data} />
        <div
          className="relative flex min-h-[30rem] flex-col justify-stretch rounded-lg border border-gray-200 bg-white px-4 pt-2"
          data-loc="manual-review-content"
        >
          <ReviewQueueTable
            canFetchNextPage={Boolean(!cases.isFetching && cases.hasNextPage)}
            cases={rows}
            fetchNextPage={cases.fetchNextPage}
            filters={filters}
            hidden={areFiltersDefault && isDataEmpty}
            isFetchingNextPage={cases.isFetchingNextPage}
            isLoading={cases.isLoading || users.isLoading}
            users={users.data}
            onChangeFilter={setFilter}
            onChangeOrder={onChangeOrder}
          />

          {areFiltersDefault && isDataEmpty && (
            <div className="flex flex-1 items-center justify-center">
              <EmptyState
                description="It seems no items need manual review at the moment. "
                headline="The review queue is empty"
                icon={faUserCircle}
              />
            </div>
          )}
          {!areFiltersDefault && isDataEmpty && (
            <div className="flex flex-1 items-center justify-center">
              <EmptyState
                description="Change your column filters or search for a different ID"
                headline="No results found"
                icon={faUserCircle}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
