import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { CustomInvokeCodeEditor } from "src/aiNode/CustomInvokeCodeEditor";
import { AiNodeV2Form } from "src/aiNode/types";
import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { FormItem } from "src/base-components/FormItem";
import { Switch } from "src/base-components/Switch";
import {
  AiNodeV2ConversationMessageFilesListContentBlock,
  AiNodeV2ConversationMessageTextContentBlock,
} from "src/clients/flow-api";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

type PropsT = {
  immutable: boolean;
  isReactive: boolean;
  runFieldErrors?: FieldErrorsT;
};

const FilesListInput: React.FC<{
  immutable: boolean;
  runFieldErrors?: FieldErrorsT;
}> = ({ immutable, runFieldErrors }) => {
  const { control, watch, getValues } = useFormContext<AiNodeV2Form>();

  const content = getValues("prompts.messages.0.content");
  const filesListContentIndex = content.findIndex(
    (c) => c.block_type === "files_list",
  );

  if (filesListContentIndex === -1) {
    // We do a backfill on the BE data to make sure this field is always present.
    // So if for some reason it's not present, we don't show the UI to avoid errors.
    return null;
  }

  const filesListEnabled = watch(
    `prompts.messages.0.content.${filesListContentIndex}.files.active`,
  );

  const filesListContent = content[
    filesListContentIndex
  ] as AiNodeV2ConversationMessageFilesListContentBlock;
  const filesListErrorMessage = runFieldErrors?.[filesListContent.files.id];

  return (
    <div className="space-y-2" data-loc="ai-node-prompt-files-list">
      <div className="items-top flex justify-between gap-x-2">
        <div>
          <h3 className="text-gray-800 font-inter-semibold-13px">
            Include files
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Include a list of file IDs to be sent to the AI
          </span>
        </div>
        <div>
          <Controller
            control={control}
            name={
              `prompts.messages.0.content.${filesListContentIndex}.files.active` as const
            }
            render={({ field: controlledField }) => (
              <Switch
                dataLoc="ai-node-prompt-files-list-toggle"
                disabled={immutable}
                enabled={controlledField.value}
                onChange={() => {
                  controlledField.onChange(!controlledField.value);
                }}
              />
            )}
          />
        </div>
      </div>
      {filesListEnabled && (
        <div>
          <Controller
            control={control}
            name={
              `prompts.messages.0.content.${filesListContentIndex}.files.expression` as const
            }
            render={({ field }) => (
              <AutocompleteCodeInput
                dataLoc={`ai-node-prompt-files-list-${filesListContentIndex}`}
                disabled={immutable}
                error={filesListErrorMessage}
                placeholder="data.file_ids"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export const PromptEditorV2: React.FC<PropsT> = ({
  immutable,
  isReactive,
  runFieldErrors,
}) => {
  const { control, getValues } = useFormContext<AiNodeV2Form>();

  const content = getValues("prompts.messages.0.content");

  const textContentIndex = content.findIndex((c) => c.block_type === "text");
  if (textContentIndex === -1) {
    throw new Error("No text content block found");
  }

  const textContent = content[
    textContentIndex
  ] as AiNodeV2ConversationMessageTextContentBlock;
  const textErrorMessage = runFieldErrors?.[textContent.text.id];

  return (
    <Card
      className={twJoin(
        "w-full pb-4 pt-4",
        textErrorMessage && "border-red-400",
      )}
    >
      <FormItem>
        <FormItem.Label
          className="mb-2"
          description="Describe the task you want the AI to perform. Use '$' to include Flow data (e.g.
            '$data.credit_report') from your Decision Flow."
          isRequired
        >
          Message
        </FormItem.Label>
        <Controller
          control={control}
          name={
            `prompts.messages.0.content.${textContentIndex}.text.expression` as const
          }
          render={({ field }) => (
            <div className="h-80">
              <CustomInvokeCodeEditor
                errorMessage={textErrorMessage}
                immutable={immutable}
                isReactive={isReactive}
                placeholder="e.g. Extract the `credit_score` and `incorporation_date` from this credit report: $data.credit_report"
                value={field.value}
                onChange={field.onChange}
              />
            </div>
          )}
        />
      </FormItem>
      {isFeatureFlagEnabled(FEATURE_FLAGS.aiNodeFilesList) && (
        <FilesListInput immutable={immutable} runFieldErrors={runFieldErrors} />
      )}
    </Card>
  );
};
