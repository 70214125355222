import { faCog } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Control, Controller, useFormContext, useWatch } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Switch } from "src/base-components/Switch";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useOpenOnHover } from "src/utils/useOpenOnHover";

type CommonSchemaEditRowPropsT = {
  name: `response_form.fields.${number}`;
  control: Control<ManualReviewFormT, "response_form.fields">;
  disabled: boolean;
};

export const RowAdvancedOptions: React.FC<CommonSchemaEditRowPropsT> = ({
  name,
  control,
  disabled,
}) => {
  const hoverProps = useOpenOnHover<HTMLDivElement, HTMLDivElement>(50);
  const fieldType = useWatch({ control, name: `${name}.type` });

  // No need to be reactive, we need this just for diff-view
  const form = useFormContext<ManualReviewFormT>();
  const displayAs = form.getValues(`${name}.display_as`);
  const required = form.getValues(`${name}.required`);

  return (
    <div
      className="relative"
      data-loc={`mr-advanced-options-${required}-${displayAs}`}
    >
      <div
        className={twJoin(!disabled && "cursor-pointer")}
        onMouseEnter={hoverProps.onMouseEnter}
        onMouseLeave={hoverProps.onMouseLeave}
      >
        <Icon
          color="text-gray-400 hover:text-gray-700"
          dataLoc="manual-review-advanced-trigger"
          icon={faCog}
          size="sm"
        />
      </div>
      {hoverProps.isOpen && (
        <div
          className="absolute -right-3 z-50 flex w-44 flex-col gap-y-2 rounded-lg bg-white px-3 py-4 text-gray-800 shadow-lg font-inter-normal-13px"
          data-loc="manual-review-advanced-popup"
          onMouseEnter={hoverProps.onMouseEnter}
          onMouseLeave={hoverProps.onMouseLeave}
        >
          <div className="flex items-center justify-between">
            <span>Required</span>
            <Controller
              control={control}
              name={`${name}.required`}
              render={(props) => (
                <Switch
                  dataLoc={`manual-review-required-${String(props.field.value)}`}
                  disabled={disabled}
                  enabled={!!props.field.value}
                  onChange={() => {
                    props.field.onChange(!props.field.value);
                  }}
                />
              )}
            />
          </div>
          {isFeatureFlagEnabled(FEATURE_FLAGS.manualReviewDisplayAsTextarea) &&
            fieldType === "string" && (
              <div className="flex items-center justify-between">
                <span>As Text Area</span>
                <Controller
                  control={control}
                  name={`${name}.display_as`}
                  render={(props) => (
                    <Switch
                      dataLoc={`manual-review-display-as-${String(props.field.value)}`}
                      disabled={disabled}
                      enabled={props.field.value === "textarea"}
                      onChange={() => {
                        props.field.onChange(
                          props.field.value === "textarea"
                            ? "input"
                            : "textarea",
                        );
                      }}
                    />
                  )}
                  rules={{
                    shouldUnregister: true,
                  }}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};
