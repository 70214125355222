import {
  faPlus,
  faTrashAlt,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import React, { useMemo } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

import {
  PropertyTypeUIT,
  PropertyValueT,
  SchemaT,
  SchemaTypesBE,
  SchemaTypesT,
} from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Select } from "src/base-components/Select";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { OutcomeTypeCreate } from "src/clients/flow-api";
import { EmptyState } from "src/design-system/EmptyState";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import {
  useCreateOutcomeType,
  useUpdateOutcomeType,
} from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import { SchemaOptions } from "src/router/SearchParams";
import { useFlowContext } from "src/router/routerContextHooks";
import { SchemaEnumRows } from "src/schema/SchemaEnumRows";
import { defaultSchema, SchemaConverter } from "src/schema/schemaMappingUtils";
import { logger } from "src/utils/logger";

type OutcomeProperty = {
  // Need id for mapping to filtarable fields, we remove them before sending to backend
  id: string;
  fieldName: string;
  type: PropertyTypeUIT[0];
  enum?: { value: string }[];
  required: boolean;
  sensitive: boolean;
};

type OutcomeTypeForm = Pick<OutcomeType, "name" | "key"> & {
  filterable_properties: string[];
  properties: OutcomeProperty[];
};

export const ManageOutcomeTypeModal: React.FC<{
  afterLeave: () => void;
  isOpen: boolean;
  outcomeType: OutcomeType | undefined;
  onClose: () => void;
}> = ({ afterLeave, isOpen, outcomeType, onClose }) => {
  const isEditing = !!outcomeType;

  const isDisabled =
    outcomeType && outcomeType.count ? outcomeType.count > 0 : false;

  const { flow } = useFlowContext();
  const defaultProperty = getDefaultField();
  const defaultValues = {
    name: "",
    key: "",
    properties: [defaultProperty],
    filterable_properties: [defaultProperty.id],
  };
  const outcomeTypeForm = useMemo(() => {
    return outcomeType
      ? mapOutcomeTypeToOutcomeTypeForm(outcomeType)
      : undefined;
  }, [outcomeType]);
  const formMethods = useForm<OutcomeTypeForm>({
    values: outcomeTypeForm,
    defaultValues,
  });
  const { register, handleSubmit, setValue, reset } = formMethods;

  const { mutateAsync: createOutcomeType } = useCreateOutcomeType();
  const { mutateAsync: updateOutcomeType } = useUpdateOutcomeType();

  const onSubmit = async (data: OutcomeTypeForm) => {
    try {
      const formData = mapOutcomeTypeFormToOutcomeType(flow.id, data);
      if (isEditing) {
        const { flow_id, key: _key, name, payload_schema } = formData;
        await updateOutcomeType({
          id: outcomeType.id,
          flowId: flow_id,
          name,
          payload_schema: isDisabled ? undefined : payload_schema,
        });
      } else {
        await createOutcomeType(formData);
      }
      onClose();
    } catch (e) {
      toastActions.failure({
        title: `Failed to ${isEditing ? "edit" : "create"} Outcome`,
        description:
          axios.isAxiosError(e) && e.status === 409
            ? "This key is already used for this Flow in another Outcome, please try with a different one"
            : TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  return (
    <Modal
      afterLeave={() => {
        afterLeave();
        reset(defaultValues);
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {isEditing ? "Edit Outcome" : "Create Outcome"}
      </Modal.Header>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <div className="flex flex-col gap-y-5">
              <div>
                <Label required>Name</Label>
                <Input
                  data-loc="outcome-modal-name-input"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                    onChange: (e) => {
                      const { isDirty } = formMethods.getFieldState("key");

                      if (!isDirty && !isEditing) {
                        setValue(
                          "key",
                          slugify(e.target.value, {
                            lower: true,
                            strict: true,
                            replacement: "_",
                          }),
                          {
                            shouldValidate: true,
                          },
                        );
                      }
                    },
                  })}
                  errored={!!formMethods.formState.errors.name}
                  placeholder="Enter name"
                  fullWidth
                />
                {formMethods.formState.errors.name && (
                  <ErrorHint>
                    {formMethods.formState.errors.name.message}
                  </ErrorHint>
                )}
              </div>
              <div>
                <Label required>Key</Label>
                <Input
                  data-loc="outcome-modal-key-input"
                  {...register("key", KEY_VALIDATIONS)}
                  disabled={isDisabled || isEditing}
                  errored={!!formMethods.formState.errors.key}
                  placeholder="Enter key"
                  fullWidth
                />
                {formMethods.formState.errors.key && (
                  <ErrorHint>
                    {formMethods.formState.errors.key.message}
                  </ErrorHint>
                )}
              </div>
              <div>
                <Label required>Fields</Label>
                <PropertiesForm immutable={isDisabled} />
              </div>
              <div>
                <Label mb="mb-1" required>
                  Filterable fields
                </Label>
                <div className="mb-2 text-gray-500 font-inter-normal-12px">
                  You can filter Outcomes by these fields in decision history,
                  and historical dataset creation.
                </div>
                <FilterableFieldsSelect immutable={isDisabled} />
                {formMethods.formState.errors.filterable_properties && (
                  <ErrorHint>
                    {formMethods.formState.errors.filterable_properties.message}
                  </ErrorHint>
                )}
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="outcome-modal-save-button"
                disabled={formMethods.formState.isSubmitting}
                htmlType="submit"
                loading={formMethods.formState.isSubmitting}
              >
                Save Outcome
              </Button>
            }
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

const OutcomeSchemaTypes = {
  ...SchemaTypesBE,
  datetime: "datetime",
  date: "date",
  enum: "enum",
} as const;

const OUTPUT_SCHEMA_TYPES_OPTIONS = Object.entries(OutcomeSchemaTypes).map(
  ([key, value]) => ({
    key: key as SchemaTypesT,
    value: (
      <div className="flex items-center gap-x-1">
        <Icon color="text-gray-500" icon={SCHEMA_TYPE_ICONS[value]} size="xs" />
        {value}
      </div>
    ),
  }),
);

const FilterableFieldsSelect: React.FC<{ immutable: boolean }> = ({
  immutable,
}) => {
  const { formState } = useFormContext<OutcomeTypeForm>();
  const properties = useWatch<OutcomeTypeForm, "properties">({
    name: "properties",
    defaultValue: [],
  });

  const options = properties
    .filter((p) => p.fieldName)
    .map((p) => ({
      key: p.id,
      value: p.fieldName,
      disabled: !isScalarType(p.type)
        ? "Object and array types are not filterable"
        : false,
    }));

  const filterableOptions = options.filter((p) => !p.disabled);
  const getAvailableValues = (value: string[]) => {
    return filterableOptions
      .map((p) => p.key)
      .filter((key) => value.includes(key));
  };

  return (
    <Controller
      name="filterable_properties"
      render={({ field }) => (
        <Select
          dataLoc="outcome-modal-filterable-fields-select"
          disabled={immutable}
          dropdownPlaceholder={
            <EmptyState
              description="Add fields to make this Outcome filterable"
              headline="No filterable fields"
              icon={faWarning}
            />
          }
          errored={!!formState.errors.filterable_properties}
          options={options}
          placeholder="Choose filterable fields"
          value={getAvailableValues(field.value)}
          multiple
          onChange={field.onChange}
        />
      )}
      rules={{
        validate: (value) => {
          const actualValues = getAvailableValues(value);

          if (actualValues.length > 3) {
            return "You cannot have more than 3 filterable fields per Outcome.";
          }

          return true;
        },
      }}
    />
  );
};

const PropertiesForm: React.FC<{ immutable: boolean }> = ({ immutable }) => {
  const { fields, append, remove } = useFieldArray<OutcomeTypeForm>({
    name: "properties",
    keyName: "uniqKey" as "id",
  });

  const {
    register,
    setValue,
    watch,
    formState,
    getFieldState,
    getValues,
    control,
  } = useFormContext<OutcomeTypeForm>();

  return (
    <div
      className="flex flex-col gap-y-2 rounded-lg border border-gray-200 bg-gray-50 p-4"
      data-loc="outcome-modal-properties-form"
    >
      <div className="flex gap-y-2 text-gray-500 font-inter-normal-12px">
        <div className="w-3/6">Field name</div>
        <div className="w-3/6">Type</div>
      </div>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div className="flex items-center gap-x-2">
            <div className="w-3/6 flex-1">
              <Input
                data-loc={`outcome-modal-field-name-${index}-input`}
                disabled={immutable}
                fullWidth
                {...register(`properties.${index}.fieldName`, {
                  required: {
                    value: true,
                    message: "Field name is required",
                  },
                  validate: (value, formValues) => {
                    const names = formValues.properties
                      .filter((f) => f.id !== field.id)
                      .map((f) => f.fieldName);

                    if (names.includes(value)) {
                      return "Field name must be unique";
                    }

                    if (!/^[a-zA-Z0-9_-]*$/.test(value)) {
                      return "Field names can only contain letters, numbers, underscore and dash";
                    }
                    if (!/^[a-zA-Z_].*$/.test(value)) {
                      return "Field names must start with a letter or underscore";
                    }

                    return true;
                  },
                })}
                errored={!!formState.errors.properties?.[index]?.fieldName}
                placeholder="Enter field name"
              />
            </div>
            <div className="flex w-3/6 items-center gap-x-2">
              <Controller
                control={control}
                name={`properties.${index}.type`}
                render={({ field }) => (
                  <Select
                    dataLoc={`outcome-modal-field-type-${index}-select`}
                    disabled={immutable}
                    options={OUTPUT_SCHEMA_TYPES_OPTIONS}
                    value={field.value}
                    fullWidth
                    onChange={(value) => {
                      if (value === "enum") {
                        setValue(`properties.${index}.enum`, [
                          { value: '"value_1"' },
                        ]);
                      } else {
                        setValue(`properties.${index}.enum`, undefined);
                      }
                      field.onChange(value);
                    }}
                  />
                )}
                rules={{
                  deps: ["filterable_properties"],
                }}
              />
              <Icon
                color={
                  !immutable && fields.length > 1
                    ? "text-gray-500"
                    : "text-gray-300"
                }
                dataLoc={`outcome-modal-remove-field-${index}-button`}
                disabled={fields.length <= 1 || immutable}
                icon={faTrashAlt}
                size="xs"
                onClick={() => remove(index)}
              />
            </div>
          </div>
          {formState.errors.properties?.[index]?.fieldName && (
            <ErrorHint>
              {formState.errors.properties[index]?.fieldName?.message}
            </ErrorHint>
          )}
          {watch(`properties.${index}.type`) === "enum" && (
            <SchemaEnumRows
              enumFormLocation={`properties.${index}.enum`}
              errorStatus={formState.errors.properties?.[index]?.enum}
              immutable={immutable}
              withValidation
            />
          )}
        </div>
      ))}
      <div>
        <Button
          dataLoc="outcome-modal-add-field-button"
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => {
            const filterablePropertiesState = getFieldState(
              "filterable_properties",
            );
            const filterableProperties = getValues("filterable_properties");

            const newProperty = getDefaultField();

            if (!filterablePropertiesState.isDirty) {
              setValue("filterable_properties", [
                ...filterableProperties,
                newProperty.id,
              ]);
            }

            append(newProperty);
          }}
        >
          Add field
        </Button>
      </div>
    </div>
  );
};

const isScalarType = (type: SchemaTypesT) => {
  return [
    "string",
    "number",
    "boolean",
    "integer",
    "datetime",
    "date",
    "enum",
  ].includes(type);
};

const mapOutcomeTypeFormToOutcomeType = (
  flowId: string,
  form: OutcomeTypeForm,
): OutcomeTypeCreate => {
  const filterableProperties = form.properties
    .filter(
      (p) => form.filterable_properties.includes(p.id) && isScalarType(p.type),
    )
    .map((p) => p.fieldName);

  const payloadSchema = SchemaConverter.uiToBE(
    {
      $schema: defaultSchema.$schema,
      properties: form.properties.map((p) => ({
        ...p,
        type: [p.type, false],
      })),
      type: "object",
    },
    // Mimic an output
    SchemaOptions.Output,
  );

  return {
    flow_id: flowId,
    name: form.name,
    key: form.key,
    payload_schema: {
      ...payloadSchema,
      properties: Object.fromEntries(
        Object.entries(payloadSchema.properties).map(([key, value]) => [
          key,
          {
            ...value,
            filterable: filterableProperties.includes(key),
          },
        ]),
      ),
    },
  };
};

/**
 * Extended PropertyValueT with filterable flag
 */
type PropertyValue = PropertyValueT & { filterable: boolean };

const mapOutcomeTypeToOutcomeTypeForm = (
  outcomeType: OutcomeType,
): OutcomeTypeForm => {
  const payloadSchema = outcomeType.payload_schema as SchemaT<PropertyValue>;
  const uiSchema = SchemaConverter.beToUI(payloadSchema, SchemaOptions.Output);
  const properties = uiSchema.properties.map((p) => ({
    ...p,
    id: uuidv4(),
    type: p.type[0],
  }));

  return {
    name: outcomeType.name,
    key: outcomeType.key,
    properties,
    filterable_properties: properties
      .filter((p) => payloadSchema.properties[p.fieldName].filterable)
      .map((p) => p.id),
  };
};

const getDefaultField = (): OutcomeProperty => ({
  id: uuidv4(),
  fieldName: "",
  type: OUTPUT_SCHEMA_TYPES_OPTIONS[0].key,
  required: true,
  sensitive: false,
});

const KEY_VALIDATIONS = {
  required: {
    value: true,
    message: "Key is required",
  },
  minLength: {
    value: 4,
    message: "Key has to be between 4 and 20 characters long",
  },
  maxLength: {
    value: 20,
    message: "Key has to be between 1 and 20 characters long",
  },
  pattern: {
    value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
    message:
      "Key must start with a letter or underscore, and contain only letters, numbers, or underscores",
  },
};
