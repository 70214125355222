import RelationArrow from "src/assets/RelationArrow.svg?react";
import { Pill } from "src/base-components/Pill";
import { findSchema, getSchemaIcon } from "src/entities/entityView/utils";
import { Cardinality, useEntitySchemas } from "src/entities/queries";
import { RelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type RelationshipProps = {
  relatedSchemaId: string;
  schemaId?: string;
  pluralDisplayName: string;
  propertyDisplayName: string;
  singularDisplayName: string;
  cardinality?: Cardinality;
};

export const RelationshipPopoverContent: React.FC<RelationshipProps> = ({
  schemaId,
  relatedSchemaId,
  singularDisplayName,
  pluralDisplayName,
  propertyDisplayName,
  cardinality = Cardinality.ONE,
}) => {
  const { workspace } = useWorkspaceContext();
  const { data: schemas } = useEntitySchemas({
    baseUrl: workspace.base_url ?? "",
    options: {
      enabled: !!workspace.base_url && !!relatedSchemaId,
    },
  });
  const relatedSchema = findSchema(relatedSchemaId ?? "", schemas);
  const schema = findSchema(schemaId ?? "", schemas);
  const relatedPluralDisplayName = relatedSchema?._display_name_plural;
  const relatedSingularDisplayName = relatedSchema?._display_name_singular;

  const [
    captializedSingular,
    capitalizedPlural,
    capitalizedRelatedPlural,
    capitalizedRelatedSingular,
  ] = [
    <span className="capitalize">{singularDisplayName}</span>,
    <span className="capitalize">{pluralDisplayName}</span>,
    <span className="capitalize">{relatedPluralDisplayName}</span>,
    <span className="capitalize">{relatedSingularDisplayName}</span>,
  ];

  return (
    <>
      <div className="px-4 py-2.5">
        <div className="mb-2 text-gray-800 font-inter-semibold-13px">
          Relationship
        </div>
        <div className="text-gray-600 font-inter-normal-12px">
          {cardinality === Cardinality.ONE ? (
            <p>
              Each {captializedSingular} can be associated to one{" "}
              {capitalizedRelatedSingular}.
            </p>
          ) : (
            <p>
              One {captializedSingular} can be associated to multiple{" "}
              {capitalizedRelatedPlural}.
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-between px-4">
        <Pill size="sm" variant="gray">
          {schema && <Pill.Icon icon={getSchemaIcon(schema)} />}
          <Pill.Text>{capitalizedPlural}</Pill.Text>
        </Pill>
        <RelationIcon
          cardinality={cardinality}
          color="text-gray-400"
          size="xs"
        />
        <Pill size="sm" variant="gray">
          {relatedSchema && <Pill.Icon icon={getSchemaIcon(relatedSchema)} />}
          <Pill.Text>{capitalizedRelatedPlural}</Pill.Text>
        </Pill>
      </div>
      <div className="px-4 py-2">
        <div className="rounded-lg border border-gray-100 p-3 text-center">
          <span className="text-gray-600 font-inter-medium-11px">
            {propertyDisplayName}
          </span>
          <RelationArrow className="m-auto my-2" />
          <div className="flex justify-between">
            <Pill size="sm" variant="gray">
              {schema && <Pill.Icon icon={getSchemaIcon(schema)} />}
              <Pill.Text>{capitalizedPlural}</Pill.Text>
            </Pill>
            <Pill size="sm" variant="gray">
              {relatedSchema && (
                <Pill.Icon icon={getSchemaIcon(relatedSchema)} />
              )}
              <Pill.Text>{capitalizedRelatedPlural}</Pill.Text>
            </Pill>
          </div>
        </div>
      </div>
    </>
  );
};
