import { isObject } from "lodash";

import { TypingInfo } from "src/clients/flow-api/api";
import { TAKTILE_TYPE } from "src/constants/InternalDataKeys";

export const isTaktileObject = (
  value: unknown,
): value is { [TAKTILE_TYPE]: string } => {
  return isObject(value) && !Array.isArray(value) && TAKTILE_TYPE in value;
};

export const EntityTypePrefix = "Entities@" as const;
export const EventTypePrefix = "Events@" as const;

export const decodeTypeToEntityEventId = (
  typeHint: unknown,
): {
  metaType: "entity" | "event";
  configSchemaId: string;
} | null => {
  if (typeof typeHint !== "string") {
    return null;
  }
  if (typeHint.startsWith(EntityTypePrefix)) {
    return {
      metaType: "entity",
      configSchemaId: typeHint.slice(EntityTypePrefix.length),
    };
  }
  if (typeHint.startsWith(EventTypePrefix)) {
    return {
      metaType: "event",
      configSchemaId: typeHint.slice(EventTypePrefix.length),
    };
  }
  return null;
};

/**
 * Extracts the type hint for a specific top level property of the data object from typing information
 */
export const getTypeHintForDataProperty = (
  typing: TypingInfo | null,
  propertyKey: string,
): string | undefined => {
  return typing?.properties?.data?.properties?.[propertyKey]?.type;
};
