import { maxBy } from "lodash";
import { useCallback, useEffect } from "react";
import { useLocalStorage, useSessionStorage } from "usehooks-ts";

import { useTestDatasets } from "src/datasets/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { useAuthStore } from "src/store/AuthStore";
import { useGraphStore } from "src/store/StoreProvider";

type GetLatestSelectedDatasetArgs = {
  userId: string;
  flowId: string;
};

const getLatestSelectedDatasetLocalStorageKey = ({
  userId,
  flowId,
}: GetLatestSelectedDatasetArgs): string => {
  return ["latestSelectedFlowDataset", userId, flowId].join();
};

/**
 * Use the selected dataset id. Saved in local storage to keep the latest selected dataset.
 * @returns The selected flow dataset.
 */
export const useSelectedDatasetId = () => {
  const { signed_in_user_id } = useAuthStore();
  const { flow, workspace } = useFlowContext();
  const { setResultsOutdated } = useGraphStore();
  const { testRunDatasets } = useTestDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });
  // We save the last set dataset in the local storage to keep it for new sessions
  const [latestSelectedDatasetId, saveLatestSelectedDataset] = useLocalStorage<
    string | null
  >(
    getLatestSelectedDatasetLocalStorageKey({
      userId: signed_in_user_id ?? "",
      flowId: flow.id,
    }),
    null,
  );
  // We save the selected dataset in the session so that different tabs can use different datasets
  const [selectedDatasetId, _setSelectedDatasetId] = useSessionStorage<
    string | null
  >(
    getLatestSelectedDatasetLocalStorageKey({
      userId: signed_in_user_id ?? "",
      flowId: flow.id,
    }),
    // New sessions will start with the last selected dataset
    latestSelectedDatasetId,
  );

  const setSelectedDatasetId = useCallback(
    (newDatasetId: string) => {
      _setSelectedDatasetId(newDatasetId);
      setResultsOutdated(true);
      if (newDatasetId !== null) {
        saveLatestSelectedDataset(newDatasetId);
      }
    },
    [_setSelectedDatasetId, setResultsOutdated, saveLatestSelectedDataset],
  );

  useEffect(() => {
    if (
      testRunDatasets.length > 0 &&
      !testRunDatasets.some((data) => data.id === selectedDatasetId)
    ) {
      const mostRecentUpload = maxBy(testRunDatasets, (data) =>
        new Date(data.created_at).valueOf(),
      );
      if (mostRecentUpload) {
        setSelectedDatasetId(mostRecentUpload.id);
      }
    }
  }, [testRunDatasets, setSelectedDatasetId, selectedDatasetId]);

  return {
    selectedDatasetId,
    setSelectedDatasetId,
  };
};
