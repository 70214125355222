import {
  QueryCache,
  QueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { logger } from "src/utils/logger";

const logErrorCode = 400;
const defaultErrorCode = 500;

const NO_REFETCH_OPTIONS: Partial<UseQueryOptions<any, any, any, any>> = {
  refetchInterval: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

const DEFAULT_USE_ERROR_BOUNDARY = (error: unknown) => {
  if (isAxiosError(error)) {
    const isCallbackPage = window.location.pathname.startsWith("/callback");
    const is500Error = error.request?.status === 500;

    if (is500Error && !isCallbackPage) {
      logger.error("Unexpected response status 500", error);
    }

    // In case of 500, we want to use ErrorBoundary
    return is500Error && !isCallbackPage;
  }

  logger.warn(
    `Can't handle error properly. Please use Axios for requests, or handle error in place:`,
    error,
  );

  return false;
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      // only log axios/network errors
      if (
        isAxiosError(error) &&
        (error.status ?? defaultErrorCode) >= logErrorCode
      ) {
        logger.error("Network error: ", error);
      }
    },
  }),
  defaultOptions: {
    queries: {
      ...NO_REFETCH_OPTIONS,
      throwOnError: DEFAULT_USE_ERROR_BOUNDARY,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === 429) {
          return false;
        }
        return failureCount < 3;
      },
      experimental_prefetchInRender: true,
    },
    mutations: {
      throwOnError: DEFAULT_USE_ERROR_BOUNDARY,
      onError: (error) => {
        // only log axios/network errors
        if (
          isAxiosError(error) &&
          (error.status ?? defaultErrorCode) >= logErrorCode
        ) {
          logger.error("Network error: ", error);
        }
      },
    },
  },
});
