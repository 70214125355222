import {
  faEllipsis,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import React, { ComponentPropsWithoutRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import {
  DropdownElement,
  OptionsDropdownElement,
  OptionsDropdownItems,
  isNav,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";

export type DropDownPropsT = {
  elements: OptionsDropdownElement[];
  menuClassName?: string;
  buttonDataLoc?: string;
  buttonClassName?: string;
  timeoutDuration?: number;
  ellipsisDirection?: "vertical";
  iconSize?: ComponentPropsWithoutRef<typeof Icon>["size"];
  placement?: "bottom-start" | "bottom-end";
  onOpenChanged?: (value: boolean) => void;
  renderMenuInPlace?: boolean;
};

export const EllipsisOptionsDropdown: React.FC<DropDownPropsT> = ({
  elements,
  menuClassName,
  buttonDataLoc,
  buttonClassName,
  ellipsisDirection = "vertical",
  iconSize = "xs",
  timeoutDuration: timeout,
  placement = "bottom-end",
  onOpenChanged,
  renderMenuInPlace = false,
}) => {
  const [open, _setOpen] = useState<boolean>(false);

  const setOpen = (value: boolean) => {
    _setOpen(value);
    onOpenChanged?.(value);
  };

  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(ref, popperElement, {
    placement,
  });

  const getOnElementClick =
    (element: DropdownElement) => (event: React.MouseEvent<Element>) => {
      event.stopPropagation();
      if (!isNav(element)) {
        setOpen(false);
      }
      element.action();
    };

  const renderedItems = (
    <div onMouseEnter={() => setOpen(true)}>
      <OptionsDropdownItems
        ref={setPopperElement}
        elements={elements}
        getOnElementClick={getOnElementClick}
        open={open}
        popper={popper}
        timeout={timeout}
      />
    </div>
  );

  return (
    <Menu
      as="div"
      className={twJoin(open && "is-open group", menuClassName)}
      onMouseLeave={() => setOpen(false)}
    >
      <Menu.Button
        ref={setRef}
        className={twJoin(
          "flex justify-center hover:text-gray-700",
          open && "text-gray-500",
          buttonClassName,
        )}
        data-loc={buttonDataLoc}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Icon
          color="text-gray-500 hover:text-gray-700"
          icon={
            ellipsisDirection === "vertical" ? faEllipsisVertical : faEllipsis
          }
          size={iconSize}
        />
      </Menu.Button>
      {renderMenuInPlace
        ? renderedItems
        : ReactDOM.createPortal(renderedItems, document.body as HTMLElement)}
    </Menu>
  );
};
