import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { memoize } from "lodash";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import { useManifests } from "src/api/connectApi/queries";
import {
  Providers,
  ProviderT,
  isSQLDatabaseProvider,
  isIntegrationProvider,
  ResourceT,
  LegacyIntegrationProviderT,
} from "src/api/connectApi/types";
import { CreateConnectionCard } from "src/connections/CreateConnectionCard";
import { getProviderName } from "src/connections/ProviderResourceProperties";
import {
  FEATURE_FLAGS,
  BETA_CONNECTIONS,
  hasConnectionCreationEnabled,
  isFeatureFlagEnabled,
} from "src/router/featureFlags";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

type AWSRegionPrefixes = "eu" | "us";

type ProviderRegionMapping = {
  [key in ProviderT]?: AWSRegionPrefixes[];
};

type ResourceRegionMapping = {
  [key in ProviderT]?: {
    [resource in ResourceT]?: AWSRegionPrefixes[];
  };
};

/**
 * Some providers are only available in certain geographical regions.
 * This mapping defines which regions are available for each provider
 * so that we can disable the provider card if it's not available.
 */
const ProviderRegionAvailability: ProviderRegionMapping = {
  boniversum: ["eu"],
  creditreform: ["eu"],
  crif_b2c: ["eu"],
  crif_b2b: ["eu"],
  crs: ["us"],
  equifax_us_commercial_risk: ["us"],
  equifax_us: ["us"],
  experian_us_b2b: ["us"],
  experian_us: ["us"],
  experian_us_clarity_services: ["us"],
  data_x: ["us"],
  schufa: ["eu"],
  thomson_reuters: ["us"],
  trans_union: ["us"],
};

const migratedLegacyProviderToManifestProviderMapping: Partial<{
  [key in LegacyIntegrationProviderT]: string;
}> = {
  creditsafe: "creditsafe_b2b",
  dnb: "dnb_v2",
  experian_us: "experian_us_b2c",
  socure: "socure_v2",
};

export const DeprecatedProviders: ProviderT[] = ["experian_us_b2b"];

const ResourceRegionAvailability: ResourceRegionMapping = {
  plaid: {
    financial_insights_report: ["eu"],
  },
};

// TODO: Move this to a base components.
const Divider: React.FC = () => (
  <div className="mt-3 h-px w-full rounded-full bg-gray-200"></div>
);

/**
 * Type for the props of the CreateConnectionGrid component which renders
 * the grid of connection cards in the create connection modal.
 */
export type CreateConnectionGridProps = {
  onProviderSelect: (
    provider: ProviderT | string,
    isManifestProvider: boolean,
  ) => void;
};

/**
 * Returns true if the provider is available in the current AWS region.
 * Returns true if the feature flag for powertools is enabled.
 */
export const isProviderAvailableInAWSRegion = (
  provider: ProviderT,
  AWSRegion: string | undefined,
): boolean => {
  if (isFeatureFlagEnabled(FEATURE_FLAGS.powertools)) {
    return true;
  }
  if (!(provider in ProviderRegionAvailability)) {
    return true;
  }
  const isAvailable = ProviderRegionAvailability[provider]?.some(
    (regionPrefix) => AWSRegion?.startsWith(regionPrefix),
  );
  return Boolean(isAvailable);
};

export const isProviderDeprecated = (provider: ProviderT): boolean => {
  return DeprecatedProviders.includes(provider);
};

export const isProviderDeprecatedWithPowertools = (
  provider: ProviderT,
): boolean => {
  if (isFeatureFlagEnabled(FEATURE_FLAGS.powertools)) {
    return false;
  }
  return isProviderDeprecated(provider);
};

export const isResourceAvailableInAwsRegion = (
  provider: ProviderT,
  resource: ResourceT,
  AWSRegion: string | undefined,
): boolean => {
  if (isFeatureFlagEnabled(FEATURE_FLAGS.powertools)) {
    return true;
  }
  if (!(provider in ResourceRegionAvailability)) {
    return true;
  }
  if (!(resource in ResourceRegionAvailability[provider]!)) {
    return true;
  }
  const isAvailable = ResourceRegionAvailability[provider]![resource]?.some(
    (regionPrefix) => AWSRegion?.startsWith(regionPrefix),
  );
  return Boolean(isAvailable);
};

export const isBetaConnection = (provider: ProviderT | string) => {
  return BETA_CONNECTIONS.includes(provider);
};

const isManifestProviderAvailableInAwsRegion = (
  manifest: ManifestIntegrationProvider,
  AWSRegion: string | undefined,
): boolean => {
  if (
    isFeatureFlagEnabled(FEATURE_FLAGS.powertools) ||
    isFeatureFlagEnabled(FEATURE_FLAGS.integrationVelocity)
  ) {
    return true;
  }
  if (manifest.geographic_restrictions) {
    return manifest.geographic_restrictions.some((regionPrefix) =>
      AWSRegion?.startsWith(regionPrefix),
    );
  }
  return true;
};

/**
 * Returns a list of providers that are available in the current AWS region
 * and have connection creation enabled. For example, CRS is only available
 * in US regions.
 */
const availableProviders = (AWSRegion: string | undefined) => {
  return Providers.filter((provider) => {
    const isEnabled = hasConnectionCreationEnabled(provider);
    const isAvailable = isProviderAvailableInAWSRegion(provider, AWSRegion);
    if (isFeatureFlagEnabled(FEATURE_FLAGS.powertools)) {
      return isEnabled && isAvailable;
    }

    const isDeprecated = isProviderDeprecated(provider);
    return isEnabled && isAvailable && !isDeprecated;
  }).sort((a, b) => a.localeCompare(b));
};

type IntegrationProvider = {
  key: LegacyIntegrationProviderT;
  value: string;
  isBeta: boolean;
  isDeprecated: boolean;
};

/**
 * Integration providers exclude databases and custom connections.
 * For example, Schufa, Boniversum, etc. are integration providers.
 */
export const integrationProviders = (
  AWSRegion: string | undefined,
): IntegrationProvider[] =>
  availableProviders(AWSRegion)
    .filter((provider) => isIntegrationProvider(provider))
    .map((provider) => ({
      key: provider,
      value: getProviderName(provider),
      isBeta: isBetaConnection(provider),
      isDeprecated: isProviderDeprecated(provider),
    }));

const isManifestProvider = (
  provider: ManifestIntegrationProvider | IntegrationProvider,
): provider is ManifestIntegrationProvider => {
  return (provider as ManifestIntegrationProvider).version !== undefined;
};

/**
 * Return a list of database providers that are available in the current AWS region.
 */
export const databaseProviders = (AWSRegion: string | undefined) =>
  availableProviders(AWSRegion)
    .filter((provider) => isSQLDatabaseProvider(provider))
    .map((provider) => ({
      key: provider,
      value: getProviderName(provider),
    }));

/**
 * This function is used to generate a unique key for each provider card.
 * It's used to a) identify the card in the VDOM and b) as test data-loc attribute.
 */

export const providerKey = memoize(
  (provider: ProviderT) => `create-${provider}-connection`,
);

interface Provider {
  provider: string;
  display_name: string;
}

export interface ManifestIntegrationProviders {
  [key: string]: Provider;
}

/**
 * This component renders the grid of connection cards in the create connection modal.
 * It calls onProviderSelect when a card is clicked.
 */
export const CreateConnectionGrid: React.FC<CreateConnectionGridProps> = ({
  onProviderSelect,
}) => {
  const workspaceData = useWorkspaceAndOrg();
  const AWSRegion = workspaceData.workspace?.aws_region;
  let manifestIntegrationProviders =
    useManifests(workspaceData.workspace?.base_url).data || [];

  // If integrationVelocity FF is disabled, display only non-`internal` manifest
  // providers available in the region
  manifestIntegrationProviders = manifestIntegrationProviders.filter(
    (provider) => isManifestProviderAvailableInAwsRegion(provider, AWSRegion),
  );
  if (!isFeatureFlagEnabled(FEATURE_FLAGS.integrationVelocity)) {
    manifestIntegrationProviders = manifestIntegrationProviders.filter(
      (provider) =>
        (provider.audience && provider.audience !== "internal") ||
        isFeatureFlagEnabled(provider.name),
    );
  }
  const filteredProviders: (
    | ManifestIntegrationProvider
    | IntegrationProvider
  )[] = [
    ...integrationProviders(AWSRegion).filter((provider) => {
      if (migratedLegacyProviderToManifestProviderMapping[provider.key]) {
        return !manifestIntegrationProviders.some(
          (manifestProvider) =>
            manifestProvider.name ===
            migratedLegacyProviderToManifestProviderMapping[provider.key],
        );
      }
      return true;
    }),
    ...manifestIntegrationProviders,
  ].sort((a, b) => {
    const aValue = isManifestProvider(a) ? a.display_name : a.value;
    const bValue = isManifestProvider(b) ? b.display_name : b.value;
    return aValue.localeCompare(bValue);
  });

  return (
    <>
      <div className="flex flex-wrap justify-start">
        {filteredProviders.map((provider, index) => {
          const side = index % 2 === 0 ? "left" : "right";

          if (isManifestProvider(provider)) {
            return (
              <CreateConnectionCard
                key={`${provider.name}-${index}`}
                dataLoc={`manifest-${provider.name}-${provider.audience}`}
                isBeta={provider.audience === "beta"}
                manifestVersion={provider.version}
                provider={provider.name}
                side={side}
                size="half"
                title={provider.display_name}
                onClick={() => onProviderSelect(provider.name, true)}
              />
            );
          }

          return (
            <CreateConnectionCard
              key={providerKey(provider.key)}
              dataLoc={providerKey(provider.key)}
              isBeta={provider.isBeta}
              isDeprecated={provider.isDeprecated}
              provider={provider.key}
              side={side}
              size="half"
              title={provider.value}
              onClick={() => onProviderSelect(provider.key, false)}
            />
          );
        })}
      </div>
      <Divider />
      <div className="mt-4 text-gray-800 font-inter-semibold-14px">
        Databases
      </div>
      <div className="mt-5 flex flex-wrap justify-start text-right">
        {databaseProviders(AWSRegion).map((provider, index) => {
          const side = index % 2 === 0 ? "left" : "right";
          return (
            <CreateConnectionCard
              key={providerKey(provider.key)}
              dataLoc={providerKey(provider.key)}
              isBeta={isBetaConnection(provider.key)}
              provider={provider.key}
              side={side}
              size="half"
              title={provider.value}
              onClick={() => onProviderSelect(provider.key, false)}
            />
          );
        })}
      </div>
      <Divider />
      <div className="mt-4 text-gray-800 font-inter-semibold-14px">
        Other tools
      </div>
      <div className="mt-5 flex flex-wrap justify-start text-right">
        <CreateConnectionCard
          key={providerKey("custom")}
          dataLoc={providerKey("custom")}
          isBeta={false}
          provider="custom"
          size="full"
          title="Custom Connection"
          onClick={() => onProviderSelect("custom", false)}
        />
        <CreateConnectionCard
          key={providerKey("webhook")}
          dataLoc={providerKey("webhook")}
          isBeta={false}
          provider="webhook"
          size="full"
          title="Inbound Webhook"
          onClick={() => onProviderSelect("webhook", false)}
        />
        <CreateConnectionCard
          key={providerKey("retool")}
          dataLoc={providerKey("retool")}
          helpTooltip={{
            body: "The Retool connection can be used to use your Retool app within Taktile. This can be useful to e.g. embed dashboards or create a Case Management UI. Once created you can access your Retool app from the Taktile home screen on the top right corner.",
            title: "Retool embed",
            placement: "right",
            icon: faInfoCircle,
          }}
          isBeta={false}
          provider="retool"
          size="full"
          title="Retool"
          onClick={() => onProviderSelect("retool", false)}
        />
      </div>
    </>
  );
};
