import { Link, useNavigate } from "react-router-dom";

import { FlowVersionT } from "src/api/flowTypes";
import {
  useFlowVersion,
  useRestoreFlowVersion,
} from "src/api/flowVersionQueries";
import { Button } from "src/base-components/Button";
import { LoadingView } from "src/base-components/LoadingView";
import { FlowVersionStatus } from "src/clients/flow-api";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { getUrlToAuthoringPage } from "src/router/urls";
import { renderEmpty } from "src/utils/renderEmpty";
import { useParamsDecode } from "src/utils/useParamsDecode";

const ButtonSkeleton: React.FC = () => {
  const movingElement = (
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-white to-gray-100"
      style={{ animation: "shimmer 1s infinite" }}
    />
  );
  return (
    <div className="relative h-8 w-[150px] overflow-hidden rounded-md border border-gray-300 bg-gray-100 shadow-sm">
      {movingElement}
    </div>
  );
};

export const RevisionHeaderButtons: React.FC<{}> = () => {
  const { flow, version, orgId, workspace } = useAuthoringContext();
  const navigate = useNavigate();
  const { revision_etag } = useParamsDecode();
  const restoreFlowVersion = useRestoreFlowVersion();
  const latestFlowVersion = useFlowVersion(version.id);

  const handleRestore = async () => {
    await restoreFlowVersion
      .mutateAsync({
        versionId: version.id,
        etag: revision_etag ?? "",
      })
      .then(() => {
        navigate(
          getUrlToAuthoringPage(orgId, workspace.id, flow.id, version.id),
        );
        tracker.emit(
          trackingEvents.restoreRevision({
            flow_id: flow.id,
            flow_version_id: version.id,
            organization_id: orgId,
          }),
        );
      });
  };

  return (
    <div className="flex flex-row items-center">
      <>
        <div className="mr-2 flex flex-row items-center gap-x-2">
          <>
            <div className="flex h-8 items-center rounded-md border border-gray-300 bg-white px-4 py-2 shadow-sm font-inter-medium-13px">
              <Link
                className="text-black"
                data-loc="change-history-open-latest-revision-link"
                to={getUrlToAuthoringPage(
                  orgId,
                  workspace.id,
                  flow.id,
                  version.id,
                )}
              >
                Go back to current state
              </Link>
            </div>
            <LoadingView
              queryResult={latestFlowVersion}
              renderErrored={renderEmpty}
              renderUpdated={(latestVersion: FlowVersionT) => (
                <>
                  {latestVersion.status === FlowVersionStatus.DRAFT && (
                    <Button
                      dataLoc="change-history-revert-to-revision-button"
                      variant="secondary"
                      onClick={handleRestore}
                    >
                      Revert to this snapshot
                    </Button>
                  )}
                </>
              )}
              // In basically all cases latest version will be already present in the query cache,
              // but in case it is not we don't want the revert snapshot button to flash in the place
              // of Open current state to prevent missclicks.
              renderUpdating={() => <ButtonSkeleton />}
            />
          </>
        </div>
      </>
    </div>
  );
};
