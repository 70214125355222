import {
  faBuilding,
  faCalculatorSimple,
  faCode,
  faCopy,
  faEdit,
  faInfoCircle,
  faMinus,
  faPlus,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { differenceInDays } from "date-fns";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";

import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { Cell, Header, TableComp } from "src/base-components/Table";
import {
  EntityMapping,
  FeatureQueryEnvironmentSetting,
  FeatureQueryStatus,
  PreviewQueryRequestEnvironmentEnum,
} from "src/clients/features-control";
import { TruncatedIdPill } from "src/decisionsOverview/TruncatedIdPill";
import { EmptyState } from "src/design-system/EmptyState";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { ManageQueryModal } from "src/featureCatalogue/Queries/ManageQueryModal";
import { QueryStatusPill } from "src/featureCatalogue/Queries/QueryStatusPill";
import {
  useFeatureQueries,
  useFeatureQuery,
  useSetFeatureQueryStatus,
  useUpdateFeatureQuery,
} from "src/featureCatalogue/queries";
import {
  commonColumns,
  ENTITY_ICON_MAP,
  formatAthenaSqlQuery,
  isActiveQuery,
  Query,
} from "src/featureCatalogue/utils";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { WhitePane } from "src/jobs/common/WhitePane";
import { dateFromNow, formatDate, parseDateIfNeeded } from "src/utils/datetime";
import { HighlightedSql } from "src/utils/highlighter";
import { getTypeIcon } from "src/utils/icons";
import { useShowOnHover } from "src/utils/useShowOnHover";

const columnHelper = createColumnHelper<Query>();

const COLUMNS = [
  columnHelper.accessor("id", {
    header: (info) => <Header info={info}>Query ID</Header>,
    cell: (info) => (
      <Cell className="w-24" info={info}>
        <TruncatedIdPill id={info.getValue()} />
      </Cell>
    ),
    minSize: 80,
    maxSize: 80,
  }),
  columnHelper.accessor("query", {
    header: (info) => <Header info={info}>SQL Query</Header>,
    cell: (info) => <DisplaySqlCell info={info} />,
    size: 300,
    maxSize: 400,
  }),
  columnHelper.accessor("environment_settings", {
    header: (info) => <Header info={info}>Status</Header>,
    cell: (info) => {
      const environmentSettings = info.getValue();

      return (
        <Cell info={info}>
          <StatusPill environmentSettings={environmentSettings} />
        </Cell>
      );
    },
    size: 100,
  }),
  columnHelper.accessor("columns.features", {
    header: (info) => <Header info={info}>Features</Header>,
    cell: (info) => {
      const features = info.getValue() ?? [];
      const [displayFeatures, remainingFeatures] =
        features.length > 2
          ? [features.slice(0, 1), features.slice(1)]
          : [features, []];

      return (
        <Cell info={info}>
          <div className="flex gap-2">
            {displayFeatures?.map((feature) => (
              <Tooltip
                delay={250}
                headerAction={
                  <CopyTextIcon
                    color="text-white hover:text-gray-500"
                    feedback="inline-only-checkmark"
                    value={feature.feature_key}
                  />
                }
                placement="top"
                title={feature.feature_key}
                asChild
              >
                <Pill key={feature.feature_key} size="sm" variant="gray">
                  <Pill.Icon
                    icon={getTypeIcon(feature.feature_type ?? "", faMinus)}
                  />
                  <Pill.Text>
                    <span
                      className={twJoin(
                        "block truncate",
                        displayFeatures.length === 1 ? "max-w-40" : "max-w-16",
                      )}
                    >
                      {feature.feature_key}
                    </span>
                  </Pill.Text>
                </Pill>
              </Tooltip>
            ))}
            {remainingFeatures?.length > 0 && (
              <RemainingFeaturesPill features={remainingFeatures} />
            )}
          </div>
        </Cell>
      );
    },
    size: 180,
    maxSize: 280,
  }),
  columnHelper.accessor("columns.entities", {
    header: (info: any) => <Header info={info}>Entity</Header>,
    cell: (info: any) => (
      <Cell className="flex space-x-2" info={info}>
        {info.getValue()?.map((entity: EntityMapping) => {
          const icon =
            ENTITY_ICON_MAP[
              entity.entity_name as keyof typeof ENTITY_ICON_MAP
            ] ?? faBuilding;
          return (
            <Pill key={entity.entity_name} size="sm" variant="gray">
              <Pill.Icon icon={icon} />
              <Pill.Text>{capitalize(entity.entity_name)}</Pill.Text>
            </Pill>
          );
        })}
      </Cell>
    ),
  }),
  ...commonColumns,
  columnHelper.accessor("created_at", {
    header: (info) => <Header info={info}>Created at</Header>,
    cell: (info) => (
      <Cell info={info}>
        <RelativeOrAbsoluteDate
          date={info.getValue()}
          format="d MMM yyyy h:mm aaa"
          thresholdDays={30}
        />
      </Cell>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: (info) => <Header info={info} />,
    cell: (info) => (
      <Cell className="justify-end" info={info}>
        <QueryActionsMenu
          query={info.row.original}
          onDuplicateQuery={info.table.options.meta?.actions?.onDuplicateQuery}
          onEditQuery={info.table.options.meta?.actions?.onEditQuery}
          onSetStatusQuery={info.table.options.meta?.actions?.onSetStatusQuery}
        />
      </Cell>
    ),
    size: 40,
  }),
];

export const Queries: React.FC = () => {
  const {
    openModal,
    closeModal,
    afterLeave,
    isOpen,
    data: queryToEdit,
  } = useModal<Query | Omit<Query, "key">>();
  const { data, isLoading, isSuccess } = useFeatureQueries();
  const { mutate: updateFeatureQuery } = useUpdateFeatureQuery({
    onError: (error) => {
      toastActions.failure({
        title: "Failed to update feature query",
        description: error.response?.data.detail ?? TAKTILE_TEAM_NOTIFIED,
      });
    },
  });
  const { value: isDuplicateModal, toggle: toggleIsDuplicateModal } =
    useBoolean(false);

  const { mutate: setFeatureQueryStatus } = useSetFeatureQueryStatus();

  useOpenEditQueryModalFromParams(openModal);

  return (
    <WhitePane>
      {isSuccess && (data ?? []).length === 0 ? (
        <EmptyState
          actionButton={{
            icon: faPlus,
            text: "Create Feature Query",
            onClick: () => {
              openModal();
            },
          }}
          dataLoc="queries-empty-state"
          description="Create Feature Queries"
          headline="No Feature Queries found"
          icon={faCode}
        />
      ) : (
        <TableComp
          actions={{
            onEditQuery: openModal,
            onChangeQueryStatus: updateFeatureQuery,
            onDuplicateQuery: ({ id: _dropId, ...query }) => {
              toggleIsDuplicateModal();
              openModal({
                ...query,
                columns: {
                  ...query.columns,
                  features: [
                    { column_name: "", feature_key: "", feature_type: "" },
                  ],
                },
              });
            },
            onSetStatusQuery: setFeatureQueryStatus,
          }}
          columns={COLUMNS}
          data={
            (data?.filter((item): item is Query => item.id !== undefined) ??
              []) as Query[]
          }
          dataLoc="queries-list"
          frameClassName="w-full p-4"
          isLoading={isLoading}
          rowPropsGetter={(row) => ({
            "data-loc": row.original.columns.features?.[0]?.feature_key,
          })}
          variant="compact"
        />
      )}
      <ManageQueryModal
        afterLeave={afterLeave}
        open={isOpen}
        query={queryToEdit}
        readOnly={isDuplicateModal ? false : !!queryToEdit?.finalized_at}
        onClose={() => {
          closeModal();
          if (isDuplicateModal) {
            toggleIsDuplicateModal();
          }
        }}
      />
    </WhitePane>
  );
};

const DisplaySqlCell: React.FC<{ info: CellContext<Query, string> }> = ({
  info,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomPopover
      button={
        <Cell info={info}>
          <HighlightedSql sqlString={info.getValue()} />
        </Cell>
      }
      className="h-[400px] w-[400px] border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <CodeEditor
          language="sql"
          value={formatAthenaSqlQuery(info.getValue())}
          lineWrapping
          readOnly
        />
      )}
    </CustomPopover>
  );
};

const QueryActionsMenu: React.FC<{
  query: Query;
  onEditQuery: ((query: Query) => void) | undefined;
  onDuplicateQuery: ((query: Query) => void) | undefined;
  onSetStatusQuery:
    | ((params: {
        status: FeatureQueryStatus;
        environment: PreviewQueryRequestEnvironmentEnum;
        id: string;
        etag: string;
      }) => void)
    | undefined;
}> = ({ query, onEditQuery, onDuplicateQuery, onSetStatusQuery }) => {
  const { openModal: openConfirmationModal, isOpen, closeModal } = useModal();
  const [environment] = useEnvironment();
  const envEnum =
    environment === "sandbox"
      ? PreviewQueryRequestEnvironmentEnum.SANDBOX
      : PreviewQueryRequestEnvironmentEnum.LIVE;

  const isActive = isActiveQuery(query, envEnum);

  const setQueryStatus = async () => {
    try {
      await onSetStatusQuery?.({
        id: query.id,
        environment: envEnum,
        status: isActiveQuery(query, envEnum)
          ? FeatureQueryStatus.INACTIVE
          : FeatureQueryStatus.ACTIVE,
        etag: query.etag,
      });

      toastActions.success({
        title: isActive ? "Query Deactivated" : "Query Activated",
        description: isActive
          ? "Query is successfully deactivated."
          : "Query is successfully activated.",
      });
    } catch {
      const toastId = toastActions.failure({
        title: isActive ? "Deactivation Failed" : "Activation Failed",
        description: isActive
          ? "Query couldn't be deactivated. Try again."
          : "Query couldn't be activated. Try again.",
        actionText: "Try again",
        onActionClick: () => {
          toastActions.dismiss(toastId);
          setQueryStatus();
        },
      });
    }
  };

  const { featureQueriesCatalogue } = useCapabilities();
  const menuElements = [
    featureQueriesCatalogue.canActivate && {
      key: isActive ? "Deactivate query" : "Activate query",
      icon: isActive ? faToggleOff : faToggleOn,
      action:
        envEnum === PreviewQueryRequestEnvironmentEnum.LIVE
          ? openConfirmationModal
          : setQueryStatus,
      suffix:
        envEnum === PreviewQueryRequestEnvironmentEnum.SANDBOX ? (
          <Tooltip
            body="This query will be activated only in Sandbox environment."
            placement="top-end"
            asChild
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="sm" />
          </Tooltip>
        ) : undefined,
    },
    featureQueriesCatalogue.canEdit && {
      key: "Edit Query",
      icon: faEdit,
      action: () => onEditQuery?.(query),
    },
    featureQueriesCatalogue.canDuplicate && {
      key: "Duplicate",
      icon: faCopy,
      action: () => onDuplicateQuery?.(query),
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  return (
    <>
      <EllipsisOptionsDropdown
        buttonClassName="text-gray-500 ml-1 group-hover/row:opacity-100"
        buttonDataLoc="query-actions-menu-button"
        elements={menuElements}
        iconSize="xs"
        placement="bottom-end"
        timeoutDuration={200}
      />
      <ConfirmationModal
        confirmationButtonText={isActive ? "Deactivate" : "Activate"}
        description={
          isActive
            ? "Deactivating this feature query will halt all associated feature value computations. This may affect published flows that rely on these feature values for their logic."
            : "Once you activate, you will not be able to edit the query nor its associated features. Are you sure you want to activate this query?"
        }
        open={isOpen}
        title={isActive ? "Deactivate query" : "Activate query"}
        variant={isActive ? "danger" : "default"}
        onClose={closeModal}
        onConfirm={() => {
          closeModal();
          setTimeout(setQueryStatus, 100);
        }}
      />
    </>
  );
};

const useOpenEditQueryModalFromParams = (openModal: (query: Query) => void) => {
  const hasBeenOpened = useRef(false);
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get("queryId");
  const { data: queryParams } = useFeatureQuery(queryId ?? "");

  // Open the modal with the query data when the queryId is in the search params
  useEffect(() => {
    if (queryParams?.id && !hasBeenOpened.current) {
      openModal(queryParams as Query);
      hasBeenOpened.current = true;
    }
  }, [openModal, queryParams]);
};

const RemainingFeaturesPill: React.FC<{
  features: { feature_key: string }[];
}> = ({ features }) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useShowOnHover(700);

  return (
    <CustomPopover
      button={
        <span className="cursor-default">
          <Pill size="sm" variant="gray">
            <Pill.Icon icon={faPlus} />
            <Pill.Text>{features.length} more</Pill.Text>
          </Pill>
        </span>
      }
      className="flex flex-wrap justify-start gap-2 p-4"
      isOpen={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {features.map((feature) => (
        <Pill key={feature.feature_key} size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{feature.feature_key}</Pill.Text>
        </Pill>
      ))}
    </CustomPopover>
  );
};

const RelativeOrAbsoluteDate: React.FC<{
  date: string;
  format: string;
  thresholdDays: number;
}> = ({ date, thresholdDays = 30, format }) => {
  const parsedDate = parseDateIfNeeded(date);
  const diffInDays = differenceInDays(new Date(), parsedDate);

  const dateString =
    diffInDays < thresholdDays
      ? dateFromNow(parsedDate)
      : formatDate(parsedDate, format);

  return (
    <Tooltip
      body={formatDate(date, format)}
      disabled={diffInDays >= thresholdDays}
      placement="top-end"
      asChild
    >
      <span>{dateString}</span>
    </Tooltip>
  );
};

const StatusPill = ({
  environmentSettings,
}: {
  environmentSettings: Record<string, FeatureQueryEnvironmentSetting>;
}) => {
  const [environment] = useEnvironment();

  const status = environmentSettings[environment]?.status;

  if (!status) {
    return null;
  }

  return <QueryStatusPill status={status} />;
};
