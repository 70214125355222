import { faCheck, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { BaseRadioGroupItem } from "src/base-components/SimpleRadioGroup";
import { Tooltip } from "src/design-system/Tooltip";

export type SinceOption = "all" | "lastReviewed" | "reviewStarted";
export type ListOption = "sequential" | "grouped";

const MenuItem: React.FC<{
  id: string;
  children: ReactNode;
  isHeader?: boolean;
  disabled?: boolean;
  onClick: (key: string, event: React.MouseEvent<HTMLDivElement>) => void;
  dataLoc?: string;
}> = (props) => (
  <Menu.Item
    as="div"
    data-loc={props.dataLoc}
    disabled={props.isHeader || props.disabled}
  >
    {() => (
      <div
        className={twJoin(
          "px-4 py-2.5 font-medium font-inter-normal-13px",
          !!props.isHeader || props.disabled
            ? "cursor-default"
            : "cursor-pointer hover:bg-gray-50",
          props.disabled ? "text-gray-500" : "text-gray-800",
        )}
        onClick={
          !props.disabled
            ? (event) => props.onClick(props.id, event)
            : undefined
        }
      >
        {props.children}
      </div>
    )}
  </Menu.Item>
);

type Props = {
  visible?: boolean;
  button: ReactNode;
  placement?: "bottom-start" | "bottom-end";
  buttonAs?: React.ElementType;
  displaySince: SinceOption;
  setDisplaySince: (since: SinceOption) => void;
  listMode: ListOption;
  setListMode: (list: ListOption) => void;
  showReviewOptions: boolean;
  userHasReviewed: boolean;
};

const divider = <div className="my-2.5 border-t border-gray-200" />;
const MenuHeader: React.FC<{ title: string }> = ({ title }) => (
  <div className="py-2 pl-4 font-inter-semibold-13px">{title}</div>
);

const SinceItem = ({
  name,
  dataLoc,
  id,
  infoTooltip,
  disabled,
  displaySince,
  setDisplaySince,
}: {
  name: string;
  dataLoc?: string;
  id: SinceOption;
  infoTooltip?: string;
  disabled?: boolean;
  displaySince: SinceOption;
  setDisplaySince: (since: SinceOption) => void;
}) => (
  <MenuItem
    dataLoc={dataLoc}
    disabled={disabled}
    id={id}
    onClick={() => setDisplaySince(id)}
  >
    <div className="flex items-center justify-between">
      {name}
      <div className="flex items-center gap-x-1">
        {infoTooltip ? (
          <Tooltip body={infoTooltip} placement="bottom">
            <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
          </Tooltip>
        ) : null}
        {displaySince === id && (
          <Icon color="text-indigo-500" icon={faCheck} size="xs" />
        )}
      </div>
    </div>
  </MenuItem>
);

const ListOptionItem = ({
  name,
  dataLoc,
  id,
  setListMode,
  listMode,
}: {
  name: string;
  disabledReason?: string;
  dataLoc?: string;
  id: ListOption;
  listMode: ListOption;
  setListMode: (list: ListOption) => void;
}) => (
  <MenuItem dataLoc={dataLoc} id={id} onClick={() => setListMode(id)}>
    <div className="flex items-center">
      <BaseRadioGroupItem checked={listMode === id} disabled={false} />
      <span className="px-2">{name}</span>
    </div>
  </MenuItem>
);

export const ViewOptionsDropdown: React.FC<Props> = ({
  visible = true,
  placement = "bottom-start",
  button,
  buttonAs: as,
  displaySince,
  setDisplaySince,
  listMode,
  setListMode,
  showReviewOptions,
  userHasReviewed,
}) => {
  const [menuRef, setMenuRef] = useState<Nullable<HTMLElement>>(null);
  const [itemsRef, setItemsRef] = useState<Nullable<HTMLDivElement>>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    menuRef,
    itemsRef,
    {
      strategy: "fixed",
      placement,
      modifiers: [{ name: "offset", options: { offset: [8, 4] } }],
    },
  );

  const renderItems = () => {
    return (
      <>
        <MenuHeader title="View changes" />
        <SinceItem
          displaySince={displaySince}
          id="all"
          name="All"
          setDisplaySince={setDisplaySince}
        />
        {showReviewOptions ? (
          <>
            <SinceItem
              disabled={!userHasReviewed}
              displaySince={displaySince}
              id="lastReviewed"
              infoTooltip="Changes since you last submitted a review (Approve, Request Changes or Comment)"
              name="Since you last reviewed"
              setDisplaySince={setDisplaySince}
            />
            <SinceItem
              displaySince={displaySince}
              id="reviewStarted"
              name="Since review process started"
              setDisplaySince={setDisplaySince}
            />
          </>
        ) : null}
        {divider}
        <MenuHeader title="List activities" />
        <ListOptionItem
          dataLoc="change-history-view-options-menu-list-sequential"
          id="sequential"
          listMode={listMode}
          name="By time"
          setListMode={setListMode}
        />
        <ListOptionItem
          dataLoc="change-history-view-options-menu-list-grouped"
          id="grouped"
          listMode={listMode}
          name="Grouped by node or section"
          setListMode={setListMode}
        />
      </>
    );
  };

  const isFiltering = displaySince !== "all" || listMode !== "sequential";

  return (
    <Menu
      ref={setMenuRef}
      as="div"
      className="relative ml-auto"
      data-loc="change-history-view-options-menu"
    >
      {({ open }) => (
        <>
          <Menu.Button
            as={as}
            className={visible || open ? "visible" : "invisible"}
          >
            {button}
            {isFiltering && (
              <div className="absolute right-0.5 top-0.5 h-2 w-2 rounded-full border border-white bg-indigo-600" />
            )}
          </Menu.Button>
          <AnimatePresence>
            {open && (
              <m.div
                key="view_options_menu"
                ref={setItemsRef}
                style={{
                  ...popperStyles.popper,
                  maxHeight: menuRef
                    ? `calc(100vh - ${menuRef.scrollHeight}px)`
                    : undefined,
                }}
                {...popperAttributes.popper}
                animate={{ opacity: 1, transition: { duration: 0.2 } }}
                className="z-50 min-h-[136px] w-[273px] overflow-y-auto rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                data-loc="view_options_menu_panel"
                exit={{ opacity: 0, transition: { duration: 0.1 } }}
                initial={{ opacity: 0 }}
              >
                <Menu.Items static>{renderItems()}</Menu.Items>
              </m.div>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};
