import { Menu, MenuButton } from "@headlessui2/react";
import React from "react";

import {
  DropdownElement,
  OptionsDropdownElement,
  OptionsDropdownItemsHeadlessUi2,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";

type Props = {
  elements: OptionsDropdownElement[];
  buttonDataLoc?: string;
  placement?: "bottom start" | "bottom end";
  children: React.ReactNode;
};

const getOnElementClick = (element: DropdownElement) => () => {
  element.action();
};

export const OptionsDropdown: React.FC<Props> = ({
  elements,
  buttonDataLoc,
  placement = "bottom end",
  children,
}) => {
  return (
    <Menu as="div">
      <MenuButton
        as="div"
        className="flex justify-center"
        data-loc={buttonDataLoc}
      >
        {children}
      </MenuButton>
      <OptionsDropdownItemsHeadlessUi2
        elements={elements}
        getOnElementClick={getOnElementClick}
        placement={placement}
      />
    </Menu>
  );
};
