import { analytics } from "./analytics";
import { ProviderT, ResourceT } from "src/api/connectApi/types";
import { DecisionEnvironment } from "src/api/types";
import { CommentResourceType } from "src/clients/flow-api";
import { BeMappedNodeType, NODE_TYPE } from "src/constants/NodeTypes";

class TrackingEvent {
  eventProps: ITrackingEventProps;
  eventName: string;
  constructor(eventName: string, eventProps: ITrackingEventProps) {
    this.eventProps = eventProps;
    this.eventName = eventName;
  }
}

interface ITrackingEventProps {
  organization_id: string;
}

interface IAuthoringTestExecError extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
  run_id: string | undefined;
  error_message: string;
  exception_type: string;
  status_code: number | undefined;
  type: "flow_level" | "row_level";
  node_id: string | undefined;
  node_type?: BeMappedNodeType;
  failure_count: number | undefined;
}

interface IAuthoringPopUpNotification extends ITrackingEventProps {
  type: string;
  message?: string;
  title?: string;
}

interface ICreateNewFlowVersion extends ITrackingEventProps {
  description: string;
  source_flow_version_id: string;
  flow_id: string;
  flow_version_name: string;
  flow_version_id: string;
}

interface IClickTestRun extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
  sample_data_id: string;
  run_id?: string;
}

interface IAddNewNode extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
  node_type: NODE_TYPE;
  provider?: ProviderT | string;
  resource?: ResourceT | string;
  connection_id?: string;
  resource_config_id?: string;
}

interface IPasteNode extends IAddNewNode {
  paste_operation_id: string;
}

interface IClickCreateFLow extends ITrackingEventProps {
  workspace_id: string;
  flow_id: string;
}

interface ICreateNewComment extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
  is_reply: false;
  resource_id: string;
  resource_type: CommentResourceType;
}

interface ICreateNewCommentReply extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
  is_reply: true;
  parent_comment_id: string;
}

interface IChangeHistoryMetrics extends ITrackingEventProps {
  flow_id: string;
  flow_version_id: string;
}

interface IClientRuntimeError extends ITrackingEventProps {
  error_message: string;
  error_stack: string;
  error_component_stack: string;
}

interface IDownloadAuditLogFile extends ITrackingEventProps {
  start_time: string;
  end_time: string;
  actor_id?: string;
}

interface IChangeAuditLogFilters extends ITrackingEventProps {
  start_time: string;
  end_time: string;
  actor_id?: string;
}

interface IChartView extends ITrackingEventProps {
  chart_id: string;
  user_id: string;
  test_run_id: string;
  flow_id: string;
  flow_version_id: string;
}

interface IReactCopilotResponse extends ITrackingEventProps {
  node_id: string;
  conversation_id: string;
  completion_id: string;
  reaction: "liked" | "disliked" | "unset";
}

interface ICopilotCompletion extends ITrackingEventProps {
  flow_version_id: string;
  node_id: string;
  completion_id: string;
  includes_node_suggestion: boolean;
}

interface ICopilotApplySuggestion extends ITrackingEventProps {
  flow_version_id: string;
  node_id: string;
  etag: string;
  completion_id: string;
  conversation_id: string;
}

// Base interface for all decision page interactions
interface IDecisionPageInteractionBase extends ITrackingEventProps {
  query_param_decision_id: string | null;
  query_param_entity_id: string | null;
  env: DecisionEnvironment | null;
  decision_id: string;
}

interface IGotoReviewCase extends IDecisionPageInteractionBase {
  review_case_id: string;
}

export const trackingEvents = {
  authoringTestExecError: (props: IAuthoringTestExecError) =>
    new TrackingEvent("authoring_test_exec_error", props),
  authoringPopupNotification: (props: IAuthoringPopUpNotification) =>
    new TrackingEvent("authoring_popup_notification", props),
  clickTestRun: (props: IClickTestRun) =>
    new TrackingEvent("click_test_run", props),
  createNewNode: (props: IAddNewNode) =>
    new TrackingEvent("add_new_node", props),
  createFlow: (props: IClickCreateFLow) =>
    new TrackingEvent("click_create_flow", props),
  createNewFlowVersion: (props: ICreateNewFlowVersion) =>
    new TrackingEvent("create_new_flow_version", props),
  pasteExistingNode: (props: IPasteNode) =>
    new TrackingEvent("paste_existing_node", props),
  createNewComment: (props: ICreateNewComment | ICreateNewCommentReply) =>
    new TrackingEvent("create_new_comment", props),
  openRevisionPage: (props: IChangeHistoryMetrics) =>
    new TrackingEvent("ch_flow_revision_page_view", props),
  jumpToPreviousFlowVersion: (props: IChangeHistoryMetrics) =>
    new TrackingEvent("ch_flow_previous_version", props),
  openHistoryPane: (props: IChangeHistoryMetrics) =>
    new TrackingEvent("ch_flow_history_pane", props),
  restoreRevision: (props: IChangeHistoryMetrics) =>
    new TrackingEvent("ch_flow_restore", props),
  setDataRetention: (props: ITrackingEventProps) =>
    new TrackingEvent("data_retention_set", props),
  clientRuntimeError: (props: IClientRuntimeError) =>
    new TrackingEvent("client_runtime_error", props),
  downloadAuditLogFile: (props: IDownloadAuditLogFile) =>
    new TrackingEvent("download_audit_log_file", props),
  changeAuditLogFilters: (props: IChangeAuditLogFilters) =>
    new TrackingEvent("change_audit_log_filters", props),
  chartView: (props: IChartView) => new TrackingEvent("chart_view", props),
  reactCopilotResponse: (props: IReactCopilotResponse) =>
    new TrackingEvent("react_copilot_response", props),
  copilotCompletion: (props: ICopilotCompletion) =>
    new TrackingEvent("copilot_completion", props),
  copilotApplySuggestion: (props: ICopilotApplySuggestion) =>
    new TrackingEvent("copilot_apply_suggestion", props),
  openDecisionDetails: (props: IDecisionPageInteractionBase) =>
    new TrackingEvent("decision_page_open_details", props),
  gotoDecisionTrace: (props: IDecisionPageInteractionBase) =>
    new TrackingEvent("decision_page_goto_trace", props),
  gotoReviewCase: (props: IGotoReviewCase) =>
    new TrackingEvent("decision_page_goto_review", props),
};

export const tracker = {
  emit: (event: TrackingEvent): void => {
    const { organization_id, ...props } = event.eventProps;
    analytics.ready(() => {
      analytics.track(
        event.eventName,
        Object.assign({ $group_id: organization_id }, props),
      );
    });
  },
};
