import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  Merge,
  FieldError,
  FieldErrorsImpl,
  FormProvider,
  useFieldArray,
  UseFormReturn,
} from "react-hook-form";

import { Button } from "src/base-components/Button";
import { SchemaOptions } from "src/router/SearchParams";
import { SchemaEditRow } from "src/schema/SchemaEditRow";
import { SchemaEmptyState } from "src/schema/SchemaEmptyState";
import {
  PropertyUIT,
  SchemaUIT,
  getNewProperty,
} from "src/schema/schemaMappingUtils";

type CustomSchemaEditorProps = {
  isShowingInputSchema: boolean;
  isReadonly: boolean;
  type: SchemaOptions;
  formMethods: UseFormReturn<SchemaUIT>;
};

export const CustomSchemaEditor: React.FC<CustomSchemaEditorProps> = ({
  isShowingInputSchema,
  isReadonly,
  type,
  formMethods,
}) => {
  const { control, getValues } = formMethods;
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "properties",
  });

  const onAddField = () => {
    if (isReadonly) {
      return;
    }
    append(getNewProperty());
  };

  const checkNameUnique = (name: string) =>
    getValues("properties").filter((p) => p.fieldName === name).length < 2;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  return (
    <>
      {fields.length > 0 && (
        <>
          <h3 className="mb-4 font-inter-semibold-13px">Fields</h3>
          <div className="flex flex-row items-center gap-x-2 text-gray-500 font-inter-normal-12px">
            <div className="basis-[20px]" />
            <div className="grow">Name</div>
            <div className="w-[140px]">Type</div>
            <div
              className={isShowingInputSchema ? "basis-[82px]" : "basis-[52px]"}
            />
          </div>
        </>
      )}
      {fields.length === 0 && (
        <SchemaEmptyState
          handleAddField={onAddField}
          isReadonly={isReadonly}
          type={type}
        />
      )}
      <form>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            disabled={isReadonly}
            items={fields.map((param) => param.id)}
          >
            <FormProvider {...formMethods}>
              {fields &&
                fields.map((field, index) => (
                  <SchemaEditRow
                    key={field.id}
                    checkNameUnique={checkNameUnique}
                    displaySensitiveToggle={isShowingInputSchema}
                    errorStatus={
                      formMethods.formState.errors.properties?.at?.(
                        index,
                      ) as Merge<FieldError, FieldErrorsImpl<PropertyUIT>>
                    }
                    fieldIndex={index}
                    id={field.id}
                    property={field}
                    readonly={isReadonly}
                    type={type}
                    onDelete={() => remove(index)}
                  />
                ))}
            </FormProvider>
          </SortableContext>
        </DndContext>
        {fields.length > 0 && (
          <div className="mb-8 mt-5">
            <Button
              dataLoc="add-schema-field"
              disabled={isReadonly}
              iconLeft={faAdd}
              variant="secondary"
              fullWidth
              onClick={onAddField}
            >
              Add field
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
