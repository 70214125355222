import { useQuery } from "@tanstack/react-query";
import { Edge } from "reactflow";

import {
  DecisionHistoryIntegrationDuration,
  DecisionHistoryRecordV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowRunnerResultV2, GraphRunResultType } from "src/api/types";
import {
  BeMappedNode,
  IntegrationLatencyData,
  NodeRunStateV2,
} from "src/constants/NodeDataTypes";
import { queryClient } from "src/queryClient";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const clearedState: NodeRunStateV2 = { type: "not-run" } as const;

export const nodeRunStatesKeys = {
  all: ["nodeRunStates"] as const,
  flowVersion: (flowVersionId: string) => [
    ...nodeRunStatesKeys.all,
    flowVersionId,
  ],
};
export const getNodeRunState = (nodeId: string, flowVersionId: string) => {
  return queryClient
    .getQueryData<
      Map<string, NodeRunStateV2>
    >(nodeRunStatesKeys.flowVersion(flowVersionId))
    ?.get(nodeId);
};

const updateNodeRunStates = (
  runStates: Map<string, NodeRunStateV2>,
  versionId: string,
) => {
  queryClient.setQueryData(nodeRunStatesKeys.flowVersion(versionId), runStates);
};

export const useNodeRunState = (
  nodeIdOrAlias: string,
): NodeRunStateV2 | undefined => {
  const nodeRunStates = useNodeRunStates();
  return nodeRunStates.get(nodeIdOrAlias);
};

export const useNodeRunStates = (): Map<string, NodeRunStateV2> => {
  const { version } = useAuthoringContext();
  const query = useQuery<Map<string, NodeRunStateV2>>({
    initialData: new Map<string, NodeRunStateV2>(),
    queryKey: nodeRunStatesKeys.flowVersion(version.id),
    queryFn: () => new Map<string, NodeRunStateV2>(),
    enabled: false,
    gcTime: 0,
  });
  // Because this is a fake query only used to store data,
  // we don't need to expose its query properties but only the data.
  return query.data;
};

const flowOutputKey = ["flowOutput"] as const;

export const updateFlowOutput = (runState: NodeRunStateV2 | undefined) => {
  if (runState === undefined) {
    // Setting the query's data to undefined did not seem to work, so reseting it instead
    queryClient.resetQueries({ queryKey: flowOutputKey });
  } else {
    queryClient.setQueryData(flowOutputKey, runState);
  }
};

export const useFlowOuput = (): NodeRunStateV2 | undefined => {
  const query = useQuery<NodeRunStateV2 | undefined>({
    initialData: undefined,
    queryKey: flowOutputKey,
    queryFn: () => undefined,
    enabled: false,
    gcTime: 0,
  });
  // Because this is a fake query only used to store data,
  // we don't need to expose its query properties but only the data.
  return query.data;
};

export const clearRunStates = (
  nodesArray: BeMappedNode[],
  versionId: string,
) => {
  const updatedRunStates = new Map<string, NodeRunStateV2>();
  nodesArray.forEach((node) => {
    updatedRunStates.set(node.id, clearedState);
  });
  updateNodeRunStates(updatedRunStates, versionId);
  updateFlowOutput(undefined);
};

const NODE_ALIASES = ["output", "output_parent"] as const;

const getIntegrationLatencyData = (
  nodeId: string,
  integrationDurations: DecisionHistoryIntegrationDuration[] | undefined,
  isErrored: boolean,
): IntegrationLatencyData | undefined => {
  if (!integrationDurations) return undefined;
  const integrationLatency = integrationDurations.find(
    (duration) =>
      duration.node_id === nodeId && duration.type === "integration",
  );
  if (!integrationLatency) return undefined;
  return {
    duration: integrationLatency.provider_response_time,
    integrationStatus: integrationLatency.provider_response_status,
    isErrored,
  };
};

const getNodeDuration = (
  node: BeMappedNode,
  nodeDurationMap: { [k: string]: number | undefined },
): IntegrationLatencyData | undefined => {
  const nodeDuration = nodeDurationMap[node.id];
  if (nodeDuration !== undefined)
    return {
      duration: nodeDuration,
      isErrored: false,
      integrationStatus: "success",
    };
  else return undefined;
};

export const setResults = (
  nodesArray: BeMappedNode[],
  edgesArray: Edge[],
  result: FlowRunnerResultV2 | DecisionHistoryRecordV2,
  versionId: string,
) => {
  // Is Decision history check. Not moved into a variable to have the condition effect Typescript
  if ("node_results" in result) {
    updateFlowOutput(undefined);

    const nodeDurationMap = Object.fromEntries(
      Object.entries(result.node_results.nodes).map(([nodeId, data]) => {
        const nodeDuration =
          data.tracing.start_time && data.tracing.end_time
            ? new Date(data.tracing.end_time).getTime() -
              new Date(data.tracing.start_time).getTime()
            : undefined;
        return [nodeId, nodeDuration];
      }),
    );

    // Error case
    if (result.error) {
      const error = result.error;
      if (!error) {
        logger.error("Decision history error is not providing details");
        clearRunStates(nodesArray, versionId);
        return;
      }
      const updatedRunStates = new Map<string, NodeRunStateV2>();

      error.integration_durations = result.durations;

      nodesArray.forEach((node: BeMappedNode) => {
        let integrationLatencyData = getIntegrationLatencyData(
          node.id,
          result.durations,
          node.id === error.node_id,
        );
        // Latency data to visualize node duration in debug mode (faking integration)
        if (
          !integrationLatencyData &&
          isFeatureFlagEnabled(FEATURE_FLAGS.debugNodeLatency)
        ) {
          integrationLatencyData = getNodeDuration(node, nodeDurationMap);
        }

        if (node.id === error.node_id) {
          updatedRunStates.set(node.id, {
            type: "historical-error",
            error: error,
            nodeExecutionMetadata: result.node_results.node_execution_metadata,
            decisionId: result.id,
            integrationLatencyData,
          });
        } else if (
          result.node_results.nodes &&
          node.id in result.node_results.nodes
        ) {
          updatedRunStates.set(node.id, {
            type: "historical-data",
            nodeExecutionMetadata: result.node_results.node_execution_metadata,
            decisionId: result.id,
            integrationLatencyData,
          });
        } else {
          updatedRunStates.set(node.id, {
            type: "not-run",
          });
        }
      });
      updateNodeRunStates(updatedRunStates, versionId);
      return;
    }
    //Pending or successful case
    const updatedRunStates = new Map<string, NodeRunStateV2>();
    nodesArray.forEach((node: BeMappedNode) => {
      const nodeHasResults = !!result.node_results.nodes[node.id];
      const outgoingEdges = edgesArray.filter(
        (edge) => edge.source === node.id,
      );
      const followingNodesExistAndHaveNoResults =
        outgoingEdges.length > 0 &&
        outgoingEdges.every(
          (outgoingEdge) => !result.node_results.nodes[outgoingEdge.target],
        );
      let integrationLatencyData = getIntegrationLatencyData(
        node.id,
        result.durations,
        false,
      );
      // Latency data to visualize node duration in debug mode (faking integration)
      if (
        !integrationLatencyData &&
        isFeatureFlagEnabled(FEATURE_FLAGS.debugNodeLatency)
      ) {
        integrationLatencyData = getNodeDuration(node, nodeDurationMap);
      }

      const newRunState: NodeRunStateV2 = nodeHasResults
        ? followingNodesExistAndHaveNoResults
          ? {
              type: "historical-pending",
              decisionId: result.id,
            }
          : {
              type: "historical-data",
              nodeExecutionMetadata:
                result.node_results.node_execution_metadata,
              decisionId: result.id,
              integrationLatencyData,
            }
        : { type: "not-run" };

      updatedRunStates.set(node.id, newRunState);
    });
    updateNodeRunStates(updatedRunStates, versionId);
  } else {
    // is FlowRunnerResultV2
    if (result.type === GraphRunResultType.SUCCESS) {
      const updatedRunStates = new Map<string, NodeRunStateV2>();
      const flowOutput = result.nodes.output;
      updateFlowOutput({
        type: "test-run",
        testResult: flowOutput,
      });

      const getRunState = (nodeIdOrAlias: string): NodeRunStateV2 => {
        const nodeResults = result.nodes[nodeIdOrAlias];
        return nodeResults
          ? {
              type: "test-run",
              testResult: nodeResults,
            }
          : { type: "not-run" };
      };

      nodesArray.forEach((node: BeMappedNode) => {
        updatedRunStates.set(node.id, getRunState(node.id));
      });
      // We have aliases in the run test results,
      // so we add them to the run states
      // to have quick access to data
      NODE_ALIASES?.forEach((alias) => {
        updatedRunStates.set(alias, getRunState(alias));
      });
      updateNodeRunStates(updatedRunStates, versionId);
      return;
    } else {
      const updatedRunStates = new Map<string, NodeRunStateV2>();
      updateFlowOutput(undefined);
      nodesArray.forEach((node: BeMappedNode) => {
        let nodeError = result.errors?.find((e) => e.node_id === node.id);
        if (!nodeError && result.error.node_id === node.id) {
          nodeError = result.error;
        }
        const newRunState: NodeRunStateV2 = nodeError
          ? {
              type: "test-error",
              error: nodeError,
            }
          : { type: "not-run" };
        updatedRunStates.set(node.id, newRunState);
      });
      updateNodeRunStates(updatedRunStates, versionId);
      return;
    }
  }
};
