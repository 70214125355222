import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { CustomInvokeCodeEditor } from "src/aiNode/CustomInvokeCodeEditor";
import { EnvironmentConfig } from "src/aiNode/editorComponents/EnvironmentConfig";
import { TagsInput } from "src/aiNode/editorComponents/TagsInput";
import { AiNodeV2Form } from "src/aiNode/types";
import { FieldErrorsT } from "src/api/types";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { Divider } from "src/base-components/Divider";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { TimeoutInput } from "src/baseConnectionNode/TimeoutInput";
import {
  handleIntegerInput,
  handleNumericInput,
} from "src/baseConnectionNode/utils";
import { AiNodeV2ResponseFormatConfigTypeEnum } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";

type AdvancedConfigPropsT = {
  immutable: boolean;
  isReactive: boolean;
  runFieldErrors?: FieldErrorsT;
};

type InferenceConfigPropsT = AdvancedConfigPropsT & {
  isOutputStructured: boolean;
};

const SYSTEM_PROMPT_PLACEHOLDER =
  "e.g. You are a specialized credit risk analyst working within a regulated financial institution. Your role is to analyse $data.applicant_attribute of credit applications.";

const STOP_SEQUENCE_DESCRIPTION =
  "Specify text patterns that signal the AI to stop generating output. You can add up to four stop sequences.";

const TEMPERATURE_DESCRIPTION =
  "Adjust the level of randomness in the AI's output. Lower values (closer to 0) create precise and consistent responses, while higher values (closer to 1) make them more creative and varied.";

const MAX_TOKENS_DESCRIPTION =
  "Limit the length of the AI's response. Higher values allow for longer outputs but may increase response times.";

const SystemPromptConfig: React.FC<AdvancedConfigPropsT> = ({
  immutable,
  isReactive,
  runFieldErrors,
}) => {
  const { watch, control, getValues } = useFormContext<AiNodeV2Form>();
  const systemPromptEnabled = watch("prompts.system_prompt.active");

  const errorMessage = runFieldErrors?.[getValues("prompts.system_prompt.id")];

  return (
    <div data-loc="ai-node-config-system-prompt">
      <div className="items-top flex justify-between gap-x-2">
        <div>
          <h3 className="text-gray-800 font-inter-semibold-13px">
            Add system prompt
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Provide additional context or a role to guide how the AI approaches
            the task. Use '$' to include Flow data (e.g. '$data.credit_report')
            from your Decision Flow.
          </span>
        </div>
        <div>
          <Controller
            control={control}
            name="prompts.system_prompt.active"
            render={({ field: controlledField }) => (
              <Switch
                dataLoc="ai-node-config-system-prompt-toggle"
                disabled={immutable}
                enabled={controlledField.value}
                onChange={() => {
                  controlledField.onChange(!controlledField.value);
                }}
              />
            )}
          />
        </div>
      </div>
      {systemPromptEnabled && (
        <div className={twJoin(errorMessage && "border-red-400")}>
          <Controller
            control={control}
            name="prompts.system_prompt.expression"
            render={({ field }) => (
              <div className="mt-2 h-80">
                <CustomInvokeCodeEditor
                  errorMessage={errorMessage}
                  immutable={immutable}
                  isReactive={isReactive}
                  placeholder={SYSTEM_PROMPT_PLACEHOLDER}
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

const StopSequenceConfig: React.FC<
  Omit<InferenceConfigPropsT, "isReactive">
> = ({ immutable, isOutputStructured }) => {
  const { control } = useFormContext<AiNodeV2Form>();

  return (
    <div data-loc="ai-node-config-stop-sequence">
      <FormItem>
        <FormItem.Label description={STOP_SEQUENCE_DESCRIPTION}>
          Stop sequences
        </FormItem.Label>
        <Controller
          control={control}
          name="inference.stop_sequences"
          render={({ field: controlledField }) => (
            <Tooltip
              disabled={!isOutputStructured}
              title='You cannot configure "Stop sequences" if "Structured output" is enabled'
              asChild
            >
              <div className="w-full">
                <TagsInput
                  disabled={immutable || isOutputStructured}
                  dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
                  maxItems={4}
                  placeholder={
                    isOutputStructured ? "N/A" : "e.g. '<|endoftext|>'"
                  }
                  value={
                    isOutputStructured ? [] : (controlledField.value ?? [])
                  }
                  onChange={controlledField.onChange}
                />
              </div>
            </Tooltip>
          )}
        />
      </FormItem>
    </div>
  );
};

const MaxTokensConfig: React.FC<Omit<InferenceConfigPropsT, "isReactive">> = ({
  immutable,
  isOutputStructured,
}) => {
  const { control, getValues } = useFormContext<AiNodeV2Form>();

  const currentValue = getValues("inference.max_tokens");
  const [isErrored, setIsErrored] = useState(
    currentValue != null && currentValue < 1,
  );

  return (
    <div data-loc="ai-node-config-max-tokens">
      <FormItem>
        <FormItem.Label description={MAX_TOKENS_DESCRIPTION}>
          Max tokens
        </FormItem.Label>
        <Controller
          control={control}
          name="inference.max_tokens"
          render={({ field: controlledField }) => {
            const showTooltip = isOutputStructured || isErrored;
            let tooltipText =
              "The configured value must be an integer greater than zero";
            if (isOutputStructured) {
              tooltipText =
                'You cannot configure "Max tokens" if "Structured output" is enabled';
            }

            return (
              <Tooltip disabled={!showTooltip} title={tooltipText} asChild>
                <Input
                  disabled={immutable || isOutputStructured}
                  errored={isErrored}
                  placeholder={isOutputStructured ? "N/A" : "e.g. 1000"}
                  suffixIcon={
                    isErrored
                      ? {
                          icon: faExclamationTriangle,
                          variant: "danger",
                        }
                      : undefined
                  }
                  value={
                    isOutputStructured ? "" : (controlledField.value ?? "")
                  }
                  fullWidth
                  onChange={(event) => {
                    const newValue = event.target.value;

                    if (newValue === "") {
                      controlledField.onChange(null);
                      setIsErrored(false);
                      return;
                    }

                    const parsedNewValue = Number(newValue);
                    controlledField.onChange(parsedNewValue);
                    setIsErrored(parsedNewValue < 1);
                  }}
                  onKeyDown={handleIntegerInput}
                />
              </Tooltip>
            );
          }}
        />
      </FormItem>
    </div>
  );
};

const TemperatureConfig: React.FC<
  Omit<InferenceConfigPropsT, "isReactive">
> = ({ immutable, isOutputStructured }) => {
  const { control, getValues } = useFormContext<AiNodeV2Form>();

  const currentValue = getValues("inference.temperature");
  const [isErrored, setIsErrored] = useState(
    currentValue != null && (currentValue < 0 || currentValue > 1),
  );

  return (
    <div data-loc="ai-node-config-temperature">
      <FormItem>
        <FormItem.Label description={TEMPERATURE_DESCRIPTION}>
          Temperature
        </FormItem.Label>
        <Controller
          control={control}
          name="inference.temperature"
          render={({ field: controlledField }) => {
            const showTooltip = isOutputStructured || isErrored;
            let tooltipText =
              "The configured value must be a number between 0 and 1";
            if (isOutputStructured) {
              tooltipText =
                'You cannot configure "Temperature" if "Structured output" is enabled';
            }

            return (
              <Tooltip disabled={!showTooltip} title={tooltipText} asChild>
                <Input
                  disabled={immutable || isOutputStructured}
                  errored={isErrored}
                  placeholder={isOutputStructured ? "N/A" : "e.g. 0.5"}
                  suffixIcon={
                    isErrored
                      ? {
                          icon: faExclamationTriangle,
                          variant: "danger",
                        }
                      : undefined
                  }
                  value={
                    isOutputStructured ? "" : (controlledField.value ?? "")
                  }
                  fullWidth
                  onChange={(event) => {
                    const newValue = event.target.value;

                    if (newValue === "") {
                      controlledField.onChange(null);
                      setIsErrored(false);
                      return;
                    }

                    if (newValue.slice(-1) === ".") {
                      controlledField.onChange(newValue);
                      setIsErrored(true);
                      return;
                    }

                    if (/^0\.0+$/.test(newValue)) {
                      controlledField.onChange(newValue);
                      setIsErrored(false);
                      return;
                    }

                    const parsedNewValue = Number(newValue);
                    controlledField.onChange(parsedNewValue);
                    setIsErrored(parsedNewValue < 0 || parsedNewValue > 1);
                  }}
                  onKeyDown={handleNumericInput}
                />
              </Tooltip>
            );
          }}
        />
      </FormItem>
    </div>
  );
};

const InferenceConfig: React.FC<InferenceConfigPropsT> = ({
  immutable,
  isReactive,
  isOutputStructured,
  runFieldErrors,
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <SystemPromptConfig
        immutable={immutable}
        isReactive={isReactive}
        runFieldErrors={runFieldErrors}
      />
      <StopSequenceConfig
        immutable={immutable}
        isOutputStructured={isOutputStructured}
      />
      <MaxTokensConfig
        immutable={immutable}
        isOutputStructured={isOutputStructured}
      />
      <TemperatureConfig
        immutable={immutable}
        isOutputStructured={isOutputStructured}
      />
    </div>
  );
};

const EnvConfig: React.FC<Omit<AdvancedConfigPropsT, "isReactive">> = ({
  immutable,
}) => {
  return <EnvironmentConfig disabled={immutable} />;
};

const ExecutionConfig: React.FC<Omit<AdvancedConfigPropsT, "isReactive">> = ({
  immutable,
}) => {
  const formProps = useFormContext<AiNodeV2Form>();
  const { control, getValues } = formProps;
  const isTimeoutActive = getValues("config.timeout.active");

  return (
    <div className="flex w-full flex-col gap-y-4">
      <div data-loc="ai-node-config-timeout">
        <div className="items-top flex justify-between gap-x-2">
          <div>
            <h3 className="text-gray-800 font-inter-semibold-13px">
              Use custom request timeout
            </h3>
            <span className="text-gray-500 font-inter-normal-12px">
              Enter the maximum number of seconds before timing out the request
            </span>
          </div>
          <Controller
            control={control}
            name="config.timeout.active"
            render={({ field: controlledField }) => (
              <Switch
                dataLoc="ai-node-config-timeout-toggle"
                disabled={immutable}
                enabled={controlledField.value}
                onChange={() => {
                  controlledField.onChange(!controlledField.value);
                }}
              />
            )}
          />
        </div>
        {isTimeoutActive && (
          <div className="flex w-full flex-row items-center justify-between gap-x-2 rounded-lg bg-gray-50 px-5 py-4">
            <div className="text-gray-700 font-inter-normal-12px">
              Time out after (seconds)
            </div>
            <TimeoutInput
              dataLoc="ai-node-timeout-seconds"
              formProps={formProps.register("config.timeout.timeout_seconds")}
              immutable={immutable}
              unsetValue={null}
            />
          </div>
        )}
      </div>
      <div data-loc="ai-node-config-skip-on-error">
        <div className="items-top flex justify-between gap-x-2">
          <div>
            <h3 className="text-gray-800 font-inter-semibold-13px">
              Skip AI Node on timeout/usage limit exhaustion
            </h3>
            <span className="text-gray-500 font-inter-normal-12px">
              In case the AI Node times out, or your usage limits are exhausted,
              the flow will continue executing with all AI Node response fields
              set to `None`
            </span>
          </div>
          <Controller
            control={control}
            name="config.error.allow_errors"
            render={({ field: controlledField }) => (
              <Switch
                dataLoc="ai-node-config-skip-on-error-toggle"
                disabled={immutable}
                enabled={controlledField.value}
                onChange={() => {
                  controlledField.onChange(!controlledField.value);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export const AdvancedConfigV2: React.FC<AdvancedConfigPropsT> = ({
  immutable,
  isReactive,
  runFieldErrors,
}) => {
  const formProps = useFormContext<AiNodeV2Form>();
  const responseType = formProps.getValues("inference.response_format.type");

  const isOutputStructured =
    responseType === AiNodeV2ResponseFormatConfigTypeEnum.JSON;

  return (
    <div className="flex w-full flex-col gap-y-4">
      <InferenceConfig
        immutable={immutable}
        isOutputStructured={isOutputStructured}
        isReactive={isReactive}
        runFieldErrors={runFieldErrors}
      />
      <Divider spacing="my-2" />
      <EnvConfig immutable={immutable} />
      <Divider spacing="my-2" />
      <ExecutionConfig immutable={immutable} />
    </div>
  );
};
