export const getRandomIntFromInterval = (min: number, max: number) => {
  // Using crypto.getRandomValues() for cryptographically secure random numbers
  const randomInt = getCryptoRandomInt();
  // Map the 32-bit unsigned integer to our desired range
  return Math.floor((randomInt / (0xffffffff + 1)) * (max - min + 1) + min);
};

export const getCryptoRandomInt = () => {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
};
