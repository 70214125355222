import {
  ColumnDef,
  HeaderGroup,
  Row,
  Table,
  getCoreRowModel,
  useReactTable,
  flexRender,
  Cell,
  Column,
  RowData,
} from "@tanstack/react-table";
import { omit, times } from "lodash";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { VirtualItem, useVirtual } from "react-virtual";
import { twJoin, twMerge } from "tailwind-merge";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { GenericObjectT } from "src/api/flowTypes";
import { URLKeys } from "src/router/SearchParams";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableMeta<TData extends RowData> {
    currentDecision?: null | DecisionHistoryRecordV2<GenericObjectT>;
  }
}

const getNextPageSkeleton = (
  rowCount: number,
  columnsCount: number,
  skeletonRowsClassnames: string[],
) =>
  times(rowCount, (index) => {
    return (
      <tr key={index} className="z-0 border-y border-gray-200">
        {Array.from(Array(columnsCount)).map((_, i) => (
          <td key={i} className={CELL_CLASS_NAME}>
            <div
              className={twJoin(
                "flex h-11 items-center overflow-hidden py-1.5",
                skeletonRowsClassnames.at(i) ?? "w-0",
              )}
            >
              <div className="relative h-5 w-full overflow-hidden rounded bg-gray-100">
                <div
                  className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
                  style={{ animation: "shimmer 1s infinite" }}
                />
              </div>
            </div>
          </td>
        ))}
      </tr>
    );
  });

type RowProps = GenericObjectT;

type PropsT<ResponseType extends GenericObjectT> = {
  tableRef?: React.MutableRefObject<Table<
    DecisionHistoryRecordV2<ResponseType>
  > | null>;
  data: DecisionHistoryRecordV2<ResponseType>[];
  columns: ColumnDef<DecisionHistoryRecordV2<ResponseType>, any>[];
  frameClassName: string;
  rowPropsGetter: (
    rowData: Row<DecisionHistoryRecordV2<ResponseType>>,
    table: Table<DecisionHistoryRecordV2<ResponseType>>,
  ) => RowProps;
  cellPropsGetter?: (
    cell: Cell<DecisionHistoryRecordV2<ResponseType>, unknown>,
    rowData: Row<DecisionHistoryRecordV2<ResponseType>>,
    table: Table<DecisionHistoryRecordV2<ResponseType>>,
  ) => RowProps;
  headerCellPropsGetter?: (
    columns: Column<DecisionHistoryRecordV2<ResponseType>, unknown>,
  ) => RowProps;
  renderTableHeader?: (
    tableInstance: Table<DecisionHistoryRecordV2<ResponseType>>,
  ) => React.ReactNode;
  handleRowClick: (
    row: Row<DecisionHistoryRecordV2<ResponseType>>,
    table: Table<DecisionHistoryRecordV2<ResponseType>>,
  ) => void;
  dataLoc?: string;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  skeletonRowsClassnames: string[];
  currentDecision?: null | DecisionHistoryRecordV2<ResponseType>;
};

const CELL_CLASS_NAME = "border-b";
const ROW_CLASS_NAMES = "[&:not(:last-child)]:border-y group";
const HEADER_CLASS_NAMES = "sticky top-0 bg-white z-[1]";

const useTraceDecisionId = (
  traceDecisionId: (decisionId: string) => boolean,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsDecisionId = searchParams.get(URLKeys.DecisionId);
  // Make sure we trace decision from URL only once after render
  // If the decisionId is not in the URL at the moment of the first render, we don't need to trace it
  const decisionIdHasBeenTraced = useRef(searchParamsDecisionId ? false : true);

  // Display the decision history record that matches the decisionId in the URL
  // After tracing mark it as traced and don't trace it again
  useEffect(() => {
    if (searchParamsDecisionId && !decisionIdHasBeenTraced.current) {
      decisionIdHasBeenTraced.current = traceDecisionId(searchParamsDecisionId);
    }
  }, [searchParamsDecisionId, searchParams, setSearchParams, traceDecisionId]);
};

export const TableComp = <ResponseType extends GenericObjectT>({
  tableRef,
  data,
  columns,
  frameClassName,
  rowPropsGetter,
  cellPropsGetter,
  headerCellPropsGetter,
  handleRowClick,
  dataLoc,
  canFetchNextPage,
  fetchNextPage,
  isFetching,
  skeletonRowsClassnames,
  currentDecision,
}: PropsT<ResponseType>) => {
  const fetchMoreOnCloseToBottom = (
    containerRefElement?: HTMLDivElement | null,
  ) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //once the user has scrolled within 1000px of the bottom of the table, fetch more data if there is any
      if (scrollHeight - scrollTop - clientHeight < 1000 && canFetchNextPage) {
        fetchNextPage();
      }
    }
  };
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { size: undefined },
    meta: {
      currentDecision,
    },
  });

  if (tableRef) {
    tableRef.current = tableInstance;
  }

  const traceDecisionId = (decisionId: string): boolean => {
    const rows = tableInstance.getRowModel().rows;
    const selectedRow = rows.find(
      (row) =>
        row.original.id === decisionId ||
        row.original.parent_decision_id === decisionId,
    );
    if (selectedRow) {
      if (!rowPropsGetter(selectedRow, tableInstance).isDisabled) {
        handleRowClick?.(selectedRow, tableInstance);
      }
      // Found the row, mark it as traced
      return true;
    }
    return false;
  };

  useTraceDecisionId(traceDecisionId);

  const { getHeaderGroups } = tableInstance;

  const headerGroups = getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;

  const renderHeaderGroup = (
    headerGroup: HeaderGroup<DecisionHistoryRecordV2<ResponseType>>,
  ) => {
    return (
      <tr key={headerGroup.id} className={HEADER_CLASS_NAMES}>
        {headerGroup.headers.map((column) => {
          const ctx = column.getContext();
          const headerCellProps = headerCellPropsGetter?.(ctx.column);

          return (
            <th
              key={column.id}
              className={twMerge(
                CELL_CLASS_NAME,
                headerCellProps?.classNameOverrides,
              )}
              style={{
                minWidth: ctx.column.columnDef.minSize,
                maxWidth: ctx.column.columnDef.maxSize,
                width: ctx.column.columnDef.size ? column.getSize() : undefined,
              }}
              {...omit(headerCellProps, "classNameOverrides")}
            >
              {flexRender(column.column.columnDef.header, column.getContext())}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRow = (
    row: Row<DecisionHistoryRecordV2<ResponseType>>,
    virtualRow: VirtualItem,
  ) => {
    const rowProps = rowPropsGetter?.(row, tableInstance);
    return (
      <tr
        key={row.id}
        ref={virtualRow.measureRef}
        className={twMerge(ROW_CLASS_NAMES, rowProps?.classNameOverrides)}
        {...omit(rowProps, "classNameOverrides")}
      >
        {row.getVisibleCells().map((cell) => {
          const cellProps = cellPropsGetter?.(cell, row, tableInstance);
          return (
            <td
              key={cell.id}
              className={twMerge(
                CELL_CLASS_NAME,
                cellProps?.classNameOverrides,
              )}
              {...omit(cellProps, "classNameOverrides")}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  };

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize: totalRowSize } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);

  return (
    <div
      ref={tableContainerRef}
      className={twMerge(
        "decideScrollbar h-full overflow-auto",
        frameClassName,
      )}
      data-loc={dataLoc}
      onScroll={(e) => fetchMoreOnCloseToBottom(e.target as HTMLDivElement)}
    >
      <table className="w-full table-auto border-separate border-spacing-0 overflow-visible">
        <thead>{headerGroups.map(renderHeaderGroup)}</thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            return renderRow(row, virtualRow);
          })}
          {isFetching &&
            getNextPageSkeleton(
              data.length === 0 ? 20 : 3,
              columns.length,
              skeletonRowsClassnames,
            )}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
