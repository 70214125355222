import {
  faArrowDownToLine,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { endOfToday, startOfToday, sub } from "date-fns";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";
import { validate as isValidUUID } from "uuid";

import { AuditLogsTable } from "src/auditLogs/Table";
import {
  useAuditLogsEvents,
  useCreateAuditLogExport,
} from "src/auditLogs/queries";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { SubHeader } from "src/flow/SubHeader";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { maxTableWidth } from "src/layout/constants";
import { logger } from "src/utils/logger";
import { useTimeWindow } from "src/utils/timeWindow";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const PAGE_SIZE = 100;

export const AuditLogsPage: React.FC = () => {
  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      {
        from: sub(startOfToday(), { months: 1 }),
        to: endOfToday(),
      },
      "audit-logs-time-window",
    );

  const [selectedActor, setSelectedActor] = useState<{
    id: string;
    source: "search_bar" | "filter";
  } | null>(null);

  const { orgId } = useParamsDecode<{ orgId: string }>();

  const {
    data: pages,
    isRefetching,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useAuditLogsEvents({
    organizationId: orgId,
    timeWindow,
    pageSize: PAGE_SIZE,
    actorId: selectedActor?.id,
  });

  const events = pages ? pages.pages.flatMap((page) => page.events) : [];

  const debouncedSetSelectedActor = useDebouncedCallback(
    (actor: { id: string; source: "search_bar" | "filter" }) => {
      setSelectedActor(actor);
    },
    1000,
  );

  const createExportMutation = useCreateAuditLogExport();

  const emitChangeAuditLogFilters = () => {
    tracker.emit(
      trackingEvents.changeAuditLogFilters({
        start_time: timeWindow.from.toISOString(),
        end_time: timeWindow.to.toISOString(),
        actor_id: selectedActor?.id,
        organization_id: orgId,
      }),
    );
  };

  return (
    <div className="h-full bg-gray-100">
      <SubHeader title="Audit log">
        <SubHeader.SearchBox
          dataLoc="audit-logs-search-box"
          placeholder="Search by actor ID..."
          onChange={(value) => {
            if (!value) {
              emitChangeAuditLogFilters();
              return setSelectedActor(null);
            }
            if (isValidUUID(value)) {
              emitChangeAuditLogFilters();
              return debouncedSetSelectedActor({
                id: value,
                source: "search_bar",
              });
            }
          }}
        />
        <SubHeader.DatePicker
          value={dateRangePickerValue}
          onChange={(value) => {
            emitChangeAuditLogFilters();
            onDateRangePickerChange(value);
          }}
        />
        <SubHeader.Button
          disabled={isRefetching || isLoading}
          icon={faRefresh}
          spin={isRefetching || isLoading}
          tooltip="Audit logs can take a few minutes to appear"
          onClick={refetch}
        />
        <SubHeader.Button
          dataLoc="audit-logs-download-button"
          disabled={createExportMutation.isPending}
          icon={faArrowDownToLine}
          tooltip="Download audit logs with the selected filters"
          onClick={async () => {
            try {
              const auditExport = await createExportMutation.mutateAsync({
                organizationId: orgId,
                timeWindow,
                actorId: selectedActor?.id,
              });
              tracker.emit(
                trackingEvents.downloadAuditLogFile({
                  start_time: timeWindow.from.toISOString(),
                  end_time: timeWindow.to.toISOString(),
                  actor_id: selectedActor?.id,
                  organization_id: orgId,
                }),
              );
              window.open((auditExport.response as any).s3_url, "_blank");
            } catch (err) {
              logger.error(err);
              toastActions.failure({
                title: `Failed to export Audit logs`,
                description: TAKTILE_TEAM_NOTIFIED,
              });
            }
          }}
        />
      </SubHeader>

      <div className="h-[calc(100%-56px)] px-8 py-8">
        <div
          className={twJoin(
            "relative mx-auto flex h-full rounded-lg border border-gray-200 bg-white pt-1",
            maxTableWidth,
          )}
        >
          <AuditLogsTable
            data={events}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isRefetching || isLoading}
            selectedActorId={
              selectedActor?.source === "filter" ? selectedActor.id : null
            }
            onActorSelect={(id) => {
              emitChangeAuditLogFilters();
              setSelectedActor(id ? { id, source: "filter" } : null);
            }}
            onScrolledToTheBottom={fetchNextPage}
          />
        </div>
      </div>
    </div>
  );
};
