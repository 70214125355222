import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";
import {
  mergeFieldIdAndName,
  RunErrorFieldNames,
} from "src/utils/FieldErrorUtils";

type DefaultValuePropsT = {
  index: number;
  name: string;
  immutable: boolean;
  lazyInputs: boolean;
  fieldError: string | undefined;
};

const DefaultValue: React.FC<DefaultValuePropsT> = ({
  index,
  name,
  immutable,
  lazyInputs,
  fieldError,
}) => {
  const { control } = useFormContext<RuleNodeV2FormT>();

  return (
    <div className="flex items-center gap-x-2 text-xs text-gray-700">
      <span className="font-inter-medium-12px">Default value of</span>
      <Tooltip placement="top" title={`data.${name}`}>
        <div className="max-w-50">
          <Pill variant="green" fullWidth>
            <Pill.Text>data.{name}</Pill.Text>
          </Pill>
        </div>
      </Tooltip>
      <span className="font-inter-medium-12px">is</span>
      <Controller
        control={control}
        name={`default_effects.${index}.value`}
        render={({ field }) => (
          <AutocompleteCodeInput
            dataLoc={`rule-node-v2-default-effects-${index}-value`}
            disabled={immutable}
            error={fieldError}
            lazy={lazyInputs}
            placeholder='Strings should be quoted, e.g. "Decide"'
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

type DefaultValuesCardPropsT = {
  immutable: boolean;
  fieldErrors: FieldErrorsT | undefined;
  lazyInputs: boolean;
};

export const DefaultValuesCard: React.FC<DefaultValuesCardPropsT> = ({
  immutable,
  lazyInputs,
  fieldErrors,
}) => {
  const { getValues } = useFormContext<RuleNodeV2FormT>();
  const defaultEffects = getValues().default_effects;

  return (
    <Card className="w-full space-y-3">
      <Card.Header>
        <Card.Title title="Default values" />
      </Card.Header>
      <Card.Content>
        <div className="h-px rounded-full bg-gray-200" />
        {defaultEffects.map(({ id, target }, index) => (
          <DefaultValue
            key={id}
            fieldError={
              fieldErrors?.[
                mergeFieldIdAndName(
                  defaultEffects.at(index)?.id,
                  RunErrorFieldNames.RIGHT,
                )
              ]
            }
            immutable={immutable}
            index={index}
            lazyInputs={lazyInputs}
            name={target}
          />
        ))}
      </Card.Content>
    </Card>
  );
};
