import {
  faCalculatorSimple,
  faSearch,
  faPlus,
  faMinus,
  faBuilding,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CellContext,
  Column,
  createColumnHelper,
  RowData,
} from "@tanstack/react-table";
import { capitalize, isString, uniq } from "lodash";
import React, { useMemo, useState } from "react";

import { ColumnFilter } from "src/base-components/ColumnFilter";
import { HighlightedText } from "src/base-components/HighlightedText";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { mdService } from "src/base-components/TextEditor/MarkdownService";
import { FeatureQueryStatus } from "src/clients/features-control/api";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { FeatureActionsMenu } from "src/featureCatalogue/Features/FeatureActionsMenu";
import { QueryStatusPill } from "src/featureCatalogue/Queries/QueryStatusPill";
import { useFeatures } from "src/featureCatalogue/queries";
import {
  ENTITY_ICON_MAP,
  Feature,
  FeatureRow,
  commonColumns,
} from "src/featureCatalogue/utils";
import { WhitePane } from "src/jobs/common/WhitePane";
import { getTypeIcon } from "src/utils/icons";

const columnHelper = createColumnHelper<FeatureRow>();

const HighlightedCellText = <D extends RowData>({
  info,
}: {
  info: CellContext<D, string | null | undefined>;
}) => (
  <HighlightedText
    highlight={info.table.getState().globalFilter}
    highlightClassName="font-inter-semibold-12px bg-yellow-200"
    text={info.getValue()}
  />
);

const getColumns = (env: string) => [
  columnHelper.accessor("name", {
    header: (info) => <Header info={info}>Feature name</Header>,
    cell: (info) => {
      const name = info.getValue();
      return (
        <Cell className="gap-x-0.5" info={info}>
          <Icon
            color="text-gray-500"
            icon={
              info.row.original.feature_type
                ? getTypeIcon(info.row.original.feature_type, faMinus)
                : faMinus
            }
            size="xs"
          />
          <Tooltip placement="top" title={name} asChild>
            <div className="truncate">
              <HighlightedCellText info={info} />
            </div>
          </Tooltip>
        </Cell>
      );
    },
    size: 300,
    maxSize: 300,
    filterFn: "includesString",
  }),
  columnHelper.accessor("description", {
    header: (info) => <Header info={info}>Description</Header>,
    cell: (info) => <DisplayDescriptionCell info={info} />,
    size: 300,
    maxSize: 300,
    filterFn: "includesString",
  }),
  columnHelper.accessor("key", {
    header: (info) => <Header info={info}>Key</Header>,
    cell: (info) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Text>
            <HighlightedCellText info={info} />
          </Pill.Text>
        </Pill>
      </Cell>
    ),
    size: 100,
    filterFn: "includesString",
  }),
  columnHelper.accessor(
    (row) => {
      return row.query?.environment_settings?.[env]?.status;
    },
    {
      id: "environment_settings_status",
      header: (info) => (
        <Header info={info}>
          <div className="flex items-center gap-x-2">
            Status
            <StatusFilter column={info.column} />
          </div>
        </Header>
      ),
      cell: (info) => {
        const currentStatus = info.getValue();

        return currentStatus ? (
          <Cell info={info}>
            <QueryStatusPill status={currentStatus} />
          </Cell>
        ) : null;
      },
      enableGlobalFilter: false,
      filterFn: "equals",
    },
  ),
  columnHelper.accessor("entity_types", {
    header: (info: any) => (
      <Header info={info}>
        <div className="flex items-center gap-x-2">
          Entity
          <EntityFilter column={info.column} />
        </div>
      </Header>
    ),
    cell: (info: any) => (
      <Cell className="flex space-x-2" info={info}>
        {info.getValue()?.map((entity: string) => {
          const icon =
            ENTITY_ICON_MAP[entity as keyof typeof ENTITY_ICON_MAP] ??
            faBuilding;
          return (
            <Pill key={entity} size="sm" variant="gray">
              <Pill.Icon icon={icon} />
              <Pill.Text>{capitalize(entity)}</Pill.Text>
            </Pill>
          );
        })}
      </Cell>
    ),
    filterFn: "includesString",
  }),
  ...commonColumns.map((column) =>
    columnHelper.accessor(`query.${column.accessorKey}` as keyof FeatureRow, {
      header: (info) =>
        column.accessorKey === "event" ? (
          <Header info={info}>
            <div className="flex items-center gap-x-2">
              Event
              <EventTypeFilter column={info.column} />
            </div>
          </Header>
        ) : (
          column.header(info)
        ),
      cell: (info) => (info.row.original.query ? column.cell(info) : null),
      enableGlobalFilter: false,
    }),
  ),
  columnHelper.display({
    id: "actions-menu",
    header: () => null,
    cell: (info) => (
      <Cell className="justify-end" info={info}>
        <FeatureActionsMenu feature={info.row.original} />
      </Cell>
    ),
    enableGlobalFilter: false,
  }),
];

export const Features: React.FC<{
  openCreateModal: () => void;
  searchQuery: string;
}> = ({ openCreateModal, searchQuery }) => {
  const [environment] = useEnvironment();
  const { data: features, isLoading } = useFeatures({ environment });

  const columns = useMemo(() => getColumns(environment), [environment]);

  return (
    <WhitePane>
      <div className="flex w-full flex-col gap-y-4">
        {!isLoading && features?.length === 0 ? (
          <EmptyState
            actionButton={
              !searchQuery
                ? {
                    icon: faPlus,
                    text: "Create Feature",
                    onClick: () => {
                      openCreateModal();
                    },
                  }
                : undefined
            }
            dataLoc="features-empty-state"
            description={
              searchQuery
                ? "Try adjusting your search to find what you're looking for"
                : "Features help you detect patterns and analyze behavior across your entities and events."
            }
            headline={
              searchQuery
                ? "No matching features found"
                : "No features configured yet"
            }
            icon={searchQuery ? faSearch : faCalculatorSimple}
          />
        ) : (
          <TableComp
            columns={columns}
            data={features ?? []}
            dataLoc="features-list"
            frameClassName="w-full p-4"
            globalFilter={searchQuery}
            initialState={{
              sorting: [{ id: "key", desc: true }],
            }}
            isLoading={isLoading}
            rowPropsGetter={(row) => ({
              "data-loc": row.original.key,
            })}
            variant="compact"
            clientSideFiltering
          />
        )}
      </div>
    </WhitePane>
  );
};

export const DisplayDescriptionCell: React.FC<{
  info: CellContext<Feature, string | null | undefined>;
}> = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionValue = info.getValue() ?? "";

  const needsPopover =
    descriptionValue.length > 50 || descriptionValue.includes("\n");

  const firstLineContent = descriptionValue.includes("\n")
    ? `${descriptionValue.split("\n")[0]}...`
    : descriptionValue.length > 50
      ? `${descriptionValue.slice(0, 43)}...`
      : descriptionValue;

  const content = (
    <div className="line-clamp-1 overflow-hidden">
      <div
        //skipcq: JS-0440
        dangerouslySetInnerHTML={{
          __html: mdService.toHtml(
            needsPopover ? firstLineContent : descriptionValue,
          ),
        }}
        className="review-case-description [&>p]:m-0"
      />
    </div>
  );

  return needsPopover ? (
    <CustomPopover
      button={<Cell info={info}>{content}</Cell>}
      className="w-96 overflow-auto border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <div
          //skipcq: JS-0440
          dangerouslySetInnerHTML={{
            __html: mdService.toHtml(descriptionValue),
          }}
          className="review-case-description p-3"
        />
      )}
    </CustomPopover>
  ) : (
    <Cell info={info}>{content}</Cell>
  );
};

const StatusFilter: React.FC<{
  column: Column<FeatureRow>;
}> = ({ column }) => {
  const filters = column.getFilterValue();

  return (
    <ColumnFilter
      description="Status filter:"
      elements={[
        {
          key: FeatureQueryStatus.ACTIVE,
          value: "active",
        },
        {
          key: FeatureQueryStatus.INACTIVE,
          value: "inactive",
        },
      ]}
      isFiltering={column.getIsFiltered()}
      selected={filters}
      onResetRequest={() => column.setFilterValue(undefined)}
      onSelect={column.setFilterValue}
    />
  );
};

const EventTypeFilter: React.FC<{
  column: Column<FeatureRow>;
}> = ({ column }) => {
  const filters = column.getFilterValue();

  const elements = column.getFacetedUniqueValues();

  return (
    <ColumnFilter
      description="Event type filter:"
      elements={Array.from(elements.keys()).map((key) => ({
        key: key,
        value: key,
      }))}
      isFiltering={column.getIsFiltered()}
      selected={filters}
      onResetRequest={() => column.setFilterValue(undefined)}
      onSelect={column.setFilterValue}
    />
  );
};

const EntityFilter: React.FC<{
  column: Column<FeatureRow>;
}> = ({ column }) => {
  const filters = column.getFilterValue();

  const elements = uniq(
    column
      .getFacetedRowModel()
      .flatRows.flatMap((row) => row.original.entity_types)
      .filter(isString),
  );

  return (
    <ColumnFilter
      description="Entity type filter:"
      elements={elements.map((entity) => ({
        key: entity,
        value: entity,
      }))}
      isFiltering={column.getIsFiltered()}
      selected={filters}
      onResetRequest={() => column.setFilterValue(undefined)}
      onSelect={column.setFilterValue}
    />
  );
};
