import { useEffect, useState } from "react";

export const useIsContentOverflows = <T extends HTMLElement>(
  ref: React.RefObject<T>,
) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const updateOverflowState = () => {
      const el = ref.current;

      if (el) {
        setIsOverflowing(Boolean(el.scrollWidth > el.clientWidth));
      }
    };

    updateOverflowState();
    window.addEventListener("resize", updateOverflowState);

    return () => {
      window.removeEventListener("resize", updateOverflowState);
    };
  }, [ref]);

  return isOverflowing;
};
