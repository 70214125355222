import React from "react";
import { twMerge } from "tailwind-merge";

import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  children?: React.ReactNode;
  className?: string;
  dataLoc?: string;
  withTooltip?: boolean;
};

export const IntermediateResultsHeaderCell: React.FC<PropsT> = ({
  children,
  className,
  withTooltip = false,
  dataLoc,
}) => {
  const tooltipWrapper = ({
    children: trigger,
  }: {
    children: React.ReactNode;
  }) => (
    <Tooltip placement="top" title={children} asChild>
      {trigger}
    </Tooltip>
  );
  const Wrapper = withTooltip ? tooltipWrapper : React.Fragment;

  return (
    <Wrapper>
      <div
        className={twMerge(
          "overflow-hidden text-ellipsis py-1.5 pl-2 pr-4 text-left text-gray-800 font-inter-medium-12px",
          className,
        )}
        data-loc={dataLoc}
      >
        {children}
      </div>
    </Wrapper>
  );
};
