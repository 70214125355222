import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import mergeRefs from "merge-refs";
import { forwardRef, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import {
  useIsSelectedCell,
  useIsEditingCell,
} from "src/datasets/DatasetTable/stores";
import { CellId } from "src/datasets/DatasetTable/utils";
import { ErrorDetails } from "src/datasets/components/errorUtils";
import { Tooltip } from "src/design-system/Tooltip";

const STICKY_HEADER_OFFSET = 65;

type CellWrapperProps = {
  children: React.ReactNode;
  cellId: CellId;
  invalid?: boolean;
  disabled?: boolean;
  warning?: boolean;
  error?: ErrorDetails;
  postfix?: React.ReactNode;
};

export const CellWrapper = forwardRef<HTMLDivElement, CellWrapperProps>(
  ({ children, cellId, invalid, disabled, warning, error, postfix }, ref) => {
    const cellRef = useRef<HTMLDivElement>(null);

    const selected = useIsSelectedCell(cellId);
    const editing = useIsEditingCell(cellId);

    useScrollIntoViewIf({
      condition: selected && !editing,
      ref: cellRef,
    });

    return (
      <div
        ref={mergeRefs(cellRef, ref)}
        className={twMerge(
          "h-full w-full max-w-full rounded-none text-gray-800 font-inter-normal-12px",
          "flex items-center justify-between gap-x-1.5 px-1.5",
          "group",
          warning &&
            "outline outline-1 outline-yellow-100 group-[:not(.is-empty)]:bg-orange-50",
          disabled &&
            "text-gray-500 outline outline-1 outline-gray-100 group-[:not(.is-empty)]:bg-gray-50",
          invalid && "bg-red-50",
          "outline-0",
          selected && "bg-indigo-50 outline outline-1 outline-indigo-500",
          !editing && "select-none",
        )}
        data-loc="dataset-table-cell"
        style={{
          scrollMarginTop: `${STICKY_HEADER_OFFSET}px`,
          scrollMarginBottom: `1px`,
        }}
        tabIndex={-1}
      >
        {children}

        <div className="flex items-center gap-x-1.5">
          {error && (
            <Tooltip
              body={error.description}
              footerAction={
                error.applyFix && {
                  text: "Apply fix",
                  onClick: () => error.applyFix?.(),
                }
              }
              placement="right"
              title={error.message}
            >
              <Icon
                color="text-red-600"
                dataLoc="dataset-cell-error-icon"
                icon={faWarning}
                size="2xs"
              />
            </Tooltip>
          )}

          {postfix}
        </div>
      </div>
    );
  },
);

const useScrollIntoViewIf = ({
  condition,
  ref,
}: {
  condition: boolean;
  ref: React.RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.focus();
      ref.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [condition, ref]);
};
