import { DecisionSidepaneWrapper } from "src/entities/entityView/DecisionSidepaneWrapper";
import { useSidepane } from "src/entities/entityView/utils";
import { EventViewPane } from "src/eventsCatalogue/EventViewPane";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type EntityViewSidePaneProps = {
  selectedDecision: ExtendedHistoricalDecisionV3 | null;
  setSelectedDecision: (decision: ExtendedHistoricalDecisionV3 | null) => void;
};

export const EntityViewSidePane: React.FC<EntityViewSidePaneProps> = ({
  selectedDecision,
  setSelectedDecision,
}) => {
  const { workspace } = useWorkspaceContext();
  const baseUrl = workspace.base_url!;

  const {
    resourceId: eventId,
    eventType,
    toggleSidepane,
  } = useSidepane("event");

  if (eventId && eventType) {
    return (
      <EventViewPane
        eventId={eventId}
        eventType={eventType}
        onClose={() => toggleSidepane(null)}
      />
    );
  }

  return (
    <DecisionSidepaneWrapper
      baseUrl={baseUrl}
      decision={selectedDecision}
      isOpen={Boolean(selectedDecision)}
      onClose={() => {
        setSelectedDecision(null);
      }}
    />
  );
};
