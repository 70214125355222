import { produce } from "immer";
import { cloneDeepWith, keys, isString, reduce, pick } from "lodash";
import { toJS } from "mobx";
import { v4 as uuidV4 } from "uuid";

import { FieldErrorsT } from "src/api/types";
import { StringRenderer } from "src/base-components/EditorTable/StringRenderer";
import { HEADER_ROW_ID } from "src/base-components/EditorTable/hooks/useSelectionHandler";
import {
  CellIndex,
  EditorColumnDef,
  EditorColumnGroup,
  ErrorCellIndex,
} from "src/base-components/EditorTable/types";
import {
  DecisionTableEffect,
  DecisionTableField,
  DecisionTablePredicate,
} from "src/clients/flow-api";
import { DecisionTableNodeDataT } from "src/constants/NodeDataTypes";
import {
  CellStringEditor,
  CellStringEditorWithoutAutocomplete,
} from "src/decisionTableNode/CellStringEditor";
import { ConditionEditor } from "src/decisionTableNode/ConditionEditor/ConditionEditor";
import { ConditionFieldCell } from "src/decisionTableNode/ConditionFieldCell";
import { DecisionTableForm } from "src/decisionTableNode/DecisionTableNodeEditor";
import { IndexRenderer } from "src/decisionTableNode/IndexRenderer";
import { PlaceholderPillRenderer } from "src/decisionTableNode/PlaceholderPillRenderer";
import { ResultFieldCell } from "src/decisionTableNode/ResultFieldCell";
import { logger } from "src/utils/logger";

const TAG = "decisionTable";

/**
 * Takes and array, position to insert and a new item
 * and returns a new array with the new item inserted
 */
export const insert = <T>(arr: T[], position: number, newItem: T) => [
  ...arr.slice(0, position),
  newItem,
  ...arr.slice(position),
];

/**
 * Converts DecisionTableNodeDataT to DecisionTableForm
 * Also takes care of omitting any extra fields that are not part of predicate or effect fields
 * @param nodeData
 * @returns DecisionTableForm
 */
export const getFormValues = (
  nodeData: DecisionTableNodeDataT,
): DecisionTableForm => {
  const predicateFields = toJS(nodeData.predicate_fields);
  const effectFields = toJS(nodeData.effect_fields);

  const predicateFieldsIds = predicateFields.map((field) => field.id);
  const effectFieldsIds = effectFields.map((field) => field.id);

  const cases = toJS(nodeData.cases).map((c) => ({
    ...c,
    predicates: pick(c.predicates, predicateFieldsIds),
    effects: pick(c.effects, effectFieldsIds),
  }));

  return {
    predicate_fields: predicateFields,
    effect_fields: effectFields,
    cases,
    fallback: pick(toJS(nodeData.fallback), effectFieldsIds),
  };
};

/**
 * Takes a case and returns a new deep clone of that case
 * but with new generated uuids for all id properties
 *
 * @param case original case to clone
 * @returns
 */
export const cloneDeepWithNewIds = <T>(object: T): T => {
  return cloneDeepWith(object, (_value, key) =>
    key === "id" ? uuidV4() : undefined,
  );
};

type RowValue = string | DecisionTablePredicate;

export type RowShape = {
  caseName: string;
  id: string;
  [key: string]: RowValue;
};

export const transformToTableData = (node: DecisionTableForm): RowShape[] => {
  const valueReducer = (
    object: Record<string, DecisionTablePredicate | DecisionTableEffect>,
  ) =>
    reduce(
      object,
      (acc, curr, fieldId) => ({
        ...acc,
        [fieldId]: curr.value,
      }),
      {},
    );

  return node.cases.map((tableCase) => ({
    id: tableCase.id,
    caseName: tableCase.name,
    ...tableCase.predicates,
    ...valueReducer(tableCase.effects),
  }));
};

export const buildTableGroupsKey = (node: DecisionTableForm) => {
  const predicateFields = node.predicate_fields.map((field) => field.id).sort();
  const effectFields = node.effect_fields.map((field) => field.id).sort();
  return [...predicateFields, ...effectFields].join("_");
};

export const transformToTableGroups = (
  node: DecisionTableForm,
  isFallback: boolean = false,
): EditorColumnGroup<RowShape>[] => {
  const caseIndexGroup: EditorColumnGroup<RowShape> = {
    id: "caseIndex",
    name: " ",
    columns: [
      {
        key: "caseIndex",
        isLegend: true,
        readonly: true,
        resizable: false,
        width: 32,
        minWidth: 27,
        maxWidth: 32,
        cell: {
          renderer: isFallback ? StringRenderer : IndexRenderer,
        },
        header: {
          value: "#",
          renderer: StringRenderer,
        },
      },
    ],
  };

  const caseNameGroup: EditorColumnGroup<RowShape> = {
    id: "caseNames",
    name: "Name",
    columns: [
      {
        key: "caseName",
        isLegend: true,
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        resizable: false,
        cell: {
          renderer: isFallback
            ? StringRenderer
            : CellStringEditorWithoutAutocomplete,
          placeholder: "Add case name...",
        },
        header: {
          value: " ",
          renderer: StringRenderer,
        },
      },
    ],
  };

  const predicateFieldGroup: EditorColumnGroup<RowShape> = {
    id: "predicateFields",
    name: "Conditions",
    columns: node.predicate_fields.map(
      (field) =>
        ({
          key: field.id,
          resizable: !isFallback,

          header: {
            value: field.value,
            renderer: isFallback ? StringRenderer : ConditionFieldCell,
            placeholder: "data.field",
          },

          cell: {
            renderer: isFallback ? PlaceholderPillRenderer : ConditionEditor,
          },
        }) as EditorColumnDef<RowShape>,
    ),
  };

  const effectFieldsGroup: EditorColumnGroup<RowShape> = {
    id: "effectFields",
    name: "Results",
    columns: node.effect_fields.map(
      (field) =>
        ({
          key: field.id,
          isHighlighted: true,
          resizable: !isFallback,

          header: {
            value: field.value,
            renderer: ResultFieldCell,
            placeholder: "data.field",
          },

          cell: {
            renderer: CellStringEditor,
          },
        }) as EditorColumnDef<RowShape>,
    ),
  };

  return [
    caseIndexGroup,
    predicateFieldGroup,
    effectFieldsGroup,
    caseNameGroup,
  ];
};

const isPredicate = (value: RowValue): value is DecisionTablePredicate => {
  const valueKeys = keys(value);
  return (
    valueKeys.includes("id") &&
    valueKeys.includes("value") &&
    valueKeys.includes("operator")
  );
};

/**
 * Applies changes from EditorTable component to DecisionTableForm type
 * Splits based on rowIndex to figure out whether the change is for field names or cases
 */
export const applyTableChange = (
  data: DecisionTableForm,
  rowIndex: number,
  row: RowShape,
): DecisionTableForm => {
  const updatedData = { ...data };

  const caseIndex = rowIndex;
  const updatedCase = { ...updatedData.cases[caseIndex], name: row.caseName };
  const predicateFieldIds = keys(updatedCase.predicates);
  const effectFeildIds = keys(updatedCase.effects);

  updatedCase.predicates = predicateFieldIds.reduce((acc, fieldId) => {
    const updatedField = row[fieldId];
    if (isPredicate(updatedField)) {
      return {
        ...acc,
        [fieldId]: {
          ...updatedField,
        },
      };
    } else {
      logger.error(
        TAG,
        "Invalid type provided - predicate value: predicate required",
        updatedField,
      );
      return acc;
    }
  }, updatedCase.predicates);

  updatedCase.effects = effectFeildIds.reduce((acc, fieldId) => {
    const updatedValue: unknown = row[fieldId];

    if (isString(updatedValue)) {
      return {
        ...acc,
        [fieldId]: {
          ...updatedCase.effects[fieldId],
          value: updatedValue,
        },
      };
    } else {
      logger.error(
        TAG,
        "Invalid type provided - effect value: string required",
        updatedValue,
      );
      return acc;
    }
  }, updatedCase.effects);

  updatedData.cases = data.cases.map((dataCase) =>
    dataCase.id === updatedCase.id ? updatedCase : dataCase,
  );

  return updatedData;
};

export const applyHeaderChange = (
  data: DecisionTableForm,
  key: keyof RowShape,
  value: string,
): DecisionTableForm => {
  return produce(data, (draft) => {
    const predicateFieldIndex = data.predicate_fields.findIndex(
      (field) => field.id === key,
    );
    const effectFieldIndex = data.effect_fields.findIndex(
      (field) => field.id === key,
    );

    if (predicateFieldIndex > -1) {
      draft.predicate_fields[predicateFieldIndex].value = value;
    } else if (effectFieldIndex > -1) {
      draft.effect_fields[effectFieldIndex].value = value;
    }
  });
};

export const transformToFallbackData = (
  data: DecisionTableForm,
): RowShape[] => {
  const fallbackValues = reduce(
    data.fallback,
    (acc, curr, fieldId) => ({
      ...acc,
      [fieldId]: curr.value,
    }),
    {},
  );

  const fallbackData = {
    ...fallbackValues,
    id: "fallback",
    caseName: "Fallback",
  };

  return [fallbackData];
};

export const applyFallbackChange = (
  data: DecisionTableForm,
  row: RowShape,
): DecisionTableForm => {
  const updatedData = { ...data };

  updatedData.fallback = reduce(
    data.fallback,
    (acc, curr, fieldId) => {
      const updatedValue = row[fieldId];

      if (isString(updatedValue)) {
        return {
          ...acc,
          [fieldId]: {
            ...curr,
            value: updatedValue,
          },
        };
      } else {
        logger.error(
          TAG,
          "Invalid type provided - fallback value: string required",
          updatedValue,
        );
        return acc;
      }
    },
    data.fallback,
  );

  return updatedData;
};

export const buildNonEditableFallbackCellList = (
  data: DecisionTableForm,
): CellIndex<RowShape>[] => {
  const predicateFields = data.predicate_fields.map((field) => ({
    row: "fallback",
    col: field.id,
  }));

  return [...predicateFields, { row: "fallback", col: "caseName" }];
};

export const mapErrorsToCoordinates = <T>(
  displayedFieldErrors: FieldErrorsT | undefined,
  node: DecisionTableForm,
) => {
  if (displayedFieldErrors) {
    const fieldErrors = displayedFieldErrors;

    const mapFieldErrors = (fields: DecisionTableField[]) => {
      return fields.reduce<ErrorCellIndex<T>[]>((acc, field) => {
        if (fieldErrors[field.id]) {
          acc.push({
            row: HEADER_ROW_ID,
            col: field.id as keyof T,
            message: fieldErrors[field.id],
          });
        }
        return acc;
      }, []);
    };

    const casesErrors = node.cases.reduce<ErrorCellIndex<T>[]>(
      (acc, dtCase) => {
        const items = { ...dtCase.predicates, ...dtCase.effects };

        Object.entries(items).forEach(([columnKey, value]) => {
          if (fieldErrors[value.id]) {
            acc.push({
              row: dtCase.id,
              col: columnKey as keyof T,
              message: fieldErrors[value.id],
            });
          }
        });

        return acc;
      },
      [],
    );

    const effectFieldsErrors = mapFieldErrors(node.effect_fields);
    const predicateFieldsErrors = mapFieldErrors(node.predicate_fields);

    const fallbackErrors = Object.entries(node.fallback).map(
      ([columnKey, value]) => {
        if (fieldErrors[value.id]) {
          return {
            row: "fallback",
            col: columnKey as keyof T,
            message: fieldErrors[value.id],
          };
        }

        return null;
      },
    );

    const tableErrors = [
      ...casesErrors,
      ...effectFieldsErrors,
      ...predicateFieldsErrors,
    ];

    return [
      tableErrors,
      fallbackErrors.filter(Boolean) as ErrorCellIndex<RowShape>[],
    ] as const;
  }

  return [];
};
