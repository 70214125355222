import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faGraduationCap,
  faLightbulb,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { GRID_COLUMNS_CLASS } from "src/flowsOverview/FlowOverview";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import { useUserData } from "src/store/AuthStore";

type Props = {
  title: string;
  description: string;
  icon: IconProp;
  iconBackground: string;
  dataLoc?: string;
} & (
  | {
      href: string;
      onClick?: never;
    }
  | {
      href?: never;
      onClick: () => void;
    }
);

const DocLink: React.FC<Props> = ({
  title,
  dataLoc,
  description,
  href,
  icon,
  iconBackground,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) {
      return;
    }
    e.preventDefault();
    onClick();
  };
  return (
    <a
      className="flex cursor-pointer gap-x-2 rounded-lg bg-white p-3 shadow-sm"
      data-loc={dataLoc}
      href={href}
      rel="noreferrer noopener"
      target="_blank"
      onClick={handleClick}
    >
      <div
        className={twJoin(
          "flex h-10 w-10 shrink-0 grow-0 items-center justify-center rounded-lg",
          iconBackground,
        )}
      >
        <Icon color="text-gray-800" icon={icon} size="lg" />
      </div>
      <div className="overflow-hidden">
        <p className="truncate text-gray-800 font-inter-semibold-14px">
          {title}
        </p>
        <p className="truncate text-gray-500 font-inter-normal-12px">
          {description}
        </p>
      </div>
    </a>
  );
};

export const DocsHeader: React.FC = () => {
  const {
    signed_in_full_name: name,
    signed_in_username: username,
    signed_in_email: email,
  } = useUserData();

  const { showOmnibox } = useOmniboxActions();
  const { entitiesEnabled } = useWorkspaceFeatureGates();

  return (
    <header className={`mb-9 ${GRID_COLUMNS_CLASS}`}>
      <p className="col-span-full mb-0.5 overflow-visible whitespace-nowrap text-gray-800 font-inter-semibold-16px">
        Welcome, <span data-loc="user-name">{name ?? username ?? email}</span> !
        👋
      </p>
      {!entitiesEnabled && (
        <DocLink
          dataLoc="monitor-entity-card"
          description="Track all decisions of an Entity"
          icon={faSearch}
          iconBackground="bg-indigo-100"
          title="Monitor an Entity"
          onClick={showOmnibox}
        />
      )}
      <DocLink
        description="Follow our Quickstart guide"
        href="https://docs.taktile.com/quickstart/quickstart-tutorial-intro"
        icon={faGraduationCap}
        iconBackground="bg-green-100"
        title="Getting started"
      />
      <DocLink
        description="Learn about testing Decision Flows"
        href="https://docs.taktile.com/recipes/testing/testing-your-decision-flow-for-go-live-in-production"
        icon={faGraduationCap}
        iconBackground="bg-green-100"
        title="Testing"
      />
      <DocLink
        description="Explore third-party data"
        href="https://docs.taktile.com/third-party-connections/built-in-connection-nodes"
        icon={faLightbulb}
        iconBackground="bg-green-100"
        title="Explore Connections"
      />
      <DocLink
        description="Integrate with your backend"
        href="https://docs.taktile.com/developer-docs/decision-flow-api"
        icon={faGraduationCap}
        iconBackground="bg-green-100"
        title="Developer docs"
      />
    </header>
  );
};
