import { useMemo } from "react";

import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import {
  useDatasetEditTableActions,
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { CellId } from "src/datasets/DatasetTable/utils";
import { ValidationResult } from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import { JSONPill } from "src/datasets/components/JSONPill";
import { RelationCellDropdown } from "src/datasets/components/RelationCellDropdown";
import { RelationPill } from "src/datasets/components/RelationJSONEditor";
import { getErrorDetails } from "src/datasets/components/errorUtils";
import { Tooltip } from "src/design-system/Tooltip";
import { TypeIcon } from "src/entities/entityView/EntityDetailsSidePane";
import {
  findSchema,
  getOrderedProperties,
} from "src/entities/entityView/utils";
import { EntitySchemaProperty, useEntitySchemas } from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type FloatingEntityEditorProps = {
  cellId: CellId;
  disabled: boolean;
  error: ValidationResult | undefined;
  invalid: boolean;
  warning: boolean;
  propValue: JSONValue | undefined;
  onChange: (value: string) => void;
  relationSchema: string;
  value: string;
  type: string;
};

export const FloatingEntityEditor: React.FC<FloatingEntityEditorProps> = ({
  value,
  cellId,
  disabled,
  error,
  invalid,
  warning,
  propValue,
  onChange,
  relationSchema: relation,
  type,
}) => {
  const { workspace } = useWorkspaceContext();
  const { reset } = useDatasetEditTableActions();
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });

  const relationSchema = findSchema(relation, entitySchemas);

  const properties = useMemo(() => {
    return relationSchema ? getOrderedProperties(relationSchema) : [];
  }, [relationSchema]);

  const children = (
    <CellWrapper
      cellId={cellId}
      disabled={disabled}
      error={getErrorDetails(error, value, onChange)}
      invalid={invalid}
      warning={warning}
    >
      {propValue ? (
        <RelationPill
          fallback={<JSONPill type={type} value={value} />}
          schema={relation}
          value={propValue}
        />
      ) : (
        <RelationCellDropdown
          isOpen={selected && !editing}
          relation={relation}
          onFill={onChange}
        />
      )}
    </CellWrapper>
  );

  return (
    <FloatingWindow
      button={children}
      isOpen={editing}
      maximizable={false}
      title={
        <div className="flex items-start gap-x-1.5">
          <RelationPill
            fallback={<JSONPill type={type} value={value} />}
            schema={relation}
            value={propValue}
          />
        </div>
      }
      onClose={() => reset("editingCellId")}
    >
      <div className="flex h-full flex-1 flex-col gap-y-1.5 pt-2">
        {relationSchema ? (
          <div className="flex flex-col divide-y divide-gray-100 border border-gray-100">
            {properties.map((key) => {
              const property = relationSchema.properties[key];
              return (
                <div
                  key={key}
                  className="flex h-8 items-stretch divide-x divide-gray-100"
                >
                  <div className="w-5/12 flex-none pl-1 pr-8">
                    <PropertyName property={property} />
                  </div>
                  <div>
                    {typeof propValue === "object" &&
                    propValue &&
                    !Array.isArray(propValue)
                      ? String((propValue as Record<string, unknown>)[key])
                      : ""}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </FloatingWindow>
  );
};

const PropertyName: React.FC<{ property: EntitySchemaProperty }> = ({
  property,
}) => {
  return (
    <div className="flex h-full items-center gap-x-1 text-gray-800 font-inter-medium-12px">
      <TypeIcon cardinality={property._cardinality} type={property._type} />
      <Tooltip title={property._display_name} asChild>
        <p className="truncate capitalize">{property._display_name}</p>
      </Tooltip>
    </div>
  );
};
