import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  useCreateConnection,
  useEditConnection,
} from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import {
  taktileInternalPrefix,
  validateNotStartsWithInternalPrefix,
} from "src/api/constants";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { EnableNonProdConfigsField } from "src/connections/config/EnableNonProdConfigsField";
import { EnvironmentConfig as BigQuerySQLEnvironmentConfig } from "src/connections/config/database/bigquery/BigQuerySQLEnvironmentConfig";
import { DatabasePermissionsPill } from "src/connections/config/database/shared/DatabasePermissionsPill";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { EnvironmentTabs } from "src/connections/config/shared/EnvironmentTabs";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  getBigQuerySQLConnectionConfigInputsDefaultValues,
  convertBEConnectionToBigQuerySQLConnectionConfigInputs,
  convertBigQuerySQLConnectionConfigInputsToBEConnection,
} from "src/connections/model/bigquery";
import { BigQuerySQLConnectionConfigInputsT } from "src/connections/types";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";

type PropsT = {
  onClose: () => void;
  workspaceUrl: string;
  connection?: ConnectionT;
};

export const BigQuerySQLConnectionConfigForm: React.FC<PropsT> = ({
  onClose,
  workspaceUrl,
  connection,
}) => {
  const defaultValues = useMemo(() => {
    if (connection) {
      return convertBEConnectionToBigQuerySQLConnectionConfigInputs(connection);
    } else {
      return getBigQuerySQLConnectionConfigInputsDefaultValues();
    }
  }, [connection]);

  const formMethods = useForm<BigQuerySQLConnectionConfigInputsT>({
    defaultValues,
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    watch,
  } = formMethods;

  const enableNonProdConfigs = watch("enableNonProdConfigs");

  const createConnectionMutation = useCreateConnection(workspaceUrl);
  const editConnectionMutation = useEditConnection(
    workspaceUrl,
    connection?.id,
  );

  const onSubmit = async (data: BigQuerySQLConnectionConfigInputsT) => {
    const payload =
      convertBigQuerySQLConnectionConfigInputsToBEConnection(data);
    try {
      if (connection) {
        await editConnectionMutation.mutateAsync({
          id: connection.id,
          payload,
        });
      } else {
        await createConnectionMutation.mutateAsync(payload);
      }

      const action = connection ? "updated" : "created";
      toastActions.success({
        title: `Connection ${action}`,
        description: `Connection ${data.name} has been ${action}.`,
        withSidebar: true,
      });
      onClose();
    } catch {
      toastActions.failure({
        title: "Error",
        description: "We couldn't save your connection",
        withSidebar: true,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <FormItem
            description="A name that describes the new connection"
            gap="sm"
            label="Connection name"
            isRequired
          >
            {Boolean(errors.name) && (
              <ErrorHint>{errors.name?.message}</ErrorHint>
            )}
            <Input
              data-loc="bigquery-connection-name"
              placeholder="e.g. BigQuery Warehouse"
              fullWidth
              {...errorProps(isFieldErrored(errors, "name"))}
              {...register("name", {
                required: "Connection name is required",
                validate: validateNotStartsWithInternalPrefix(
                  `Connection name cannot start with ${taktileInternalPrefix}`,
                ),
              })}
            />
          </FormItem>
          <EnableNonProdConfigsField<BigQuerySQLConnectionConfigInputsT>
            dataLoc="bigquery-connection-env-aware-switch"
            subtitle="You can set different credentials for test runs and sandbox DB queries"
            title="Use separate credentials for non-production DB queries"
          />
          {enableNonProdConfigs ? (
            <EnvironmentTabs
              prodContent={
                <BigQuerySQLEnvironmentConfig
                  environment="production"
                  labelsPrefix="Production"
                />
              }
              prodError={Boolean(errors.productionConfig)}
              sandboxContent={
                <BigQuerySQLEnvironmentConfig
                  environment="sandbox"
                  labelsPrefix="Sandbox"
                />
              }
              sandboxError={Boolean(errors.sandboxConfig)}
            />
          ) : (
            <BigQuerySQLEnvironmentConfig
              environment="production"
              labelsPrefix=""
            />
          )}
          <DatabasePermissionsPill />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="bigquery-connection-save"
              disabled={isSubmitting}
              htmlType="submit"
              loading={isSubmitting}
              size="base"
              variant="primary"
            >
              Save
            </Button>
          }
        ></Modal.Footer>
      </form>
    </FormProvider>
  );
};
