import {
  faBuildings,
  faCreditCard,
  faKey,
  faMoneySimpleFromBracket,
  faRightToBracket,
  faShop,
  faTimer,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

import {
  EventConfigEnriched,
  FeatureResponse,
  PropertyDefinitionOutput,
} from "src/clients/features-control";
import {
  expandEntitySchema,
  getEntityTemplate,
} from "src/datasets/dataTemplateUtils";
import { findSchema } from "src/entities/entityView/utils";
import { EntitySchemaResource } from "src/entities/queries";
import { WorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";

const EVENT_FALLBACK_ICON = faTimer;

const EVENT_ICONS = {
  user: faUser,
  buildings: faBuildings,
  shop: faShop,
  card: faCreditCard,
  transaction: faMoneySimpleFromBracket,
  login: faRightToBracket,
  key: faKey,
} as const;

export const getEventIcon = (eventType: EventConfigEnriched) =>
  EVENT_ICONS[eventType.display_symbol as keyof typeof EVENT_ICONS] ??
  EVENT_FALLBACK_ICON;

export const getEventTemplate = (
  properties: Record<string, PropertyDefinitionOutput> | undefined,
  entitySchemas:
    | {
        entities: EntitySchemaResource[];
        next_page_token: string;
      }
    | undefined,
  features: FeatureResponse[],
  featureGates: WorkspaceFeatureGates,
) => {
  if (!properties) {
    return {};
  }
  return Object.entries(properties).reduce(
    (acc, [key, value]: [string, PropertyDefinitionOutput]) => {
      if (value.type === "relation") {
        const relationSchema = findSchema(
          value.relation_schema?.entity_type ?? "",
          entitySchemas,
        );
        if (relationSchema) {
          const expandedSchema = expandEntitySchema(
            relationSchema,
            entitySchemas?.entities ?? [],
            4,
          );
          return {
            ...acc,
            [key]: getEntityTemplate(expandedSchema, features, featureGates),
          };
        }
      }
      return {
        ...acc,
        [key]: "",
      };
    },
    {},
  );
};

export const findEventSchema = (
  eventType: string,
  eventSchemas: EventConfigEnriched[] | undefined,
) => {
  if (!eventSchemas) {
    return undefined;
  }

  return eventSchemas.find((event) => event.event_type === eventType);
};
