import { faCube } from "@fortawesome/pro-regular-svg-icons";

import { useHistoryDecisionV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { EventConfigEnriched } from "src/clients/features-control";
import {
  INTERNAL_DATA_KEYS,
  TAKTILE_TYPE,
} from "src/constants/InternalDataKeys";
import {
  decodeTypeToEntityEventId,
  isTaktileObject,
} from "src/dataTable/typingDecoding";
import { DecisionRequest } from "src/decisionsOverview/DecisionsSidepane";
import { Tree, TreeData } from "src/entities/entityView/Tree";
import { getSchemaIcon } from "src/entities/entityView/utils";
import { EntitySchemaResource } from "src/entities/queries";
import { getEventIcon } from "src/eventsCatalogue/utils";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type Props = {
  decisionId: string;
};

const getTreeDataChildren = (
  data: Record<string, unknown> | undefined,
  entitySchemas: EntitySchemaResource[],
  eventConfigs: EventConfigEnriched[],
  nesting = 5,
): TreeData[] => {
  const children: TreeData[] = Object.entries(data ?? {})
    .filter(([key]) => !INTERNAL_DATA_KEYS.includes(key))
    .map(([key, value]) => {
      if (isTaktileObject(value)) {
        const decodedTypeHint = decodeTypeToEntityEventId(value[TAKTILE_TYPE]);
        const entitySchema =
          decodedTypeHint?.metaType === "entity"
            ? entitySchemas.find(
                (entity) => entity._id === decodedTypeHint.configSchemaId,
              )
            : undefined;
        const eventConfig =
          decodedTypeHint?.metaType === "event"
            ? eventConfigs.find(
                (event) => event.event_type === decodedTypeHint.configSchemaId,
              )
            : undefined;
        if (entitySchema || eventConfig) {
          const displayName =
            entitySchema?._display_name_singular ??
            eventConfig?.display_name_singular ??
            "";
          const icon = entitySchema
            ? getSchemaIcon(entitySchema)
            : eventConfig
              ? getEventIcon(eventConfig)
              : faCube;
          return {
            id: key,
            key,
            value: key,
            type: {
              icon: icon,
              displayName: displayName,
            },
            children: getTreeDataChildren(
              value,
              entitySchemas,
              eventConfigs,
              nesting - 1,
            ),
          };
        }
      }
      return {
        id: key,
        key,
        value,
      };
    });
  return children;
};

export const transformToTreeData = (
  data: Record<string, unknown> | undefined,
  entitySchemas: EntitySchemaResource[],
  events: EventConfigEnriched[],
): TreeData[] => {
  return getTreeDataChildren(data, entitySchemas, events, 5);
};

export const DecisionInputFieldsTree: React.FC<Props> = ({ decisionId }) => {
  const { workspace } = useWorkspaceContext();
  const { data, isLoading } = useHistoryDecisionV2({
    decisionId,
    baseUrl: workspace.base_url!,
  });

  const rowData = (() => {
    if (data?.isQueryError || !data?.decision) {
      return undefined;
    }

    const decision = data.decision;

    return (decision.request as DecisionRequest).data;
  })();

  const treeData = transformToTreeData(rowData, [], []);

  return <Tree data={treeData} loading={isLoading} />;
};
