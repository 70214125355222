import React, { useMemo } from "react";
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useWatch,
} from "react-hook-form";

import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { useEntitySchemas } from "src/entities/queries";
import { useFeatures } from "src/featureCatalogue/queries";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitySchemaHint } from "src/schema/EntitySchemaHint";
import { SchemaGlobalFeatures } from "src/schema/SchemaGlobalFeatures";
import { getEntitySchemaOptions } from "src/schema/SchemaTypeSelector";
import {
  decodeJsonRefPath,
  isJsonRefPath,
  isReferencingEntity,
  JsonRefPath,
} from "src/schema/jsonRefUtils";
import { FieldAccessInfo } from "src/schema/schemaEditor/FieldAccessInfo";
import { FEATURES_PROPERTY } from "src/schema/schemaEditor/utils";
import { getFeaturesByEntity, SchemaUIT } from "src/schema/schemaMappingUtils";

export const EntitySchemaEditor: React.FC<{
  formMethods: UseFormReturn<SchemaUIT>;
}> = ({ formMethods }) => {
  const { workspace } = useWorkspaceContext();
  const { data: entitySchemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });
  const schemasOptions = getEntitySchemaOptions(
    entitySchemasData?.entities ?? [],
  );

  const { data: features } = useFeatures({
    options: {
      enabled: isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase),
    },
  });

  const featuresByEntity = useMemo(
    () => getFeaturesByEntity(features ?? []),
    [features],
  );

  const properties = useWatch({
    control: formMethods.control,
    name: "properties",
  });
  const entityProperty = properties.find(
    (property) =>
      isJsonRefPath(property.type[0]) &&
      isReferencingEntity(property.type[0] as JsonRefPath),
  );

  const selectedEntitySchema = entityProperty?.type[0];

  const hasNoSchemas = entitySchemasData?.entities.length === 0;

  const decodedRef = decodeJsonRefPath(selectedEntitySchema ?? "");
  const selectedEntity =
    decodedRef?.metaType === "entity"
      ? entitySchemasData?.entities.find(
          (entity) => entity._id === decodedRef.type,
        )
      : undefined;

  return (
    <>
      <FormProvider {...formMethods}>
        <Controller
          name="properties"
          render={({ field }) => (
            <FixedSimpleDropdown
              buttonDataLoc="schema-field-type"
              disabled={hasNoSchemas}
              elements={schemasOptions}
              placeholder={
                hasNoSchemas ? "No schemas available" : "Select type"
              }
              placement="bottomLeft"
              selectedKey={field.value?.[0]?.type[0]}
              onSelect={(newType) => {
                const selectedSchema = schemasOptions.find(
                  (schema) => schema.key === newType,
                );
                field.onChange([
                  {
                    fieldName: selectedSchema?.schemaName.toLowerCase() ?? "",
                    required: true,
                    sensitive: false,
                    type: [newType as JsonRefPath, false],
                  },
                  FEATURES_PROPERTY,
                ]);
              }}
            />
          )}
        />
      </FormProvider>
      {selectedEntitySchema && (
        <FieldAccessInfo
          inputType="entity"
          schemaName={entityProperty?.fieldName}
        />
      )}
      {entitySchemasData && selectedEntity && (
        <EntitySchemaHint
          entitySchema={selectedEntity}
          entitySchemas={entitySchemasData.entities}
          featuresByEntity={featuresByEntity}
          rootPath={`data.${entityProperty?.fieldName}`}
        />
      )}
      <SchemaGlobalFeatures />
    </>
  );
};
