import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useId,
  useInteractions,
  useRole,
  useTransitionStatus,
} from "@floating-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { noop, pickBy } from "lodash-es";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Dropdown, DropdownItem } from "src/design-system/Dropdown";
import { WidthSize } from "src/design-system/types";

export const ControllableDropdown: React.FC<{
  isOpen: boolean;
  children: React.ReactNode;
  width?: WidthSize;
  placeholder?: string;
  postfix?: React.ReactNode;
}> = ({ isOpen, children, width, placeholder, postfix }) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: "bottom-start",
    onOpenChange: noop,
    middleware: [
      offset({
        mainAxis: 4,
        crossAxis: -7,
      }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context);
  const { isMounted, status } = useTransitionStatus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([role]);

  const headingId = useId();
  const dataStatusAttributes = pickBy(
    {
      "data-closed": status === "close" || status === "unmounted",
      "data-open": status === "open",
    },
    (value) => value === true,
  );

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps({
          className: "h-full w-full flex items-center justify-between",
        })}
      >
        <span className="invisible text-gray-500 group-[.is-empty]:visible">
          {placeholder}
        </span>
        {postfix && (
          <span className="invisible group-[.is-empty]:visible">{postfix}</span>
        )}
      </div>
      {isMounted && (
        <Dropdown
          ref={refs.setFloating}
          aria-labelledby={headingId}
          className={twJoin(
            "transition-opacity duration-200 ease-out data-[closed]:opacity-0",
            width,
          )}
          data-loc="dataset-dropdown-content"
          style={floatingStyles}
          {...getFloatingProps()}
          {...dataStatusAttributes}
        >
          {children}
        </Dropdown>
      )}
    </>
  );
};

export const ButtonItem: React.FC<{
  icon: IconProp;
  label: string;
  onClick: () => void;
}> = ({ icon, label, onClick }) => {
  return (
    <DropdownItem data-loc="dataset-dropdown-item" onClick={onClick}>
      <div className="flex items-center gap-x-2 text-gray-800">
        <Icon color="text-gray-500" icon={icon} size="sm" />
        {label}
      </div>
    </DropdownItem>
  );
};
