import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  useFormContext,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { ClauseBlock, ClauseFormT } from "src/clauseBlock/ClauseBlock";
import { BranchEffect } from "src/ruleNodeV2Editor/BranchEffect";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";
import { useDefaultEffectsValue } from "src/ruleNodeV2Editor/useDefaultEffectsValue";
import {
  mergeFieldIdAndName,
  RunErrorFieldNames,
} from "src/utils/FieldErrorUtils";

type PropsT = {
  index: number;
  id: string;
  immutable: boolean;
  lazyInputs: boolean;
  fieldErrors: FieldErrorsT | undefined;
  onRemove: () => void;
  hideActionButtons?: boolean;
};

export const Branch: React.FC<PropsT> = ({
  hideActionButtons,
  index: branchIndex,
  id,
  immutable,
  lazyInputs,
  fieldErrors,
  onRemove,
}) => {
  const { setValue, control } = useFormContext<RuleNodeV2FormT>();
  const { remove } = useFieldArray({
    name: `branches.${branchIndex}.effects`,
  });

  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const defaultEffectsValue = useDefaultEffectsValue({
    onUnregister: (index) => remove(index),
    onSetValue: (index, value) =>
      setValue(`branches.${branchIndex}.effects.${index}.target`, value),
  });
  const branchEffectsValues = useWatch({
    control,
    name: `branches.${branchIndex}.effects`,
  });

  const handleChange = (value: ClauseFormT) => {
    setValue(`branches.${branchIndex}.clauses`, value.clauses);
    setValue(`branches.${branchIndex}.junction`, value.junction);
  };

  return (
    <Card
      ref={setNodeRef}
      className="w-full space-y-3"
      data-loc={`branch-${branchIndex}`}
      style={style}
    >
      <Card.Header>
        <div className="flex flex-row items-center gap-x-1">
          <ReorderHandle
            className="-ml-2"
            immutable={immutable}
            listeners={listeners}
          />
          <Card.Title title={`Rule ${branchIndex + 1}`} />
        </div>
        {!hideActionButtons && (
          <Button
            disabled={immutable}
            iconLeft={faTrashAlt}
            size="sm"
            variant="secondary"
            onClick={onRemove}
          >
            Delete rule
          </Button>
        )}
      </Card.Header>
      <Card.Content>
        <Controller
          control={control}
          name={`branches.${branchIndex}`}
          render={({ field }) => (
            <ClauseBlock
              clauses={field.value.clauses}
              fieldErrors={fieldErrors}
              hideActionButtons={hideActionButtons}
              immutable={immutable}
              junction={field.value.junction}
              lazyInputs={lazyInputs}
              onChange={handleChange}
            />
          )}
        />
        <div className="h-px rounded-full bg-gray-200" />
        {defaultEffectsValue.map((field, index) => (
          <BranchEffect
            key={field.id}
            branchIndex={branchIndex}
            fieldError={
              fieldErrors?.[
                mergeFieldIdAndName(
                  branchEffectsValues.at(index)?.id,
                  RunErrorFieldNames.RIGHT,
                )
              ]
            }
            immutable={immutable}
            index={index}
            lazyInputs={lazyInputs}
            name={field.target}
          />
        ))}
      </Card.Content>
    </Card>
  );
};
