import { faBracketsCurly } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import {
  Content,
  Header,
  Item,
  Root,
  Trigger,
} from "@radix-ui/react-accordion";
import { capitalize } from "lodash";
import { useState } from "react";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import {
  EventConfigEnriched,
  FeatureResponse,
  PropertyDefinitionOutput,
} from "src/clients/features-control";
import { Tooltip } from "src/design-system/Tooltip";
import { FeaturePill, PropertyPill } from "src/entities/PropertyPill";
import { propertyIsExpandable } from "src/entities/entityUtils";
import {
  getOrderedProperties,
  getOrderedPropertiesForEvent,
} from "src/entities/entityView/utils";
import {
  EntitySchemaProperty,
  EntitySchemaResource,
} from "src/entities/queries";
import { entityIdToFeaturesKey } from "src/schema/schemaMappingUtils";

type Props = {
  rootPath: string;
  entitySchemas: EntitySchemaResource[];
  featuresByEntity: Record<string, FeatureResponse[]>;
  eventType?: EventConfigEnriched;
  entitySchema?: EntitySchemaResource;
  nestingDepth?: number;
  path?: string;
};

export const isEntitySchemaProperty = (
  property: EntitySchemaProperty | PropertyDefinitionOutput,
): property is EntitySchemaProperty => {
  return "_type" in property;
};

export const getExpansions = (
  properties:
    | Record<string, EntitySchemaProperty>
    | Record<string, PropertyDefinitionOutput>,
  entitySchemas: EntitySchemaResource[],
) => {
  const expandableRelations: [
    string,
    EntitySchemaProperty | PropertyDefinitionOutput,
  ][] = Object.entries(properties).filter(([_, property]) =>
    propertyIsExpandable(property),
  );
  const expansions: {
    relationName: string;
    relationProperty: EntitySchemaProperty | PropertyDefinitionOutput;
    entity: EntitySchemaResource | undefined;
  }[] = expandableRelations.map(([relationName, relationProperty]) => {
    const relatedSchema = isEntitySchemaProperty(relationProperty)
      ? relationProperty._rel_schema
      : relationProperty.relation_schema?.entity_type;
    return {
      relationName,
      relationProperty,
      entity: entitySchemas.find((schema) => schema._id === relatedSchema),
    };
  });
  return expansions;
};

const EntitySchemaHintNested = ({
  rootPath,
  entitySchema,
  entitySchemas,
  featuresByEntity,
  nestingDepth = 5,
  path,
  eventType,
}: Props) => {
  const properties = entitySchema?.properties ?? eventType?.properties;

  if (!properties) return null;

  const orderedProperties = (() => {
    if (entitySchema) {
      return getOrderedProperties(entitySchema);
    }

    if (eventType) {
      return getOrderedPropertiesForEvent(eventType);
    }

    return [];
  })();

  const propertiesToDisplay = orderedProperties
    .map((propertyName) => properties[propertyName])
    .filter((property) => !propertyIsExpandable(property));

  const expansions = getExpansions(properties, entitySchemas);

  const displayAccessPath = path ? `${rootPath}.${path}` : rootPath;

  const features = entitySchema?._id
    ? featuresByEntity[entityIdToFeaturesKey(entitySchema?._id)]
    : undefined;

  return (
    <>
      <Item className="w-full max-w-full" value={path ?? "base"}>
        <Header className="w-full max-w-full">
          <Trigger className="group w-full max-w-full">
            <div className="ml-0.5 flex flex-row items-center gap-x-2 overflow-hidden text-left">
              <div className="group-radix-state-closed:-rotate-90 group-radix-state-closed:duration-300 group-radix-state-open:duration-300">
                <Icon color="text-gray-500" icon={faChevronDown} size="sm" />
              </div>
              <div className="flex-1 text-gray-800 font-inter-semibold-13px">
                {capitalize(
                  entitySchema?._display_name_singular ??
                    eventType?.display_name_singular,
                )}
              </div>
              <div className="min-w-0 flex-shrink">
                <EntitySchemaPathPill path={displayAccessPath} />
              </div>
            </div>
          </Trigger>
        </Header>
        <Content className="relative pl-3">
          <div className="absolute bottom-[14px] left-3 top-1 w-[1px] border-l border-gray-200" />
          <div>
            <div className="mb-1 ml-3.5 mt-3 text-gray-800 font-inter-medium-12px">
              Properties
            </div>
            <div className="mb-5 ml-3.5 flex max-w-full flex-row flex-wrap gap-x-1 gap-y-1">
              {propertiesToDisplay.map((property, index) => (
                <PropertyPill key={index} property={property} />
              ))}
            </div>
            {features && features.length > 0 && (
              <>
                <div className="mb-1 ml-3.5 mt-3 text-gray-800 font-inter-medium-12px">
                  Features
                </div>
                <div className="mb-5 ml-3.5 flex max-w-full flex-row flex-wrap gap-x-1 gap-y-1">
                  {features?.map((feature, index) => (
                    <FeaturePill key={index} feature={feature} />
                  ))}
                </div>
              </>
            )}
            {nestingDepth > 0 &&
              expansions.length > 0 &&
              expansions.map(
                (expansion) =>
                  expansion.entity && (
                    <div
                      key={`${path}.${expansion.relationName}`}
                      className="items-top flex w-full"
                    >
                      <div className="mt-2.5 h-[1px] w-3.5 flex-shrink-0 bg-gray-200" />
                      <div className="min-w-0 flex-1">
                        <EntitySchemaHintNested
                          entitySchema={expansion.entity}
                          entitySchemas={entitySchemas}
                          featuresByEntity={featuresByEntity}
                          nestingDepth={nestingDepth - 1}
                          path={
                            path
                              ? `${path}.${expansion.relationName}`
                              : expansion.relationName
                          }
                          rootPath={rootPath}
                        />
                      </div>
                    </div>
                  ),
              )}
          </div>
        </Content>
      </Item>
    </>
  );
};

export const EntitySchemaHint = ({
  rootPath,
  entitySchema,
  eventType,
  entitySchemas,
  featuresByEntity,
  nestingDepth = 5,
}: Props) => {
  if (!entitySchema && !eventType) return null;

  return (
    <div className="rounded-md border border-gray-100 bg-gray-50 px-4 py-4">
      <Root type="multiple">
        <EntitySchemaHintNested
          entitySchema={entitySchema}
          entitySchemas={entitySchemas}
          eventType={eventType}
          featuresByEntity={featuresByEntity}
          nestingDepth={nestingDepth}
          rootPath={rootPath}
        />
      </Root>
    </div>
  );
};

const EntitySchemaPathPill = ({ path }: { path: string }) => {
  const [copied, setCopied] = useState(false);
  return (
    <Tooltip placement="top" title={copied ? "Copied!" : path} asChild>
      <Pill
        size="sm"
        variant="indigo-light"
        maxWidth
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(path);
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }}
      >
        <Pill.Icon icon={faBracketsCurly} />
        <Pill.Text>{path}</Pill.Text>
      </Pill>
    </Tooltip>
  );
};
