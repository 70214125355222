import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type HeaderProps = React.FC<{ children: ReactNode }>;

const Header: HeaderProps = ({ children }) => (
  <div className="flex min-h-[3.5rem] items-center justify-between border-b border-gray-200 px-6 py-3 text-gray-800 font-inter-semibold-14px">
    {children}
  </div>
);

type BodyProps = React.FC<{ children: ReactNode }>;

const Body: BodyProps = ({ children }) => (
  <div className="relative px-6 py-4 text-gray-800">{children}</div>
);

type SubComponents = {
  Header: HeaderProps;
  Body: BodyProps;
};

type CardType = React.FC<{ children: ReactNode; width?: "w-3/4" | "w-full" }> &
  SubComponents;

export const Card: CardType = ({ children, width = "w-full" }) => (
  <div
    className={twMerge(
      "flex flex-col overflow-clip rounded-lg border border-gray-200 bg-white",
      width,
    )}
  >
    {children}
  </div>
);

Card.Header = Header;
Card.Body = Body;
