import {
  SchemaT,
  SchemaTypesBET,
  PropertyTypeUIT,
  PropertyValueT,
  SchemaTypesT,
  PropertyTypeBET,
  PropertyTitleBET,
  EnumOptionsBET,
  SubSchemaT,
  JsonSchemaItems,
  anyJsonSchemaType,
  SchemaTypesBE,
} from "src/api/flowTypes";
import {
  EventConfigEnriched,
  FeatureResponse,
} from "src/clients/features-control/api";
import { SchemaOptions } from "src/router/SearchParams";
import { isValidEnumOptionInput } from "src/schema/SchemaEnumRow";
import {
  decodeJsonRefPath,
  isJsonRefPath,
  JsonRefPath,
} from "src/schema/jsonRefUtils";
import { ValueOf } from "src/utils/typeUtils";

export const defaultSchema: SchemaT = {
  $schema: "https://json-schema.org/draft/2020-12/schema",
  properties: {},
  required: [],
  order: [],
  sensitive: [],
  type: "object",
};

export type EnumValueObject = { value: string };

type BaseProperty = {
  fieldName: string;
  required: boolean;
  sensitive: boolean;
};

export type PropertyUIT = BaseProperty & {
  type: PropertyTypeUIT;
  enum?: EnumValueObject[];
  items?: JsonSchemaItems;
};

export type SchemaUIT = {
  $schema: string;
  $defs?: Record<string, SubSchemaUIT> | undefined;
  properties: PropertyUIT[];
  type: "object";
};

export type SubSchemaUIT<T = PropertyUIT> = {
  properties: T[];
  type: PropertyTypeBET;
  enum?: EnumOptionsBET[];
};

export type CommonSchemaEditRowPropsT = {
  rowName: `properties.${number}`;
  disabled: boolean;
};

export type SchemaTypeSelectorProps = CommonSchemaEditRowPropsT & {
  type: SchemaOptions;
  index: number;
};

export const getNewProperty = (): PropertyUIT => {
  return {
    fieldName: "",
    type: ["string", false],
    required: true,
    sensitive: false,
  };
};

const comparePropertiesByOrder =
  (order: string[] | undefined) => (a: PropertyUIT, b: PropertyUIT) => {
    if (!order) {
      return 0; // Keep original order
    }
    const firstIndex = order.indexOf(a.fieldName);
    const secondIndex = order.indexOf(b.fieldName);
    return firstIndex - secondIndex;
  };

export class PropertyConverter {
  static uiToBEEnum = (
    enumOptions: EnumValueObject[],
    nullable: boolean,
  ): EnumOptionsBET => {
    const beEnums: EnumOptionsBET = enumOptions.map(({ value: option }) => {
      const number = Number(option);
      if (!isNaN(number)) {
        return number;
      }

      if (isValidEnumOptionInput(option)) {
        return option.substring(1, option.length - 1);
      }

      return option;
    });
    if (nullable) {
      beEnums.push(null);
    }
    return beEnums;
  };
  static beToUIEnum = (enumOptions: EnumOptionsBET) => {
    return enumOptions
      .filter((option) => option !== null)
      .map((option) => {
        // This check is just to satisfy TS, in the filter above we make sure there are no nulls here
        if (option === null) {
          return { value: "" };
        }
        if (typeof option === "number") {
          return { value: String(option) };
        }
        if (option.includes('"')) {
          return { value: `'${option}'` };
        }

        return { value: `"${option}"` };
      });
  };
  static uiToBEProperty = (
    property: PropertyUIT,
    schemaType: SchemaOptions,
  ): PropertyValueT => {
    const type: PropertyTypeBET | JsonRefPath = this.typeSetter(property);
    const isNullable = property.type[1] === "null";
    if (isNullable && typeof type !== "string") {
      type.push("null");
    }

    return {
      ...(typeof type === "string"
        ? isNullable
          ? { anyOf: [{ $ref: type }, { type: "null" }] }
          : { $ref: type }
        : { type }),
      format: this.formatSetter(property),
      title:
        schemaType === SchemaOptions.Input
          ? this.titleSetter(property)
          : undefined,
      ...(property.enum
        ? { enum: this.uiToBEEnum(property.enum, isNullable) }
        : {}),
      ...(property.type.includes("array") ? { items: {} } : {}),
    };
  };
  /**
   * We're setting schema title to make sure that the backend knows that
   * it needs to transform the fields to native date/datetime types. This is
   * required for preserving backwards compatibility with the old date/datetime fields.
   */
  static titleSetter = (property: PropertyUIT) => {
    const [type] = property.type;
    if (type === "datetime") {
      return "native-datetime";
    }
    if (type === "date") {
      return "native-date";
    }
    return undefined;
  };

  static formatSetter = (property: PropertyUIT) => {
    const [type] = property.type;
    if (type === "datetime" || type === "datetime-str") {
      return "date-time";
    }
    if (type === "date" || type === "date-str") {
      return "date";
    }
    return undefined;
  };

  static typeSetter = (
    property: PropertyUIT,
  ): SchemaTypesBET[] | JsonRefPath => {
    const [propertyType] = property.type;
    if (typeof propertyType === "string" && isJsonRefPath(propertyType)) {
      return propertyType;
    }

    if (propertyType === "any") {
      return [...anyJsonSchemaType];
    }
    if (propertyType === "enum") {
      return ["string", "number"];
    }

    if (
      propertyType === "datetime" ||
      propertyType === "date" ||
      propertyType === "datetime-str" ||
      propertyType === "date-str"
    ) {
      return ["string"];
    }

    return [propertyType];
  };

  static beToUiProperty = (
    name: string,
    property: PropertyValueT,
    schemaType: SchemaOptions,
    required: boolean,
    sensitive: boolean,
  ): PropertyUIT => {
    const { type, anyOf, format, title, $ref, ...restProperties } = property;

    const fieldType =
      type ??
      anyOf
        ?.map(({ type, $ref }) => ($ref ? $ref : type))
        .find((t) => t !== "null");

    const fieldNullable =
      ($ref === undefined || !isJsonRefPath($ref)) && !anyOf
        ? this.nullableSetterBE(type)
        : anyOf?.some(({ type }) => type === "null")
          ? "null"
          : false;
    const anyOfNonNull = anyOf?.find(({ type }) => type !== "null");
    const enumOptions =
      restProperties.enum ??
      restProperties.items?.enum ??
      anyOfNonNull?.enum ??
      anyOfNonNull?.items?.enum;

    return {
      fieldName: name,
      type: [
        $ref === undefined || !isJsonRefPath($ref)
          ? this.typeSetterBE(fieldType, format, schemaType, title, enumOptions)
          : $ref,
        fieldNullable,
      ],
      ...(restProperties.items && { items: restProperties.items }),
      required,
      sensitive,
      enum: enumOptions ? this.beToUIEnum(enumOptions) : undefined,
    };
  };

  static beToUiProperties = (
    schema: SchemaT | SubSchemaT,
    schemaType: SchemaOptions,
    { forceNativeDates = false }: { forceNativeDates?: boolean } = {},
  ): PropertyUIT[] => {
    const getTitle = (property: PropertyValueT) => {
      if (forceNativeDates) {
        const isDateFormat = property.format === "date";
        const isDateTimeFormat = property.format === "date-time";

        const isDateFormattedString =
          property.type?.includes("string") &&
          (isDateFormat || isDateTimeFormat);

        return isDateFormattedString
          ? isDateTimeFormat
            ? "native-datetime"
            : "native-date"
          : property.title;
      }

      return property.title;
    };
    const property: PropertyUIT[] = Object.entries(schema.properties || {}).map(
      ([name, property]) =>
        this.beToUiProperty(
          name,
          {
            ...property,
            title: getTitle(property),
          },
          schemaType,
          "required" in schema ? schema.required.includes(name) : false,
          "sensitive" in schema && schema.sensitive !== undefined
            ? schema.sensitive?.includes(name)
            : false,
        ),
    );
    return property;
  };

  static typeSetterBE = (
    type: PropertyTypeBET | JsonRefPath,
    format: any,
    schemaType: SchemaOptions,
    title: PropertyTitleBET,
    enumOptions: EnumOptionsBET | undefined,
  ): SchemaTypesT | JsonRefPath => {
    if (typeof type === "string" && isJsonRefPath(type)) {
      return type;
    }
    const isEnumType =
      type === "string" ||
      // Compatibility code, when type is array of string and number (and/or "null")
      (type?.includes("string") && type.length <= 3);
    if (enumOptions !== undefined && isEnumType) {
      return "enum";
    }
    // Backward compatibility code, when type is just a string notation or undefined
    if (!type || typeof type === "string") {
      if (type === undefined) {
        return "any";
      }

      if (type === "string" && format === "date-time") {
        if (schemaType === SchemaOptions.Input) {
          if (title === "native-datetime") {
            return "datetime";
          }
          return "datetime-str";
        }
        // Output schema
        return "datetime";
      }

      if (type === "string" && format === "date") {
        if (schemaType === SchemaOptions.Input) {
          if (title === "native-date") {
            return "date";
          }
          return "date-str";
        }
        // Output schema
        return "date";
      }

      return type;
    }

    // Type is an array of types
    // Filter out "null" type value, and take what's left
    const types = type.filter<SchemaTypesBET>(
      (t): t is SchemaTypesBET => t !== "null",
    );

    // If there are more than 1 type in array
    // It is only case for any type
    if (types.length > 1) {
      return "any";
    }

    if (types[0] === "string" && format === "date-time") {
      if (schemaType === SchemaOptions.Input) {
        if (title === "native-datetime") {
          return "datetime";
        }
        return "datetime-str";
      }
      // Output schema
      return "datetime";
    }

    if (types[0] === "string" && format === "date") {
      if (schemaType === SchemaOptions.Input) {
        if (title === "native-date") {
          return "date";
        }
        return "date-str";
      }
      // Output schema
      return "date";
    }

    return types[0];
  };

  static nullableSetterBE = (type: PropertyTypeBET) => {
    if (Array.isArray(type)) {
      return type.includes("null") ? "null" : false;
    }

    if (type === undefined) {
      // Legacy `any` type, nullable by default
      return "null";
    }

    return false;
  };
}

export class SchemaConverter {
  static uiToBE(schema: SchemaUIT, schemaType: SchemaOptions): SchemaT {
    const convertedSchema: SchemaT = {
      $schema: schema.$schema,
      type: schema.type,
      properties: {},
      required: [],
      sensitive: [],
      order: [],
    };
    schema.properties.forEach((property) => {
      convertedSchema.properties = {
        ...convertedSchema.properties,
        [property.fieldName]: PropertyConverter.uiToBEProperty(
          property,
          schemaType,
        ),
      };
      if (property.required) {
        convertedSchema.required.push(property.fieldName);
      }
      // maintain schema order
      convertedSchema.order.push(property.fieldName);
      if (property.sensitive && convertedSchema.sensitive) {
        convertedSchema.sensitive.push(property.fieldName);
      }
    });
    return convertedSchema;
  }

  static beToUI(schema: SchemaT, schemaType: SchemaOptions): SchemaUIT {
    const convertedProperties: PropertyUIT[] =
      PropertyConverter.beToUiProperties(schema, schemaType);

    const sortedProperties = [...convertedProperties].sort(
      comparePropertiesByOrder(schema.order),
    );

    const defsSubSchemas = Object.fromEntries(
      Object.entries(schema.$defs ?? {}).map(([subSchemaKey, subSchema]) => {
        return [
          subSchemaKey,
          {
            ...subSchema,
            properties: PropertyConverter.beToUiProperties(
              subSchema,
              SchemaOptions.Input,
              // We always use native dates for newest schemas
              { forceNativeDates: true },
            ),
          },
        ];
      }),
    );

    return {
      $schema: schema.$schema,
      $defs: defsSubSchemas,
      type: schema.type,
      properties: sortedProperties,
    };
  }
}

const globalFeaturesKey = "FeaturesGlobal" as const;

type FeaturesKey = `Features@${string}` | typeof globalFeaturesKey;
export const entityIdToFeaturesKey = (entityId: string) =>
  `Features@${entityId}` as FeaturesKey;

// Also used as a display helper
export const getFeaturesByEntity = (
  features: FeatureResponse[],
): Record<FeaturesKey, FeatureResponse[]> => {
  return features.reduce(
    (acc: Record<string, FeatureResponse[]>, feature) => {
      if (
        // isGlobalFeature
        feature.entity_types === undefined ||
        feature.entity_types === null ||
        feature.entity_types.length === 0
      ) {
        if (globalFeaturesKey in acc) {
          acc[globalFeaturesKey].push(feature);
        } else {
          acc[globalFeaturesKey] = [feature];
        }
      } else {
        if (entityIdToFeaturesKey(feature.entity_types[0]) in acc) {
          acc[entityIdToFeaturesKey(feature.entity_types[0])].push(feature);
        } else {
          acc[entityIdToFeaturesKey(feature.entity_types[0])] = [feature];
        }
      }
      return acc;
    },
    {} as Record<string, FeatureResponse[]>,
  );
};

const featureListToJsonSchema = (features: FeatureResponse[]): SubSchemaT => {
  const props = features.reduce(
    (acc, feature) => {
      return {
        ...acc,
        [feature.key]: {
          type: feature.feature_type
            ? ([feature.feature_type] as ValueOf<typeof SchemaTypesBE>[])
            : [...anyJsonSchemaType],
          default: feature.feature_default,
        },
      };
    },
    {} as SubSchemaT["properties"],
  );
  return {
    properties: props,
    type: "object",
  };
};

export const buildSubSchemas = (
  schema: SchemaUIT,
  eventSchemas: EventConfigEnriched[],
  entitySchemas: Record<string, SubSchemaT>,
  features: FeatureResponse[],
) => {
  const hasEnrichedProperty = schema.properties.some((property) =>
    isJsonRefPath(property.type[0]),
  );
  const acc: Record<string, SubSchemaT> = hasEnrichedProperty
    ? entitySchemas
    : {};

  const featuresByEntity = getFeaturesByEntity(features);

  // Always add global features
  const globalFeatures = featuresByEntity[globalFeaturesKey];
  if (globalFeatures) {
    acc[globalFeaturesKey] = featureListToJsonSchema(globalFeatures);
  }

  // Add events to the acc
  return schema.properties.reduce((acc, property) => {
    const [propertyType] = property.type;
    if (isJsonRefPath(propertyType)) {
      const decoded = decodeJsonRefPath(propertyType);
      if (!decoded) return acc;
      const { metaType, type } = decoded;
      if (metaType === "entity") {
        // If there is an entity referenced top level, add its Features
        const featuresKey = entityIdToFeaturesKey(type);
        const features = featuresByEntity[featuresKey];
        if (features) {
          return {
            ...acc,
            [featuresKey]: featureListToJsonSchema(features),
          };
        } else {
          return acc;
        }
      }
      if (metaType === "event") {
        const eventSchema = eventSchemas?.find(
          (event) => event.event_type === type,
        );
        if (eventSchema) {
          const eventJsonSchema = eventSchema.json_schema;
          // The $defs of an event carry all entities referenced in the event schema.
          // But since we already add all entities to the schema by default we can just delete the $def here.
          const eventJsonSchemaWithoutDefs = {
            ...eventJsonSchema,
          };
          // @ts-expect-error (Will not break if $defs is not present)
          delete eventJsonSchemaWithoutDefs?.$defs;
          return {
            ...acc,
            [`Events@${type}`]: eventJsonSchemaWithoutDefs as SubSchemaT,
          };
        }
      }
    }
    return acc;
  }, acc);
};
