import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";

import { Job, JobS3BucketDatasetSource } from "../types";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useCreateJobSource, useUpdateJob } from "src/jobs/api/queries";
import { handlePreconditionError } from "src/jobs/jobUtils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  useCreateS3BucketDataset,
  useS3BucketDataset,
  useUploadFileToS3BucketDataset,
} from "src/s3BucketDataset/api/queries";
import { logger } from "src/utils/logger";

type S3BucketDatasetModalProps = {
  open: boolean;
  job: Job;
  afterLeave?: () => void;
  onClose: () => void;
  onSuccess: (source: JobS3BucketDatasetSource) => void;
};

type FormValues = {
  name: string;
};

export const S3BucketDatasetModalCreate: React.FC<
  S3BucketDatasetModalProps
> = ({ open, job, onClose, afterLeave, onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>();
  const { workspace } = useWorkspaceContext();
  const createSource = useCreateJobSource(workspace.base_url!, job.id);
  const createS3BucketDataset = useCreateS3BucketDataset(
    workspace.base_url!,
    job.flow_id,
  );
  const patchJob = useUpdateJob(workspace.base_url!, job);

  const submitCreate = async (name: string) => {
    try {
      const s3Dataset = await createS3BucketDataset.mutateAsync(name);
      const source = await createSource.mutateAsync({
        name: name,
        job_id: job.id,
        configuration: {
          type: "s3_bucket_dataset",
          s3_bucket_dataset_id: s3Dataset.id,
        },
      });
      await patchJob.mutateAsync({
        active_source: source.id,
        etag: job.etag,
      });

      toastActions.success({
        title: `S3 Bucket Source "${name}" successfully created`,
      });

      onClose();
      onSuccess(source as JobS3BucketDatasetSource);
    } catch (e) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        e,
      );
      if (!isPreconditionError) {
        toastActions.failure({
          title: "Source creation failed",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  return (
    <Modal afterLeave={afterLeave} open={open} onClose={onClose}>
      <Modal.Header description="Streamline your data processing by creating a dedicated S3 bucket for this job.">
        Create S3 Bucket
      </Modal.Header>
      <form onSubmit={handleSubmit(({ name }) => submitCreate(name))}>
        <Modal.Content noScroll>
          <Label required>Bucket Name</Label>
          <Input
            placeholder="Your Bucket Name"
            fullWidth
            {...register("name")}
          ></Input>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="s3-modal-done"
              htmlType="submit"
              loading={isSubmitting}
              role="submit"
              variant="primary"
            >
              Create Bucket
            </Button>
          }
        />
      </form>
    </Modal>
  );
};

type S3BucketDatasetModalEditProps = {
  source?: JobS3BucketDatasetSource;
  open: boolean;
  afterLeave?: () => void;
  onClose: () => void;
};

export const S3BucketDatasetModalEdit: React.FC<
  S3BucketDatasetModalEditProps
> = ({ open, onClose, afterLeave, source }) => {
  const { workspace } = useWorkspaceContext();
  const dataset = useS3BucketDataset(
    workspace.base_url!,
    source?.configuration.s3_bucket_dataset_id,
  );
  logger.log("source", source);
  const upload = useUploadFileToS3BucketDataset(
    workspace.base_url!,
    source?.id ?? "",
  );

  const onSubmitFiles = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    toastActions.loading({
      title: "Uploading source file(s)",
      description:
        "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
      duration: Infinity,
    });
    try {
      files.forEach(async (file) => {
        await upload.mutateAsync(file);
      });
    } catch {
      toastActions.failure({
        title: "Error uploading dataset files",
      });
    }
    toastActions.success({
      title: "Source file uploaded",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onSubmitFiles,
  });

  return (
    <Modal afterLeave={afterLeave} open={open} onClose={onClose}>
      <Modal.Header description="Your new S3 bucket is ready to use.">
        Bucket created
      </Modal.Header>
      <Modal.Content noScroll>
        <div className="flex flex-row rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-600 font-inter-medium-12px">
          <Icon color="text-gray-500" icon={faInfoCircle} />
          <div className="mx-1">
            Learn how to efficiently upload your files and manage your new S3
            bucket.
          </div>
          <div
            className="w-32 cursor-pointer underline font-inter-medium-12px"
            onClick={() => window.open("https://docs.taktile.com", "_blank")}
          >
            View API Docs
          </div>
        </div>
        {isFeatureFlagEnabled(FEATURE_FLAGS.powertools) && (
          <>
            <hr className="my-4" />
            <div>Admin Interface</div>
            <div>Total Rows: {dataset.data?.total_row_count}</div>
            {dataset.data?.files && <div>Files: {dataset.data?.files}</div>}
            <div>
              <div {...getRootProps()}>
                <Input {...getInputProps()} />
              </div>
            </div>
          </>
        )}
      </Modal.Content>
      <Modal.Footer primaryButton="Close" secondaryButton={false} />
    </Modal>
  );
};
