import {
  faCalculatorSimple,
  faCog,
  faDungeon,
  faEdit,
  faIndustry,
  faPlus,
  faUpload,
  faBug,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useEventListener } from "usehooks-ts";

import { AddUserModal } from "src/adminPanel/AddUserModal";
import { BootstrapFeatureStoreConfig } from "src/adminPanel/BootstrapFeatureStoreConfig";
import { CreateOrganizationModal } from "src/adminPanel/CreateOrganizationModal";
import { ModifyWsConfigModal } from "src/adminPanel/ModifyWsConfigModal";
import { ModifyWsFeatureGatesModal } from "src/adminPanel/ModifyWsFeatureGatesModal";
import { UploadFeatureStoreData } from "src/adminPanel/UploadFeatureStoreData";
import { WorkspaceDebugModal } from "src/adminPanel/WorkspaceDebugModal";
import { OrganizationT } from "src/api/flowTypes";
import {
  useDeleteOrganizationLogo,
  useUpdateOrg,
  useUpdateOrganizationLogo,
  useDeleteWorkspace,
} from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { OrgLogo } from "src/base-components/OrgLogo";
import { Pill, PillVariants } from "src/base-components/Pill";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { OrgPartner } from "src/clients/taktile-api";
import { EmptyState } from "src/design-system/EmptyState";
import { toastActions } from "src/design-system/Toast/utils";
import { WorkspaceFormModal } from "src/layout/WorkspaceFormModal/WorkspaceFormModal";
import { AuthProviders } from "src/userManagement/AuthProviders";

export const OrganizationsDisplay: React.FC<{
  org: OrganizationT;
  workspaces: WorkspaceDataplane[];
  handleOrgSelection: (orgId: string) => void;
  handleWsSelection: (wsId: string | null) => void;
  selectedOrg: string | null;
  setAddUserModalIsOpen: (isOpen: boolean) => void;
  setWorkspaceModalIsOpen: (isOpen: boolean) => void;
  filterStatus: string;
}> = ({
  org,
  workspaces,
  handleOrgSelection,
  handleWsSelection,
  setWorkspaceModalIsOpen,
  setAddUserModalIsOpen,
  filterStatus,
}) => {
  const deleteLogo = useDeleteOrganizationLogo();
  const uploadLogo = useUpdateOrganizationLogo();
  const updateOrg = useUpdateOrg();
  const [wsSelected, setWsSelected] = useState<WorkspaceDataplane | null>(null);
  const [wsModalIsOpen, setWsModalIsOpen] = useState(false);
  const [modifyWsFeatureGatesModalIsOpen, setModifyWsFeatureGatesModalIsOpen] =
    useState(false);
  const [
    bootstrapFeatureStoreConfigIsOpen,
    setBootstrapFeatureStoreConfigIsOpen,
  ] = useState(false);
  const [uploadFeatureStoreDataIsOpen, setUploadFeatureStoreDataIsOpen] =
    useState(false);
  const [debugModalIsOpen, setDebugModalIsOpen] = useState(false);
  const [isAltPressed, setIsAltPressed] = useState(false);
  const deleteWorkspace = useDeleteWorkspace();

  const variantLevels = ["gray", "green", "yellow", "red"];
  const getVariant = (level: number) => {
    return variantLevels[
      Math.min(level, variantLevels.length - 1)
    ] as PillVariants;
  };

  const filteredWorkspaces = workspaces
    .filter((ws) => ws.organization_id === org.id)
    .filter((ws) => filterStatus === "ALL" || ws.status === filterStatus);

  useEventListener("keydown", (event) => {
    if (event.altKey) {
      setIsAltPressed(true);
    }
  });

  useEventListener("keyup", (event) => {
    if (!event.altKey) {
      setIsAltPressed(false);
    }
  });

  const handleReactivateOrg = () => {
    if (confirm(`Reactivate organization ${org.name}?`)) {
      updateOrg.mutate({
        organizationId: org.id,
        is_active: true,
      });
    }
  };

  const handleDeleteWorkspace = (id: string) => {
    if (confirm(`Delete workspace?`)) {
      deleteWorkspace.mutate(id);
    }
  };

  const handleDeactivateOrg = () => {
    const maxLevel = Math.max(
      ...workspaces
        .filter((ws) => ws.organization_id === org.id)
        .map((ws) => ws.config?.profile_level ?? 0),
    );
    if (maxLevel > 0) {
      toastActions.failure({
        title: "Cannot deactivate organization with active workspaces",
        description:
          "You should set the profile level to 0 for all workspaces first.",
      });
    } else {
      if (confirm(`Deactivate organization ${org.name}?`)) {
        updateOrg.mutate({
          organizationId: org.id,
          is_active: false,
        });
      }
    }
  };

  return (
    <>
      {wsSelected && wsModalIsOpen && (
        <ModifyWsConfigModal
          isOpen={wsModalIsOpen}
          selectedWs={wsSelected}
          onClose={() => setWsModalIsOpen(false)}
        />
      )}
      {wsSelected && modifyWsFeatureGatesModalIsOpen && (
        <ModifyWsFeatureGatesModal
          isOpen={modifyWsFeatureGatesModalIsOpen}
          selectedWs={wsSelected}
          onClose={() => setModifyWsFeatureGatesModalIsOpen(false)}
        />
      )}
      {wsSelected && bootstrapFeatureStoreConfigIsOpen && (
        <BootstrapFeatureStoreConfig
          isOpen={bootstrapFeatureStoreConfigIsOpen}
          selectedWs={wsSelected}
          onClose={() => setBootstrapFeatureStoreConfigIsOpen(false)}
        />
      )}
      {wsSelected && uploadFeatureStoreDataIsOpen && (
        <UploadFeatureStoreData
          isOpen={uploadFeatureStoreDataIsOpen}
          selectedWs={wsSelected}
          onClose={() => setUploadFeatureStoreDataIsOpen(false)}
        />
      )}
      {wsSelected && debugModalIsOpen && (
        <WorkspaceDebugModal
          isOpen={debugModalIsOpen}
          selectedWs={wsSelected}
          onClose={() => setDebugModalIsOpen(false)}
        />
      )}
      <div
        key={org.id}
        className="mb-4 rounded-lg border border-gray-50 bg-white py-2 shadow-base"
      >
        <div className="flex flex-row items-center justify-between gap-2 px-3 py-2">
          <div className="flex flex-row items-center gap-2">
            <OrgLogo org={org} size="medium" />
            <h3 className="font-inter-semibold-16px">{org.name}</h3>
            {workspaces.some((ws) => ws.organization_id === org.id) && (
              <Pill
                variant={getVariant(
                  Math.max(
                    ...workspaces
                      .filter((ws) => ws.organization_id === org.id)
                      .map((ws) => ws.config?.profile_level ?? 0),
                  ),
                )}
              >
                Max Level:{" "}
                {Math.max(
                  ...workspaces
                    .filter((ws) => ws.organization_id === org.id)
                    .map((ws) => ws.config?.profile_level ?? 0),
                )}
              </Pill>
            )}
            {isAltPressed && (
              <Button size="sm" variant="warning" onClick={handleDeactivateOrg}>
                Deactivate Organization
              </Button>
            )}
            {!org.is_active && (
              <Button
                size="sm"
                variant="secondary"
                onClick={handleReactivateOrg}
              >
                Reactivate Organization
              </Button>
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            <label className="mr-2 flex flex-row items-center gap-x-1 font-inter-normal-13px">
              loanpro login
              <Checkbox
                checked={org.partner === OrgPartner.LOANPRO}
                disabled={updateOrg.isPending}
                onChange={() => {
                  updateOrg.mutate({
                    organizationId: org.id,
                    partner:
                      org.partner === OrgPartner.LOANPRO
                        ? null
                        : OrgPartner.LOANPRO,
                  });
                }}
              />
            </label>
            <label className="mr-2 flex flex-row items-center gap-x-1 font-inter-normal-13px">
              whitelabel
              <Checkbox
                checked={org.whitelabel}
                disabled={updateOrg.isPending}
                onChange={() => {
                  updateOrg.mutate({
                    organizationId: org.id,
                    whitelabel: !org.whitelabel,
                  });
                }}
              />
            </label>
            <label className="mr-2 flex flex-row items-center gap-x-1 font-inter-normal-13px">
              Allows sign up
              <Checkbox
                checked={org.allows_signup}
                disabled={updateOrg.isPending}
                onChange={() => {
                  updateOrg.mutate({
                    organizationId: org.id,
                    allows_signup: !org.allows_signup,
                  });
                }}
              />
            </label>
            <Dropzone
              onDrop={(acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file) {
                  uploadLogo.mutate({
                    organizationId: org.id,
                    logo: file,
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className:
                      "bg-gray-100 border border-dashed rounded-md px-2 py-0.5 cursor-pointer",
                  })}
                >
                  <p className="font-inter-medium-12px">
                    Click or Drop new logo here <input {...getInputProps()} />
                  </p>
                </div>
              )}
            </Dropzone>
            <Button
              loading={deleteLogo.isPending}
              size="sm"
              variant="secondary"
              onClick={() => deleteLogo.mutate({ organizationId: org.id })}
            >
              Delete existing logo
            </Button>
            <Button
              iconLeft={faPlus}
              size="sm"
              variant="secondary"
              onClick={() => {
                handleOrgSelection(org.id);
                setAddUserModalIsOpen(true);
              }}
            >
              Add user
            </Button>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/2 rounded-md p-2">
            <AuthProviders orgId={org.id} />
          </div>

          <div className="w-1/2 py-2 pl-2 pr-4">
            <div className="rounded-lg !border !border-gray-100 p-2">
              {workspaces.filter((ws) => ws.organization_id === org.id).length >
              0 ? (
                <div className="mb-1 flex w-full flex-row items-center justify-between px-2">
                  <p className="truncate py-2.5 pr-4 text-left text-gray-800 font-inter-medium-12px">
                    Workspaces
                  </p>
                  <Button
                    iconLeft={faPlus}
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      handleOrgSelection(org.id);
                      handleWsSelection(null);
                      setWorkspaceModalIsOpen(true);
                    }}
                  >
                    Add Workspace
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {(() => {
                return filteredWorkspaces.length > 0 ? (
                  filteredWorkspaces.map((ws) => (
                    <div
                      key={ws.id}
                      className="flex w-full flex-col items-center gap-2 px-2 py-1"
                    >
                      <div
                        className={`flex w-full flex-row ${
                          ws.status === "FAILURE" ? "bg-orange-100" : ""
                        }`}
                      >
                        <div
                          key={ws.id}
                          className="block flex w-full flex-row items-center truncate text-left font-inter-normal-12px"
                        >
                          <div className="mr-2">{ws.name}</div>
                          <Pill variant="outlined-gray">
                            {ws.flow_services_version}
                          </Pill>
                          {Object.entries(ws.feature_gates ?? {}).map(
                            ([key, value]) =>
                              value && (
                                <div key={key} className="mx-1">
                                  <Pill size="sm" variant="indigo-light">
                                    {key}
                                  </Pill>
                                </div>
                              ),
                          )}
                          <div className="ml-2">
                            <Pill
                              variant={getVariant(
                                ws.config?.profile_level ?? 0,
                              )}
                            >
                              Level: {ws.config?.profile_level}
                            </Pill>
                          </div>
                          {ws.status !== "SUCCESS" && (
                            <Pill variant="orange">{ws.status}</Pill>
                          )}
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faUpload}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setUploadFeatureStoreDataIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Upload Feature Store Data
                          </span>
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faCalculatorSimple}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setBootstrapFeatureStoreConfigIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Bootstrap Feature Store Config
                          </span>
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faEdit}
                            size="xs"
                            onClick={() => {
                              handleWsSelection(ws.id);
                              handleOrgSelection(org.id);
                              setWorkspaceModalIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Edit Workspace
                          </span>
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faCog}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setWsModalIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Workspace Settings
                          </span>
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faDungeon}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setModifyWsFeatureGatesModalIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Modify Feature Gates
                          </span>
                        </div>
                        <div className="group relative mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            icon={faBug}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setDebugModalIsOpen(true);
                            }}
                          />
                          <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                            Debug Information
                          </span>
                        </div>
                        {!org.is_active && (
                          <Button
                            size="sm"
                            variant="warning"
                            onClick={() => handleDeleteWorkspace(ws.id)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                      <div className="h-px w-full rounded-full bg-gray-100"></div>
                    </div>
                  ))
                ) : (
                  <div className="h-60">
                    <EmptyState
                      actionButton={{
                        icon: faPlus,
                        text: "Add Workspace",
                        onClick: () => {
                          handleOrgSelection(org.id);
                          handleWsSelection(null);
                          setWorkspaceModalIsOpen(true);
                        },
                      }}
                      dataLoc="admin-org-no-workspaces"
                      description="There are currently no workspaces available for this organization!"
                      headline="No Workspaces available"
                      icon={faIndustry}
                    ></EmptyState>
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const OrganizationsWorkspacesSettings: React.FC<{
  organizations: OrganizationT[];
  workspaces: WorkspaceDataplane[];
  isActive: boolean;
}> = ({ organizations, workspaces, isActive }) => {
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [selectedWs, setSelectedWs] = useState<string | null>(null);
  const [workspaceModalIsOpen, setWorkspaceModalIsOpen] = useState(false);
  const [orgModalIsOpen, setOrgModalIsOpen] = useState(false);

  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState<string>("ALL");
  const [sortOption, setSortOption] = useState<string>("NAME_ASC");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [includeWithoutWorkspaces, setIncludeWithoutWorkspaces] =
    useState<boolean>(isActive);

  const workspaceStatusCounts = {
    ALL: workspaces.length,
    SUCCESS: workspaces.filter((ws) => ws.status === "SUCCESS").length,
    FAILURE: workspaces.filter((ws) => ws.status === "FAILURE").length,
  };

  const filteredAndSortedOrganizations = [...organizations]
    .filter((org) => org.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((org) =>
      includeWithoutWorkspaces
        ? true
        : workspaces.some((ws) => ws.organization_id === org.id),
    )
    .sort((a, b) => {
      switch (sortOption) {
        case "NAME_ASC":
          return a.name.localeCompare(b.name);
        case "NAME_DESC":
          return b.name.localeCompare(a.name);
        case "WORKSPACE_COUNT_ASC":
          return (
            workspaces.filter((ws) => ws.organization_id === a.id).length -
            workspaces.filter((ws) => ws.organization_id === b.id).length
          );
        case "WORKSPACE_COUNT_DESC":
          return (
            workspaces.filter((ws) => ws.organization_id === b.id).length -
            workspaces.filter((ws) => ws.organization_id === a.id).length
          );
        default:
          return 0;
      }
    });

  const orgsWithoutWorkspacesCount = organizations.filter(
    (org) => !workspaces.some((ws) => ws.organization_id === org.id),
  ).length;

  return (
    <>
      <div className="mb-4 flex items-center gap-4">
        <input
          className="rounded border p-1"
          placeholder="Search organizations..."
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="rounded border p-1"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="ALL">All ({workspaceStatusCounts.ALL})</option>
          <option value="SUCCESS">
            Success ({workspaceStatusCounts.SUCCESS})
          </option>
          <option value="FAILURE">
            Failure ({workspaceStatusCounts.FAILURE})
          </option>
        </select>

        <select
          className="rounded border p-1"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="NAME_ASC">Organization Name (A-Z)</option>
          <option value="NAME_DESC">Organization Name (Z-A)</option>
          <option value="WORKSPACE_COUNT_ASC">
            Workspace Count (Low-High)
          </option>
          <option value="WORKSPACE_COUNT_DESC">
            Workspace Count (High-Low)
          </option>
        </select>
        <label className="flex flex-row items-center gap-x-1 font-inter-normal-13px">
          <Checkbox
            checked={includeWithoutWorkspaces}
            onChange={() =>
              setIncludeWithoutWorkspaces(!includeWithoutWorkspaces)
            }
          />
          Show organizations without workspaces ({orgsWithoutWorkspacesCount})
        </label>
        {isActive && (
          <Button onClick={() => setOrgModalIsOpen(true)}>
            Create an Organisation
          </Button>
        )}
      </div>
      <div>
        <div className="m-1">
          {filteredAndSortedOrganizations.map((org) => {
            const orgWorkspaces = workspaces.filter(
              (ws) => ws.organization_id === org.id,
            );
            const filteredOrgWorkspaces = orgWorkspaces.filter(
              (ws) => filterStatus === "ALL" || ws.status === filterStatus,
            );

            if (filterStatus !== "ALL" && filteredOrgWorkspaces.length === 0) {
              return null;
            }

            return (
              <OrganizationsDisplay
                key={org.id}
                filterStatus={filterStatus}
                handleOrgSelection={setSelectedOrg}
                handleWsSelection={setSelectedWs}
                org={org}
                selectedOrg={selectedOrg}
                setAddUserModalIsOpen={setAddUserModalIsOpen}
                setWorkspaceModalIsOpen={setWorkspaceModalIsOpen}
                workspaces={workspaces}
              />
            );
          })}
        </div>
      </div>
      {workspaceModalIsOpen && !!selectedOrg && (
        <>
          <WorkspaceFormModal
            isOpen={workspaceModalIsOpen && !!selectedOrg && !selectedWs}
            orgId={selectedOrg || ""}
            variant="Create"
            onClose={() => setWorkspaceModalIsOpen(false)}
          />
          <WorkspaceFormModal
            isOpen={workspaceModalIsOpen && !!selectedOrg && !!selectedWs}
            orgId={selectedOrg || ""}
            variant="Edit"
            wsId={selectedWs || ""}
            onClose={() => setWorkspaceModalIsOpen(false)}
          />
        </>
      )}
      <CreateOrganizationModal
        isOpen={orgModalIsOpen}
        onClose={() => setOrgModalIsOpen(false)}
      />
      <AddUserModal
        isOpen={addUserModalIsOpen}
        selectedOrg={selectedOrg ?? ""}
        onClose={() => setAddUserModalIsOpen(false)}
      />
    </>
  );
};
