import { useMemo } from "react";
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useWatch,
} from "react-hook-form";

import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { isFeatureFlagEnabled, FEATURE_FLAGS } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitySchemaHint } from "src/schema/EntitySchemaHint";
import { SchemaGlobalFeatures } from "src/schema/SchemaGlobalFeatures";
import { getEventSchemaOptions } from "src/schema/SchemaTypeSelector";
import {
  decodeJsonRefPath,
  isJsonRefPath,
  isReferencingEvent,
  JsonRefPath,
} from "src/schema/jsonRefUtils";
import { FieldAccessInfo } from "src/schema/schemaEditor/FieldAccessInfo";
import { FEATURES_PROPERTY } from "src/schema/schemaEditor/utils";
import { getFeaturesByEntity, SchemaUIT } from "src/schema/schemaMappingUtils";

export const EventSchemaEditor: React.FC<{
  formMethods: UseFormReturn<SchemaUIT>;
}> = ({ formMethods }) => {
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();
  const { data: eventSchemas } = useEventSchemas({
    enabled:
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
      featureGates.featuresEventsEnabled,
  });
  const schemasOptions = getEventSchemaOptions(eventSchemas ?? []);

  const { data: entitySchemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.entitiesEnabled,
    },
  });

  const properties = useWatch({
    control: formMethods.control,
    name: "properties",
  });
  const eventProperty = properties.find(
    (property) =>
      isJsonRefPath(property.type[0]) &&
      isReferencingEvent(property.type[0] as JsonRefPath),
  );
  const selectedEventSchema = eventProperty?.type[0];

  const { data: features } = useFeatures({
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.featuresEventsEnabled,
    },
  });

  const featuresByEntity = useMemo(
    () => getFeaturesByEntity(features ?? []),
    [features],
  );

  const hasNoSchemas = !eventSchemas || eventSchemas?.length === 0;

  const decodedRef = decodeJsonRefPath(selectedEventSchema ?? "");
  const selectedEvent =
    decodedRef?.metaType === "event"
      ? eventSchemas?.find(
          (eventSchema) => eventSchema.internal_name === decodedRef.type,
        )
      : undefined;

  return (
    <>
      <FormProvider {...formMethods}>
        <Controller
          name="properties"
          render={({ field }) => (
            <FixedSimpleDropdown
              buttonDataLoc="schema-field-type"
              disabled={hasNoSchemas}
              elements={schemasOptions}
              placeholder={
                hasNoSchemas ? "No schemas available" : "Select type"
              }
              placement="bottomLeft"
              selectedKey={field.value?.[0]?.type[0]}
              onSelect={(newType) => {
                const selectedSchema = schemasOptions.find(
                  (schema) => schema.key === newType,
                );
                field.onChange([
                  {
                    fieldName: selectedSchema?.internalName ?? "",
                    required: true,
                    sensitive: false,
                    type: [newType as JsonRefPath, false],
                  },
                  FEATURES_PROPERTY,
                ]);
              }}
            />
          )}
        />
      </FormProvider>
      {selectedEventSchema && (
        <FieldAccessInfo
          inputType="event"
          schemaName={eventProperty?.fieldName}
        />
      )}
      {entitySchemasData && (
        <EntitySchemaHint
          entitySchemas={entitySchemasData.entities}
          eventType={selectedEvent}
          featuresByEntity={featuresByEntity}
          rootPath={`data.${eventProperty?.fieldName}`}
        />
      )}
      <SchemaGlobalFeatures />
    </>
  );
};
