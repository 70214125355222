import { Outlet, useOutletContext } from "react-router-dom";

import { DashboardContent } from "src/layout/DashboardContent";
import { LayoutHeader } from "src/layout/Header";
import { MainSideBar } from "src/mainPage/MainSideBar";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

export const MainPageDashboard: React.FC = () => {
  const context = useOutletContext();
  return (
    <DashboardContent
      Header={
        !isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && <LayoutHeader />
      }
      sidebar={
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && <MainSideBar />
      }
      removePadding
    >
      <Outlet context={context} />
    </DashboardContent>
  );
};
