import React, { useCallback, useMemo } from "react";
import { useSessionStorage } from "usehooks-ts";

import { ErroredRow, NodeTestRunResult } from "src/api/types";
import { FixedPositionedDropdownElementT } from "src/base-components/FixedPositionedDropDown";
import { NODE_TYPE } from "src/constants/NodeTypes";
import {
  getIntermediateResultColumns,
  StatusColumnProps,
  StatusFilterKey,
} from "src/dataTable/TableUtils";
import { useSelectedDatasetId } from "src/datasets/store/useSelectedDatasetId";
import { useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { IntermediateResultsTableV2 } from "src/nodeEditor/IntermediateResultsTableV2";
import { RowCountInfo } from "src/nodeEditor/RowCountInfo";
import { usePaginatedResults } from "src/nodeEditor/usePaginatedResults";
import { useSetEditDatasetId } from "src/router/SearchParams";
import { isFeatureFlagEnabled, FEATURE_FLAGS } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";

type Props = {
  testResult: NodeTestRunResult;
  showErrorInEditor: (error: ErroredRow) => void;
  openEditor: () => void;
  fieldNameToSourceNode?: Map<string, { id: string; type: NODE_TYPE }>;
};

export const IntermediateResultTableWrapper: React.FC<Props> = ({
  testResult,
  showErrorInEditor,
  openEditor,
  fieldNameToSourceNode,
}) => {
  const { workspace } = useAuthoringContext();
  const featureGates = useWorkspaceFeatureGates();
  const { selectedDatasetId } = useSelectedDatasetId();
  const { lastRunDatasetId, selectedNode, setSelectedNode, nodes } =
    useGraphStore();
  const isOutputNode = selectedNode?.type === NODE_TYPE.OUTPUT_NODE;

  const getNodeName = useCallback(
    (nodeId: string) => nodes.get(nodeId)?.data.label,
    [nodes],
  );

  const [statusFilter, setStatusFilter] = useSessionStorage<StatusFilterKey>(
    `status-filter-${testResult.test_run_id}-${testResult.node_id}`,
    "all",
  );
  const {
    resultsToDisplay,
    fetchAdditionalData,
    isFetching,
    additionalDataCanBeFetched,
    ignoredRowsQuery,
    failureRowsQuery,
  } = usePaginatedResults(testResult, isOutputNode, statusFilter);

  const displayExpectedOutput =
    (testResult.expected_output_columns?.length ?? 0) > 0;

  const statusElements: FixedPositionedDropdownElementT<
    string,
    StatusFilterKey
  >[] = useMemo(
    () => [
      { key: "all", value: "All" },
      ...(displayExpectedOutput
        ? ([
            {
              key: "success_match",
              value: "Successful",
              disabled: testResult.success_count === 0,
            },
            {
              key: "success_mismatch",
              value: "Output Mismatch",
              disabled: testResult.expected_output_mismatch_count === 0,
            },
          ] as FixedPositionedDropdownElementT<string, StatusFilterKey>[])
        : ([
            {
              key: "success",
              value: "Successful",
              disabled: testResult.success_count === 0,
            },
          ] as FixedPositionedDropdownElementT<string, StatusFilterKey>[])),
      {
        key: "failure",
        value: "Failed",
        disabled: testResult.failure_count === 0,
      },
      {
        key: "ignored",
        value: "Ignored",
        disabled: testResult.ignored_count === 0,
      },
    ],
    [testResult, displayExpectedOutput],
  );

  const statusFilterProps: StatusColumnProps = useMemo(
    () => ({
      isFiltering: statusFilter !== "all",
      onSelect: (key) => setStatusFilter(key),
      selected: statusFilter,
      elements: statusElements,
    }),
    [statusFilter, statusElements, setStatusFilter],
  );

  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url ?? "",
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.entitiesEnabled,
    },
  });

  const { data: eventSchemas } = useEventSchemas({
    enabled:
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
      featureGates.featuresEventsEnabled,
  });
  const columns = useMemo(() => {
    return getIntermediateResultColumns(
      testResult.data_columns,
      testResult.aux_data_columns,
      testResult.expected_output_columns,
      statusFilterProps,
      fieldNameToSourceNode,
      entitySchemas?.entities ?? [],
      eventSchemas ?? [],
      testResult.typing,
      featureGates,
    );
  }, [
    testResult,
    statusFilterProps,
    fieldNameToSourceNode,
    entitySchemas?.entities,
    eventSchemas,
    featureGates,
  ]);

  const onErrorClick = useCallback(
    (errorIndex: number) => {
      const failureDataFlatened = failureRowsQuery.data?.pages
        .map((page) => page.data)
        .flat();
      const clickedError = failureDataFlatened?.find(
        (failure) => failure.index === errorIndex,
      );
      if (clickedError) {
        // In case of the Output node the error can originate in another node. We want to open that node.
        if (clickedError.node_id && clickedError.node_id !== selectedNode?.id) {
          setSelectedNode(clickedError.node_id);
        }
        showErrorInEditor(clickedError);
      }
    },
    [
      failureRowsQuery.data?.pages,
      selectedNode?.id,
      setSelectedNode,
      showErrorInEditor,
    ],
  );

  const onIgnoredClick = useCallback(
    (ignoredIndex: number) => {
      const ignoredDataFlatened = ignoredRowsQuery.data?.pages
        .map((page) => page.data)
        .flat();
      const clickedIgnored = ignoredDataFlatened?.find(
        (ignore) => ignore.index === ignoredIndex,
      );
      // In case of the Output node the ignored row can originate in another node. We want to open that node.
      if (clickedIgnored?.node_id !== undefined) {
        if (clickedIgnored.node_id !== selectedNode?.id) {
          setSelectedNode(clickedIgnored.node_id);
        }
        openEditor();
      }
    },
    [
      ignoredRowsQuery.data?.pages,
      openEditor,
      selectedNode?.id,
      setSelectedNode,
    ],
  );

  const setEditDatasetId = useSetEditDatasetId();
  const onIgnoredTooltipClick = useCallback(() => {
    if (selectedDatasetId) {
      setEditDatasetId(selectedDatasetId);
    }
  }, [selectedDatasetId, setEditDatasetId]);

  return (
    <IntermediateResultsTableV2
      canFetchNextBatch={additionalDataCanBeFetched}
      columns={columns}
      data={resultsToDisplay}
      displayingOutputNode={selectedNode?.type === NODE_TYPE.OUTPUT_NODE}
      fetchNextBatch={fetchAdditionalData}
      getNodeName={getNodeName}
      isFetching={isFetching}
      lastRunDatasetID={lastRunDatasetId ?? undefined}
      rowCountInfo={
        <RowCountInfo
          activeSuccessFilter={
            displayExpectedOutput ? "success_match" : "success"
          }
          currentFilter={statusFilterProps.selected}
          failureCount={testResult.failure_count}
          ignoredCount={testResult.ignored_count}
          mismatchCount={
            displayExpectedOutput
              ? testResult.expected_output_mismatch_count
              : undefined
          }
          successCount={testResult.success_count}
          onFilterChange={statusFilterProps.onSelect}
        />
      }
      statusFilter={statusFilter}
      testResult={testResult}
      onErrorClick={onErrorClick}
      onIgnoredClick={onIgnoredClick}
      onIgnoredTooltipClick={onIgnoredTooltipClick}
    />
  );
};
