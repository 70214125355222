import {
  faChevronDown,
  faPlus,
  faArrowDownToLine,
} from "@fortawesome/pro-regular-svg-icons";
import { ButtonHTMLAttributes, ReactNode, useState } from "react";
import { twJoin } from "tailwind-merge";

import { useFlowVersion } from "src/api/flowVersionQueries";
import { DatasetPurpose, GLOBAL_FEATURES_TYPE } from "src/api/types";
import {
  FixedPositionedDropdown,
  FixedPositionedDropdownElementT,
} from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import {
  useAvailableColumns,
  useAvailableIntegrationNodes,
} from "src/datasets/DatasetTable/hooks";
import {
  getEnrichedSchemaInputFields,
  getEnrichedSchemaOutputFields,
} from "src/datasets/DatasetTable/utils";
import { DecideDropzone } from "src/datasets/Dropzone";
import {
  expandEntitySchema,
  getEntityTemplate,
} from "src/datasets/dataTemplateUtils";
import {
  createTemplateContent,
  downloadDatasetTemplate,
  useSchemas,
} from "src/datasets/utils";
import { findSchema } from "src/entities/entityView/utils";
import { useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { getEventTemplate } from "src/eventsCatalogue/utils";
import { useEntityRelatedFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { isFeatureFlagEnabled, FEATURE_FLAGS } from "src/router/featureFlags";
import {
  useFlowContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import { useParamsDecode } from "src/utils/useParamsDecode";

const MenuItem: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { hideDefaultIcon?: boolean }
> = ({ children, hideDefaultIcon = false, ...rest }) => (
  <button
    {...rest}
    className="flex w-full items-center gap-x-2 py-3 pl-4 pr-8 text-left text-gray-800 font-inter-normal-12px"
    type="button"
  >
    {!hideDefaultIcon && (
      <Icon color="text-gray-500" icon={faPlus} size="2xs" />
    )}
    {children}
  </button>
);

type UploadMenuItemProps = {
  children: ReactNode;
  close: () => void;
  onFilesDropped: (files: File[], errors: string[]) => Promise<void>;
};

const UploadMenuItem: React.FC<UploadMenuItemProps> = ({
  children,
  close,
  onFilesDropped,
}) => {
  const handleFilesDropped = async (files: File[], errors: string[]) => {
    await onFilesDropped(files, errors);
    close();
  };

  return (
    <DecideDropzone
      content={({ getInputProps, getRootProps }) => (
        <div {...getRootProps({ onClick: (e) => e.preventDefault() })}>
          <MenuItem data-loc="dataset-upload">
            {children}
            <input {...getInputProps()} />
          </MenuItem>
        </div>
      )}
      onSubmitFiles={handleFilesDropped}
    />
  );
};

const useTemplateData = () => {
  const { flow } = useFlowContext();
  const { version_id: flowVersionId } = useParamsDecode();
  const { data: flowVersion } = useFlowVersion(flowVersionId);
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();

  const { data: schemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: { enabled: featureGates.entitiesEnabled },
  });

  const { data: eventTypes } = useEventSchemas({
    enabled: featureGates.featuresEventsEnabled,
  });
  const integrationNodes = useAvailableIntegrationNodes(flowVersion);
  const schemas = useSchemas(flowVersion);
  const { data: features } = useEntityRelatedFeatures({
    options: {
      enabled: featureGates.featuresEventsEnabled,
    },
  });

  const emptyDataset = {
    name: "",
    flow_id: flow.id,
    source: "scratch" as const,
    purpose: "test" as DatasetPurpose,
    input_columns: [],
    output_columns: [],
    mock_columns: [],
    outcome_columns: [],
    id: "",
    created_at: "",
    updated_at: "",
    etag: "",
  };

  const {
    availableInputColumns,
    availableMockColumns,
    availableOutputColumns,
    availableOutcomeColumns,
  } = useAvailableColumns(emptyDataset, schemas, integrationNodes);

  const enrichedInputColumns = getEnrichedSchemaInputFields(schemas.input);
  const enrichedOutputColumns = getEnrichedSchemaOutputFields(schemas.output);

  const inputColumns = availableInputColumns.inputFields.map((field) => {
    const enrichedInput = enrichedInputColumns.find(
      (input) => input.name === field,
    );
    if (
      enrichedInput?.id &&
      enrichedInput?.type === "Entities" &&
      featureGates.entitiesEnabled
    ) {
      const relationSchema = findSchema(enrichedInput.id, schemasData);
      if (relationSchema) {
        const expandedSchema = expandEntitySchema(
          relationSchema,
          schemasData?.entities ?? [],
          4,
        );
        return {
          name: field,
          template: getEntityTemplate(
            expandedSchema,
            features ?? [],
            featureGates,
          ),
          type: enrichedInput.type,
        };
      }
    }
    if (
      enrichedInput?.type === "Events" &&
      featureGates.featuresEventsEnabled
    ) {
      const eventTypeData = eventTypes?.find(
        (event) => event.event_type === enrichedInput.id,
      );
      const properties = eventTypeData?.properties;
      return {
        name: field,
        template: getEventTemplate(
          properties,
          schemasData,
          features ?? [],
          featureGates,
        ),
        type: enrichedInput.type,
      };
    }
    return {
      name: field,
      type:
        enrichedInput?.type ||
        (field === GLOBAL_FEATURES_TYPE && featureGates.featuresEventsEnabled
          ? "object"
          : "string"),
    };
  });

  const outputColumns = availableOutputColumns.outputFields.map((field) => {
    const enrichedOutput = enrichedOutputColumns.find(
      (output) => output.name === field,
    );
    return { name: field, type: enrichedOutput?.type || "string" };
  });

  return {
    availableInputColumns: inputColumns,
    availableMockColumns,
    availableOutputColumns: outputColumns,
    availableOutcomeColumns,
  };
};

const getDatasetItemsArchetype = (
  showTemplateDownloads: boolean,
): FixedPositionedDropdownElementT<string, string>[] => [
  { key: "scratch", value: "From scratch" },
  { key: "upload", value: "Upload file" },
  { key: "divider", value: "", disabled: true },
  { key: "assemble", value: "From historical decisions" },
  ...(showTemplateDownloads
    ? [
        { key: "divider", value: "", disabled: true },
        { key: "download_csv", value: "Download CSV template" },
        { key: "download_json", value: "Download JSON template" },
      ]
    : []),
];

export const AddDatasetDropdown: React.FC<{
  onFilesDropped: (files: File[], errors: string[]) => Promise<void>;
  onAssemble: () => void;
  onCreate: () => void;
  placement?: "bottomRight" | "bottom";
  size?: "base" | "sm";
}> = ({
  onFilesDropped,
  onAssemble,
  onCreate,
  placement = "bottomRight",
  size = "base",
}) => {
  const [disabled, setDisabled] = useState(false);
  const templateData = useTemplateData();

  const handleCreate = async () => {
    try {
      setDisabled(true);
      await onCreate();
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FixedPositionedDropdown
      dataLoc="create-dataset-dropdown"
      disabled={disabled}
      elements={getDatasetItemsArchetype(
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase),
      )}
      placement={placement}
      renderButtonValue={() => (
        <div
          className={twJoin(
            "flex select-none items-center gap-x-0.5 rounded-md text-gray-800 shadow-sm font-inter-medium-12px",
            disabled && "opacity-50",
            size === "sm" && "px-1.5 py-0.5",
            size === "base" && "px-3 py-1.5",
          )}
        >
          <Icon color="text-gray-500" icon={faPlus} size="2xs" />
          <span>Create test dataset</span>
          <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
        </div>
      )}
      renderValue={(val, close) => {
        switch (val.key) {
          case "upload":
            return (
              <UploadMenuItem close={close} onFilesDropped={onFilesDropped}>
                {val.value}
              </UploadMenuItem>
            );
          case "assemble":
            return (
              <MenuItem data-loc="assemble-dataset" onClick={onAssemble}>
                {val.value}
              </MenuItem>
            );
          case "scratch":
            return (
              <MenuItem data-loc="create-from-scratch" onClick={handleCreate}>
                {val.value}
              </MenuItem>
            );
          case "download_csv":
            return (
              <MenuItem
                data-loc="download-csv-template"
                hideDefaultIcon
                onClick={() => {
                  const content = createTemplateContent(templateData, "csv");
                  downloadDatasetTemplate(content, "csv");
                }}
              >
                <div className="flex items-center gap-x-2">
                  <Icon
                    color="text-gray-500"
                    icon={faArrowDownToLine}
                    size="2xs"
                  />
                  {val.value}
                </div>
              </MenuItem>
            );
          case "download_json":
            return (
              <MenuItem
                data-loc="download-json-template"
                hideDefaultIcon
                onClick={() => {
                  const content = createTemplateContent(templateData, "json");
                  downloadDatasetTemplate(content, "json");
                }}
              >
                <div className="flex items-center gap-x-2">
                  <Icon
                    color="text-gray-500"
                    icon={faArrowDownToLine}
                    size="2xs"
                  />
                  {val.value}
                </div>
              </MenuItem>
            );
          case "divider":
            return <hr className="my-2.5 cursor-default" />;
        }
      }}
      onSelect={(key) => key === "assemble" && onAssemble()}
    />
  );
};
