import { faInfoCircle, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { JSONValue } from "@segment/analytics-next";
import { Column } from "@tanstack/react-table";
import { last } from "lodash";
import { ReactNode, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

import { DatasetRow, DecisionEnvironment, DesiredType } from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { CellId, getColumnResource } from "src/datasets/DatasetTable/utils";
import { ValidationResult } from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import {
  ButtonItem,
  ControllableDropdown,
} from "src/datasets/components/ControllableDropdown";
import { DefaultInputCell } from "src/datasets/components/DefaultInputCell";
import { ResourceSearchPane } from "src/datasets/components/ResourceSearchPane";
import {
  ErrorDetails,
  getErrorDetails,
} from "src/datasets/components/errorUtils";
import { useEnrichedEntityOnSelect } from "src/datasets/components/useEnrichedEntityOnSelect";
import { useShowDefaultEditor } from "src/datasets/components/useShowDefaultEditor";
import { Tooltip } from "src/design-system/Tooltip";
import { EntitySchemaResource, useEntities } from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

/**
 * Currently we assume that only primary and secondary properties are searchable
 * and they are always primitive values string, number or enums
 */
export const SearchableEntityCell: React.FC<{
  isOpen: boolean;
  cellId: CellId;
  column: Column<DatasetRow>;
  disabled: boolean;
  desiredType: DesiredType;
  error: ValidationResult | undefined;
  value: string;
  onChange: (value: string) => void;
  onResetError: () => void;
  onSetLocalValue: (value: string) => void;
  warning: boolean;
  postfix: ReactNode;
  onChangeEntityValue: (value: JSONValue) => void;
}> = ({
  isOpen,
  cellId,
  column,
  disabled,
  desiredType,
  error,
  value,
  onChange,
  onResetError,
  onSetLocalValue,
  warning,
  postfix,
  onChangeEntityValue,
}) => {
  const [showDefaultEditor, setShowDefaultEditor] = useShowDefaultEditor(
    cellId,
    value,
  );
  const resource = getColumnResource(column);

  if (!resource || showDefaultEditor) {
    return (
      <DefaultInputCell
        cellId={cellId}
        desiredType={desiredType}
        disabled={disabled}
        error={error}
        postfix={postfix}
        value={value}
        warning={warning}
        onChange={onChange}
        onResetError={onResetError}
        onSetLocalValue={onSetLocalValue}
      />
    );
  }

  return (
    <SearchableEntityRenderer
      cellId={cellId}
      column={column}
      entitySchema={resource as EntitySchemaResource}
      error={getErrorDetails(error, value, onChange)}
      isOpen={isOpen}
      onChangeEntityValue={(value) => {
        onChangeEntityValue(value);
        setShowDefaultEditor();
      }}
      onNewValue={setShowDefaultEditor}
    />
  );
};

const SearchableEntityRenderer: React.FC<{
  cellId: CellId;
  isOpen: boolean;
  onNewValue: () => void;
  onChangeEntityValue: (value: JSONValue) => void;
  column: Column<DatasetRow>;
  entitySchema: EntitySchemaResource;
  error: ErrorDetails | undefined;
}> = ({
  cellId,
  isOpen,
  onNewValue,
  column,
  entitySchema,
  onChangeEntityValue,
  error,
}) => {
  const { workspace } = useWorkspaceContext();
  const [env, setEnv] = useSessionStorage<DecisionEnvironment>(
    "datasets-searchable-entity-env",
    DecisionEnvironment.LIVE,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const columnName = last(column.id.split("."));

  const { data: entities, isLoading } = useEntities({
    baseUrl: workspace.base_url!,
    env: env,
    schema: entitySchema._id,
    filters:
      searchQuery.length > 0 ? [`${columnName}:${searchQuery}*`] : undefined,
    limit: 5,
  });

  const foundEntities = entities?.pages[0].entities;

  const { onSelect, isLoading: isLoadingEnrichedEntity } =
    useEnrichedEntityOnSelect({
      entitySchema: entitySchema._id,
      env,
      onDataReady: (data: JSONValue) => {
        onChangeEntityValue(data);
      },
    });

  return (
    <CellWrapper cellId={cellId} error={error}>
      <ControllableDropdown
        isOpen={isOpen}
        placeholder="Select value"
        postfix={
          <Tooltip
            title="Select a value or search from your database. Other fields in the dataset will auto-fill based on the entity's properties."
            asChild
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
          </Tooltip>
        }
        width="w-96"
      >
        <ButtonItem icon={faPlus} label="New value" onClick={onNewValue} />
        <Divider spacing="my-2" />
        <ResourceSearchPane
          filters={{
            environment: env,
            searchQuery,
          }}
          isLoading={isLoading}
          items={
            columnName && foundEntities
              ? foundEntities.map((entity) => ({
                  label: entity.properties[columnName] as string,
                  key: entity._id,
                  isFetching: isLoadingEnrichedEntity(entity._id),
                }))
              : []
          }
          resourceName={entitySchema._display_name_plural}
          searchPlaceholder={`Search for ${entitySchema._display_name_singular} by ${column.columnDef.meta?.archetype?.name}`}
          onChangeFilters={(filters) => {
            setEnv(filters.environment);
            setSearchQuery(filters.searchQuery);
          }}
          onSelect={(item) => onSelect(item.key)}
        />
      </ControllableDropdown>
    </CellWrapper>
  );
};
