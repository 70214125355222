import {
  faFlask,
  faPlus,
  faWavePulse,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { ManageQueryModal } from "./ManageQueryModal";
import { Queries } from "./Queries";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { useModal } from "src/design-system/Modal";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { FeatureCatalogueWrapper } from "src/featureCatalogue/FeatureCatalogue";
import { useCapabilities } from "src/hooks/useCapabilities";

const QueriesHeader: React.FC = () => {
  const [environment, setEnvironment] = useEnvironment();
  const { openModal, closeModal, isOpen } = useModal();
  const { featureQueriesCatalogue } = useCapabilities();

  const dropdownItems: OptionsDropdownElement[] = [
    {
      key: "Create Query",
      icon: faPlus,
      action: openModal,
      disabled: !featureQueriesCatalogue.canCreate
        ? "You don't have permission to create queries"
        : undefined,
    },
    MENU_DIVIDER,
    {
      key: `Switch to ${environment === "sandbox" ? "Live" : "Sandbox"}`,
      icon: environment === "sandbox" ? faWavePulse : faFlask,
      action: () =>
        setEnvironment(environment === "sandbox" ? "live" : "sandbox"),
    },
  ] as const;

  return (
    <>
      <span className="border-l border-gray-200 p-3.5">
        <EllipsisOptionsDropdown
          buttonDataLoc="queries-menu-dropdown"
          elements={dropdownItems}
          iconSize="sm"
          placement="bottom-start"
        />
      </span>

      <ManageQueryModal open={isOpen} onClose={closeModal} />
    </>
  );
};

export const QueriesPage: React.FC = () => {
  return (
    <FeatureCatalogueWrapper header={<QueriesHeader />}>
      <Queries />
    </FeatureCatalogueWrapper>
  );
};
