import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";

export const ItemSkeleton = () => (
  <li
    className="h-[72px] rounded-lg border border-gray-200 px-5 pt-3.5"
    data-loc="dataset-item-skeleton"
  >
    <div className="flex items-center gap-x-2">
      <SkeletonPlaceholder height="h-4" rounded="rounded-lg" width="w-4" />
      <SkeletonPlaceholder height="h-3" width="w-56" />
    </div>
    <div className="w- mt-2.5 pl-6">
      <SkeletonPlaceholder height="h-3" width="w-80" />
    </div>
  </li>
);
