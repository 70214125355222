import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { faEyeSlash as faEyeSlashSolid } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import {
  CommonSchemaEditRowPropsT,
  SchemaUIT,
} from "src/schema/schemaMappingUtils";

const PiiDescriptionActive =
  "This field contains personally identifiable information and is made unavailable during backtesting and exports.";
const PiiDescriptionInactive =
  "Enable this toggle for fields that contain personally identifiable information, so that they can be made unavailable during backtesting and exports.";

export const SchemaSensitiveToggle: React.FC<CommonSchemaEditRowPropsT> = ({
  rowName,
  disabled,
}) => {
  const { control } = useFormContext<SchemaUIT>();
  return (
    <Controller
      control={control}
      name={`${rowName}.sensitive`}
      render={(props) => (
        <Tooltip
          body={
            props.field.value ? PiiDescriptionActive : PiiDescriptionInactive
          }
          placement="right"
          title={
            props.field.value
              ? "This field is not stored in Decision History"
              : "Do not store in Decision History"
          }
          asChild
        >
          <div className="flex items-center">
            <Icon
              color={
                props.field.value
                  ? "text-indigo-600"
                  : "text-gray-500 hover:text-gray-700"
              }
              dataLoc={`schema-field-sensitive-${props.field.value}`}
              disabled={disabled}
              icon={props.field.value ? faEyeSlashSolid : faEyeSlash}
              size="xs"
              onClick={() => props.field.onChange(!props.field.value)}
            />
          </div>
        </Tooltip>
      )}
    />
  );
};
