import { twJoin } from "tailwind-merge";

import ManyToOneIconSvg from "src/assets/icons/ManyToOne.svg?react";
import OneToManyIconSvg from "src/assets/icons/OneToMany.svg?react";
import { getIconSize, IconSizes } from "src/base-components/Icon";
import { Cardinality } from "src/entities/queries";
import { tailwindSizeToPixels } from "src/utils/tailwindcss";
import { assertUnreachable } from "src/utils/typeUtils";

type Props = {
  color?: `text-gray-${number}`;
  width: number;
  height: number;
};

export const ManyToOneRelationIcon: React.FC<Props> = ({
  color = "text-gray-500",
  width,
  height,
}) => (
  <span className={twJoin("flex justify-center p-1", color)}>
    <ManyToOneIconSvg height={height} width={width} />
  </span>
);

export const OneToManyRelationIcon: React.FC<Props> = ({
  color = "text-gray-500",
  width,
  height,
}) => (
  <span className={twJoin("flex justify-center p-1", color)}>
    <OneToManyIconSvg height={height} width={width} />
  </span>
);

type RelationIconProps = Pick<Props, "color"> & {
  cardinality: Cardinality | undefined;
  size: IconSizes;
};

export const RelationIcon: React.FC<RelationIconProps> = ({
  cardinality = Cardinality.ONE,
  size = "base",
  ...rest
}) => {
  const { width: widthSize, height: heightSize } = getIconSize(size);
  const width = tailwindSizeToPixels(widthSize);
  const height = tailwindSizeToPixels(heightSize);

  switch (cardinality) {
    case Cardinality.ONE:
      return <ManyToOneRelationIcon {...rest} height={height} width={width} />;
    case Cardinality.ONE_TO_MANY:
      return <OneToManyRelationIcon {...rest} height={height} width={width} />;
    default:
      return assertUnreachable(cardinality);
  }
};
