import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useFormContext, UseFormRegisterReturn } from "react-hook-form";

import { Input } from "src/base-components/Input";
import { handleIntegerInput } from "src/baseConnectionNode/utils";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  dataLoc?: string;
  immutable: boolean;
  formProps: UseFormRegisterReturn;
  errorMessage?: string;
  maxTimeout?: number;
  unsetValue?: any;
};

export const TimeoutInput = ({
  dataLoc,
  immutable,
  formProps,
  errorMessage,
  maxTimeout,
  unsetValue = "",
}: PropsT) => {
  const { getValues, setValue } = useFormContext();

  const [isErrored, setIsErrored] = useState(
    !!maxTimeout && Number(getValues(formProps.name)) > maxTimeout,
  );

  return (
    <Tooltip body={errorMessage} disabled={!isErrored}>
      <Input
        data-loc={dataLoc}
        disabled={immutable}
        errored={isErrored}
        placeholder="Enter timeout in seconds"
        suffixIcon={
          isErrored
            ? {
                icon: faExclamationTriangle,
                variant: "danger",
              }
            : undefined
        }
        {...formProps}
        onChange={(e) => {
          const newValue = e.target.value === "" ? unsetValue : e.target.value;
          setValue(formProps.name, newValue);

          if (newValue !== unsetValue) {
            setIsErrored(!!maxTimeout && Number(newValue) > maxTimeout);
          }
        }}
        onKeyDown={handleIntegerInput}
      />
    </Tooltip>
  );
};
