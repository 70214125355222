import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowUpRight,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";
import { twJoin } from "tailwind-merge";

import { Pill } from "src/base-components/Pill";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToEntityDetailPage } from "src/router/urls";

type LinkedEntityPillProps = {
  name: string;
  id: string;
  schemaId: string;
  entityIcon?: IconProp;
  fullWidth?: boolean;
};

export const LinkedEntityPill: React.FC<LinkedEntityPillProps> = ({
  name,
  id,
  schemaId,
  entityIcon = faQuestionCircle,
  fullWidth = true,
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const url =
    getBaseUrl() + getUrlToEntityDetailPage(orgId, workspace.id, schemaId, id);

  return (
    <a
      className={twJoin(fullWidth ? "w-full" : "w-fit")}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <Pill size="sm" variant="gray" fullWidth>
        <Pill.Icon icon={entityIcon} />
        <Pill.Text>{capitalize(name)}</Pill.Text>
        <Pill.Icon icon={faArrowUpRight} />
      </Pill>
    </a>
  );
};
