import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { PillToggle } from "src/base-components/PillToggle";
import { Tooltip } from "src/design-system/Tooltip";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import {
  isReferencingEntity,
  JsonRefPath,
  isJsonRefPath,
  isReferencingEvent,
} from "src/schema/jsonRefUtils";
import {
  FEATURES_PROPERTY,
  schemaContainsFeatures,
} from "src/schema/schemaEditor/utils";
import { SchemaUIT } from "src/schema/schemaMappingUtils";

export enum SchemaViewType {
  EVENT = "Event",
  ENTITY = "Entity",
  CUSTOM = "Custom",
}
type SchemaTogglesProps = {
  activeView: SchemaViewType;
  setActiveView: (view: SchemaViewType) => void;
  formMethods: UseFormReturn<SchemaUIT>;
};

export const SchemaToggles = ({
  activeView,
  setActiveView,
  formMethods,
}: SchemaTogglesProps) => {
  const properties = useWatch({
    control: formMethods.control,
    name: "properties",
  });
  const featureGates = useWorkspaceFeatureGates();

  const hasNoProperties = properties.length === 0;
  const hasOneProperty = properties.length === 1;
  const hasTwoProperties = properties.length === 2;

  const containsEvent = properties.some(
    (property) =>
      isJsonRefPath(property.type[0]) &&
      isReferencingEvent(property.type[0] as JsonRefPath),
  );

  const containsEntity = properties.some(
    (property) =>
      isJsonRefPath(property.type[0]) &&
      isReferencingEntity(property.type[0] as JsonRefPath),
  );

  const containsFeatures = schemaContainsFeatures(properties);

  const addGlobalFeatures = () => {
    if (!containsFeatures && (containsEvent || containsEntity)) {
      formMethods.setValue("properties", [
        ...formMethods.getValues("properties"),
        FEATURES_PROPERTY,
      ]);
    }
  };

  const eventSchemaEnabled =
    hasNoProperties ||
    (hasOneProperty && (containsEvent || containsFeatures)) ||
    (hasTwoProperties && containsEvent && containsFeatures);

  const entitySchemaEnabled =
    hasNoProperties ||
    (hasOneProperty && (containsEntity || containsFeatures)) ||
    (hasTwoProperties && containsEntity && containsFeatures);

  return (
    <div className="mb-4">
      <PillToggle value={activeView} onChange={setActiveView}>
        <>
          {featureGates.featuresEventsEnabled && (
            <Tooltip
              disabled={eventSchemaEnabled}
              title="Input schema must contain only a single Event."
              asChild
            >
              <PillToggle.Button
                disabled={!eventSchemaEnabled}
                selected={activeView === SchemaViewType.EVENT}
                value={SchemaViewType.EVENT}
                onChange={() => {
                  setActiveView(SchemaViewType.EVENT);
                  addGlobalFeatures();
                }}
              >
                Event
              </PillToggle.Button>
            </Tooltip>
          )}
        </>
        <>
          {featureGates.entitiesEnabled && (
            <Tooltip
              disabled={entitySchemaEnabled}
              title="Input schema must contain only a single Entity."
              asChild
            >
              <PillToggle.Button
                disabled={!entitySchemaEnabled}
                selected={activeView === SchemaViewType.ENTITY}
                value={SchemaViewType.ENTITY}
                onChange={() => {
                  setActiveView(SchemaViewType.ENTITY);
                  addGlobalFeatures();
                }}
              >
                Entity
              </PillToggle.Button>
            </Tooltip>
          )}
        </>
        <PillToggle.Button
          value={SchemaViewType.CUSTOM}
          onChange={() => setActiveView(SchemaViewType.CUSTOM)}
        >
          Custom
        </PillToggle.Button>
      </PillToggle>
    </div>
  );
};
