import { useMatch } from "react-router-dom";

const PATH_PREFIX = "/org/:orgId/ws/:wsId";

export const useCurrentPage = () => {
  const isEntityViewPage = Boolean(
    useMatch(`${PATH_PREFIX}/entities/:schema/:id/*`),
  );
  const isFeaturesPage = Boolean(useMatch(`${PATH_PREFIX}/features/*`));
  const isEventsPage = Boolean(useMatch(`${PATH_PREFIX}/events/*`));
  const isSettingsPage = Boolean(useMatch(`${PATH_PREFIX}/settings/*`));
  const isFlowPage = Boolean(useMatch(`${PATH_PREFIX}/flows/:flowId/*`));
  const isAuthoringPage = Boolean(useMatch(`${PATH_PREFIX}/flow/*`));
  const isEntitiesPage = Boolean(useMatch(`${PATH_PREFIX}/entities/:schema`));

  return {
    isEntityViewPage,
    isFeaturesPage,
    isEventsPage,
    isSettingsPage,
    isFlowPage,
    isAuthoringPage,
    isEntitiesPage,
  };
};
