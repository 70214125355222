import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import React from "react";

import { Pill } from "src/base-components/Pill";
import { EntityCaseSummaryStatusEnum } from "src/clients/entities/api";

type EntityReviewStatusProps = {
  status: EntityCaseSummaryStatusEnum;
};

export const EntityReviewStatus = ({ status }: EntityReviewStatusProps) => {
  const reviewRequired = status === EntityCaseSummaryStatusEnum.REVIEW_REQUIRED;

  return (
    <div className="pr-1">
      <Pill size="sm" variant={reviewRequired ? "indigo-light" : "gray"}>
        <Pill.Icon icon={faUserCircle} />
        <Pill.Text>
          {reviewRequired ? "Review required" : "No pending reviews"}
        </Pill.Text>
      </Pill>
    </div>
  );
};
