import { format } from "date-fns";
import { get, isEmpty, keyBy } from "lodash";
import { useMemo } from "react";

import {
  ProviderResourceT,
  ResourceSample,
  ResourceT,
} from "src/api/connectApi/types";
import { FlowVersionT, SchemaTypesT } from "src/api/flowTypes";
import {
  Dataset,
  DatasetColumn,
  DesiredType,
  DesiredTypeValues,
} from "src/api/types";
import { ConnectionDataSourcesValues } from "src/baseConnectionNode/types";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { CustomConnectionConfig } from "src/customConnectionNode/types";
import {
  AvailableOutcomeColumns,
  getOutcomeDatasetColumns,
} from "src/datasets/DatasetTable/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { SchemaOptions } from "src/router/SearchParams";
import { useFlowContext } from "src/router/routerContextHooks";
import {
  DecodedJsonRefPath,
  decodeJsonRefPath,
  isJsonRefPath,
  JsonRefPath,
  JsonRefPathPrefix,
} from "src/schema/jsonRefUtils";
import {
  SchemaConverter,
  SchemaUIT,
  SubSchemaUIT,
} from "src/schema/schemaMappingUtils";

export type DatasetIssue =
  | {
      issueType: "missing-column";
      schemaType: DesiredType;
    }
  | {
      issueType: "type-mismatch";
      schemaType: DesiredType;
      datasetType: DesiredType;
      complexType?: DecodedJsonRefPath;
    }
  | { issueType: "extra-column"; datasetType: DesiredType };

export type DatasetIssues = Record<string, DatasetIssue>;

export const schemaTypeToDesiredType = (
  schemaType: SchemaTypesT,
): DesiredType => {
  if (schemaType === "date-str") return DesiredTypeValues.date;
  else if (schemaType === "datetime-str") return DesiredTypeValues.datetime;
  else if (isJsonRefPath(schemaType)) return DesiredTypeValues.object;
  else return schemaType;
};

export const getRefSchema = (
  schemasDefs: SchemaUIT["$defs"],
  ref: JsonRefPath,
): SchemaUIT | SubSchemaUIT => {
  const refName = ref.slice(JsonRefPathPrefix.length);
  const refSchema = get(schemasDefs, refName);

  if (!refSchema) {
    throw new Error(`Schema reference ${ref} not found`);
  }

  return refSchema;
};

export const EMPTY_ISSUES: DatasetIssues = {};

export const findCompatibilityIssues = (
  dataset: Dataset,
  schema?: SchemaUIT,
): DatasetIssues => {
  if (!schema) return EMPTY_ISSUES;

  const issues: DatasetIssues = {};

  const datasetColumnsByName = keyBy(dataset.input_columns, "name");

  // Check that all columns in the schema are present in the dataset with correct type
  schema.properties.forEach((prop) => {
    const datasetColumn: DatasetColumn | null =
      datasetColumnsByName[prop.fieldName] ?? null;

    const schemaColumnType = schemaTypeToDesiredType(prop.type[0]);

    if (!datasetColumn) {
      if (prop.required) {
        issues[prop.fieldName] = {
          issueType: "missing-column",
          schemaType: schemaColumnType,
        };
      }
    } else if (
      !COMPATIBILITY_TYPE_MAP[datasetColumn.desired_type].includes(
        schemaColumnType,
      )
    ) {
      issues[prop.fieldName] = {
        issueType: "type-mismatch",
        schemaType: schemaColumnType,
        complexType: decodeJsonRefPath(prop.type[0]),
        datasetType: datasetColumn.desired_type,
      };
    }
  });

  // Check that all columns in the dataset are present in the schema
  dataset.input_columns.forEach((column) => {
    const schemaColumn = schema.properties.find(
      (prop) => prop.fieldName === column.name,
    );
    if (!schemaColumn) {
      issues[column.name] = {
        issueType: "extra-column",
        datasetType: column.desired_type,
      };
    }
  });

  // Return stable reference to issues if no issues are present
  return isEmpty(issues) ? EMPTY_ISSUES : issues;
};

const COMPATIBLE_ISSUES = ["extra-column", "type-mismatch"];
export const isDatasetCompatible = (
  dataset: Dataset,
  inputSchemas?: SchemaUIT,
): boolean => {
  const datasetIssues = findCompatibilityIssues(dataset, inputSchemas);

  return Object.values(datasetIssues).every((issue) =>
    COMPATIBLE_ISSUES.includes(issue.issueType),
  );
};

// This is read as "if the column type is `key` then it is compatible
// with the schema type if it is among `COMPATIBILITY_TYPE_MAP[key]` "
const COMPATIBILITY_TYPE_MAP: Record<DesiredType, DesiredType[]> = {
  string: ["any", "string"],
  boolean: ["any", "boolean"],
  number: ["any", "number"],
  integer: ["any", "number", "integer"],
  object: ["any", "object"],
  array: ["any", "array"],
  datetime: ["any", "string", "datetime"],
  date: ["any", "string", "date"],
  any: ["any"],
  enum: ["any", "enum"],
};

export const buildUiSchemas = (
  version: Pick<FlowVersionT, "input_schema" | "output_schema">,
) => ({
  input:
    version.input_schema && Object.keys(version.input_schema).length !== 0
      ? SchemaConverter.beToUI(version.input_schema, SchemaOptions.Input)
      : undefined,
  output:
    version.output_schema && Object.keys(version.output_schema).length !== 0
      ? SchemaConverter.beToUI(version.output_schema, SchemaOptions.Output)
      : undefined,
});

export const useSchemas = (
  version: Pick<FlowVersionT, "input_schema" | "output_schema"> | undefined,
) => {
  return useMemo(
    () =>
      buildUiSchemas({
        input_schema: version?.input_schema,
        output_schema: version?.output_schema,
      }),
    // Putting only the needed keys here instead of the whole version is a significant
    // reduction on-calculations when used with an object from react-query
    // (Nested object that are not changed maintain identity, changes bubble up though)
    [version?.input_schema, version?.output_schema],
  );
};

export const useInputColumnsFromSchema = (
  version: FlowVersionT | undefined,
): DatasetColumn[] => {
  const { input: inputSchema } = useSchemas(version);

  if (!inputSchema) return [];

  return inputSchema.properties.map((property) => ({
    name: property.fieldName,
    desired_type: schemaTypeToDesiredType(property.type[0]),
    use_subflow_mocks: false,
  }));
};

export const useOutcomeColumnsFromOutcomeTypes = () => {
  const { flow } = useFlowContext();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });

  return getOutcomeDatasetColumns(outcomeTypes ?? []).map((column) => ({
    name: column.columnName,
    desired_type: schemaTypeToDesiredType(column.property.type[0]),
    use_subflow_mocks: false,
  }));
};

export const separateFilenameExtension = (
  filename: string,
): { name: string; extension?: string } => {
  const RECOGNIZED_EXTENSIONS = ["json", "csv", "xlsx"];

  const filenameSegments = filename.split(".");

  if (filenameSegments.length < 2) {
    return { name: filename };
  }

  const possibleExtensionSegment = filenameSegments.at(-1)!;

  if (RECOGNIZED_EXTENSIONS.includes(possibleExtensionSegment.toLowerCase())) {
    return {
      name: filenameSegments.slice(0, -1).join("."),
      extension: possibleExtensionSegment,
    };
  } else {
    return { name: filename };
  }
};

export const isDatasetEmpty = (dataset: Dataset | undefined): boolean =>
  dataset?.input_columns.length === 0 &&
  dataset?.output_columns.length === 0 &&
  dataset?.mock_columns.length === 0 &&
  dataset?.outcome_columns.length === 0;

export const MOCK_EXTERNAL_DATA_DOCS_URL =
  "https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1/edit-datasets-and-resolve-incompatibility#external-mock-data";

export type DatasetIntegrationNode = {
  id: string;
  name: string;
  provider:
    | ProviderResourceT["provider"]
    | NODE_TYPE.FLOW_NODE
    | NODE_TYPE.LOOP_NODE
    | NODE_TYPE.AI_NODE_V2;
  resource: ResourceT | null;
  mediaKey: string | null;
  testingConfig: CustomConnectionConfig["testing"] | null;
  environmentsConfig: CustomConnectionConfig["environments_config"] | null;
  localSampleReports: ResourceSample[] | null;
  mockableChildNodes: DatasetIntegrationNode[] | null;
};

export const isIntegrationNodeWithLiveConnection = (
  node?: DatasetIntegrationNode,
): boolean => {
  if (node?.environmentsConfig) {
    // If environmentsConfig is present, this a CC Node with the new format
    const { authoring_mode_data_source } = node.environmentsConfig;

    return authoring_mode_data_source
      ? [
          ConnectionDataSourcesValues.production,
          ConnectionDataSourcesValues.sandbox,
        ].includes(authoring_mode_data_source)
      : false;
  }

  // For backwards compatibility on the old format of the CC node.
  return Boolean(node?.testingConfig?.use_fallback_live_connection);
};

export const DATASET_JOB_TIMEOUT =
  "We couldn't process this dataset under 15 minutes. Please try again.";

type TemplateField = {
  name: string;
  type: string;
  template?: any;
};

const getDefaultValueBasedOnType = (type: string) => {
  switch (type) {
    case "string":
      return "";
    case "date":
    case "date-str":
      return format(new Date(), "yyyy-MM-dd");
    case "datetime":
    case "datetime-str":
      return new Date().toISOString();
    case "enum":
      return "";
    case "boolean":
      return false;
    case "number":
    case "integer":
      return 0;
    case "object":
      return {};
    case "array":
      return [];
    default:
      return "";
  }
};

export const createCsvTemplateData = (
  inputFields: TemplateField[],
  mockFields: { name: string; subMocks?: { name: string }[] | null }[],
  outputFields: TemplateField[],
  outcomeFields: AvailableOutcomeColumns[],
): {
  flatTemplateRow: Record<string, any>;
  headers: Record<string, string>;
} => {
  const flatTemplateRow: Record<string, any> = {};
  const headers: Record<string, string> = {};

  inputFields.forEach((field) => {
    const key = `input.${field.name}`;
    const header = `request__data__${field.name}`;
    flatTemplateRow[key] = "";
    headers[key] = header;
  });

  mockFields.forEach((mock) => {
    if (mock.subMocks) {
      mock.subMocks.forEach((subMock) => {
        const key = `mock.${mock.name}.${subMock.name}`;
        const header = `external__data__${mock.name}__${subMock.name}`;
        flatTemplateRow[key] = "";
        headers[key] = header;
      });
    } else {
      const key = `mock.${mock.name}`;
      const header = `external__data__${mock.name}`;
      flatTemplateRow[key] = "";
      headers[key] = header;
    }
  });

  outputFields.forEach((field) => {
    const key = `output.${field.name}`;
    const header = `response__data__${field.name}`;
    flatTemplateRow[key] = "";
    headers[key] = header;
  });

  outcomeFields.forEach((field) => {
    const key = `outcome.${field.outcomeKey}.${field.property.fieldName}`;
    const header = `outcome__data__${field.outcomeKey}__${field.property.fieldName}`;
    flatTemplateRow[key] = "";
    headers[key] = header;
  });

  return { flatTemplateRow, headers };
};

export const createJsonTemplateData = (
  inputFields: TemplateField[],
  mockFields: { name: string; subMocks?: { name: string }[] | null }[],
  outputFields: TemplateField[],
  outcomeFields: AvailableOutcomeColumns[],
) => {
  const jsonTemplate = {
    request: {
      data: {} as Record<string, any>,
    },
    external: {
      data: {} as Record<string, any>,
    },
    response: {
      data: {} as Record<string, any>,
    },
    outcome: {
      data: {} as Record<string, Record<string, any>>,
    },
  };

  inputFields.forEach((field) => {
    jsonTemplate.request.data[field.name] =
      field.template || getDefaultValueBasedOnType(field.type);
  });

  mockFields.forEach((mock) => {
    jsonTemplate.external.data[mock.name] = {
      data: {},
      insights: {},
    };
  });

  outputFields.forEach((field) => {
    jsonTemplate.response.data[field.name] = getDefaultValueBasedOnType(
      field.type,
    );
  });

  const outcomesByKey = outcomeFields.reduce(
    (acc, field) => {
      if (!acc[field.outcomeKey]) {
        acc[field.outcomeKey] = {};
      }
      acc[field.outcomeKey][field.property.fieldName] =
        getDefaultValueBasedOnType(field.property.type[0]);
      return acc;
    },
    {} as Record<string, Record<string, any>>,
  );

  jsonTemplate.outcome.data = outcomesByKey;

  return jsonTemplate;
};

export const downloadDatasetTemplate = (
  content: string,
  fileType: "csv" | "json",
) => {
  const toastId = `template-download-${fileType}`;

  try {
    toastActions.loading({
      id: toastId,
      title: "Preparing your download...",
      description: "Creating template file...",
    });

    const mimeType = fileType === "csv" ? "text/csv" : "application/json";
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);

    toastActions.success({
      id: toastId,
      duration: Infinity,
      title: "Download ready",
      description: `Your ${fileType.toUpperCase()} template file is ready for download.`,
      actionText: "Click here to download",
      onActionClick: () => {
        const link = document.createElement("a");
        link.href = url;
        link.download = `dataset_template.${fileType}`;
        link.click();
        URL.revokeObjectURL(url);
      },
    });
  } catch {
    toastActions.failure({
      id: toastId,
      title: "Download failed",
      description: `Failed to create ${fileType.toUpperCase()} template file.`,
    });
  }
};

export const createTemplateContent = (
  templateData: {
    availableInputColumns: TemplateField[];
    availableMockColumns: {
      mockFields: { name: string; subMocks?: { name: string }[] | null }[];
    };
    availableOutputColumns: TemplateField[];
    availableOutcomeColumns: { outcomeFields: AvailableOutcomeColumns[] };
  },
  fileType: "csv" | "json",
): string => {
  if (fileType === "csv") {
    const { flatTemplateRow, headers } = createCsvTemplateData(
      templateData.availableInputColumns,
      templateData.availableMockColumns.mockFields,
      templateData.availableOutputColumns,
      templateData.availableOutcomeColumns.outcomeFields,
    );

    const headerRow = Object.keys(flatTemplateRow)
      .map((key) => headers[key])
      .join(",");
    const valueRow = Object.values(flatTemplateRow).join(",");
    return [headerRow, valueRow].join("\n");
  }

  const jsonTemplateRow = createJsonTemplateData(
    templateData.availableInputColumns,
    templateData.availableMockColumns.mockFields,
    templateData.availableOutputColumns,
    templateData.availableOutcomeColumns.outcomeFields,
  );

  return JSON.stringify([jsonTemplateRow], null, 2);
};
