import {
  faBuildings,
  faCreditCard,
  faCube,
  faMoneyFromBracket,
  faShop,
  faStore,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { pick, uniq } from "lodash";
import { useSearchParams } from "react-router-dom";

import { RelatedListPage } from "src/clients/entities/api";
import {
  EnumColor,
  EnumValue,
  EventConfigEnriched,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import {
  EntityEnumValue,
  EntityPropertyValue,
  EntityResource,
  EntityResourceDataplane,
  EntityResourceProperties,
  EntitySchemaProperty,
  EntitySchemaResource,
  EntitySchemaResourceDataplane,
  EntitySchemaResourcePage,
  GroupedRelation,
  RelatedEntitiesPage,
  TableRow,
} from "src/entities/queries";

export const entityIcons = {
  user: faUser,
  credit_card: faCreditCard,
  buildings: faBuildings,
  store: faStore,
  money_from_bracket: faMoneyFromBracket,
  merchant: faShop,
} as const;

const comparePropertiesByOrderOrDisplayName = (
  a: [string, EntitySchemaProperty],
  b: [string, EntitySchemaProperty],
) => {
  const aOrder = a[1]?._order ?? Infinity;
  const bOrder = b[1]?._order ?? Infinity;

  if (aOrder === Infinity && bOrder === Infinity) {
    const aDisplayName = a[1]?._display_name;
    const bDisplayName = b[1]?._display_name;
    return aDisplayName?.localeCompare(bDisplayName ?? "") ?? 0;
  }
  return aOrder - bOrder;
};

export const getOrderedProperties = (
  schema: EntitySchemaResource,
): string[] => {
  const properties = Object.entries(schema.properties ?? {})
    .toSorted(comparePropertiesByOrderOrDisplayName)
    .map(([key]) => key);

  return uniq(
    [
      schema._primary_property,
      schema._secondary_property,
      ...properties,
    ].filter(Boolean),
  );
};

export const getOrderedPropertiesForEvent = (
  eventType: EventConfigEnriched,
) => {
  const properties = Object.entries(eventType.properties ?? {})
    .toSorted(
      (a, b) =>
        a[1]?.display_name?.localeCompare(b[1]?.display_name ?? "") ?? 0,
    )
    .map(([key]) => key);

  return properties;
};

export const getOrderedPropertiesByKey = (
  schema: EntitySchemaResource,
): string[] => {
  const properties = Object.entries(schema.properties ?? {})
    .toSorted((a, b) =>
      a[0]?.toLocaleLowerCase()?.localeCompare(b[0].toLocaleLowerCase()),
    )
    .map(([key]) => key);

  return uniq(
    [
      schema._primary_property,
      schema._secondary_property,
      ...properties,
    ].filter(Boolean),
  );
};

export const getTableData = (
  entitySchema: EntitySchemaResource | undefined,
  entity: EntityResource | EntityResourceProperties | undefined,
) => {
  if (!entitySchema || !entity) {
    return [];
  }

  const tableData: TableRow[] = [];

  if ("id" in entitySchema.properties && "_id" in entity) {
    tableData.push({
      value: entity._id,
      _display_name: entitySchema.properties.id._display_name,
      _indexed: true,
      _type: PropertyDefinitionOutputTypeEnum.STRING,
      isId: true,
    });
  }

  const orderedFields = getOrderedProperties(entitySchema).filter(
    (propertyKey) => propertyKey !== "id",
  );

  orderedFields.forEach((fieldName) => {
    const value = entity.properties[fieldName];

    tableData.push({
      value: value as EntityPropertyValue,
      ...entitySchema.properties[fieldName],
    });
  });

  return tableData;
};

export const findSchema = (
  schema: string,
  data: EntitySchemaResourcePage | undefined,
): EntitySchemaResource | undefined => {
  const entitySchema = data?.entities.find((entity) => entity._id === schema);
  return entitySchema ? (entitySchema as EntitySchemaResource) : undefined;
};

const SidepaneTypeParam = "resource-type" as const;
const SidepaneIdParam = "resource-id" as const;
const EventTypeParam = "event-type" as const;

export type SidepaneType = "event";

type ToggleSidepaneParams<T extends SidepaneType> = {
  id: string;
  eventType?: T extends "event" ? string | null : null;
} | null;

type UseSidepaneReturn<T extends SidepaneType> = {
  resourceId: string | null;
  eventType: T extends "event" ? string | null : null;
  toggleSidepane: (params: ToggleSidepaneParams<T>) => void;
};

export const useSidepane = <T extends SidepaneType>(
  type: T,
): UseSidepaneReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceType = searchParams.get(SidepaneTypeParam);
  const resourceId = searchParams.get(SidepaneIdParam);

  const toggleSidepane = (params: ToggleSidepaneParams<T>) => {
    setSearchParams((prev) => {
      if (params) {
        prev.set(SidepaneTypeParam, type);
        prev.set(SidepaneIdParam, params.id);
        if (params.eventType) {
          prev.set(EventTypeParam, params.eventType);
        }
      } else {
        prev.delete(SidepaneTypeParam);
        prev.delete(SidepaneIdParam);
        prev.delete(EventTypeParam);
      }
      return prev;
    });
  };

  return {
    resourceId: resourceType === type ? resourceId : null,
    eventType: (type === "event"
      ? searchParams.get(EventTypeParam)
      : null) as T extends "event" ? string | null : null,
    toggleSidepane,
  };
};

export const getEntityKeys = (
  entity: EntitySchemaResourceDataplane | EntityResourceDataplane,
) => {
  const [reservedKeys, userKeys] = [
    Object.keys(entity).filter((key) => key[0] === "_"),
    Object.keys(entity).filter((key) => key[0] !== "_"),
  ];

  return {
    ...pick(entity, reservedKeys),
    properties: pick(entity, userKeys),
  };
};

export const getSchemaIcon = (schema: EntitySchemaResource) => {
  return (
    entityIcons[schema._display_symbol as keyof typeof entityIcons] ?? faCube
  );
};

export const convertFilters = (filters: Record<string, string[]>): string[] =>
  Object.entries(filters).map(([key, value]) => `${key}:${value.join(",")}`);

export const transformToFrontendEntity = <S extends string>(entity: object) => {
  const [reservedKeys, userKeys] = [
    Object.keys(entity).filter((key) => key[0] === "_"),
    Object.keys(entity).filter((key) => key[0] !== "_"),
  ];

  return {
    ...pick(entity, reservedKeys),
    properties: pick(entity, userKeys),
  } as S extends "schemas" ? EntitySchemaResource : EntityResource;
};

export const transformRelations = (
  relatedPage: RelatedListPage,
): RelatedEntitiesPage => {
  const groupedByProperty = relatedPage.relations.reduce(
    (acc, relation) => {
      const [schemaId, propertyName] = [
        relation.property.entity_schema,
        relation.property.property,
      ];
      const key = `${schemaId}:${propertyName}`;

      if (!acc[key]) {
        acc[key] = {
          schemaId: relation.property.entity_schema,
          entitySchemaId: (relation.entity as EntityResourceDataplane)._schema,
          property: propertyName,
          entities: [],
        };
      }

      acc[key].entities.push(transformToFrontendEntity(relation.entity));

      return acc;
    },
    {} as Record<string, GroupedRelation>,
  );

  return {
    relations: Object.values(groupedByProperty),
    next_page_token: relatedPage.next_page_token,
  };
};

export const transformEnumValue = (
  values: EntityEnumValue[],
  lookupValue: string,
): EnumValue | undefined => {
  const enumVal = values.find(
    (enumVal) => enumVal._internal_name === lookupValue,
  );

  if (enumVal) {
    return {
      color: enumVal._color as EnumColor,
      value: enumVal._internal_name,
    };
  }
  return undefined;
};
