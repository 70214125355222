import { logger } from "src/utils/logger";

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    logger.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    logger.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

const createHiddenDiv = (text: string) => {
  // This is needed to have a way to access the copied clipboard text from cypress
  if (document.getElementById("clipboard-text")) {
    document.getElementById("clipboard-text")?.remove();
  }
  const hiddenDiv = document.createElement("div");

  hiddenDiv.setAttribute("data-loc", "clipboard-text");
  hiddenDiv.setAttribute("id", "clipboard-text");
  const textNode = document.createTextNode(text);
  hiddenDiv.appendChild(textNode);
  hiddenDiv.style.display = "none";
  document.body.appendChild(hiddenDiv);
};

export const copyTextToClipboard = async (text: string) => {
  createHiddenDiv(text);
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    return navigator.clipboard.writeText(text).then(
      () => {
        logger.log("Async: Copying to clipboard was successful!");
      },
      (err) => {
        logger.error("Async: Could not copy text: ", err);
      },
    );
  }
};
