import { v4 as uuidV4 } from "uuid";

import {
  getDefaultSocureAddress,
  SOCURE_FIRST_NAME_HINT,
  SOCURE_LAST_NAME_HINT,
  SOCURE_DATE_OF_BIRTH_HINT,
  SOCURE_NATIONAL_ID_HINT,
  SOCURE_PHONE_NUMBER_HINT,
  SOCURE_EMAIL_HINT,
  SOCURE_USER_CONSENT_HINT,
  SOCURE_USER_CONSENT_TIMESTAMP_HINT,
} from "src/connections/connectionConfigs/socure/shared";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingsT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getBasicSocureInputMapping = (): InputMappingsT => ({
  ungrouped: {
    first_name: {
      id: uuidV4(),
      type: "text",
      displayName: "First name",
      assignedTo: "",
      hint: SOCURE_FIRST_NAME_HINT,
    },
    last_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Last name",
      assignedTo: "",
      hint: SOCURE_LAST_NAME_HINT,
    },
    full_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Full name",
      assignedTo: "",
      hint: `The name of a non-person entity, such as a business, aircraft, or ship. This field is required if "first name" and "last name" are not provided and must not exceed 240 characters.`,
    },
    date_of_birth: {
      id: uuidV4(),
      type: "text",
      displayName: "Date of birth",
      assignedTo: "",
      rules: { required: true },
      hint: SOCURE_DATE_OF_BIRTH_HINT,
    },
    national_id: {
      id: uuidV4(),
      type: "text",
      displayName: "National ID",
      assignedTo: "",
      rules: { required: true },
      hint: SOCURE_NATIONAL_ID_HINT,
    },
    phone_number: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone number",
      assignedTo: "",
      rules: { required: true },
      hint: SOCURE_PHONE_NUMBER_HINT,
    },
    email: {
      id: uuidV4(),
      type: "text",
      displayName: "Email",
      assignedTo: "",
      hint: SOCURE_EMAIL_HINT,
    },
    user_consent: {
      id: uuidV4(),
      type: "text",
      displayName: "User consent",
      assignedTo: "",
      hint: SOCURE_USER_CONSENT_HINT,
    },
    consent_timestamp: {
      id: uuidV4(),
      type: "text",
      displayName: "Consent timestamp",
      assignedTo: "",
      hint: SOCURE_USER_CONSENT_TIMESTAMP_HINT,
    },
  },
  grouped: {
    address: getDefaultSocureAddress(),
  },
  lists: {},
});

const getExtendedSocureInputMapping = (): InputMappingsT => {
  const input = getBasicSocureInputMapping();
  input.ungrouped.email.rules = {
    ...input.ungrouped.email.rules,
    required: true,
  };
  input.ungrouped.company_name = {
    id: uuidV4(),
    type: "text",
    displayName: "Company name",
    assignedTo: "",
    hint: `The name of the consumer's employer.`,
  };
  return input;
};

const getSocureAddressInsights = (): { [key: string]: OutputMappingT } => ({
  name_address_correlation: {
    ...getNamedOutputMapping("Name-address correlation"),
    hint: `A number between 0.01 (no correlation found) and 0.99 (high confidence) that indicates the strength of the relationship between the physical address and the consumer's first name and last name. For a detailed guide on interpreting this insight, please refer to our docs.`,
  },
  address_risk_score: {
    ...getNamedOutputMapping("Address risk"),
    hint: `A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with a physical address. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.`,
  },
});

const getSocureKYCInsight = (): { [key: string]: OutputMappingT } => ({
  kyc_validations: {
    ...getNamedOutputMapping("KYC validations"),
    hint: `An object that maps consumer PII to Boolean values (True/False). The Booleans indicate whether the information provided by the consumer correlates with the best-matched entity. The object's (optional) keys are: "first_name", "sur_name", "physical_address", "city", "state", "zip", "mobile_number", "dob" (i.e., date of birth), and "ssn" (corresponding to the "national_id" input field).`,
  },
});

const getSocureGlobalWatchlistInsight = (): {
  [key: string]: OutputMappingT;
} => ({
  is_on_global_watchlist: {
    ...getNamedOutputMapping("Is on global watchlist"),
    hint: `A Boolean (True/False) that indicates if any of the consumer's information was found on Socure's Global Watchlist - a collection of sanctions and enforcement lists, as well as Politically Exposed Persons (PEP) and adverse media registries worldwide. If True, you can find more granular information under the "global_watchlist" section of the report.`,
  },
});

const getSocureStandardBundleInsights = (): {
  [key: string]: OutputMappingT;
} => ({
  ...getSocureKYCInsight(),
  sigma_fraud_score: {
    ...getNamedOutputMapping("Sigma fraud score"),
    hint: `A probabilistic value between 0.001 and 0.999 that predicts the inherent fraud risk associated with the input PII. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.`,
  },
  ...getSocureAddressInsights(),
  name_email_correlation: {
    ...getNamedOutputMapping("Name-email correlation"),
    hint: `A number between 0.01 (no correlation found) and 0.99 (high confidence) that indicates the strength of the relationship between the email and the consumer's first name and last name. For a detailed guide on interpreting this insight, please refer to our docs.`,
  },
  email_risk_score: {
    ...getNamedOutputMapping("Email risk"),
    hint: `A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with an email address. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.`,
  },
  name_phone_correlation: {
    ...getNamedOutputMapping("Name-phone correlation"),
    hint: `A number between 0.01 (no correlation found) and 0.99 (high confidence) that indicates the strength of the relationship between the phone number and the consumer's first name and last name. For a detailed guide on interpreting this insight, please refer to our docs.`,
  },
  phone_risk_score: {
    ...getNamedOutputMapping("Phone risk"),
    hint: `A probabilistic value between 0.001 and 0.999 that represents the level of risk associated with a phone number. A higher score indicates a greater likelihood of fraud. For example, a score of 0.800 means that the consumer is riskier than 80 percent of all consumers or less risky than 20 percent of consumers. In general, consumers with scores ≥ 0.900 are considered high risk.`,
  },
  is_on_alert_list: {
    ...getNamedOutputMapping("Is on alert list"),
    hint: `A Boolean (True/False) that indicates if any of the consumer's information was found on Socure's Alert List. This is a list of known fraudulent users and specific identifying traits, as reported by Socure and its customers. Submitted data is checked against that list according to variable matching logic and processing. If True, you can find more granular information under the "alert_list" section of the report.`,
  },
});

export const getDefaultSocureComplianceBundle = (): IntegrationResourceT => ({
  providerResource: {
    provider: "socure",
    resource: "compliance_bundle",
  },
  connectionId: "",
  resourceConfigId: "",
  input: getBasicSocureInputMapping(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      ...getSocureAddressInsights(),
      ...getSocureKYCInsight(),
      ...getSocureGlobalWatchlistInsight(),
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSocureStandardBundle = (): IntegrationResourceT => {
  return {
    providerResource: {
      provider: "socure",
      resource: "standard_bundle",
    },
    connectionId: "",
    resourceConfigId: "",
    input: getExtendedSocureInputMapping(),
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        ...getSocureStandardBundleInsights(),
      },
    },
    config: defaultResourceConfig,
  };
};

export const getDefaultSocurePremiumBundle = (): IntegrationResourceT => ({
  providerResource: {
    provider: "socure",
    resource: "premium_bundle",
  },
  connectionId: "",
  resourceConfigId: "",
  input: getExtendedSocureInputMapping(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      ...getSocureStandardBundleInsights(),
      ...getSocureGlobalWatchlistInsight(),
    },
  },
  config: defaultResourceConfig,
});
