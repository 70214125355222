import { faTrashAlt, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { get } from "lodash";
import React from "react";
import { Controller, useFormContext, FieldError } from "react-hook-form";

import {
  ManifestIntegrationProvider,
  ManifestResourceDefinition,
} from "src/api/connectApi/manifestTypes";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Select } from "src/base-components/Select";
import { Switch } from "src/base-components/Switch";
import { Form } from "src/connections/config/manifest/common/Form";
import {
  ManifestFormType,
  ManifestResourceCreateT,
} from "src/connections/config/manifest/types";
import { Tooltip } from "src/design-system/Tooltip";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

type ResourceConfigFormProps = {
  resource: ManifestResourceCreateT;
  index: number;
  manifest: ManifestIntegrationProvider;
  onRemove: (resourceId: string) => void;
  onSelectResource: (
    currentResourceInstance: ManifestResourceCreateT,
    newResource: string,
  ) => void;
  removeDisabled: boolean;
};

export const isManifestResourceAvailableInAWSRegion = (
  resourceManifest: ManifestResourceDefinition,
  AWSRegion: string | undefined,
): boolean => {
  if (isFeatureFlagEnabled(FEATURE_FLAGS.powertools)) {
    return true;
  }
  if (resourceManifest.geographic_restrictions) {
    return resourceManifest.geographic_restrictions.some((regionPrefix) =>
      AWSRegion?.startsWith(regionPrefix),
    );
  }
  return true;
};

export const ResourceConfigForm: React.FC<ResourceConfigFormProps> = ({
  resource,
  index,
  manifest,
  onRemove,
  onSelectResource,
  removeDisabled,
}) => {
  const workspaceData = useWorkspaceAndOrg();
  const AWSRegion = workspaceData.workspace?.aws_region;
  const formMethods = useFormContext<ManifestFormType>();
  const resourceOptions = Object.entries(manifest.resources)
    .filter(([_, resource]) =>
      isManifestResourceAvailableInAWSRegion(resource, AWSRegion),
    )
    .map(([key, resource]) => ({
      key,
      value: resource.display_name,
      disabled: false,
    }));
  const resourceManifestDefinition = manifest.resources[resource.resource];
  const resourceInputs = resourceManifestDefinition.resource_inputs;

  const nameFieldError = get(
    formMethods.formState.errors,
    `resource_configs.${index}.name`,
  ) as FieldError | undefined;
  const nameFieldErrorMessage = nameFieldError?.message || "Invalid value";

  return (
    <div
      key={resource.id}
      className="mb-4 rounded-lg bg-gray-50 px-4 pb-9 pt-4"
    >
      <div className="flex items-center justify-between">
        <h3 className="text-xs uppercase text-gray-600">
          Resource {index + 1}
        </h3>
        <Tooltip
          disabled={!removeDisabled}
          placement="top"
          title="At least one resource is required"
          asChild
        >
          <div>
            <Button
              dataLoc="resource-delete-button"
              disabled={removeDisabled}
              iconLeft={faTrashAlt}
              size="sm"
              variant="secondary"
              onClick={() => onRemove(resource.id)}
            >
              Delete resource
            </Button>
          </div>
        </Tooltip>
      </div>
      <FormItem className="mt-4" label="Type">
        <Select
          dataLoc="manifest-resource-config-form-type"
          options={resourceOptions}
          placement="bottom"
          value={resource.resource}
          onChange={(newResource) => onSelectResource(resource, newResource)}
        />
      </FormItem>
      <FormItem
        description="A name that describes the resource"
        label="Resource name"
        isRequired
      >
        <Input
          data-loc="manifest-resource-config-form-name"
          errored={!!nameFieldError}
          placeholder={resourceManifestDefinition.display_name}
          suffixIcon={nameFieldError ? { icon: faWarning } : undefined}
          fullWidth
          {...formMethods.register(`resource_configs.${index}.name`, {
            required: "Resource name is required",
          })}
        />
        {nameFieldError && <ErrorHint>{nameFieldErrorMessage}</ErrorHint>}
      </FormItem>
      <Form
        formPath={`resource_configs.${index}.configuration`}
        prefix="manifest-resource-config-form"
        schema={resourceInputs}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h3 className="inline-block text-gray-800 font-inter-semibold-13px">
            Include raw provider responses
            <FormItem.Help body="With this enabled, the untouched API response from this resource will be returned alongside published Flows' output. The API response will only be included on Flow Runs that successfully fetch an API response, and not when using a cached response. Taktile never stores API responses." />
          </h3>
          <span className="mt-1 text-xs text-gray-500 font-inter-normal-12px">
            Include the raw API response for this resource in published Flows'
            API responses. Note that this may expose sensitive data in the API
            response.
          </span>
        </div>
        <div className="ml-1">
          <Controller
            control={formMethods.control}
            name={`resource_configs.${index}.has_raw_response_enabled`}
            render={({ field: { value, onChange } }) => (
              <Switch
                enabled={value as boolean}
                onChange={() => onChange(!value)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
