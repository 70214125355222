import {
  faCube,
  faCheck,
  faClock,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef } from "@tanstack/react-table";
import { isEmpty, reverse, times } from "lodash";
import { useMemo } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { GenericObjectT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { TableComp } from "src/base-components/Table";
import { INPUT_LIST, LOOP, OUTPUT_LIST } from "src/constants/InternalDataKeys";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { getColumnNames } from "src/dataTable/TableUtils";
import { CellContent } from "src/dataTable/types";
import { Cell } from "src/databaseDebugPopover/BaseDebugPopover";
import {
  HistoryDebugPopoverState,
  TestRunDebugPopoverState,
} from "src/databaseDebugPopover/types";
import { Callout } from "src/design-system/Callout";
import { EmptyState } from "src/design-system/EmptyState";
import { errorMessage } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";

export const LoopIterations: React.FC<
  Omit<TestResultPopoverPropsT, "open" | "onClose" | "nodeID"> & {
    isFetching: boolean;
  }
> = ({ data, rowData, selectedNodeId, error: historyError, isFetching }) => {
  const { availableData, status, error } = useIterationsData({
    error: historyError,
    rowData,
    data,
    selectedNodeId,
  });

  return (
    <InspectIterationsBody
      availableData={availableData}
      errorMessage={error}
      isFetching={isFetching}
      status={status}
    />
  );
};

const InspectIterationsBody: React.FC<{
  availableData: AvailableData;
  status: RowStatusType;
  errorMessage: string | false;
  isFetching?: boolean;
}> = ({ availableData, status, errorMessage, isFetching = false }) => {
  const rows = useMemo(
    () =>
      buildRows(
        availableData[INPUT_LIST],
        availableData[OUTPUT_LIST],
        availableData.item,
        status,
      ),
    [availableData, status],
  );

  const columns = useMemo(
    () =>
      !availableData && !isFetching
        ? []
        : isFetching
          ? times(4, (i) => ({
              id: `column${i}`,
              header: () => (
                <Header
                  header={<SkeletonPlaceholder height="h-4" width="w-4/5" />}
                />
              ),
              accessorFn: () => "",
              cell: () => <span />,
            }))
          : buildColumnsDef(
              availableData[INPUT_LIST],
              availableData[OUTPUT_LIST],
            ),
    [availableData, isFetching],
  );

  const isFailure = status === "failure";
  const hadEarlyExit =
    status === "success" && availableData[INPUT_LIST]?.length !== rows.length;
  const isEmpty = rows.length < 1 && !isFetching;
  return (
    <>
      {(isEmpty || hadEarlyExit || isFailure) && (
        <div
          className={twJoin(
            "flex flex-col gap-y-4 pt-2",
            "decideScrollbar min-h-0",
            isEmpty ? "pb-6" : "pb-4",
          )}
        >
          <Callout
            dataLoc={
              isFailure
                ? "iteration-pill-failure"
                : isEmpty
                  ? "iteration-pill-empty"
                  : "iteration-pill-break"
            }
            type={isFailure ? "error" : "neutral"}
          >
            {isFailure && errorMessage}
            {isEmpty &&
              "Loop iteration results cannot be viewed since the list being looped over is empty."}
            {hadEarlyExit &&
              `The loop terminated early after meeting the break condition during the iteration with index ${
                rows.length - 1
              }.`}
          </Callout>
          {isEmpty && (
            <div className="flex w-full items-center justify-center rounded-lg border border-gray-200">
              <EmptyState
                description="The field you intended to loop over is empty. Select a field with items to enable loop iterations."
                headline="No loop iterations"
                icon={faCube}
              />
            </div>
          )}
        </div>
      )}
      {!isEmpty && (
        <div
          className={twJoin(
            "w-full bg-white",
            !hadEarlyExit && !isFailure && "pt-4",
            "flex h-full min-h-0 flex-col pb-4",
          )}
        >
          <TableComp
            columns={columns}
            data={rows}
            dataLoc="iterations-table"
            frameClassName="h-full min-h-0"
            headerPropsGetter={(headerGroup) => ({
              classNameOverrides: twJoin(
                headerGroup.depth === 0 &&
                  "[&>th>div]:border-y [&>th>div]:border-gray-100",
                headerGroup.depth === 1 &&
                  "[&>th>div]:border-b [&>th>div]:border-gray-100",
                "static border-b-0 [&>th:first-child>div]:border-l [&>th:first-child>div]:border-gray-100 [&>th:first-child]:sticky [&>th:first-child]:left-0",
              ),
            })}
            isLoading={isFetching}
            loadingRowsCount={9}
            rowClassName={twMerge(
              "border-b border-gray-100 [&>td:first-child>div]:border-l [&>td:first-child>div]:border-gray-100 [&>td:first-child]:sticky [&>td:first-child]:left-0",
              isFetching &&
                "border-r border-gray-100 [&>td:not(:last-child)>div]:border-r [&>td:not(:last-child)>div]:border-gray-100",
            )}
          />
        </div>
      )}
    </>
  );
};

const buildRows = (
  inputList: DataShape[] | undefined,
  outputList: DataShape[] | undefined,
  pendingItem: DataShape | undefined,
  status: RowStatusType,
): RowShape[] => {
  if (!inputList || !outputList) {
    return [];
  }

  const isObjectList = inputList.every((item) => typeof item === "object");
  const rows = inputList
    .map((inputItem, index) => {
      const outputItem = outputList?.[index] as Record<string, string | number>;

      if (outputItem) {
        if (isObjectList) {
          return {
            index,
            input: inputItem,
            output: outputItem,
            type: "success",
          };
        } else {
          return {
            index,
            input: { item: inputItem },
            output: outputItem,
            type: "success",
          };
        }
      } else if (status === "ignored" && index < outputList.length + 1) {
        return {
          index,
          input: { item: inputItem },
          output: {},
          type: "ignored",
        };
      }

      return false;
    })
    .filter(Boolean) as RowShape[];

  // pending decision
  if (inputList.length > outputList.length && pendingItem !== undefined) {
    rows.push({
      index: outputList.length,
      input:
        typeof pendingItem === "object" ? pendingItem : { item: pendingItem },
      output: {},
      type: status === "failure" ? "failure" : "pending",
    });

    const listDiff = inputList.length - outputList.length;
    if (status === "pending" && listDiff > 1) {
      rows.push({
        index: "...",
        input: {},
        output: {},
        type: "skeleton",
      });

      rows.push({
        index: inputList.length - 1,
        input: {},
        output: {},
        type: "skeleton",
      });
    }
  }

  return reverse(rows);
};

const buildColumnsDef = (
  inputList?: DataShape[],
  outputList?: DataShape[],
): ColumnDef<RowShape, React.ReactNode>[] => {
  const inputColumns = (() => {
    if (!inputList) {
      return [];
    }

    const isObjectList = inputList.every((item) => typeof item === "object");
    if (isObjectList) {
      const inputColumnNames = getColumnNames(
        inputList as Record<string, unknown>[],
      );
      return inputColumnNames.map(
        (key) =>
          ({
            id: `item.${key}`,
            header: () => <Header header={key} transparent />,

            accessorFn: (row) =>
              row.type === "skeleton"
                ? pendingCell
                : (normalizeCellValue(row.input[key]) ?? " "),

            cell: ({ cell }) => <CustomResultCell cell={cell} />,
          }) as ColumnDef<RowShape, React.ReactNode>,
      );
    } else {
      return {
        id: "item",
        header: (ctx) => (
          <Header header={ctx.header.depth === 2 ? "item" : " "} transparent />
        ),
        accessorFn: (row) =>
          row.type === "skeleton"
            ? pendingCell
            : (normalizeCellValue(row.input.item) ?? " "),
        cell: ({ cell }) => <CustomResultCell cell={cell} />,
      } as ColumnDef<RowShape, React.ReactNode>;
    }
  })();

  const outputColumns = (() => {
    if (!outputList) {
      return [];
    }

    const outputColumnNames = getColumnNames(
      outputList as Record<string, unknown>[],
    );

    // Edge case when the output list is empty and column names cannot be inferred from
    // the sub-flow response items in the list. In this case, we add a single column with a blank header.
    if (outputColumnNames.length < 1) {
      outputColumnNames.push(" ");
    }

    return outputColumnNames.map(
      (key) =>
        ({
          id: `output.${key}`,
          header: () => <Header header={key} transparent />,

          accessorFn: (row) =>
            row.type === "failure"
              ? " "
              : row.type === "skeleton" || row.type === "pending"
                ? pendingCell
                : normalizeCellValue(row.output[key]),

          cell: ({ cell }) => (
            <CustomResultCell
              cell={cell}
              isFailure={cell.row.original.type === "failure"}
            />
          ),
        }) as ColumnDef<RowShape, React.ReactNode>,
    );
  })();

  return [
    {
      id: "index",
      header: (ctx) => (
        <Header
          header={ctx.header.depth === 2 ? "index" : " "}
          transparent={ctx.header.depth !== 2}
        />
      ),
      accessorFn: (row) => row?.index,
      cell: ({ cell }) => (
        <Cell
          cell={cell}
          classNameOverrides={twJoin(
            cell.row.original.type === "skeleton" ? "text-gray-500" : "",
            "sticky left-0 z-10 bg-white",
          )}
        />
      ),
      size: 50,
      maxSize: 50,
    },
    {
      id: "test-status",
      header: (ctx) => (
        <Header
          header={ctx.header.depth === 2 ? "Test Status" : " "}
          transparent
        />
      ),
      accessorFn: (row) => {
        if (row.type === "skeleton") {
          return pendingCell;
        } else {
          return <RowStatus type={row.type} />;
        }
      },
      size: 120,
      maxSize: 120,
      minSize: 120,
      cell: ({ cell }) => (
        <Cell cell={cell} classNameOverrides="justify-start" />
      ),
    },
    ...(Array.isArray(inputColumns)
      ? [
          {
            id: "item-parent",
            header: () =>
              Array.isArray(inputColumns) && <Header header="item" />,
            columns: inputColumns,
          } as ColumnDef<RowShape, React.ReactNode>,
        ]
      : [inputColumns]),
    {
      id: "output",
      header: () => <Header header="output" />,
      columns: outputColumns,
    },
  ];
};

type DataShape = Record<string, string | number> | string | number;
type RowStatusType = "success" | "pending" | "failure" | "ignored";
type RowType = RowStatusType | "skeleton";
type RowShape = {
  index: number | string;
  input: Record<string, string | number>;
  output: Record<string, string | number>;
  type: RowType;
};

const RowStatus: React.FC<{ type: RowType }> = ({ type }) => {
  switch (type) {
    case "failure":
      return (
        <>
          <Icon color="text-red-500" icon={faWarning} size="2xs" /> Failed
        </>
      );

    case "pending":
      return (
        <>
          <Icon color="text-indigo-400" icon={faClock} size="2xs" /> Pending
        </>
      );

    case "ignored":
      return (
        <>
          <Icon color="text-gray-400" icon={faWarning} size="2xs" /> Ignored
        </>
      );

    case "skeleton":
      return pendingCell;

    case "success":
      return (
        <>
          <Icon color="text-green-600" icon={faCheck} size="2xs" /> Successful
        </>
      );

    default:
      assertUnreachable(type);
      return null;
  }
};

const pendingCell = (
  <div className="flex h-full min-w-[104px] items-center gap-x-0.5">
    <SkeletonPlaceholder height="h-3" />
    <div children=" " className="basis-5" />
  </div>
);

const useIterationsData = ({
  error: historyError,
  rowData,
  data,
  selectedNodeId,
}: Omit<TestResultPopoverPropsT, "open" | "onClose" | "nodeID">) => {
  const availableData: AvailableData = useMemo(() => {
    // Historical data
    if (!isEmpty(data)) {
      if (Array.isArray(data[INPUT_LIST]) && Array.isArray(data[OUTPUT_LIST])) {
        const [inputList, outputList] = [data[INPUT_LIST], data[OUTPUT_LIST]];
        return {
          [INPUT_LIST]: inputList,
          [OUTPUT_LIST]: outputList,
          item: historyError ? inputList?.[inputList.length - 1] : undefined,
        };
      }

      const pendingLoopData = data[LOOP];

      if (!Array.isArray(pendingLoopData)) {
        return {
          [INPUT_LIST]: pendingLoopData?.target_list ?? [],
          [OUTPUT_LIST]: pendingLoopData?.output_list ?? [],
          item: pendingLoopData?.item ?? {},
        };
      }
    }

    // test run data here in any case
    if (rowData?.type === "failure" || rowData?.type === "ignored") {
      const provenanceData = rowData?.provenance?.[selectedNodeId] ?? {};
      const [inputList, outputList] = [
        provenanceData[INPUT_LIST],
        provenanceData[OUTPUT_LIST],
      ];
      return provenanceData
        ? {
            [INPUT_LIST]: inputList,
            [OUTPUT_LIST]: outputList,
            item:
              rowData.type === "failure"
                ? inputList?.[inputList?.length - 1]
                : undefined,
          }
        : {};
    } else {
      return { ...rowData?.data };
    }
  }, [rowData, data, selectedNodeId, historyError]);

  const status: RowStatusType = (() => {
    if (Boolean(historyError) || (rowData && rowData.type === "failure")) {
      return "failure";
    }

    if (rowData?.type === "ignored") {
      return "ignored";
    }

    if (availableData.item) {
      return "pending";
    }

    return "success";
  })();

  return {
    availableData,
    status,
    error: historyError
      ? errorMessage(historyError.msg)
      : rowData?.type === "failure" && rowData?.errorMessage,
  };
};

type AvailableData =
  | {
      [x: string]: DataShape[];
    }
  | {
      [INPUT_LIST]: any;
      [OUTPUT_LIST]: any;
      item: any;
    };

const normalizeCellValue = (value: unknown) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "object") {
    return JSON.stringify(value);
  } else if (value === undefined) {
    return undefined;
  } else {
    return String(value);
  }
};

type LoopType = {
  target_list: DataShape[];
  output_list: DataShape[];
  item: DataShape;
};

type TestResultPopoverPropsT =
  | (BasePopoverPropsT &
      TestRunDebugPopoverState<DataShape[]> & { data?: never; error?: never })
  | (BasePopoverPropsT &
      HistoryDebugPopoverState<DataShape[] | LoopType> & { rowData?: never });

type BasePopoverPropsT = {
  open: boolean;
  selectedNodeId: string;
  onClose: () => void;
};

const Header: React.FC<{
  header?: React.ReactNode;
  transparent?: boolean;
}> = ({ header, transparent = false }) => (
  <HeaderCell
    classNameOverrides={twJoin(
      "max-w-none border-r border-gray-100 bg-gray-50 py-1.5 pl-1.5 pr-1.5",
      transparent && "bg-white",
    )}
  >
    {header}
  </HeaderCell>
);

type CustomResultCellProps<T> = {
  cell: CellContent<T>;
  isFailure?: boolean;
};
const CustomResultCell = <T extends GenericObjectT>({
  cell,
  isFailure,
}: CustomResultCellProps<T>) => {
  return (
    <ResultCell
      cell={cell}
      className={twJoin(
        "max-w-none border-r border-gray-100 py-1.5 pl-1.5 pr-2",
        isFailure && "bg-red-50",
      )}
      objectDetailPosition="topLeft"
    />
  );
};
