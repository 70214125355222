import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { Effect } from "src/clients/flow-api";

export type AssignmentNodeFormT = {
  default_effects: Effect[];
};

type PropsT = {
  onRemove?: () => void;
  id: string;
  index: number;
  immutable: boolean;
  lazyInputs: boolean;
  register: UseFormRegister<AssignmentNodeFormT>;
  fieldErrorLeft: string | undefined;
  fieldErrorRight: string | undefined;
  control: Control<AssignmentNodeFormT, any>;
  hideActionButtons?: boolean;
};

export const AssignmentRow: React.FC<PropsT> = ({
  index,
  immutable,
  lazyInputs,
  onRemove,
  id,
  fieldErrorLeft,
  fieldErrorRight,
  control,
  hideActionButtons,
}) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex items-center gap-x-2 text-gray-700"
      style={style}
    >
      <ReorderHandle
        className="-mx-1" // We need to offset the padding of 1
        immutable={immutable}
        listeners={listeners}
      />
      <Controller
        control={control}
        name={`default_effects.${index}.target`}
        render={({ field }) => (
          <AutocompleteCodeInput
            dataLoc={`assignment-node-target-${index}`}
            disabled={immutable}
            error={fieldErrorLeft}
            lazy={lazyInputs}
            prefix="data."
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <span className="font-inter-medium-12px">to</span>
      <Controller
        control={control}
        name={`default_effects.${index}.value`}
        render={({ field }) => (
          <AutocompleteCodeInput
            dataLoc={`assignment-node-value-${index}`}
            disabled={immutable}
            error={fieldErrorRight}
            lazy={lazyInputs}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {onRemove && !hideActionButtons && (
        <Icon
          color={twJoin(
            immutable ? "text-gray-400" : "text-gray-500 hover:text-gray-700",
          )}
          dataLoc="delete-assignment-button"
          disabled={immutable}
          icon={faTrashAlt}
          size="xs"
          onClick={onRemove}
        />
      )}
    </div>
  );
};
