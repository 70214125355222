import { faCube, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Cell, TableComp } from "src/base-components/Table";
import {
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { parseJsonOrReturnAsIs } from "src/datasets/DatasetTable/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useSidepaneContext } from "src/entities/entityView/DecisionsSidePane/SidePaneContext";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { findSchema, getSchemaIcon } from "src/entities/entityView/utils";
import { useEntitySchemas } from "src/entities/queries";
import { useEvent, useEventSchema } from "src/eventsCatalogue/queries";
import { EnumPill, PropertyWithIcon } from "src/eventsCatalogue/tableConfig";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { getEventIcon } from "src/eventsCatalogue/utils";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EventsCatalogueParams, getUrlToEventsPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";
import { speakPythonPrimitive } from "src/utils/speakPython";
import { useParamsDecode } from "src/utils/useParamsDecode";

type EventViewPaneProps = {
  eventType: string;
  eventId: string;
  onClose: () => void;
};

export const EventViewPane: React.FC<EventViewPaneProps> = ({
  eventType,
  eventId,
  onClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workspace } = useWorkspaceContext();
  const [environment] = useEnvironment();
  const previousEnv = useRef(environment);
  const { orgId, wsId } = useParamsDecode<EventsCatalogueParams>();
  const { data: entitiesSchemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });
  const { data: event, isLoading: isEventLoading } = useEvent(
    {
      eventType,
      eventId,
      environment,
    },
    {
      // Disable the query when environment changes and we're on the event detail page
      enabled: !(
        previousEnv.current !== environment &&
        location.pathname.includes("/event/")
      ),
    },
  );
  const { data: schema, isLoading: isSchemaLoading } =
    useEventSchema(eventType);

  useEffect(() => {
    if (previousEnv.current !== environment) {
      if (location.pathname.includes("/event/")) {
        const timeoutId = setTimeout(() => {
          //make the transition feel less abrupt and flashy
          navigate(
            getUrlToEventsPage({
              orgId,
              wsId,
              eventType,
            }),
          );
        }, 200);

        return () => clearTimeout(timeoutId);
      }
      previousEnv.current = environment;
    }
  }, [environment, navigate, eventType, eventId, orgId, wsId, location]);

  const transformEventToRows = () => {
    if (!event?.payload || !schema) return [];

    const eventData = event.payload;
    const rows = [
      {
        label: "ID",
        value: eventData.id,
        type: PropertyDefinitionOutputTypeEnum.STRING,
        render: (value: any) => (
          <Pill dataLoc="event-id-pill-sidepane" size="sm" variant="gray">
            <Pill.Text fontType="code">{value}</Pill.Text>
            <CopyTextIcon
              dataLoc="copy-id-icon"
              feedback="inline"
              size="3xs"
              value={value}
            />
          </Pill>
        ),
      },
      {
        label: "Timestamp",
        value: eventData.timestamp,
        type: PropertyDefinitionOutputTypeEnum.DATETIME,
        render: (value: any) => formatDate(value, "MMM d, yyyy 'at' h:mmaaa"),
      },
    ];

    Object.entries(schema.properties)
      .filter(([key]) => !["id", "timestamp"].includes(key))
      .forEach(([key, value]: [string, PropertyDefinitionOutput]) => {
        const cellValue = eventData[key];
        let renderer;

        if (
          value.type === PropertyDefinitionOutputTypeEnum.DATETIME ||
          value.type === PropertyDefinitionOutputTypeEnum.DATE
        ) {
          renderer = (val: any) => {
            if (!val) return null;
            return formatDate(
              val,
              value.type === PropertyDefinitionOutputTypeEnum.DATETIME
                ? "MMM d, yyyy 'at' h:mmaaa"
                : "MMM d, yyyy",
            );
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.INTEGER) {
          renderer = (val: any) => {
            if (!val) return null;
            return <div className="font-code-13">{formatNumber(val)}</div>;
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.NUMBER) {
          renderer = (val: any) => {
            if (!val) return null;
            return <div className="font-code-13">{formatNumber(val)}</div>;
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.ENUM) {
          renderer = (val: any) => {
            const parsedCellValue = parseJsonOrReturnAsIs(val);
            const enumValue = value.enum_schema?.values.find(
              (v: any) => v.value === parsedCellValue,
            );
            if (!enumValue) return null;
            return <EnumPill enumValue={enumValue} />;
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.RELATION) {
          renderer = (val: any) => {
            if (!val) return null;
            const relationSchema = findSchema(val._type, entitiesSchemasData);
            return (
              <EntityDetailsWindowPill
                entityIcon={
                  relationSchema ? getSchemaIcon(relationSchema) : faCube
                }
                fullWidth={false}
                id={val._id}
                name={val._id}
                schemaId={val._type}
              />
            );
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.BOOLEAN) {
          renderer = (val: any) =>
            val === null || val === undefined
              ? null
              : speakPythonPrimitive(String(val));
        } else {
          renderer = (val: any) => val;
        }

        rows.push({
          label:
            value.display_name ?? value.relation_schema?.entity_type ?? key,
          value: cellValue,
          type: value.type,
          render: renderer,
        });
      });

    return rows;
  };

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor("label", {
      header: "Label",
      cell: (info) => (
        <PropertyWithIcon type={info.row.original.type}>
          {info.getValue()}
        </PropertyWithIcon>
      ),
      maxSize: 200,
    }),
    columnHelper.accessor("value", {
      header: "Value",
      cell: (info) => (
        <Cell info={info}>
          <Tooltip
            disabled={typeof info.getValue() !== "string"}
            placement="top"
            title={info.getValue()}
            asChild
          >
            <div className="truncate">
              {info.row.original.render(info.getValue())}
            </div>
          </Tooltip>
        </Cell>
      ),
      maxSize: 200,
    }),
  ];

  const { width, ref, ResizeHandler } = useSidepaneContext();

  return (
    <div
      ref={ref}
      className="relative flex shrink-0 flex-col gap-y-4 bg-white p-5"
      style={{
        width: `${width}px`,
      }}
    >
      <ResizeHandler />
      <div className="flex items-center justify-between">
        <div>
          {!isEventLoading && event && schema && !isSchemaLoading ? (
            <div className="flex gap-2 text-gray-800 font-inter-semibold-13px">
              <div className="h-5 w-5 rounded border border-gray-200">
                <Icon
                  color="text-gray-600"
                  icon={getEventIcon(schema)}
                  size="2xs"
                />
              </div>
              {formatDate(event.payload.timestamp, "MMMM d, yyyy, hh:mm aa")}
            </div>
          ) : (
            <SkeletonPlaceholder height="h-5" />
          )}
        </div>
        <Icon
          color="text-gray-500"
          icon={faTimes}
          size="xs"
          onClick={onClose}
        />
      </div>
      <Divider />
      {schema && (
        <TableComp
          columns={columns}
          data={transformEventToRows()}
          dataLoc="event-details-table"
          frameClassName="[&_thead_tr]:hidden [&_table]:border-b-0"
          isLoading={isEventLoading || isSchemaLoading}
          variant="compact"
          noScroll
        />
      )}
    </div>
  );
};
