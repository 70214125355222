import {
  PropertyDefinitionOutputTypeEnum,
  PropertyDefinitionOutput,
} from "src/clients/features-control";
import { Cardinality, EntitySchemaProperty } from "src/entities/queries";
import { isEntitySchemaProperty } from "src/schema/EntitySchemaHint";

/**
 * Checks whether a property is expandable. I.e. whether it is a to one relationship.
 */
export const propertyIsExpandable = (
  property: EntitySchemaProperty | PropertyDefinitionOutput,
) => {
  const type = isEntitySchemaProperty(property)
    ? property._type
    : property.type;
  const cardinality = isEntitySchemaProperty(property)
    ? property._cardinality
    : // Assume to one relationships for events
      Cardinality.ONE;
  return (
    type === PropertyDefinitionOutputTypeEnum.RELATION &&
    cardinality === Cardinality.ONE
  );
};
