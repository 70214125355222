import { isSafari, isFirefox } from "react-device-detect";

import { SubGraphClipboard } from "src/utils/GraphUtils";
import { logger } from "src/utils/logger";
import { pluralize } from "src/utils/stringUtils";

export const ClipboardMimeType = "web application/taktile" as const;
const FallbackClipboardMimeType = "text/plain" as const;

export const writeGraphToClipboard = async (
  newClipboard: SubGraphClipboard,
) => {
  try {
    if (isFirefox) {
      // Firefox does not support custom mime types so we need to write as plain text
      await navigator.clipboard.writeText(JSON.stringify(newClipboard));
    }
    // Safari does not support custom mime types so we need to write as plain text
    else if (isSafari) {
      await navigator.clipboard.write([
        new ClipboardItem({
          [FallbackClipboardMimeType]: new Blob(
            [JSON.stringify(newClipboard)],
            {
              type: FallbackClipboardMimeType,
            },
          ),
        }),
      ]);
    } else {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob(
            [pluralize(newClipboard.nodes.length, "node")],
            {
              type: "text/plain",
            },
          ),
          [ClipboardMimeType]: new Blob([JSON.stringify(newClipboard)], {
            type: ClipboardMimeType,
          }),
        }),
      ]);
    }
    return true;
  } catch (error) {
    logger.error(`Error writing the clipboard: ${error}`);
    return false;
  }
};

export const readGraphFromClipboard = async (options: {
  promptUser: boolean;
}) => {
  const permission = await getClipboardReadPermission();
  if (
    permission === undefined || // In safari and firefox the permission is undefined here so we need to pass through
    permission.state === "granted" ||
    (permission.state === "prompt" && options.promptUser)
  ) {
    try {
      const currentClipboardContents = await navigator.clipboard.read();
      for (const item of currentClipboardContents) {
        // Safari and Firefox do not support custom mime types so we need to read as plain text
        if (
          (isSafari || isFirefox) &&
          item.types.includes(FallbackClipboardMimeType)
        ) {
          try {
            const parsedClipboard = JSON.parse(
              await (await item.getType(FallbackClipboardMimeType)).text(),
            );
            if (
              "workspaceId" in parsedClipboard &&
              "nodes" in parsedClipboard &&
              "edges" in parsedClipboard &&
              "groups" in parsedClipboard
            ) {
              return parsedClipboard as SubGraphClipboard;
            }
          } catch {
            // Silently fail
          }
        }
        if (item.types.includes(ClipboardMimeType)) {
          let text: string = "";
          try {
            const blob = await item.getType(ClipboardMimeType);
            text = await blob.text();
            return JSON.parse(text) as SubGraphClipboard;
          } catch (error) {
            logger.error(
              `Error processing the data from the clipboard: ${error}`,
              text,
            );
          }
        }
      }
    } catch (error) {
      logger.error(`Error reading the clipboard: ${error}`);
    }
  }
};

export const getClipboardReadPermission = async () => {
  // This can be undefined in some browsers/unsecure contexts
  if (navigator.permissions) {
    try {
      return await navigator.permissions.query({
        name: "clipboard-read" as PermissionName,
      });
    } catch (error) {
      logger.warn(`Error getting the clipboard read permission: ${error}`);
      return undefined;
    }
  }
};
