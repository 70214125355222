import { range } from "lodash";
import React from "react";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { FeaturePill } from "src/entities/PropertyPill";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { useEntityFeatures } from "src/featureCatalogue/queries";

export const GLOBAL_ENTITY_TYPE = "__global__";

const Header = () => {
  return (
    <p className="mb-1 ml-3.5 mt-3 text-gray-800 font-inter-medium-13px">
      Global Features
    </p>
  );
};

export const SchemaGlobalFeatures = () => {
  const [env] = useEnvironment();
  const { data: globalFeatures, isLoading: isLoadingGlobalFeatures } =
    useEntityFeatures(env, GLOBAL_ENTITY_TYPE, GLOBAL_ENTITY_TYPE);

  if (isLoadingGlobalFeatures) {
    return (
      <>
        <Header />
        <div className="flex flex-col gap-y-1">
          {range(4).map((index) => (
            <SkeletonPlaceholder key={index} height="h-4" width="w-60" />
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      {globalFeatures?.data?.length === 0 ? (
        <p className="ml-3.5 text-gray-800 font-inter-medium-12px">
          No Global Features found
        </p>
      ) : (
        <div className="mx-5 ml-3.5 flex max-w-full flex-row flex-wrap gap-x-1 gap-y-1">
          {globalFeatures?.data?.map((feature) => (
            <FeaturePill key={feature.key} feature={feature} />
          ))}
        </div>
      )}
    </>
  );
};
