import { HeightSize, WidthSize } from "src/design-system/types";

/**
 * Parses a tailwind size to a pixel value.
 * @param size - The tailwind size to parse.
 * @returns The pixel value.
 */
export const tailwindSizeToPixels = (size: WidthSize | HeightSize) => {
  return parseFloat(size.split("-")[1]) * 4;
};
