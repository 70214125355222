import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useWorkspaces } from "src/api/queries";
import {
  useCreateOrganizationInvite,
  useUpdateOrganizationUser,
  useUpdateOrganizationUserWorkspacesRoles,
} from "src/api/taktile/mutations";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { WorkspaceDataplane } from "src/clients/flow-api";
import {
  OrganizationUser,
  SimpleRoleType,
  WorkspaceSimpleRoleType,
} from "src/clients/taktile-api";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { OrganizationInviteForm } from "src/store/api/types";
import { OrgRoleSelector } from "src/userManagement/CreateInviteModal/OrgRoleSelector";
import { WorkspaceRoleSelect } from "src/userManagement/CreateInviteModal/WorkspaceRoleSelect";
import { emailUsernameValidations } from "src/userManagement/CreateInviteModal/utils";
import { wrapWithErrorHandler } from "src/utils/toastError";

type PropsT = {
  open: boolean;
  organizationId: string;
  editableOrgRole: boolean;
  user?: Nullable<OrganizationUser>;
  onClose: () => void;
};

const getInitialValues = (
  workspaces: WorkspaceDataplane[],
  user?: Nullable<OrganizationUser>,
): OrganizationInviteForm => {
  const userWsRoleMap =
    user?.workspaces_roles?.reduce(
      (acc, item) => ({ ...acc, [item.workspace_id]: item.role }),
      {} as Record<string, WorkspaceSimpleRoleType>,
    ) ?? {};

  return {
    username: user?.username ?? "",
    role: user?.role ?? SimpleRoleType.MEMBER,
    workspaces_roles: workspaces.map((ws) => ({
      role: userWsRoleMap[ws.id] ?? null,
      workspace_id: ws.id,
    })),
  };
};

export const CreateInviteModal: React.FC<PropsT> = ({
  open,
  editableOrgRole,
  organizationId,
  user,
  onClose,
}) => {
  const { data: workspaces } = useWorkspaces();
  const filteredWorkspaces = useMemo(
    () =>
      (workspaces || []).filter((ws) => ws.organization_id === organizationId),
    [workspaces, organizationId],
  );
  const wsNameMap = useMemo(() => {
    return filteredWorkspaces.reduce(
      (acc, ws) => ({ ...acc, [ws.id]: ws.name }),
      {} as Record<string, string>,
    );
  }, [filteredWorkspaces]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<OrganizationInviteForm>({
    mode: "onChange",
    defaultValues: getInitialValues(filteredWorkspaces, user),
  });
  const { fields: workspacesRoles } = useFieldArray({
    control,
    name: "workspaces_roles",
  });
  const selectedRole = watch("role");

  const createInvite = useCreateOrganizationInvite(organizationId);
  const updateUser = useUpdateOrganizationUser(organizationId, user?.id ?? "");
  const updateWorkspacesRoles = useUpdateOrganizationUserWorkspacesRoles(
    organizationId,
    user?.id ?? "",
  );

  // When the modal is opened, reset it and disable the submit button
  useEffect(() => {
    if (open) {
      reset(getInitialValues(filteredWorkspaces, user));
    }
  }, [open, reset, user, filteredWorkspaces]);

  const onSubmit = wrapWithErrorHandler(
    async (data: OrganizationInviteForm) => {
      const filteredData = {
        ...data,
        workspaces_roles: data.workspaces_roles.filter(({ role }) =>
          Boolean(role),
        ) as {
          workspace_id: string;
          role: WorkspaceSimpleRoleType;
        }[],
      };

      if (user) {
        const orgRolePromise = editableOrgRole
          ? updateUser.mutateAsync(filteredData)
          : Promise.resolve();

        await orgRolePromise;
        await updateWorkspacesRoles.mutateAsync(filteredData);
        toastActions.success({ title: "Permissions updated successfully." });
      } else {
        await createInvite.mutateAsync(filteredData);
        toastActions.success({ title: "Invite created successfully." });
      }

      onClose();
    },
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        {user ? "Edit permissions" : "Assign permissions"}
      </Modal.Header>
      <form
        /*className="flex flex-col gap-y-5"*/ onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Content>
          <div className="mb-5">
            <div className="mb-1 text-sm text-gray-600">Email</div>
            <Input
              disabled={Boolean(user)}
              placeholder="Email Address"
              fullWidth
              {...register("username", {
                required: true,
                validate: emailUsernameValidations,
              })}
            />
          </div>
          <div className="mb-5">
            <div className="mb-3.5 text-sm text-gray-600">User Role</div>
            <Controller
              control={control}
              name="role"
              render={(props) => (
                <OrgRoleSelector
                  disabled={!editableOrgRole}
                  value={props.field.value}
                  onChange={props.field.onChange}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <div className="mb-3.5 text-sm text-gray-600">
              Workspace permissions
            </div>
            <ul>
              {workspacesRoles.map((field, index) => (
                <Controller
                  key={index}
                  control={control}
                  name={`workspaces_roles.${index}`}
                  render={({ field: { value, onChange } }) => (
                    <WorkspaceRoleSelect
                      dataLoc={`workspace-role-${field.workspace_id}`}
                      nullable={selectedRole === SimpleRoleType.MEMBER}
                      value={value.role}
                      workspaceName={wsNameMap[field.workspace_id] || ""}
                      onChange={(role) => {
                        onChange({
                          workspace_id: field.workspace_id,
                          role,
                        });
                      }}
                    />
                  )}
                />
              ))}
            </ul>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="save-user"
              disabled={!isEmpty(errors) || !isValid}
              htmlType="submit"
              loading={updateUser.isPending || createInvite.isPending}
              variant="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {user ? "Edit permissions" : "Assign permissions"}
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
