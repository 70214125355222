/**
 * A JSON editor that is used to edit a relation.
 * This is temporary solution, we're going to implement a proper entities editor
 */
import { Column } from "@tanstack/react-table";
import { get, isPlainObject } from "lodash-es";

import { DatasetRow } from "src/api/types";
import { PonEditor } from "src/base-components/PONEditor";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import { getIcon } from "src/datasets/DatasetTable/cells";
import {
  useDatasetEditTableActions,
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { JSONValue } from "src/datasets/DatasetTable/types";
import {
  CellId,
  getNonProviderIntegrationNodeIcon,
  ponToJson,
  SUB_COLUMN_SEPARATOR,
} from "src/datasets/DatasetTable/utils";
import {
  validateAny,
  ValidationResult,
} from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import { JSONPill } from "src/datasets/components/JSONPill";
import { RelationCellDropdown } from "src/datasets/components/RelationCellDropdown";
import { getErrorDetails } from "src/datasets/components/errorUtils";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { findSchema, getSchemaIcon } from "src/entities/entityView/utils";
import { useEntitySchemas } from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { copyTextToClipboard } from "src/utils/clipboard";
import { logger } from "src/utils/logger";

export const RelationJSONEditorCell: React.FC<{
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  integrationNode?: DatasetIntegrationNode;
  type: string;
  cellId: CellId;
  warning: boolean;
  invalid: boolean;
  column: Column<DatasetRow>;
  rowIndex: number;
  error?: ValidationResult | undefined;
  relationSchema: string;
  propValue: JSONValue | undefined;
}> = ({
  value,
  onChange,
  disabled,
  integrationNode,
  type,
  cellId,
  warning,
  invalid,
  column,
  rowIndex,
  error,
  relationSchema: relation,
  propValue,
}) => {
  const { reset } = useDatasetEditTableActions();
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const children = (
    <CellWrapper
      cellId={cellId}
      disabled={disabled}
      error={getErrorDetails(error, value, onChange)}
      invalid={invalid}
      warning={warning}
    >
      {!propValue ? (
        <RelationCellDropdown
          isOpen={selected && !editing}
          relation={relation}
          onFill={onChange}
        />
      ) : (
        <RelationPill
          fallback={<JSONPill type={type} value={value} />}
          schema={relation}
          value={propValue}
        />
      )}
    </CellWrapper>
  );

  const onCopy = async () => {
    try {
      const json = ponToJson(value);
      const isInvalid = validateAny(json);

      if (!isInvalid) {
        await copyTextToClipboard(json);
        toastActions.success({
          title: "Copied JSON to clipboard",
        });
      } else {
        toastActions.failure({
          title: "Failed to copy JSON to clipboard",
          description: "Value is not valid JSON",
        });
      }
    } catch (e) {
      logger.error(e);
      toastActions.failure({
        title: "Failed to copy JSON to clipboard",
      });
    }
  };

  if (disabled) {
    return children;
  }

  return (
    <FloatingWindow
      button={children}
      isOpen={editing}
      title={
        <>
          <div className="flex items-start gap-x-1.5">
            <Pill size="sm" variant="gray">
              <Pill.Text>#{rowIndex + 1}</Pill.Text>
            </Pill>
            <Pill size="sm" variant="gray">
              {integrationNode ? (
                <Pill.Icon
                  icon={getNonProviderIntegrationNodeIcon(
                    integrationNode.provider,
                  )}
                />
              ) : (
                <Pill.Icon
                  icon={getIcon(
                    column.columnDef.meta?.archetype?.desiredType ?? "any",
                  )}
                />
              )}
              <Pill.Text>
                {renderColumnName(column.columnDef.meta?.archetype?.name ?? "")}
              </Pill.Text>
            </Pill>
            {relation && (
              <RelationPill schema={relation} value={propValue ?? {}} />
            )}
          </div>
        </>
      }
      onClose={() => reset("editingCellId")}
      onCopy={onCopy}
    >
      <div className="flex h-full flex-1 flex-col gap-y-1.5">
        {integrationNode?.provider === NODE_TYPE.LOOP_NODE && (
          <p className="text-gray-500 font-inter-normal-12px">
            For each iteration in the loop, mock response below will be used.
          </p>
        )}
        <PonEditor value={value} onChange={onChange} />
      </div>
    </FloatingWindow>
  );
};

const renderColumnName = (name: string) => {
  if (name.includes(SUB_COLUMN_SEPARATOR)) {
    return name.split(SUB_COLUMN_SEPARATOR)[1];
  }

  return name;
};

export const RelationPill: React.FC<{
  schema: string;
  value: unknown;
  fallback?: React.ReactNode;
}> = ({ schema, value, fallback = null }) => {
  const { workspace } = useWorkspaceContext();
  const { data: schemasData, isLoading } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });

  if (!value) return <div />;
  if (isLoading) return <SkeletonPlaceholder height="h-5.5" width="w-20" />;

  const relationSchema = findSchema(schema, schemasData);

  if (relationSchema) {
    const icon = getSchemaIcon(relationSchema);

    const primaryPropValue = isPlainObject(value)
      ? get(value, relationSchema._primary_property)
      : undefined;

    return (
      <div className="min-w-0 flex-auto">
        <Pill size="sm" variant="gray" maxWidth>
          <Tooltip
            delay={250}
            disabled={!!primaryPropValue}
            placement="top"
            title={relationSchema._id}
          >
            <Pill.Icon icon={icon} />
          </Tooltip>
          <Pill.Text>
            {primaryPropValue ? (
              primaryPropValue
            ) : (
              <span className="text-gray-400">{relationSchema._id}</span>
            )}
          </Pill.Text>
        </Pill>
      </div>
    );
  }

  return fallback;
};
