import React from "react";

import { Button } from "src/base-components/Button";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { DebugTools } from "src/powertools/DebugPane";

interface WorkspaceDebugModalProps {
  isOpen: boolean;
  selectedWs: WorkspaceDataplane;
  onClose: () => void;
}

export const WorkspaceDebugModal: React.FC<WorkspaceDebugModalProps> = ({
  isOpen,
  selectedWs,
  onClose,
}) => {
  return (
    <Modal open={isOpen} size="lg" onClose={onClose}>
      <div className="p-4">
        <DebugTools workspace={selectedWs} />

        <div className="mt-6 flex justify-end">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
