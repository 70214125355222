import { faMinus, faQuestion } from "@fortawesome/pro-regular-svg-icons";

import ManyToOneIcon from "src/assets/icons/ManyToOne.svg?react";
import OneToManyIcon from "src/assets/icons/OneToMany.svg?react";
import { Pill } from "src/base-components/Pill";
import {
  FeatureResponse,
  PropertyDefinitionOutput,
} from "src/clients/features-control";
import { EntityFeatureValue } from "src/clients/features-retrieve";
import { Cardinality, EntitySchemaProperty } from "src/entities/queries";
import { PROPERTY_TYPES_TO_ICONS } from "src/eventsCatalogue/SchemaEditor/utils";
import { isEntitySchemaProperty } from "src/schema/EntitySchemaHint";
import { getTypeIcon } from "src/utils/icons";

type PropertyPillProps = {
  property: EntitySchemaProperty | PropertyDefinitionOutput;
};

type FeaturePillProps = {
  feature: FeatureResponse | EntityFeatureValue;
};

const Icon: React.FC<PropertyPillProps> = ({ property }) => {
  const type = isEntitySchemaProperty(property)
    ? property._type
    : property.type;

  if (type === "relation") {
    const cardinality: Cardinality | undefined = isEntitySchemaProperty(
      property,
    )
      ? property._cardinality
      : // Assume to one relationships for events
        Cardinality.ONE;
    return cardinality === Cardinality.ONE ? (
      <ManyToOneIcon />
    ) : (
      <OneToManyIcon />
    );
  }

  return <Pill.Icon icon={PROPERTY_TYPES_TO_ICONS[type] ?? faQuestion} />;
};

export const PropertyPill: React.FC<PropertyPillProps> = ({ property }) => {
  const displayName = isEntitySchemaProperty(property)
    ? property._display_name
    : property.display_name;
  return (
    <Pill size="sm" variant="gray">
      <Icon property={property} />
      <Pill.Text>{displayName}</Pill.Text>
    </Pill>
  );
};

export const FeaturePill: React.FC<FeaturePillProps> = ({ feature }) => {
  const icon = getTypeIcon(
    "feature_type" in feature && feature.feature_type
      ? feature.feature_type
      : "",
    faMinus,
  );

  return (
    <Pill size="sm" variant="gray">
      <Pill.Icon icon={icon} />
      <Pill.Text>{feature.key}</Pill.Text>
    </Pill>
  );
};
