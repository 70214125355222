import { faSearch, faSliders } from "@fortawesome/pro-regular-svg-icons";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui2/react";
import { noop } from "lodash";
import React, { useState } from "react";

import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { SearchBox } from "src/base-components/SearchBox";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { useFuseSearch } from "src/utils/useFuseSearch";

type ColumnItem = {
  label: string;
  value: string;
  disabled?: boolean | string;
};

type ColumnVisibilityDropdownProps = {
  dataLoc?: string;
  items: ColumnItem[];
  value: string[];
  min?: number;
  max?: number;
  onChange: (value: string[]) => void;
  onReset?: () => void;
};

export const ColumnVisibilityDropdown: React.FC<
  ColumnVisibilityDropdownProps
> = ({ dataLoc, items, value, onChange, onReset, min = 0, max = Infinity }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const search = useFuseSearch(items, {
    threshold: 0.5,
    keys: ["label"],
  });

  const filteredItems = search(searchQuery);

  // Make sure that value and items have intersection
  const selectedItems = items.filter((item) => value.includes(item.value));

  const hasReachedMinSelectedItems = selectedItems.length <= min;
  const hasReachedMaxSelectedItems = selectedItems.length >= max;

  const isSelected = (itemValue: string) => value.includes(itemValue);
  const isDisabled = (item: ColumnItem) =>
    !!item.disabled ||
    (!isSelected(item.value) && hasReachedMaxSelectedItems) ||
    (isSelected(item.value) && hasReachedMinSelectedItems);

  return (
    <Listbox value={value} multiple onChange={onChange}>
      <Tooltip placement="top" title="View options" asChild>
        <ListboxButton className="group">
          <Icon
            color="text-gray-500 group-hover:text-gray-800 group-data-[open]:text-gray-800"
            dataLoc="column-selector-button"
            icon={faSliders}
            size="xs"
          />
        </ListboxButton>
      </Tooltip>
      <ListboxOptions
        anchor={{
          to: "bottom end",
          padding: 24,
        }}
        className="relative z-10 min-h-[156px] w-70 origin-top overflow-hidden rounded-lg bg-white shadow-lg transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
        data-loc={`${dataLoc ?? "table"}-column-visibility-dropdown`}
        transition
        unmount
      >
        <div className="sticky top-0 z-10 bg-white p-4 pb-2.5">
          <SearchBox
            placeholder="Search fields"
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </div>
        {filteredItems.length === 0 && (
          <EmptyState
            description="Try searching for a different field"
            headline="No fields found"
            icon={faSearch}
          />
        )}
        {filteredItems.map((item) => (
          <ListboxOption
            key={item.value}
            as="li"
            className="cursor-pointer list-none text-gray-800 data-[disabled]:cursor-default data-[focus]:bg-gray-50 data-[disabled]:text-gray-500"
            disabled={isDisabled(item)}
            value={item.value}
          >
            {({ selected }) => (
              <Tooltip
                disabled={
                  (item.disabled && typeof item.disabled !== "string") ||
                  !isDisabled(item)
                }
                placement="left"
                title={
                  item.disabled && typeof item.disabled === "string"
                    ? item.disabled
                    : hasReachedMaxSelectedItems
                      ? `Maximum ${max} fields can be selected.`
                      : `At least ${min} fields should be selected.`
                }
                asChild
              >
                <div className="flex h-12 items-center gap-x-2 overflow-hidden px-4 py-2.5">
                  <Checkbox
                    checked={selected}
                    disabled={isDisabled(item)}
                    readOnly
                  />
                  <span className="overflow-hidden text-ellipsis font-inter-normal-13px">
                    {item.label}
                  </span>
                </div>
              </Tooltip>
            )}
          </ListboxOption>
        ))}
        <Footer
          disabled={searchQuery.length > 0}
          isSaving={false}
          onReset={onReset}
          onSave={noop}
        />
      </ListboxOptions>
    </Listbox>
  );
};

const Footer: React.FC<{
  onReset?: () => void;
  onSave: () => void;
  isSaving: boolean;
  disabled: boolean;
}> = ({ isSaving: _, onSave: __, onReset, disabled }) =>
  onReset && (
    <li className="sticky bottom-0 mt-2.5 flex w-full list-none items-center justify-between border-t border-t-gray-200 bg-white px-4 pb-4 pt-4.5">
      {onReset && (
        <Button
          dataLoc="column-selector-reset"
          disabled={disabled}
          size="sm"
          variant="secondary"
          onClick={onReset}
        >
          Reset
        </Button>
      )}
      {/* TODO: Implement BE for saving view options */}
      {/* <Button
      dataLoc="column-selector-save"
      disabled={disabled || isSaving}
      loading={isSaving}
      size="sm"
      variant="secondary"
      onClick={onSave}
    >
      Save for everyone
    </Button> */}
    </li>
  );
