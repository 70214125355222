import React from "react";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useTestDatasets } from "src/datasets/api/queries";
import { RunFlowButton } from "src/flowContainer/RunFlowButton/RunFlowButton";
import { useAuthoringContext } from "src/router/routerContextHooks";

type WrapperPropsT = {
  flow: FlowT;
  version: FlowVersionT;
};

export const RunFlowButtonWrapper: React.FC<WrapperPropsT> = ({
  flow,
  version,
}) => {
  const { workspace } = useAuthoringContext();
  const { testRunDatasets, isLoading } = useTestDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  return (
    <RunFlowButton
      key={version.id}
      datasets={testRunDatasets}
      flow={flow}
      isLoading={isLoading}
      version={version}
    />
  );
};
