import { JSONValue } from "@segment/analytics-next";
import { groupBy, isNull, isString, last, omitBy } from "lodash-es";
import { useEffect, useState } from "react";

import { DecisionEnvironment } from "src/api/types";
import { EntityMap } from "src/clients/entities";
import { PropertyDefinitionOutputTypeEnum } from "src/clients/features-control";
import { toastActions } from "src/design-system/Toast/utils";
import { getEntityKeys } from "src/entities/entityView/utils";
import {
  Cardinality,
  EntityResource,
  EntityResourceDataplane,
  useEnrichedEntityData,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type UseEnrichedEntityOnSelectProps = {
  entitySchema: string;
  env: DecisionEnvironment;
  onDataReady: (data: JSONValue) => void;
};

export const useEnrichedEntityOnSelect = ({
  entitySchema,
  env,
  onDataReady,
}: UseEnrichedEntityOnSelectProps) => {
  const { workspace } = useWorkspaceContext();

  const [entityId, setEntityId] = useState<string>("");
  const { isLoading, promise, isError } = useEnrichedEntityData({
    entitySchema,
    entityId,
    env,
    baseUrl: workspace.base_url!,
  });

  useEffect(() => {
    if (isError) {
      toastActions.failure({
        id: "enriched-entity-on-select",
        title: "Failed to prepare entity for insertion",
        description: "Please try again later",
      });
    }
  }, [isError]);

  return {
    onSelect: (entityId: string) => {
      setEntityId(entityId);

      promise.then((data) => {
        const enrichedEntity = getEnrichedEntityData(entityId, data.entities);

        if (enrichedEntity) {
          onDataReady(enrichedEntity);
        }
      });
    },
    isLoading: (entityIdToCheck: string) => {
      return isLoading && entityId === entityIdToCheck;
    },
  } as const;
};

const getEnrichedEntityData = (
  entityId: string | undefined,
  dataForEnrichment: EntityMap["entities"],
) => {
  const { schemas, entities } = groupBy(
    Object.entries(dataForEnrichment),
    ([key, _schemaOrEntity]) =>
      key.startsWith("schemas") ? "schemas" : "entities",
  );

  // Map schemaId => array of relation property names
  // example: user: ["company", "card"]
  const schemaRelationsMap = Object.fromEntries(
    schemas.map(([entityKey, schemaData]) => {
      const schemaId = last(entityKey.split("."));
      const relationPropertyNames = Object.entries(schemaData)
        .filter(([key, value]) => {
          return (
            !key.startsWith("_") &&
            value._type === PropertyDefinitionOutputTypeEnum.RELATION &&
            value._cardinality === Cardinality.ONE
          );
        })
        .map(([key]) => key);

      return [schemaId, relationPropertyNames];
    }),
  ) as Record<string, string[]>;

  // Map entityId => entity
  // example: user: { id: "1", name: "John Doe", _schema: "user" }
  const entitiesMap = Object.fromEntries(
    entities.map(([entityKey, entityData]) => {
      const entityId = last(entityKey.split("."));
      const entity = getEntityKeys(entityData as EntityResourceDataplane);

      return [entityId, entity];
    }),
  ) as Record<string, EntityResource>;

  if (entityId) {
    return recursivelyEnrichEntity({
      entityId,
      entitiesMap,
      schemaRelationsMap,
    });
  }
};

const recursivelyEnrichEntity = ({
  entityId,
  entitiesMap,
  schemaRelationsMap,
  maxDepth = 5,
}: {
  entityId: string;
  entitiesMap: Record<string, EntityResource>;
  schemaRelationsMap: Record<string, string[]>;
  maxDepth?: number;
}): JSONValue => {
  const entity = entitiesMap[entityId];
  const relationPropertiesToEnrich = schemaRelationsMap[entity._schema];

  if (maxDepth === 0) {
    return entityId;
  }

  return {
    // Remove null values
    ...omitBy(entity.properties, isNull),
    ...Object.fromEntries(
      relationPropertiesToEnrich.map((relationProperty) => {
        const relationId = entity.properties[relationProperty];

        if (isString(relationId)) {
          return [
            relationProperty,
            recursivelyEnrichEntity({
              entityId: relationId,
              entitiesMap,
              schemaRelationsMap,
              maxDepth: maxDepth - 1,
            }),
          ];
        }

        return [relationProperty, isNull(relationId) ? undefined : relationId];
      }),
    ),
  };
};
