import { useMemo } from "react";

import { useWorkspace } from "../api/queries";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type WorkspaceFeatureGates = {
  entitiesEnabled: boolean;
  featuresEventsEnabled: boolean;
};

const DefaultFeatureGates: WorkspaceFeatureGates = {
  entitiesEnabled: false,
  featuresEventsEnabled: false,
};

export const useWorkspaceFeatureGates = (): WorkspaceFeatureGates => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const { data: workspace } = useWorkspace(wsId);

  return useMemo(() => {
    if (!workspace || !workspace.feature_gates) {
      return DefaultFeatureGates;
    }

    const { feature_gates } = workspace;

    return {
      entitiesEnabled: feature_gates.entity,
      featuresEventsEnabled: feature_gates.feature,
    };
  }, [workspace]);
};
