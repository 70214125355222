import { useCallback } from "react";
import { v4 } from "uuid";

import { FlowVersionT } from "src/api/flowTypes";
import {
  CommentResourceType,
  ReviewCommentStatus,
  ReviewDb,
} from "src/clients/flow-api";
import { useCreateComment } from "src/comments/queries";
import { useAuthStore } from "src/store/AuthStore";
import { wrapWithErrorHandler } from "src/utils/toastError";

type RerequestReviewArgs = {
  review: ReviewDb;
  version: FlowVersionT;
  requestedFrom: string[];
};

export const useRerequestReview = () => {
  const { mutateAsync: createComment, isPending: isLoading } =
    useCreateComment();
  const { signed_in_user_id } = useAuthStore();

  const rerequestReview = useCallback(
    async ({ review, version, requestedFrom }: RerequestReviewArgs) => {
      const content = requestedFrom.map((id) => `@user:${id}`).join(",");
      const createCommentWrapped = wrapWithErrorHandler(createComment);
      createCommentWrapped({
        id: v4(),
        userId: signed_in_user_id!,
        commentCreate: {
          content,
          resource_type: CommentResourceType.REVIEW,
          resource_id: review.id,
          flow_version: version.id,
          flow_version_etag: version.etag,
          review_id: review.id,
          reviewer_status: ReviewCommentStatus.REVIEW_REREQUESTED,
        },
      });
    },
    [signed_in_user_id, createComment],
  );

  return {
    rerequestReview,
    isLoading,
  };
};
