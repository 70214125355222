import { limitShift, offset, shift } from "@floating-ui/dom";
import {
  faCheck,
  faCrosshairs,
  faCube,
  faEye,
  faTimes,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Cell,
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { difference, get } from "lodash";
import { RefObject, useCallback, useRef } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { GenericObjectT } from "src/api/flowTypes";
import { FixedPositionedDropdownElementT } from "src/base-components/FixedPositionedDropDown";
import { useFloatingWindowsActions } from "src/base-components/FloatingWindow/hooks";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import {
  EventConfigEnriched,
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { TypingInfo } from "src/clients/flow-api";
import { INPUT_LIST, LOOP, OUTPUT_LIST } from "src/constants/InternalDataKeys";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { IntermediateResultsHeaderCell } from "src/dataTable/IntermediateResultsHeaderCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { IntermediateResultsTableFilter } from "src/dataTable/TableFilter";
import {
  AUX_DATA_COLUMN_PREFIX,
  EXPECTED_DATA_COLUMN_PREFIX,
  INDEX_COLUMN_ID,
  RESULT_COLUMN_PREFIX,
  STATUS_COLUMN_ID,
} from "src/dataTable/TablePrefixes";
import {
  ResultDataAndAuxRowV2,
  SuccessResultDataAndAuxRow,
} from "src/dataTable/types";
import {
  decodeTypeToEntityEventId,
  getTypeHintForDataProperty,
} from "src/dataTable/typingDecoding";
import {
  renderValue,
  TypeIcon,
} from "src/entities/entityView/EntityDetailsSidePane";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { getSchemaIcon } from "src/entities/entityView/utils";
import {
  Cardinality,
  EntityPropertyValue,
  EntitySchemaProperty,
  EntitySchemaResource,
} from "src/entities/queries";
import { getEventIcon } from "src/eventsCatalogue/utils";
import {
  useAuthoringUIActions,
  useIsSelectedResultsRow,
} from "src/flowContainer/AuthoringUIContext";
import { WorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { RESULT_SIZE_OVERWRITE } from "src/nodeEditor/IntermediateResultsTableV2";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { assertUnreachable } from "src/utils/typeUtils";

export const DETAILED_VIEW_ID = "detailed-view";

const statusColumnTitle = "Test Status";
const INTERNAL_COLUMN_NAMES: Readonly<string[]> = [
  INPUT_LIST,
  OUTPUT_LIST,
  LOOP,
];

type SourceNode = { id: string; type: NODE_TYPE };

type EntityCellValue =
  | string[]
  | {
      [key: string]: EntityPropertyValue;
    };

const columnHelper = createColumnHelper<ResultDataAndAuxRowV2>();

// Gets all unique keys contained in the list of objects
export const getColumnNames = (data: GenericObjectT[]): string[] => {
  return [
    ...data.reduce(
      (set: Set<string>, row: GenericObjectT) =>
        Object.keys(row).reduce(
          (s, key) => (INTERNAL_COLUMN_NAMES.includes(key) ? s : s.add(key)),
          set,
        ),
      new Set(),
    ),
  ];
};

export const useOpenDetailedView = () => {
  const { setSelectedResultsRow } = useAuthoringUIActions();
  const { open: openWindow } = useFloatingWindowsActions();

  return useCallback(
    (row: ResultDataAndAuxRowV2, target?: RefObject<HTMLDivElement>) => {
      setSelectedResultsRow(row);
      openWindow({
        id: DETAILED_VIEW_ID,
        type: "detailed-view",
        target,
        positionOptions: {
          placement: "right",
          middleware: [
            offset(100),
            shift({
              padding: 10,
              crossAxis: true,
              limiter: limitShift(),
            }),
          ],
        },
      });
    },
    [setSelectedResultsRow, openWindow],
  );
};

const IndexCell: React.FC<{
  cell: CellContext<ResultDataAndAuxRowV2, string>["cell"];
}> = ({ cell }) => {
  const target = useRef<HTMLDivElement>(null);
  const displayedIndex = cell.row.original.index + 1;

  const { close: closeWindow } = useFloatingWindowsActions();
  const isSelectedResultsRow = useIsSelectedResultsRow(cell.row.original.index);

  const openDetailedView = useOpenDetailedView();

  const { setSelectedResultsRow } = useAuthoringUIActions();
  return (
    <div
      ref={target}
      className={twMerge(
        "group flex h-8 w-full cursor-default items-center pl-2 text-gray-800 font-inter-normal-12px",
        "justify-center pr-2",
        isSelectedResultsRow &&
          "border-y-0.5 border-l-3 border-indigo-500 pl-1",
      )}
      data-loc="open-detailed-view"
    >
      <span
        className={twJoin(
          "group-hover/row:hidden",
          isSelectedResultsRow && "hidden",
        )}
      >
        {displayedIndex}
      </span>
      <div
        className={twJoin(
          "flex-shrink-0 text-indigo-500 group-hover/row:block",
          isSelectedResultsRow ? "block" : "hidden",
        )}
      >
        <Icon
          icon={faEye}
          size="xs"
          onClick={() => {
            if (isSelectedResultsRow) {
              setSelectedResultsRow(null);
              return closeWindow(DETAILED_VIEW_ID);
            }
            openDetailedView(cell.row.original, target);
          }}
        />
      </div>
    </div>
  );
};

export const getIndexColumn = () => ({
  id: INDEX_COLUMN_ID,
  header: () => (
    <IntermediateResultsHeaderCell
      className="pr-2 text-center"
      dataLoc="index-column"
    >
      #
    </IntermediateResultsHeaderCell>
  ),
  cell: ({ cell }: CellContext<ResultDataAndAuxRowV2, string>) => (
    <IndexCell cell={cell} />
  ),
  enableHiding: false,
});

const getHeaderSchemaIcon = (
  entitySchema: EntitySchemaResource | undefined,
  eventSchema: EventConfigEnriched | undefined,
) => {
  if (entitySchema) {
    return getSchemaIcon(entitySchema);
  }
  if (eventSchema) {
    return getEventIcon(eventSchema);
  }
  return faCube;
};

// Returns the react-table columns required to represent data based on one data row
export const getColumns = (
  data: GenericObjectT[],
  objectDetailPosition: "topRight" | "topLeft",
): ColumnDef<GenericObjectT, string>[] =>
  getColumnNames(data).map((key) => ({
    id: key,
    header: () => (
      <HeaderCell dataLoc={`${key}-column`} withTooltip>
        {key}
      </HeaderCell>
    ),
    accessorFn: getAccessorFunction(key),
    cell: ({ cell }) => (
      <ResultCell
        cell={cell}
        className={twMerge("max-w-0", RESULT_SIZE_OVERWRITE)}
        columnTitle={key}
        objectDetailPosition={objectDetailPosition}
        withTooltip
      />
    ),
  }));

export type StatusFilterKey = ResultDataAndAuxRowV2["type"] | "all";
export type StatusColumnProps = {
  onSelect: (key: StatusFilterKey) => void;
  selected: StatusFilterKey;
  isFiltering: boolean;
  elements: FixedPositionedDropdownElementT<string, StatusFilterKey>[];
};

const getStatusColumn = (props: StatusColumnProps) => ({
  id: STATUS_COLUMN_ID,
  header: () => (
    <IntermediateResultsHeaderCell dataLoc="status-column">
      {statusColumnTitle}
      <IntermediateResultsTableFilter {...props} />
    </IntermediateResultsHeaderCell>
  ),
  accessorFn: (data: ResultDataAndAuxRowV2) => {
    switch (data.type) {
      case "success":
      case "success_match":
        return (
          <span className="flex items-center">
            <div className="flex-shrink-0">
              <Icon color="text-green-500" icon={faCheck} size="2xs" />
            </div>
            <div className="min-w-0 truncate">Successful</div>
          </span>
        );
      case "success_mismatch":
        return (
          <span className="flex items-center">
            <div className="flex-shrink-0">
              <Icon color="text-yellow-600" icon={faTimes} size="2xs" />
            </div>
            <div className="min-w-0 truncate">Output Mismatch</div>
          </span>
        );
      case "failure":
        return (
          <span className="flex items-center">
            <div className="flex-shrink-0">
              <Icon color="text-red-500" icon={faWarning} size="2xs" />
            </div>
            <div className="min-w-0 truncate">Failed: {data.errorMessage}</div>
          </span>
        );
      case "ignored":
        return (
          <span className="flex items-center">
            <Icon
              color="text-gray-500 hover:text-gray-700"
              icon={faWarning}
              size="2xs"
            />
            Ignored
          </span>
        );
      default:
        assertUnreachable(data);
    }
  },
  cell: ({ cell }: CellContext<ResultDataAndAuxRowV2, string>) => (
    <ResultCell
      cell={cell}
      className={twMerge(
        "max-w-0 border-r border-gray-100",
        RESULT_SIZE_OVERWRITE,
      )}
      columnTitle={statusColumnTitle}
      objectDetailPosition="topLeft"
    />
  ),
  enableHiding: false,
});

export const hasExpectedData = (
  row: ResultDataAndAuxRowV2,
): row is SuccessResultDataAndAuxRow => {
  return row.type === "success_match" || row.type === "success_mismatch";
};

const getSimpleAccessorColumn = (
  key: string,
  sourceNode: SourceNode | undefined,
) =>
  columnHelper.accessor(getAccessorFunctionWithAuxDataV2(key, "data"), {
    id: RESULT_COLUMN_PREFIX + key,
    header: () => (
      <IntermediateResultsHeaderCell dataLoc={`${key}-column`} withTooltip>
        {key}
      </IntermediateResultsHeaderCell>
    ),
    cell: ({
      cell,
    }: CellContext<ResultDataAndAuxRowV2, string | undefined>) => {
      return (
        <ResultCellWrapper cell={cell} fieldKey={key} sourceNode={sourceNode} />
      );
    },
  });

// convert PropertyDefinitionOutput to EntitySchemaProperty
const toEntitySchemaProperty = (
  property: EntitySchemaProperty | PropertyDefinitionOutput,
): EntitySchemaProperty => {
  if ("_type" in property) {
    return property;
  }

  return {
    _type: property.type,
    _display_name: property.display_name ?? "",
    _indexed: false,
    _rel_schema: property.relation_schema?.entity_type,
    _cardinality: property.type === "relation" ? Cardinality.ONE : undefined,
    _values: property.enum_schema?.values.map(({ color, value }) => ({
      _color: color,
      _internal_name: value,
    })),
  };
};

const customRenderTypes = [
  PropertyDefinitionOutputTypeEnum.RELATION,
  PropertyDefinitionOutputTypeEnum.ENUM,
  PropertyDefinitionOutputTypeEnum.TAGS,
];

const entityPropToSubColumn = (
  propName: string,
  prop: EntitySchemaProperty | PropertyDefinitionOutput,
  entityKey: string,
  sourceNode: SourceNode | undefined,
  propKeys: string[],
) => {
  const isIdProp = propName.toLocaleLowerCase() === "id";
  return columnHelper.accessor(
    (row) => {
      const entityOrEvent = row.data[entityKey];

      if (entityOrEvent) {
        // handles the case where the entity/event is passed in as an id
        if (typeof entityOrEvent === "string") {
          return isIdProp ? entityOrEvent : undefined;
        } else {
          return entityOrEvent[propName];
        }
      }
      // this makes sure to display None in id column when there is no entity/event data is available
      else if (isIdProp) {
        return "null";
      }
      return undefined;
    },
    {
      id: RESULT_COLUMN_PREFIX + entityKey + propName,
      header: () => (
        <IntermediateResultsHeaderCell
          className="flex items-center gap-x-1 border-t border-gray-100"
          withTooltip
        >
          <TypeIcon
            cardinality={"_cardinality" in prop ? prop._cardinality : undefined}
            type={"_type" in prop ? prop._type : prop.type}
          />
          {propName}
        </IntermediateResultsHeaderCell>
      ),
      cell: ({
        cell,
      }: CellContext<ResultDataAndAuxRowV2, string | undefined>) => {
        const customRender =
          ("_type" in prop && customRenderTypes.includes(prop._type)) ||
          ("type" in prop && customRenderTypes.includes(prop.type));
        if (customRender) {
          const cellValue = cell.getValue() as unknown as EntityCellValue;
          const isEntity = typeof cellValue === "object" && "id" in cellValue;
          const entityRelSchema =
            "_rel_schema" in prop ? prop._rel_schema : null;
          const eventRelSchema =
            "relation_schema" in prop
              ? (prop.relation_schema?.entity_type ?? null)
              : null;

          return (
            <div className="px-2">
              {isEntity ? (
                <EntityDetailsWindowPill
                  entityData={{ properties: cellValue }}
                  name={cellValue.id as string}
                  schemaId={entityRelSchema ?? eventRelSchema ?? ""}
                />
              ) : (
                renderValue({
                  property: toEntitySchemaProperty(prop),
                  propertyValue: cellValue,
                  isWindowPill: true,
                  foreginKeyToPrimaryProperty: {},
                })
              )}
            </div>
          );
        }

        const entityData = cell.row.original.data?.[entityKey];
        const entityKeys = Object.keys(
          typeof entityData === "object" ? (entityData ?? {}) : {},
        );
        const isStringEntity = typeof entityData === "string";
        const isUnexpectedObject = difference(propKeys, entityKeys).length > 0;

        return (
          <ResultCellWrapper
            cell={cell}
            dataTypeMismatch={
              isIdProp && (isStringEntity || isUnexpectedObject)
            }
            fieldKey={entityKey}
            sourceNode={sourceNode}
          />
        );
      },
    },
  );
};

/**
 *  Returns the react-table columns for node results V2
 */
export const getIntermediateResultColumns = (
  resultColumnNames: string[],
  auxColumnNames: string[],
  expectedColumnNames: string[] | undefined,
  statusColumnProps: StatusColumnProps,
  fieldNameToSourceNode: Map<string, SourceNode> | undefined,
  entitySchemas: EntitySchemaResource[] | undefined,
  eventSchemas: EventConfigEnriched[] | undefined,
  typing: TypingInfo | null,
  featureGates: WorkspaceFeatureGates,
): ColumnDef<ResultDataAndAuxRowV2, string>[] => {
  const expectedColumns: ColumnDef<ResultDataAndAuxRowV2, string>[] =
    expectedColumnNames?.map((key) => {
      return {
        id: EXPECTED_DATA_COLUMN_PREFIX + key,
        header: () => (
          <IntermediateResultsHeaderCell dataLoc={`expected-${key}-column`}>
            <div className="flex items-center gap-x-1">
              {key}
              <Pill size="sm" variant="gray">
                <Pill.Text>Expected</Pill.Text>
              </Pill>
            </div>
          </IntermediateResultsHeaderCell>
        ),
        accessorFn: getAccessorFunctionWithAuxDataV2(key, "expectedOutputData"),
        cell: ({ cell }: CellContext<ResultDataAndAuxRowV2, string>) => {
          const actualValue = hasExpectedData(cell.row.original)
            ? getAccessorFunction(key)(cell.row.original.data ?? {})
            : undefined;

          return (
            <ResultCell
              actualOutput={
                actualValue !== undefined
                  ? {
                      value: actualValue,
                      isMatch:
                        hasExpectedData(cell.row.original) &&
                        !cell.row.original.expectedOutputKeysMismatch?.includes(
                          key,
                        ),
                    }
                  : undefined
              }
              cell={cell}
              className={twMerge(
                "min-h-[32px] max-w-0 border-r border-gray-100",
                RESULT_SIZE_OVERWRITE,
              )}
              columnTitle={key}
              objectDetailPosition="topLeft"
              withTooltip
            />
          );
        },
      };
    }) ?? [];
  const resultColumns = resultColumnNames
    .filter((col) => !INTERNAL_COLUMN_NAMES.includes(col))
    .flatMap((key) => {
      const sourceNode = fieldNameToSourceNode?.get(key);
      const columns: ColumnDef<ResultDataAndAuxRowV2, string>[] = [];
      const typeHint = getTypeHintForDataProperty(typing, key);
      const decodedTypeHint = decodeTypeToEntityEventId(typeHint);

      if (
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        ((decodedTypeHint?.metaType === "entity" &&
          featureGates.entitiesEnabled) ||
          (decodedTypeHint?.metaType === "event" &&
            featureGates.featuresEventsEnabled))
      ) {
        let props: [string, EntitySchemaProperty | PropertyDefinitionOutput][] =
          [];
        let eventSchema = undefined;
        let entitySchema = undefined;
        let propKeys = undefined;

        if (decodedTypeHint?.metaType === "event") {
          eventSchema = eventSchemas?.find(
            (schema) => schema.internal_name === decodedTypeHint.configSchemaId,
          );
          props = Object.entries(eventSchema?.properties ?? {});
        } else if (decodedTypeHint?.metaType === "entity") {
          entitySchema = entitySchemas?.find(
            (schema) => schema._id === decodedTypeHint.configSchemaId,
          );
          props = Object.entries(entitySchema?.properties ?? {});
        }
        propKeys = props.map(([propKey]) => propKey);
        columns.push(
          columnHelper.group({
            id: RESULT_COLUMN_PREFIX + key,
            header: () => (
              <IntermediateResultsHeaderCell
                className="flex items-center gap-x-1"
                dataLoc={`${key}-column`}
              >
                <Icon
                  icon={getHeaderSchemaIcon(entitySchema, eventSchema)}
                  size="2xs"
                />
                {key}
              </IntermediateResultsHeaderCell>
            ),
            columns: props.map(([propertyName, prop]) =>
              entityPropToSubColumn(
                propertyName,
                prop,
                key,
                sourceNode,
                propKeys,
              ),
            ),
          }),
        );
      } else {
        columns.push(getSimpleAccessorColumn(key, sourceNode));
      }

      // We put a matching expected column next to it if any
      // and remove it from its original array.
      // The expected columns that don't have a matching
      // data column are appended after the ones that have a match
      const matchingExpectedColumnIndex = expectedColumns.findIndex(
        (c) => c.id === EXPECTED_DATA_COLUMN_PREFIX + key,
      );
      if (matchingExpectedColumnIndex !== -1) {
        columns.push(expectedColumns[matchingExpectedColumnIndex]);
        expectedColumns.splice(matchingExpectedColumnIndex, 1);
      }
      return columns;
    });

  const auxColumns = auxColumnNames.map((key) => ({
    id: AUX_DATA_COLUMN_PREFIX + key,
    header: () => (
      <IntermediateResultsHeaderCell
        className="bg-gray-50"
        dataLoc={`${key}-column`}
        withTooltip
      >
        {key}
      </IntermediateResultsHeaderCell>
    ),
    accessorFn: getAccessorFunctionWithAuxDataV2(key, "auxData"),
    cell: ({ cell }: CellContext<ResultDataAndAuxRowV2, string>) => (
      <ResultCell
        cell={cell}
        className={twMerge(
          "max-w-0 border-r border-gray-100",
          RESULT_SIZE_OVERWRITE,
        )}
        columnTitle={key}
        objectDetailPosition="topLeft"
        withTooltip
      />
    ),
  }));
  return [
    getIndexColumn(),
    getStatusColumn(statusColumnProps),
    ...resultColumns,
    ...expectedColumns,
    ...auxColumns,
  ];
};

const ResultCellWrapper = ({
  cell,
  sourceNode,
  fieldKey: key,
  dataTypeMismatch,
}: {
  cell: Cell<ResultDataAndAuxRowV2, string | undefined>;
  sourceNode: { id: string; type: NODE_TYPE } | undefined;
  fieldKey: string;
  dataTypeMismatch?: boolean;
}) => {
  const target = useRef<HTMLDivElement>(null);
  const isSelectedResultsRow = useIsSelectedResultsRow(cell.row.original.index);
  const expectedValue =
    cell.row.original.type === "success_match" ||
    cell.row.original.type === "success_mismatch"
      ? getAccessorFunction(key)(cell.row.original.expectedOutputData ?? {})
      : undefined;

  const openDetailedView = useOpenDetailedView();
  const { setSelectedResultsRow } = useAuthoringUIActions();

  const suffixIcon = (() => {
    if (dataTypeMismatch) {
      return <Icon color="text-yellow-500" icon={faWarning} size="2xs" />;
    }

    // We only show the debug icon if column was added by DBC Node or Loop Node
    // and if that node was executed in this particular flow run.
    if (
      sourceNode &&
      cell.row.original.nodeExecutionMetadata?.[sourceNode.id]
    ) {
      return (
        <div ref={target} className="hidden group-hover:block">
          <Icon
            icon={faCrosshairs}
            size="2xs"
            onClick={() => {
              if (!isSelectedResultsRow) {
                openDetailedView(cell.row.original, target);
              } else {
                setSelectedResultsRow(null);
              }
            }}
          />
        </div>
      );
    }
  })();

  return (
    <ResultCell
      cell={cell}
      className={twMerge(
        "group max-w-0 border-r border-gray-100",
        RESULT_SIZE_OVERWRITE,
        dataTypeMismatch && "bg-yellow-50",
      )}
      columnTitle={key}
      expectedOutput={
        expectedValue !== undefined
          ? {
              value: expectedValue,
              isMatch:
                (cell.row.original.type === "success_match" ||
                  cell.row.original.type === "success_mismatch") &&
                !cell.row.original.expectedOutputKeysMismatch?.includes(key),
            }
          : undefined
      }
      objectDetailPosition="topLeft"
      suffix={suffixIcon}
      withTooltip
    />
  );
};

const baseAccessorFunction = (value: unknown) => {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "object") {
    return JSON.stringify(value);
  } else if (value === undefined) {
    return undefined;
  } else {
    return String(value);
  }
};

// Used to tell react-table how to access the values of different columns in the data
export const getAccessorFunction = (key: string) => {
  // If the value is not a string, we need to convert it to a string
  return (d: GenericObjectT) => {
    const value = d[key];
    return baseAccessorFunction(value);
  };
};

/**
 * Used to tell react-table how to access the values of different columns in the result and aux data
 */
const getAccessorFunctionWithAuxDataV2 = (
  key: string,
  prependKey: "data" | "auxData" | "expectedOutputData",
) => {
  return (d: ResultDataAndAuxRowV2) => {
    const object = get(d, prependKey);
    if (object) {
      return getAccessorFunction(key)(object);
    }
  };
};
