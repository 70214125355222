import { faCube } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo } from "react";

import { TableComp } from "src/base-components/Table";
import { EmptyState } from "src/design-system/EmptyState";
import { getEntityDetailsColumns } from "src/entities/entityView/EntityDetailsSidePane";
import { findSchema, getTableData } from "src/entities/entityView/utils";
import {
  EntityResourceProperties,
  useEntitySchemas,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type EntityDetailsTableProps = {
  schemaId: string;
  entityData: EntityResourceProperties;
};

export const EntityDetailsTable: React.FC<EntityDetailsTableProps> = ({
  schemaId,
  entityData,
}) => {
  const { workspace } = useWorkspaceContext();
  const { data: entitySchemas, isLoading: isLoadingSchemas } = useEntitySchemas(
    {
      baseUrl: workspace.base_url ?? "",
    },
  );

  const tableData = useMemo(() => {
    const entitySchema = findSchema(schemaId, entitySchemas);
    return getTableData(entitySchema, entityData);
  }, [schemaId, entityData, entitySchemas]);

  const columns = useMemo(() => getEntityDetailsColumns(true), []);

  if (!entityData && !isLoadingSchemas) {
    return (
      <EmptyState
        description="Unable to load entity"
        headline="Error"
        icon={faCube}
        variant="default"
      />
    );
  }

  return (
    <TableComp
      columns={columns}
      data={tableData}
      frameClassName="[&_thead_tr]:hidden [&_table]:border-b-0"
      isLoading={isLoadingSchemas}
      variant="compact"
    />
  );
};
