import { JSONValue } from "@segment/analytics-next";
import { times } from "lodash";
import { useRef } from "react";

import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Tooltip } from "src/design-system/Tooltip";
import { DataValue } from "src/manualReview/reviewCaseCommon/Card";
import { useIsContentOverflows } from "src/utils/useIsContentOverflows";

type Props = {
  data: { key: string; value: unknown }[];
  isLoading?: boolean;
};

const Item: React.FC<{
  label: React.ReactNode;
  value: React.ReactNode;
}> = ({ label, value }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const isOverflowing = useIsContentOverflows(divRef);

  return (
    <li className="flex w-full p-1.5">
      <Tooltip delay={200} disabled={!isOverflowing} title={label} asChild>
        <p className="w-2/5 text-gray-800 font-inter-medium-12px">
          <div ref={divRef} className="w-auto truncate">
            {label}
          </div>
        </p>
      </Tooltip>
      <div className="w-3/5">{value}</div>
    </li>
  );
};

export const ManualReviewFieldList: React.FC<Props> = ({
  data,
  isLoading = false,
}) => {
  return (
    <ul className="my-4 divide-y divide-gray-100 border-y border-y-gray-100">
      {isLoading &&
        times(3).map((i) => (
          <Item
            key={i}
            label={<SkeletonPlaceholder height="h-5" width="w-20" />}
            value={<SkeletonPlaceholder height="h-5" />}
          />
        ))}
      {!isLoading &&
        data.map((row, index) => (
          <Item
            key={index}
            label={row.key}
            value={<DataValue value={row.value as JSONValue} />}
          />
        ))}
    </ul>
  );
};
