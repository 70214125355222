import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  useConnection,
  useCreateConnection,
  useDeleteConnection,
  useEditConnection,
} from "src/api/connectApi/queries";
import { ConnectionCreateT, ConnectionT } from "src/api/connectApi/types";
import { useWorkspaceWithSettings } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { LoadingView } from "src/base-components/LoadingView";
import { Webhook } from "src/clients/flow-api";
import { OutgoingWebhookConnectionAuthMethod } from "src/connections/config/CCAuthMethodDropdownOptions";
import { connectionConfigAuthMethodsDefaultValues } from "src/connections/model/model";
import { ConnectionConfigAuthMethods } from "src/connections/types";
import { isValidHTTPURL } from "src/connections/urls";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { TriggerSelector } from "src/webhooks/editModal/TriggerSelection";
import { AuthFields } from "src/webhooks/editModal/authentication/AuthFields";
import {
  outgoingWebhookInputsToWebhookPayload,
  outgoingWebhookInputsToCreateConnection,
  outgoingWebhookToEditFormInputs,
  getIndexFromOutgoingWebhookKey,
  getNextIndexForCreation,
} from "src/webhooks/editModal/model";
import {
  useFlowOutgoingWebhooks,
  usePutOutgoingWebhook,
} from "src/webhooks/queries";

export type OutgoingWebhookConnectionSecret = {
  key: string;
  value: string;
  editable: boolean;
};

export type TriggerSelection = {
  trigger: {
    decisionOutcome: {
      live: {
        successful: boolean;
        errored: boolean;
      };
      sandbox: {
        successful: boolean;
        errored: boolean;
      };
    };
    manualReviewCaseCreation: {
      live: boolean;
      sandbox: boolean;
    };
    jobRunExecution: {
      live: {
        completed: boolean;
        failed: boolean;
        history_complete: boolean;
      };
      sandbox: {
        completed: boolean;
        failed: boolean;
        history_complete: boolean;
      };
    };
  };
};

export const defaultTriggerSelection: TriggerSelection = {
  trigger: {
    decisionOutcome: {
      live: {
        successful: false,
        errored: false,
      },
      sandbox: {
        successful: false,
        errored: false,
      },
    },
    jobRunExecution: {
      live: {
        completed: false,
        failed: false,
        history_complete: false,
      },
      sandbox: {
        completed: false,
        failed: false,
        history_complete: false,
      },
    },
    manualReviewCaseCreation: {
      live: false,
      sandbox: false,
    },
  },
} as const;

export type OutgoingWebhookConnectionForm = ConnectionConfigAuthMethods & {
  authMethod: OutgoingWebhookConnectionAuthMethod;
} & TriggerSelection & {
    name: string;
    url: string;
    secrets: OutgoingWebhookConnectionSecret[];
    /**
     * When editing an existing webhook this field stores current secrets of the connection in the BE to
     * preserve them when switching between auth methods in the FE and to delete them correctly when saving.
     */
    currentBackendSecrets: { key: string; value: string }[];
    active: boolean;
  };

const defaultCreateFormValues = (): OutgoingWebhookConnectionForm => ({
  name: "",
  url: "",
  ...defaultTriggerSelection,
  ...{ ...connectionConfigAuthMethodsDefaultValues(), authMethod: "no_auth" },
  secrets: [],
  currentBackendSecrets: [],
  active: true,
});

const SeparatorLine: React.FC = () => (
  <div className="mb-6 mt-6 border-b border-gray-200" />
);

type Props = {
  open: boolean;
  onClose: () => void;
  onClosed?: () => void;
  webhookToEdit: Webhook | null;
};

export const EditOutgoingWebhookModal: React.FC<Props> = ({
  open,
  onClose,
  onClosed,
  webhookToEdit,
}) => {
  const { workspace, flow } = useFlowContext();
  const currentWorkspace = useWorkspaceWithSettings(workspace.id);
  const webhooksQuery = useFlowOutgoingWebhooks(flow.id);
  const putWebhook = usePutOutgoingWebhook();
  const createConnection = useCreateConnection(workspace.base_url);
  const editConnection = useEditConnection(
    workspace.base_url,
    webhookToEdit?.connection_id,
  );
  const connectionToEdit = useConnection({
    baseUrl: workspace.base_url,
    id: webhookToEdit?.connection_id ?? "",
    neverRefetch: true,
  });
  const deleteConnection = useDeleteConnection(workspace.base_url);

  const formMethods = useForm<OutgoingWebhookConnectionForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultCreateFormValues(),
  });
  const { reset, formState } = formMethods;

  useEffect(() => {
    // Set initial values when editing a webhook
    if (webhookToEdit && connectionToEdit.data) {
      // Submitting updates connectionToEdit.data. So as to not reset alreaddy during editing,
      // we only reset non-dirty forms during editing (i.e. the form before opening)
      if (!formState.isDirty) {
        const configValues = outgoingWebhookToEditFormInputs({
          webhook: webhookToEdit,
          connection: connectionToEdit.data,
        });
        reset(configValues);
      }
    } else {
      reset(defaultCreateFormValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open, connectionToEdit.data, webhookToEdit]);

  const updateWebhook = useCallback(
    async (
      getWebhookPayload: (usedConnection: ConnectionT) => Webhook,
      referencedConnection: ConnectionT | undefined,
      connectionUpdatePayload: ConnectionCreateT,
    ): Promise<boolean> => {
      let patchConnectionResult: ConnectionT | undefined = undefined;
      let putWebhookResult: Webhook | undefined = undefined;
      try {
        if (!referencedConnection)
          throw Error(
            "No current Connection provided, throw to reach catch error message",
          );
        const webhookPayload = getWebhookPayload(referencedConnection);
        patchConnectionResult = await editConnection.mutateAsync({
          id: webhookPayload.connection_id,
          payload: connectionUpdatePayload,
        });
        putWebhookResult = await putWebhook.mutateAsync(webhookPayload);
        toastActions.success({
          title: "Webhook updated",
          description: "Webhook has been updated successfully.",
          withSidebar: true,
        });
        return true;
      } catch {
        if (
          patchConnectionResult !== undefined &&
          putWebhookResult === undefined
        ) {
          toastActions.failure({
            title: "Webhook partially edited",
            description:
              "Webhook could only partially be edited. Please re-check the webhook's settings.",
            withSidebar: true,
          });
        } else {
          toastActions.failure({
            title: "Webhook editing failed",
            description: "Webhook could not be edited.",
            withSidebar: true,
          });
        }
        return false;
      }
    },
    [editConnection, putWebhook],
  );

  const createWebhook = useCallback(
    async (
      getWebhookPayload: (usedConnection: ConnectionT) => Webhook,
      connectionPayload: ConnectionCreateT,
    ): Promise<boolean> => {
      let createdConnection: ConnectionT | undefined = undefined;
      try {
        createdConnection =
          await createConnection.mutateAsync(connectionPayload);
        await putWebhook.mutateAsync(getWebhookPayload(createdConnection));
        toastActions.success({
          title: "Webhook created",
          description: "Webhook was created successfully.",
          withSidebar: true,
        });
        return true;
      } catch {
        // In case the connection was already created we try to delete it
        if (createdConnection) {
          deleteConnection.mutate(createdConnection.id);
        }
        toastActions.failure({
          title: "Webhook creation failed",
          description: "Webhook could not be created.",
          withSidebar: true,
        });
        return false;
      }
    },
    [createConnection, deleteConnection, putWebhook],
  );

  const submitData = useCallback(
    async (formData: OutgoingWebhookConnectionForm) => {
      const webhookIndexes = webhooksQuery.data?.map((webhook) =>
        getIndexFromOutgoingWebhookKey(webhook.key),
      );
      const creationIndex = getNextIndexForCreation({
        currentMaxIndex: webhookIndexes?.length
          ? Math.max(...webhookIndexes)
          : 0,
        currentWebhookCount: webhooksQuery.data?.length,
      });

      const index = webhookToEdit
        ? getIndexFromOutgoingWebhookKey(webhookToEdit.key)
        : creationIndex;

      if (currentWorkspace.data === undefined)
        throw Error("No current workspace data loaded");

      const connectionPayload = outgoingWebhookInputsToCreateConnection(
        formData,
        flow.id,
        index,
        currentWorkspace.data,
      );
      const getWebhookPayload = (usedConnection: ConnectionT) =>
        outgoingWebhookInputsToWebhookPayload({
          formData,
          workspaceId: workspace.id,
          flowId: flow.id,
          connectionId: usedConnection.id,
          resourceConfigId: usedConnection.resource_configs.filter(
            (resource) => resource.resource !== "probe",
          )[0].id,
          index,
        });
      if (webhookToEdit != null) {
        const success = await updateWebhook(
          getWebhookPayload,
          connectionToEdit.data,
          connectionPayload,
        );
        if (success) onClose();
      } else {
        const success = await createWebhook(
          getWebhookPayload,
          connectionPayload,
        );
        if (success) onClose();
      }
    },
    [
      connectionToEdit.data,
      createWebhook,
      currentWorkspace.data,
      flow.id,
      onClose,
      updateWebhook,
      webhookToEdit,
      webhooksQuery.data,
      workspace.id,
    ],
  );

  const form = (
    <>
      <FormItem gap="sm" label="Webhook Name" isRequired>
        {Boolean(formState.errors.name) && (
          <ErrorHint>{formState.errors?.name?.message}</ErrorHint>
        )}
        <Input
          data-loc="input-webhook-name"
          errored={formState.errors.name !== undefined}
          placeholder="Webhook name"
          fullWidth
          {...formMethods.register("name", {
            required: "Connection name is required",
            validate: (value) => value.length < 32 || "Name too long",
          })}
        />
      </FormItem>
      <FormItem
        description="The endpoint URL where you want to receive notifications."
        gap="sm"
        label="Endpoint URL"
        isRequired
      >
        {Boolean(formState.errors.url) && (
          <ErrorHint>{formState.errors?.url?.message}</ErrorHint>
        )}
        <Input
          data-loc="input-webhook-url"
          errored={formState.errors.url !== undefined}
          placeholder="https://example.com/postreceive"
          fullWidth
          {...formMethods.register("url", {
            validate: (value) => isValidHTTPURL(value) || "Invalid URL",
          })}
        />
      </FormItem>
      <AuthFields />
      <SeparatorLine />
      <FormItem
        description="Webhooks are only triggered on Async Calls."
        label="Which events should trigger this webhook?"
        isRequired
      >
        <TriggerSelector />
      </FormItem>
      <SeparatorLine />
      <FormItem
        className="relative"
        description="We will send notifications when this hook is triggered."
        label="Active"
      >
        <div className="absolute right-0 top-0">
          <Checkbox
            data-loc="toggle-webhook-active"
            {...formMethods.register("active")}
          />
        </div>
      </FormItem>
    </>
  );

  return (
    <Modal
      afterLeave={onClosed}
      data-loc="edit-webhook-modal"
      open={open}
      onClose={onClose}
    >
      <Modal.Header description="Enter details below to send requests for subscribed events to your URL.">
        {webhookToEdit ? "Edit webhook" : "Create webhook"}
      </Modal.Header>
      <form
        onSubmit={formMethods.handleSubmit((formData) => submitData(formData))}
      >
        <FormProvider {...formMethods}>
          <Modal.Content>
            {webhookToEdit ? (
              <LoadingView
                queryResult={connectionToEdit}
                renderUpdated={() => form}
              />
            ) : (
              form
            )}
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="webhook-save"
                disabled={
                  formMethods.formState.isSubmitting ||
                  currentWorkspace.data === undefined ||
                  // Create form is initially disabled while edit form is enabled
                  (!webhookToEdit && !formState.isDirty) ||
                  !formState.isValid
                }
                htmlType="submit"
                loading={formMethods.formState.isSubmitting}
                variant="primary"
              >
                Save
              </Button>
            }
          />
        </FormProvider>
      </form>
    </Modal>
  );
};
