import {
  faBuilding,
  faCalculatorSimple,
  faUser,
  faCreditCard,
} from "@fortawesome/pro-regular-svg-icons";
import { formatDialect, trino, hive } from "sql-formatter";

import { Pill, PillVariants } from "src/base-components/Pill";
import { Cell, Header } from "src/base-components/Table";
import {
  ColumnMappingInput,
  FeatureQueryEnvironmentSetting,
  FeatureQueryStatus,
  FeatureResponse,
  PreviewQueryRequestEnvironmentEnum,
} from "src/clients/features-control";
import { formatDuration as formatDurationUtil } from "src/utils/numbers";

export const STATUS_COLOR_MAP: Record<FeatureQueryStatus, PillVariants> = {
  active: "green",
  inactive: "gray",
} as const;

export type Entity = "card" | "account" | "company";
export const ENTITY_ICON_MAP = {
  card: faCreditCard,
  account: faUser,
  company: faBuilding,
} as const;

export type Query = {
  id: string;
  environment_settings: Record<string, FeatureQueryEnvironmentSetting>;
  event: string;
  query: string;
  backfilling_query: string;
  columns: ColumnMappingInput;
  window_hop: number;
  window_length: number;
  created_at: string;
  updated_at: string;
  finalized_at?: string;
  etag: string;
};

export type Common = {
  id: string;
  column: string;
  window_hop: number;
  window_length: number;
  status: FeatureQueryStatus;
  entities: Entity[];
  event: string;
  query: string;
};

export type Feature = FeatureResponse;

export type QueryRow = Common;
export type FeatureRow = Feature;

const DAY_IN_SECONDS = 24 * 60 * 60;
export const formatTimeWindow = (seconds: number): string => {
  if (seconds <= DAY_IN_SECONDS) {
    return formatDurationUtil(seconds * 1000, {
      locale: "long",
      format: ["days", "hours", "minutes", "seconds"],
    });
  }

  return `${Math.floor(seconds / DAY_IN_SECONDS)} days`;
};

export const commonColumns = [
  {
    accessorKey: "event",
    header: (info: any) => <Header info={info}>Event</Header>,
    cell: (info: any) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "window_length",
    header: (info: any) => <Header info={info}>Time window</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatTimeWindow(info.getValue())}</Cell>
    ),
    minSize: 94,
    maxSize: 94,
  },
  {
    accessorKey: "window_hop",
    header: (info: any) => <Header info={info}>Freshness</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatTimeWindow(info.getValue())}</Cell>
    ),
  },
];

export const formatAthenaSqlQuery = (sql: string | undefined) => {
  if (!sql) return "";

  try {
    return formatDialect(sql, { dialect: trino });
  } catch {
    try {
      return formatDialect(sql, { dialect: hive });
    } catch {
      return sql;
    }
  }
};

export type OutletContextT = {
  type: "header" | "content";
  searchQuery?: string;
};

export const isActiveQuery = (
  query: Query,
  environment: PreviewQueryRequestEnvironmentEnum,
) => {
  return (
    query.environment_settings[environment].status === FeatureQueryStatus.ACTIVE
  );
};
