import {
  Completion,
  CompletionContext,
  CompletionResult,
  insertCompletionText,
  pickedCompletion,
  startCompletion,
} from "@codemirror/autocomplete";

import { TypingInfo } from "src/clients/flow-api";

type FirstAccessor = (typeof FIRST_ACCESSORS)[number];
const FIRST_ACCESSORS = ["data", "params", "item", "meta"] as const;

//Officially, variable names in Python can be any length and can consist of uppercase and lowercase letters ( A-Z , a-z ), digits ( 0-9 ), and the underscore character ( _ ). An additional restriction is that, although a variable name can contain digits, the first character of a variable name cannot be a digit.
const PythonDotKeyCharacters = "a-zA-Z0-9_";
const PythonDotKeyCharacterMatcher = `[${PythonDotKeyCharacters}]`;
// Chars that we accept as part of a string that accesses a dict (all except a few distinctly specified)
const PythonDictAccessCharacterMatcher = `[^\n"]`;
const PythonIntegerDigitMatcher = "[0-9_]";
const bracketKeyMatcher = `\\["${PythonDictAccessCharacterMatcher}+"\\]`;
const dotKeyMatcher = `\\.${PythonDotKeyCharacterMatcher}+`;
const PythonVariableNameMatcher = "[a-zA-Z_][a-zA-Z0-9_]*";
/**
 * For now we only match raw integers and variable names as indices
 */
const indexMatcher = `\\[(${PythonIntegerDigitMatcher}+|${PythonVariableNameMatcher})\\]`;

const defaultApplyAndStartNewCompletion: Completion["apply"] = (
  view,
  completion,
  from,
  to,
) => {
  view.dispatch({
    ...insertCompletionText(view.state, completion.label, from, to),
    annotations: pickedCompletion.of(completion),
  });
  startCompletion(view);
};

export const startCompletions: Completion[] = [
  {
    label: "data.",
    type: "Dict",
    apply: defaultApplyAndStartNewCompletion,
  },
  {
    label: "params.",
    type: "Dict",
    apply: defaultApplyAndStartNewCompletion,
  },
  {
    label: "meta.",
    type: "Dict",
    apply: defaultApplyAndStartNewCompletion,
  },
  {
    label: "item.",
    type: "Dict",
    apply: defaultApplyAndStartNewCompletion,
  },
  { label: "index", type: "int" },
];

enum NotationType {
  INDEX = "index",
  DOT = "dot",
  BRACKETS = "brackets",
  FIRST_KEY_OF_PREFIXED = "firstKeyOfPrefixed",
}

const getApplyInBracketsNotation =
  (
    notationOrigin: NotationType.DOT | NotationType.FIRST_KEY_OF_PREFIXED,
  ): Completion["apply"] =>
  (view, completion, from, to) => {
    view.dispatch({
      ...insertCompletionText(
        view.state,
        `["${completion.label}"]`,
        // Also remove the dot if one is used
        notationOrigin === NotationType.DOT ? from - 1 : from,
        to,
      ),
      annotations: pickedCompletion.of(completion),
    });
  };

const applyAsBracketsAfterDot = getApplyInBracketsNotation(NotationType.DOT);
const applyAsBracketsFirstKeyAfterPrefix = getApplyInBracketsNotation(
  NotationType.FIRST_KEY_OF_PREFIXED,
);

const pythonBuiltInDictMethods = [
  "clear",
  "copy",
  "from_keys",
  "get",
  "items",
  "keys",
  "pop",
  "popitem",
  "setdefault",
  "update",
  "values",
];

const pythonBuiltInListMethods = [
  "append",
  "extend",
  "insert",
  "remove",
  "pop",
  "clear",
  "index",
  "count",
  "sort",
  "reverse",
  "copy",
];

const completionsFromTypingInfo = (
  typingInfo: TypingInfo,
  notationType: NotationType,
): Completion[] => {
  if (!typingInfo.properties) return [];
  return Object.entries(typingInfo.properties).map((keyValue): Completion => {
    const label = keyValue[0];
    // Either empty string or a string that starts with a non-alphanumeric character or a string that contains a non-alphanumeric character that is not a number or underscore
    // We also want to use brackets notation for built in dict and list methods
    const needsBracketNotation =
      label.match(
        new RegExp(`^$|^[^a-zA-Z]|[^${PythonDotKeyCharacters}0-9_]`),
      ) ||
      pythonBuiltInDictMethods.includes(label) ||
      pythonBuiltInListMethods.includes(label);

    const overwriteApplyFunction =
      needsBracketNotation && notationType === NotationType.DOT
        ? applyAsBracketsAfterDot
        : needsBracketNotation &&
            notationType === NotationType.FIRST_KEY_OF_PREFIXED
          ? applyAsBracketsFirstKeyAfterPrefix
          : undefined;
    return {
      label: label,
      type: keyValue[1].type,
      apply: overwriteApplyFunction,
    };
  });
};

type MatchFromContext = NonNullable<
  ReturnType<CompletionContext["matchBefore"]>
>;

type AccessorChain = {
  notationType: NotationType;
  /** Position at which the cleaned word starts  */
  cleanedWordFrom: number;
  /** Just the word without notation specifics */
  cleanedWord: string;
  /** The word as found in the editor, including notation specifics */
  editorWord: MatchFromContext;
}[];

export const getCompletionSource =
  (
    getTypingInfo: () => TypingInfo | undefined,
    dataPrefixed: boolean,
    withItemsAndIndex: boolean,
    dollarInvoker: string = "",
  ) =>
  (context: CompletionContext): CompletionResult | null => {
    const typingInfo = getTypingInfo();
    // If we do not want to take items explcitely into account, we remove the item property from the typing info
    if (!withItemsAndIndex) {
      delete typingInfo?.properties?.["item"];
    }
    const inComment = context.tokenBefore(["Comment"]);
    if (typingInfo === undefined || inComment) return null;

    const availableStartCompletions = startCompletions.filter((completion) => {
      if (completion.label === "params.") {
        return (
          typingInfo.properties &&
          Object.hasOwn(typingInfo.properties, "params")
        );
      }
      if (completion.label === "item." || completion.label === "index") {
        return withItemsAndIndex;
      }
      return true;
    });

    // Special cases to provide completions for the first word (no chained accessors yet)
    if (dataPrefixed) {
      const checkResult = handleStartOfInputForDataPrefixed(
        context,
        typingInfo,
      );
      if (checkResult.matchedBeginningOfInput)
        return checkResult.completionResult;
    }
    if (!dataPrefixed) {
      let firstWordRegex = new RegExp(
        `((?<!${PythonDotKeyCharacterMatcher}|\\["|\\.)${PythonDotKeyCharacterMatcher}+)|(^${PythonDotKeyCharacterMatcher}*)`,
      );
      // modify first word regex if dollar invoke is provided
      if (dollarInvoker) {
        firstWordRegex = new RegExp(
          `((?<!${PythonDotKeyCharacterMatcher}|\\["|\\.)\\${dollarInvoker}${PythonDotKeyCharacterMatcher}*)|(^\\${dollarInvoker}${PythonDotKeyCharacterMatcher}*)`,
        );
      }

      const firstWordNotChained = context.matchBefore(firstWordRegex);
      // Since brackets access also allows for special characters we have to validate that the beginning of the first word that we have identified is not actually part of a brackets accessor
      const firstWordPartOfBracketsAccess = context.matchBefore(
        new RegExp(`\\["${PythonDictAccessCharacterMatcher}*`),
      );
      if (firstWordNotChained && !firstWordPartOfBracketsAccess) {
        //suggest data. or params. (or if applicable item. and index)
        return {
          from: firstWordNotChained.from,
          options: availableStartCompletions.map((completion) => ({
            label: `${dollarInvoker}${completion.label}`,
            type: completion.type,
            apply: completion.apply,
          })),
        };
      }
    }

    // First match until the previous separator.
    let current = context.matchBefore(
      new RegExp(
        `((\\.${PythonDotKeyCharacterMatcher}*)|(\\["${PythonDictAccessCharacterMatcher}*))`,
      ),
    );
    if (!current) return null;

    const matchedChain: AccessorChain = [];

    let firstAccessor: FirstAccessor | null = dataPrefixed ? "data" : null;
    while (current) {
      pushCurrentMatchToMatchedChain(current, matchedChain);

      // Check Endconditions
      const currentMatchLength = current.to - current.from;
      if (dataPrefixed) {
        if (
          checkAndHandleStartOfInputReachedDataPrefixed(
            context,
            matchedChain,
            currentMatchLength,
          )
        ) {
          break;
        }
      } else {
        // modify accessor regex if dollar invoke is provided
        const accessorInvoker = dollarInvoker ? `\\${dollarInvoker}` : "";
        for (const accessor of FIRST_ACCESSORS) {
          const accessorMatched = context.matchBefore(
            new RegExp(
              `(?<!${PythonDotKeyCharacterMatcher}\\.?)(?<=${accessorInvoker})${accessor}(.{${currentMatchLength}})`,
            ),
          );
          if (accessorMatched) {
            firstAccessor = accessor;
            break;
          }
        }
      }

      // Match the next accessor in the chain
      current = context.matchBefore(
        new RegExp(
          `(${dotKeyMatcher}|${bracketKeyMatcher}|${indexMatcher})(.{${currentMatchLength}})`,
        ),
      );
    }

    if (!firstAccessor) return null;
    return getCompletionOptionViaAccessorChain(
      typingInfo,
      matchedChain,
      firstAccessor,
    );
  };

/**
 * Checks whether the current word is the first word in the data prefixed input and if so returns the completions based on the data field of the typing info.
 */
const handleStartOfInputForDataPrefixed = (
  context: CompletionContext,
  typingInfo: TypingInfo,
):
  | {
      matchedBeginningOfInput: false;
    }
  | {
      matchedBeginningOfInput: true;
      completionResult: CompletionResult | null;
    } => {
  const startOfInput = context.matchBefore(
    new RegExp(`^${PythonDotKeyCharacterMatcher}*`),
  );
  const startOfInputBracketNotation = context.matchBefore(
    new RegExp(`^\\["${PythonDictAccessCharacterMatcher}*`),
  );
  if (startOfInput || startOfInputBracketNotation) {
    const dataTypingInfo = typingInfo.properties?.["data"];
    const startCompletionFrom = startOfInput
      ? startOfInput.from
      : startOfInputBracketNotation
        ? startOfInputBracketNotation.from + 2
        : undefined;
    if (!dataTypingInfo || startCompletionFrom === undefined)
      return { matchedBeginningOfInput: true, completionResult: null };
    const completionOptions = completionsFromTypingInfo(
      dataTypingInfo,
      NotationType.FIRST_KEY_OF_PREFIXED,
    );
    return {
      matchedBeginningOfInput: true,
      completionResult: {
        from: startCompletionFrom,
        options: completionOptions,
      },
    };
  }
  return {
    matchedBeginningOfInput: false,
  };
};

const pushCurrentMatchToMatchedChain = (
  current: MatchFromContext,
  matchedChain: AccessorChain,
) => {
  const previouseMatchLength =
    matchedChain.at(0) !== undefined
      ? matchedChain.at(0)!.editorWord.to - matchedChain.at(0)!.editorWord.from
      : 0;
  if (current.text.startsWith(".")) {
    matchedChain.unshift({
      notationType: NotationType.DOT,
      cleanedWordFrom: current.from + 1,
      cleanedWord: current.text.substring(
        1,
        current.text.length - previouseMatchLength,
      ),
      editorWord: current,
    });
  } else if (current.text.startsWith(`["`)) {
    matchedChain.unshift({
      notationType: NotationType.BRACKETS,
      cleanedWordFrom: current.from + 2,
      cleanedWord: current.text.substring(
        2,
        current.text.length - (previouseMatchLength + 2),
      ),
      editorWord: current,
    });
  } else {
    matchedChain.unshift({
      notationType: NotationType.INDEX,
      cleanedWordFrom: current.from + 1,
      cleanedWord: current.text.substring(
        1,
        current.text.length - (previouseMatchLength + 1),
      ),
      editorWord: current,
    });
  }
};

/**
 * Check whether the next key reaches the beginning of the input. If so, add it to the matched chain.
 * @returns Whether a key was matched.
 */
const checkAndHandleStartOfInputReachedDataPrefixed = (
  context: CompletionContext,
  matchedChain: AccessorChain,
  currentMatchLength: number,
): boolean => {
  const beginningOfInputDotNotation = context.matchBefore(
    new RegExp(`^${PythonDotKeyCharacterMatcher}*(.{${currentMatchLength}})`),
  );
  if (beginningOfInputDotNotation) {
    matchedChain.unshift({
      notationType: NotationType.FIRST_KEY_OF_PREFIXED,
      cleanedWordFrom: beginningOfInputDotNotation.from,
      cleanedWord: beginningOfInputDotNotation.text.substring(
        0,
        beginningOfInputDotNotation.text.length - currentMatchLength,
      ),
      editorWord: beginningOfInputDotNotation,
    });
    return true;
  }
  const beginningOfInputBracketNotation = context.matchBefore(
    new RegExp(`^${bracketKeyMatcher}*(.{${currentMatchLength}})`),
  );
  if (beginningOfInputBracketNotation) {
    matchedChain.unshift({
      notationType: NotationType.FIRST_KEY_OF_PREFIXED,
      cleanedWordFrom: beginningOfInputBracketNotation.from + 2,
      cleanedWord: beginningOfInputBracketNotation.text.substring(
        2,
        beginningOfInputBracketNotation.text.length - (currentMatchLength + 2),
      ),
      editorWord: beginningOfInputBracketNotation,
    });
    return true;
  }
  return false;
};

/**
 * Use the accessor chain to travers the typing info.
 * @returns The nested typing info accessed by the chain.
 */
const getCompletionOptionViaAccessorChain = (
  typingInfo: TypingInfo,
  chain: AccessorChain,
  firstAccessor: FirstAccessor,
) => {
  let currentNestedTypingInfo = typingInfo.properties?.[firstAccessor];
  // The last element of the chain is the one we currently type in, so don't use it for nesting
  for (let i = 0; i < chain.length - 1; i++) {
    const match = chain[i];
    if (match.notationType === NotationType.INDEX) {
      currentNestedTypingInfo = currentNestedTypingInfo?.items;
    } else {
      currentNestedTypingInfo =
        currentNestedTypingInfo?.properties?.[match.cleanedWord];
    }
  }
  const finalNotationType = chain.at(-1)?.notationType;
  const completionStartsFrom = chain.at(-1)?.cleanedWordFrom;

  if (
    currentNestedTypingInfo?.properties === undefined ||
    finalNotationType === undefined ||
    completionStartsFrom === undefined
  )
    return null;

  const completionOptions = completionsFromTypingInfo(
    currentNestedTypingInfo,
    finalNotationType,
  );

  return {
    from: completionStartsFrom,
    options: completionOptions,
  };
};
