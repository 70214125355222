import { Menu } from "@headlessui/react";
import {
  detectOverflow,
  type Placement,
  ModifierArguments,
} from "@popperjs/core";
import { groupBy } from "lodash";
import { Fragment, ReactNode, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";

import { assertUnreachable } from "src/utils/typeUtils";

export type FixedDropDownKey = string | null;

type Section = { key: string; displayText: string };

export type FixedPositionedDropdownElementT<
  T,
  K extends FixedDropDownKey = string,
  Sections extends readonly Section[] | undefined = undefined,
> = {
  key: K;
  value: T;
  disabled?: boolean;
} & (Sections extends readonly Section[]
  ? { sectionKey: Sections[number]["key"] }
  : {});

export type RenderValueFn<
  T,
  K extends FixedDropDownKey = string,
  Sections extends readonly Section[] | undefined = undefined,
> = (
  element: FixedPositionedDropdownElementT<T, K, Sections> & {
    selected: boolean;
  },
  close: () => void,
) => ReactNode;

type FixedPositionedDropdownPlacement =
  | "topRight"
  | "topLeft"
  | "bottomRight"
  | "bottomLeft"
  | "bottom";

export type SingleValueDropdownProps<T, K extends FixedDropDownKey> = {
  multiple?: false;
  selected?: K;
  onSelect?: (keys: K) => void;
  renderButtonValue: (
    values: T,
    isOpen: boolean,
    close: () => void,
  ) => ReactNode;
};

export type MultipleValueDropdownProps<T, K extends FixedDropDownKey> = {
  multiple: true;
  selected?: K[];
  onSelect?: (keys: K[]) => void;
  renderButtonValue: (
    values: T[],
    isOpen: boolean,
    close: () => void,
  ) => ReactNode;
};

export type FixedPositionedDropdownPropsT<
  T,
  K extends FixedDropDownKey = string,
  Sections extends readonly Section[] | undefined = undefined,
> = {
  elements: FixedPositionedDropdownElementT<T, K, Sections>[];
  renderValue: RenderValueFn<T, K>;
  renderFooter?: () => ReactNode;
  renderHeader?: () => ReactNode;
  className?: string;
  buttonClassName?: string;
  placement?: FixedPositionedDropdownPlacement;
  buttonDataLoc?: string;
  itemsClassNames?: string;
  disabled?: boolean;
  dataLoc?: string;
  listMatchesButtonWidth?: boolean;
  placementOffsetX?: number;
  sections?: Sections;
  itemsPortal?: boolean;
} & (SingleValueDropdownProps<T, K> | MultipleValueDropdownProps<T, K>);

const getPopperPlacement = (
  placement: FixedPositionedDropdownPlacement,
): Placement => {
  switch (placement) {
    case "bottomLeft":
      return "bottom-start";
    case "bottomRight":
      return "bottom-end";
    case "topLeft":
      return "top-start";
    case "topRight":
      return "top-end";
    case "bottom":
      return "bottom";
    default:
      assertUnreachable(placement);
      return "bottom";
  }
};

// Adapted from https://www.npmjs.com/package/popper-max-size-modifier
const applyMaxSizeModifier = {
  name: "applyMaxSize" as const,
  enabled: true,
  phase: "beforeWrite" as const,
  requires: ["maxSize"],
  fn: ({ state }: ModifierArguments<{}>) => {
    const { height } = state.modifiersData.maxSize;
    state.styles.popper.maxHeight = `${height - 20}px`;
  },
};

const maxSizeModifier = {
  name: "maxSize" as const,
  enabled: true,
  phase: "main" as const,
  requiresIfExists: ["offset", "preventOverflow", "flip"],
  fn: ({ state, name, options }: ModifierArguments<{}>) => {
    const overflow = detectOverflow(state, options);
    const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
    const { width, height } = state.rects.popper;
    const [basePlacement] = state.placement.split("-");

    const widthProp = basePlacement === "left" ? "left" : "right";
    const heightProp = basePlacement === "top" ? "top" : "bottom";

    state.modifiersData[name] = {
      width: width - overflow[widthProp] - x,
      height: height - overflow[heightProp] - y,
    };
  },
};

export const FixedPositionedDropdown = <
  T,
  K extends FixedDropDownKey = string,
  Sections extends readonly Section[] | undefined = undefined,
>({
  disabled = false,
  onSelect,
  elements,
  renderButtonValue,
  renderValue,
  renderFooter,
  selected,
  className,
  buttonClassName,
  placement = "bottomRight",
  buttonDataLoc,
  itemsClassNames,
  renderHeader,
  dataLoc,
  listMatchesButtonWidth = true,
  multiple,
  placementOffsetX,
  sections,
  itemsPortal = false,
}: FixedPositionedDropdownPropsT<T, K, Sections>) => {
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      strategy: "fixed",
      placement: getPopperPlacement(placement),
      modifiers: [
        { name: "offset", options: { offset: [placementOffsetX ?? 0, 8] } },
        maxSizeModifier,
        applyMaxSizeModifier,
      ],
    },
  );

  const selectedKeys = Array.isArray(selected)
    ? selected
    : selected
      ? [selected]
      : [];
  const selectedItems = elements.filter((element) =>
    selectedKeys.includes(element.key),
  );

  const menuSpanClassName = (active: boolean) =>
    twJoin(
      "block text-xs text-gray-800",
      active && "cursor-pointer bg-gray-50",
      !active && "cursor-default",
    );

  const getNewSelectedItems = (key: K) =>
    selectedKeys.includes(key)
      ? selectedKeys.filter((selectedKey) => selectedKey !== key)
      : [...selectedKeys, key];

  const renderItems = (
    elements: FixedPositionedDropdownElementT<T, K, Sections>[],
  ) =>
    elements.map((element) => (
      <Menu.Item key={element.key} as="div" disabled={element.disabled}>
        {({ active, close }) => (
          <span
            className={menuSpanClassName(active)}
            onClick={(e) => {
              if (multiple) {
                e.stopPropagation(); // avoids closing the menu when multi select is possible
                onSelect?.(getNewSelectedItems(element.key));
              } else {
                onSelect?.(element.key);
              }
            }}
          >
            {renderValue(
              {
                ...element,
                selected: multiple
                  ? Boolean(selectedKeys?.includes(element.key))
                  : selectedKeys[0] === element.key,
              },
              close,
            )}
          </span>
        )}
      </Menu.Item>
    ));

  const renderItemsWithSections = (
    elements: FixedPositionedDropdownElementT<T, K, Sections>[],
    sections: Sections,
  ) => {
    if (!sections) return renderItems(elements);
    const resultsGroupedBySections = groupBy(elements, (r) =>
      "sectionKey" in r ? r.sectionKey : "",
    );

    const sectionsWithResults = sections
      .filter((section) => section.key in resultsGroupedBySections)
      .map((section) => ({
        ...section,
        results: resultsGroupedBySections[section.key],
      }));

    return (
      <>
        {sectionsWithResults.map((section, i) => (
          <Fragment key={section.displayText}>
            <p
              className={twJoin(
                "pb-1.5 pl-4 text-gray-800 font-inter-normal-12px",
                i === 0 ? "pt-1.5" : "pt-3.5",
              )}
            >
              {section.displayText}
            </p>
            {renderItems(section.results)}
          </Fragment>
        ))}
      </>
    );
  };

  const renderFooterAsItem = () =>
    renderFooter ? (
      <Menu.Item>
        {({ active }) => (
          <span className={menuSpanClassName(active)}>{renderFooter()}</span>
        )}
      </Menu.Item>
    ) : undefined;
  const disableButton =
    disabled || (elements.length === 0 && !renderFooter && !renderHeader);

  const items = (
    <Menu.Items
      ref={setPopperElement}
      className={twMerge(
        "decideScrollbar z-50 rounded-lg bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none",
        itemsClassNames,
      )}
      data-loc={`${dataLoc}-dropdown`}
      style={
        listMatchesButtonWidth
          ? {
              ...popperStyles.popper,
              minWidth: referenceElement.current?.scrollWidth,
            }
          : popperStyles.popper
      }
      {...popperAttributes.popper}
    >
      {renderHeader?.()}
      {sections
        ? renderItemsWithSections(elements, sections)
        : renderItems(elements)}
      {renderFooterAsItem()}
    </Menu.Items>
  );
  return (
    <Menu
      ref={referenceElement}
      as="div"
      className={twMerge(
        "text-left text-gray-800 font-inter-normal-13px",
        className,
      )}
      data-loc={dataLoc}
    >
      {({ open, close }) => (
        <>
          <Menu.Button
            className={twMerge(
              "flex h-full w-full items-center justify-center rounded-lg",
              "border border-gray-200 bg-white",
              "ring-inset focus-within:border-indigo-400 focus-within:ring-2 focus-within:ring-indigo-500/25 focus:outline-none",
              (multiple ? selectedKeys.length : selectedItems[0]?.value) &&
                "text-gray-500",
              disableButton && "cursor-default",
              open &&
                "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
              buttonClassName,
            )}
            data-loc={buttonDataLoc}
            disabled={disableButton}
          >
            {multiple
              ? renderButtonValue(
                  selectedItems.map((item) => item?.value),
                  open,
                  close,
                )
              : renderButtonValue(selectedItems[0]?.value, open, close)}
          </Menu.Button>
          {itemsPortal ? createPortal(items, document.body) : items}
        </>
      )}
    </Menu>
  );
};
