import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { S3BucketDataset, S3PresignedPost } from "./types";
import { s3BucketDatasetApi } from "src/api/exporterApi";

export const useS3BucketDatasets = (baseURL: string, flowId: string) => {
  return useQuery({
    queryKey: ["s3_bucket_datasets", baseURL, flowId],
    queryFn: async () =>
      (
        await s3BucketDatasetApi.get<S3BucketDataset[]>("/s3_bucket_datasets", {
          baseURL,
          params: { flow_id: flowId },
        })
      ).data,
    enabled: !!baseURL,
  });
};

export const useS3BucketDataset = (baseURL: string, datasetId?: string) => {
  return useQuery({
    queryKey: ["s3_bucket_datasets", baseURL, datasetId],
    queryFn: async () =>
      (
        await s3BucketDatasetApi.get<S3BucketDataset>(
          `/s3_bucket_datasets/${datasetId}`,
          {
            baseURL,
          },
        )
      ).data,
    enabled: !!baseURL && !!datasetId,
  });
};

export const useCreateS3BucketDataset = (baseURL: string, flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (name: string) =>
      (
        await s3BucketDatasetApi.post<S3BucketDataset>(
          "/s3_bucket_datasets",
          { name, flow_id: flowId },
          {
            baseURL,
          },
        )
      ).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["s3_bucket_datasets", baseURL, flowId],
      });
    },
  });
};

export const useUploadFileToS3BucketDataset = (
  baseURL: string,
  datasetId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (file: File) => {
      const presignedPost = await s3BucketDatasetApi.post<S3PresignedPost>(
        `/s3_bucket_datasets/${datasetId}/file`,
        { file_name: file.name },
        {
          baseURL,
        },
      );
      const { url, fields } = presignedPost.data;
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);
      formData.append("Content-Type", file.type);

      await fetch(url, {
        method: "POST",
        body: formData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["s3_bucket_datasets", baseURL, datasetId],
      });
    },
  });
};
