import { isAxiosError } from "axios";
import { useState } from "react";

import { usePatchWorkspace } from "src/adminPanel/queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Label } from "src/base-components/Label";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedWs: WorkspaceDataplane;
};

export const ModifyWsFeatureGatesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedWs,
}) => {
  const [featureGates, setFeatureGates] = useState(
    selectedWs.feature_gates ?? {},
  );
  const [errors, setErrors] = useState<string>("");

  const patchWs = usePatchWorkspace();
  // once a feature gate is enabled it can't be disabled anymmore, so we disable the submit button if all are enabled
  const disableSubmit = Object.values(selectedWs.feature_gates ?? {}).every(
    (value) => value === true,
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Modify Workspace Feature Gates</Modal.Header>
      <Modal.Content>
        {Object.entries(featureGates).map(([key, value]) => (
          <div key={key} className="my-2 flex items-center space-x-2">
            <Checkbox
              checked={value}
              disabled={selectedWs.feature_gates?.[key]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFeatureGates((prev) => ({
                  ...prev,
                  [key]: event.target.checked,
                }));
              }}
            />
            <Label mb="mb-0">{key}</Label>
          </div>
        ))}
        <ErrorHint>{errors}</ErrorHint>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="submit"
            disabled={disableSubmit}
            loading={patchWs.isPending}
            variant="primary"
            onClick={async () => {
              const workspace = selectedWs;
              workspace.feature_gates = featureGates;
              try {
                await patchWs.mutateAsync(workspace);
                onClose();
              } catch (e) {
                if (isAxiosError(e)) {
                  setErrors(
                    JSON.stringify(e.response?.data?.detail) ?? e.message,
                  );
                } else {
                  setErrors(JSON.stringify(e));
                }
              }
            }}
          >
            Submit
          </Button>
        }
      />
    </Modal>
  );
};
