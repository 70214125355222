import { faRefresh, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { UseQueryResult } from "@tanstack/react-query";
import {
  endOfDay,
  endOfToday,
  isValid,
  parseISO,
  startOfDay,
  startOfToday,
} from "date-fns";
import { noop } from "lodash";
import { useState } from "react";
import { SelectRangeEventHandler } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import {
  DevisionHistoryV3GetRequest,
  FLOW_MISSMATCH,
  useHistoryDecisionsV3,
  useHistoryDecisionV3,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { LoadingView } from "src/base-components/LoadingView";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { ColumnSelector } from "src/decisionsOverview/ColumnSelector";
import { useViewOptions } from "src/decisionsOverview/useViewOptions";
import { SubHeader } from "src/flow/SubHeader";
import { DecisionHistoryTable } from "src/flow/decisionHistory/TableWrapper";
import {
  useEnvironment,
  useSearchTerm,
} from "src/flow/decisionHistory/filterHooks";
import { useDecisionTracingDisabledCb } from "src/flow/decisionHistory/useDecisionTracingDisabledCb";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { maxTableWidth } from "src/layout/constants";
import { DecisionHistoryKeys } from "src/router/SearchParams";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToHistoricDecisionTrace } from "src/router/urls";
import { renderEmpty } from "src/utils/renderEmpty";
import { useTimeWindow } from "src/utils/timeWindow";

type Props = {};

const useDefaultValuesFromSearchParams = () => {
  const [searchParams] = useSearchParams();

  const fromDate = parseISO(searchParams.get(DecisionHistoryKeys.From) ?? "");
  const toDate = parseISO(searchParams.get(DecisionHistoryKeys.To) ?? "");
  const from = isValid(fromDate) ? fromDate : startOfToday();
  const to = isValid(toDate) ? toDate : endOfToday();

  const jobId = searchParams.get(DecisionHistoryKeys.JobId);
  const jobRunId = searchParams.get(DecisionHistoryKeys.JobRunId);
  const filters =
    jobRunId && jobId ? { jobIds: jobId, jobRunIds: jobRunId } : {};

  return {
    timeWindow: {
      from,
      to,
    },
    filters,
  };
};

export const FlowHistoryContent: React.FC<Props> = () => {
  const [, setSearchParams] = useSearchParams();
  const defaultValues = useDefaultValuesFromSearchParams();
  const { entitiesEnabled } = useWorkspaceFeatureGates();
  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      defaultValues.timeWindow,
      "flow-decision-history-time-window",
    );

  const onTimeWindowChange: SelectRangeEventHandler = (range) => {
    onDateRangePickerChange(range);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (range && range.from && range.to) {
        newParams.set(
          DecisionHistoryKeys.From,
          startOfDay(range.from).toISOString(),
        );
        newParams.set(DecisionHistoryKeys.To, endOfDay(range.to).toISOString());
      } else {
        newParams.delete(DecisionHistoryKeys.From);
        newParams.delete(DecisionHistoryKeys.To);
      }
      return newParams;
    });
  };

  const [term, setTerm] = useSearchTerm();
  const isQueryingByTerm = term !== "";

  const [environment, setEnvironment] = useEnvironment();
  const [filterFields, setFilterFields] = useState<DevisionHistoryV3GetRequest>(
    defaultValues.filters,
  );
  const { orgId, workspace, flow } = useFlowContext();
  const viewOptions = useViewOptions({
    flow,
    includeEntityId: !entitiesEnabled,
  });

  const singleDecisionById = useHistoryDecisionV3({
    baseUrl: workspace.base_url ?? "",
    decisionId: term,
    flowIdToMatch: flow.id,
    environmentToMatch: environment,
  });

  const termIsDecisionIdOfOtherFlow =
    !singleDecisionById.data?.isQueryError ||
    (singleDecisionById.data?.isQueryError &&
      singleDecisionById.data.reason === FLOW_MISSMATCH);

  const decisionHistory = useHistoryDecisionsV3({
    baseUrl: workspace.base_url ?? "",
    flowIds: filterFields.flowVersionIds ? undefined : flow.id,
    flowVersionIds: filterFields.flowVersionIds,
    environments: environment,
    startStartTime: timeWindow.from.toISOString(),
    endStartTime: timeWindow.to.toISOString(),
    parentDecisionIds:
      isQueryingByTerm && termIsDecisionIdOfOtherFlow ? term : undefined,
    entityIds:
      !entitiesEnabled && isQueryingByTerm && !termIsDecisionIdOfOtherFlow
        ? term
        : undefined,
    $fields: viewOptions.fieldsMask,
    ...filterFields,
  });

  const singleDecisionDisplayData =
    singleDecisionById.data && !singleDecisionById.data.isQueryError
      ? [singleDecisionById.data.decision]
      : undefined;

  const termFilteredData = term
    ? singleDecisionDisplayData
      ? singleDecisionDisplayData
      : decisionHistory.data?.pages?.flatMap((page) => page.decisions)
    : undefined;

  const displayedData = isQueryingByTerm
    ? termFilteredData
    : decisionHistory.data?.pages?.flatMap((page) => page.decisions);

  const decisionTracingDisabledCb = useDecisionTracingDisabledCb(flow);
  const openDecisionTrace = (decision: HistoricalDecisionV3) => {
    const decisionTraceUrl = getUrlToHistoricDecisionTrace({
      orgId,
      wsId: workspace.id,
      flowId: decision.flow.id!,
      versionId: decision?.flow?.version?.id!,
      decisionId: decision.id,
      decisionEnv: environment,
    });
    window.open(window.location.origin + decisionTraceUrl, "_blank");
  };

  const renderTable = () => (
    <FloatingWindowsProvider>
      <DecisionHistoryTable
        canFetchNextPage={Boolean(
          !decisionHistory.isFetching && decisionHistory.hasNextPage,
        )}
        changeFilterFields={setFilterFields}
        columnVisibility={viewOptions.columnVisibility}
        decisionHistory={displayedData}
        env={environment}
        fetchNextPage={decisionHistory.fetchNextPage}
        filterFields={filterFields}
        flow={flow}
        includeEntityId={!entitiesEnabled}
        includeEntityLinks={!entitiesEnabled}
        isFetching={
          isQueryingByTerm
            ? singleDecisionById.isFetching || decisionHistory.isFetching
            : decisionHistory.isFetching
        }
        singleDecisionError={
          !decisionHistory.isFetching &&
          (termFilteredData === undefined || termFilteredData.length === 0) &&
          singleDecisionById.data?.isQueryError
            ? singleDecisionById.data.reason
            : undefined
        }
        tracingHoverBehavior="trace"
        includeInputOutput
        includeOutcomes
        onDecisionClick={{
          predicate: (decision) =>
            !decisionTracingDisabledCb(decision).isDisabled,
          effect: (decision) => {
            if (!decisionTracingDisabledCb(decision).isDisabled)
              openDecisionTrace(decision);
          },
        }}
      />
      {/* Render a loading view below the table to give error infos while the header
        of the table is still rendered*/}

      <LoadingView
        queryResult={
          (isQueryingByTerm
            ? singleDecisionById
            : decisionHistory) as UseQueryResult<any, Error>
        }
        renderUpdated={renderEmpty}
        renderUpdating={renderEmpty}
      />
    </FloatingWindowsProvider>
  );

  return (
    <>
      <SubHeader
        title="Decision history"
        titleAction={
          <EnvironmentPill value={environment} onChange={setEnvironment} />
        }
        paddedParent
      >
        <SubHeader.SearchBox
          defaultValue={term}
          placeholder={
            entitiesEnabled
              ? "Search by Decision ID ..."
              : "Search by Decision ID or Entity ID ..."
          }
          onChange={setTerm}
        />
        <ColumnSelector
          button={
            <SubHeader.Button
              as="div"
              dataLoc="decision-history-view-options-button"
              icon={faSliders}
              tooltip="View options"
              onClick={noop}
            />
          }
          flow={flow}
          includeEntityId={!entitiesEnabled}
          {...viewOptions}
        />
        <SubHeader.DatePicker
          value={dateRangePickerValue}
          onChange={onTimeWindowChange}
        />
        <SubHeader.Button
          dataLoc="decision-history-refresh-button"
          disabled={decisionHistory.isFetching}
          icon={faRefresh}
          spin={decisionHistory.isFetching}
          tooltip="Refresh"
          onClick={() => decisionHistory.refetch()}
        />
      </SubHeader>
      <div
        className={twJoin(
          "relative mx-auto flex h-[calc(100%-48px)] rounded-lg border border-gray-200 bg-white px-4",
          maxTableWidth,
        )}
      >
        {renderTable()}
      </div>
    </>
  );
};
