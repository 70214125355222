import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { isNaN } from "lodash";
import { ReactNode } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Skeleton } from "src/base-components/Skeleton";
import { PerformanceSource } from "src/performance/ApiJobsPill";
import { Card } from "src/performance/Card";
import { useJobsSummary, useSummary } from "src/performance/queries";
import {
  JobsSummary,
  Summary,
  PerformanceFilters,
  SummaryResult,
  JobsSummaryResult,
} from "src/performance/types";
import { displayLatency, getPercentageDiff } from "src/performance/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { formatNumber } from "src/utils/numbers";

const isSummaryWithLatency = (
  summary: Summary | JobsSummary,
): summary is Summary => "latencyP90" in summary;

type OverviewPaneProps = {
  filters: PerformanceFilters;
  source: PerformanceSource;
};

const getDifference = ({
  currentPeriod: current,
  previousPeriod: previous,
}: SummaryResult | JobsSummaryResult) => {
  let errorRate = NaN;
  if (previous.decisions > 0) {
    errorRate = (current.errorRate - previous.errorRate) * 100;
  }

  return {
    decisions: getPercentageDiff(previous.decisions, current.decisions),
    errors: getPercentageDiff(previous.errors, current.errors),
    errorRate: errorRate,
    ...(isSummaryWithLatency(current) &&
      isSummaryWithLatency(previous) && {
        latencyP90: getPercentageDiff(previous.latencyP90, current.latencyP90),
      }),
  };
};

export const OverviewPane: React.FC<OverviewPaneProps> = ({
  filters,
  source,
}) => {
  const { workspace, flow } = useFlowContext();
  const { versions, timeWindow } = filters;

  const summary = useSummary(
    workspace.base_url,
    { versions, timeWindow },
    {
      enabled: source === PerformanceSource.API,
    },
  );

  const jobsSummary = useJobsSummary(workspace.base_url, {
    flowId: flow.id,
    timeWindow,
    options: { enabled: source === PerformanceSource.JOBS },
  });

  const selectedData: SummaryResult | JobsSummaryResult =
    source === PerformanceSource.API ? summary.data : jobsSummary.data;

  const currentPeriod: Summary | JobsSummary = selectedData.currentPeriod;
  const difference = getDifference(selectedData);
  return (
    <Card width={source === PerformanceSource.JOBS ? "w-3/4" : "w-full"}>
      <div
        className={twMerge(
          "grid divide-x divide-gray-200",
          source === PerformanceSource.API && "grid-cols-4",
          source === PerformanceSource.JOBS && "grid-cols-3",
        )}
      >
        <OverviewValue
          difference={
            <Difference
              isLoading={summary.isFetching}
              value={difference.decisions}
            />
          }
          isLoading={summary.isFetching}
          name="Total decisions"
          value={formatNumber(currentPeriod.decisions)}
        />
        <OverviewValue
          difference={
            <Difference
              isLoading={summary.isFetching}
              value={difference.errors}
              invert
            />
          }
          isLoading={summary.isFetching}
          name="Total errors"
          value={formatNumber(currentPeriod.errors)}
        />
        <OverviewValue
          difference={
            <Difference
              isLoading={summary.isFetching}
              value={difference.errorRate}
              invert
              isAbsolute
            />
          }
          isLoading={summary.isFetching}
          name="Error rate"
          value={formatNumber(currentPeriod.errorRate, {
            style: "percent",
            maximumFractionDigits: 1,
          })}
        />
        {isSummaryWithLatency(currentPeriod) &&
          isSummaryWithLatency(difference) && (
            <OverviewValue
              difference={
                <Difference
                  isLoading={summary.isFetching}
                  value={difference.latencyP90}
                  invert
                />
              }
              isLoading={summary.isFetching}
              name="Response time (P90)"
              value={displayLatency(currentPeriod.latencyP90)}
            />
          )}
      </div>
    </Card>
  );
};

const OverviewValue: React.FC<{
  name: string;
  value?: string | number;
  difference?: ReactNode;
  isLoading?: boolean;
}> = ({ name, difference, value, isLoading = false }) => (
  <div className="relative py-4 pl-10">
    <div>
      <span className="text-gray-800 font-inter-semibold-16px">
        {isLoading ? <Skeleton width="w-12" /> : value}
      </span>
      {difference}
      <div className="text-gray-500 font-inter-normal-12px">{name}</div>
    </div>
  </div>
);

const Difference: React.FC<{
  value: number;
  invert?: boolean;
  isLoading?: boolean;
  isAbsolute?: boolean;
}> = ({ value, invert, isAbsolute, isLoading }) => {
  const isNaNValue = isNaN(value);
  const isNegative = value <= 0;
  const icon = isNegative ? faArrowDown : faArrowUp;

  const isRed = isNaNValue || (invert ? !isNegative : isNegative);
  const isGreen = !isNaNValue && (invert ? isNegative : !isNegative);

  if (isLoading) {
    return <span></span>;
  }

  const formattedValue = formatNumber(value, {
    style: isAbsolute ? "decimal" : "percent",
    signDisplay: "never",
    maximumFractionDigits: 0,
  });

  return (
    <span
      className={twJoin(
        "ml-2 inline-flex items-end font-inter-semibold-13px",
        isGreen && "text-green-500",
        isRed && "text-red-500",
      )}
    >
      {!isNaNValue && <Icon icon={icon} size="3xs" />}
      <span className="-ml-0.5 translate-y-px">
        {isNaNValue
          ? "--"
          : isAbsolute
            ? `${formattedValue} p.p.`
            : formattedValue}
      </span>
    </span>
  );
};
