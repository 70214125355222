import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { AiNodeSelectableSchemaTypes, AiNodeV2Form } from "src/aiNode/types";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { Select } from "src/base-components/Select";
import { Textarea } from "src/base-components/Textarea";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { type SchemaEnumErrorStatus } from "src/schema/SchemaEnumRow";
import { SchemaEnumRows } from "src/schema/SchemaEnumRows";

type JsonSchemaRowProps = {
  id: string;
  immutable: boolean;
  handleRemove: () => void;
  index: number;
};

export const JsonSchemaRow: React.FC<JsonSchemaRowProps> = ({
  id,
  immutable,
  handleRemove,
  index,
}) => {
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id,
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<AiNodeV2Form>();

  const prefix =
    `inference.response_format.json_schema.properties.${index}` as const;

  const propertyEnum = useWatch({
    control,
    name: `${prefix}.enum`,
  });

  return (
    <div
      ref={setNodeRef}
      className="w-full"
      data-loc={`ai-node-response-schema-${index}`}
      style={style}
    >
      <div className="flex w-full items-center gap-x-2">
        <ReorderHandle immutable={immutable} listeners={listeners} />
        <div className="min-w-0 flex-1">
          <Controller
            control={control}
            name={`${prefix}.fieldName`}
            render={({ field }) => (
              <AutocompleteCodeInput
                dataLoc={`ai-node-field-location-${index}`}
                disabled={immutable}
                error={
                  errors?.inference?.response_format?.json_schema?.properties?.[
                    index
                  ]?.fieldName?.message
                }
                placeholder="field"
                prefix="data."
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="w-36">
          <Controller
            control={control}
            name={`${prefix}.type.0`}
            render={(props) => (
              <Select
                dataLoc={`ai-node-field-type-${index}`}
                disabled={immutable}
                dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
                options={SCHEMA_TYPES_OPTIONS}
                value={props.field.value}
                onChange={(value) => {
                  if (value === "enum" && props.field.value !== "enum") {
                    setValue(`${prefix}.enum`, [{ value: '"value_1"' }]);
                  }
                  if (value !== "enum" && props.field.value === "enum") {
                    setValue(`${prefix}.enum`, undefined);
                  }
                  props.field.onChange(value);
                }}
              />
            )}
          />
        </div>
        <Icon
          color="text-gray-400 hover:text-gray-700"
          icon={faTrashCan}
          size="sm"
          onClick={!immutable ? handleRemove : undefined}
        />
      </div>
      {Array.isArray(propertyEnum) && !isDragging && (
        <SchemaEnumRows
          enumFormLocation={`${prefix}.enum`}
          errorStatus={
            errors?.inference?.response_format?.json_schema?.properties?.[index]
              ?.enum as SchemaEnumErrorStatus
          }
          immutable={immutable}
          withValidation
        />
      )}
      <div className="ml-7 mt-2">
        <Textarea
          data-loc={`ai-node-field-description-${index}`}
          disabled={immutable}
          {...register(`${prefix}.description`)}
          placeholder="Describe what this field represents in the response..."
          fullWidth
        />
      </div>
    </div>
  );
};

const SCHEMA_TYPES_OPTIONS = Object.entries(AiNodeSelectableSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);
