import { observer } from "mobx-react-lite";
import React from "react";

import { FlowT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Modal } from "src/design-system/Modal";

export const MAX_ROWS_FOR_AI_TEST_RUN = 10;

type RunBlockedModalPropsT = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  flow: FlowT;
  datasetName?: string;
  datasetSize?: number;
  aiNodeNames?: string[];
  flowVersionName?: string;
  onDuplicateDataset: () => void;
};

export const RunBlockedModal: React.FC<RunBlockedModalPropsT> = observer(
  ({
    isOpen,
    onClose,
    isLoading,
    datasetName,
    datasetSize,
    aiNodeNames,
    flowVersionName,
    onDuplicateDataset,
  }) => {
    if (!datasetName || !datasetSize || !aiNodeNames || !flowVersionName) {
      return null;
    }

    return (
      <Modal open={isOpen} size="sm" onClose={onClose}>
        <Modal.Header>Test running a flow with live AI Nodes</Modal.Header>
        <Modal.Content>
          <div className="flex flex-col gap-y-1.5 text-gray-500 font-inter-normal-13px">
            <p>
              Decision Flow version{" "}
              <b className="text-gray-800 font-inter-medium-13px">
                {flowVersionName}
              </b>{" "}
              includes {aiNodeNames.length} AI{" "}
              {aiNodeNames.length > 1 ? "nodes" : "node"} making live inference
              calls:
            </p>
            <ul className="ml-6 list-disc text-gray-800 font-inter-medium-13px">
              {aiNodeNames.map((nodeName) => (
                <li key={nodeName}>{nodeName}</li>
              ))}
            </ul>
            <p>
              Your test dataset{" "}
              <b className="text-gray-800 font-inter-medium-13px">
                {datasetName}
              </b>{" "}
              contains{" "}
              <b className="text-gray-800 font-inter-medium-13px">
                {datasetSize} rows
              </b>
              .
            </p>
            <p>
              To ensure performance and manage usage, test runs with AI Nodes
              making live calls are limited to{" "}
              <b className="text-gray-800 font-inter-medium-13px">
                {MAX_ROWS_FOR_AI_TEST_RUN} rows
              </b>
              . Please create a copy of your dataset with a maximum of 10 rows
              to continue testing.
            </p>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="confirm-stop-run"
              htmlType="submit"
              loading={isLoading}
              variant="primary"
              onClick={onDuplicateDataset}
            >
              Create copy of dataset with first {MAX_ROWS_FOR_AI_TEST_RUN} rows
            </Button>
          }
        />
      </Modal>
    );
  },
);
