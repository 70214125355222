import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import {
  CellContext,
  createColumnHelper,
  DisplayColumnDef,
  Row,
} from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";

import {
  AccordionRoot,
  EditorAccordionItem,
} from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import {
  Cell,
  ColumnVisibilityDropdown,
  Header,
  TableComp,
} from "src/base-components/Table";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { AccordionSkeleton } from "src/decisionsOverview/DecisionsOverview";
import { TruncatedIdPill } from "src/decisionsOverview/TruncatedIdPill";
import { renderValue } from "src/entities/entityView/EntityDetailsSidePane";
import {
  EntityDetailsFloatingWindow,
  EntityDetailsWindowPill,
} from "src/entities/entityView/EntityDetailsWindowPill";
import {
  findSchema,
  getOrderedProperties,
  getSchemaIcon,
} from "src/entities/entityView/utils";
import {
  EntityResource,
  EntitySchemaResource,
  EntitySchemaProperty,
  useEntitySchemas,
  GroupedRelation,
  EntitySchemaResourcePage,
  useRelatedEntities,
  Cardinality,
  EntityPropertyValue,
} from "src/entities/queries";
import { RelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { RelationshipPopoverContent } from "src/eventsCatalogue/SchemaEditor/RelationshipPopoverContent";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntityViewParams } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type SetSelectedIdFn = React.Dispatch<React.SetStateAction<string | null>>;

type TableActions = {
  getSelectedId: () => string | null;
  setSelectedId: SetSelectedIdFn;
};

const Title: React.FC<{
  property: string;
  schema: EntitySchemaResource;
  relatedSchema: EntitySchemaResource;
}> = ({ property, schema, relatedSchema }) => {
  const propertyDef = schema.properties?.[property];
  const cardinality = propertyDef?._cardinality ?? Cardinality.ONE_TO_MANY;
  const { setTrue, setFalse, value: isOpen } = useBoolean(false);

  const button = (
    <div className="flex cursor-default items-center gap-x-2">
      <Pill size="sm" variant="gray">
        <Pill.Icon color="text-gray-400" icon={getSchemaIcon(schema)} />
        <Pill.Text>
          <span className="capitalize">{schema._display_name_singular}</span>
        </Pill.Text>
      </Pill>
      <h3 className="font-inter-semibold-13px">{propertyDef._display_name}</h3>
      <RelationIcon cardinality={cardinality} size="xs" />
      <Pill size="sm" variant="gray">
        <Pill.Icon color="text-gray-400" icon={getSchemaIcon(relatedSchema)} />
        <Pill.Text>
          <span className="capitalize">
            {cardinality === Cardinality.ONE
              ? relatedSchema._display_name_singular
              : relatedSchema._display_name_plural}
          </span>
        </Pill.Text>
      </Pill>
    </div>
  );

  return (
    <CustomPopover
      button={button}
      isOpen={isOpen}
      onMouseEnter={setTrue}
      onMouseLeave={setFalse}
    >
      <RelationshipPopoverContent
        cardinality={cardinality}
        pluralDisplayName={schema._display_name_plural}
        propertyDisplayName={propertyDef._display_name}
        relatedSchemaId={relatedSchema._id}
        schemaId={schema._id}
        singularDisplayName={schema._display_name_singular}
      />
    </CustomPopover>
  );
};

const EntityTypeIcon: React.FC<{
  schema: EntitySchemaResource;
  propertyKey: string;
  property: EntitySchemaProperty;
}> = ({ schema, propertyKey, property }) => {
  if (property._type === "relation") {
    return (
      <div className="mr-0.5 shrink-0">
        <RelationIcon cardinality={property._cardinality} size="xs" />
      </div>
    );
  }
  const icon =
    schema._primary_property === propertyKey
      ? faArrowUpRight
      : SCHEMA_TYPE_ICONS[property._type as keyof typeof SCHEMA_TYPE_ICONS];
  return <Icon color="text-gray-500" icon={icon} size="2xs" />;
};

const ValueCell: React.FC<{
  schema: EntitySchemaResource;
  property: EntitySchemaProperty;
  propertyKey: string;
  info: CellContext<EntityResource, unknown>;
}> = ({ info, schema, property, propertyKey }) => {
  const isPrimary = schema._primary_property === propertyKey;

  if (isPrimary) {
    const row = info.row.original;

    return (
      <Cell info={info}>
        <EntityDetailsWindowPill
          entityIcon={getSchemaIcon(schema)}
          id={row._id}
          name={info.getValue() as string}
          schemaId={schema._id}
        />
      </Cell>
    );
  }

  return (
    <Cell info={info}>
      {renderValue({
        property,
        propertyValue: info.getValue() as EntityPropertyValue,
        isWindowPill: false,
        foreginKeyToPrimaryProperty: info.row.original._fk_pp,
      })}
    </Cell>
  );
};

const buildColumns = (
  entitySchema: EntitySchemaResource,
  relatedSchema: EntitySchemaResource,
) => {
  const helper = createColumnHelper<EntityResource>();
  const orderedFields = getOrderedProperties(entitySchema).filter(
    (propertyKey) => propertyKey !== "id",
  );
  const userColumns = orderedFields.map((propertyKey) => {
    const propertyDef = entitySchema.properties[propertyKey];
    return helper.accessor(`properties.${propertyKey}`, {
      meta: {
        displayName: propertyDef._display_name,
      },
      header: (info) => (
        <Header info={info}>
          <div className="flex items-center gap-x-0.5">
            <EntityTypeIcon
              property={propertyDef}
              propertyKey={propertyKey}
              schema={relatedSchema}
            />
            <p className="whitespace-nowrap">{propertyDef._display_name}</p>
          </div>
        </Header>
      ),
      cell: (info) => (
        <ValueCell
          info={info}
          property={propertyDef}
          propertyKey={propertyKey}
          schema={relatedSchema}
        />
      ),
    });
  });

  const idColumn = (() => {
    if (!("id" in relatedSchema.properties)) {
      return false;
    }

    const idProperty = relatedSchema.properties.id;

    return helper.accessor("properties.id", {
      meta: {
        displayName: idProperty._display_name,
      },
      header: (info) => (
        <Header info={info}>
          <div className="flex items-center gap-x-0.5">
            <Icon
              color="text-gray-500"
              icon={SCHEMA_TYPE_ICONS["string"]}
              size="2xs"
            />
            <p className="whitespace-nowrap">{idProperty._display_name}</p>
          </div>
        </Header>
      ),
      size: 100,
      cell: (info) => {
        const actions = info.table.options.meta?.actions as TableActions;
        const [userId, systemId] = [
          info.getValue() as string,
          info.row.original._id,
        ];
        return (
          <Cell className="w-24" info={info}>
            <TruncatedIdPill id={userId} />
            <EntityDetailsFloatingWindow
              button={<></>}
              entityIcon={getSchemaIcon(entitySchema)}
              id={systemId}
              isOpen={actions.getSelectedId() === systemId}
              name={userId}
              schemaId={entitySchema._id}
              onClose={() => actions.setSelectedId(null)}
            />
          </Cell>
        );
      },
    });
  })();

  const detailsColumn = helper.display({
    id: "properties.options",
    header: (info) => (
      <div className="flex justify-end pr-1.5">
        <ColumnVisibilityDropdown min={3} table={info.table} />
      </div>
    ),
    size: 54,
    cell: () => null,
  });

  return [idColumn, ...userColumns, detailsColumn].filter(
    Boolean,
  ) as DisplayColumnDef<EntityResource, unknown>[];
};

const EntityAccordionItem: React.FC<{
  schema: EntitySchemaResource;
  relatedSchema: EntitySchemaResource;
  entitySchema: EntitySchemaResource;
  property: string;
  entities: EntityResource[];
  isOpen: boolean;
  value: string;
}> = ({
  value,
  entities,
  isOpen,
  property,
  schema,
  entitySchema,
  relatedSchema,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const columns = useMemo(
    () => buildColumns(entitySchema, relatedSchema),
    [relatedSchema, entitySchema],
  );

  const rowPropsGetter = useCallback((row: Row<EntityResource>) => {
    const actions = row.getAllCells()[0].getContext().table.options.meta
      ?.actions as TableActions;
    return {
      onClick: () => {
        actions.setSelectedId((prev) =>
          prev === row.original._id ? null : row.original._id,
        );
      },
      classNameOverrides: twJoin(
        row.original._id === actions.getSelectedId()
          ? "bg-gray-50"
          : "hover:bg-gray-50",
        "cursor-pointer",
      ),
    };
  }, []);

  const tableActions = useMemo(
    () => ({
      setSelectedId,
      getSelectedId: () => selectedId,
    }),
    [selectedId],
  );

  return (
    <EditorAccordionItem
      className="decideScrollbar overflow-hidden overflow-x-auto p-4"
      contentWrapperClassName="bg-white border-b border-x border-gray-200 rounded-b-lg overflow-hidden"
      dataLoc="related-entities"
      headerClassName={twJoin(
        "rounded-lg border border-gray-200 bg-white transition-all",
        isOpen && "rounded-b-none",
      )}
      title={
        <Title
          property={property}
          relatedSchema={relatedSchema}
          schema={schema}
        />
      }
      value={value}
    >
      <TableComp
        actions={tableActions}
        columns={columns}
        data={entities}
        dataLoc="related-entities-table"
        rowClassName="group"
        rowPropsGetter={rowPropsGetter}
        variant="compact"
      />
    </EditorAccordionItem>
  );
};

const addSchemasToRelations = (
  relations: GroupedRelation[] | undefined,
  schemas: EntitySchemaResourcePage,
) => {
  if (!relations) {
    return [];
  }

  return relations.map((relation) => {
    const schema = findSchema(relation.schemaId, schemas);
    const relatedSchemaId = schema?.properties[relation.property]?._rel_schema;
    const relatedSchema = findSchema(relatedSchemaId ?? "", schemas);
    const entitySchema = findSchema(relation.entitySchemaId, schemas);
    return { ...relation, schema, relatedSchema, entitySchema };
  });
};

export const RelatedEntities = () => {
  const { schema, id: entityId } = useParamsDecode<EntityViewParams>();
  const { workspace } = useWorkspaceContext();
  const [env] = useEnvironment();
  const [accordionValue, setAccordionValue] = useState<string[]>(["0"]);

  const {
    data: relatedEntitiesData,
    isLoading: isLoadingRelatedEntities,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useRelatedEntities({
    schema,
    env,
    baseUrl: workspace.base_url ?? "",
    entityId,
  });

  const { data: schemas } = useEntitySchemas({
    baseUrl: workspace.base_url ?? "",
    options: { enabled: !!workspace.base_url },
  });

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  const relatedEntities = useMemo(
    () =>
      addSchemasToRelations(
        relatedEntitiesData?.pages.flatMap((page) => page.relations),
        schemas as EntitySchemaResourcePage,
      ),
    [relatedEntitiesData, schemas],
  );

  if (isLoadingRelatedEntities) {
    return (
      <div className="p-5">
        <AccordionSkeleton />
      </div>
    );
  }

  return (
    <div className="p-5">
      <AccordionRoot
        className="flex flex-col gap-y-3 pb-6"
        type="multiple"
        value={accordionValue}
        onValueChange={setAccordionValue}
      >
        {relatedEntities.map(
          (
            { schema, entities, property, relatedSchema, entitySchema },
            index,
          ) => (
            <EntityAccordionItem
              key={property}
              entities={entities}
              entitySchema={entitySchema!}
              isOpen={accordionValue.includes(String(index))}
              property={property}
              relatedSchema={relatedSchema!}
              schema={schema!}
              value={String(index)}
            />
          ),
        )}
      </AccordionRoot>
    </div>
  );
};
