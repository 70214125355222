import { isString, take } from "lodash";

import { FeatureResponse } from "src/clients/features-control";
import { findSchema } from "src/entities/entityView/utils";
import {
  Cardinality,
  EntitySchemaProperty,
  EntitySchemaResource,
} from "src/entities/queries";
import { WorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { assertUnreachable } from "src/utils/typeUtils";

type TemplatePropertyTypes =
  | "string"
  | "number"
  | "integer"
  | "boolean"
  | "date"
  | "datetime"
  | "enum"
  | "tags"
  | "list"
  | "array"
  | "object";

type TemplatePropertyValue =
  | string
  | string[]
  | number
  | true
  | Array<never>
  | Record<string, never>
  | null;

const getPropertyValue = (
  type: TemplatePropertyTypes,
  enumValues?: string[],
): TemplatePropertyValue => {
  switch (type) {
    case "string":
      return "";
    case "number":
    case "integer":
      return 1;
    case "boolean":
      return true;
    case "date":
      return getRandomDate().split("T")[0];
    case "datetime":
      return getRandomDate();
    case "enum":
      return enumValues?.[0] ?? null;
    case "tags":
      return take(enumValues, 2);
    case "list":
    case "array":
      return [];
    case "object":
      return {};
    default:
      assertUnreachable(type);
      return null;
  }
};

const getRandomDate = () =>
  new Date(
    // This is safe pseudo random
    // eslint-disable-next-line sonarjs/pseudo-random
    Date.now() - Math.random() * 10 * 365 * 24 * 60 * 60 * 1000,
  ).toISOString();

type BaseTemplateValue<T = unknown> =
  | TemplatePropertyValue
  | FileTemplate
  | UrlTemplate
  | LatLongTemplate
  | IpAddressTemplate
  | AddressTemplate
  | `${string}_id`
  | SubEntityTemplate<T>;

type SubEntityTemplate<T> = Record<string, T>;

export const getEntityTemplate = (
  schema: ExpandedEntitySchemaResource | SchemaStringRepresentation,
  features: FeatureResponse[],
  featureGates: WorkspaceFeatureGates,
): BaseTemplateValue => {
  if (isString(schema)) return schema;

  const template = Object.entries(schema.properties).reduce(
    (acc, [key, property]) => {
      switch (property._type) {
        case "string":
        case "number":
        case "integer":
        case "boolean":
        case "date":
        case "datetime":
        case "array":
        case "object":
          acc[key] = getPropertyValue(property._type);
          break;
        case "enum":
        case "tags":
          acc[key] = getPropertyValue(
            property._type,
            property._values?.map((value) => value._internal_name),
          );
          break;
        case "relation":
          if (property._cardinality === Cardinality.ONE && property._schema) {
            acc[key] = getEntityTemplate(
              property._schema,
              features,
              featureGates,
            );
          } else if (property._cardinality === Cardinality.ONE_TO_MANY) {
            acc[key] = [`${property._rel_schema}_id`];
          } else {
            // fallback for unexpected case
            acc[key] = `${property._rel_schema}_id`;
          }
          break;
        case "file":
          acc[key] = getFileTemplate();
          break;
        case "url":
          acc[key] = getUrlTemplate();
          break;
        case "lat_long":
          acc[key] = getLatLongTemplate();
          break;
        case "ip_address":
          acc[key] = getIpAddressTemplate();
          break;
        case "address":
          acc[key] = getAddressTemplate();
          break;
        default:
          acc[key] = property._display_name;
          break;
      }

      return acc;
    },
    {} as Record<string, BaseTemplateValue>,
  );

  if (featureGates.featuresEventsEnabled) {
    template.features = features.reduce(
      (acc, feature) => {
        if (feature.entity_types?.includes(schema._id)) {
          acc[feature.key] = getPropertyValue(
            feature.feature_type as TemplatePropertyTypes,
          );
        }
        return acc;
      },
      {} as Record<string, BaseTemplateValue>,
    );
  }

  return template;
};

type SchemaStringRepresentation = `${string}_id`;

type ExpandedEntitySchemaResource = Omit<EntitySchemaResource, "properties"> & {
  properties: {
    [key: string]: ExpandedEntitySchemaProperty;
  };
};

type ExpandedEntitySchemaProperty = EntitySchemaProperty & {
  _schema?: ExpandedEntitySchemaResource | SchemaStringRepresentation;
};

export const expandEntitySchema = (
  schema: EntitySchemaResource,
  schemasData: EntitySchemaResource[],
  depth = 5,
): ExpandedEntitySchemaResource | SchemaStringRepresentation => {
  if (depth === 0) return `${schema._id}_id`;

  const rootSchema = { ...schema };

  rootSchema.properties = Object.entries(schema.properties).reduce(
    (acc, [key, property]) => {
      acc[key] =
        property._type === "relation" &&
        property._cardinality === Cardinality.ONE &&
        property._rel_schema
          ? {
              ...property,
              _schema: expandEntitySchema(
                findSchema(property._rel_schema, {
                  entities: schemasData,
                  next_page_token: "",
                  previous_page_token: "",
                })!,
                schemasData,
                depth - 1,
              ),
            }
          : property;

      return acc;
    },
    {} as Record<string, ExpandedEntitySchemaProperty>,
  );

  return rootSchema;
};

type FileTemplate = {
  document_url: string;
  name: string;
  uploaded_at: string;
};

// Special types Templates
const getFileTemplate = (): FileTemplate => ({
  document_url: "",
  name: "",
  uploaded_at: getRandomDate(),
});

type UrlTemplate = {
  url: string;
  name: string;
};

const getUrlTemplate = (): UrlTemplate => ({
  url: "",
  name: "",
});

type LatLongTemplate = {
  lat: number;
  long: number;
};

const getLatLongTemplate = (): LatLongTemplate => ({
  lat: 0,
  long: 0,
});

type IpAddressTemplate = {
  ipv4: string;
  ipv6: string;
};

const getIpAddressTemplate = (): IpAddressTemplate => ({
  ipv4: "",
  ipv6: "",
});

type AddressTemplate = {
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  region: string;
  zipcode: string;
  phone: string;
};

const getAddressTemplate = (): AddressTemplate => ({
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  region: "",
  zipcode: "",
  phone: "",
});
