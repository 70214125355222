import {
  faCode,
  faCopy,
  faEdit,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { ManageFeatureModal } from "./ManageFeatureModal";
import { Button } from "src/base-components/Button";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Modal, useModal } from "src/design-system/Modal";
import { Feature, formatAthenaSqlQuery } from "src/featureCatalogue/utils";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToQueryPreviewPage } from "src/router/urls";
import { useIsFWDOrAdmin } from "src/store/AuthStore";

interface FeatureActionsMenuProps {
  feature: Feature;
}

export const FeatureActionsMenu: React.FC<FeatureActionsMenuProps> = ({
  feature,
}) => {
  const { featureCatalogue } = useCapabilities();
  const { workspace } = useWorkspaceContext();
  const isFWDOrAdmin = useIsFWDOrAdmin();
  const {
    isOpen: isViewQueryModalOpen,
    openModal: openViewQueryModal,
    closeModal: closeViewQueryModal,
    afterLeave: afterViewQueryLeave,
  } = useModal();

  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
    afterLeave: afterEditLeave,
  } = useModal();

  const {
    isOpen: isDuplicateModalOpen,
    openModal: openDuplicateModal,
    closeModal: closeDuplicateModal,
    afterLeave: afterDuplicateLeave,
  } = useModal();

  return (
    <>
      <EllipsisOptionsDropdown
        buttonClassName="text-gray-500 ml-1 group-hover/row:opacity-100"
        buttonDataLoc="feature-actions-menu-button"
        elements={[
          {
            key: "View Query",
            icon: faCode,
            action: openViewQueryModal,
            disabled: !feature.query,
          },
          ...(featureCatalogue.canEdit
            ? [
                {
                  key: "Edit feature",
                  icon: faEdit,
                  action: openEditModal,
                },
              ]
            : []),
          ...(featureCatalogue.canDuplicate
            ? [
                {
                  key: "Duplicate",
                  icon: faCopy,
                  action: openDuplicateModal,
                },
              ]
            : []),
        ]}
        iconSize="xs"
        placement="bottom-end"
        timeoutDuration={200}
      />
      <Modal
        afterLeave={afterViewQueryLeave}
        open={isViewQueryModalOpen}
        size="lg"
        onClose={closeViewQueryModal}
      >
        <Modal.Header>Feature Query</Modal.Header>
        <Modal.Content>
          <div className="h-134">
            <CodeEditor
              language="sql"
              value={formatAthenaSqlQuery(feature.query?.query ?? "")}
              readOnly
            />
          </div>
        </Modal.Content>
        <Modal.Footer primaryButton="Done" secondaryButton="Cancel">
          {isFWDOrAdmin && (
            <Button
              dataLoc="feature-modal-view-query-button"
              variant="secondary"
              onClick={() => {
                const url =
                  getBaseUrl() +
                  getUrlToQueryPreviewPage(
                    workspace.organization_id,
                    workspace.id,
                    feature.query?.id,
                  );
                window.open(url, "_blank");
              }}
            >
              <span className="flex items-center gap-x-0.5">
                View Query
                <Icon color="text-gray-600" icon={faExternalLink} size="3xs" />
              </span>
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ManageFeatureModal
        afterLeave={afterEditLeave}
        feature={{
          name: feature.name ?? "",
          key: feature.key ?? "",
          description: feature.description ?? "",
          etag: feature.etag ?? "",
          queryId: feature.query?.id ?? "",
        }}
        isOpen={isEditModalOpen}
        mode="edit"
        onClose={closeEditModal}
      />

      <ManageFeatureModal
        afterLeave={afterDuplicateLeave}
        feature={{
          name: feature.name ?? "",
          key: feature.key,
          description: feature.description || "",
        }}
        isOpen={isDuplicateModalOpen}
        mode="duplicate"
        onClose={closeDuplicateModal}
      />
    </>
  );
};
