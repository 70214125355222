import {
  faBracketsCurly,
  faBracketsSquare,
  faAsterisk,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash-es";

import { Pill } from "src/base-components/Pill";

const LARGE_CELL_VALUE_LENGTH = 1000;

export const JSONPill: React.FC<{
  value: string;
  type: string;
}> = ({ value, type }) => {
  if (value.length === 0) {
    return <div />;
  }

  if (value.length > LARGE_CELL_VALUE_LENGTH) {
    return (
      <div className="min-w-0 flex-auto">
        <Pill size="sm" variant="gray" maxWidth>
          <Pill.Icon
            icon={
              type === "array"
                ? faBracketsSquare
                : type === "object"
                  ? faBracketsCurly
                  : faAsterisk
            }
          />
          <Pill.Text>{capitalize(type)}</Pill.Text>
        </Pill>
      </div>
    );
  }

  return (
    <div className="min-w-0 flex-auto">
      <Pill size="sm" variant="gray" maxWidth>
        <Pill.Text>{value.slice(0, 75)}</Pill.Text>
      </Pill>
    </div>
  );
};
