import { v4 as uuidV4 } from "uuid";

import { SchufaResourceT } from "src/api/connectApi/types";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingListT,
  InputMappingT,
  IntegrationResourceT,
} from "src/integrationNode/types";

const B2C_ADDRESS_TYPES = [
  { key: `"PRIMARY"`, value: "primary" },
  { key: `"PREVIOUS"`, value: "previous" },
];

const FURNISH_TYPES = [
  {
    key: `"NEW_ADDRESS"`,
    value: "New address",
  },
  {
    key: `"CORRECTION_ADDRESS"`,
    value: "Correction address",
  },
  {
    key: `"NEW_SURNAME"`,
    value: "New surname",
  },
  {
    key: `"CORRECTION_CONSUMER_DATA"`,
    value: "Correction consumer data",
  },
  {
    key: `"NEW_DEATH"`,
    value: "New death",
  },
  {
    key: `"DELETION_DEATH"`,
    value: "Deletion death",
  },
  {
    key: `"CREDIT_REPORT_DATA"`,
    value: "Credit report data",
  },
];

const FEATURE_CODES = [
  { key: `"BP"`, value: "(BP) Surety for land charge loan" },
  { key: `"BS"`, value: "(BS) Payment account with basic features" },
  { key: `"BU"`, value: "(BU) Surety for unsecured loan/leasing" },
  { key: `"BX"`, value: "(BX) Surety for unsecured bullet maturity loan" },
  { key: `"BY"`, value: "(BY) Surety for land charge loan without amount" },
  { key: `"CC"`, value: "(CC) Credit card account" },
  { key: `"CR"`, value: "(CR) Credit card with revolving credit" },
  { key: `"GI"`, value: "(GI) Current account" },
  { key: `"GP"`, value: "(GP) Current account exempt from wage attachment" },
  { key: `"HF"`, value: "(HF) Forward loan" },
  { key: `"HN"`, value: "(HN) Saver's building loan with obligation" },
  { key: `"HP"`, value: "(HP) Land charge loan" },
  { key: `"HY"`, value: "(HY) Land charge loan without amount" },
  { key: `"K1"`, value: "(K1) Credit line for freelancers" },
  { key: `"K2"`, value: "(K2) Credit line for small business/self-employed" },
  { key: `"K3"`, value: "(K3) Investment loans/leasing for freelancers" },
  { key: `"K4"`, value: "(K4) Investment loans/leasing for small business" },
  { key: `"K7"`, value: "(K7) Absolute business guarantees" },
  { key: `"KG"`, value: "(KG) Non-installment loans/current accounts" },
  { key: `"KR"`, value: "(KR) Unsecured building society/installment loan" },
  { key: `"KW"`, value: "(KW) Account being processed" },
  { key: `"KX"`, value: "(KX) Unsecured bullet maturity loan" },
  { key: `"M1"`, value: "(M1) Bus. curr. account for freelancers" },
  {
    key: `"M2"`,
    value: "(M2) Bus. curr. account for small business/self-employed",
  },
  { key: `"M3"`, value: "(M3) Bus. leasing/hire purchase for freelancers" },
  {
    key: `"M4"`,
    value: "(M4) Bus. leasing/hire purchase for small business/self-employed",
  },
  { key: `"M5"`, value: "(M5) Bus. credit card for freelancers" },
  {
    key: `"M6"`,
    value: "(M6) Bus. credit card  for small business/self-employed",
  },
  {
    key: `"M7"`,
    value: "(M7) Bus. Invest. credit land charge for freelancers",
  },
  {
    key: `"M8"`,
    value:
      "(M8) Bus. Invest. credit land charge for small business/self-employed",
  },
  { key: `"MA"`, value: "(MA) Co-applicant for unsecured installment loan" },
  { key: `"ML"`, value: "(ML) Movable equipment leasing/hire purchase" },
  { key: `"MX"`, value: "(MX) Co-applicant for unsecured bullet loan" },
  { key: `"MY"`, value: "(MY) Co-applicant for land charge loan" },
  { key: `"RI"`, value: "(RI) Claim returned from collection agency" },
  { key: `"RK"`, value: "(RK) Master loan agreement" },
  { key: `"SD"`, value: "(SD) Balance from remaining payment/claim" },
  { key: `"SE"`, value: "(SE) Balance after court decision" },
  { key: `"SG"`, value: "(SG) Balance after full payment demand" },
  { key: `"SV"`, value: "(SV) Balance comparison to avert insolvency" },
  { key: `"SZ"`, value: "(SZ) Balance sold via debt assignment" },
  { key: `"UF"`, value: "(UF) Irrecoverable enforceable claim" },
  { key: `"UI"`, value: "(UI) Claim sent to collection" },
  { key: `"US"`, value: "(US) Unrecoverable balance without title" },
  { key: `"WS"`, value: "(WS) Objection to reported balance" },
];

const REPORTING_TYPES = [
  { key: `"neumeldung"`, value: "New report (neumeldung)" },
  { key: `"aenderungsmeldung"`, value: "Change report (aenderungsmeldung)" },
  { key: `"loeschungsmeldung"`, value: "Delete report (loeschungsmeldung)" },
  {
    key: `"erledigungsmeldung"`,
    value: "Settlement report (erledigungsmeldung)",
  },
  { key: `"abwicklungsmeldung"`, value: "Wind up report (abwicklungsmeldung)" },
  {
    key: `"aufstockungsmeldung"`,
    value: "Replenishment report (aufstockungsmeldung)",
  },
  { key: `"widerrufsmeldung"`, value: "Revocation report (widerrufsmeldung)" },
];

const B2B_ADDRESS_TYPES = [
  {
    key: `"BUSINESS"`,
    value: "business",
  },
  {
    key: `"MAILING"`,
    value: "mailing",
  },
  {
    key: `"REGISTRATION"`,
    value: "registration",
  },
  {
    key: `"PLANT"`,
    value: "plant",
  },
  {
    key: `"BRANCH"`,
    value: "branch",
  },
  {
    key: `"PRIVATE"`,
    value: "private",
  },
  {
    key: `"PO"`,
    value: "po",
  },
];

const getDefaultSchufaAddress = (
  type: SchufaResourceT,
): { [key: string]: InputMappingT } => ({
  address_type: {
    id: uuidV4(),
    type: "dropDown",
    displayName: "Type",
    elements:
      type === "b2b_report_cascading" ? B2B_ADDRESS_TYPES : B2C_ADDRESS_TYPES,
    assignedTo: type === "b2b_report_cascading" ? `"BUSINESS"` : `"PRIMARY"`,
    rules: { required: true },
  },
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: { required: true },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: { required: true },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: true },
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country code",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
  },
});

const getDefaultSchufaAddressList = (
  type: SchufaResourceT,
  withInitialElement: boolean,
  required: boolean = false,
): InputMappingListT => ({
  displayName: "address",
  getDefaultElement: () => getDefaultSchufaAddress(type),
  elements: withInitialElement ? [getDefaultSchufaAddress(type)] : [],
  rules: { required: required },
});

const getDefaultSchufaRepresentative = (): {
  [key: string]: InputMappingT;
} => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: { required: true },
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: { required: true },
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
  },
  gender: {
    id: uuidV4(),
    type: "text",
    displayName: "Gender",
    assignedTo: "",
    hint: "Options are: “MALE”, “FEMALE”, “UNKNOWN”",
  },
});

const getDefaultSchufaRepresentativeList = (): InputMappingListT => ({
  displayName: "representative",
  getDefaultElement: getDefaultSchufaRepresentative,
  elements: [],
});

export const getDefaultSchufaB2BReport = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "b2b_report_cascading" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      schufa_company_id: {
        id: uuidV4(),
        type: "text",
        displayName: "SCHUFA company ID",
        assignedTo: "",
        hint: "SCHUFA assigns each company in its database a unique ID. This ID is returned alongside any business report and can be used to accurately and easily fetch information about that company in the future.",
      },
      company_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Company name",
        assignedTo: "",
      },
      email: {
        id: uuidV4(),
        type: "text",
        displayName: "Email",
        assignedTo: "",
      },
      website_url: {
        id: uuidV4(),
        type: "text",
        displayName: "Website URL",
        assignedTo: "",
      },
      vat_id: {
        id: uuidV4(),
        type: "text",
        displayName: "VAT ID",
        assignedTo: "",
      },
      tax_id: {
        id: uuidV4(),
        type: "text",
        displayName: "Tax ID",
        assignedTo: "",
      },
    },
    grouped: {
      phone: {
        getDefaultElements: () => ({
          number: {
            id: uuidV4(),
            type: "text",
            displayName: "Number",
            assignedTo: "",
            rules: { required: true },
            hint: `Phone number without country and dialling code, e.g., "0612345678".`,
          },
          country: {
            id: uuidV4(),
            type: "text",
            displayName: "Country",
            assignedTo: "",
            hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
          },
          dialling_code: {
            id: uuidV4(),
            type: "text",
            displayName: "Dialling Code",
            assignedTo: "",
            hint: `A prefix to the phone number that connects to a geographical or logical location (in German: "Vorwahl"), e.g., "030".`,
          },
        }),
      },
    },
    lists: {
      addresses: getDefaultSchufaAddressList("b2b_report_cascading", false),
      representatives: getDefaultSchufaRepresentativeList(),
    },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      schufa_b2b_score: {
        ...getNamedOutputMapping("SCHUFA B2B Score"),
        hint: `The score (called “Bonitätsindex”) ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year.`,
      },
      schufa_b2b_range: {
        ...getNamedOutputMapping("SCHUFA B2B Range"),
        hint: `A string that assigns a broader range of the SCHUFA B2B Score (e.g., “100-249”). We recommend using the B2B Score for a more granular assessment.`,
      },
      schufa_b2b_probability_of_default: {
        ...getNamedOutputMapping("SCHUFA B2B probability of default"),
        hint: `A decimal metric expressing the probability that a company will default in the next twelve months (e.g., 0.2).`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSchufaB2CReport = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "b2c_report" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: { required: true },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: { required: true },
      },
      title: {
        id: uuidV4(),
        type: "text",
        displayName: "Title",
        assignedTo: "",
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        rules: { required: true },
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
      },
      place_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Place of birth",
        assignedTo: "",
        hint: `The city in which the individual was born`,
      },
      gender: {
        id: uuidV4(),
        type: "text",
        displayName: "Gender",
        assignedTo: "",
        rules: { required: true },
        hint: `Options are: “MALE”, “FEMALE”, “UNKNOWN”`,
      },
    },
    grouped: {},
    lists: { addresses: getDefaultSchufaAddressList("b2c_report", true) },
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      schufa_score: {
        ...getNamedOutputMapping("SCHUFA Score"),
        hint: `The primary SCHUFA Score that is part of the B2C report according to your agreement with SCHUFA. By default, this is the "SCHUFA Score for Banks", but you can also ask SCHUFA to return the "SCHUFA Score for Mortgage Banking" (also called "HypoScore") instead of or in addition to the bank score. If SCHUFA enabled both scores for you, you can access the HypoScore via this insight and the bank score via the "Alternative SCHUFA Score" insight. See our docs to learn how to interpret the different scores.`,
      },
      schufa_grade: {
        ...getNamedOutputMapping("SCHUFA Grade"),
        hint: `The SCHUFA Grade ranges from A to P (from better to worse). It directly corresponds to the SCHUFA Score value. See our docs for more information.`,
      },
      alternative_schufa_score: {
        ...getNamedOutputMapping("Alternative SCHUFA Score"),
        hint: `See the "SCHUFA Score" insight help text`,
      },
      solvency_index: {
        ...getNamedOutputMapping("Solvency index"),
        hint: `Ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year.`,
      },
      retrieval_reference: {
        ...getNamedOutputMapping("Retrieval reference"),
        hint: `The retrieval reference ID returned in case SCHUFA indicates that the requested report needs to be manually retrieved.\nThis is not required, but recommended by SCHUFA.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSchufaB2CBasicAccountAndExemptionFromExecutionAccountReport =
  (): IntegrationResourceT => ({
    providerResource: {
      provider: "schufa",
      resource:
        "schufa_b2c_basic_account_and_exemption_from_execution_account_report",
    },
    connectionId: "",
    resourceConfigId: "",
    input: {
      ungrouped: {
        schufa_id: {
          id: uuidV4(),
          type: "text",
          displayName: "Schufa ID",
          assignedTo: "",
          hint: `Unique identifier for private individuals`,
        },
        first_name: {
          id: uuidV4(),
          type: "text",
          displayName: "First name",
          assignedTo: "",
          rules: { required: true },
        },
        last_name: {
          id: uuidV4(),
          type: "text",
          displayName: "Last name",
          assignedTo: "",
          rules: { required: true },
        },
        title: {
          id: uuidV4(),
          type: "text",
          displayName: "Title",
          assignedTo: "",
        },
        date_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Date of birth",
          assignedTo: "",
          rules: { required: true },
          hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
        },
        place_of_birth: {
          id: uuidV4(),
          type: "text",
          displayName: "Place of birth",
          assignedTo: "",
          hint: `The city in which the individual was born`,
        },
        gender: {
          id: uuidV4(),
          type: "text",
          displayName: "Gender",
          assignedTo: "",
          rules: { required: true },
          hint: `Options are: “MALE”, “FEMALE”, “UNKNOWN”`,
        },
        schufa_clause_date: {
          id: uuidV4(),
          type: "text",
          displayName: "Schufa clause date",
          assignedTo: "",
          hint: `The date of signing of the Schufa clause. Must adhere to the ISO format "YYYY-MM-DD", e.g., "2000-12-20".`,
        },
      },
      grouped: {},
      lists: {
        addresses: getDefaultSchufaAddressList(
          "schufa_b2c_basic_account_and_exemption_from_execution_account_report",
          true,
        ),
      },
    },
    output: {
      default: getDefaultOutputMapping(),
      insights: {
        ...getCommonOutputMappings(
          CommonOutputMappingKey.PROVIDER_REQUEST_ID,
          CommonOutputMappingKey.PROVIDER_ENTITY_ID,
          CommonOutputMappingKey.RETRIEVAL_DATETIME,
        ),
        consumer_found: {
          ...getNamedOutputMapping("Consumer found"),
          hint: `Indicates whether Schufa's database contains information on the requested consumer`,
        },
        account_found: {
          ...getNamedOutputMapping("Account found"),
          hint: `Indicates whether a basic account/exemption from execution account exists for the requested consumer`,
        },
        retrieval_reference: {
          ...getNamedOutputMapping("Retrieval reference"),
          hint: `The retrieval reference ID returned in case SCHUFA indicates that the requested report needs to be manually retrieved`,
        },
      },
    },
    config: defaultResourceConfig,
  });

export const getDefaultSchufaB2CManualRetrieval = (): IntegrationResourceT => ({
  providerResource: {
    provider: "schufa",
    resource: "schufa_b2c_manual_retrieval",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      retrieval_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Retrieval reference",
        assignedTo: "",
        hint: `Retrieval reference ID identifying the report that SCHUFA indicated needed to be manually retrieved`,
      },
    },
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      schufa_score: {
        ...getNamedOutputMapping("SCHUFA Score"),
        hint: `The primary SCHUFA Score according to your agreement with SCHUFA`,
      },
      schufa_grade: {
        ...getNamedOutputMapping("SCHUFA Grade"),
        hint: `The SCHUFA Grade ranges from A to P (from better to worse). It directly corresponds to the SCHUFA Score value. See our docs for more information.`,
      },
      alternative_schufa_score: {
        ...getNamedOutputMapping("Alternative SCHUFA Score"),
        hint: `See the "SCHUFA Score" insight help text`,
      },
      solvency_index: {
        ...getNamedOutputMapping("Solvency index"),
        hint: `Ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSchufaB2CMonitorReport = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "schufa_b2c_monitor" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {},
    grouped: {},
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      follow_up_report_available: {
        ...getNamedOutputMapping("Follow-up report available"),
        hint: `Indicates whether a follow-up report was available and retrieved`,
      },
      schufa_score: {
        ...getNamedOutputMapping("SCHUFA Score"),
        hint: `The primary SCHUFA Score according to your agreement with SCHUFA`,
      },
      schufa_grade: {
        ...getNamedOutputMapping("SCHUFA Grade"),
        hint: `The SCHUFA Grade ranges from A to P (from better to worse). It directly corresponds to the SCHUFA Score value. See our docs for more information.`,
      },
      solvency_index: {
        ...getNamedOutputMapping("Solvency index"),
        hint: `Ranges from 100 to 600, with 100 being the best possible score. 600 translates to 99.99% chance of default in the next year`,
      },
    },
  },
  config: defaultResourceConfig,
});

const getDefaultSchufaFraudPreCheckAddress = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: { required: true },
    hint: `Either provide the entire street name (e.g., "Beispielstrasse 123a") here or use the Street, Number, and Affix fields to split up the street name. The total length must not exceed 50 characters.`,
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
    hint: `Additional information about the house number, e.g., "a" if the house number is "123a"`,
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: { required: true },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: true },
  },
});

export const getDefaultSchufaFraudPreCheck = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "schufa_fraud_pre_check" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    ungrouped: {
      business_transaction_reference: {
        id: uuidV4(),
        type: "text",
        displayName: "Business transaction reference",
        assignedTo: "",
        rules: { required: true },
        hint: `Used to assign further FraudPreCheck requests to a single business transaction. This value can be chosen freely and will be returned in the response.`,
      },
      first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "First name",
        assignedTo: "",
        rules: { required: true },
      },
      last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "Last name",
        assignedTo: "",
        rules: { required: true },
      },
      date_of_birth: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of birth",
        assignedTo: "",
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
      },
    },
    grouped: {
      address: {
        getDefaultElements: () => getDefaultSchufaFraudPreCheckAddress(),
      },
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      fraud_pre_check_score: {
        ...getNamedOutputMapping("Fraud Pre-Check score"),
        hint: `A score between 0.0 and 1.0 used to assess the inquiry regarding potential fraudulence. A higher score indicates a higher level of suspicion about the inquired entity.`,
      },
    },
  },
  config: defaultResourceConfig,
});

const getDefaultSchufaB2CFurnishAddressElements = (): {
  [key: string]: InputMappingT;
} => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: { required: true },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "Number",
    assignedTo: "",
  },
  affix: {
    id: uuidV4(),
    type: "text",
    displayName: "Affix",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    rules: { required: true },
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    rules: { required: true },
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country code",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany)`,
  },
});

const getDefaultSchufaB2CFurnishBasicConsumerElements = (): {
  [key: string]: InputMappingT;
} => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
  },
  title: {
    id: uuidV4(),
    type: "text",
    displayName: "Title",
    assignedTo: "",
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
  },
  place_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Place of birth",
    assignedTo: "",
    hint: `The city in which the individual was born.`,
  },
  gender: {
    id: uuidV4(),
    type: "text",
    displayName: "Gender",
    assignedTo: "",
    hint: `Options are: “MALE”, “FEMALE”, “UNKNOWN”`,
  },
});

const getDefaultSchufaB2CFurnishConsumerElements = (): {
  [key: string]: InputMappingT;
} => ({
  first_name: {
    id: uuidV4(),
    type: "text",
    displayName: "First name",
    assignedTo: "",
    rules: { required: true },
  },
  last_name: {
    id: uuidV4(),
    type: "text",
    displayName: "Last name",
    assignedTo: "",
    rules: { required: true },
  },
  title: {
    id: uuidV4(),
    type: "text",
    displayName: "Title",
    assignedTo: "",
  },
  date_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Date of birth",
    assignedTo: "",
    rules: { required: true },
    hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
  },
  place_of_birth: {
    id: uuidV4(),
    type: "text",
    displayName: "Place of birth",
    assignedTo: "",
    hint: `The city in which the individual was born.`,
  },
  gender: {
    id: uuidV4(),
    type: "text",
    displayName: "Gender",
    assignedTo: "",
    rules: { required: true },
    hint: `Options are: “MALE”, “FEMALE”, “UNKNOWN”`,
  },
  schufa_id: {
    id: uuidV4(),
    type: "text",
    displayName: "Schufa ID",
    assignedTo: "",
    hint: `Unique identifier for private individuals, used by Schufa`,
  },
  schufa_clause_date: {
    id: uuidV4(),
    type: "text",
    displayName: "Schufa clause date",
    assignedTo: "",
    hint: `The date of signing of the Schufa clause. Must adhere to the ISO format "YYYY-MM-DD", e.g., "2000-12-20".`,
  },
});

export const getDefaultSchufaB2CFurnish = (): IntegrationResourceT => ({
  providerResource: { provider: "schufa", resource: "schufa_b2c_furnish" },
  connectionId: "",
  resourceConfigId: "",
  input: {
    grouped: {
      new_address: {
        getDefaultElements: getDefaultSchufaB2CFurnishAddressElements,
        displayName: "New address",
        elements: getDefaultSchufaB2CFurnishAddressElements(),
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"NEW_ADDRESS"`],
          },
        ],
      },
      corrected_address: {
        getDefaultElements: getDefaultSchufaB2CFurnishAddressElements,
        displayName: "Corrected address",
        elements: getDefaultSchufaB2CFurnishAddressElements(),
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CORRECTION_ADDRESS"`],
          },
        ],
      },
      corrected_consumer_data: {
        getDefaultElements: getDefaultSchufaB2CFurnishBasicConsumerElements,
        displayName: "Corrected consumer data",
        elements: getDefaultSchufaB2CFurnishBasicConsumerElements(),
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CORRECTION_CONSUMER_DATA"`],
          },
        ],
      },
      consumer_data: {
        getDefaultElements: getDefaultSchufaB2CFurnishConsumerElements,
        displayName: "Consumer data",
        elements: getDefaultSchufaB2CFurnishConsumerElements(),
        lists: {
          addresses: getDefaultSchufaAddressList("b2c_report", true, true),
        },
        rules: { required: true },
      },
      contact_info: {
        getDefaultElements: () => ({
          administrative_contact: {
            id: uuidV4(),
            type: "text",
            displayName: "Administrative contact",
            assignedTo: "",
          },
          department: {
            id: uuidV4(),
            type: "text",
            displayName: "Department",
            assignedTo: "",
          },
          phone_number: {
            id: uuidV4(),
            type: "text",
            displayName: "Phone number",
            assignedTo: "",
          },
          fax_number: {
            id: uuidV4(),
            type: "text",
            displayName: "Fax number",
            assignedTo: "",
          },
          email: {
            id: uuidV4(),
            type: "text",
            displayName: "Email",
            assignedTo: "",
          },
        }),
        displayName: "Contact info",
        rules: { required: false },
      },
    },
    ungrouped: {
      furnish_type: {
        id: uuidV4(),
        type: "dropDown",
        displayName: "Furnish type",
        elements: FURNISH_TYPES,
        assignedTo: "",
        rules: { required: true },
      },
      schufa_feature_code: {
        id: uuidV4(),
        type: "dropDown",
        displayName: "Feature code",
        elements: FEATURE_CODES,
        assignedTo: "",
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      reporting_type: {
        id: uuidV4(),
        type: "dropDown",
        displayName: "Reporting type",
        elements: REPORTING_TYPES,
        assignedTo: "",
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      account_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Account number",
        assignedTo: "",
        hint: `Consumer's account number`,
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      date: {
        id: uuidV4(),
        type: "text",
        displayName: "Date",
        assignedTo: "",
        hint: `Could refer to contract date, maturity date, settlement date, etc. depending on attribute code context. Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".\nOptional for feature codes: KG, RK, KX, MX, K1, K2, K7, BP, BX, HN, HP, GI, KW.\nRequired for the rest. `,
        rules: { required: false },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      amount: {
        id: uuidV4(),
        type: "text",
        displayName: "Amount",
        assignedTo: "",
        hint: `Amount of the matter reported in full euros`,
        rules: { required: true },
        renderConditions: [
          {
            key: "schufa_feature_code",
            value: [
              `"KR"`,
              `"MA"`,
              `"ML"`,
              `"K3"`,
              `"K4"`,
              `"BU"`,
              `"KG"`,
              `"RK"`,
              `"KX"`,
              `"MX"`,
              `"K1"`,
              `"K2"`,
              `"K7"`,
              `"BP"`,
              `"BX"`,
              `"HN"`,
              `"HP"`,
              `"HF"`,
              `"CR"`,
              `"SG"`,
              `"SD"`,
              `"WS"`,
              `"SE"`,
              `"US"`,
              `"UF"`,
              `"UI"`,
              `"RI"`,
              `"SZ"`,
            ],
          },
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      number_of_installments: {
        id: uuidV4(),
        type: "text",
        displayName: "Number of installments",
        assignedTo: "",
        hint: `Planned number of installments for contract settlement`,
        rules: { required: true },
        renderConditions: [
          {
            key: "schufa_feature_code",
            value: [`"KR"`, `"MA"`, `"ML"`, `"K3"`, `"K4"`, `"BU"`],
          },
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      type_of_installments: {
        id: uuidV4(),
        type: "dropDown",
        displayName: "Type of installments",
        assignedTo: "",
        hint: `Indicates if payments are monthly or annual`,
        elements: [
          { key: `"j"`, value: "Annual" },
          { key: `"m"`, value: "Monthly" },
        ],
        rules: { required: true },
        renderConditions: [
          {
            key: "schufa_feature_code",
            value: [`"KR"`, `"MA"`, `"ML"`, `"K3"`, `"K4"`, `"BU"`],
          },
          {
            key: "furnish_type",
            value: [`"CREDIT_REPORT_DATA"`],
          },
        ],
      },
      new_first_name: {
        id: uuidV4(),
        type: "text",
        displayName: "New first name",
        assignedTo: "",
        rules: { required: false },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"NEW_SURNAME"`],
          },
        ],
      },
      new_last_name: {
        id: uuidV4(),
        type: "text",
        displayName: "New surname",
        assignedTo: "",
        rules: { required: true },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"NEW_SURNAME"`],
          },
        ],
      },
      date_of_death: {
        id: uuidV4(),
        type: "text",
        displayName: "Date of death",
        assignedTo: "",
        rules: { required: false },
        hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20"`,
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"NEW_DEATH"`, `"DELETION_DEATH"`],
          },
        ],
      },
      death_certificate_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Death certificate number",
        assignedTo: "",
        rules: { required: false },
        renderConditions: [
          {
            key: "furnish_type",
            value: [`"NEW_DEATH"`, `"DELETION_DEATH"`],
          },
        ],
      },
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      furnish_response: {
        ...getNamedOutputMapping("Furnish response"),
        hint: `Contains the response from Schufa regarding the furnish request`,
      },
      furnish_successful: {
        ...getNamedOutputMapping("Furnish successful"),
        hint: `Indicates whether the furnish request was successful`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultSchufaB2CUpdateAddress = (): IntegrationResourceT => ({
  providerResource: {
    provider: "schufa",
    resource: "schufa_b2c_update_address",
  },
  connectionId: "",
  resourceConfigId: "",
  input: {
    grouped: {
      consumer_data: {
        getDefaultElements: getDefaultSchufaB2CFurnishConsumerElements,
        displayName: "Consumer data",
        elements: getDefaultSchufaB2CFurnishConsumerElements(),
        lists: {
          addresses: getDefaultSchufaAddressList(
            "schufa_b2c_update_address",
            true,
            true,
          ),
        },
        rules: { required: true },
      },
    },
    ungrouped: {
      order_type: {
        id: uuidV4(),
        type: "dropDown",
        displayName: "Order type",
        assignedTo: "",
        hint: `Defines whether the order to update the consumer's address is non-recurring or permanent`,
        rules: { required: true },
        elements: [
          {
            key: `"einmalig"`,
            value: "Non-recurring",
          },
          {
            key: `"dauerhaft"`,
            value: "Permanent",
          },
        ],
      },
      account_number: {
        id: uuidV4(),
        type: "text",
        displayName: "Account number",
        assignedTo: "",
        hint: `Consumer's account number. Required if order type 'Permanent' is selected.`,
        rules: { required: false },
      },
    },
    lists: {},
  },
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getCommonOutputMappings(
        CommonOutputMappingKey.PROVIDER_REQUEST_ID,
        CommonOutputMappingKey.PROVIDER_ENTITY_ID,
        CommonOutputMappingKey.RETRIEVAL_DATETIME,
      ),
      consumer_found: {
        ...getNamedOutputMapping("Consumer found"),
        hint: `Indicates whether Schufa's database contains information on the requested consumer`,
      },
      processing_information: {
        ...getNamedOutputMapping("Processing information"),
        hint: `Displays any additional processing information returned by SCHUFA`,
      },
    },
  },
  config: defaultResourceConfig,
});
