import { capitalize } from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { InputSchemaTypes, OutputSchemaTypes } from "src/api/flowTypes";
import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { EventConfigEnriched } from "src/clients/features-control";
import { getSchemaIcon } from "src/entities/entityView/utils";
import { EntitySchemaResource, useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { getEventIcon } from "src/eventsCatalogue/utils";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  entityNameToJsonRefPath,
  eventNameToJsonRefPath,
} from "src/schema/jsonRefUtils";
import {
  SchemaTypeSelectorProps,
  SchemaUIT,
} from "src/schema/schemaMappingUtils";

const INPUT_SCHEMA_TYPES_OPTIONS_BASE = Object.entries(InputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

export const getEntitySchemaOptions = (
  entitySchemas: EntitySchemaResource[],
) => {
  return entitySchemas?.map((entity) => ({
    key: entityNameToJsonRefPath(entity._id),
    value: capitalize(entity._display_name_singular),
    icon: getSchemaIcon(entity),
    schemaName: entity._id,
  }));
};

export const getEventSchemaOptions = (eventSchemas: EventConfigEnriched[]) =>
  eventSchemas?.map((eventSchema) => ({
    key: eventNameToJsonRefPath(eventSchema.event_type),
    value: capitalize(eventSchema.display_name_singular),
    icon: getEventIcon(eventSchema),
    internalName: eventSchema.internal_name,
  }));

const OUTPUT_SCHEMA_TYPES_OPTIONS_BASE = Object.entries(OutputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

export const SchemaTypeSelector: React.FC<SchemaTypeSelectorProps> = ({
  rowName,
  disabled,
  index,
  type,
}) => {
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();
  const { control, setValue } = useFormContext<SchemaUIT>();
  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.entitiesEnabled,
    },
  });
  const { data: eventSchemas } = useEventSchemas({
    enabled:
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
      featureGates.featuresEventsEnabled,
  });
  const isEnrichedSchema = isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase);
  const entitySchemaOptions =
    isEnrichedSchema && featureGates.entitiesEnabled && entitySchemas?.entities
      ? getEntitySchemaOptions(entitySchemas?.entities)
      : [];
  const eventSchemaOptions =
    isEnrichedSchema && featureGates.featuresEventsEnabled && eventSchemas
      ? getEventSchemaOptions(eventSchemas)
      : [];

  return (
    <Controller
      control={control}
      name={`${rowName}.type.0`}
      render={(props) => (
        <FixedSimpleDropdown
          buttonDataLoc="schema-field-type"
          dataLoc={`schema-field-type-${index}`}
          disabled={disabled}
          elements={[
            ...(type === "input"
              ? INPUT_SCHEMA_TYPES_OPTIONS_BASE
              : OUTPUT_SCHEMA_TYPES_OPTIONS_BASE),
            ...entitySchemaOptions,
            ...eventSchemaOptions,
          ]}
          placeholder="Select type"
          placement="bottomLeft"
          selectedKey={props.field.value}
          onSelect={(newType) => {
            if (newType === "enum") {
              if (props.field.value !== "enum") {
                setValue(`properties.${index}.enum`, [{ value: '"value_1"' }]);
              }
            } else {
              setValue(`properties.${index}.enum`, undefined);
            }
            props.field.onChange(newType);
          }}
        />
      )}
    />
  );
};
