import { ValidationResult } from "src/datasets/DatasetTable/validators";

export type ErrorDetails = Omit<ValidationResult, "applyFix"> & {
  applyFix: (() => void) | undefined;
};

export const getErrorDetails = (
  error: ValidationResult | undefined,
  value: string,
  onChange: (value: string) => void,
): ErrorDetails | undefined => {
  if (!error) return;

  const applyFix = error.applyFix
    ? () => {
        const fixedValue = error.applyFix?.(value);
        if (fixedValue) {
          onChange(fixedValue);
        }
      }
    : undefined;

  return {
    ...error,
    applyFix,
  };
};
