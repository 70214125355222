import { noop } from "lodash";
import React from "react";

import { useFlowVersion } from "src/api/flowVersionQueries";
import { Dataset } from "src/api/types";
import { EditModal as EditDatasetModal } from "src/datasets/EditModal";
import { useSchemas } from "src/datasets/utils";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { usePatchJobSource } from "src/jobs/api/queries";
import { TrafficPolicyModal } from "src/jobs/common/TrafficPolicyModal";
import { useDominantVersion, useJobSource } from "src/jobs/common/hooks";
import { Job, JobDatasetSource } from "src/jobs/types";
import { queryClient } from "src/queryClient";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";

interface DatasetSourceModalProps {
  dataset: Dataset | undefined;
  sourceId: string | undefined;
  job: Job;
  open: boolean;
  onClose: () => void;
}

export const EditDatasetSourceModal: React.FC<DatasetSourceModalProps> = ({
  dataset,
  sourceId,
  open,
  onClose,
  job,
}) => {
  const { workspace } = useWorkspaceContext();
  const { mutateAsync: patchSqlSource } = usePatchJobSource(
    workspace.base_url!,
    job.id,
  );
  const source = useJobSource<JobDatasetSource>(job.id, sourceId);

  const { isOpen, openModal, closeModal } = useModal();

  const version = useDominantVersion(job);
  const versionWithGraph = useFlowVersion(version?.id);

  const schemas = useSchemas(version);

  const onNameUpdate = async ({ name }: { name: string }) => {
    if (!source) return;

    try {
      await patchSqlSource({
        id: source.id,
        etag: source.etag,
        name: name,
        configuration: source.configuration,
      });
    } catch (error) {
      logger.error(error);

      if (isPreconditionError(error)) {
        await queryClient.invalidateQueries({
          queryKey: ["job_sources", workspace.base_url, job.id],
        });
        toastActions.failure({
          title: "Failed to update SQL source",
          description:
            "Somebody has changed the Source, check the updated configuration and try again",
        });
      } else {
        toastActions.failure({
          title: "Failed to update SQL source",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  return (
    <>
      <EditDatasetModal
        context="jobs"
        dataset={dataset}
        open={open}
        schemas={schemas}
        version={versionWithGraph.data}
        // We do not want to close the modal when the select version modal is open
        onClose={!isOpen ? onClose : noop}
        onNameUpdate={onNameUpdate}
        onOpenConnectVersionModal={openModal}
      />
      <TrafficPolicyModal job={job} open={isOpen} onClose={closeModal} />
    </>
  );
};
