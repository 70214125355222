import {
  faSearch,
  faSliders,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { InfiniteData } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { ChangeHistoryGrouped } from "src/changeHistory/ChangeHistoryGrouped";
import { ChangeHistoryList } from "src/changeHistory/ChangeHistoryList";
import { DiffViewModal } from "src/changeHistory/DiffViewModal/DiffViewModal";
import {
  getChangeToCompareAgainst,
  OpenDiffViewParams,
} from "src/changeHistory/DiffViewModal/GetChangeToCompareAgainst";
import {
  ListOption,
  SinceOption,
  ViewOptionsDropdown,
} from "src/changeHistory/ViewOptionsDropdown";
import { groupChangesByResource } from "src/changeHistory/getChangeResourceAndIcon";
import {
  canFetchNextPage,
  useLoadChangeHistory,
  useFetchAllChanges,
  etag_including_comments,
} from "src/changeHistory/queries";
import { useLatestReviewCommentFlowVersionEtag } from "src/changeHistory/useLatestReviewComment";
import { useLatestSeenVersionEtag } from "src/changeHistory/useLatestSeenVersionEtag";
import { ChangeLogDb } from "src/clients/flow-api";
import { EmptyState } from "src/design-system/EmptyState";
import { rightSidePaneWidthWideClassname } from "src/flowContainer/RightPane";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useAuthStore } from "src/store/AuthStore";

type Props = {
  onClose: () => void;
};

const getHistoryDataToDisplay = (
  changeHistory: InfiniteData<ChangeLogDb[]> | undefined,
  displayDataUntilEtag: string | undefined,
) => {
  if (displayDataUntilEtag === undefined) return changeHistory?.pages.flat();
  return changeHistory?.pages
    .flat()
    .filter((change) => change.etag > displayDataUntilEtag);
};

const ChangeHistoryEmptyState: React.FC = () => (
  <EmptyState
    description="Try adjusting the filter to see the changes made to this version"
    headline="No change made"
    icon={faSearch}
  />
);

export const ChangeHistoryPane: React.FC<Props> = ({ onClose }) => {
  const { signed_in_user_id: userId } = useAuthStore();
  const { version, flow, orgId } = useAuthoringContext();
  const latestOwnReviewCommentEtag = useLatestReviewCommentFlowVersionEtag(
    version,
    userId,
  );

  useEffect(() => {
    if (flow.id && orgId && version.id) {
      tracker.emit(
        trackingEvents.openHistoryPane({
          flow_id: flow.id,
          flow_version_id: version.id,
          organization_id: orgId,
        }),
      );
    }
  }, [flow.id, orgId, version.id]);

  const { data: review } = useFlowVersionReview(version.id);
  const [listMode, setListMode] = useSessionStorage<ListOption>(
    "change_history_listMode",
    "grouped",
  );
  const [displaySince, setDisplaySince] = useSessionStorage<SinceOption>(
    "change_history_displaySince",
    "all",
  );

  const displayDataUntilEtag =
    displaySince === "lastReviewed"
      ? latestOwnReviewCommentEtag
      : displaySince === "reviewStarted"
        ? review?.opened_at_flow_version_etag
        : undefined;
  const changeHistory = useLoadChangeHistory(
    version.id,
    etag_including_comments(version.etag),
  );

  // For grouping changes by nodes and to always be able to compare against the previous change
  // we always fetch all changes.
  useFetchAllChanges(changeHistory);
  const canFetchNextChanges = canFetchNextPage({
    hasNextPage: changeHistory.hasNextPage,
    isFetchingNextPage: changeHistory.isFetchingNextPage,
  });

  const [updatedLatestEtag, saveLatestEtag] = useLatestSeenVersionEtag({
    userId: userId!,
    versionId: version.id,
  });

  // Memo the initial lastSeenEtag so that seen/unseen changes are only update when re-opening the pane
  const latestSeenEtag = useMemo(
    () => updatedLatestEtag,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (userId && changeHistory.data && changeHistory.data.pages) {
      const lastSeenEtag = changeHistory.data.pages.at(0)?.at(0)?.etag;
      if (lastSeenEtag) {
        saveLatestEtag(lastSeenEtag);
      }
    }
  }, [changeHistory.data, flow.id, orgId, saveLatestEtag, userId, version.id]);

  const [diffViewOpen, setDiffViewOpen] = useState(false);
  const [diffViewState, setDiffViewState] = useState<{
    change: ChangeLogDb;
    compareAgainst: ChangeLogDb;
    forNodeId?: string;
  }>();

  const openDiffView = useCallback(
    ({
      change,
      againstChangeBefore = "self",
      forNodeId,
    }: OpenDiffViewParams) => {
      const changeToCompareAgainst = getChangeToCompareAgainst({
        change,
        againstChangeBefore,
        flatHistoryData: changeHistory.data?.pages.flat() ?? [],
      });
      if (!changeToCompareAgainst) return;
      setDiffViewState({
        change,
        compareAgainst: changeToCompareAgainst,
        forNodeId,
      });
      setDiffViewOpen(true);
    },
    [changeHistory.data?.pages],
  );
  const groupedAndFilteredChanges = useMemo(() => {
    const flatChanges = changeHistory.data?.pages.flat();
    if (!flatChanges) return undefined;
    return groupChangesByResource({
      changes: flatChanges,
      afterEtagFilter: displayDataUntilEtag,
      // If we show all changes we don't want nodes to appear that existed at version creation and did not change
      excludeNodesOnlyPartOfFirstChange: displaySince === "all",
    });
  }, [changeHistory.data?.pages, displayDataUntilEtag, displaySince]);
  return (
    <>
      <div
        className={twJoin(
          "flex max-h-full flex-col overflow-hidden bg-white py-4 pt-6 shadow-lg",
          rightSidePaneWidthWideClassname,
        )}
      >
        <div className="mx-5 flex justify-between gap-x-1 border-b border-gray-100 pb-4">
          <h2 className="text-gray-800 font-inter-semibold-16px">History</h2>
          <div className="flex gap-x-1.5">
            <ViewOptionsDropdown
              button={
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  dataLoc="expand-view-options-menu-button"
                  icon={faSliders}
                  size="xs"
                />
              }
              displaySince={displaySince}
              listMode={listMode}
              setDisplaySince={setDisplaySince}
              setListMode={setListMode}
              showReviewOptions={!!review}
              userHasReviewed={latestOwnReviewCommentEtag !== undefined}
            />
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="close-node-editor"
              icon={faXmark}
              size="xs"
              onClick={onClose}
            />
          </div>
        </div>
        <LoadingView
          queryResult={changeHistory}
          renderUpdated={(data: InfiniteData<ChangeLogDb[]>) => {
            if (listMode === "grouped") {
              if (
                !groupedAndFilteredChanges ||
                groupedAndFilteredChanges === "noGroupedChanges"
              )
                return <ChangeHistoryEmptyState />;
              return (
                <ChangeHistoryGrouped
                  groupedChanges={groupedAndFilteredChanges}
                  latestSeenEtag={latestSeenEtag}
                  openDiffView={openDiffView}
                />
              );
            }
            const fitleredChanges = getHistoryDataToDisplay(
              data,
              displayDataUntilEtag,
            );
            if (fitleredChanges && fitleredChanges.length === 0)
              return <ChangeHistoryEmptyState />;
            return (
              <ChangeHistoryList
                canFetchNextChanges={canFetchNextChanges}
                changes={fitleredChanges}
                fetchNextChanges={changeHistory.fetchNextPage}
                isFetchingNextPage={changeHistory.isFetchingNextPage}
                latestSeenEtag={latestSeenEtag}
                openDiffView={openDiffView}
              />
            );
          }}
        />
      </div>
      <DiffViewModal
        afterLeave={() => {
          setDiffViewState(undefined);
        }}
        change={diffViewState?.change}
        compareAgainst={diffViewState?.compareAgainst}
        forNodeId={diffViewState?.forNodeId}
        handleClose={() => {
          setDiffViewOpen(false);
        }}
        open={diffViewOpen}
      />
    </>
  );
};
