/* tslint:disable */
/* eslint-disable */
/**
 * SAMPLE_PROJECT_NAME
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ActorType {
    USER = 'user',
    SYSTEM = 'system'
}


/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'api_key': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ApiKeyType {
    SANDBOX = 'sandbox',
    LIVE = 'live',
    JOB_ADMIN = 'job_admin',
    HISTORY_ADMIN = 'history_admin',
    AUDIT_LOGS = 'audit_logs'
}


/**
 * 
 * @export
 * @interface AuditEvent
 */
export interface AuditEvent {
    /**
     * 
     * @type {TaktileApiAuditLogsSchemasAction}
     * @memberof AuditEvent
     */
    'action': TaktileApiAuditLogsSchemasAction;
    /**
     * 
     * @type {EntityType}
     * @memberof AuditEvent
     */
    'entity_type': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'entity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'workspace_id'?: string;
    /**
     * 
     * @type {AuthPhase}
     * @memberof AuditEvent
     */
    'auth_phase': AuthPhase;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'source_ip'?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof AuditEvent
     */
    'actor_type': ActorType;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'actor_id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'actor_name': string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AuditEvent
     */
    'version'?: string;
    /**
     * 
     * @type {object}
     * @memberof AuditEvent
     */
    'details'?: object;
}


/**
 * 
 * @export
 * @interface AuditEventPage
 */
export interface AuditEventPage {
    /**
     * 
     * @type {Array<AuditEvent>}
     * @memberof AuditEventPage
     */
    'events': Array<AuditEvent>;
    /**
     * 
     * @type {string}
     * @memberof AuditEventPage
     */
    'next_page_token'?: string;
}
/**
 * 
 * @export
 * @interface AuditExport
 */
export interface AuditExport {
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof AuditExport
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'error'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditExport
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof AuditExport
     */
    'type': string;
    /**
     * 
     * @type {AuditExportParams}
     * @memberof AuditExport
     */
    'request': AuditExportParams;
    /**
     * 
     * @type {object}
     * @memberof AuditExport
     */
    'response': object;
}
/**
 * 
 * @export
 * @interface AuditExportParams
 */
export interface AuditExportParams {
    /**
     * 
     * @type {string}
     * @memberof AuditExportParams
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExportParams
     */
    'end_date': string;
    /**
     * 
     * @type {string}
     * @memberof AuditExportParams
     */
    'actor_id'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AuthPhase {
    UNAUTHORIZED = 'unauthorized',
    AUTHORIZED = 'authorized'
}


/**
 * 
 * @export
 * @interface BodyUpdateUserMeApiV1UsersMePut
 */
export interface BodyUpdateUserMeApiV1UsersMePut {
    /**
     * 
     * @type {string}
     * @memberof BodyUpdateUserMeApiV1UsersMePut
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyUpdateUserMeApiV1UsersMePut
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmail
 */
export interface CreateEmail {
    /**
     * 
     * @type {string}
     * @memberof CreateEmail
     */
    'sender_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEmail
     */
    'receiver_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateEmail
     */
    'organization_id': string;
    /**
     * 
     * @type {Template}
     * @memberof CreateEmail
     */
    'template': Template;
    /**
     * 
     * @type {FlowReviewRequestParams}
     * @memberof CreateEmail
     */
    'template_data': FlowReviewRequestParams;
}
/**
 * 
 * @export
 * @interface EntityId
 */
export interface EntityId {
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EntityType {
    API_KEY = 'api_key',
    DECISION = 'decision',
    DECISION_EXPORT = 'decision_export',
    ENTITY_DECISIONS = 'entity_decisions',
    ROUTING_POLICY = 'routing_policy',
    REVIEW_CASE = 'review_case',
    REVIEW_CASE_EXPORT = 'review_case_export',
    DATASET = 'dataset',
    DATASET_EXPORT = 'dataset_export',
    JOB = 'job',
    TEST_RUN = 'test_run',
    USER = 'user',
    CONNECTION = 'connection',
    LOGIN = 'login',
    OUTCOME_TYPE = 'outcome_type',
    OUTCOME_DATA = 'outcome_data',
    FEATURE = 'feature',
    FEATURE_QUERY = 'feature_query',
    EVENT = 'event',
    ENTITY = 'entity',
    FILE = 'file'
}


/**
 * 
 * @export
 * @interface FlowReviewRequestParams
 */
export interface FlowReviewRequestParams {
    /**
     * 
     * @type {string}
     * @memberof FlowReviewRequestParams
     */
    'flow_version_name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowReviewRequestParams
     */
    'flow_name': string;
    /**
     * 
     * @type {string}
     * @memberof FlowReviewRequestParams
     */
    'flow_version_url': string;
}
/**
 * 
 * @export
 * @interface GitbookAuthenticatedURL
 */
export interface GitbookAuthenticatedURL {
    /**
     * 
     * @type {string}
     * @memberof GitbookAuthenticatedURL
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GitbookRedirectPath
 */
export interface GitbookRedirectPath {
    /**
     * 
     * @type {string}
     * @memberof GitbookRedirectPath
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Installation
 */
export interface Installation {
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Installation
     */
    'installation_id': number;
    /**
     * 
     * @type {string}
     * @memberof Installation
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface InstallationAccessToken
 */
export interface InstallationAccessToken {
    /**
     * 
     * @type {string}
     * @memberof InstallationAccessToken
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof InstallationAccessToken
     */
    'expires_at': string;
    /**
     * 
     * @type {object}
     * @memberof InstallationAccessToken
     */
    'permissions': object;
}
/**
 * 
 * @export
 * @interface LoginOrRegisterMessage
 */
export interface LoginOrRegisterMessage {
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterMessage
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterMessage
     */
    'register_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterMessage
     */
    'auth_source': string;
    /**
     * 
     * @type {string}
     * @memberof LoginOrRegisterMessage
     */
    'redirect_uri'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum OrgPartner {
    LOANPRO = 'loanpro'
}


/**
 * 
 * @export
 * @interface OrganizationAuthSourceCreateNamed
 */
export interface OrganizationAuthSourceCreateNamed {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceCreateNamed
     */
    'domain_name': string;
    /**
     * 
     * @type {TaktileApiSchemasOrganizationAvailableAuthSources}
     * @memberof OrganizationAuthSourceCreateNamed
     */
    'source_name': TaktileApiSchemasOrganizationAvailableAuthSources;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceCreateNamed
     */
    'well_known_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceCreateNamed
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceCreateNamed
     */
    'client_secret'?: string;
}


/**
 * 
 * @export
 * @interface OrganizationAuthSourceResponse
 */
export interface OrganizationAuthSourceResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceResponse
     */
    'domain_name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceResponse
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceResponse
     */
    'user_auth_source_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAuthSourceResponse
     */
    'user_auth_source_name': string;
}
/**
 * 
 * @export
 * @interface OrganizationCreate
 */
export interface OrganizationCreate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreate
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCreate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreate
     */
    'principal': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCreate
     */
    'allows_signup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCreate
     */
    'allows_decide_access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCreate
     */
    'whitelabel': boolean;
    /**
     * 
     * @type {OrgPartner}
     * @memberof OrganizationCreate
     */
    'partner'?: OrgPartner;
}


/**
 * 
 * @export
 * @interface OrganizationInDB
 */
export interface OrganizationInDB {
    /**
     * 
     * @type {string}
     * @memberof OrganizationInDB
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationInDB
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInDB
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInDB
     */
    'principal': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationInDB
     */
    'allows_signup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationInDB
     */
    'allows_decide_access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationInDB
     */
    'whitelabel': boolean;
    /**
     * 
     * @type {OrgPartner}
     * @memberof OrganizationInDB
     */
    'partner'?: OrgPartner;
}


/**
 * 
 * @export
 * @interface OrganizationLogoResponse
 */
export interface OrganizationLogoResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationLogoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLogoResponse
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLogoResponse
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLogoResponse
     */
    'mimetype': string;
}
/**
 * 
 * @export
 * @interface OrganizationUpdate
 */
export interface OrganizationUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'allows_signup'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'allows_decide_access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'whitelabel'?: boolean;
    /**
     * 
     * @type {OrgPartner}
     * @memberof OrganizationUpdate
     */
    'partner'?: OrgPartner;
}


/**
 * 
 * @export
 * @interface OrganizationUser
 */
export interface OrganizationUser {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'is_superuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'source_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'authenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'sponsor_user_id'?: string;
    /**
     * 
     * @type {SimpleRoleType}
     * @memberof OrganizationUser
     */
    'role'?: SimpleRoleType;
    /**
     * 
     * @type {Array<WorkspaceRole>}
     * @memberof OrganizationUser
     */
    'workspaces_roles'?: Array<WorkspaceRole>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'invite_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'organization_id': string;
}


/**
 * 
 * @export
 * @interface OrganizationUserUpdate
 */
export interface OrganizationUserUpdate {
    /**
     * 
     * @type {SimpleRoleType}
     * @memberof OrganizationUserUpdate
     */
    'role'?: SimpleRoleType;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUserUpdate
     */
    'is_active'?: boolean;
}


/**
 * 
 * @export
 * @interface OrganizationUserWSRolesUpdate
 */
export interface OrganizationUserWSRolesUpdate {
    /**
     * 
     * @type {Array<WorkspaceRole>}
     * @memberof OrganizationUserWSRolesUpdate
     */
    'workspaces_roles'?: Array<WorkspaceRole>;
}
/**
 * 
 * @export
 * @interface RepositorySecretCreatePayload
 */
export interface RepositorySecretCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretCreatePayload
     */
    'secret_name': string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretCreatePayload
     */
    'secret_value': string;
}
/**
 * 
 * @export
 * @interface RepositorySecretInDB
 */
export interface RepositorySecretInDB {
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'repository_id': string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'secret_name': string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretInDB
     */
    'secret_value': string;
}
/**
 * 
 * @export
 * @interface RepositorySecretUpdatePayload
 */
export interface RepositorySecretUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretUpdatePayload
     */
    'secret_name': string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySecretUpdatePayload
     */
    'secret_value': string;
}
/**
 * 
 * @export
 * @interface RoleCreate
 */
export interface RoleCreate {
    /**
     * 
     * @type {string}
     * @memberof RoleCreate
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RoleCreate
     */
    'arguments': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RoleCreate
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface RoleInDB
 */
export interface RoleInDB {
    /**
     * 
     * @type {string}
     * @memberof RoleInDB
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RoleInDB
     */
    'arguments': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RoleInDB
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleInDB
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof RoleInDB
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof RoleInDB
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RoleParams
 */
export interface RoleParams {
    /**
     * 
     * @type {string}
     * @memberof RoleParams
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RoleParams
     */
    'arguments': { [key: string]: string; };
}
/**
 * Response from SSO discovery endpoint
 * @export
 * @interface SSODiscoveryResponse
 */
export interface SSODiscoveryResponse {
    /**
     * 
     * @type {string}
     * @memberof SSODiscoveryResponse
     */
    'scopes': string;
    /**
     * 
     * @type {string}
     * @memberof SSODiscoveryResponse
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof SSODiscoveryResponse
     */
    'login_url': string;
    /**
     * 
     * @type {string}
     * @memberof SSODiscoveryResponse
     */
    'provider_name': string;
}
/**
 * 
 * @export
 * @interface SecretLinkUserCreate
 */
export interface SecretLinkUserCreate {
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretLinkUserCreate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecretLinkUserCreate
     */
    'is_superuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'source_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretLinkUserCreate
     */
    'authenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SecretLinkUserCreate
     */
    'sponsor_user_id'?: string;
}
/**
 * 
 * @export
 * @interface ServiceAccountAccess
 */
export interface ServiceAccountAccess {
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountAccess
     */
    'repository_id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountAccess
     */
    'branch': string;
}
/**
 * api_key : UUID4 Associated api key
 * @export
 * @interface ServiceAccountResponse
 */
export interface ServiceAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'last_used_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'sponsored_by_username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountResponse
     */
    'api_key': string;
}
/**
 * 
 * @export
 * @interface SimpleRole
 */
export interface SimpleRole {
    /**
     * 
     * @type {SimpleRoleType}
     * @memberof SimpleRole
     */
    'role': SimpleRoleType;
}


/**
 * Organization role types
 * @export
 * @enum {string}
 */

export enum SimpleRoleType {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum TaktileApiAuditLogsSchemasAction {
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    EXECUTE = 'execute',
    ACTIVATE = 'activate',
    DEACTIVATE = 'deactivate',
    SUBMIT = 'submit',
    CREATE_RESPONSE = 'create_response',
    READ_RESPONSE = 'read_response'
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum TaktileApiCoreTAvailableAuthSources {
    BASIC = 'basic',
    GITHUB = 'github',
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    OKTA = 'okta',
    SECRET_LINK = 'secret_link',
    SERVICE_ACCOUNT = 'service_account',
    DECIDE_SERVICE_ACCOUNT = 'decide_service_account'
}


/**
 * ServiceAccount model for Decide.  Attributes ----------  id : UUID4     Unique identifier for the SA name : str     Name of this SA last_used_at: t.Optional[datetime] = None     Last date API key was used for authentication.
 * @export
 * @interface TaktileApiSchemasDecideServiceAccountServiceAccount
 */
export interface TaktileApiSchemasDecideServiceAccountServiceAccount {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccount
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccount
     */
    'last_used_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccount
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccount
     */
    'sponsored_by_username'?: string;
}
/**
 * 
 * @export
 * @interface TaktileApiSchemasDecideServiceAccountServiceAccountPatch
 */
export interface TaktileApiSchemasDecideServiceAccountServiceAccountPatch {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccountPatch
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TaktileApiSchemasDecideServiceAccountServiceAccountPost
 */
export interface TaktileApiSchemasDecideServiceAccountServiceAccountPost {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasDecideServiceAccountServiceAccountPost
     */
    'name': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum TaktileApiSchemasOrganizationAvailableAuthSources {
    GITHUB = 'github',
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    OKTA = 'okta'
}


/**
 * ServiceAccount Model.  Attributes ----------  id : UUID4     Unique identifier for the SA api_key : UUID4     Associated api key name : str     Name of this SA access : t.List[ServiceAccountAccess]     List of access tuples last_used_at: t.Optional[datetime] = None     Last date API key was used for authentication.
 * @export
 * @interface TaktileApiSchemasServiceAccountServiceAccount
 */
export interface TaktileApiSchemasServiceAccountServiceAccount {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'api_key': string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'name': string;
    /**
     * 
     * @type {Array<ServiceAccountAccess>}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'access': Array<ServiceAccountAccess>;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'last_used_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccount
     */
    'sponsored_by_username'?: string;
}
/**
 * 
 * @export
 * @interface TaktileApiSchemasServiceAccountServiceAccountPatch
 */
export interface TaktileApiSchemasServiceAccountServiceAccountPatch {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccountPatch
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ServiceAccountAccess>}
     * @memberof TaktileApiSchemasServiceAccountServiceAccountPatch
     */
    'access'?: Array<ServiceAccountAccess>;
}
/**
 * 
 * @export
 * @interface TaktileApiSchemasServiceAccountServiceAccountPost
 */
export interface TaktileApiSchemasServiceAccountServiceAccountPost {
    /**
     * 
     * @type {string}
     * @memberof TaktileApiSchemasServiceAccountServiceAccountPost
     */
    'name': string;
    /**
     * 
     * @type {Array<ServiceAccountAccess>}
     * @memberof TaktileApiSchemasServiceAccountServiceAccountPost
     */
    'access': Array<ServiceAccountAccess>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum TaktileAuthEnumsAction {
    R = 'r',
    W = 'w',
    D = 'd'
}


/**
 * 
 * @export
 * @interface TeamInDB
 */
export interface TeamInDB {
    /**
     * 
     * @type {string}
     * @memberof TeamInDB
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof TeamInDB
     */
    'github_id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInDB
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInDB
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInDB
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof TeamInDB
     */
    'members_count': number;
    /**
     * 
     * @type {number}
     * @memberof TeamInDB
     */
    'repos_count': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInDB
     */
    'organization_id': string;
}
/**
 * 
 * @export
 * @interface TeamRoleCreate
 */
export interface TeamRoleCreate {
    /**
     * 
     * @type {string}
     * @memberof TeamRoleCreate
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TeamRoleCreate
     */
    'arguments': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TeamRoleCreate
     */
    'team_id': string;
}
/**
 * 
 * @export
 * @interface TeamRoleInDB
 */
export interface TeamRoleInDB {
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInDB
     */
    'name': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TeamRoleInDB
     */
    'arguments': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInDB
     */
    'team_id': string;
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInDB
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInDB
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamRoleInDB
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
}
/**
 * 
 * @export
 * @interface TokenWithUserAndKey
 */
export interface TokenWithUserAndKey {
    /**
     * 
     * @type {string}
     * @memberof TokenWithUserAndKey
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenWithUserAndKey
     */
    'token_type': string;
    /**
     * 
     * @type {string}
     * @memberof TokenWithUserAndKey
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenWithUserAndKey
     */
    'id_token': string;
    /**
     * 
     * @type {User}
     * @memberof TokenWithUserAndKey
     */
    'user': User;
    /**
     * 
     * @type {UserApiKey}
     * @memberof TokenWithUserAndKey
     */
    'api_key': UserApiKey;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof TokenWithUserAndKey
     */
    'product_access': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_superuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'source_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'authenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'sponsor_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'expires'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'register_token'?: string;
    /**
     * 
     * @type {Array<RoleInDB>}
     * @memberof User
     */
    'roles'?: Array<RoleInDB>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_guest_user'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'hashed_email'?: string;
}
/**
 * 
 * @export
 * @interface UserApiKey
 */
export interface UserApiKey {
    /**
     * 
     * @type {string}
     * @memberof UserApiKey
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserApiKey
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserApiKey
     */
    'api_key': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserApiKey
     */
    'valid': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserApiKey
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserApiKey
     */
    'last_used_at'?: string;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_superuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'source_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'authenticated': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'sponsor_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'expires'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'register_token'?: string;
}
/**
 * 
 * @export
 * @interface UserOrganizationInviteData
 */
export interface UserOrganizationInviteData {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteData
     */
    'username': string;
    /**
     * 
     * @type {TaktileApiSchemasOrganizationAvailableAuthSources}
     * @memberof UserOrganizationInviteData
     */
    'auth_provider'?: TaktileApiSchemasOrganizationAvailableAuthSources;
    /**
     * 
     * @type {SimpleRoleType}
     * @memberof UserOrganizationInviteData
     */
    'role': SimpleRoleType;
    /**
     * 
     * @type {Array<WorkspaceRole>}
     * @memberof UserOrganizationInviteData
     */
    'workspaces_roles'?: Array<WorkspaceRole>;
}


/**
 * 
 * @export
 * @interface UserOrganizationInviteResponse
 */
export interface UserOrganizationInviteResponse {
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteResponse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteResponse
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganizationInviteResponse
     */
    'user_id': string;
    /**
     * 
     * @type {OrganizationUser}
     * @memberof UserOrganizationInviteResponse
     */
    'user': OrganizationUser;
}
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'avatar_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_superuser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'source_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'authenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'sponsor_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface ValidateKeyPayload
 */
export interface ValidateKeyPayload {
    /**
     * 
     * @type {string}
     * @memberof ValidateKeyPayload
     */
    'repository_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateKeyPayload
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateKeyPayload
     */
    'endpoint'?: string;
    /**
     * 
     * @type {TaktileAuthEnumsAction}
     * @memberof ValidateKeyPayload
     */
    'action'?: TaktileAuthEnumsAction;
}


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface WorkspaceRole
 */
export interface WorkspaceRole {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceRole
     */
    'workspace_id': string;
    /**
     * 
     * @type {WorkspaceSimpleRoleType}
     * @memberof WorkspaceRole
     */
    'role': WorkspaceSimpleRoleType;
}


/**
 * Workspace based service account for Decide.  Attributes ---------- id : UUID4     Unique identifier for the SA created_at: datetime     Date the SA was created name : str     Name of this SA last_used_at: t.Optional[datetime] = None     Last date API key was used for authentication. key_types: list[ApiKeyType]     live, sandbox, job_admin, history_admin sponsored_by_username: t.Optional[str] = None     Username of the user that sponsored this service account
 * @export
 * @interface WorkspaceServiceAccount
 */
export interface WorkspaceServiceAccount {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccount
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccount
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccount
     */
    'last_used_at'?: string;
    /**
     * 
     * @type {Array<ApiKeyType>}
     * @memberof WorkspaceServiceAccount
     */
    'key_types': Array<ApiKeyType>;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccount
     */
    'sponsored_by_username'?: string;
}
/**
 * 
 * @export
 * @interface WorkspaceServiceAccountPatch
 */
export interface WorkspaceServiceAccountPatch {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountPatch
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WorkspaceServiceAccountPost
 */
export interface WorkspaceServiceAccountPost {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountPost
     */
    'name': string;
    /**
     * 
     * @type {Array<ApiKeyType>}
     * @memberof WorkspaceServiceAccountPost
     */
    'key_types': Array<ApiKeyType>;
}
/**
 * Workspace based service account for Decide.  Attributes ---------- id : UUID4     Unique identifier for the SA created_at: datetime     Date the SA was created name : str     Name of this SA last_used_at: t.Optional[datetime] = None     Last date API key was used for authentication. key_types: list[ApiKeyType]     live, sandbox, job_admin, history_admin sponsored_by_username: t.Optional[str] = None     Username of the user that sponsored this service account
 * @export
 * @interface WorkspaceServiceAccountWithKey
 */
export interface WorkspaceServiceAccountWithKey {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'last_used_at'?: string;
    /**
     * 
     * @type {Array<ApiKeyType>}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'key_types': Array<ApiKeyType>;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'sponsored_by_username'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceServiceAccountWithKey
     */
    'api_key': string;
}
/**
 * 
 * @export
 * @interface WorkspaceSimpleRole
 */
export interface WorkspaceSimpleRole {
    /**
     * 
     * @type {WorkspaceSimpleRoleType}
     * @memberof WorkspaceSimpleRole
     */
    'role': WorkspaceSimpleRoleType;
}


/**
 * Workspace role types
 * @export
 * @enum {string}
 */

export enum WorkspaceSimpleRoleType {
    VIEWER = 'VIEWER',
    REVIEWER = 'REVIEWER',
    EDITOR = 'EDITOR',
    ADMIN = 'ADMIN'
}



/**
 * AuditLogsApi - axios parameter creator
 * @export
 */
export const AuditLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Internal use for local development only.
         * @summary Create Event
         * @param {string} organizationId 
         * @param {AuditEvent} auditEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventApiV1AuditLogsEventsOrganizationIdPut: async (organizationId: string, auditEvent: AuditEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createEventApiV1AuditLogsEventsOrganizationIdPut', 'organizationId', organizationId)
            // verify required parameter 'auditEvent' is not null or undefined
            assertParamExists('createEventApiV1AuditLogsEventsOrganizationIdPut', 'auditEvent', auditEvent)
            const localVarPath = `/api/v1/audit_logs/events/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create export file with up to 100,000 events
         * @summary Create Export
         * @param {string} organizationId 
         * @param {AuditExportParams} auditExportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportApiV1AuditLogsEventsOrganizationIdExportsPost: async (organizationId: string, auditExportParams: AuditExportParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createExportApiV1AuditLogsEventsOrganizationIdExportsPost', 'organizationId', organizationId)
            // verify required parameter 'auditExportParams' is not null or undefined
            assertParamExists('createExportApiV1AuditLogsEventsOrganizationIdExportsPost', 'auditExportParams', auditExportParams)
            const localVarPath = `/api/v1/audit_logs/events/{organization_id}/exports`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditExportParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Events
         * @param {string} organizationId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {string} [actorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsApiV1AuditLogsEventsOrganizationIdGet: async (organizationId: string, startDate: string, endDate: string, pageSize: number, pageToken?: string, actorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getEventsApiV1AuditLogsEventsOrganizationIdGet', 'organizationId', organizationId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getEventsApiV1AuditLogsEventsOrganizationIdGet', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getEventsApiV1AuditLogsEventsOrganizationIdGet', 'endDate', endDate)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getEventsApiV1AuditLogsEventsOrganizationIdGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/audit_logs/events/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (actorId !== undefined) {
                localVarQueryParameter['actor_id'] = actorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Internal use for local development only.
         * @summary Create Event
         * @param {string} organizationId 
         * @param {AuditEvent} auditEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId: string, auditEvent: AuditEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId, auditEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogsApi.createEventApiV1AuditLogsEventsOrganizationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create export file with up to 100,000 events
         * @summary Create Export
         * @param {string} organizationId 
         * @param {AuditExportParams} auditExportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId: string, auditExportParams: AuditExportParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId, auditExportParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogsApi.createExportApiV1AuditLogsEventsOrganizationIdExportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Events
         * @param {string} organizationId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {string} [actorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId: string, startDate: string, endDate: string, pageSize: number, pageToken?: string, actorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditEventPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId, startDate, endDate, pageSize, pageToken, actorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogsApi.getEventsApiV1AuditLogsEventsOrganizationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogsApiFp(configuration)
    return {
        /**
         * Internal use for local development only.
         * @summary Create Event
         * @param {string} organizationId 
         * @param {AuditEvent} auditEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId: string, auditEvent: AuditEvent, options?: RawAxiosRequestConfig): AxiosPromise<AuditEvent> {
            return localVarFp.createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId, auditEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * Create export file with up to 100,000 events
         * @summary Create Export
         * @param {string} organizationId 
         * @param {AuditExportParams} auditExportParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId: string, auditExportParams: AuditExportParams, options?: RawAxiosRequestConfig): AxiosPromise<AuditExport> {
            return localVarFp.createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId, auditExportParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Events
         * @param {string} organizationId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {string} [actorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId: string, startDate: string, endDate: string, pageSize: number, pageToken?: string, actorId?: string, options?: RawAxiosRequestConfig): AxiosPromise<AuditEventPage> {
            return localVarFp.getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId, startDate, endDate, pageSize, pageToken, actorId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
    /**
     * Internal use for local development only.
     * @summary Create Event
     * @param {string} organizationId 
     * @param {AuditEvent} auditEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId: string, auditEvent: AuditEvent, options?: RawAxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).createEventApiV1AuditLogsEventsOrganizationIdPut(organizationId, auditEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create export file with up to 100,000 events
     * @summary Create Export
     * @param {string} organizationId 
     * @param {AuditExportParams} auditExportParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId: string, auditExportParams: AuditExportParams, options?: RawAxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).createExportApiV1AuditLogsEventsOrganizationIdExportsPost(organizationId, auditExportParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Events
     * @param {string} organizationId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} pageSize 
     * @param {string} [pageToken] 
     * @param {string} [actorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId: string, startDate: string, endDate: string, pageSize: number, pageToken?: string, actorId?: string, options?: RawAxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).getEventsApiV1AuditLogsEventsOrganizationIdGet(organizationId, startDate, endDate, pageSize, pageToken, actorId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DecideServiceAccountApi - axios parameter creator
 * @export
 */
export const DecideServiceAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPost} taktileApiSchemasDecideServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1DecideServiceAccountOrganizationIdPost: async (organizationId: string, taktileApiSchemasDecideServiceAccountServiceAccountPost: TaktileApiSchemasDecideServiceAccountServiceAccountPost, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createServiceAccountApiV1DecideServiceAccountOrganizationIdPost', 'organizationId', organizationId)
            // verify required parameter 'taktileApiSchemasDecideServiceAccountServiceAccountPost' is not null or undefined
            assertParamExists('createServiceAccountApiV1DecideServiceAccountOrganizationIdPost', 'taktileApiSchemasDecideServiceAccountServiceAccountPost', taktileApiSchemasDecideServiceAccountServiceAccountPost)
            const localVarPath = `/api/v1/decide_service_account/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taktileApiSchemasDecideServiceAccountServiceAccountPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete: async (organizationId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete', 'organizationId', organizationId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete', 'accountId', accountId)
            const localVarPath = `/api/v1/decide_service_account/{organization_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/decide_service_account/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet: async (organizationId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet', 'organizationId', organizationId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet', 'accountId', accountId)
            const localVarPath = `/api/v1/decide_service_account/{organization_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPatch} taktileApiSchemasDecideServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch: async (organizationId: string, accountId: string, taktileApiSchemasDecideServiceAccountServiceAccountPatch: TaktileApiSchemasDecideServiceAccountServiceAccountPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch', 'organizationId', organizationId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch', 'accountId', accountId)
            // verify required parameter 'taktileApiSchemasDecideServiceAccountServiceAccountPatch' is not null or undefined
            assertParamExists('patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch', 'taktileApiSchemasDecideServiceAccountServiceAccountPatch', taktileApiSchemasDecideServiceAccountServiceAccountPatch)
            const localVarPath = `/api/v1/decide_service_account/{organization_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taktileApiSchemasDecideServiceAccountServiceAccountPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DecideServiceAccountApi - functional programming interface
 * @export
 */
export const DecideServiceAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DecideServiceAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPost} taktileApiSchemasDecideServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId: string, taktileApiSchemasDecideServiceAccountServiceAccountPost: TaktileApiSchemasDecideServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId, taktileApiSchemasDecideServiceAccountServiceAccountPost, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecideServiceAccountApi.createServiceAccountApiV1DecideServiceAccountOrganizationIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecideServiceAccountApi.deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaktileApiSchemasDecideServiceAccountServiceAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecideServiceAccountApi.getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasDecideServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecideServiceAccountApi.getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPatch} taktileApiSchemasDecideServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId: string, accountId: string, taktileApiSchemasDecideServiceAccountServiceAccountPatch: TaktileApiSchemasDecideServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasDecideServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId, accountId, taktileApiSchemasDecideServiceAccountServiceAccountPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DecideServiceAccountApi.patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DecideServiceAccountApi - factory interface
 * @export
 */
export const DecideServiceAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DecideServiceAccountApiFp(configuration)
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPost} taktileApiSchemasDecideServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId: string, taktileApiSchemasDecideServiceAccountServiceAccountPost: TaktileApiSchemasDecideServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig): AxiosPromise<ServiceAccountResponse> {
            return localVarFp.createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId, taktileApiSchemasDecideServiceAccountServiceAccountPost, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId: string, accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TaktileApiSchemasDecideServiceAccountServiceAccount>> {
            return localVarFp.getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId: string, accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasDecideServiceAccountServiceAccount> {
            return localVarFp.getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPatch} taktileApiSchemasDecideServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId: string, accountId: string, taktileApiSchemasDecideServiceAccountServiceAccountPatch: TaktileApiSchemasDecideServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasDecideServiceAccountServiceAccount> {
            return localVarFp.patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId, accountId, taktileApiSchemasDecideServiceAccountServiceAccountPatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DecideServiceAccountApi - object-oriented interface
 * @export
 * @class DecideServiceAccountApi
 * @extends {BaseAPI}
 */
export class DecideServiceAccountApi extends BaseAPI {
    /**
     * Create a new service account in an organization
     * @summary Create Service Account
     * @param {string} organizationId 
     * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPost} taktileApiSchemasDecideServiceAccountServiceAccountPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecideServiceAccountApi
     */
    public createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId: string, taktileApiSchemasDecideServiceAccountServiceAccountPost: TaktileApiSchemasDecideServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig) {
        return DecideServiceAccountApiFp(this.configuration).createServiceAccountApiV1DecideServiceAccountOrganizationIdPost(organizationId, taktileApiSchemasDecideServiceAccountServiceAccountPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a service account in an organization
     * @summary Delete Service Account
     * @param {string} organizationId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecideServiceAccountApi
     */
    public deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId: string, accountId: string, options?: RawAxiosRequestConfig) {
        return DecideServiceAccountApiFp(this.configuration).deleteServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdDelete(organizationId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve service accounts of an organization
     * @summary Get All Service Accounts
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecideServiceAccountApi
     */
    public getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return DecideServiceAccountApiFp(this.configuration).getAllServiceAccountsApiV1DecideServiceAccountOrganizationIdGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a service account in an organization
     * @summary Get Service Account
     * @param {string} organizationId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecideServiceAccountApi
     */
    public getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId: string, accountId: string, options?: RawAxiosRequestConfig) {
        return DecideServiceAccountApiFp(this.configuration).getServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdGet(organizationId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch service account in an organization
     * @summary Patch Service Account
     * @param {string} organizationId 
     * @param {string} accountId 
     * @param {TaktileApiSchemasDecideServiceAccountServiceAccountPatch} taktileApiSchemasDecideServiceAccountServiceAccountPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecideServiceAccountApi
     */
    public patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId: string, accountId: string, taktileApiSchemasDecideServiceAccountServiceAccountPatch: TaktileApiSchemasDecideServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig) {
        return DecideServiceAccountApiFp(this.configuration).patchServiceAccountApiV1DecideServiceAccountOrganizationIdAccountIdPatch(organizationId, accountId, taktileApiSchemasDecideServiceAccountServiceAccountPatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Healthz
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet: async (includeAlembicRevision?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeAlembicRevision !== undefined) {
                localVarQueryParameter['include_alembic_revision'] = includeAlembicRevision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Jwk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwkWellKnownJwksJsonGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/.well-known/jwks.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Healthz
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthzHealthzGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthzHealthzGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthzHealthzGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoInfoGet(includeAlembicRevision?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoInfoGet(includeAlembicRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.infoInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Jwk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwkWellKnownJwksJsonGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwkWellKnownJwksJsonGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.jwkWellKnownJwksJsonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Healthz
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthzHealthzGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Info
         * @param {boolean} [includeAlembicRevision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet(includeAlembicRevision?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.infoInfoGet(includeAlembicRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Jwk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwkWellKnownJwksJsonGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.jwkWellKnownJwksJsonGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Healthz
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthzHealthzGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthzHealthzGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Info
     * @param {boolean} [includeAlembicRevision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoInfoGet(includeAlembicRevision?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).infoInfoGet(includeAlembicRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Jwk
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jwkWellKnownJwksJsonGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jwkWellKnownJwksJsonGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GitbookApi - axios parameter creator
 * @export
 */
export const GitbookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Gitbook docs URL with JWT to which the user should be redirected
         * @summary Gitbook Auth
         * @param {GitbookRedirectPath} gitbookRedirectPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gitbookAuthApiV1GitbookAuthPost: async (gitbookRedirectPath: GitbookRedirectPath, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitbookRedirectPath' is not null or undefined
            assertParamExists('gitbookAuthApiV1GitbookAuthPost', 'gitbookRedirectPath', gitbookRedirectPath)
            const localVarPath = `/api/v1/gitbook_auth/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gitbookRedirectPath, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GitbookApi - functional programming interface
 * @export
 */
export const GitbookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GitbookApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Gitbook docs URL with JWT to which the user should be redirected
         * @summary Gitbook Auth
         * @param {GitbookRedirectPath} gitbookRedirectPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath: GitbookRedirectPath, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitbookAuthenticatedURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GitbookApi.gitbookAuthApiV1GitbookAuthPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GitbookApi - factory interface
 * @export
 */
export const GitbookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GitbookApiFp(configuration)
    return {
        /**
         * Get Gitbook docs URL with JWT to which the user should be redirected
         * @summary Gitbook Auth
         * @param {GitbookRedirectPath} gitbookRedirectPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath: GitbookRedirectPath, options?: RawAxiosRequestConfig): AxiosPromise<GitbookAuthenticatedURL> {
            return localVarFp.gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GitbookApi - object-oriented interface
 * @export
 * @class GitbookApi
 * @extends {BaseAPI}
 */
export class GitbookApi extends BaseAPI {
    /**
     * Get Gitbook docs URL with JWT to which the user should be redirected
     * @summary Gitbook Auth
     * @param {GitbookRedirectPath} gitbookRedirectPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitbookApi
     */
    public gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath: GitbookRedirectPath, options?: RawAxiosRequestConfig) {
        return GitbookApiFp(this.configuration).gitbookAuthApiV1GitbookAuthPost(gitbookRedirectPath, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InstallationsApi - axios parameter creator
 * @export
 */
export const InstallationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes installation that have no corresponding repositories or produce a 404 on GitHub.
         * @summary Delete Missing
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissingApiV1InstallationsMissingDelete: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/installations/missing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an App token for an installation_id
         * @summary Get Installation Access Token
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationAccessTokenApiV1InstallationsAccessTokenGet: async (repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('getInstallationAccessTokenApiV1InstallationsAccessTokenGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/installations/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (repositoryId !== undefined) {
                localVarQueryParameter['repository_id'] = repositoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the installation for a specific repository name
         * @summary Get Installation From Full Repo Name
         * @param {string} fullRepoName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet: async (fullRepoName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fullRepoName' is not null or undefined
            assertParamExists('getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet', 'fullRepoName', fullRepoName)
            const localVarPath = `/api/v1/installations/{full_repo_name}`
                .replace(`{${"full_repo_name"}}`, encodeURIComponent(String(fullRepoName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all installations
         * @summary Get Installations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationsApiV1InstallationsGet: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/installations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationsApi - functional programming interface
 * @export
 */
export const InstallationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes installation that have no corresponding repositories or produce a 404 on GitHub.
         * @summary Delete Missing
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMissingApiV1InstallationsMissingDelete(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMissingApiV1InstallationsMissingDelete(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationsApi.deleteMissingApiV1InstallationsMissingDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an App token for an installation_id
         * @summary Get Installation Access Token
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstallationAccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationsApi.getInstallationAccessTokenApiV1InstallationsAccessTokenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the installation for a specific repository name
         * @summary Get Installation From Full Repo Name
         * @param {string} fullRepoName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Installation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationsApi.getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all installations
         * @summary Get Installations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallationsApiV1InstallationsGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Installation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallationsApiV1InstallationsGet(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InstallationsApi.getInstallationsApiV1InstallationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InstallationsApi - factory interface
 * @export
 */
export const InstallationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationsApiFp(configuration)
    return {
        /**
         * Removes installation that have no corresponding repositories or produce a 404 on GitHub.
         * @summary Delete Missing
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissingApiV1InstallationsMissingDelete(skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteMissingApiV1InstallationsMissingDelete(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an App token for an installation_id
         * @summary Get Installation Access Token
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<InstallationAccessToken> {
            return localVarFp.getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the installation for a specific repository name
         * @summary Get Installation From Full Repo Name
         * @param {string} fullRepoName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName: string, options?: RawAxiosRequestConfig): AxiosPromise<Installation> {
            return localVarFp.getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all installations
         * @summary Get Installations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallationsApiV1InstallationsGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Installation>> {
            return localVarFp.getInstallationsApiV1InstallationsGet(skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationsApi - object-oriented interface
 * @export
 * @class InstallationsApi
 * @extends {BaseAPI}
 */
export class InstallationsApi extends BaseAPI {
    /**
     * Removes installation that have no corresponding repositories or produce a 404 on GitHub.
     * @summary Delete Missing
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationsApi
     */
    public deleteMissingApiV1InstallationsMissingDelete(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return InstallationsApiFp(this.configuration).deleteMissingApiV1InstallationsMissingDelete(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an App token for an installation_id
     * @summary Get Installation Access Token
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationsApi
     */
    public getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId: string, options?: RawAxiosRequestConfig) {
        return InstallationsApiFp(this.configuration).getInstallationAccessTokenApiV1InstallationsAccessTokenGet(repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the installation for a specific repository name
     * @summary Get Installation From Full Repo Name
     * @param {string} fullRepoName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationsApi
     */
    public getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName: string, options?: RawAxiosRequestConfig) {
        return InstallationsApiFp(this.configuration).getInstallationFromFullRepoNameApiV1InstallationsFullRepoNameGet(fullRepoName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all installations
     * @summary Get Installations
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationsApi
     */
    public getInstallationsApiV1InstallationsGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return InstallationsApiFp(this.configuration).getInstallationsApiV1InstallationsGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KeysApi - axios parameter creator
 * @export
 */
export const KeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Api Key Validation
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyValidationApiV1KeysValidateGet: async (repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('apiKeyValidationApiV1KeysValidateGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/keys/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (repositoryId !== undefined) {
                localVarQueryParameter['repository_id'] = repositoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate key using branch / endpoint specific controls.  Turn off /validate once everyone has moved over.
         * @summary Api Key Validation V2
         * @param {ValidateKeyPayload} validateKeyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyValidationV2ApiV1KeysValidateKeyPost: async (validateKeyPayload: ValidateKeyPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateKeyPayload' is not null or undefined
            assertParamExists('apiKeyValidationV2ApiV1KeysValidateKeyPost', 'validateKeyPayload', validateKeyPayload)
            const localVarPath = `/api/v1/keys/validate_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateKeyPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new api key for a user and invalidate current key
         * @summary Create Key For User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKeyForUserApiV1KeysPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s api key
         * @summary Get Key By User Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyByUserIdApiV1KeysUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getKeyByUserIdApiV1KeysUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/keys/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s api key
         * @summary Get My Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyApiKeyApiV1KeysGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeysApi - functional programming interface
 * @export
 */
export const KeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Api Key Validation
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyValidationApiV1KeysValidateGet(repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyValidationApiV1KeysValidateGet(repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeysApi.apiKeyValidationApiV1KeysValidateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Validate key using branch / endpoint specific controls.  Turn off /validate once everyone has moved over.
         * @summary Api Key Validation V2
         * @param {ValidateKeyPayload} validateKeyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload: ValidateKeyPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeysApi.apiKeyValidationV2ApiV1KeysValidateKeyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new api key for a user and invalidate current key
         * @summary Create Key For User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKeyForUserApiV1KeysPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKeyForUserApiV1KeysPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeysApi.createKeyForUserApiV1KeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user\'s api key
         * @summary Get Key By User Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeyByUserIdApiV1KeysUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKeyByUserIdApiV1KeysUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeysApi.getKeyByUserIdApiV1KeysUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user\'s api key
         * @summary Get My Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyApiKeyApiV1KeysGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyApiKeyApiV1KeysGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KeysApi.getMyApiKeyApiV1KeysGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KeysApi - factory interface
 * @export
 */
export const KeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Api Key Validation
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyValidationApiV1KeysValidateGet(repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiKeyValidationApiV1KeysValidateGet(repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate key using branch / endpoint specific controls.  Turn off /validate once everyone has moved over.
         * @summary Api Key Validation V2
         * @param {ValidateKeyPayload} validateKeyPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload: ValidateKeyPayload, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new api key for a user and invalidate current key
         * @summary Create Key For User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKeyForUserApiV1KeysPost(options?: RawAxiosRequestConfig): AxiosPromise<UserApiKey> {
            return localVarFp.createKeyForUserApiV1KeysPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s api key
         * @summary Get Key By User Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeyByUserIdApiV1KeysUserIdGet(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.getKeyByUserIdApiV1KeysUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s api key
         * @summary Get My Api Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyApiKeyApiV1KeysGet(options?: RawAxiosRequestConfig): AxiosPromise<ApiKey> {
            return localVarFp.getMyApiKeyApiV1KeysGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeysApi - object-oriented interface
 * @export
 * @class KeysApi
 * @extends {BaseAPI}
 */
export class KeysApi extends BaseAPI {
    /**
     * 
     * @summary Api Key Validation
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public apiKeyValidationApiV1KeysValidateGet(repositoryId: string, options?: RawAxiosRequestConfig) {
        return KeysApiFp(this.configuration).apiKeyValidationApiV1KeysValidateGet(repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate key using branch / endpoint specific controls.  Turn off /validate once everyone has moved over.
     * @summary Api Key Validation V2
     * @param {ValidateKeyPayload} validateKeyPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload: ValidateKeyPayload, options?: RawAxiosRequestConfig) {
        return KeysApiFp(this.configuration).apiKeyValidationV2ApiV1KeysValidateKeyPost(validateKeyPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new api key for a user and invalidate current key
     * @summary Create Key For User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public createKeyForUserApiV1KeysPost(options?: RawAxiosRequestConfig) {
        return KeysApiFp(this.configuration).createKeyForUserApiV1KeysPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s api key
     * @summary Get Key By User Id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public getKeyByUserIdApiV1KeysUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return KeysApiFp(this.configuration).getKeyByUserIdApiV1KeysUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s api key
     * @summary Get My Api Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public getMyApiKeyApiV1KeysGet(options?: RawAxiosRequestConfig) {
        return KeysApiFp(this.configuration).getMyApiKeyApiV1KeysGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OAuth2 compatible token refresh. Creates a access token that is marked as non-fresh
         * @summary Auth Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshApiV1LoginRefreshPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {number} [expiresSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenApiV1LoginAccessTokenPost: async (expiresSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (expiresSeconds !== undefined) {
                localVarQueryParameter['expires_seconds'] = expiresSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Social Access Token
         * @param {LoginOrRegisterMessage} loginOrRegisterMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialAccessTokenApiV1LoginSocialAccessTokenPost: async (loginOrRegisterMessage: LoginOrRegisterMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginOrRegisterMessage' is not null or undefined
            assertParamExists('socialAccessTokenApiV1LoginSocialAccessTokenPost', 'loginOrRegisterMessage', loginOrRegisterMessage)
            const localVarPath = `/api/v1/login/social-access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginOrRegisterMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SSO Discovery
         * @summary Sso Discovery
         * @param {TaktileApiCoreTAvailableAuthSources} [providerName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoDiscoveryApiV1LoginSsoDiscoveryGet: async (providerName?: TaktileApiCoreTAvailableAuthSources, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/sso-discovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (providerName !== undefined) {
                localVarQueryParameter['provider_name'] = providerName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * OAuth2 compatible token refresh. Creates a access token that is marked as non-fresh
         * @summary Auth Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefreshApiV1LoginRefreshPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWithUserAndKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefreshApiV1LoginRefreshPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.authRefreshApiV1LoginRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {number} [expiresSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWithUserAndKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginAccessTokenApiV1LoginAccessTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Social Access Token
         * @param {LoginOrRegisterMessage} loginOrRegisterMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage: LoginOrRegisterMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenWithUserAndKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.socialAccessTokenApiV1LoginSocialAccessTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * SSO Discovery
         * @summary Sso Discovery
         * @param {TaktileApiCoreTAvailableAuthSources} [providerName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName?: TaktileApiCoreTAvailableAuthSources, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SSODiscoveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.ssoDiscoveryApiV1LoginSsoDiscoveryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * OAuth2 compatible token refresh. Creates a access token that is marked as non-fresh
         * @summary Auth Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshApiV1LoginRefreshPost(options?: RawAxiosRequestConfig): AxiosPromise<TokenWithUserAndKey> {
            return localVarFp.authRefreshApiV1LoginRefreshPost(options).then((request) => request(axios, basePath));
        },
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {number} [expiresSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds?: number, options?: RawAxiosRequestConfig): AxiosPromise<TokenWithUserAndKey> {
            return localVarFp.loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Social Access Token
         * @param {LoginOrRegisterMessage} loginOrRegisterMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage: LoginOrRegisterMessage, options?: RawAxiosRequestConfig): AxiosPromise<TokenWithUserAndKey> {
            return localVarFp.socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * SSO Discovery
         * @summary Sso Discovery
         * @param {TaktileApiCoreTAvailableAuthSources} [providerName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName?: TaktileApiCoreTAvailableAuthSources, email?: string, options?: RawAxiosRequestConfig): AxiosPromise<SSODiscoveryResponse> {
            return localVarFp.ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * OAuth2 compatible token refresh. Creates a access token that is marked as non-fresh
     * @summary Auth Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public authRefreshApiV1LoginRefreshPost(options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).authRefreshApiV1LoginRefreshPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OAuth2 compatible token login, get an access token for future requests
     * @summary Login Access Token
     * @param {number} [expiresSeconds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds?: number, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginAccessTokenApiV1LoginAccessTokenPost(expiresSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Social Access Token
     * @param {LoginOrRegisterMessage} loginOrRegisterMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage: LoginOrRegisterMessage, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).socialAccessTokenApiV1LoginSocialAccessTokenPost(loginOrRegisterMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SSO Discovery
     * @summary Sso Discovery
     * @param {TaktileApiCoreTAvailableAuthSources} [providerName] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName?: TaktileApiCoreTAvailableAuthSources, email?: string, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).ssoDiscoveryApiV1LoginSsoDiscoveryGet(providerName, email, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send Mail
         * @param {CreateEmail} createEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailApiV1EmailsPost: async (createEmail: CreateEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmail' is not null or undefined
            assertParamExists('sendMailApiV1EmailsPost', 'createEmail', createEmail)
            const localVarPath = `/api/v1/emails/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send Mail
         * @param {CreateEmail} createEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMailApiV1EmailsPost(createEmail: CreateEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMailApiV1EmailsPost(createEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.sendMailApiV1EmailsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Send Mail
         * @param {CreateEmail} createEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailApiV1EmailsPost(createEmail: CreateEmail, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendMailApiV1EmailsPost(createEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Send Mail
     * @param {CreateEmail} createEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public sendMailApiV1EmailsPost(createEmail: CreateEmail, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).sendMailApiV1EmailsPost(createEmail, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationSimpleRolesApi - axios parameter creator
 * @export
 */
export const OrganizationSimpleRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve simple role for a User in given organization
         * @summary Get Me
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/simple_roles/me`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSimpleRolesApi - functional programming interface
 * @export
 */
export const OrganizationSimpleRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSimpleRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve simple role for a User in given organization
         * @summary Get Me
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationSimpleRolesApi.getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationSimpleRolesApi - factory interface
 * @export
 */
export const OrganizationSimpleRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSimpleRolesApiFp(configuration)
    return {
        /**
         * Retrieve simple role for a User in given organization
         * @summary Get Me
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<SimpleRole> {
            return localVarFp.getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationSimpleRolesApi - object-oriented interface
 * @export
 * @class OrganizationSimpleRolesApi
 * @extends {BaseAPI}
 */
export class OrganizationSimpleRolesApi extends BaseAPI {
    /**
     * Retrieve simple role for a User in given organization
     * @summary Get Me
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSimpleRolesApi
     */
    public getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationSimpleRolesApiFp(this.configuration).getMeApiV1OrganizationsOrganizationIdSimpleRolesMeGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationUsersApi - axios parameter creator
 * @export
 */
export const OrganizationUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiV1OrganizationsOrgIdUsersUserIdGet: async (orgId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getUserApiV1OrganizationsOrgIdUsersUserIdGet', 'orgId', orgId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserApiV1OrganizationsOrgIdUsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/organizations/{org_id}/users/{user_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve users, only available for admins (users with write access to the org)
         * @summary Get Users
         * @param {string} orgId 
         * @param {boolean} [includeDeactivated] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1OrganizationsOrgIdUsersGet: async (orgId: string, includeDeactivated?: boolean, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getUsersApiV1OrganizationsOrgIdUsersGet', 'orgId', orgId)
            const localVarPath = `/api/v1/organizations/{org_id}/users/`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeDeactivated !== undefined) {
                localVarQueryParameter['include_deactivated'] = includeDeactivated;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update/remove user role within organization.
         * @summary Update User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserUpdate} organizationUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1OrganizationsOrgIdUsersUserIdPatch: async (orgId: string, userId: string, organizationUserUpdate: OrganizationUserUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('updateUserApiV1OrganizationsOrgIdUsersUserIdPatch', 'orgId', orgId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserApiV1OrganizationsOrgIdUsersUserIdPatch', 'userId', userId)
            // verify required parameter 'organizationUserUpdate' is not null or undefined
            assertParamExists('updateUserApiV1OrganizationsOrgIdUsersUserIdPatch', 'organizationUserUpdate', organizationUserUpdate)
            const localVarPath = `/api/v1/organizations/{org_id}/users/{user_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUserUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update/remove user role within workspace.
         * @summary Update User Ws Roles
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserWSRolesUpdate} organizationUserWSRolesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch: async (orgId: string, userId: string, organizationUserWSRolesUpdate: OrganizationUserWSRolesUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch', 'orgId', orgId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch', 'userId', userId)
            // verify required parameter 'organizationUserWSRolesUpdate' is not null or undefined
            assertParamExists('updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch', 'organizationUserWSRolesUpdate', organizationUserWSRolesUpdate)
            const localVarPath = `/api/v1/organizations/{org_id}/users/{user_id}/ws_roles`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUserWSRolesUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationUsersApi - functional programming interface
 * @export
 */
export const OrganizationUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationUsersApi.getUserApiV1OrganizationsOrgIdUsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve users, only available for admins (users with write access to the org)
         * @summary Get Users
         * @param {string} orgId 
         * @param {boolean} [includeDeactivated] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersApiV1OrganizationsOrgIdUsersGet(orgId: string, includeDeactivated?: boolean, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiV1OrganizationsOrgIdUsersGet(orgId, includeDeactivated, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationUsersApi.getUsersApiV1OrganizationsOrgIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update/remove user role within organization.
         * @summary Update User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserUpdate} organizationUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId: string, userId: string, organizationUserUpdate: OrganizationUserUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId, userId, organizationUserUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationUsersApi.updateUserApiV1OrganizationsOrgIdUsersUserIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update/remove user role within workspace.
         * @summary Update User Ws Roles
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserWSRolesUpdate} organizationUserWSRolesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId: string, userId: string, organizationUserWSRolesUpdate: OrganizationUserWSRolesUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId, userId, organizationUserWSRolesUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationUsersApi.updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationUsersApi - factory interface
 * @export
 */
export const OrganizationUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve users, only available for admins (users with write access to the org)
         * @summary Get Users
         * @param {string} orgId 
         * @param {boolean} [includeDeactivated] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1OrganizationsOrgIdUsersGet(orgId: string, includeDeactivated?: boolean, skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationUser>> {
            return localVarFp.getUsersApiV1OrganizationsOrgIdUsersGet(orgId, includeDeactivated, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update/remove user role within organization.
         * @summary Update User
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserUpdate} organizationUserUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId: string, userId: string, organizationUserUpdate: OrganizationUserUpdate, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId, userId, organizationUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update/remove user role within workspace.
         * @summary Update User Ws Roles
         * @param {string} orgId 
         * @param {string} userId 
         * @param {OrganizationUserWSRolesUpdate} organizationUserWSRolesUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId: string, userId: string, organizationUserWSRolesUpdate: OrganizationUserWSRolesUpdate, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId, userId, organizationUserWSRolesUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationUsersApi - object-oriented interface
 * @export
 * @class OrganizationUsersApi
 * @extends {BaseAPI}
 */
export class OrganizationUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get User
     * @param {string} orgId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationUsersApi
     */
    public getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId: string, userId: string, options?: RawAxiosRequestConfig) {
        return OrganizationUsersApiFp(this.configuration).getUserApiV1OrganizationsOrgIdUsersUserIdGet(orgId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve users, only available for admins (users with write access to the org)
     * @summary Get Users
     * @param {string} orgId 
     * @param {boolean} [includeDeactivated] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationUsersApi
     */
    public getUsersApiV1OrganizationsOrgIdUsersGet(orgId: string, includeDeactivated?: boolean, skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OrganizationUsersApiFp(this.configuration).getUsersApiV1OrganizationsOrgIdUsersGet(orgId, includeDeactivated, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update/remove user role within organization.
     * @summary Update User
     * @param {string} orgId 
     * @param {string} userId 
     * @param {OrganizationUserUpdate} organizationUserUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationUsersApi
     */
    public updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId: string, userId: string, organizationUserUpdate: OrganizationUserUpdate, options?: RawAxiosRequestConfig) {
        return OrganizationUsersApiFp(this.configuration).updateUserApiV1OrganizationsOrgIdUsersUserIdPatch(orgId, userId, organizationUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update/remove user role within workspace.
     * @summary Update User Ws Roles
     * @param {string} orgId 
     * @param {string} userId 
     * @param {OrganizationUserWSRolesUpdate} organizationUserWSRolesUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationUsersApi
     */
    public updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId: string, userId: string, organizationUserWSRolesUpdate: OrganizationUserWSRolesUpdate, options?: RawAxiosRequestConfig) {
        return OrganizationUsersApiFp(this.configuration).updateUserWsRolesApiV1OrganizationsOrgIdUsersUserIdWsRolesPatch(orgId, userId, organizationUserWSRolesUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationWsServiceAccountApi - axios parameter creator
 * @export
 */
export const OrganizationWsServiceAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new service account
         * @summary Create Service Account
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {WorkspaceServiceAccountPost} workspaceServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost: async (workspaceId: string, organizationId: string, workspaceServiceAccountPost: WorkspaceServiceAccountPost, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost', 'workspaceId', workspaceId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost', 'organizationId', organizationId)
            // verify required parameter 'workspaceServiceAccountPost' is not null or undefined
            assertParamExists('createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost', 'workspaceServiceAccountPost', workspaceServiceAccountPost)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/api_keys`
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceServiceAccountPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a service account
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete: async (organizationId: string, workspaceId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete', 'accountId', accountId)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/api_keys/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve service accounts of a workspace
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalApiKeys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet: async (organizationId: string, workspaceId: string, includeInternalApiKeys?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/api_keys`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeInternalApiKeys !== undefined) {
                localVarQueryParameter['include_internal_api_keys'] = includeInternalApiKeys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a service account on a workspace
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet: async (organizationId: string, workspaceId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet', 'accountId', accountId)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/api_keys/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch service account
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {WorkspaceServiceAccountPatch} workspaceServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch: async (organizationId: string, workspaceId: string, accountId: string, workspaceServiceAccountPatch: WorkspaceServiceAccountPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch', 'accountId', accountId)
            // verify required parameter 'workspaceServiceAccountPatch' is not null or undefined
            assertParamExists('patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch', 'workspaceServiceAccountPatch', workspaceServiceAccountPatch)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/api_keys/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceServiceAccountPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationWsServiceAccountApi - functional programming interface
 * @export
 */
export const OrganizationWsServiceAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationWsServiceAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new service account
         * @summary Create Service Account
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {WorkspaceServiceAccountPost} workspaceServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId: string, organizationId: string, workspaceServiceAccountPost: WorkspaceServiceAccountPost, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceServiceAccountWithKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId, organizationId, workspaceServiceAccountPost, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsServiceAccountApi.createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a service account
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId, workspaceId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsServiceAccountApi.deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve service accounts of a workspace
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalApiKeys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId: string, workspaceId: string, includeInternalApiKeys?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceServiceAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId, workspaceId, includeInternalApiKeys, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsServiceAccountApi.getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a service account on a workspace
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId, workspaceId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsServiceAccountApi.getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch service account
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {WorkspaceServiceAccountPatch} workspaceServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId: string, workspaceId: string, accountId: string, workspaceServiceAccountPatch: WorkspaceServiceAccountPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId, workspaceId, accountId, workspaceServiceAccountPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsServiceAccountApi.patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationWsServiceAccountApi - factory interface
 * @export
 */
export const OrganizationWsServiceAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationWsServiceAccountApiFp(configuration)
    return {
        /**
         * Create a new service account
         * @summary Create Service Account
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {WorkspaceServiceAccountPost} workspaceServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId: string, organizationId: string, workspaceServiceAccountPost: WorkspaceServiceAccountPost, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceServiceAccountWithKey> {
            return localVarFp.createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId, organizationId, workspaceServiceAccountPost, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a service account
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId, workspaceId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve service accounts of a workspace
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {boolean} [includeInternalApiKeys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId: string, workspaceId: string, includeInternalApiKeys?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkspaceServiceAccount>> {
            return localVarFp.getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId, workspaceId, includeInternalApiKeys, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a service account on a workspace
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceServiceAccount> {
            return localVarFp.getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId, workspaceId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch service account
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {string} accountId 
         * @param {WorkspaceServiceAccountPatch} workspaceServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId: string, workspaceId: string, accountId: string, workspaceServiceAccountPatch: WorkspaceServiceAccountPatch, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceServiceAccount> {
            return localVarFp.patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId, workspaceId, accountId, workspaceServiceAccountPatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationWsServiceAccountApi - object-oriented interface
 * @export
 * @class OrganizationWsServiceAccountApi
 * @extends {BaseAPI}
 */
export class OrganizationWsServiceAccountApi extends BaseAPI {
    /**
     * Create a new service account
     * @summary Create Service Account
     * @param {string} workspaceId 
     * @param {string} organizationId 
     * @param {WorkspaceServiceAccountPost} workspaceServiceAccountPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsServiceAccountApi
     */
    public createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId: string, organizationId: string, workspaceServiceAccountPost: WorkspaceServiceAccountPost, options?: RawAxiosRequestConfig) {
        return OrganizationWsServiceAccountApiFp(this.configuration).createServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysPost(workspaceId, organizationId, workspaceServiceAccountPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a service account
     * @summary Delete Service Account
     * @param {string} organizationId 
     * @param {string} workspaceId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsServiceAccountApi
     */
    public deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig) {
        return OrganizationWsServiceAccountApiFp(this.configuration).deleteServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdDelete(organizationId, workspaceId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve service accounts of a workspace
     * @summary Get All Service Accounts
     * @param {string} organizationId 
     * @param {string} workspaceId 
     * @param {boolean} [includeInternalApiKeys] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsServiceAccountApi
     */
    public getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId: string, workspaceId: string, includeInternalApiKeys?: boolean, options?: RawAxiosRequestConfig) {
        return OrganizationWsServiceAccountApiFp(this.configuration).getAllServiceAccountsApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysGet(organizationId, workspaceId, includeInternalApiKeys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a service account on a workspace
     * @summary Get Service Account
     * @param {string} organizationId 
     * @param {string} workspaceId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsServiceAccountApi
     */
    public getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId: string, workspaceId: string, accountId: string, options?: RawAxiosRequestConfig) {
        return OrganizationWsServiceAccountApiFp(this.configuration).getServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdGet(organizationId, workspaceId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch service account
     * @summary Patch Service Account
     * @param {string} organizationId 
     * @param {string} workspaceId 
     * @param {string} accountId 
     * @param {WorkspaceServiceAccountPatch} workspaceServiceAccountPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsServiceAccountApi
     */
    public patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId: string, workspaceId: string, accountId: string, workspaceServiceAccountPatch: WorkspaceServiceAccountPatch, options?: RawAxiosRequestConfig) {
        return OrganizationWsServiceAccountApiFp(this.configuration).patchServiceAccountApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdApiKeysAccountIdPatch(organizationId, workspaceId, accountId, workspaceServiceAccountPatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationWsUsersApi - axios parameter creator
 * @export
 */
export const OrganizationWsUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Users
         * @param {string} orgId 
         * @param {string} workspaceId 
         * @param {boolean} [includeDeactivated] 
         * @param {Set<WorkspaceSimpleRoleType>} [workspaceRoleFilters] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet: async (orgId: string, workspaceId: string, includeDeactivated?: boolean, workspaceRoleFilters?: Set<WorkspaceSimpleRoleType>, skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet', 'orgId', orgId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/organizations/{org_id}/workspaces/{workspace_id}/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (includeDeactivated !== undefined) {
                localVarQueryParameter['include_deactivated'] = includeDeactivated;
            }

            if (workspaceRoleFilters) {
                localVarQueryParameter['workspace_role_filters'] = Array.from(workspaceRoleFilters);
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationWsUsersApi - functional programming interface
 * @export
 */
export const OrganizationWsUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationWsUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Users
         * @param {string} orgId 
         * @param {string} workspaceId 
         * @param {boolean} [includeDeactivated] 
         * @param {Set<WorkspaceSimpleRoleType>} [workspaceRoleFilters] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId: string, workspaceId: string, includeDeactivated?: boolean, workspaceRoleFilters?: Set<WorkspaceSimpleRoleType>, skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId, workspaceId, includeDeactivated, workspaceRoleFilters, skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationWsUsersApi.getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationWsUsersApi - factory interface
 * @export
 */
export const OrganizationWsUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationWsUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Users
         * @param {string} orgId 
         * @param {string} workspaceId 
         * @param {boolean} [includeDeactivated] 
         * @param {Set<WorkspaceSimpleRoleType>} [workspaceRoleFilters] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId: string, workspaceId: string, includeDeactivated?: boolean, workspaceRoleFilters?: Set<WorkspaceSimpleRoleType>, skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationUser>> {
            return localVarFp.getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId, workspaceId, includeDeactivated, workspaceRoleFilters, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationWsUsersApi - object-oriented interface
 * @export
 * @class OrganizationWsUsersApi
 * @extends {BaseAPI}
 */
export class OrganizationWsUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get Users
     * @param {string} orgId 
     * @param {string} workspaceId 
     * @param {boolean} [includeDeactivated] 
     * @param {Set<WorkspaceSimpleRoleType>} [workspaceRoleFilters] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationWsUsersApi
     */
    public getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId: string, workspaceId: string, includeDeactivated?: boolean, workspaceRoleFilters?: Set<WorkspaceSimpleRoleType>, skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OrganizationWsUsersApiFp(this.configuration).getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(orgId, workspaceId, includeDeactivated, workspaceRoleFilters, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add organization
         * @summary Add Organization
         * @param {OrganizationCreate} organizationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationApiV1OrganizationsPost: async (organizationCreate: OrganizationCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationCreate' is not null or undefined
            assertParamExists('addOrganizationApiV1OrganizationsPost', 'organizationCreate', organizationCreate)
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete auth source for organization.  auth_source_id is the id of the OrganizationAuthSource object, not the id of UserAuthSource.
         * @summary Delete Organization Auth Source
         * @param {string} organizationId 
         * @param {string} authSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete: async (organizationId: string, authSourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete', 'organizationId', organizationId)
            // verify required parameter 'authSourceId' is not null or undefined
            assertParamExists('deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete', 'authSourceId', authSourceId)
            const localVarPath = `/api/v1/organizations/{organization_id}/auth_sources/{auth_source_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"auth_source_id"}}`, encodeURIComponent(String(authSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete invite for organization.
         * @summary Delete Organization Invite
         * @param {string} organizationId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete: async (organizationId: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete', 'organizationId', organizationId)
            // verify required parameter 'inviteId' is not null or undefined
            assertParamExists('deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete', 'inviteId', inviteId)
            const localVarPath = `/api/v1/organizations/{organization_id}/invites/{invite_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete logo for organization.
         * @summary Delete Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/logo`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve simple roles for a User in given organization and workspace
         * @summary Get Me Ws Roles
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet: async (organizationId: string, workspaceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet', 'organizationId', organizationId)
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet', 'workspaceId', workspaceId)
            const localVarPath = `/api/v1/organizations/{organization_id}/workspaces/{workspace_id}/ws_roles/me`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"workspace_id"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve my organizations.
         * @summary Get My Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationsApiV1OrganizationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve organization.
         * @summary Get Organization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationApiV1OrganizationsOrganizationIdGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationApiV1OrganizationsOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all auth sources for organization.
         * @summary Get Organization Auth Sources
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/auth_sources`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all invites for organization.  DEPRECATED
         * @summary Get Organization Invites
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/invites`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve logo for organization.
         * @summary Get Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/logo`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all organizations.
         * @summary Get Organizations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiV1OrganizationsAllGet: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch organization
         * @summary Patch Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationApiV1OrganizationsOrganizationIdPatch: async (organizationId: string, organizationUpdate: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchOrganizationApiV1OrganizationsOrganizationIdPatch', 'organizationId', organizationId)
            // verify required parameter 'organizationUpdate' is not null or undefined
            assertParamExists('patchOrganizationApiV1OrganizationsOrganizationIdPatch', 'organizationUpdate', organizationUpdate)
            const localVarPath = `/api/v1/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create auth source for organization.
         * @summary Post Organization Auth Source
         * @param {string} organizationId 
         * @param {OrganizationAuthSourceCreateNamed} organizationAuthSourceCreateNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost: async (organizationId: string, organizationAuthSourceCreateNamed: OrganizationAuthSourceCreateNamed, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost', 'organizationId', organizationId)
            // verify required parameter 'organizationAuthSourceCreateNamed' is not null or undefined
            assertParamExists('postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost', 'organizationAuthSourceCreateNamed', organizationAuthSourceCreateNamed)
            const localVarPath = `/api/v1/organizations/{organization_id}/auth_sources`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationAuthSourceCreateNamed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create invite for organization. Note that for Google users, the username is the email address. Whereas for Microsoft and Okta users, the username is the part before the @ of the email.
         * @summary Post Organization Invite
         * @param {string} organizationId 
         * @param {UserOrganizationInviteData} userOrganizationInviteData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost: async (organizationId: string, userOrganizationInviteData: UserOrganizationInviteData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost', 'organizationId', organizationId)
            // verify required parameter 'userOrganizationInviteData' is not null or undefined
            assertParamExists('postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost', 'userOrganizationInviteData', userOrganizationInviteData)
            const localVarPath = `/api/v1/organizations/{organization_id}/invites`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userOrganizationInviteData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Store logo for organization from multipart form data.
         * @summary Post Organization Logo
         * @param {string} organizationId 
         * @param {File} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost: async (organizationId: string, logo: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost', 'organizationId', organizationId)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost', 'logo', logo)
            const localVarPath = `/api/v1/organizations/{organization_id}/logo`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add organization
         * @summary Add Organization
         * @param {OrganizationCreate} organizationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationApiV1OrganizationsPost(organizationCreate: OrganizationCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationApiV1OrganizationsPost(organizationCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.addOrganizationApiV1OrganizationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete auth source for organization.  auth_source_id is the id of the OrganizationAuthSource object, not the id of UserAuthSource.
         * @summary Delete Organization Auth Source
         * @param {string} organizationId 
         * @param {string} authSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId: string, authSourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId, authSourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete invite for organization.
         * @summary Delete Organization Invite
         * @param {string} organizationId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId, inviteId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete logo for organization.
         * @summary Delete Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve simple roles for a User in given organization and workspace
         * @summary Get Me Ws Roles
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId: string, workspaceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSimpleRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId, workspaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve my organizations.
         * @summary Get My Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyOrganizationsApiV1OrganizationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyOrganizationsApiV1OrganizationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getMyOrganizationsApiV1OrganizationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve organization.
         * @summary Get Organization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationApiV1OrganizationsOrganizationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all auth sources for organization.
         * @summary Get Organization Auth Sources
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationAuthSourceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all invites for organization.  DEPRECATED
         * @summary Get Organization Invites
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOrganizationInviteResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve logo for organization.
         * @summary Get Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all organizations.
         * @summary Get Organizations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsApiV1OrganizationsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsApiV1OrganizationsAllGet(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationsApiV1OrganizationsAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch organization
         * @summary Patch Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId: string, organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId, organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.patchOrganizationApiV1OrganizationsOrganizationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create auth source for organization.
         * @summary Post Organization Auth Source
         * @param {string} organizationId 
         * @param {OrganizationAuthSourceCreateNamed} organizationAuthSourceCreateNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId: string, organizationAuthSourceCreateNamed: OrganizationAuthSourceCreateNamed, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAuthSourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId, organizationAuthSourceCreateNamed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create invite for organization. Note that for Google users, the username is the email address. Whereas for Microsoft and Okta users, the username is the part before the @ of the email.
         * @summary Post Organization Invite
         * @param {string} organizationId 
         * @param {UserOrganizationInviteData} userOrganizationInviteData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId: string, userOrganizationInviteData: UserOrganizationInviteData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrganizationInviteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId, userOrganizationInviteData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Store logo for organization from multipart form data.
         * @summary Post Organization Logo
         * @param {string} organizationId 
         * @param {File} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId: string, logo: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId, logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Add organization
         * @summary Add Organization
         * @param {OrganizationCreate} organizationCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationApiV1OrganizationsPost(organizationCreate: OrganizationCreate, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationInDB> {
            return localVarFp.addOrganizationApiV1OrganizationsPost(organizationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete auth source for organization.  auth_source_id is the id of the OrganizationAuthSource object, not the id of UserAuthSource.
         * @summary Delete Organization Auth Source
         * @param {string} organizationId 
         * @param {string} authSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId: string, authSourceId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId, authSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete invite for organization.
         * @summary Delete Organization Invite
         * @param {string} organizationId 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId: string, inviteId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId, inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete logo for organization.
         * @summary Delete Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationLogoResponse> {
            return localVarFp.deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve simple roles for a User in given organization and workspace
         * @summary Get Me Ws Roles
         * @param {string} organizationId 
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId: string, workspaceId: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkspaceSimpleRole> {
            return localVarFp.getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId, workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve my organizations.
         * @summary Get My Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationsApiV1OrganizationsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationInDB>> {
            return localVarFp.getMyOrganizationsApiV1OrganizationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve organization.
         * @summary Get Organization
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationInDB> {
            return localVarFp.getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all auth sources for organization.
         * @summary Get Organization Auth Sources
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationAuthSourceResponse>> {
            return localVarFp.getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all invites for organization.  DEPRECATED
         * @summary Get Organization Invites
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserOrganizationInviteResponse>> {
            return localVarFp.getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve logo for organization.
         * @summary Get Organization Logo
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationLogoResponse> {
            return localVarFp.getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all organizations.
         * @summary Get Organizations
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiV1OrganizationsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrganizationInDB>> {
            return localVarFp.getOrganizationsApiV1OrganizationsAllGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch organization
         * @summary Patch Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId: string, organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationInDB> {
            return localVarFp.patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId, organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Create auth source for organization.
         * @summary Post Organization Auth Source
         * @param {string} organizationId 
         * @param {OrganizationAuthSourceCreateNamed} organizationAuthSourceCreateNamed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId: string, organizationAuthSourceCreateNamed: OrganizationAuthSourceCreateNamed, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationAuthSourceResponse> {
            return localVarFp.postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId, organizationAuthSourceCreateNamed, options).then((request) => request(axios, basePath));
        },
        /**
         * Create invite for organization. Note that for Google users, the username is the email address. Whereas for Microsoft and Okta users, the username is the part before the @ of the email.
         * @summary Post Organization Invite
         * @param {string} organizationId 
         * @param {UserOrganizationInviteData} userOrganizationInviteData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId: string, userOrganizationInviteData: UserOrganizationInviteData, options?: RawAxiosRequestConfig): AxiosPromise<UserOrganizationInviteResponse> {
            return localVarFp.postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId, userOrganizationInviteData, options).then((request) => request(axios, basePath));
        },
        /**
         * Store logo for organization from multipart form data.
         * @summary Post Organization Logo
         * @param {string} organizationId 
         * @param {File} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId: string, logo: File, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationLogoResponse> {
            return localVarFp.postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId, logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * Add organization
     * @summary Add Organization
     * @param {OrganizationCreate} organizationCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public addOrganizationApiV1OrganizationsPost(organizationCreate: OrganizationCreate, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).addOrganizationApiV1OrganizationsPost(organizationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete auth source for organization.  auth_source_id is the id of the OrganizationAuthSource object, not the id of UserAuthSource.
     * @summary Delete Organization Auth Source
     * @param {string} organizationId 
     * @param {string} authSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId: string, authSourceId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(organizationId, authSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete invite for organization.
     * @summary Delete Organization Invite
     * @param {string} organizationId 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId: string, inviteId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganizationInviteApiV1OrganizationsOrganizationIdInvitesInviteIdDelete(organizationId, inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete logo for organization.
     * @summary Delete Organization Logo
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve simple roles for a User in given organization and workspace
     * @summary Get Me Ws Roles
     * @param {string} organizationId 
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId: string, workspaceId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getMeWsRolesApiV1OrganizationsOrganizationIdWorkspacesWorkspaceIdWsRolesMeGet(organizationId, workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve my organizations.
     * @summary Get My Organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getMyOrganizationsApiV1OrganizationsGet(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getMyOrganizationsApiV1OrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve organization.
     * @summary Get Organization
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationApiV1OrganizationsOrganizationIdGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all auth sources for organization.
     * @summary Get Organization Auth Sources
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all invites for organization.  DEPRECATED
     * @summary Get Organization Invites
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationInvitesApiV1OrganizationsOrganizationIdInvitesGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve logo for organization.
     * @summary Get Organization Logo
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all organizations.
     * @summary Get Organizations
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsApiV1OrganizationsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsApiV1OrganizationsAllGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch organization
     * @summary Patch Organization
     * @param {string} organizationId 
     * @param {OrganizationUpdate} organizationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId: string, organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).patchOrganizationApiV1OrganizationsOrganizationIdPatch(organizationId, organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create auth source for organization.
     * @summary Post Organization Auth Source
     * @param {string} organizationId 
     * @param {OrganizationAuthSourceCreateNamed} organizationAuthSourceCreateNamed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId: string, organizationAuthSourceCreateNamed: OrganizationAuthSourceCreateNamed, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(organizationId, organizationAuthSourceCreateNamed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create invite for organization. Note that for Google users, the username is the email address. Whereas for Microsoft and Okta users, the username is the part before the @ of the email.
     * @summary Post Organization Invite
     * @param {string} organizationId 
     * @param {UserOrganizationInviteData} userOrganizationInviteData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId: string, userOrganizationInviteData: UserOrganizationInviteData, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postOrganizationInviteApiV1OrganizationsOrganizationIdInvitesPost(organizationId, userOrganizationInviteData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Store logo for organization from multipart form data.
     * @summary Post Organization Logo
     * @param {string} organizationId 
     * @param {File} logo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId: string, logo: File, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(organizationId, logo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create role in bulk
         * @summary Create Bulk Roles
         * @param {string} userId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkRolesApiV1RolesBulkUserIdPost: async (userId: string, roleParams: Array<RoleParams>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createBulkRolesApiV1RolesBulkUserIdPost', 'userId', userId)
            // verify required parameter 'roleParams' is not null or undefined
            assertParamExists('createBulkRolesApiV1RolesBulkUserIdPost', 'roleParams', roleParams)
            const localVarPath = `/api/v1/roles/bulk/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create role in bulk
         * @summary Create Bulk Team Roles
         * @param {string} teamId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost: async (teamId: string, roleParams: Array<RoleParams>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost', 'teamId', teamId)
            // verify required parameter 'roleParams' is not null or undefined
            assertParamExists('createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost', 'roleParams', roleParams)
            const localVarPath = `/api/v1/roles/team/bulk/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a role
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleApiV1RolesPost: async (roleCreate: RoleCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreate' is not null or undefined
            assertParamExists('createRoleApiV1RolesPost', 'roleCreate', roleCreate)
            const localVarPath = `/api/v1/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a role
         * @summary Create Team Role
         * @param {TeamRoleCreate} teamRoleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamRoleApiV1RolesTeamPost: async (teamRoleCreate: TeamRoleCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamRoleCreate' is not null or undefined
            assertParamExists('createTeamRoleApiV1RolesTeamPost', 'teamRoleCreate', teamRoleCreate)
            const localVarPath = `/api/v1/roles/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamRoleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleApiV1RolesRoleIdDelete: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRoleApiV1RolesRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/api/v1/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleByTeamApiV1RolesTeamTeamIdGet: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getRoleByTeamApiV1RolesTeamTeamIdGet', 'teamId', teamId)
            const localVarPath = `/api/v1/roles/team/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleByUserApiV1RolesUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getRoleByUserApiV1RolesUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/roles/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create role in bulk
         * @summary Create Bulk Roles
         * @param {string} userId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkRolesApiV1RolesBulkUserIdPost(userId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkRolesApiV1RolesBulkUserIdPost(userId, roleParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createBulkRolesApiV1RolesBulkUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create role in bulk
         * @summary Create Bulk Team Roles
         * @param {string} teamId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamRoleInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId, roleParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a role
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleApiV1RolesPost(roleCreate: RoleCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleApiV1RolesPost(roleCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createRoleApiV1RolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a role
         * @summary Create Team Role
         * @param {TeamRoleCreate} teamRoleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeamRoleApiV1RolesTeamPost(teamRoleCreate: TeamRoleCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamRoleInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeamRoleApiV1RolesTeamPost(teamRoleCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createTeamRoleApiV1RolesTeamPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a role
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleApiV1RolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleApiV1RolesRoleIdDelete(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.deleteRoleApiV1RolesRoleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleByTeamApiV1RolesTeamTeamIdGet(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamRoleInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleByTeamApiV1RolesTeamTeamIdGet(teamId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getRoleByTeamApiV1RolesTeamTeamIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleByUserApiV1RolesUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleByUserApiV1RolesUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getRoleByUserApiV1RolesUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * Create role in bulk
         * @summary Create Bulk Roles
         * @param {string} userId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkRolesApiV1RolesBulkUserIdPost(userId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createBulkRolesApiV1RolesBulkUserIdPost(userId, roleParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Create role in bulk
         * @summary Create Bulk Team Roles
         * @param {string} teamId 
         * @param {Array<RoleParams>} roleParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamRoleInDB>> {
            return localVarFp.createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId, roleParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a role
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleApiV1RolesPost(roleCreate: RoleCreate, options?: RawAxiosRequestConfig): AxiosPromise<RoleInDB> {
            return localVarFp.createRoleApiV1RolesPost(roleCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a role
         * @summary Create Team Role
         * @param {TeamRoleCreate} teamRoleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamRoleApiV1RolesTeamPost(teamRoleCreate: TeamRoleCreate, options?: RawAxiosRequestConfig): AxiosPromise<TeamRoleInDB> {
            return localVarFp.createTeamRoleApiV1RolesTeamPost(teamRoleCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleApiV1RolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<RoleInDB> {
            return localVarFp.deleteRoleApiV1RolesRoleIdDelete(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleByTeamApiV1RolesTeamTeamIdGet(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamRoleInDB>> {
            return localVarFp.getRoleByTeamApiV1RolesTeamTeamIdGet(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve roles from User ID
         * @summary Get Role By User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleByUserApiV1RolesUserIdGet(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleInDB>> {
            return localVarFp.getRoleByUserApiV1RolesUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * Create role in bulk
     * @summary Create Bulk Roles
     * @param {string} userId 
     * @param {Array<RoleParams>} roleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createBulkRolesApiV1RolesBulkUserIdPost(userId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createBulkRolesApiV1RolesBulkUserIdPost(userId, roleParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create role in bulk
     * @summary Create Bulk Team Roles
     * @param {string} teamId 
     * @param {Array<RoleParams>} roleParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId: string, roleParams: Array<RoleParams>, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createBulkTeamRolesApiV1RolesTeamBulkTeamIdPost(teamId, roleParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a role
     * @summary Create Role
     * @param {RoleCreate} roleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRoleApiV1RolesPost(roleCreate: RoleCreate, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRoleApiV1RolesPost(roleCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a role
     * @summary Create Team Role
     * @param {TeamRoleCreate} teamRoleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createTeamRoleApiV1RolesTeamPost(teamRoleCreate: TeamRoleCreate, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createTeamRoleApiV1RolesTeamPost(teamRoleCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role
     * @summary Delete Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRoleApiV1RolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRoleApiV1RolesRoleIdDelete(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve roles from User ID
     * @summary Get Role By Team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoleByTeamApiV1RolesTeamTeamIdGet(teamId: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoleByTeamApiV1RolesTeamTeamIdGet(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve roles from User ID
     * @summary Get Role By User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoleByUserApiV1RolesUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoleByUserApiV1RolesUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SecretsApi - axios parameter creator
 * @export
 */
export const SecretsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new secret for a repository
         * @summary Create Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretCreatePayload} repositorySecretCreatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretForRepositoryApiV1SecretsRepositoryIdPost: async (repositoryId: string, repositorySecretCreatePayload: RepositorySecretCreatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('createSecretForRepositoryApiV1SecretsRepositoryIdPost', 'repositoryId', repositoryId)
            // verify required parameter 'repositorySecretCreatePayload' is not null or undefined
            assertParamExists('createSecretForRepositoryApiV1SecretsRepositoryIdPost', 'repositorySecretCreatePayload', repositorySecretCreatePayload)
            const localVarPath = `/api/v1/secrets/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repositorySecretCreatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a secret for a repository
         * @summary Delete Secret For Repository
         * @param {string} repositoryId 
         * @param {string} secretName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete: async (repositoryId: string, secretName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete', 'repositoryId', repositoryId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (secretName !== undefined) {
                localVarQueryParameter['secret_name'] = secretName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get secrets for a repository (limited to SU)
         * @summary Get Secret Names And Values For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet: async (repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/secrets/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get secret names for a repository
         * @summary Get Secret Names For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet: async (repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/secrets/names/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing secret for a repository
         * @summary Put Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretUpdatePayload} repositorySecretUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSecretForRepositoryApiV1SecretsRepositoryIdPut: async (repositoryId: string, repositorySecretUpdatePayload: RepositorySecretUpdatePayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('putSecretForRepositoryApiV1SecretsRepositoryIdPut', 'repositoryId', repositoryId)
            // verify required parameter 'repositorySecretUpdatePayload' is not null or undefined
            assertParamExists('putSecretForRepositoryApiV1SecretsRepositoryIdPut', 'repositorySecretUpdatePayload', repositorySecretUpdatePayload)
            const localVarPath = `/api/v1/secrets/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repositorySecretUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretsApi - functional programming interface
 * @export
 */
export const SecretsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new secret for a repository
         * @summary Create Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretCreatePayload} repositorySecretCreatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId: string, repositorySecretCreatePayload: RepositorySecretCreatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepositorySecretInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId, repositorySecretCreatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecretsApi.createSecretForRepositoryApiV1SecretsRepositoryIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a secret for a repository
         * @summary Delete Secret For Repository
         * @param {string} repositoryId 
         * @param {string} secretName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId: string, secretName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepositorySecretInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId, secretName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecretsApi.deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get secrets for a repository (limited to SU)
         * @summary Get Secret Names And Values For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepositorySecretInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecretsApi.getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get secret names for a repository
         * @summary Get Secret Names For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepositorySecretInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecretsApi.getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing secret for a repository
         * @summary Put Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretUpdatePayload} repositorySecretUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId: string, repositorySecretUpdatePayload: RepositorySecretUpdatePayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepositorySecretInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId, repositorySecretUpdatePayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecretsApi.putSecretForRepositoryApiV1SecretsRepositoryIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SecretsApi - factory interface
 * @export
 */
export const SecretsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretsApiFp(configuration)
    return {
        /**
         * Create a new secret for a repository
         * @summary Create Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretCreatePayload} repositorySecretCreatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId: string, repositorySecretCreatePayload: RepositorySecretCreatePayload, options?: RawAxiosRequestConfig): AxiosPromise<RepositorySecretInDB> {
            return localVarFp.createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId, repositorySecretCreatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a secret for a repository
         * @summary Delete Secret For Repository
         * @param {string} repositoryId 
         * @param {string} secretName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId: string, secretName: string, options?: RawAxiosRequestConfig): AxiosPromise<RepositorySecretInDB> {
            return localVarFp.deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get secrets for a repository (limited to SU)
         * @summary Get Secret Names And Values For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RepositorySecretInDB>> {
            return localVarFp.getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get secret names for a repository
         * @summary Get Secret Names For Repository
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<RepositorySecretInDB>> {
            return localVarFp.getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing secret for a repository
         * @summary Put Secret For Repository
         * @param {string} repositoryId 
         * @param {RepositorySecretUpdatePayload} repositorySecretUpdatePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId: string, repositorySecretUpdatePayload: RepositorySecretUpdatePayload, options?: RawAxiosRequestConfig): AxiosPromise<RepositorySecretInDB> {
            return localVarFp.putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId, repositorySecretUpdatePayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretsApi - object-oriented interface
 * @export
 * @class SecretsApi
 * @extends {BaseAPI}
 */
export class SecretsApi extends BaseAPI {
    /**
     * Create a new secret for a repository
     * @summary Create Secret For Repository
     * @param {string} repositoryId 
     * @param {RepositorySecretCreatePayload} repositorySecretCreatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId: string, repositorySecretCreatePayload: RepositorySecretCreatePayload, options?: RawAxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createSecretForRepositoryApiV1SecretsRepositoryIdPost(repositoryId, repositorySecretCreatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a secret for a repository
     * @summary Delete Secret For Repository
     * @param {string} repositoryId 
     * @param {string} secretName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId: string, secretName: string, options?: RawAxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteSecretForRepositoryApiV1SecretsRepositoryIdDelete(repositoryId, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get secrets for a repository (limited to SU)
     * @summary Get Secret Names And Values For Repository
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig) {
        return SecretsApiFp(this.configuration).getSecretNamesAndValuesForRepositoryApiV1SecretsRepositoryIdGet(repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get secret names for a repository
     * @summary Get Secret Names For Repository
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig) {
        return SecretsApiFp(this.configuration).getSecretNamesForRepositoryApiV1SecretsNamesRepositoryIdGet(repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing secret for a repository
     * @summary Put Secret For Repository
     * @param {string} repositoryId 
     * @param {RepositorySecretUpdatePayload} repositorySecretUpdatePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId: string, repositorySecretUpdatePayload: RepositorySecretUpdatePayload, options?: RawAxiosRequestConfig) {
        return SecretsApiFp(this.configuration).putSecretForRepositoryApiV1SecretsRepositoryIdPut(repositoryId, repositorySecretUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceAccountApi - axios parameter creator
 * @export
 */
export const ServiceAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPost} taktileApiSchemasServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost: async (organizationId: string, repositoryId: string, taktileApiSchemasServiceAccountServiceAccountPost: TaktileApiSchemasServiceAccountServiceAccountPost, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost', 'organizationId', organizationId)
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost', 'repositoryId', repositoryId)
            // verify required parameter 'taktileApiSchemasServiceAccountServiceAccountPost' is not null or undefined
            assertParamExists('createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost', 'taktileApiSchemasServiceAccountServiceAccountPost', taktileApiSchemasServiceAccountServiceAccountPost)
            const localVarPath = `/api/v1/service_account/{organization_id}/{repository_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taktileApiSchemasServiceAccountServiceAccountPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete: async (organizationId: string, accountId: string, repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete', 'organizationId', organizationId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete', 'accountId', accountId)
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/service_account/{organization_id}/{repository_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet: async (organizationId: string, repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet', 'organizationId', organizationId)
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/service_account/{organization_id}/{repository_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet: async (organizationId: string, repositoryId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet', 'organizationId', organizationId)
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet', 'repositoryId', repositoryId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet', 'accountId', accountId)
            const localVarPath = `/api/v1/service_account/{organization_id}/{repository_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPatch} taktileApiSchemasServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch: async (organizationId: string, repositoryId: string, accountId: string, taktileApiSchemasServiceAccountServiceAccountPatch: TaktileApiSchemasServiceAccountServiceAccountPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch', 'organizationId', organizationId)
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch', 'repositoryId', repositoryId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch', 'accountId', accountId)
            // verify required parameter 'taktileApiSchemasServiceAccountServiceAccountPatch' is not null or undefined
            assertParamExists('patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch', 'taktileApiSchemasServiceAccountServiceAccountPatch', taktileApiSchemasServiceAccountServiceAccountPatch)
            const localVarPath = `/api/v1/service_account/{organization_id}/{repository_id}/{account_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taktileApiSchemasServiceAccountServiceAccountPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceAccountApi - functional programming interface
 * @export
 */
export const ServiceAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPost} taktileApiSchemasServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId: string, repositoryId: string, taktileApiSchemasServiceAccountServiceAccountPost: TaktileApiSchemasServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId, repositoryId, taktileApiSchemasServiceAccountServiceAccountPost, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountApi.createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId: string, accountId: string, repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId, accountId, repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountApi.deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId: string, repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaktileApiSchemasServiceAccountServiceAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId, repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountApi.getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId: string, repositoryId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId, repositoryId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountApi.getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPatch} taktileApiSchemasServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId: string, repositoryId: string, accountId: string, taktileApiSchemasServiceAccountServiceAccountPatch: TaktileApiSchemasServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId, repositoryId, accountId, taktileApiSchemasServiceAccountServiceAccountPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountApi.patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceAccountApi - factory interface
 * @export
 */
export const ServiceAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceAccountApiFp(configuration)
    return {
        /**
         * Create a new service account in an organization
         * @summary Create Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPost} taktileApiSchemasServiceAccountServiceAccountPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId: string, repositoryId: string, taktileApiSchemasServiceAccountServiceAccountPost: TaktileApiSchemasServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount> {
            return localVarFp.createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId, repositoryId, taktileApiSchemasServiceAccountServiceAccountPost, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a service account in an organization
         * @summary Delete Service Account
         * @param {string} organizationId 
         * @param {string} accountId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId: string, accountId: string, repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount> {
            return localVarFp.deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId, accountId, repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve service accounts of an organization
         * @summary Get All Service Accounts
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId: string, repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TaktileApiSchemasServiceAccountServiceAccount>> {
            return localVarFp.getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId, repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a service account in an organization
         * @summary Get Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId: string, repositoryId: string, accountId: string, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount> {
            return localVarFp.getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId, repositoryId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch service account in an organization
         * @summary Patch Service Account
         * @param {string} organizationId 
         * @param {string} repositoryId 
         * @param {string} accountId 
         * @param {TaktileApiSchemasServiceAccountServiceAccountPatch} taktileApiSchemasServiceAccountServiceAccountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId: string, repositoryId: string, accountId: string, taktileApiSchemasServiceAccountServiceAccountPatch: TaktileApiSchemasServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig): AxiosPromise<TaktileApiSchemasServiceAccountServiceAccount> {
            return localVarFp.patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId, repositoryId, accountId, taktileApiSchemasServiceAccountServiceAccountPatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceAccountApi - object-oriented interface
 * @export
 * @class ServiceAccountApi
 * @extends {BaseAPI}
 */
export class ServiceAccountApi extends BaseAPI {
    /**
     * Create a new service account in an organization
     * @summary Create Service Account
     * @param {string} organizationId 
     * @param {string} repositoryId 
     * @param {TaktileApiSchemasServiceAccountServiceAccountPost} taktileApiSchemasServiceAccountServiceAccountPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountApi
     */
    public createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId: string, repositoryId: string, taktileApiSchemasServiceAccountServiceAccountPost: TaktileApiSchemasServiceAccountServiceAccountPost, options?: RawAxiosRequestConfig) {
        return ServiceAccountApiFp(this.configuration).createServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdPost(organizationId, repositoryId, taktileApiSchemasServiceAccountServiceAccountPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a service account in an organization
     * @summary Delete Service Account
     * @param {string} organizationId 
     * @param {string} accountId 
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountApi
     */
    public deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId: string, accountId: string, repositoryId: string, options?: RawAxiosRequestConfig) {
        return ServiceAccountApiFp(this.configuration).deleteServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdDelete(organizationId, accountId, repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve service accounts of an organization
     * @summary Get All Service Accounts
     * @param {string} organizationId 
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountApi
     */
    public getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId: string, repositoryId: string, options?: RawAxiosRequestConfig) {
        return ServiceAccountApiFp(this.configuration).getAllServiceAccountsApiV1ServiceAccountOrganizationIdRepositoryIdGet(organizationId, repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a service account in an organization
     * @summary Get Service Account
     * @param {string} organizationId 
     * @param {string} repositoryId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountApi
     */
    public getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId: string, repositoryId: string, accountId: string, options?: RawAxiosRequestConfig) {
        return ServiceAccountApiFp(this.configuration).getServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdGet(organizationId, repositoryId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch service account in an organization
     * @summary Patch Service Account
     * @param {string} organizationId 
     * @param {string} repositoryId 
     * @param {string} accountId 
     * @param {TaktileApiSchemasServiceAccountServiceAccountPatch} taktileApiSchemasServiceAccountServiceAccountPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountApi
     */
    public patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId: string, repositoryId: string, accountId: string, taktileApiSchemasServiceAccountServiceAccountPatch: TaktileApiSchemasServiceAccountServiceAccountPatch, options?: RawAxiosRequestConfig) {
        return ServiceAccountApiFp(this.configuration).patchServiceAccountApiV1ServiceAccountOrganizationIdRepositoryIdAccountIdPatch(organizationId, repositoryId, accountId, taktileApiSchemasServiceAccountServiceAccountPatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve my teams.
         * @summary Get My Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTeamsApiV1TeamsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve team.
         * @summary Get Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamApiV1TeamsTeamIdGet: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamApiV1TeamsTeamIdGet', 'teamId', teamId)
            const localVarPath = `/api/v1/teams/{team_id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all teams.
         * @summary Get Teams
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsApiV1TeamsAllGet: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teams/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove orphaned teams
         * @summary Remove Orphaned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrphanedApiV1TeamsRemoveOrphanedPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teams/remove_orphaned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve my teams.
         * @summary Get My Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTeamsApiV1TeamsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTeamsApiV1TeamsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamsApi.getMyTeamsApiV1TeamsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve team.
         * @summary Get Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamApiV1TeamsTeamIdGet(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamApiV1TeamsTeamIdGet(teamId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamsApi.getTeamApiV1TeamsTeamIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all teams.
         * @summary Get Teams
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsApiV1TeamsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsApiV1TeamsAllGet(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamsApi.getTeamsApiV1TeamsAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove orphaned teams
         * @summary Remove Orphaned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrphanedApiV1TeamsRemoveOrphanedPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrphanedApiV1TeamsRemoveOrphanedPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamsApi.removeOrphanedApiV1TeamsRemoveOrphanedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamsApiFp(configuration)
    return {
        /**
         * Retrieve my teams.
         * @summary Get My Teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTeamsApiV1TeamsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamInDB>> {
            return localVarFp.getMyTeamsApiV1TeamsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve team.
         * @summary Get Team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamApiV1TeamsTeamIdGet(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<TeamInDB> {
            return localVarFp.getTeamApiV1TeamsTeamIdGet(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all teams.
         * @summary Get Teams
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsApiV1TeamsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamInDB>> {
            return localVarFp.getTeamsApiV1TeamsAllGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove orphaned teams
         * @summary Remove Orphaned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrphanedApiV1TeamsRemoveOrphanedPost(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.removeOrphanedApiV1TeamsRemoveOrphanedPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI {
    /**
     * Retrieve my teams.
     * @summary Get My Teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getMyTeamsApiV1TeamsGet(options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getMyTeamsApiV1TeamsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve team.
     * @summary Get Team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getTeamApiV1TeamsTeamIdGet(teamId: string, options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getTeamApiV1TeamsTeamIdGet(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all teams.
     * @summary Get Teams
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getTeamsApiV1TeamsAllGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getTeamsApiV1TeamsAllGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove orphaned teams
     * @summary Remove Orphaned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public removeOrphanedApiV1TeamsRemoveOrphanedPost(options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).removeOrphanedApiV1TeamsRemoveOrphanedPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user and token for secret link user
         * @summary Auth Refresh
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshApiV1UsersSecretLinkRepositoryIdGet: async (repositoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('authRefreshApiV1UsersSecretLinkRepositoryIdGet', 'repositoryId', repositoryId)
            const localVarPath = `/api/v1/users/secret-link/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new secret link user.
         * @summary Create Secret Link User
         * @param {string} repositoryId 
         * @param {SecretLinkUserCreate} secretLinkUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost: async (repositoryId: string, secretLinkUserCreate: SecretLinkUserCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repositoryId' is not null or undefined
            assertParamExists('createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost', 'repositoryId', repositoryId)
            // verify required parameter 'secretLinkUserCreate' is not null or undefined
            assertParamExists('createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost', 'secretLinkUserCreate', secretLinkUserCreate)
            const localVarPath = `/api/v1/users/secret-link/{repository_id}`
                .replace(`{${"repository_id"}}`, encodeURIComponent(String(repositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretLinkUserCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {TaktileApiCoreTAvailableAuthSources} [sourceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost: async (userCreate: UserCreate, sourceName?: TaktileApiCoreTAvailableAuthSources, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUserApiV1UsersPost', 'userCreate', userCreate)
            const localVarPath = `/api/v1/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (sourceName !== undefined) {
                localVarQueryParameter['source_name'] = sourceName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiV1UsersIdUserIdDelete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserApiV1UsersIdUserIdDelete', 'userId', userId)
            const localVarPath = `/api/v1/users/id/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific user by id.
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdApiV1UsersIdUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserByIdApiV1UsersIdUserIdGet', 'userId', userId)
            const localVarPath = `/api/v1/users/id/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User By Role
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByRoleApiV1UsersRoleRoleNameGet: async (roleName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleName' is not null or undefined
            assertParamExists('getUserByRoleApiV1UsersRoleRoleNameGet', 'roleName', roleName)
            const localVarPath = `/api/v1/users/role/{role_name}`
                .replace(`{${"role_name"}}`, encodeURIComponent(String(roleName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific user by username
         * @summary Get User By Username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsernameApiV1UsersNameUsernameGet: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUserByUsernameApiV1UsersNameUsernameGet', 'username', username)
            const localVarPath = `/api/v1/users/name/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user.
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMeApiV1UsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve users.
         * @summary Get Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UsersGet: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersIdUserIdPut: async (userId: string, userUpdate: UserUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserApiV1UsersIdUserIdPut', 'userId', userId)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUserApiV1UsersIdUserIdPut', 'userUpdate', userUpdate)
            const localVarPath = `/api/v1/users/id/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UsersMePut} [bodyUpdateUserMeApiV1UsersMePut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UsersMePut: async (bodyUpdateUserMeApiV1UsersMePut?: BodyUpdateUserMeApiV1UsersMePut, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyUpdateUserMeApiV1UsersMePut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user and token for secret link user
         * @summary Auth Refresh
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.authRefreshApiV1UsersSecretLinkRepositoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create new secret link user.
         * @summary Create Secret Link User
         * @param {string} repositoryId 
         * @param {SecretLinkUserCreate} secretLinkUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId: string, secretLinkUserCreate: SecretLinkUserCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId, secretLinkUserCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {TaktileApiCoreTAvailableAuthSources} [sourceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserApiV1UsersPost(userCreate: UserCreate, sourceName?: TaktileApiCoreTAvailableAuthSources, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserApiV1UsersPost(userCreate, sourceName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUserApiV1UsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserApiV1UsersIdUserIdDelete(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserApiV1UsersIdUserIdDelete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserApiV1UsersIdUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a specific user by id.
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByIdApiV1UsersIdUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByIdApiV1UsersIdUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserByIdApiV1UsersIdUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User By Role
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByRoleApiV1UsersRoleRoleNameGet(roleName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByRoleApiV1UsersRoleRoleNameGet(roleName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserByRoleApiV1UsersRoleRoleNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a specific user by username
         * @summary Get User By Username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByUsernameApiV1UsersNameUsernameGet(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByUsernameApiV1UsersNameUsernameGet(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserByUsernameApiV1UsersNameUsernameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get current user.
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMeApiV1UsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMeApiV1UsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserMeApiV1UsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve users.
         * @summary Get Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersApiV1UsersGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersApiV1UsersGet(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsersApiV1UsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserApiV1UsersIdUserIdPut(userId: string, userUpdate: UserUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserApiV1UsersIdUserIdPut(userId, userUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUserApiV1UsersIdUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UsersMePut} [bodyUpdateUserMeApiV1UsersMePut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut?: BodyUpdateUserMeApiV1UsersMePut, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUserMeApiV1UsersMePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Get user and token for secret link user
         * @summary Auth Refresh
         * @param {string} repositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new secret link user.
         * @summary Create Secret Link User
         * @param {string} repositoryId 
         * @param {SecretLinkUserCreate} secretLinkUserCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId: string, secretLinkUserCreate: SecretLinkUserCreate, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId, secretLinkUserCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {TaktileApiCoreTAvailableAuthSources} [sourceName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UsersPost(userCreate: UserCreate, sourceName?: TaktileApiCoreTAvailableAuthSources, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.createUserApiV1UsersPost(userCreate, sourceName, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user.
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApiV1UsersIdUserIdDelete(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.deleteUserApiV1UsersIdUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific user by id.
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdApiV1UsersIdUserIdGet(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUserByIdApiV1UsersIdUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User By Role
         * @param {string} roleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByRoleApiV1UsersRoleRoleNameGet(roleName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.getUserByRoleApiV1UsersRoleRoleNameGet(roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific user by username
         * @summary Get User By Username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsernameApiV1UsersNameUsernameGet(username: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUserByUsernameApiV1UsersNameUsernameGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user.
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMeApiV1UsersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUserMeApiV1UsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve users.
         * @summary Get Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersApiV1UsersGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.getUsersApiV1UsersGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UsersIdUserIdPut(userId: string, userUpdate: UserUpdate, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.updateUserApiV1UsersIdUserIdPut(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UsersMePut} [bodyUpdateUserMeApiV1UsersMePut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut?: BodyUpdateUserMeApiV1UsersMePut, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Get user and token for secret link user
     * @summary Auth Refresh
     * @param {string} repositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).authRefreshApiV1UsersSecretLinkRepositoryIdGet(repositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new secret link user.
     * @summary Create Secret Link User
     * @param {string} repositoryId 
     * @param {SecretLinkUserCreate} secretLinkUserCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId: string, secretLinkUserCreate: SecretLinkUserCreate, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createSecretLinkUserApiV1UsersSecretLinkRepositoryIdPost(repositoryId, secretLinkUserCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new user.
     * @summary Create User
     * @param {UserCreate} userCreate 
     * @param {TaktileApiCoreTAvailableAuthSources} [sourceName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserApiV1UsersPost(userCreate: UserCreate, sourceName?: TaktileApiCoreTAvailableAuthSources, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserApiV1UsersPost(userCreate, sourceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user.
     * @summary Delete User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserApiV1UsersIdUserIdDelete(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserApiV1UsersIdUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific user by id.
     * @summary Get User By Id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByIdApiV1UsersIdUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByIdApiV1UsersIdUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User By Role
     * @param {string} roleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByRoleApiV1UsersRoleRoleNameGet(roleName: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByRoleApiV1UsersRoleRoleNameGet(roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific user by username
     * @summary Get User By Username
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByUsernameApiV1UsersNameUsernameGet(username: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByUsernameApiV1UsersNameUsernameGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user.
     * @summary Get User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserMeApiV1UsersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserMeApiV1UsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve users.
     * @summary Get Users
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersApiV1UsersGet(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersApiV1UsersGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user.
     * @summary Update User
     * @param {string} userId 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserApiV1UsersIdUserIdPut(userId: string, userUpdate: UserUpdate, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserApiV1UsersIdUserIdPut(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update own user.
     * @summary Update User Me
     * @param {BodyUpdateUserMeApiV1UsersMePut} [bodyUpdateUserMeApiV1UsersMePut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut?: BodyUpdateUserMeApiV1UsersMePut, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserMeApiV1UsersMePut(bodyUpdateUserMeApiV1UsersMePut, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new user without the need to be logged in.
         * @summary App Installed Webhook
         * @param {string} [xHubSignature] 
         * @param {string} [xGithubEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appInstalledWebhookApiV1WebhooksAppEventPost: async (xHubSignature?: string, xGithubEvent?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/webhooks/app/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (xHubSignature != null) {
                localVarHeaderParameter['x-hub-signature'] = String(xHubSignature);
            }
            if (xGithubEvent != null) {
                localVarHeaderParameter['x-github-event'] = String(xGithubEvent);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new user without the need to be logged in.
         * @summary App Installed Webhook
         * @param {string} [xHubSignature] 
         * @param {string} [xGithubEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature?: string, xGithubEvent?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature, xGithubEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebhooksApi.appInstalledWebhookApiV1WebhooksAppEventPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * Create new user without the need to be logged in.
         * @summary App Installed Webhook
         * @param {string} [xHubSignature] 
         * @param {string} [xGithubEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature?: string, xGithubEvent?: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature, xGithubEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * Create new user without the need to be logged in.
     * @summary App Installed Webhook
     * @param {string} [xHubSignature] 
     * @param {string} [xGithubEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature?: string, xGithubEvent?: string, options?: RawAxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).appInstalledWebhookApiV1WebhooksAppEventPost(xHubSignature, xGithubEvent, options).then((request) => request(this.axios, this.basePath));
    }
}



