import { faChevronDown, faLink } from "@fortawesome/pro-regular-svg-icons";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { times } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { PropertyDefinitionOutputTypeEnum } from "src/clients/features-control/api";
import { EnumValuesEditor } from "src/entities/SchemaEditor/EnumValuesEditor";
import { isEntityEnumSchemaProperty } from "src/entities/SchemaEditor/utils";
import { getOrderedPropertiesByKey } from "src/entities/entityView/utils";
import {
  EntitySchemaProperty,
  EntitySchemaResource,
} from "src/entities/queries";
import { PropertyTypeSelector } from "src/eventsCatalogue/SchemaEditor/PropertyTypeSelector";

type PropertyFieldsProps =
  | {
      propertyName: string;
      property: EntitySchemaProperty;
      schemaId: string;
      isLoading?: false;
    }
  | {
      isLoading: true;
    };

const PropertyFields: React.FC<PropertyFieldsProps> = (props) => {
  const { register, formState, getValues } =
    useFormContext<EntitySchemaResource>();

  if (props.isLoading) {
    return (
      <div className="flex gap-x-2">
        {times(3, (i) => (
          <div key={i} className="w-1/3 flex-1 shrink-0">
            <SkeletonPlaceholder
              height="h-8"
              rounded="rounded-lg"
              width="w-full"
            />
          </div>
        ))}
      </div>
    );
  }

  const { property, propertyName } = props;
  const isEnum = isEntityEnumSchemaProperty(property);

  return (
    <>
      <div className="relative flex gap-x-2 pl-7">
        <div className="absolute left-0 flex h-8 w-7 cursor-not-allowed items-center justify-start">
          <Icon color="text-gray-400" icon={faGripDotsVertical} size="xs" />
        </div>
        <div className="w-1/3 flex-1 shrink-0">
          <Input value={propertyName} disabled fullWidth monospaced />
        </div>
        <div className="w-1/3 flex-1 shrink-0">
          <Input
            errored={
              !!formState.errors.properties?.[propertyName]?._display_name
            }
            disabled
            fullWidth
            {...register(`properties.${propertyName}._display_name`, {
              required: "This field is required",
            })}
          />
          {formState.errors.properties?.[propertyName]?._display_name && (
            <ErrorHint>
              {
                formState.errors.properties?.[propertyName]?._display_name
                  ?.message
              }
            </ErrorHint>
          )}
        </div>
        <div className="w-1/3 flex-1 shrink-0">
          <Controller<EntitySchemaResource, `properties.${string}._type`>
            name={`properties.${propertyName}._type`}
            render={({ field }) => {
              const type = getValues(`properties.${propertyName}._type`);
              const pluralDisplayName = getValues("_display_name_plural");
              const singularDisplayName = getValues("_display_name_singular");

              if ("url" === (type as string)) {
                return (
                  <Input
                    prefixIcon={{ icon: faLink }}
                    suffixIcon={{ icon: faChevronDown }}
                    value="url"
                    width="w-auto"
                    disabled
                    fullWidth
                  />
                );
              }

              return (
                <PropertyTypeSelector
                  pluralDisplayName={pluralDisplayName}
                  property={{
                    type: property._type as PropertyDefinitionOutputTypeEnum,
                    display_name: property._display_name,
                    enum_schema:
                      property._type === "enum"
                        ? {
                            values: [],
                          }
                        : undefined,
                    relation_schema: property._rel_schema
                      ? {
                          entity_type: property._rel_schema,
                        }
                      : undefined,
                  }}
                  relSchema={property._rel_schema}
                  relationCardinality={property._cardinality}
                  schemaId={props.schemaId}
                  singularDisplayName={singularDisplayName}
                  value={field.value as PropertyDefinitionOutputTypeEnum}
                />
              );
            }}
          />
        </div>
      </div>
      {isEnum && <EnumValuesEditor propertyName={propertyName} />}
    </>
  );
};

type PropertiesSchemaEditorProps = {
  isLoading?: boolean;
};

export const SchemaPropertiesEditor: React.FC<PropertiesSchemaEditorProps> = ({
  isLoading,
}) => {
  const form = useFormContext<EntitySchemaResource>();

  const orderdFields = getOrderedPropertiesByKey(form.getValues());
  const properties = form.getValues("properties");
  const schemaId = form.getValues("_id");

  return (
    <div className="flex flex-col gap-y-3">
      <div className="ml-7 flex gap-x-2 text-gray-500 font-inter-normal-12px">
        <div className="w-1/3 flex-1">Name</div>
        <div className="w-1/3 flex-1">Display Name</div>
        <div className="w-1/3 flex-1">Type</div>
      </div>
      {isLoading
        ? times(7, (i) => <PropertyFields key={i} isLoading />)
        : orderdFields.map((propertyName) => (
            <PropertyFields
              key={propertyName}
              property={properties[propertyName]}
              propertyName={propertyName}
              schemaId={schemaId}
            />
          ))}
    </div>
  );
};
