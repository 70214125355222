import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { AiNodeV2Form, BasicModelCard } from "src/aiNode/types";
import { Card } from "src/base-components/Card";
import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { FormItem } from "src/base-components/FormItem";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

const MODELS_BEHIND_FEATURE_FLAGS: { [model_id: string]: FEATURE_FLAGS } = {
  "claude-3-7-sonnet": FEATURE_FLAGS.aiNodeClaude37Sonnet,
  "deepseek-r1": FEATURE_FLAGS.aiNodeDeepseekR1,
};

type ModelSelectorPropsT = {
  availableModels: BasicModelCard[];
  immutable: boolean;
};

export const ModelSelector: React.FC<ModelSelectorPropsT> = ({
  availableModels,
  immutable,
}) => {
  const { setValue, watch, control } = useFormContext<AiNodeV2Form>();
  const selectedModelId = watch("model.model_id");

  const dropdownElements = availableModels.reduce(
    (filteredModels, model) => {
      if (
        model.model_id == selectedModelId ||
        !(model.model_id in MODELS_BEHIND_FEATURE_FLAGS) ||
        isFeatureFlagEnabled(MODELS_BEHIND_FEATURE_FLAGS[model.model_id])
      ) {
        filteredModels.push({
          key: model.model_id,
          value: model.name,
        });
      }

      return filteredModels;
    },
    [] as { key: string; value: string }[],
  );

  return (
    <Card className="w-full pb-4 pt-4">
      <FormItem>
        <FormItem.Label
          className="mb-2"
          description="Pick an AI model for your task. Each model has different capabilities and performance characteristics."
          isRequired
        >
          Select AI model
        </FormItem.Label>
        <Controller
          control={control}
          name="model.model_id"
          render={(_) => (
            <div className="w-full">
              <FixedSimpleDropdown
                buttonDataLoc="ai-node-model-selector"
                disabled={immutable}
                elements={dropdownElements}
                itemsWidth="w-full"
                placeholder="Select model"
                placement="bottomLeft"
                selectedKey={selectedModelId}
                onSelect={(newModelId) => {
                  setValue("model", {
                    model_id: newModelId,
                    provider: "bedrock",
                  });
                }}
              />
            </div>
          )}
        />
      </FormItem>
    </Card>
  );
};
