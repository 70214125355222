import { startCompletion } from "@codemirror/autocomplete";
import { EditorView, ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { useCallback, FocusEvent } from "react";

import { lineWrappingComp } from "src/base-components/CodeInput/CodeInput";

type FocusHandlerParams = {
  editor: React.RefObject<ReactCodeMirrorRef>;
  disabled: boolean;
  toggleWrappingOnFocus: boolean;
  toggleScrollbarOnFocus?: boolean;
  onBlur?: React.DOMAttributes<HTMLDivElement>["onBlur"];
  onFocus?: React.DOMAttributes<HTMLDivElement>["onFocus"];
};

const useHandleFocus = ({
  editor,
  disabled,
  toggleWrappingOnFocus: switchWrappingOnFocus,
  toggleScrollbarOnFocus: switchScrollbarOnFocus,
  onFocus,
}: FocusHandlerParams) => {
  return useCallback(
    (e: FocusEvent<HTMLDivElement, Element>) => {
      if (switchWrappingOnFocus) {
        setTimeout(() => {
          if (editor.current?.view) {
            editor.current?.view?.dispatch({
              effects: lineWrappingComp.reconfigure(EditorView.lineWrapping),
            });
          }
        });
      }

      if (switchScrollbarOnFocus) {
        editor.current?.editor?.classList.remove("scrollbar-hide");
        editor.current?.editor?.classList.add("overflow-y-auto");
      }

      // We want to only show the completion when the user clicks on the input otherwise we prevent the default
      if (!e?.defaultPrevented && !disabled) {
        // Does not work synchronously for unknown reasons
        setTimeout(() => {
          // We want to only show the completion when the editor is empty
          if (editor.current?.view && editor.current.state?.doc.length === 0) {
            startCompletion(editor.current.view);
          }
        });
      }

      onFocus?.(e);
    },
    [disabled, editor, onFocus, switchScrollbarOnFocus, switchWrappingOnFocus],
  );
};

const useHandleBlur = ({
  editor,
  toggleWrappingOnFocus: switchWrappingOnFocus,
  toggleScrollbarOnFocus: switchScrollbarOnFocus,
  onBlur,
}: FocusHandlerParams) => {
  return useCallback(
    (e: FocusEvent<HTMLDivElement, Element>) => {
      if (switchWrappingOnFocus) {
        setTimeout(() => {
          if (editor.current?.view) {
            editor.current?.view?.dispatch({
              effects: lineWrappingComp.reconfigure([]),
            });
          }
        });
      }

      if (switchScrollbarOnFocus) {
        editor.current?.editor?.classList.add("scrollbar-hide");
        editor.current?.editor?.classList.remove("overflow-y-auto");
      }

      onBlur?.(e);
    },
    [editor, onBlur, switchScrollbarOnFocus, switchWrappingOnFocus],
  );
};

export const useFocusHandlers = (args: FocusHandlerParams) => {
  return {
    handleFocus: useHandleFocus(args),
    handleBlur: useHandleBlur(args),
  };
};
