import {
  faBroom,
  faEdit,
  faPlus,
  faTrashAlt,
  faScaleUnbalancedFlip,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

import { PropertyValueT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { TableComp } from "src/base-components/Table";
import { OutcomeTypeStatusEnum } from "src/clients/flow-api";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { CellWrapper, ColumnHeader } from "src/jobs/common/TableElements";
import { OutcomeType } from "src/outcomes/types";
import { TYPE_ICONS } from "src/utils/constants";
import { formatDate } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";
import { useShowOnHover } from "src/utils/useShowOnHover";

const helper = createColumnHelper<OutcomeType>();

const getColumns = (clearingOutcomeReportsForTypes: string[]) => [
  helper.accessor("name", {
    header: () => <ColumnHeader>Outcome name</ColumnHeader>,
    cell: ({ row }) => {
      return <CellWrapper>{row.original.name}</CellWrapper>;
    },
  }),
  helper.accessor("key", {
    header: () => <ColumnHeader>Key</ColumnHeader>,
    cell: ({ row }) => {
      return (
        <CellWrapper>
          <Pill variant="gray">
            <Pill.Text>{row.original.key}</Pill.Text>
          </Pill>
        </CellWrapper>
      );
    },
  }),
  helper.accessor("created_at", {
    header: () => <ColumnHeader>Created at</ColumnHeader>,
    cell: (info) => (
      <CellWrapper>
        <div className="w-36">
          {formatDate(info.getValue(), "MMM d, yyyy 'at' h:mmaaa")}
        </div>
      </CellWrapper>
    ),
  }),
  helper.accessor("count", {
    header: () => <ColumnHeader>Recorded Outcomes</ColumnHeader>,
    cell: (info) => {
      return <CellWrapper>{formatNumber(info.getValue() ?? 0)}</CellWrapper>;
    },
  }),
  helper.accessor("payload_schema", {
    header: () => <ColumnHeader>Fields</ColumnHeader>,
    cell: (info) => {
      const schema = info.getValue() as { properties: object };
      const properties = Object.entries(schema.properties);
      const [displayProperties, remainingProperties] =
        properties.length > 4
          ? [properties.slice(0, 3), properties.slice(3)]
          : [properties, []];

      return (
        <CellWrapper>
          {displayProperties.map(
            ([key, value]: [string, PropertyValueT]) =>
              value.type && (
                <FieldPill
                  key={key}
                  field={key}
                  type={value.type[0] as keyof typeof TYPE_ICONS}
                />
              ),
          )}
          {remainingProperties.length > 0 && (
            <RemainingFieldsPopover properties={remainingProperties} />
          )}
        </CellWrapper>
      );
    },
  }),
  helper.display({
    id: "actions",
    header: () => null,
    cell: (info) =>
      info.row.original.status === OutcomeTypeStatusEnum.DELETING ? (
        <div className="flex justify-end">
          <Tooltip placement="top" title="Deleting...">
            <Icon
              color="text-gray-500"
              cursorType="default"
              dataLoc="outcome-type-deleting"
              icon={faCircleNotch}
              size="xs"
              spin
            />
          </Tooltip>
        </div>
      ) : (
        <div className="flex w-full items-center justify-end gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
          <Tooltip
            disabled={clearingOutcomeReportsForTypes.includes(
              info.row.original.key,
            )}
            placement="top"
            title={
              info.row.original.count
                ? "Clear all reported Outcomes"
                : "There is no reported Outcome to clear yet"
            }
          >
            {clearingOutcomeReportsForTypes.includes(info.row.original.key) ? (
              <Icon
                color="text-gray-500"
                cursorType="default"
                icon={faCircleNotch}
                size="xs"
                spin
              />
            ) : (
              <Icon
                color={
                  !info.row.original.count ? "text-gray-300" : "text-gray-500"
                }
                dataLoc="outcome-type-clear-button"
                disabled={info.row.original.count === 0}
                icon={faBroom}
                size="xs"
                onClick={() => {
                  info.table.options.meta?.actions?.onClear?.(
                    info.row.original,
                  );
                }}
              />
            )}
          </Tooltip>
          <Tooltip placement="top" title="Edit Outcome">
            <Icon
              color="text-gray-500"
              dataLoc="outcome-type-edit-button"
              icon={faEdit}
              size="xs"
              onClick={() => {
                info.table.options.meta?.actions?.onEdit?.(info.row.original);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Delete Outcome">
            <Icon
              color="text-gray-500"
              dataLoc="outcome-type-delete-button"
              icon={faTrashAlt}
              size="xs"
              onClick={() => {
                info.table.options.meta?.actions?.onDelete?.(info.row.original);
              }}
            />
          </Tooltip>
        </div>
      ),
  }),
];

export const OutcomeTypesTable: React.FC<{
  data?: OutcomeType[];
  isLoading?: boolean;
  onOutcomeTypeEdit: (outcomeType: OutcomeType) => void;
  onOutcomeTypeDelete: (outcomeType: OutcomeType) => void;
  onOutcomeReportsClear: (outcomeType: OutcomeType) => void;
  clearingOutcomeReportsForTypes: string[];
}> = ({
  data = [],
  isLoading = false,
  onOutcomeTypeDelete,
  onOutcomeTypeEdit,
  onOutcomeReportsClear,
  clearingOutcomeReportsForTypes,
}) => {
  if (!isLoading && data.length === 0) {
    return (
      <EmptyState
        description="Track real-world results of your decisions to measure effectiveness and drive improvements."
        headline="No Outcomes added yet"
        icon={faScaleUnbalancedFlip}
      />
    );
  }

  return (
    <TableComp
      actions={{
        onDelete: onOutcomeTypeDelete,
        onEdit: onOutcomeTypeEdit,
        onClear: onOutcomeReportsClear,
      }}
      columns={getColumns(clearingOutcomeReportsForTypes)}
      data={data}
      dataLoc="outcome-types-table"
      frameClassName="w-full px-3 pb-2"
      getRowId={(row) => row.id}
      isLoading={isLoading}
      loadingRowsCount={5}
    />
  );
};

const FieldPill: React.FC<{ type: keyof typeof TYPE_ICONS; field: string }> = ({
  type,
  field,
}) => {
  return (
    <Pill variant="gray">
      <Pill.Icon icon={TYPE_ICONS[type]} />
      <Pill.Text>{field}</Pill.Text>
    </Pill>
  );
};

const RemainingFieldsPopover: React.FC<{
  properties: [string, PropertyValueT][];
}> = ({ properties }) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useShowOnHover(700);

  return (
    <CustomPopover
      button={
        <span className="cursor-default">
          <Pill variant="gray">
            <Pill.Icon icon={faPlus} />
            <Pill.Text>{properties.length} more</Pill.Text>
          </Pill>
        </span>
      }
      className="flex w-70 flex-wrap justify-start gap-2 p-4"
      isOpen={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {properties.map(([key, value]) => (
        <FieldPill
          key={key}
          field={key}
          type={value.type![0] as keyof typeof TYPE_ICONS}
        />
      ))}
    </CustomPopover>
  );
};
