import React from "react";
import { Controller } from "react-hook-form";

import { BasicModelCard } from "src/aiNode/types";
import { FormItem } from "src/base-components/FormItem";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";

type AdvancedConfigPropsT = {
  availableModels: BasicModelCard[];
};

export const AdvancedConfig: React.FC<AdvancedConfigPropsT> = ({
  availableModels,
}) => {
  const renderableAvailableModels = availableModels.map((model) => {
    return {
      key: model.model_id,
      value: model.name,
    };
  });

  return (
    <FormItem label="Model" isRequired>
      <Controller
        name="model.model_id"
        render={({ field }) => (
          <SimpleDropDown
            buttonClassName="pl-3"
            buttonDataLoc="ai-node-model"
            className="h-8 w-full"
            elements={renderableAvailableModels}
            itemsClassNames="w-full"
            itemsWidth="w-full"
            placeholder="Select model"
            placement="bottomLeft"
            selectedKey={field.value}
            onSelect={field.onChange}
          />
        )}
      />
    </FormItem>
  );
};
