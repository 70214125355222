import Quill from "quill";
// @ts-ignore-next-line
import QuillMarkdown from "quilljs-markdown";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { twJoin } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { mdService } from "src/base-components/TextEditor/MarkdownService";

type Range = { index: number; length: number };

type Props = {
  dataLoc?: string;
  toolbarRef?: React.MutableRefObject<Nullable<HTMLUListElement>>;
  placeholder?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  keepEmptyLines?: boolean;
  value: string;
  fullHeight?: boolean;
  inline?: boolean;
  maxHeight?: `max-h-[${number}px]`;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const defaultMaxHeight = "max-h-[300px]";

const mdIgnoreTags = [
  "header",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "checkbox",
];

export type TextEditorRef = {
  quill: Quill | null;
  focus: () => void;
};

export const TextEditor = forwardRef<TextEditorRef, Props>(
  (
    {
      dataLoc,
      placeholder,
      readOnly = false,
      autoFocus = false,
      keepEmptyLines = false,
      inline = false,
      value: propsValue,
      onChange,
      onFocus,
      onBlur,
      fullHeight,
      toolbarRef,
      maxHeight,
    },
    ref,
  ) => {
    const Code: any = Quill.import("formats/code");
    Code.className = "ql-code-highlighting";
    Quill.register(Code, true);

    const quill = useRef<Nullable<Quill>>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      quill: quill.current,
      focus: () => {
        quill.current?.focus();
      },
    }));

    const syncUserValueWithProps = useCallback(() => {
      const { current } = quill;
      if (!current) {
        return;
      }

      const delta = current.clipboard.convert({
        html: mdService.toHtml(propsValue),
      });
      current.setContents(delta);
    }, [propsValue, quill]);

    const commitValue = useCallback(() => {
      if (onChange) {
        const html = quill.current?.root.innerHTML || "";
        const markdown = mdService.toMarkdown(html, { keepEmptyLines });
        onChange(markdown);
      }
    }, [onChange, keepEmptyLines]);

    useEffect(() => {
      // hack to sync value in next render cycle, othewise it doesn't work as expected
      Promise.resolve().then(() => {
        syncUserValueWithProps();
      });
    }, [propsValue, syncUserValueWithProps]);

    useEffect(() => {
      quill.current?.enable(!readOnly);
    }, [readOnly]);

    useEffect(() => {
      if (quill.current || !containerRef.current) {
        return;
      }

      const current = new Quill(containerRef.current, {
        placeholder,
        readOnly,
        modules: {
          toolbar: toolbarRef?.current,
        },
      });

      quill.current = current;

      // enable markdown support
      new QuillMarkdown(current, { ignoreTags: mdIgnoreTags });
      if (autoFocus) {
        setTimeout(() => {
          current.setSelection(current.getLength(), 0);
        });
      }
    }, [autoFocus, placeholder, toolbarRef, readOnly, onChange]);

    useEffect(() => {
      const handleSelectionChange = (range: Range, oldRange: Range) => {
        if (range === null && oldRange !== null) {
          onBlur?.();
          commitValue();
        }

        if (range !== null && oldRange === null) {
          onFocus?.();
        }
      };

      quill.current?.on("selection-change", handleSelectionChange);

      return () => {
        quill.current?.off("selection-change", handleSelectionChange);
      };
    }, [quill, onFocus, onBlur, commitValue]);

    // Commit value on outside click
    useEventListener("mousedown", (e) => {
      const [container, toolbar] = [containerRef.current, toolbarRef?.current];

      if (
        container?.contains(document.activeElement) &&
        !(
          container?.contains(e.target as Node) ||
          toolbar?.contains(e.target as Node)
        )
      ) {
        commitValue();
      }
    });

    return (
      <div
        ref={containerRef}
        className={twJoin(
          "decideScrollbar relative overflow-y-auto whitespace-pre-wrap text-gray-800 font-inter-normal-13px",
          inline ? "inline-flex max-w-full" : "flex w-full",
          !readOnly && "min-h-[50px]",
          fullHeight && "h-full",
          maxHeight ? maxHeight : !readOnly && defaultMaxHeight,
        )}
        data-loc={dataLoc}
      />
    );
  },
);
