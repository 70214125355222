import { useEffect, useRef, useState } from "react";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Pill } from "src/base-components/Pill";

type TruncatedIdPillProps = {
  id: string;
  dataLoc?: string;
};

export const TruncatedIdPill: React.FC<TruncatedIdPillProps> = ({
  id,
  dataLoc,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [truncatedText, setTruncatedText] = useState(id);

  useEffect(() => {
    const text = textRef.current;
    const container = containerRef.current;
    if (!container || !text) return;

    // If it fits, show everything
    text.textContent = id;
    if (text.scrollWidth <= container.clientWidth) {
      setTruncatedText(id);
      return;
    }

    const halfLength = Math.floor(id.length / 4);
    const middleTruncated = `${id.slice(0, halfLength)}...${id.slice(-halfLength)}`;
    text.textContent = middleTruncated;

    if (text.scrollWidth > container.clientWidth) {
      const lastFive = "..." + id.slice(-5);
      text.textContent = lastFive;
      setTruncatedText(lastFive);
    } else {
      setTruncatedText(middleTruncated);
    }
  }, [id]);

  return (
    <Pill dataLoc={dataLoc ?? "id-pill"} size="sm" variant="gray" maxWidth>
      <div ref={containerRef} className="overflow-hidden">
        <p ref={textRef} className="font-fira-code whitespace-nowrap">
          {truncatedText}
        </p>
      </div>
      <CopyTextIcon dataLoc={`copy-id-${id}`} size="3xs" value={id} />
    </Pill>
  );
};
