import { useCallback, useContext } from "react";

import {
  CodeEditor,
  dollarInvoker,
} from "src/base-components/CodeInput/CodeEditor";
import { AiNodeDataT } from "src/constants/NodeDataTypes";
import { Tooltip } from "src/design-system/Tooltip";
import {
  NodeEditorBaseProps,
  NodeEditorCompletionContext,
} from "src/nodeEditor/NodeEditor";

type CustomInvokeCodeEditorPropsT = {
  value: string;
  placeholder?: string;
  errorMessage?: string;
  onChange: (value: string | undefined) => void;
} & Omit<NodeEditorBaseProps<AiNodeDataT>, "onUpdate" | "displayedError">;

export const CustomInvokeCodeEditor: React.FC<CustomInvokeCodeEditorPropsT> = ({
  value,
  onChange,
  errorMessage,
  immutable,
  isReactive,
  placeholder,
}) => {
  const { defaultCompletion } = useContext(NodeEditorCompletionContext);

  const basicSetup = {
    lineNumbers: false,
    highlightActiveLine: false,
  };

  const onValueChange = useCallback(
    (newValue: string | undefined) => {
      if (!isReactive && value !== newValue) {
        onChange(newValue ?? "");
      }
    },
    [isReactive, value, onChange],
  );

  return (
    <Tooltip
      disabled={!errorMessage}
      placement="top"
      title={errorMessage}
      asChild
    >
      <div className="h-full w-full">
        <CodeEditor
          backgroundColor="white"
          basicSetup={basicSetup}
          completionExtension={
            defaultCompletion
              ? [defaultCompletion, dollarInvoker.extension]
              : undefined
          }
          dataLoc="prompt-editor"
          disabled={immutable}
          drawErrorBorder={!!errorMessage}
          fontFamily="inter"
          language="prompt"
          placeholder={placeholder}
          value={value}
          lineWrapping
          onChange={onValueChange}
        />
      </div>
    </Tooltip>
  );
};
