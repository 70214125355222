/* eslint import-x/namespace: ['error', { allowComputed: true }] */
import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import * as d3 from "d3";
import { capitalize, get, isDate } from "lodash";
import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useWatch } from "react-hook-form";

import { SummaryChartDimensions } from "src/analytics/types";
import { GenericObjectT } from "src/api/flowTypes";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { ChartAggregator, ChartFormat } from "src/clients/flow-api";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { formatDate } from "src/utils/datetime";
import { logger } from "src/utils/logger";
import { formatNumber } from "src/utils/numbers";
import { assertUnreachable } from "src/utils/typeUtils";

type SummaryChartValue = {
  value: number;
  label: string;
};

const useSummaryScale = (values: SummaryChartValue[]) => {
  const [scale, setScale] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      const scales = Array.from(container.children).map((child) => {
        if (child instanceof HTMLElement) {
          const scrollWidth = child.scrollWidth;
          const clientWidth = child.clientWidth;
          return scrollWidth > clientWidth ? clientWidth / scrollWidth : 1;
        }
        return 1;
      });

      const minScale = Math.min(...scales);
      if (minScale !== scale) {
        setScale(minScale);
      }
    }
    // We want to recompute the scale when the values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.map((v) => v.value).join("")]);

  return { scale, containerRef };
};

const SummaryChartBase: React.FC<{
  values: SummaryChartValue[];
  format: ChartFormat;
  warnings: Record<string, React.ReactNode>;
}> = ({ values, format, warnings }) => {
  const { scale, containerRef } = useSummaryScale(values);

  const isMultiVersion = values.length > 1;

  return (
    <>
      <div
        ref={containerRef}
        className="flex h-full w-full items-center gap-x-2"
        data-loc="summary-chart"
      >
        {values.map(({ value, label }, index) => (
          <React.Fragment key={label}>
            <div className="flex min-w-0 flex-1 flex-col gap-y-1">
              {isMultiVersion && (
                <div className="truncate text-gray-500 font-inter-normal-12px">
                  {label}
                </div>
              )}
              <div className="flex items-center gap-x-1.5">
                <div
                  className="origin-left text-gray-800 font-inter-semibold-32px"
                  data-loc="summary-chart-value"
                  style={{
                    transform: `scale(${scale})`,
                  }}
                >
                  {formatSummary(value, format)}
                </div>
                {warnings[label] && (
                  <Tooltip body={warnings[label]} placement="right">
                    <Icon
                      color="text-yellow-500"
                      dataLoc="summary-chart-warning-icon"
                      icon={faWarning}
                      size="xs"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            {index < values.length - 1 && (
              <Divider height="h-12" orientation="vertical" />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const SummaryChartPlaceholder: React.FC = () => {
  return (
    <SkeletonPlaceholder
      dataLoc="summary-chart-placeholder"
      height="h-10"
      width="w-10"
    />
  );
};

interface SummaryChartPreviewProps {
  data: GenericObjectT[];
  spec: SummaryChartDimensions;
}
/**
 * This component is supposed to be used in the ChartFormModal.
 * @param param0
 * @returns
 */
export const SummaryChartPreview: React.FC<SummaryChartPreviewProps> = ({
  data,
  spec,
}) => {
  const title = useWatch({ name: "title", defaultValue: "Untitled Chart" });

  return (
    <div className="flex min-w-[13rem] max-w-128 flex-col gap-y-2 rounded-lg border border-gray-200 bg-white p-4 pt-3">
      <div className="truncate font-inter-semibold-13px">{title}</div>
      <SummaryChart data={data} spec={spec} />
    </div>
  );
};

const ListOfTypes: React.FC<{ types: string[] }> = ({ types }) => {
  return (
    <>
      {types.map((type, index) => (
        <React.Fragment key={type}>
          {index > 0 && ", "}
          <Pill size="sm" variant="dark-gray">
            <Pill.Text fontType="code">{type}</Pill.Text>
          </Pill>
        </React.Fragment>
      ))}
    </>
  );
};

const validateSummary = (
  { aggregator, field }: SummaryChartDimensions,
  data: GenericObjectT[],
): undefined | ReactNode => {
  if (!field || !data.length) return;

  const fieldValuesTypes = new Set(
    data.map((d) => {
      const value = get(d, field, undefined);

      // We use transformed data, so we have "None", "False", "True" in data as a string
      if (value === "True" || value === "False") {
        return "Boolean";
      }

      if (value === "None") {
        return "None";
      }

      if (isDate(value)) {
        return "Date";
      }

      if (value === null) {
        return "None";
      }

      return capitalize(typeof value);
    }),
  );

  const isSingleTypeField =
    fieldValuesTypes.size === 1 ||
    // None is allowed as a type as well
    (fieldValuesTypes.has("None") && fieldValuesTypes.size === 2);

  switch (aggregator) {
    case ChartAggregator.COUNT:
    case ChartAggregator.SUM:
    case ChartAggregator.MEAN:
    case ChartAggregator.MEDIAN:
      if (isSingleTypeField && fieldValuesTypes.has("Number")) {
        return undefined;
      }

      if (!fieldValuesTypes.has("Number")) {
        return (
          <>
            This aggregator can only be applied to numeric values. Found:{" "}
            <ListOfTypes types={Array.from(fieldValuesTypes)} />
          </>
        );
      }

      return (
        <>
          The selected field has multiple types:{" "}
          <ListOfTypes types={Array.from(fieldValuesTypes)} />. Non-numeric
          values will be ignored, which may lead to unexpected results.
        </>
      );
    case ChartAggregator.MIN:
    case ChartAggregator.MAX:
      if (
        isSingleTypeField &&
        (fieldValuesTypes.has("Number") || fieldValuesTypes.has("Date"))
      ) {
        return undefined;
      }

      if (!fieldValuesTypes.has("Number") && !fieldValuesTypes.has("Date")) {
        return (
          <>
            This aggregator can only be applied to numeric or date values.
            Found: <ListOfTypes types={Array.from(fieldValuesTypes)} />
          </>
        );
      }

      return (
        <>
          The selected field has multiple types:{" "}
          <ListOfTypes types={Array.from(fieldValuesTypes)} />. Non-numeric and
          non-date values will be ignored, which may lead to unexpected results.
        </>
      );
    default:
      assertUnreachable(aggregator);
  }
};

const DEFAULT_LABEL = "Total";
export const SummaryChart: React.FC<{
  data: GenericObjectT[];
  spec: SummaryChartDimensions;
  isLoading?: boolean;
}> = ({ data, spec, isLoading }) => {
  if (isLoading) {
    return <SummaryChartPlaceholder />;
  }

  const { aggregator, field, format } = spec;

  if (!field) {
    return (
      <EmptyState
        description="Please select the key value to display the summary chart"
        headline="Key value is not set"
        icon={faWarning}
        variant="error"
      />
    );
  }

  const warnings = Object.fromEntries(
    d3.rollup(
      data,
      (values) => validateSummary(spec, values),
      (d) => d.__tktl_flow_version_name ?? DEFAULT_LABEL,
    ),
  );

  const values = Array.from(
    d3.rollup(
      data,
      (values) =>
        field ? (aggregateSummary(values, aggregator, field) ?? 0) : 0,
      (d) => d.__tktl_flow_version_name ?? DEFAULT_LABEL,
    ),
    ([label, value]) => ({ label, value }),
  ).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SummaryChartBase format={format} values={values} warnings={warnings} />
  );
};

const MAX_DIGITS = 3;

const formatSummary = (value: number | Date, format: ChartFormat): string => {
  // if value is a date, format it as a date
  if (isDate(value)) {
    return formatDate(value);
  }

  switch (format) {
    case ChartFormat.CURRENCY:
      return formatNumber(value, {
        style: "currency",
        currency: "USD",
      });
    case ChartFormat.PERCENT:
      return formatNumber(value, {
        style: "percent",
        maximumFractionDigits: MAX_DIGITS,
      });
    default:
      return formatNumber(value, {
        maximumFractionDigits: MAX_DIGITS,
      });
  }
};

type D3AggregatorFn = (
  data: GenericObjectT[],
  accessor: (d: GenericObjectT) => number | undefined,
) => number | undefined;

const aggregateSummary = (
  data: GenericObjectT[],
  aggregator: ChartAggregator,
  field: string,
) => {
  const aggregatorFn = d3[aggregator] as D3AggregatorFn;

  const values = data.filter((d) => {
    const value = get(d, field, undefined);

    if (
      aggregator === ChartAggregator.MIN ||
      aggregator === ChartAggregator.MAX
    ) {
      return isDate(value) || typeof value === "number";
    }

    return typeof value === "number";
  });

  try {
    return aggregatorFn(values, (d: GenericObjectT) =>
      get(d, field, undefined),
    );
  } catch {
    logger.error(`SummaryChart: Cannot aggregate ${aggregator} on ${field}`);
    return 0;
  }
};
