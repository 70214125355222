import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useGithbookAuth } from "src/gitbookAuth/api/queries";

const GITBOOK_PATH_QUERY_PARAM = "location";
const GITBOOK_DEFAULT_PATH = "/";

export const GitbookAuthPage: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryHash = window.location.hash;
  // Combining the `location` query param and the page anchor
  // to make sure that links to particular docs page sections work as expected
  const gitbookPath =
    (queryParams.get(GITBOOK_PATH_QUERY_PARAM) || GITBOOK_DEFAULT_PATH) +
    queryHash;

  const gitbookAuthResult = useGithbookAuth(gitbookPath);

  useEffect(() => {
    if (gitbookAuthResult.isSuccess) {
      window.location.replace(gitbookAuthResult.data);
    }
  }, [gitbookAuthResult.isSuccess, gitbookAuthResult.data]);

  if (gitbookAuthResult.isError) {
    return (
      <Navigate
        to={{
          pathname: "/error",
          search: `?${new URLSearchParams({
            error: "Failed to generate documents URL",
          }).toString()}`,
        }}
      />
    );
  }

  return (
    <h3 className="text-lg font-bold text-gray-800">
      Redirecting to the docs...
    </h3>
  );
};
