import { faUser } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Pill } from "src/base-components/Pill";
import { OrganizationUser } from "src/clients/taktile-api";
import { ClampedItems } from "src/entities/entityView/ClampedItems";
import { ReviewerAvatar } from "src/flowReview/ReviewStatusPills";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitiesParams } from "src/router/urls";
import { useCurrentUserId } from "src/store/AuthStore";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { getUserName } from "src/utils/user";

type AssigneePillProps = {
  assignee: OrganizationUser | undefined;
  assigneeId: string;
};

const UserNotFoundPill = ({ id }: { id: string }) => {
  return (
    <Pill size="sm" variant="gray">
      <Pill.Icon icon={faUser} />
      <Pill.Text>...{id.slice(-5)}</Pill.Text>
    </Pill>
  );
};

const AssigneePill: React.FC<AssigneePillProps> = ({
  assignee,
  assigneeId,
}) => {
  const { orgId } = useParamsDecode<EntitiesParams>();
  const currentUserId = useCurrentUserId();

  if (!assignee) {
    return <UserNotFoundPill id={assigneeId} />;
  }

  return (
    <Pill size="sm" variant="gray">
      <ReviewerAvatar orgId={orgId} userId={assignee.id} />
      <Pill.Text>
        {currentUserId === assignee.id ? "You" : getUserName(assignee)}
      </Pill.Text>
    </Pill>
  );
};

export const Assignees = ({ assignees }: { assignees: string[] }) => {
  const { orgId, workspace } = useWorkspaceContext();
  const { data: users } = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });

  const items = assignees.map((assigneeId) => {
    const user = users?.find((user) => user.id === assigneeId);
    return (
      <AssigneePill key={assigneeId} assignee={user} assigneeId={assigneeId} />
    );
  });

  return (
    <div className="flex items-center gap-1">
      <ClampedItems items={items} />
    </div>
  );
};
