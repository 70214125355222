import { Root } from "@radix-ui/react-accordion";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { AdvancedConfigV2 } from "src/aiNode/editorComponents/AdvancedConfigV2";
import { ModelSelector } from "src/aiNode/editorComponents/ModelSelector";
import { PromptEditorV2 } from "src/aiNode/editorComponents/PromptEditorV2";
import { ResponseMappingV2 } from "src/aiNode/editorComponents/ResponseMappingV2";
import { AiNodeV2Form, BasicModelCard } from "src/aiNode/types";
import {
  convertAiNodeV2BEToForm,
  convertAiNodeV2FormToBE,
} from "src/aiNode/utils";
import { useAvailableAiModels } from "src/api/connectApi/queries";
import { useWorkspace } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { AiNodeV2, AiNodeV2DataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type WrapperPropsT = {
  node: AiNodeV2;
  workspaceId: string;
} & NodeEditorBaseProps<AiNodeV2DataT>;

export const AiNodeV2Editor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const availableModels = useAvailableAiModels(workspace.data?.base_url);
  return (
    <LoadingView
      queryResult={availableModels}
      renderUpdated={(availableModels: BasicModelCard[]) => (
        <AiNodeV2EditorInternal
          availableModels={availableModels}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

type PropsT = {
  node: AiNodeV2;
  availableModels: BasicModelCard[];
} & NodeEditorBaseProps<AiNodeV2DataT>;

const AiNodeV2EditorInternal: React.FC<PropsT> = ({
  availableModels,
  immutable,
  node,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const currentFormValues: AiNodeV2Form = convertAiNodeV2BEToForm(node.data);

  const formProps = useForm({
    defaultValues: currentFormValues,
    ...(isReactive && { values: currentFormValues }),
  });

  useSubmitForm({
    onChange: (data: AiNodeV2Form) => {
      onUpdate({
        newData: convertAiNodeV2FormToBE(data),
      });
    },
    disabled: isReactive,
    previousValues: currentFormValues,
    watch: formProps.watch,
  });

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  return (
    <FormProvider {...formProps}>
      <form>
        <Root
          className={accordionRootClassName}
          defaultValue={["model", "prompt", "response", "config"]}
          type="multiple"
        >
          <AccordionItem
            className="pb-6 pr-5"
            disabled={immutable || renderedInDiffView}
            title="Model details"
            value="model"
          >
            <ModelSelector
              availableModels={availableModels}
              immutable={immutable}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-5"
            disabled={immutable || renderedInDiffView}
            title="Prompt"
            value="prompt"
          >
            <PromptEditorV2
              immutable={immutable}
              isReactive={isReactive}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-5"
            disabled={immutable || renderedInDiffView}
            title="Configure AI response"
            value="response"
          >
            <ResponseMappingV2
              immutable={immutable}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-5"
            disabled={immutable || renderedInDiffView}
            title="Advanced settings"
            value="config"
          >
            <AdvancedConfigV2
              immutable={immutable}
              isReactive={isReactive}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
        </Root>
      </form>
    </FormProvider>
  );
};
