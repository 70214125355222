import { capitalize } from "lodash";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import { ConnectionT } from "src/api/connectApi/types";
import { DropDown } from "src/base-components/DropDown";
import { Pill } from "src/base-components/Pill";
import { EnvironmentDropdownButton } from "src/baseConnectionNode/EnvironmentDropdownButton";
import {
  ConnectionDataSourcesLabels,
  ConnectionDataSourcesValues,
} from "src/baseConnectionNode/types";
import { Tooltip } from "src/design-system/Tooltip";
import { ConfigT } from "src/integrationNode/types";
import { ManifestConfigT } from "src/manifestConnectionNode/types";

const PRODUCTION_TARGET_ENVIRONMENT_TOOLTIP_CONTENT = (
  <>
    For live API calls to the{" "}
    <Pill size="sm" variant="dark-gray">
      <Pill.Text>/decide</Pill.Text>
    </Pill>{" "}
    endpoint, external calls always use production credentials. If caching is
    enabled, Taktile will use cached responses for this node.
  </>
);

type ManifestEnvironmentSelectProps = {
  title: string;
  defaultOption: string;
  description: React.ReactNode;
  immutable: boolean;
  handleEnvironmentChange: (value: string, key: string) => void;
  formProps: UseFormReturn<ManifestConfigT>;
  options: { key: string; value: string; disabled?: boolean }[];
  decideEnvironmentConfigKey: string;
  tooltipContent?: React.ReactNode;
};

const ManifestEnvironmentSelect = ({
  title,
  defaultOption,
  description,
  immutable,
  handleEnvironmentChange,
  formProps,
  options,
  decideEnvironmentConfigKey,
}: ManifestEnvironmentSelectProps) => {
  const value = formProps.watch(
    `environments_config.${decideEnvironmentConfigKey}`,
  );
  const isValidValue = options.some(
    (option) => option.key === value && !option.disabled,
  );
  const isMockOrEmpty = value === "mock_data" || value === "empty_response";

  useEffect(() => {
    if (!isMockOrEmpty && !isValidValue && !immutable) {
      formProps.setValue(
        `environments_config.${decideEnvironmentConfigKey}`,
        defaultOption,
      );
      handleEnvironmentChange(defaultOption, decideEnvironmentConfigKey);
    }
  }, [
    value,
    options,
    defaultOption,
    decideEnvironmentConfigKey,
    formProps,
    handleEnvironmentChange,
    isMockOrEmpty,
    isValidValue,
    immutable,
  ]);

  return (
    <div className="mb-4 flex h-full w-full flex-row justify-between last:mb-0">
      <div className="flex h-full flex-col">
        <h3 className="text-gray-800 font-inter-medium-12px">{title}</h3>
        <span className="text-gray-500 font-inter-normal-12px">
          {description}
        </span>
      </div>
      <div className="w-[188px]">
        <Controller
          control={formProps.control}
          defaultValue={defaultOption}
          name={`environments_config.${decideEnvironmentConfigKey}`}
          render={({ field: { onChange, value } }) => (
            <DropDown<string>
              disabled={immutable}
              elements={options.map(({ key, value, disabled }) => ({
                key,
                value: value as string,
                disabled,
              }))}
              itemsClassNames="w-full"
              renderButtonValue={(value) => {
                return (
                  <Tooltip
                    body={PRODUCTION_TARGET_ENVIRONMENT_TOOLTIP_CONTENT}
                    disabled={decideEnvironmentConfigKey !== "live"}
                    asChild
                  >
                    <div>
                      <EnvironmentDropdownButton value={value} />
                    </div>
                  </Tooltip>
                );
              }}
              renderValue={({ key, value, disabled }) => (
                <Tooltip
                  body={`${value} are not configured for this connection. Please add the credentials in the connection settings.`}
                  disabled={!disabled}
                  //   INT-4166 allow direct connection edit if admin
                  footerAction={{
                    text: "Read more",
                    onClick: () =>
                      window.open(
                        "https://docs.taktile.com/decision-flow-fundamentals/node-types/integration-nodes#configuring-the-connection",
                      ),
                  }}
                  placement="left"
                  asChild
                >
                  <div
                    className={`flex items-center justify-between text-nowrap px-5 py-4 ${key === "mock_data" && options.length !== 1 && "border-b"} ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
                    data-loc={`dropdown-item-${key}`}
                  >
                    <span className="text inline-block">{value}</span>
                  </div>
                </Tooltip>
              )}
              selectedKey={value}
              onSelect={(selected) => {
                onChange(selected);
                handleEnvironmentChange(selected, decideEnvironmentConfigKey);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

type ManifestTargetEnvironmentConfigProps = {
  config: ManifestConfigT;
  connection: ConnectionT;
  immutable: boolean;
  isReactive: boolean;
  formProps: UseFormReturn<ManifestConfigT>;
  manifest: ManifestIntegrationProvider;
  onUpdate: (updatedConfig: ConfigT) => void;
};

export const ManifestTargetEnvironmentConfig = ({
  config,
  connection,
  immutable,
  isReactive,
  formProps,
  manifest,
  onUpdate,
}: ManifestTargetEnvironmentConfigProps) => {
  const handleEnvironmentChange = (value: string, key: string) => {
    const updatedConfig = {
      ...config,
      environments_config: {
        ...config.environments_config,
        [key]: value,
      },
    };
    onUpdate(updatedConfig);
  };

  const availableNonProductionEnvironments = Object.entries(
    manifest.environment_configs,
  )
    .filter(([key]) => key !== "production")
    .map(([key, value]) => ({
      key,
      value: value?.display_name
        ? `${value.display_name} credentials`
        : `${capitalize(key)} credentials`,
      disabled: !connection.environment_configurations?.[key],
    }));

  useEffect(() => {
    if (isReactive) {
      formProps.reset(config);
    }
  }, [isReactive, config, formProps]);

  return (
    <div>
      <ManifestEnvironmentSelect
        decideEnvironmentConfigKey="authoring_mode_data_source"
        defaultOption={ConnectionDataSourcesValues.mock_data}
        description="Test runs using the button in the UI"
        formProps={formProps}
        handleEnvironmentChange={handleEnvironmentChange}
        immutable={immutable}
        options={[
          {
            key: ConnectionDataSourcesValues.mock_data,
            value: ConnectionDataSourcesLabels.mock_data,
          },
          ...availableNonProductionEnvironments,
        ]}
        title="For test runs, use"
      />
      <ManifestEnvironmentSelect
        decideEnvironmentConfigKey="sandbox_mode_data_source"
        defaultOption={ConnectionDataSourcesValues.mock_data}
        description={
          <>
            API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/sandbox/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        formProps={formProps}
        handleEnvironmentChange={handleEnvironmentChange}
        immutable={immutable}
        options={[
          {
            key: ConnectionDataSourcesValues.mock_data,
            value: ConnectionDataSourcesLabels.mock_data,
          },
          {
            key: ConnectionDataSourcesValues.empty_response,
            value: ConnectionDataSourcesLabels.empty_response,
          },
          ...availableNonProductionEnvironments,
        ]}
        title="For sandbox API calls, use"
      />
      <ManifestEnvironmentSelect
        decideEnvironmentConfigKey="live"
        defaultOption={ConnectionDataSourcesValues.production}
        description={
          <>
            Production API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        formProps={formProps}
        handleEnvironmentChange={handleEnvironmentChange}
        immutable={true}
        options={[
          {
            key: ConnectionDataSourcesValues.production,
            value: ConnectionDataSourcesLabels.production,
          },
        ]}
        title="For live API calls, use"
      />
    </div>
  );
};
