import {
  faCube,
  faExternalLink,
  faQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { capitalize, omitBy } from "lodash";
import React, { useMemo } from "react";
import { twJoin } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";
import { validate as validateUUID } from "uuid";

import { GenericObjectT } from "src/api/flowTypes";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { DataValue } from "src/base-components/DataList";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, TableComp } from "src/base-components/Table";
import { PropertyDefinitionOutputTypeEnum } from "src/clients/features-control";
import { EntityFeatureValue } from "src/clients/features-retrieve/api";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import { TruncatedIdPill } from "src/decisionsOverview/TruncatedIdPill";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { ClampedItems } from "src/entities/entityView/ClampedItems";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";
import {
  findSchema,
  getSchemaIcon,
  getTableData,
  transformEnumValue,
} from "src/entities/entityView/utils";
import {
  Cardinality,
  EntityFieldType,
  EntityFieldUrlType,
  EntityPropertyValue,
  EntityResource,
  EntityResourceDataplane,
  EntitySchemaProperty,
  EntitySchemaResource,
  TableRow,
  useEntity,
  useEntityRelatedEntities,
  useEntitySchemas,
} from "src/entities/queries";
import { RelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { PROPERTY_TYPES_TO_ICONS } from "src/eventsCatalogue/SchemaEditor/utils";
import { EnumPill } from "src/eventsCatalogue/tableConfig";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { useEntityFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { useIsFWDOrAdmin } from "src/store/AuthStore";
import { copyTextToClipboard } from "src/utils/clipboard";
import { stringifiedObjectSpeakPython } from "src/utils/speakPython";

type RenderValueFnProps = {
  property: EntitySchemaProperty;
  propertyValue: EntityPropertyValue;
  isWindowPill: boolean;
  foreginKeyToPrimaryProperty: EntityResource["_fk_pp"];
};

type RenderByTypeFnProps = Omit<RenderValueFnProps, "propertyValue"> & {
  propertyValue: Exclude<EntityPropertyValue, string[]>;
};

type TableActions = {
  getForeignKeyMap: () => EntityResource["_fk_pp"];
};

const JsonContent: React.FC<{ content: GenericObjectT; title: string }> = ({
  title,
  content,
}) => {
  const { value: isOpen, setTrue, setFalse } = useBoolean(false);
  const stringValue = JSON.stringify(content);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => setTrue()}
      onMouseLeave={() => setFalse()}
    >
      <FloatingWindow
        button={
          <div
            className={twJoin(
              "h-full w-80 max-w-full cursor-default",
              isOpen && "bg-indigo-100",
            )}
          >
            <div className="truncate">
              {stringifiedObjectSpeakPython(stringValue)}
            </div>
          </div>
        }
        draggable={false}
        isOpen={isOpen}
        maximizable={false}
        pinnable={false}
        title={title}
        onClose={setFalse}
        onCopy={() => copyTextToClipboard(stringValue)}
      >
        <ObjectDetailPane
          innerDimensionClass="h-full"
          jsonObject={JSON.parse(stringValue) as GenericObjectT}
        />
      </FloatingWindow>
    </div>
  );
};

const getType = (info: CellContext<TableRow, EntityPropertyValue>) =>
  info.row.original._type;

const renderByType = ({
  property,
  propertyValue,
  isWindowPill,
  foreginKeyToPrimaryProperty,
}: RenderByTypeFnProps) => {
  const { _type: propertyType } = property;

  if (propertyValue === null || propertyValue === undefined) {
    return null;
  }

  if (
    propertyType === PropertyDefinitionOutputTypeEnum.RELATION &&
    property._rel_schema &&
    typeof propertyValue === "string"
  ) {
    const Comp = isWindowPill ? EntityDetailsWindowPill : LinkedEntityPill;
    const name =
      foreginKeyToPrimaryProperty?.[
        `${property._rel_schema}.${propertyValue}`
      ] ?? propertyValue;
    return (
      <div className="mr-1 max-w-full">
        <Comp
          entityIcon={faCube}
          id={propertyValue}
          name={name}
          schemaId={property._rel_schema}
        />
      </div>
    );
  }

  if (typeof propertyValue === "string" && validateUUID(propertyValue)) {
    return <CopyTextIcon value={propertyValue} />;
  }

  if (propertyType === "url") {
    const link = propertyValue as unknown as EntityFieldUrlType;
    return (
      <div
        className="mr-1 overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <a
          className="flex items-center gap-x-1 truncate hover:text-indigo-500"
          href={link.url}
          rel="noreferrer noopener"
          target="_blank"
        >
          {link.name ?? link.url}
          <Icon icon={faExternalLink} size="2xs" />
        </a>
      </div>
    );
  }

  if (typeof propertyValue === "object") {
    return (
      <JsonContent content={propertyValue} title={capitalize(propertyType)} />
    );
  }

  if (["enum", "tags", "list"].includes(propertyType)) {
    const enumValue = transformEnumValue(property._values ?? [], propertyValue);
    return (
      <div className="mr-1 overflow-hidden">
        {enumValue ? (
          <EnumPill enumValue={enumValue} />
        ) : (
          <Pill size="sm" variant="gray" fullWidth>
            <Pill.Text>
              {typeof propertyValue === "string" ? (
                propertyValue
              ) : (
                <DataValue field="" value={propertyValue} />
              )}
            </Pill.Text>
          </Pill>
        )}
      </div>
    );
  }

  return (
    <div className="cursor-text" onClick={(e) => e.stopPropagation()}>
      <DataValue field="" value={propertyValue} />
    </div>
  );
};

export const renderValue = ({
  property,
  propertyValue,
  isWindowPill,
  foreginKeyToPrimaryProperty,
}: RenderValueFnProps) => {
  if (Array.isArray(propertyValue)) {
    const mapped = propertyValue.map((val) => (
      <React.Fragment key={String(val)}>
        {renderByType({
          property,
          propertyValue: val,
          isWindowPill,
          foreginKeyToPrimaryProperty,
        })}
      </React.Fragment>
    ));

    return <ClampedItems items={mapped} />;
  }

  return renderByType({
    property,
    propertyValue,
    isWindowPill,
    foreginKeyToPrimaryProperty,
  });
};

export const TypeIcon = ({
  type,
  cardinality,
}: {
  type: EntityFieldType;
  cardinality: Cardinality | undefined;
}) => {
  if (type === "relation") {
    return (
      <div className="shrink-0">
        <RelationIcon cardinality={cardinality ?? Cardinality.ONE} size="2xs" />
      </div>
    );
  }

  return (
    <Icon
      color="text-gray-500"
      icon={PROPERTY_TYPES_TO_ICONS[type] ?? faQuestion}
      size="2xs"
    />
  );
};

const columnHelper = createColumnHelper<TableRow>();

export const getEntityDetailsColumns = (isWindowPill: boolean) => [
  columnHelper.accessor("_display_name", {
    header: () => null,
    size: 129,
    maxSize: 129,
    cell: (info: CellContext<TableRow, EntityPropertyValue>) => {
      const type = getType(info);
      const cardinality = info.row.original._cardinality;
      return (
        <Cell info={info}>
          <TypeIcon cardinality={cardinality} type={type} />
          <Tooltip title={info.row.original._display_name} asChild>
            <p className="truncate capitalize">
              {info.row.original._display_name}
            </p>
          </Tooltip>
        </Cell>
      );
    },
  }),
  columnHelper.accessor("value", {
    header: () => null,
    size: 189,
    maxSize: 189,
    cell: (info: CellContext<TableRow, EntityPropertyValue>) => {
      const fkMap = (
        info.table.options.meta?.actions as TableActions
      )?.getForeignKeyMap();
      return info.row.original.isId ? (
        <Cell info={info}>
          <TruncatedIdPill id={info.getValue() as string} />
        </Cell>
      ) : (
        <Cell className="max-w-full" info={info}>
          {renderValue({
            property: info.row.original,
            propertyValue: info.getValue(),
            isWindowPill,
            foreginKeyToPrimaryProperty: fkMap,
          })}
        </Cell>
      );
    },
  }),
];

type EntityDetailsSidePaneProps = {
  entityId: string;
  schemaId: string;
  showHeader?: boolean;
  isWindowPill?: boolean;
};

type EntityDetailsHeadeProps = {
  entitySchema: EntitySchemaResource;
  entity: EntityResource;
};

export type RelatedEntityConfig = {
  entityType: string;
  entityId: string;
};

export type RelatedEntityDataWithPath = {
  key: string;
  data: EntityResourceDataplane;
};

const parseRelatedEntities = (
  related: Record<string, any>,
): Record<string, RelatedEntityConfig> => {
  return Object.entries(related).reduce(
    (parsedEntities, [key, value]) => {
      if (key.endsWith("._schema")) {
        const entityPath = key.replace("._schema", "");
        parsedEntities[entityPath] = {
          entityType: value.split(".").at(-1),
          entityId: related[entityPath].split(".").at(-1),
        };
      }
      return parsedEntities;
    },
    {} as Record<string, RelatedEntityConfig>,
  );
};

const cleanData = (data: Record<string, any>): Record<string, any> => {
  return {
    ...omitBy(data, (_value, key) => key.startsWith("_")),
    id: data._id,
  };
};

/**
 * Processes a related entity and adds it to the relatedEntities object, maintaining the nested path structure.
 * For example, a key like "customer.address" will create a nested structure: { customer: { address: { ...data } } }
 */
export const processRelatedEntity = (
  entity: RelatedEntityDataWithPath,
  relatedEntities: Record<string, any>,
) => {
  if (!entity?.key || !entity.data) return;

  const pathParts = entity.key.split(".");
  let current = relatedEntities;

  pathParts.forEach((part, index) => {
    if (index === pathParts.length - 1) {
      current[part] = { ...cleanData(entity.data), ...current[part] };
    } else {
      current[part] = current[part] || {};
      current = current[part];
    }
  });
};

const constructEntityJson = (
  entity: EntityResource,
  relatedEntitiesData: RelatedEntityDataWithPath[],
  entityFeatures: EntityFeatureValue[],
) => {
  const relatedEntities: Record<string, any> = {};
  const cleanedEntity = cleanData({
    ...entity.properties,
    _id: entity._id,
  });
  const sortedByPathDepthRelatedEntities = [...relatedEntitiesData].sort(
    (a, b) => b.key.split(".").length - a.key.split(".").length,
  );
  sortedByPathDepthRelatedEntities.forEach((entity) => {
    processRelatedEntity(entity, relatedEntities);
  });

  const entityFeaturesComputed = entityFeatures.reduce(
    (acc, feature) => {
      acc[feature.key] = feature.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  return {
    ...cleanedEntity,
    ...relatedEntities,
    features: entityFeaturesComputed,
  };
};

const CopyEntityJsonButton: React.FC<{
  entity: EntityResource;
}> = ({ entity }) => {
  const { workspace } = useWorkspaceContext();
  const [env] = useEnvironment();
  const { data: entityFeatures } = useEntityFeatures(
    env,
    entity._id,
    entity._schema,
  );

  const relatedEntitiesConfig = useMemo(
    () => parseRelatedEntities(entity._related ?? {}),
    [entity._related],
  );
  const relatedEntitiesData = useEntityRelatedEntities(
    relatedEntitiesConfig,
    workspace.base_url ?? "",
    env,
  );

  const entityJson = useMemo(() => {
    return JSON.stringify(
      constructEntityJson(
        entity,
        relatedEntitiesData
          .map((d) => d.data)
          .filter((d): d is RelatedEntityDataWithPath => !!d),
        entityFeatures?.data ?? [],
      ),
      null,
      2,
    );
  }, [entity, relatedEntitiesData, entityFeatures?.data]);

  return (
    <CopyTextIcon
      dataLoc="copy-entity-data"
      feedback="inline"
      tooltip="Copy Entity as JSON"
      tooltipPlacement="bottom"
      value={entityJson}
    />
  );
};

const EntityDetailsHeader: React.FC<EntityDetailsHeadeProps> = ({
  entitySchema,
  entity,
}) => {
  const isFWDOrAdmin = useIsFWDOrAdmin();
  const [primaryProperty, secondaryProperty] = [
    entity.properties[entitySchema._primary_property],
    entity.properties[entitySchema._secondary_property],
  ];

  return (
    <div className="flex justify-between">
      <div className="flex gap-3">
        <span className="rounded-lg border border-gray-200 p-2">
          <Icon
            color="text-gray-600"
            icon={getSchemaIcon(entitySchema)}
            size="md"
          />
        </span>
        <div>
          <p className="font-inter-normal-13px">
            {typeof primaryProperty === "object"
              ? JSON.stringify(primaryProperty)
              : primaryProperty}
          </p>
          <p className="text-gray-500 font-inter-medium-11px">
            {typeof secondaryProperty === "object"
              ? JSON.stringify(secondaryProperty)
              : secondaryProperty}
          </p>
        </div>
      </div>
      <div>{isFWDOrAdmin && <CopyEntityJsonButton entity={entity} />}</div>
    </div>
  );
};

export const EntityDetailsSidePane: React.FC<EntityDetailsSidePaneProps> = ({
  entityId,
  schemaId,
  showHeader = true,
  isWindowPill = false,
}) => {
  const { workspace } = useWorkspaceContext();
  const [env] = useEnvironment();
  const { data: entitySchemas, isLoading: isLoadingSchemas } = useEntitySchemas(
    {
      baseUrl: workspace.base_url ?? "",
    },
  );
  const { data: entityData, isLoading: isLoadingEntity } = useEntity({
    schema: schemaId,
    env,
    baseUrl: workspace.base_url ?? "",
    entityId,
    enabled: !isLoadingSchemas,
  });

  const [tableData, entitySchema] = useMemo(() => {
    const entitySchema = findSchema(schemaId, entitySchemas);

    return [getTableData(entitySchema, entityData), entitySchema];
  }, [schemaId, entityData, entitySchemas]);

  const tableActions: TableActions = useMemo(
    () => ({
      getForeignKeyMap: () => entityData?._fk_pp,
    }),
    [entityData?._fk_pp],
  );

  const columns = useMemo(
    () => getEntityDetailsColumns(isWindowPill),
    [isWindowPill],
  );

  if (!isLoadingEntity && !entityData) {
    return (
      <EmptyState
        description="Unable to load entity"
        headline="Error"
        icon={faCube}
        variant="default"
      />
    );
  }

  return (
    <div>
      {showHeader && entitySchema && entityData && (
        <>
          <EntityDetailsHeader
            entity={entityData}
            entitySchema={entitySchema}
          />
          <Divider spacing="my-4" />
        </>
      )}
      <TableComp
        actions={tableActions}
        columns={columns}
        data={tableData}
        frameClassName="[&_thead_tr]:hidden [&_table]:border-b-0"
        isLoading={isLoadingSchemas || isLoadingEntity}
        variant="compact"
      />
    </div>
  );
};
