import { useState } from "react";

import { CustomPopover } from "src/base-components/Popover";
import { Select } from "src/base-components/Select";
import {
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { Cardinality } from "src/entities/queries";
import { RelationshipPopoverContent } from "src/eventsCatalogue/SchemaEditor/RelationshipPopoverContent";
import { SchemaPropertyIcon } from "src/eventsCatalogue/SchemaEditor/utils";

type PropertyTypeSelectorProps = {
  value: PropertyDefinitionOutputTypeEnum;
  property: PropertyDefinitionOutput;
  singularDisplayName: string;
  pluralDisplayName: string;
  schemaId?: string;
  relSchema?: string;
  relationCardinality?: Cardinality;
};

export const PropertyTypeSelector: React.FC<PropertyTypeSelectorProps> = ({
  value,
  property,
  schemaId,
  relSchema,
  singularDisplayName,
  pluralDisplayName,
  relationCardinality = Cardinality.ONE,
}) => {
  const [open, setOpen] = useState(false);

  if (value === "relation" && property) {
    const displayName = property.display_name ?? property.type;
    return (
      <CustomPopover
        button={
          <Select
            options={[
              ...PROPERTY_TYPE_OPTIONS,
              {
                key: value,
                value: (
                  <div className="flex items-center gap-x-1.5">
                    <SchemaPropertyIcon
                      cardinality={relationCardinality}
                      property={property.type}
                    />
                    <div className="truncate text-gray-500 font-inter-normal-12px">
                      {relSchema ?? displayName}
                    </div>
                  </div>
                ),
              },
            ]}
            placeholder="Select type"
            value={value}
            disabled
            fullWidth
          />
        }
        className="w-70"
        isOpen={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <RelationshipPopoverContent
          cardinality={relationCardinality}
          pluralDisplayName={pluralDisplayName}
          propertyDisplayName={displayName}
          relatedSchemaId={property.relation_schema?.entity_type ?? ""}
          schemaId={schemaId}
          singularDisplayName={singularDisplayName}
        />
      </CustomPopover>
    );
  }

  return (
    <Select
      options={PROPERTY_TYPE_OPTIONS}
      placeholder="Select type"
      value={value}
      disabled
      fullWidth
    />
  );
};
const PROPERTY_TYPE_OPTIONS = Object.values(PropertyDefinitionOutputTypeEnum)
  // Relation is specific type, we add it on the go in component
  .filter((type) => type !== PropertyDefinitionOutputTypeEnum.RELATION)
  .map((type) => ({
    key: type,
    value: (
      <div className="flex items-center gap-x-1.5">
        <SchemaPropertyIcon
          property={type as PropertyDefinitionOutputTypeEnum}
        />
        <div className="truncate text-gray-500 font-inter-normal-12px">
          {type}
        </div>
      </div>
    ),
  }));
