import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { JsonSchemaRow } from "src/aiNode/editorComponents/JsonSchemaRow";
import { AiNodeV2Form } from "src/aiNode/types";
import { getAiNodeNewProperty } from "src/aiNode/utils";
import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { PillToggle } from "src/base-components/PillToggle";
import { AiNodeV2ResponseFormatConfigTypeEnum } from "src/clients/flow-api";

type ResponseMappingPropsT = {
  runFieldErrors: FieldErrorsT | undefined;
  immutable: boolean;
};

const TextOutputField: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<AiNodeV2Form>();

  const fieldPath = `response.default`;

  const error = runFieldErrors?.[formProps.getValues(`${fieldPath}.id`)];

  return (
    <Card className="flex w-full flex-row items-center gap-x-3">
      <div className="flex text-gray-800 font-inter-medium-12px">
        Output location
      </div>
      <Controller
        control={formProps.control}
        name={`${fieldPath}.mapped_to`}
        render={({ field: controlledField }) => (
          <AutocompleteCodeInput
            dataLoc={`ai-node-${fieldPath}.mappedTo`}
            disabled={immutable}
            error={error}
            placeholder="ai_response"
            prefix="data."
            value={controlledField.value}
            onChange={controlledField.onChange}
          />
        )}
      />
    </Card>
  );
};

const TextOutputMapping: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-gray-500 font-inter-normal-12px">
        Get the AI's response as unstructured text. Choose this when you need
        more flexible, human-readable outputs.
      </div>
      <TextOutputField immutable={immutable} runFieldErrors={runFieldErrors} />
    </div>
  );
};

const JsonSchemaHeader: React.FC = () => (
  // Replicating the structure of the JsonSchemaRow to align headers and fields
  <div className="flex gap-x-2 text-gray-500 font-inter-normal-12px">
    {/* Empty span with w-5 is replacing the ReorderHandler */}
    <span className="w-5"></span>
    <span className="flex-1">Name</span>
    <span className="w-36">Type</span>
    {/* Empty span with w-[22px] is replacing the bin icon*/}
    <span className="w-[22px]"></span>
  </div>
);

const JsonSchemaEditor: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<AiNodeV2Form>();
  const control = formProps.control;

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "inference.response_format.json_schema.properties",
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  const onAddField = () => {
    if (immutable) {
      return;
    }
    append(getAiNodeNewProperty());
  };

  const onRemoveField = (index: number) => {
    if (immutable) {
      return;
    }
    remove(index);
  };

  // NOTE: we'll need to extract errors both from
  // `inference.response_format.json_schema` and `response` sections
  const _runFieldErrors = runFieldErrors;

  return (
    <Card className="w-full space-y-2 pt-4">
      <JsonSchemaHeader />
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext
          disabled={immutable}
          items={fields.map((row) => row.id)}
        >
          {fields &&
            fields.map((field, index) => (
              <JsonSchemaRow
                key={field.id}
                handleRemove={() => onRemoveField(index)}
                id={field.id}
                immutable={immutable}
                index={index}
              />
            ))}
        </SortableContext>
      </DndContext>
      <Button
        dataLoc="add-ai-node-jsonschema-field"
        disabled={immutable}
        iconLeft={faAdd}
        size="sm"
        variant="secondary"
        onClick={onAddField}
      >
        Add field
      </Button>
    </Card>
  );
};

const JsonOutputMapping: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-gray-500 font-inter-normal-12px">
        Structure the AI's output as a JSON object to easily process and use the
        response in your Decision Flow. This ensures consistent, parsable
        outputs.
      </div>
      <JsonSchemaEditor immutable={immutable} runFieldErrors={runFieldErrors} />
    </div>
  );
};

export const ResponseMappingV2: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<AiNodeV2Form>();
  const responseType = formProps.getValues("inference.response_format.type");
  const [selectedType, setSelectedType] =
    useState<AiNodeV2ResponseFormatConfigTypeEnum>(responseType);

  const updateResponseType = (type: AiNodeV2ResponseFormatConfigTypeEnum) => {
    if (!immutable) {
      setSelectedType(type);
      formProps.setValue("inference.response_format.type", type);
    }
  };

  return (
    <>
      <div className="mb-4">
        <PillToggle value={selectedType} onChange={updateResponseType}>
          <PillToggle.Button value={AiNodeV2ResponseFormatConfigTypeEnum.JSON}>
            Structured output
          </PillToggle.Button>
          <PillToggle.Button value={AiNodeV2ResponseFormatConfigTypeEnum.TEXT}>
            Text output
          </PillToggle.Button>
        </PillToggle>
      </div>

      {selectedType === AiNodeV2ResponseFormatConfigTypeEnum.JSON && (
        <JsonOutputMapping
          immutable={immutable}
          runFieldErrors={runFieldErrors}
        />
      )}
      {selectedType === AiNodeV2ResponseFormatConfigTypeEnum.TEXT && (
        <TextOutputMapping
          immutable={immutable}
          runFieldErrors={runFieldErrors}
        />
      )}
    </>
  );
};
