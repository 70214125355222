import { maxBy, uniqueId } from "lodash";
import { useMemo } from "react";

import { jobsApi } from "src/api/exporterApi";
import { DecisionEnvironment } from "src/api/types";
import { useModal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { useJobSources, useRunJob } from "src/jobs/api/queries";
import { isJobRunnable } from "src/jobs/jobUtils";
import { Job, JobRun, JobRunStatusType } from "src/jobs/types";
import { useEnvironmentFilterAsQueryParam } from "src/jobs/useEnvironmentFilterAsQueryParam";
import {
  useWorkspaceContext,
  useFlowContext,
} from "src/router/routerContextHooks";

export const useJobSource = <S>(jobId: string, sourceId?: string) => {
  const { workspace } = useWorkspaceContext();
  const sources = useJobSources(workspace.base_url!, jobId);

  if (!sourceId) return;

  return sources.data?.find((source) => source.id === sourceId) as S;
};

export const useRunJobHandler = (job: Job) => {
  const { isOpen, closeModal, afterLeave, openModal } = useModal();
  const jobRunnable = isJobRunnable(job);

  const { flow, workspace } = useFlowContext();

  const runJob = useRunJob(workspace.base_url!, job.id, flow.id);

  const [, setEnvironmentFilter] = useEnvironmentFilterAsQueryParam();

  const handleRunJob = async (environment: DecisionEnvironment) => {
    const toastId = uniqueId();
    let createdRun: JobRun | undefined;
    try {
      createdRun = await runJob.mutateAsync(environment);
      closeModal();
      toastActions.loading({
        id: toastId,
        title: "Running your Job...",
        description:
          "The process will continue in the background even when this notification is dismissed.",
        duration: Infinity,
      });
      setEnvironmentFilter(environment);
    } catch {
      toastActions.failure({
        id: toastId,
        title: "Oops, something went wrong.",
        description: "Please try again",
      });
    }
    if (!createdRun) return;

    while (
      createdRun.status.type === JobRunStatusType.Running ||
      createdRun.status.type === JobRunStatusType.Writing
    ) {
      await new Promise((r) => setTimeout(r, 5000));
      createdRun = (
        await jobsApi.get<JobRun>(
          `/jobs/${createdRun.job_id}/runs/${createdRun.id}/poll`,
          {
            baseURL: workspace.base_url!,
          },
        )
      ).data;
    }

    if (createdRun.status.type === JobRunStatusType.Completed)
      toastActions.success({ id: toastId, title: "Job run finished" });
    else if (createdRun.status.type === JobRunStatusType.Failed)
      toastActions.failure({ id: toastId, title: "Job run failed" });
    else if (createdRun.status.type === JobRunStatusType.Stopped)
      toastActions.success({ id: toastId, title: "Job run stopped" });
  };

  return {
    onConfirm: handleRunJob,
    isJobRunnable: jobRunnable,
    isOpen,
    afterLeave,
    openModal,
    closeModal,
  };
};

export const useUsedVersions = (job?: Job) => {
  const { flow } = useFlowContext();

  return useMemo(() => {
    if (!job) return [];

    const usedVersionIds = job.active_traffic_policy
      ? job.active_traffic_policy.policy.flow_versions.map(
          (v) => v.flow_version_id,
        )
      : job.flow_version_id
        ? [job.flow_version_id]
        : [];

    return flow.versions.filter((v) => usedVersionIds.includes(v.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.active_traffic_policy?.id, job?.flow_version_id, flow.versions]);
};

export const useDominantVersion = (job: Job) => {
  const usedVersions = useUsedVersions(job);

  return useMemo(() => {
    const policy = job.active_traffic_policy?.policy;
    if (!policy) return usedVersions.at(0);

    const versionWithMostPercentage = maxBy(
      usedVersions,
      (version) =>
        policy.flow_versions.find(
          (flowVersion) => flowVersion.flow_version_id === version.id,
        )?.percentage ?? 0,
    );

    return versionWithMostPercentage ?? usedVersions.at(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.active_traffic_policy?.id, usedVersions]);
};
