import React from "react";

import { useReviewCase } from "src/api/queries";
import { ManualReviewFieldList } from "src/entities/entityView/DecisionsSidePane/ManualReviewFieldList";

type Props = {
  id: string;
  flowSlug: string;
  baseUrl: string | undefined;
};

export const ManualReviewInspectData: React.FC<Props> = ({
  id,
  flowSlug,
  baseUrl,
}) => {
  const { data, isLoading } = useReviewCase(baseUrl, flowSlug, id);
  const tableData = Object.entries(data?.inspect_data ?? {}).map(
    ([key, value]) => ({
      key,
      value,
    }),
  );

  return <ManualReviewFieldList data={tableData} isLoading={isLoading} />;
};
