import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useConnections } from "src/api/connectApi/queries";
import RetoolIcon from "src/assets/RetoolMenuIcon.svg?react";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { UserMenu } from "src/layout/UserMenuV2";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getUrlToRetoolPage, DashboardPageParamsT } from "src/router/urls";
import { useCurrentPage } from "src/router/useCurrentPage";
import { useParamsDecode } from "src/utils/useParamsDecode";

type BreadcrumbProps =
  | {
      label: string;
      href: string;
      children?: never;
      dataLoc?: string;
      hideIcon?: boolean;
      active?: boolean;
    }
  | {
      children: React.ReactNode;
      dataLoc?: string;
      hideIcon?: boolean;
      label?: never;
      href?: never;
      active?: never;
    };

type BreadcrumbType = React.FC<BreadcrumbProps>;

type Props =
  | {
      title: React.ReactNode;
      breadcrumbs?: never;
      navigation?: ArrowNavigationProps;
      sideContent?: React.ReactNode;
    }
  | {
      title?: never;
      breadcrumbs: { key: string; breadcrumb: ReturnType<BreadcrumbType> }[];
      navigation?: ArrowNavigationProps;
      sideContent?: React.ReactNode;
    };

type SubComponents = {
  Breadcrumb: BreadcrumbType;
};

type NavigationProps = {
  url: string;
  disabled: boolean;
};

type ArrowNavigationProps = {
  previous: NavigationProps;
  next: NavigationProps;
};

const ProfileSideContent = () => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const { workspace } = useWorkspaceContext();

  const connections = useConnections(workspace.base_url);
  const retoolConnection = connections.data?.find(
    (c) => c.provider === "retool",
  );
  const location = useLocation();

  const retoolPageUrl = getUrlToRetoolPage(orgId, wsId, retoolConnection?.id);
  const isCurrentPageRetool =
    `${location.pathname}${location.search}` === `${retoolPageUrl}`;

  return (
    <div className="mr-3 flex items-center gap-4">
      {retoolPageUrl && (
        <Tooltip placement="bottom-end" title={retoolConnection?.name} asChild>
          <Link
            className={
              isCurrentPageRetool ? "text-indigo-500" : "text-gray-700"
            }
            to={retoolPageUrl}
          >
            <RetoolIcon />
          </Link>
        </Tooltip>
      )}
      <UserMenu />
    </div>
  );
};

export const PageHeader: React.FC<Props> & SubComponents = ({
  title,
  breadcrumbs,
  navigation,
  sideContent,
}) => {
  const [environment] = useEnvironment();
  const { isEntityViewPage, isFeaturesPage, isEventsPage, isEntitiesPage } =
    useCurrentPage();
  const showEnvPill =
    isEntityViewPage || isFeaturesPage || isEventsPage || isEntitiesPage;

  return (
    <div className="sticky top-0 z-10 flex h-13 shrink-0 items-center justify-between gap-x-2 border-b border-l border-gray-200 bg-white">
      {title && <h2 className="pl-4 font-inter-semibold-13px">{title}</h2>}
      {breadcrumbs && breadcrumbs.length > 0 && (
        <ul className="flex items-center gap-x-3 pl-4" data-loc="breadcrumbs">
          {breadcrumbs.map(({ key, breadcrumb }) => (
            <React.Fragment key={key}>{breadcrumb}</React.Fragment>
          ))}
        </ul>
      )}
      {navigation && <PageHeaderArrowNavigation {...navigation} />}
      {environment !== "live" && showEnvPill && (
        <Pill data-loc="environment-pill">
          <Pill.Text>{capitalize(environment)}</Pill.Text>
        </Pill>
      )}
      <div className="ml-auto flex h-full items-center pr-3">
        {sideContent ? sideContent : <ProfileSideContent />}
      </div>
    </div>
  );
};

const PageHeaderBreadcrumb: BreadcrumbType = ({
  label,
  href,
  dataLoc,
  hideIcon,
  active,
  children,
}) => (
  <li className="flex items-center gap-x-3">
    {!hideIcon && (
      <Icon color="text-gray-500" icon={faChevronRight} size="xs" />
    )}
    {href ? (
      <Link
        className={twMerge(
          "text-indigo-600 font-inter-semibold-13px",
          active && "text-gray-800",
        )}
        data-loc={dataLoc}
        to={href}
      >
        {label}
      </Link>
    ) : (
      label
    )}
    {children}
  </li>
);

const PageHeaderArrowNavigation: React.FC<ArrowNavigationProps> = ({
  previous,
  next,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Link
        className={twMerge(
          "rounded-md border border-gray-200",
          previous.disabled && "pointer-events-none opacity-50",
        )}
        to={previous.url}
      >
        <Icon color="text-gray-500" icon={faChevronDown} size="xs" />
      </Link>
      <Link
        className={twMerge(
          "rounded-md border border-gray-200",
          next.disabled && "pointer-events-none opacity-50",
        )}
        to={next.url}
      >
        <Icon color="text-gray-500" icon={faChevronUp} size="xs" />
      </Link>
    </div>
  );
};

PageHeader.Breadcrumb = PageHeaderBreadcrumb;
