import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useHover } from "usehooks-ts";

import { ProviderT } from "src/api/connectApi/types";
import { DatasetColumnGroups, DesiredType } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { CustomPopover } from "src/base-components/Popover";
import { ProviderIcon } from "src/connections/config/Icon";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { ColumnMenu } from "src/datasets/DatasetTable/ColumnMenu";
import {
  useIsColumnHovered,
  useIsColumnHoveredLike,
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { DatasetContext, JSONValue } from "src/datasets/DatasetTable/types";
import {
  AvailableOutcomeColumns,
  CellId,
  getNonProviderIntegrationNodeIcon,
  isNonProviderIntegrationNode,
  jsonToPon,
  parseCellId,
} from "src/datasets/DatasetTable/utils";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { Tooltip, TooltipProps } from "src/design-system/Tooltip";
import { copyTextToClipboard } from "src/utils/clipboard";
import { TYPE_ICONS } from "src/utils/constants";

export const getIcon = (type: DesiredType) => TYPE_ICONS[type];

export type MockColumn = {
  name: string;
  provider:
    | ProviderT
    | string
    | NODE_TYPE.FLOW_NODE
    | NODE_TYPE.LOOP_NODE
    | NODE_TYPE.AI_NODE_V2;
  mediaKey: string | null;
  subMocks: Pick<MockColumn, "name" | "provider" | "mediaKey">[] | null;
};

export const ReadonlyCell: React.FC<{
  children: string | undefined;
  withPopup: boolean;
}> = ({ children, withPopup }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const wrapWithPopupIfNeeded = (mainElement: JSX.Element) => {
    if (withPopup && children) {
      return (
        <CustomPopover
          button={mainElement}
          isOpen={tooltipOpen}
          placement="top"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <div className="decideScrollbar z-50 max-h-150 min-w-[220px] max-w-128 overflow-auto px-3 pb-2 pt-3">
            <ObjectDetailPane
              innerDimensionClass="max-h-64 max-w-164"
              jsonObject={JSON.parse(children)}
              speakPython
            />
          </div>
        </CustomPopover>
      );
    } else {
      return mainElement;
    }
  };

  return wrapWithPopupIfNeeded(
    <div className="max-w-40 truncate p-1.5 text-gray-800 font-inter-normal-12px">
      {children ? jsonToPon(children) : ""}
    </div>,
  );
};

type HeaderProps = {
  children: string;
  type?: DesiredType;
  cellId: CellId;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  onDelete: () => void;
  onRename: (newName: string) => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onAddSubMocks?: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onAddAdditionalColumn: () => void;
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  outcome: {
    outcomeFields: AvailableOutcomeColumns[];
    thereAreNoOutcomes: boolean;
  };
  readonly: boolean;
  warning?: Pick<TooltipProps, "title" | "body" | "headerAction">;
  disabled?: boolean;
  typeChange?: {
    selectedType: DesiredType;
    compatibleType?: DesiredType;
    onChangeType: (selectedType: DesiredType) => void;
  };
  fillMockColumn?: {
    integrationNode: DatasetIntegrationNode;
    onFill: (value: JSONValue) => void;
  };
  context: DatasetContext;
};

export const Header: React.FC<HeaderProps> = ({
  children,
  type,
  cellId,
  onDelete,
  onRename,
  readonly,
  onColumnAdd,
  onAddSubMocks,
  warning,
  typeChange,
  mock,
  fillMockColumn,
  input,
  output,
  outcome,
  disabled,
  onAddAdditionalColumn,
  context,
}) => {
  const editorRef = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = React.useState(children);
  const [_, columnId] = parseCellId(cellId);
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);
  const isMenuVisible = useIsColumnHovered(columnId) || selected || editing;

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (selected && !editing && cellRef.current) {
      cellRef.current.focus();
      cellRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [selected, editing]);

  useEffect(() => {
    setLocalValue(children);
  }, [children]);

  return (
    <HeaderCell
      ref={cellRef}
      className={twJoin(
        "group gap-x-1.5",
        disabled
          ? "bg-gray-50 text-gray-500"
          : warning
            ? "bg-orange-50"
            : "bg-white",
        selected && "outline outline-1 outline-indigo-500",
      )}
      dataLoc={`dataset-header-${localValue}`}
    >
      {fillMockColumn ? (
        isNonProviderIntegrationNode(fillMockColumn.integrationNode) ? (
          <Icon
            color="text-gray-400"
            icon={getNonProviderIntegrationNodeIcon(
              fillMockColumn.integrationNode.provider,
            )}
            padding={false}
            size="2xs"
          />
        ) : (
          <ProviderIcon
            mediaKey={fillMockColumn.integrationNode.mediaKey}
            provider={fillMockColumn.integrationNode.provider as ProviderT}
            size="sm"
          />
        )
      ) : (
        <Icon
          color="text-gray-400"
          icon={getIcon(type ?? "any")}
          padding={false}
          size="2xs"
        />
      )}

      <Tooltip
        headerAction={{
          icon: faCopy,
          onMouseDownCapture: () => {
            copyTextToClipboard(children);
          },
        }}
        placement="top"
        title={children}
        asChild
      >
        <input
          ref={editorRef}
          className={twJoin(
            "w-full bg-transparent outline-none [&:not(:focus)]:text-ellipsis",
            (readonly || disabled) && "cursor-default",
            disabled && "text-gray-500",
          )}
          data-loc="dataset-table-header-cell"
          readOnly={readonly || disabled}
          spellCheck={false}
          value={localValue}
          onBlur={() => {
            onRename(localValue);
          }}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </Tooltip>
      <div className="ml-auto flex items-center">
        {!readonly && (
          <ColumnMenu
            key={cellId}
            cellId={cellId}
            columnDisabled={disabled}
            context={context}
            fillMockColumn={fillMockColumn}
            input={input}
            mock={mock}
            outcome={outcome}
            output={output}
            typeChange={typeChange}
            visible={isMenuVisible}
            onAdd={onColumnAdd}
            onAddAdditionalColumn={onAddAdditionalColumn}
            onAddSubMocks={onAddSubMocks}
            onDelete={onDelete}
          />
        )}
        {warning && (
          <Tooltip placement="right" {...warning}>
            <Icon color="text-orange-500" icon={faWarning} size="2xs" />
          </Tooltip>
        )}
      </div>
    </HeaderCell>
  );
};

export const NoMenuHeader: React.FC<{
  children: string;
  type: DesiredType;
  icon?: ReactNode;
  warning?: Pick<TooltipProps, "title" | "body" | "headerAction">;
}> = ({ children, type, icon, warning }) => (
  <HeaderCell
    className={twJoin("group gap-x-1.5", warning ? "bg-orange-50" : "bg-white")}
    dataLoc={`dataset-header-${children}`}
  >
    {icon || (
      <Icon
        color="text-gray-400"
        icon={getIcon(type)}
        padding={false}
        size="2xs"
      />
    )}

    <Tooltip
      headerAction={{
        icon: faCopy,
        onMouseDownCapture: () => {
          copyTextToClipboard(children);
        },
      }}
      placement="top"
      title={children}
    >
      {children}
    </Tooltip>
    <div className="ml-auto flex items-center">
      {warning && (
        <Tooltip placement="right" {...warning}>
          <Icon color="text-orange-500" icon={faWarning} size="2xs" />
        </Tooltip>
      )}
    </div>
  </HeaderCell>
);

type SubGroupHeaderProps = {
  children: string;
  cellId: CellId;
  mock: {
    mockFields: MockColumn[];
    integrationNodesExist: boolean;
  };
  onDelete: () => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onDeleteSubMocks: () => void;
  onAddAdditionalColumn: () => void;
  onRename?: (newName: string) => void;
  output: { outputFields: string[]; schemaIsEmpty: boolean };
  input: { inputFields: string[]; schemaIsEmpty: boolean };
  outcome: {
    outcomeFields: AvailableOutcomeColumns[];
    thereAreNoOutcomes: boolean;
  };
  readonly: boolean;
  warning?: Pick<TooltipProps, "title" | "body" | "headerAction">;
  disabled?: boolean;
  integrationNode: DatasetIntegrationNode | null;
  desiredType?: DesiredType;
  customIcon?: ReactNode;
  typeChange?: {
    selectedType: DesiredType;
    compatibleType?: DesiredType;
    onChangeType: (selectedType: DesiredType) => void;
  };
};

export const SubGroupHeader: React.FC<SubGroupHeaderProps> = ({
  children,
  cellId,
  integrationNode,
  onDelete,
  onDeleteSubMocks,
  readonly,
  onColumnAdd,
  onRename,
  warning,
  mock,
  input,
  output,
  outcome,
  disabled,
  desiredType,
  onAddAdditionalColumn,
  customIcon,
  typeChange,
}) => {
  const [_, columnId] = parseCellId(cellId);
  const editorRef = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover(cellRef);
  const isColumnHovered = useIsColumnHoveredLike(columnId);
  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const isMenuVisible = Boolean(
    isHovered || isColumnHovered || selected || editing,
  );

  const [localValue, setLocalValue] = React.useState(children);

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (selected && !editing && cellRef.current) {
      cellRef.current.focus();
      cellRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest",
      });
    }
  }, [selected, editing]);

  useEffect(() => {
    setLocalValue(children);
  }, [children]);

  return (
    <HeaderCell
      ref={cellRef}
      className={twJoin(
        "group gap-x-1.5 border-b border-gray-100",
        disabled
          ? "bg-gray-50 text-gray-500"
          : warning
            ? "bg-orange-50"
            : "bg-white",
        selected && "outline outline-1 outline-indigo-500",
      )}
      dataLoc={`dataset-header-${localValue}`}
    >
      {desiredType || integrationNode ? (
        <Icon
          color="text-gray-400"
          icon={
            integrationNode
              ? getNonProviderIntegrationNodeIcon(integrationNode.provider)
              : getIcon(desiredType ?? "any")
          }
          padding={false}
          size="2xs"
        />
      ) : (
        customIcon
      )}
      <Tooltip
        headerAction={{
          icon: faCopy,
          onMouseDownCapture: () => {
            copyTextToClipboard(children);
          },
        }}
        placement="top"
        title={children}
        asChild
      >
        <input
          ref={editorRef}
          className={twJoin(
            "w-full bg-transparent outline-none [&:not(:focus)]:text-ellipsis",
            (readonly || disabled || !onRename) && "cursor-default",
            disabled && "text-gray-500",
          )}
          data-loc="dataset-table-header-cell"
          readOnly={readonly || disabled || !onRename}
          spellCheck={false}
          value={localValue}
          onBlur={() => {
            onRename?.(localValue);
          }}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </Tooltip>
      <div className="ml-auto flex items-center">
        {!readonly && (
          <ColumnMenu
            cellId={cellId}
            columnDisabled={disabled}
            context="authoring"
            fillMockColumn={integrationNode ? { integrationNode } : undefined}
            input={input}
            mock={mock}
            outcome={outcome}
            output={output}
            typeChange={typeChange}
            visible={isMenuVisible}
            onAdd={onColumnAdd}
            onAddAdditionalColumn={onAddAdditionalColumn}
            onDelete={onDelete}
            onDeleteSubMocks={onDeleteSubMocks}
          />
        )}
        {warning && (
          <Tooltip placement="right" {...warning}>
            <Icon color="text-orange-500" icon={faWarning} size="2xs" />
          </Tooltip>
        )}
      </div>
    </HeaderCell>
  );
};

type GroupHeaderProps = {
  children: ReactNode;
  variant: "gray" | "indigo" | "dark-gray" | "green";
};

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  children,
  variant,
}) => (
  <HeaderCell
    className={twJoin(
      variant === "gray" && "bg-gray-50",
      variant === "indigo" && "bg-indigo-50",
      variant === "dark-gray" && "bg-gray-200",
      variant === "green" && "bg-green-50",
    )}
  >
    {children}
  </HeaderCell>
);

const HeaderCell = React.forwardRef<
  HTMLDivElement,
  { children: ReactNode; className?: string; dataLoc?: string }
>(({ children, className, dataLoc }, ref) => (
  <div
    ref={ref}
    className={twJoin(
      "flex w-full items-center p-1.5 text-gray-800 font-inter-medium-12px",
      className,
    )}
    data-loc={dataLoc}
    tabIndex={-1}
  >
    {children}
  </div>
));
