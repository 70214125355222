import {
  formatDistanceToNow,
  isFuture,
  parseISO,
  subMilliseconds,
  format as formatDateFns,
  format,
  formatDistance as formatDistanceDateFns,
  formatISO,
} from "date-fns";

type DateType = Date | string | number;

export const parseDateIfNeeded = (date: DateType) =>
  typeof date === "string" ? parseISO(date) : date;

export const formatDate = (
  date: DateType,
  format: string = "h:mm aaa, d MMM yyyy",
): string => formatDateFns(parseDateIfNeeded(date), format);

// The default date-fns format is different from moment.js
// This is a custom locale for the `formatDistanceToNow`
// function to match the moment.js format
const FORMAT_DISTANCE_LOCALE = {
  lessThanXSeconds: "a few seconds",
  xSeconds: "{{count}} second{{s}}",
  halfAMinute: "30 seconds",
  lessThanXMinutes: "a few seconds",
  xMinutes: "{{count}} minute{{s}}",
  aboutXHours: "{{count}} hour{{s}}",
  xHours: "{{count}} hour{{s}}",
  xDays: "{{count}} day{{s}}",
  aboutXWeeks: "{{count}} week{{s}}",
  xWeeks: "{{count}} week{{s}}",
  aboutXMonths: "{{count}} month{{s}}",
  xMonths: "{{count}} month{{s}}",
  aboutXYears: "{{count}} year{{s}}",
  xYears: "{{count}} year{{s}}",
  overXYears: "{{count}} year{{s}}",
  almostXYears: "{{count}} year{{s}}",
} as const;

export const formatDistance = (
  date: DateType,
  baseDate: DateType,
  withoutSuffix?: boolean,
): string => {
  const parsedDate = parseDateIfNeeded(date);
  const displayDate = isFuture(parsedDate)
    ? subMilliseconds(new Date(), 1)
    : parsedDate;
  const parsedBaseDate = parseDateIfNeeded(baseDate);

  return formatDistanceDateFns(displayDate, parsedBaseDate, {
    addSuffix: !withoutSuffix,
    locale: {
      formatDistance: (
        token: keyof typeof FORMAT_DISTANCE_LOCALE,
        count: number,
        options,
      ) => {
        const article = token.includes("Hours") ? "an" : "a";

        const result = FORMAT_DISTANCE_LOCALE[token]
          .replace("{{count}}", count > 1 ? String(count) : article)
          .replace("{{s}}", count > 1 ? "s" : "");

        if (options.addSuffix) {
          return options.comparison > 0 ? `in ${result}` : `${result} ago`;
        }

        return result;
      },
    },
  });
};

export const dateFromNow = (
  date: DateType,
  withoutSuffix?: boolean,
): string => {
  return formatDistance(date, new Date(), withoutSuffix);
};

const RETURN_DATE_KEY = "return date";
// The default date-fns format is different from moment.js
// This is a custom locale for the `formatDistanceToNow`
// function to match the moment.js format
const FORMAT_SHORT_DISTANCE_LOCALE = {
  lessThanXSeconds: "now",
  xSeconds: "now",
  halfAMinute: "1m",
  lessThanXMinutes: "now",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: RETURN_DATE_KEY,
  xWeeks: RETURN_DATE_KEY,
  aboutXMonths: RETURN_DATE_KEY,
  xMonths: RETURN_DATE_KEY,
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
} as const;

export const shortDateFromNow = (date: DateType): string => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  const displayDate = isFuture(parsedDate)
    ? subMilliseconds(new Date(), 1)
    : parsedDate;

  return formatDistanceToNow(displayDate, {
    locale: {
      formatDistance: (
        token: keyof typeof FORMAT_SHORT_DISTANCE_LOCALE,
        count: number,
      ) => {
        if (FORMAT_SHORT_DISTANCE_LOCALE[token] === RETURN_DATE_KEY) {
          return format(displayDate, "d MMM");
        } else {
          return FORMAT_SHORT_DISTANCE_LOCALE[token].replace(
            "{{count}}",
            String(count),
          );
        }
      },
    },
  });
};

export const isISO8601 = (date: string): boolean => {
  const looksLikeISO8601 =
    /^\d{4}-(?:[01]\d(?:-[0-3]\d)?)?(?:[T ][0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:Z|[+-][0-2]\d:[0-5]\d)?)?$/.test(
      date,
    );

  return looksLikeISO8601 && Boolean(new Date(date).getTime());
};

export const getDateOnly = (date: string) => {
  return formatISO(parseISO(date), { representation: "date" });
};
