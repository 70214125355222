import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { get, capitalize } from "lodash";
import { useState } from "react";
import { Controller, useFormContext, FieldError } from "react-hook-form";

import { EnumField } from "./EnumField";
import { TagsInput } from "src/aiNode/editorComponents/TagsInput";
import { ManifestJSONSchema } from "src/api/connectApi/manifestTypes";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import { EditSecretButton } from "src/connections/config/manifest/common/EditSecretButton";
import { getValidationRules } from "src/connections/config/manifest/common/ValidationRules";
import {
  FormPathT,
  ManifestFormType,
} from "src/connections/config/manifest/types";
import {
  isFieldBoolean,
  isFieldTagsInput,
  isFieldTextarea,
} from "src/connections/config/manifest/utils";
import { TextWithMarkdownLinks } from "src/integrationNode/editorComponents/TextWithMarkdownLinks";

type ManifestFormT = {
  schema: ManifestJSONSchema;
  prefix: string;
  isConnectionUpdate?: boolean;
  formPath: FormPathT;
};
export const Form: React.FC<ManifestFormT> = ({
  schema,
  prefix,
  formPath,
  isConnectionUpdate,
}) => {
  const form = useFormContext<ManifestFormType>();
  const [editableFields, setEditableFields] = useState<{
    [key: string]: boolean;
  }>(() => {
    if (!schema?.secrets) return {};

    const currentValues = get(form.getValues(), formPath);

    const isEmptyEnvironment = () => {
      if (!currentValues) return true;
      return Object.values(currentValues).every((field) => !field);
    };

    return schema.secrets.reduce(
      (acc, secretKey) => {
        const value = get(currentValues, secretKey);
        acc[secretKey] = isEmptyEnvironment() || !value;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  });

  const toggleEditable = (key: string) => {
    setEditableFields((prev) => {
      const newState = { ...prev, [key]: !prev[key] };
      // We reset the value to an empty string when the secret
      // is toggled to editable.
      if (!prev[key]) {
        form.setValue(`${formPath}.${key}`, "");
      }
      return newState;
    });
  };

  if (!schema) return null;

  const secretFields = schema.secrets || [];
  return Object.entries(schema.properties || {}).map(([key, value]) => {
    if (typeof value === "boolean") {
      throw new Error("Boolean is not supported");
    }
    const schemaValue = value as ManifestJSONSchema;
    const isRequired = schema.required?.includes(key);
    const inputFieldPlaceholder =
      value.example !== undefined
        ? `e.g. ${value.example}`
        : `Enter ${(value.title || key).toLowerCase()}`;

    const isSecret = secretFields.includes(key);
    const fieldError = get(form.formState.errors, `${formPath}.${key}`) as
      | FieldError
      | undefined;
    const isFieldErrored = !!fieldError;

    const currentValue = get(form.getValues(), `${formPath}.${key}`);
    const isDisabledSecretInput =
      isConnectionUpdate && isSecret && !editableFields[key] && currentValue;

    const validationRules = getValidationRules(schemaValue, !!isRequired, key);

    const errorMessage = fieldError?.message || "Invalid value";

    return (
      <>
        {"enum" in value ? (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={
              <TextWithMarkdownLinks text={value.description || ""} />
            }
            gap={isFieldErrored ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <EnumField
              fieldKey={key}
              formPath={formPath}
              isRequired={!!isRequired}
              jsonSchemaDefinition={value}
              prefix={prefix}
            />
          </FormItem>
        ) : isFieldBoolean(value) ? (
          <FormItem
            key={key}
            className="flex items-center justify-between"
            dataLoc={`${prefix}-${key}`}
            description={
              <TextWithMarkdownLinks text={value.description || ""} />
            }
            gap={isFieldErrored ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <Controller
              control={form.control}
              name={`${formPath}.${key}`}
              render={({ field: { value, onChange } }) => (
                <div className="ml-2">
                  <Switch enabled={value as boolean} onChange={onChange} />
                </div>
              )}
              rules={validationRules}
            />
          </FormItem>
        ) : isFieldTextarea(value) ? (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={
              <TextWithMarkdownLinks text={value.description || ""} />
            }
            gap={isFieldErrored ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <Textarea
              errored={isFieldErrored}
              {...form.register(`${formPath}.${key}`, validationRules)}
              placeholder={inputFieldPlaceholder}
            />
          </FormItem>
        ) : isFieldTagsInput(value) ? (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={
              <TextWithMarkdownLinks text={value.description || ""} />
            }
            gap={isFieldErrored ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <Controller
              control={form.control}
              name={`${formPath}.${key}`}
              render={({ field: controlledField }) => (
                <TagsInput
                  value={controlledField.value ?? []}
                  onChange={controlledField.onChange}
                />
              )}
            />
          </FormItem>
        ) : (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={
              <TextWithMarkdownLinks text={value.description || ""} />
            }
            gap={isFieldErrored ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <div className="flex w-full">
              <div className="flex-1">
                <Input
                  data-loc={`${prefix}-${key}-input`}
                  disabled={isDisabledSecretInput}
                  errored={isFieldErrored}
                  placeholder={inputFieldPlaceholder}
                  suffixIcon={isFieldErrored ? { icon: faWarning } : undefined}
                  type={isSecret ? "password" : "text"}
                  fullWidth
                  {...form.register(`${formPath}.${key}`, validationRules)}
                />
              </div>
              {isDisabledSecretInput && (
                <EditSecretButton
                  secretKey={key}
                  secretName={value.title || ""}
                  toggleEditable={() => toggleEditable(key)}
                />
              )}
            </div>
          </FormItem>
        )}
        {isFieldErrored && <ErrorHint>{errorMessage}</ErrorHint>}
      </>
    );
  });
};
