import { useQuery } from "@tanstack/react-query";
import { Dictionary, fromPairs, reduce } from "lodash";
import { useCallback } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { queryClient } from "src/queryClient";
import { useAuthoringContext } from "src/router/routerContextHooks";

const transformHighlightedToGrayedOut = (
  highlighted: Record<string, boolean>,
) => {
  return reduce(
    highlighted,
    (acc, value, key) => {
      return {
        ...acc,
        [key]: !value,
      };
    },
    {} as Record<string, boolean>,
  );
};

export const queryKeys = {
  all: ["nodeHighlighting"] as const,
  flowVersion: (flowVersionId: string) => [...queryKeys.all, flowVersionId],
};

// TODO: Remove this function once GraphStore is completely deprecated, as this is being called outside component render cycle
// and we are trying to avoid that.
export const clearHighlightingGraphStore = (versionId: string) => {
  queryClient.setQueryData(queryKeys.flowVersion(versionId), {
    nodes: {},
    groups: {},
    highlightedParam: null,
  });
};

const buildData = (
  version: FlowVersionT,
  highlightedNodeIds: string[],
  highlightedParam: string | null,
) => {
  const nodeIds: [string, boolean][] =
    version.graph?.nodes.map((node) => [
      node.id,
      highlightedNodeIds.length > 0
        ? highlightedNodeIds.includes(node.id)
        : true,
    ]) ?? [];
  const groupIds: [string, boolean][] =
    version.graph?.node_groups.map((group) => [
      group.id,
      highlightedNodeIds.length > 0
        ? Boolean(
            group.node_ids?.some((childId) =>
              highlightedNodeIds.includes(childId),
            ),
          )
        : true,
    ]) ?? [];

  return {
    nodes: fromPairs(nodeIds),
    groups: fromPairs(groupIds),
    highlightedParam,
  };
};

type HighlightingData = {
  nodes: Dictionary<boolean>;
  groups: Dictionary<boolean>;
  highlightedParam: string | null;
};

const selectData = (data: HighlightingData) => ({
  nodesGrayedOut: transformHighlightedToGrayedOut(data.nodes),
  groupsGrayedOut: transformHighlightedToGrayedOut(data.groups),
  highlightedParam: data.highlightedParam,
});

export const useNodeHighlighting = () => {
  const { version } = useAuthoringContext();

  const { data } = useQuery({
    initialData: buildData(version, [], null),
    queryKey: queryKeys.flowVersion(version.id),
    queryFn: () => buildData(version, [], null),
    enabled: false,
    gcTime: 0,
    select: selectData,
  });

  const clearHighlighting = useCallback(() => {
    queryClient.setQueryData(
      queryKeys.flowVersion(version.id),
      buildData(version, [], null),
    );
  }, [version]);

  const highlightNodes = useCallback(
    (nodeIds: string[], param?: string | null) => {
      const updatedData = buildData(
        version,
        nodeIds,
        param ?? data.highlightedParam,
      );
      queryClient.setQueryData(queryKeys.flowVersion(version.id), updatedData);
      return updatedData;
    },
    [version, data.highlightedParam],
  );

  return {
    data,
    highlightNodes,
    clearHighlighting,
  };
};
