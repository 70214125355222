import {
  faEdit,
  faFlask,
  faRefresh,
  faWavePulse,
} from "@fortawesome/pro-regular-svg-icons";
import { noop } from "lodash";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";
import { useNavigate } from "react-router-dom";

import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { EventConfigEnriched } from "src/clients/features-control";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { getEventIcon } from "src/eventsCatalogue/utils";
import {
  SubHeader,
  SubHeaderButton,
  SubHeaderDatePicker,
  SubHeaderSearchBox,
  SubHeaderTab,
  SubHeaderTabs,
} from "src/layout/SubHeader";
import { EventsCatalogueParams, getUrlToEventsPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type EventsSubHeaderProps = {
  eventTypes: EventConfigEnriched[];
  dateRangePickerValue?: DateRange | undefined;
  onTimeWindowChange?: SelectRangeEventHandler;
  onRefresh: () => void;
  isRefreshing?: boolean;
  onSearchQueryChange: (query: string) => void;
  searchQuery?: string | null;
};

export const EventsSearchKeys = {
  From: "from",
  To: "to",
} as const;

export const EventsSubHeader: React.FC<EventsSubHeaderProps> = ({
  eventTypes,
  dateRangePickerValue,
  onTimeWindowChange,
  onRefresh,
  isRefreshing,
  searchQuery,
  onSearchQueryChange,
}) => {
  const [environment, setEnvironment] = useEnvironment();
  const {
    orgId,
    wsId,
    event_type: eventType,
  } = useParamsDecode<EventsCatalogueParams>();
  const navigate = useNavigate();

  const dropdownItems: OptionsDropdownElement[] = [
    {
      key: "Edit Schema",
      icon: faEdit,
      action: () =>
        navigate(
          getUrlToEventsPage({
            orgId,
            wsId,
            eventType: eventType + "/edit",
          }),
        ),
    },
    MENU_DIVIDER,
    {
      key: `Switch to ${environment === "sandbox" ? "Live" : "Sandbox"}`,
      icon: environment === "sandbox" ? faWavePulse : faFlask,
      action: () =>
        setEnvironment(environment === "sandbox" ? "live" : "sandbox"),
    },
  ] as const;
  const hasEventTypes = eventTypes.length > 0;

  return (
    <SubHeader
      leftSlot={
        <SubHeaderTabs dataLoc="events-subheader-tabs">
          {hasEventTypes &&
            eventTypes.filter(Boolean).map((eventType) => (
              <SubHeaderTab
                key={eventType.event_type}
                icon={getEventIcon(eventType)}
                to={getUrlToEventsPage({
                  orgId,
                  wsId,
                  eventType: eventType.event_type,
                })}
              >
                {eventType.display_name_plural}
              </SubHeaderTab>
            ))}
        </SubHeaderTabs>
      }
    >
      {hasEventTypes && (
        <>
          <SubHeaderSearchBox
            defaultValue={searchQuery ?? ""}
            placeholder="Search by event ID"
            onChange={onSearchQueryChange}
          />
          {onTimeWindowChange && (
            <SubHeaderDatePicker
              value={dateRangePickerValue}
              usePredefinedDates
              onChange={onTimeWindowChange}
            />
          )}
          <SubHeaderButton
            icon={faRefresh}
            spin={isRefreshing}
            tooltip="Refresh"
            onClick={isRefreshing ? noop : onRefresh}
          />
          <span className="border-l border-gray-200 p-3.5">
            <EllipsisOptionsDropdown
              elements={dropdownItems}
              iconSize="sm"
              placement="bottom-start"
            />
          </span>
        </>
      )}
    </SubHeader>
  );
};
