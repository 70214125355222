import { isEqual, map } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { SubFormBaseProps } from "src/integrationNode/IntegrationNodeEditor";
import { AddOptionalField } from "src/integrationNode/editorComponents/AddOptionalField";
import { InputMappingGroup } from "src/integrationNode/editorComponents/InputMappingGroup";
import { InputMappingList } from "src/integrationNode/editorComponents/InputMappingList";
import { InputMappingUngrouped } from "src/integrationNode/editorComponents/InputMappingUngrouped";
import { InputMultiSelector } from "src/integrationNode/editorComponents/InputMultiSelector";
import {
  InputMappingListT,
  InputMappingsT,
  IntegrationResourceT,
} from "src/integrationNode/types";
import { filterInputs } from "src/integrationNode/utils";
import { ManifestIntegrationResourceT } from "src/manifestConnectionNode/types";
import { useSubmitForm } from "src/utils/useSubmitForm";

export const groupClassName =
  "w-full rounded-lg bg-gray-50 px-4 pt-4 pb-2 mb-2";

type PropsT = {
  integrationResource: IntegrationResourceT | ManifestIntegrationResourceT;
  updateInputMapping: (input: InputMappingsT) => void;
  runFieldErrors?: FieldErrorsT;
} & SubFormBaseProps;

export const FIELD_ARRAY_KEY_NAME = "reactHookFormsKey" as const;

export const InputMappings: React.FC<PropsT> = ({
  integrationResource,
  updateInputMapping,
  runFieldErrors,
  immutable,
  isReactive,
}) => {
  const formProps = useForm({
    defaultValues: integrationResource.input,
    ...(isReactive && { values: integrationResource.input }),
  });

  useSubmitForm({
    onChange: (data: InputMappingsT) => {
      // React hook forms introduces a list key attribute to each element of a FieldArray. As our mappings rely on semantical
      // attributes we need to remove this list key attribute form the form data before saving it.
      const valuesWithoutHookFormIds = data;
      map(valuesWithoutHookFormIds.lists, (list) => {
        list.elements.forEach(
          (element) => delete element[FIELD_ARRAY_KEY_NAME],
        );
      });
      map(valuesWithoutHookFormIds.grouped.lists, (list: InputMappingListT) => {
        list.elements.forEach(
          (element) => delete element[FIELD_ARRAY_KEY_NAME],
        );
      });
      if (!isEqual(valuesWithoutHookFormIds, integrationResource.input)) {
        updateInputMapping(valuesWithoutHookFormIds);
      }
    },
    disabled: isReactive,
    previousValues: integrationResource.input,
    watch: formProps.watch,
  });

  const handleAddition = (type: "group" | "list", name: string) => {
    if (type === "group") {
      const groupElementsPath = `grouped.${name}.elements` as const;
      const group = integrationResource.input.grouped[name];
      formProps.setValue(groupElementsPath, group.getDefaultElements());
    } else if (type === "list") {
      const listElementsPath = `lists.${name}.elements` as const;
      const list = integrationResource.input.lists[name];
      const currentList = formProps.getValues(listElementsPath);
      formProps.setValue(listElementsPath, [
        ...currentList,
        list.getDefaultElement(),
      ]);
    }
  };

  const filteredInputs: InputMappingsT = filterInputs(
    integrationResource.input,
  );

  return (
    <>
      <InputMappingUngrouped
        formProps={formProps}
        groupClassName={groupClassName}
        immutable={immutable}
        inputMappings={filteredInputs.ungrouped}
        runFieldErrors={runFieldErrors}
      />
      {map(filteredInputs.grouped, (group, groupName) => {
        const groupElementsPath = `grouped.${groupName}.elements` as const;
        const onAdd = () => {
          formProps.setValue(groupElementsPath, group.getDefaultElements());
        };
        const onDelete = () => {
          formProps.setValue(groupElementsPath, undefined);
        };
        return (
          <InputMappingGroup
            key={groupName}
            displayGroupName={group.displayName}
            formProps={formProps}
            group={group}
            groupClassName={groupClassName}
            groupName={groupName}
            groupedGroupsAndLists={integrationResource.groupedGroupsAndLists}
            immutable={immutable}
            runFieldErrors={runFieldErrors}
            onAdd={onAdd}
            onDelete={onDelete}
          />
        );
      })}
      {Object.keys(filteredInputs.lists).length > 0 &&
        map(filteredInputs.lists, (list, listName) => (
          <InputMappingList
            key={listName}
            formProps={formProps}
            groupedGroupsAndLists={integrationResource.groupedGroupsAndLists}
            immutable={immutable}
            list={list}
            listName={listName}
            runFieldErrors={runFieldErrors}
          />
        ))}
      {integrationResource.groupedGroupsAndLists && (
        <div className="mb-4 flex flex-row">
          <AddOptionalField
            append={handleAddition}
            groupData={filteredInputs.grouped}
            immutable={immutable}
            label="Add optional field"
            listData={filteredInputs.lists}
            provider={integrationResource.providerResource.provider}
          />
        </div>
      )}
      {map(
        filteredInputs.multiselectors,
        (multiSelector, multiSelectorName) => (
          <InputMultiSelector
            key={multiSelectorName}
            formProps={formProps}
            immutable={immutable}
            multiSelector={multiSelector}
            multiSelectorName={multiSelectorName}
          />
        ),
      )}
    </>
  );
};
