import {
  faBracketsCurly,
  faBook,
  faDatabase,
  faWavePulse,
  faSliders,
  faBug,
  faChartSimple,
} from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { AnalyticsSidePane } from "src/analytics/AnalyticsSidePane";
import { Icon } from "src/base-components/Icon";
import { ResourceType } from "src/clients/flow-api";
import { SimpleRoleType } from "src/clients/taktile-api";
import { DatasetSidePane } from "src/datasets/DatasetSidePane";
import { Tooltip } from "src/design-system/Tooltip";
import { LockableNavIconContainer } from "src/flowContainer/FlowSideBar/LockableNavIconContainer";
import { NavIconContainer } from "src/flowContainer/FlowSideBar/NavIconContainer";
import { GlobalParametersSidePane } from "src/globalParameters/GlobalParametersSidePane";
import { useCapabilities } from "src/hooks/useCapabilities";
import { DebugSidePane } from "src/powertools/DebugPane";
import { usePowertools } from "src/powertools/hooks";
import {
  getCurrentLeftPane,
  LeftPaneOptions,
  useToggleLeftSidePaneOption,
} from "src/router/SearchParams";
import { AuthorPageParamsT, getApiDocsUrl } from "src/router/urls";
import { SchemaEditSidePane } from "src/schema/SchemaPane";
import {
  useInputSchemaError,
  useOutputSchemaError,
} from "src/store/runState/inputOutputErrors";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { DecisionHistorySidePane } from "src/versionDecisionHistory/SidePane";

const sideBarTooltipAlignementProps = {
  offset: 20,
  asChild: true,
  placement: "right-start",
} as const;

type SchemaIconProps = {
  schemaInvalid: boolean;
  toggleSchemaPane: () => void;
  currentOpenPane: LeftPaneOptions | null;
  flowVersionId: string;
};

/** Subscribes to specifc observers for displaying schema errors which can not really be generalised. */
const SchemaIcon: React.FC<SchemaIconProps> = observer(
  ({ schemaInvalid, toggleSchemaPane, currentOpenPane, flowVersionId }) => {
    const inputSchemaError = useInputSchemaError();
    const outputSchemaError = useOutputSchemaError();

    return (
      <LockableNavIconContainer
        currentOpenPane={currentOpenPane}
        errored={schemaInvalid || inputSchemaError || outputSchemaError}
        flowVersionId={flowVersionId}
        icon={
          <Tooltip
            body="Configure the input and output fields for this Decision Flow"
            title="Schemas"
            {...sideBarTooltipAlignementProps}
          >
            <Icon dataLoc="show-schemas-pane" icon={faBracketsCurly} />
          </Tooltip>
        }
        lockResourceType={ResourceType.SCHEMA}
        selected={currentOpenPane === LeftPaneOptions.Schema}
        onClick={toggleSchemaPane}
      />
    );
  },
);

export const FlowSideBar: React.FC = () => {
  const isPowertoolsEnabled = usePowertools();
  const { role, datasets, decisionHistory, flowCharts } = useCapabilities();
  const [searchParams] = useSearchParams();
  const [schemaInvalid, setSchemaInvalid] = useState(false);
  const { flow_id, version_id, orgId, wsId } =
    useParamsDecode<AuthorPageParamsT>();
  const currentOpenPane = getCurrentLeftPane(searchParams);
  const toggleSidePaneOptions = useToggleLeftSidePaneOption();
  const toggleDatasetsPane = () =>
    toggleSidePaneOptions(LeftPaneOptions.Datasets);
  const toggleSchemaPane = () => toggleSidePaneOptions(LeftPaneOptions.Schema);
  const toggleDecisionHistorySidePane = () =>
    toggleSidePaneOptions(LeftPaneOptions.DecisionHistory);
  const toggleParametersSidePane = () =>
    toggleSidePaneOptions(LeftPaneOptions.Parameters);
  const toggleDebugSidePane = () =>
    toggleSidePaneOptions(LeftPaneOptions.Debug);
  const toggleAnalyticsSidePane = () =>
    toggleSidePaneOptions(LeftPaneOptions.Analytics);

  const renderSideBarButtonsContainer = () => (
    <div className="z-20 flex w-13 flex-col items-center justify-between border-l border-r border-gray-200 bg-white py-4">
      <div className="flex flex-col items-center gap-y-2">
        {datasets.canAccess && (
          <NavIconContainer
            icon={
              <Tooltip
                body="Upload and manage test data to test this Decision Flow"
                title="Test data"
                {...sideBarTooltipAlignementProps}
              >
                <Icon
                  dataLoc="show-datasets-pane"
                  icon={faDatabase}
                  size="xs"
                />
              </Tooltip>
            }
            selected={currentOpenPane === LeftPaneOptions.Datasets}
            onClick={toggleDatasetsPane}
          />
        )}
        <SchemaIcon
          currentOpenPane={currentOpenPane}
          flowVersionId={version_id}
          schemaInvalid={schemaInvalid}
          toggleSchemaPane={toggleSchemaPane}
        />
        <LockableNavIconContainer
          currentOpenPane={currentOpenPane}
          flowVersionId={version_id}
          icon={
            <Tooltip
              body="Create and edit Parameters for this Decision Flow version."
              title="Parameters"
              {...sideBarTooltipAlignementProps}
            >
              <Icon
                dataLoc="show-global-parameters-pane"
                icon={faSliders}
                size="xs"
              />
            </Tooltip>
          }
          lockResourceType={ResourceType.PARAMETER}
          selected={currentOpenPane === LeftPaneOptions.Parameters}
          onClick={toggleParametersSidePane}
        />
        {decisionHistory.canAccess && (
          <NavIconContainer
            icon={
              <Tooltip
                body="See the decision history of the selected Flow version."
                title="Decision history"
                {...sideBarTooltipAlignementProps}
              >
                <Icon
                  dataLoc="show-decision-history-pane"
                  icon={faWavePulse}
                  size="xs"
                />
              </Tooltip>
            }
            selected={currentOpenPane === LeftPaneOptions.DecisionHistory}
            onClick={toggleDecisionHistorySidePane}
          />
        )}
        <NavIconContainer
          icon={
            <Tooltip
              body="View API documentation for this Decision Flow"
              title="API docs"
              {...sideBarTooltipAlignementProps}
            >
              <Icon dataLoc="show-api-docs" icon={faBook} size="xs" />
            </Tooltip>
          }
          linkTarget={getApiDocsUrl(orgId, wsId, flow_id, version_id)}
          selected={false}
          // AUTH-6206: We have to reload the page due to a bug in swagger-ui where the schemas would not update on prop changes.
          reloadDocument
        />
        {flowCharts.canAccess && (
          <NavIconContainer
            icon={
              <Tooltip
                body="Visualize the output from test runs to gain insights into data and decision logic"
                title="Analytics"
                {...sideBarTooltipAlignementProps}
              >
                <Icon dataLoc="show-analytics" icon={faChartSimple} size="xs" />
              </Tooltip>
            }
            selected={currentOpenPane === LeftPaneOptions.Analytics}
            onClick={toggleAnalyticsSidePane}
          />
        )}
        {role === SimpleRoleType.ADMIN && isPowertoolsEnabled && (
          <NavIconContainer
            icon={
              <Tooltip
                body="Debug features for @taktile employees only."
                title="@taktile debug features (INTERNAL)"
                {...sideBarTooltipAlignementProps}
              >
                <Icon
                  dataLoc="show-taktile-debug-pane"
                  icon={faBug}
                  size="xs"
                />
              </Tooltip>
            }
            selected={currentOpenPane === LeftPaneOptions.Debug}
            onClick={toggleDebugSidePane}
          />
        )}
      </div>
    </div>
  );

  const renderSidePane = (openPane: LeftPaneOptions) => {
    return (
      <div
        className="absolute left-[calc(3.25rem-1px)] z-10 h-full shadow-lg"
        data-loc={`side-pane-${openPane}`}
      >
        {
          {
            [LeftPaneOptions.Parameters]: (
              <GlobalParametersSidePane
                onRequestClose={toggleParametersSidePane}
              />
            ),
            [LeftPaneOptions.DecisionHistory]: (
              <DecisionHistorySidePane
                onRequestClose={toggleDecisionHistorySidePane}
              />
            ),
            [LeftPaneOptions.Datasets]: (
              <DatasetSidePane onRequestClose={toggleDatasetsPane} />
            ),
            [LeftPaneOptions.Schema]: (
              <SchemaEditSidePane
                setSchemaInvalid={setSchemaInvalid}
                onRequestClose={toggleSchemaPane}
              />
            ),
            [LeftPaneOptions.Debug]: (
              <DebugSidePane onRequestClose={toggleDebugSidePane} />
            ),
            [LeftPaneOptions.Analytics]: (
              <AnalyticsSidePane onRequestClose={toggleAnalyticsSidePane} />
            ),
          }[openPane]
        }
      </div>
    );
  };

  return (
    <div className="relative flex h-full flex-row">
      {renderSideBarButtonsContainer()}
      {currentOpenPane !== null && renderSidePane(currentOpenPane)}
    </div>
  );
};
