import React from "react";

import { ExternalLink } from "src/base-components/ExternalLink";

type LinkStyleType = "external" | "tooltip";

interface TextWithMarkdownLinksProps {
  text: string;
  type?: LinkStyleType;
}

export const TextWithMarkdownLinks: React.FC<TextWithMarkdownLinksProps> = ({
  text,
  type = "external",
}) => {
  const compileText = (text: string) =>
    text
      .split(/\n/)
      .flatMap((part: string, i: number, splitParts: string[]) =>
        splitParts.length - 1 !== i ? (
          [<span>{part}</span>, <br className="mb-2" />]
        ) : (
          <span>{part}</span>
        ),
      );

  const parseMarkdownToLink = (text: string, type: LinkStyleType) => {
    // Expected link format: "[link text](https://example.com)"
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push(...compileText(text.slice(lastIndex, match.index)));
      }

      if (type === "external") {
        parts.push(
          <ExternalLink key={match.index} className="break-all" href={match[2]}>
            {match[1]}
          </ExternalLink>,
        );
      } else if (type === "tooltip") {
        parts.push(
          <a
            key={match.index}
            className="w-fit break-all underline"
            data-loc={`${match.index ?? "tooltip"}-action`}
            href={match[2]}
          >
            {match[1]}
          </a>,
        );
      }
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) {
      parts.push(...compileText(text.slice(lastIndex)));
    }
    return parts;
  };

  return <>{parseMarkdownToLink(text, type)}</>;
};
