import { times } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { PropertyDefinitionOutput } from "src/clients/features-control/api";
import { EventSchemaForm } from "src/eventsCatalogue/EventSchemaEditSidebar";
import { EnumValuesEditor } from "src/eventsCatalogue/SchemaEditor/EnumValuesEditor";
import { PropertyTypeSelector } from "src/eventsCatalogue/SchemaEditor/PropertyTypeSelector";

type PropertyFieldsProps =
  | {
      property: string;
      config: PropertyDefinitionOutput;
      isLoading?: false;
    }
  | {
      isLoading: true;
    };

const PropertyFields: React.FC<PropertyFieldsProps> = (props) => {
  const { register, formState, getValues } = useFormContext<EventSchemaForm>();

  if (props.isLoading) {
    return (
      <div className="flex gap-x-2">
        {times(3, (i) => (
          <div key={i} className="w-1/3 flex-1 shrink-0">
            <SkeletonPlaceholder
              height="h-8"
              rounded="rounded-lg"
              width="w-full"
            />
          </div>
        ))}
      </div>
    );
  }

  const { property, config } = props;
  const isEnumOrTagsProperty =
    (config.type === "enum" || config.type === "tags") &&
    config.enum_schema?.values;

  return (
    <>
      <div className="flex gap-x-2">
        <div className="w-1/3 flex-1 shrink-0">
          <Input
            value={property + (config?.required ? " *" : "")}
            disabled
            fullWidth
            monospaced
          />
        </div>
        <div className="w-1/3 flex-1 shrink-0">
          <Input
            errored={!!formState.errors.properties?.[property]?.display_name}
            fullWidth
            {...register(`properties.${property}.display_name`, {
              required: "This field is required",
            })}
          />
          {formState.errors.properties?.[property]?.display_name && (
            <ErrorHint>
              {formState.errors.properties?.[property]?.display_name?.message}
            </ErrorHint>
          )}
        </div>
        <div className="w-1/3 flex-1 shrink-0">
          <Controller<EventSchemaForm, `properties.${string}.type`>
            name={`properties.${property}.type`}
            render={({ field }) => {
              const propertyValue = getValues(`properties.${property}`);
              const pluralDisplayName = getValues("display_name_plural");
              const singularDisplayName = getValues("display_name_singular");
              return (
                <PropertyTypeSelector
                  pluralDisplayName={pluralDisplayName}
                  property={propertyValue}
                  singularDisplayName={singularDisplayName}
                  value={field.value}
                />
              );
            }}
          />
        </div>
      </div>
      {isEnumOrTagsProperty && <EnumValuesEditor property={property} />}
    </>
  );
};

type PropertiesSchemaEditorProps = {
  isLoading?: boolean;
};

export const PropertiesSchemaEditor: React.FC<PropertiesSchemaEditorProps> = ({
  isLoading,
}) => {
  const form = useFormContext<EventSchemaForm>();

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex gap-x-2 text-gray-500 font-inter-normal-12px">
        <div className="w-1/3 flex-1">Name</div>
        <div className="w-1/3 flex-1">Display Name</div>
        <div className="w-1/3 flex-1">Type</div>
      </div>
      {isLoading
        ? times(7, (i) => <PropertyFields key={i} isLoading />)
        : Object.entries(form.getValues("properties") ?? {}).map(
            ([property, propertyConfig]) => (
              <PropertyFields
                key={property}
                config={propertyConfig}
                property={property}
              />
            ),
          )}
    </div>
  );
};
