import { v4 as uuidV4 } from "uuid";

import {
  JSONSchemaPropertyDefinition,
  ManifestInsightsConfig,
  ManifestIntegrationProvider,
  ManifestJSONSchema,
} from "src/api/connectApi/manifestTypes";
import { ManifestIntegrationProviderResourceT } from "src/api/connectApi/types";
import { getDefaultEnvironmentsConfig } from "src/baseConnectionNode/defaultValues";
import { isFieldOfNullableType } from "src/connections/config/manifest/utils";
import {
  defaultResourceConfig,
  getDefaultOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  DEFAULT_OUTPUT_BE_NAME,
  OutputMappingT,
  OutputMappingsT,
  InputMappingsT,
  InputMappingT,
  InputMappingGroupT,
  InputMappingListT,
  StringInputT,
  DropDownInputT,
} from "src/integrationNode/types";
import { ManifestIntegrationResourceT } from "src/manifestConnectionNode/types";

// These jsonschema types should be displayed as a text input in the node editor
const TEXT_INPUT_JSON_SCHEMA_TYPES = ["string", "number", "integer", "boolean"];

const isInputTextType = (
  jsonSchemaDefinition: JSONSchemaPropertyDefinition,
): boolean => {
  if (typeof jsonSchemaDefinition !== "object" || !jsonSchemaDefinition.type)
    return false;

  if (Array.isArray(jsonSchemaDefinition.type)) {
    return jsonSchemaDefinition.type.some((type) =>
      TEXT_INPUT_JSON_SCHEMA_TYPES.includes(type),
    );
  }

  return TEXT_INPUT_JSON_SCHEMA_TYPES.includes(jsonSchemaDefinition.type);
};

export const getDefaultManifestInputUngrouped = (
  manifestInputProperties: ManifestJSONSchema,
): { [key: string]: InputMappingT } => {
  /**
   * Given the node input properties defined in a given manifest, this function
   * constructs the default (unfilled) ungrouped input mappings.
   * */
  const { properties, required: requiredInputs } = manifestInputProperties;
  const ungrouped: { [key: string]: InputMappingT } = {};
  if (properties) {
    Object.entries(properties).forEach(([key, value]) => {
      let input = {
        id: uuidV4(),
        displayName: value.title || key,
        assignedTo: "",
      };
      if (
        typeof value === "object" &&
        isInputTextType(value) &&
        value.widget === "single_select"
      ) {
        input = {
          ...input,
          type: "dropDown",
          hint: value.description,
          rules: {
            required: requiredInputs?.includes(key) || false,
          },
          elements: value.enum!.map((element) => ({
            key: `"${element}"`,
            value: element,
          })),
        } as DropDownInputT;
        ungrouped[key] = input as InputMappingT;
      } else if (typeof value === "object" && isInputTextType(value)) {
        input = {
          ...input,
          type: "text",
          hint: value.description || undefined,
          rules: {
            required: requiredInputs?.includes(key) || false,
            maxLength: value.maxLength,
            minLength: value.minLength,
          },
        } as StringInputT;
        ungrouped[key] = input as InputMappingT;
      }
    });
  }
  return ungrouped;
};

export const getDefaultManifestInputGroups = (
  manifestNodeInputs: ManifestJSONSchema,
  existingGroups: { [key: string]: InputMappingGroupT } = {},
): { [key: string]: InputMappingGroupT } => {
  /**
   * Given the node input properties defined in a given manifest, this function
   * constructs the default (unfilled) grouped input mappings.
   * This function is also used when rendering inputs in the node editor. In this case, we
   * pass the existing group inputs to ensure they're not overridden by default values.
   * Additionally, we omit elements in the groups that are not required or don't have existing elements.
   * This allows the "add {group}" button to render.
   * */
  const { properties, required: requiredInputs } = manifestNodeInputs;
  const grouped: { [key: string]: InputMappingGroupT } = {};

  if (!properties) return {};

  const groupProperties = Object.entries(properties).filter(
    ([_, inputDefinition]) => {
      return (
        typeof inputDefinition === "object" &&
        isFieldOfNullableType(inputDefinition, "object")
      );
    },
  );
  groupProperties.sort((a, b) => {
    const firstIsRequired = requiredInputs?.includes(a[0]);
    const secondIsRequired = requiredInputs?.includes(b[0]);

    if (secondIsRequired && !firstIsRequired) return 1;
    if (firstIsRequired && !secondIsRequired) return -1;

    const firstIsPopulated =
      a[0] in existingGroups ? !!existingGroups[a[0]].elements : false;
    const secondIsPopulated =
      b[0] in existingGroups ? !!existingGroups[b[0]].elements : false;

    if (secondIsPopulated && !firstIsPopulated) return 1;
    if (firstIsPopulated && !secondIsPopulated) return -1;

    return 0;
  });

  groupProperties.forEach(([key, groupInputDefinition]) => {
    if (key in existingGroups) {
      grouped[key] = existingGroups[key];
      return;
    }

    const group: InputMappingGroupT = {
      displayName: groupInputDefinition.title || key,
      rules: { required: requiredInputs?.includes(key) },
      getDefaultElements: () =>
        getDefaultManifestInputUngrouped(
          groupInputDefinition as ManifestJSONSchema,
        ),
    };
    if (requiredInputs?.includes(key)) {
      group.elements = getDefaultManifestInputUngrouped(
        groupInputDefinition as ManifestJSONSchema,
      );
    }
    grouped[key] = group;
  });
  return grouped;
};

const getDefaultManifestInputLists = (
  manifestNodeInputs: ManifestJSONSchema,
): {
  [listName: string]: InputMappingListT;
} => {
  /**
   * Given the node input properties defined in a given manifest, this function
   * constructs the default (unfilled) list input mappings.
   * This function is also used when rendering inputs in the node editor. In this case, we
   * pass the existing list inputs to ensure they're not overridden by default values.
   * Additionally, we omit elements in the list that are not required or don't have existing elements.
   * This allows the "add {list}" button to render.
   * */
  const { properties, required: requiredInputs } = manifestNodeInputs;
  const lists: { [key: string]: InputMappingListT } = {};
  if (properties) {
    Object.entries(properties).forEach(([key, value]) => {
      if (typeof value === "object" && isFieldOfNullableType(value, "array")) {
        const list: InputMappingListT = {
          displayName: value.title || key,
          rules: { required: requiredInputs?.includes(key) },
          getDefaultElement: () => ({}),
          elements: [],
        };
        if (requiredInputs!.includes(key)) {
          list.elements = [
            getDefaultManifestInputUngrouped(
              value.items! as ManifestJSONSchema,
            ),
          ];
        }
        lists[key] = list;
      }
    });
  }
  return lists;
};

export const getDefaultManifestInputMappings = (
  manifestNodeInputs: ManifestJSONSchema,
): InputMappingsT => {
  /**
   * Given the node inputs defined in a manifest, return the default (unfilled) input mappings.
   * When converting inputs BE -> FE we don't have access to displayName, hint, and rules
   * for each input, so we use the default values from the manifest.
   * This is why these are the "unfilled" inputs.
   * */
  let ungrouped: { [key: string]: InputMappingT } = {};
  let grouped: { [key: string]: InputMappingGroupT } = {};
  let lists: { [key: string]: InputMappingListT } = {};

  if (manifestNodeInputs) {
    ungrouped = getDefaultManifestInputUngrouped(manifestNodeInputs);
    grouped = getDefaultManifestInputGroups(manifestNodeInputs);
    lists = getDefaultManifestInputLists(manifestNodeInputs);
  }

  return {
    ungrouped,
    grouped,
    lists,
  };
};

export const getDefaultManifestOutputMappings = (
  manifestInsights: ManifestInsightsConfig,
): OutputMappingsT => {
  const insights = Object.entries(manifestInsights).reduce(
    (acc, [key, value]) => {
      acc[key] = {
        id: uuidV4(),
        displayName: value.display_name,
        assignedTo: "",
        selected: false,
        hint: value.description,
      };
      return acc;
    },
    {} as { [key: string]: OutputMappingT },
  );
  return {
    [DEFAULT_OUTPUT_BE_NAME]: getDefaultOutputMapping(),
    insights,
  };
};

export const getDefaultManifestProviderResource = (
  providerResource: ManifestIntegrationProviderResourceT,
  connectionId: string,
  resourceConfigId: string,
  manifest: ManifestIntegrationProvider,
): ManifestIntegrationResourceT => {
  const output = getDefaultManifestOutputMappings(
    manifest.resources[providerResource.resource].insights,
  );
  const input = getDefaultManifestInputMappings(
    manifest.resources[providerResource.resource].node_inputs,
  );
  const manifestIntegrationResource: ManifestIntegrationResourceT = {
    connectionId: connectionId,
    resourceConfigId: resourceConfigId,
    providerResource: providerResource,
    input,
    output,
    config: {
      ...defaultResourceConfig,
      environments_config: getDefaultEnvironmentsConfig() as {
        [key: string]: string;
      },
    },
  };
  return manifestIntegrationResource;
};
