const checkKeyValue = (
  event: React.KeyboardEvent<HTMLInputElement>,
  allowDecimal: boolean = false,
) => {
  // Allow only numbers and special set of keys for the timeout input
  const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

  if (
    !new RegExp(`[0-9${allowDecimal ? "\\." : ""}]`).test(event.key) &&
    !validKeys.includes(event.key)
  ) {
    event.preventDefault();
  }
};

export const handleNumericInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  checkKeyValue(event, true);
};

export const handleIntegerInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  checkKeyValue(event);
};
