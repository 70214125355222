/* eslint-disable no-console */
// CS will get support from engineering here
// so if it fails console statements help
import { faCube, faTimer, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Dropzone from "react-dropzone";

import { entitiesApi, ExporterDatasetJobsEndpoint } from "src/api/endpoints";
import * as s3 from "src/api/s3";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Select } from "src/base-components/Select";
import { PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum } from "src/clients/entities";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { createToast } from "src/design-system/Toast/utils";
import { toastError } from "src/utils/toastError";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedWs: WorkspaceDataplane;
};

export const UploadFeatureStoreData: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedWs,
}) => {
  const [entityName, setEntityName] = useState("");
  const [environment, setEnvironment] = useState<"live" | "sandbox">("sandbox");
  const [eventType, setEventType] = useState("");
  return (
    <Modal open={isOpen} size="xl" onClose={onClose}>
      <Modal.Header>Upload feature store data</Modal.Header>
      <Modal.Content>
        <div className="flex flex-wrap gap-8">
          <div className="w-80">
            <Label>Target workspace</Label>
            <Select
              options={[{ key: selectedWs.id, value: selectedWs.name }]}
              value={selectedWs.id}
              disabled
            />
            <div className="mt-3 flex gap-4">
              <label className="flex items-center gap-1">
                <input
                  checked={environment === "sandbox"}
                  className="accent-primary-600"
                  name="environment"
                  type="radio"
                  value="sandbox"
                  onChange={() => setEnvironment("sandbox")}
                />
                <span className="text-gray-800 font-inter-medium-12px">
                  Sandbox
                </span>
              </label>
              <label className="flex items-center gap-1">
                <input
                  checked={environment === "live"}
                  className="accent-primary-600"
                  name="environment"
                  type="radio"
                  value="live"
                  onChange={() => setEnvironment("live")}
                />
                <span className="text-gray-800 font-inter-medium-12px">
                  Live
                </span>
              </label>
            </div>
          </div>
          <div className="space-y-3">
            <div className="mb-2 flex items-center gap-1">
              <Icon color="text-gray-600" icon={faCube} size="xs" />
              <Label mb="mb-0">Entities</Label>
            </div>
            <Input
              placeholder="Entity type"
              value={entityName}
              monospaced
              onChange={(e) => setEntityName(e.target.value)}
            />
            <Dropzone
              accept={{
                "application/json": [".json"],
                "application/x-ndjson": [".ndjson"],
                "application/gzip": [".gz"],
              }}
              maxSize={1024 * 1024 * 75}
              onDrop={async (acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file && selectedWs.base_url && entityName) {
                  const toastId = createToast("loading")({
                    title: "Uploading entities",
                    duration: Infinity,
                  });
                  try {
                    let job = (
                      await entitiesApi(
                        selectedWs.base_url,
                      ).putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(
                        {
                          entityId: entityName,
                          env:
                            environment === "live"
                              ? PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum.LIVE
                              : PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum.SANDBOX,
                          entityUploadDatasetJobRequestBody: {
                            file_name: file.name,
                          },
                        },
                      )
                    ).data;

                    await s3.uploadFile(
                      {
                        url: job.response!.s3_presigned_url,
                        fields: job.response!.s3_presigned_fields,
                      },
                      file,
                    );

                    while (job.status !== "FAILED" && job.progress !== 100) {
                      await new Promise((r) => setTimeout(r, 1000));
                      // @ts-ignore
                      job = await ExporterDatasetJobsEndpoint.getDatasetJob({
                        baseURL: selectedWs.base_url,
                        id: job.id,
                      });
                    }
                    if (job.error) {
                      createToast("error")({
                        id: toastId,
                        title: "Failed to upload entities",
                        description: job.error.message,
                        duration: Infinity,
                      });
                    } else {
                      createToast("success")({
                        id: toastId,
                        title: "Entities uploaded successfully",
                        duration: Infinity,
                      });
                    }
                  } catch (err) {
                    console.error(err);
                    toastError(err, { id: toastId });
                  }
                } else {
                  createToast("error")({
                    title: "Could not upload entities",
                    description:
                      "Make sure the workspace has a base_url, the file is under 75MB and the entity name is filled",
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className:
                      "bg-gray-100 w-42 h-42 flex  justify-center border border-dashed rounded-md px-2 py-0.5 cursor-pointer",
                  })}
                >
                  <div className="mt-5 max-w-[75%] text-center font-inter-medium-12px">
                    <Icon color="text-gray-400" icon={faUpload} size="xl" />
                    <input {...getInputProps()} />
                    <p className="text-gray-600 font-inter-medium-11px">
                      Supported formats: JSON and NDJSON (can be gzip
                      compressed)
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="space-y-3">
            <div className="mb-2 flex items-center gap-1">
              <Icon color="text-gray-600" icon={faTimer} size="xs" />
              <Label mb="mb-0">Events</Label>
            </div>
            <Input
              placeholder="Event type"
              value={eventType}
              monospaced
              onChange={(e) => setEventType(e.target.value)}
            />
            <Dropzone
              accept={{
                "application/json": [".json"],
                "application/x-ndjson": [".ndjson"],
                "application/gzip": [".gz"],
              }}
              maxSize={1024 * 1024 * 75}
              onDrop={async (acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file && selectedWs.base_url && eventType) {
                  const toastId = createToast("loading")({
                    title: "Uploading events",
                    duration: Infinity,
                  });
                  try {
                    let job =
                      await ExporterDatasetJobsEndpoint.createEventUploadDatasetJob(
                        selectedWs.base_url,
                        {
                          request: {
                            event_type: eventType,
                            environment: environment,
                            file_name: file.name,
                          },
                        },
                      );

                    await s3.uploadFile(
                      {
                        url: job.response!.s3_presigned_url,
                        fields: job.response!.s3_presigned_fields,
                      },
                      file,
                    );

                    while (job.status !== "FAILED" && job.progress !== 100) {
                      await new Promise((r) => setTimeout(r, 1000));
                      // @ts-ignore
                      job = await ExporterDatasetJobsEndpoint.getDatasetJob({
                        baseURL: selectedWs.base_url,
                        id: job.id,
                      });
                    }
                    if (job.error) {
                      createToast("error")({
                        id: toastId,
                        title: "Failed to upload events",
                        description: job.error.message,
                        duration: Infinity,
                      });
                    } else {
                      createToast("success")({
                        id: toastId,
                        title: "Events uploaded successfully",
                        duration: Infinity,
                      });
                    }
                  } catch (err) {
                    console.error(err);
                    toastError(err, { id: toastId });
                  }
                } else {
                  createToast("error")({
                    title: "Could not upload events",
                    description:
                      "Make sure the workspace has a base_url, the file is under 75MB and the event type is filled",
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className:
                      "bg-gray-100 w-42 h-42 flex  justify-center border border-dashed rounded-md px-2 py-0.5 cursor-pointer",
                  })}
                >
                  <div className="mt-5 max-w-[75%] text-center font-inter-medium-12px">
                    <Icon color="text-gray-400" icon={faUpload} size="xl" />
                    <input {...getInputProps()} />
                    <p className="text-gray-600 font-inter-medium-11px">
                      Supported formats: JSON and NDJSON (can be gzip
                      compressed)
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer />
    </Modal>
  );
};
