import { UseMutateFunction } from "@tanstack/react-query";
import { debounce, isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { FlowVersionT, SchemaT } from "src/api/flowTypes";
import { SchemaUpdateT } from "src/api/flowVersionQueries";
import { useLockableChildFocused } from "src/authoringMultiplayerLock/useLockableChildFocused";
import { useAcquireVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { ResourceType } from "src/clients/flow-api";
import { useEntitySchemasJsonSchema } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { CustomSchemaEditor } from "src/schema/schemaEditor/CustomSchemaEditor";
import { EntitySchemaEditor } from "src/schema/schemaEditor/EntitySchemaEditor";
import { EventSchemaEditor } from "src/schema/schemaEditor/EventSchemaEditor";
import {
  SchemaToggles,
  SchemaViewType,
} from "src/schema/schemaEditor/SchemaToggles";
import {
  PropertyUIT,
  SchemaConverter,
  SchemaUIT,
  buildSubSchemas,
} from "src/schema/schemaMappingUtils";
import { UPDATE_DEBOUNCE_WAIT_TIME } from "src/store/GraphStore";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  schema: SchemaT;
  setSchemaInvalid: (errored: boolean) => void;
  type: SchemaOptions;
  flowVersionId: string;
  immutable: boolean;
  mutateSchema: UseMutateFunction<
    FlowVersionT,
    unknown,
    SchemaUpdateT,
    unknown
  >;
};

export const SchemaEditor: React.FC<PropsT> = observer(
  ({
    schema,
    setSchemaInvalid,
    type,
    flowVersionId,
    immutable,
    mutateSchema,
  }) => {
    const { workspace } = useWorkspaceContext();
    const featureGates = useWorkspaceFeatureGates();
    const isShowingInputSchema = type === "input";
    const editorRef = useRef<HTMLDivElement>(null);
    const editorHasLockableFocus = useLockableChildFocused({
      refList: [editorRef],
    });
    const isEntitiesBaseEnabled = isFeatureFlagEnabled(
      FEATURE_FLAGS.entitiesBase,
    );

    const { data: entitySchemas } = useEntitySchemasJsonSchema({
      baseUrl: workspace.base_url!,
      options: {
        enabled: isEntitiesBaseEnabled && featureGates.entitiesEnabled,
      },
    });
    const { data: eventSchemas } = useEventSchemas({
      enabled: isEntitiesBaseEnabled && featureGates.featuresEventsEnabled,
    });

    const { lockedByOtherUser, lockAcquired } = useAcquireVersionResourceLock(
      ResourceType.SCHEMA,
      flowVersionId,
      !immutable && editorHasLockableFocus,
    );

    const { data: features } = useFeatures({
      options: {
        enabled: isEntitiesBaseEnabled && featureGates.featuresEventsEnabled,
      },
    });

    const isReadonly = immutable || lockedByOtherUser;

    const isReactive =
      (!editorHasLockableFocus && !lockAcquired) || lockedByOtherUser;

    const formMethods = useForm<SchemaUIT>({
      mode: "onChange",
      shouldFocusError: false,
      defaultValues: SchemaConverter.beToUI(schema, type),
      ...(isReactive && {
        values: SchemaConverter.beToUI(schema, type),
      }),
    });

    const {
      formState: { errors },
      watch,
      reset,
    } = formMethods;

    const mutateSchemaDebounce = useMemo(() => {
      const updateBackendSchema = async (
        updatedSchema: SchemaUIT,
        type: SchemaOptions,
      ) => {
        const subSchemas = buildSubSchemas(
          updatedSchema,
          eventSchemas ?? [],
          entitySchemas ?? {},
          features ?? [],
        );
        const backendSchema = SchemaConverter.uiToBE(
          {
            $schema: updatedSchema.$schema,
            type: updatedSchema.type,
            properties: updatedSchema.properties,
          },
          type,
        );

        mutateSchema({
          type,
          schema: {
            ...backendSchema,
            $defs: subSchemas,
          },
        });
      };
      return debounce(updateBackendSchema, UPDATE_DEBOUNCE_WAIT_TIME);
    }, [eventSchemas, entitySchemas, features, mutateSchema]);

    useSubmitForm({
      onChange: (newValues) => {
        // We want to filter out the fields with empty names here
        mutateSchemaDebounce(
          {
            ...newValues,
            properties: newValues.properties.filter(
              (p: PropertyUIT) => p?.fieldName !== "",
            ),
          },
          type,
        );
      },
      disabled: isReactive,
      watch: watch,
    });

    useEffect(() => {
      if (lockedByOtherUser) {
        // Cancel pending invocations of schema updates and reset the form
        // in case another user acquires a lock while editing the form.
        reset();
        mutateSchemaDebounce.cancel();
      } else {
        // Ensure debounced mutation is instantly performed when the schema editor is dismounted.
        return () => {
          mutateSchemaDebounce.flush();
        };
      }
    }, [lockedByOtherUser, mutateSchemaDebounce, reset]);

    useEffect(() => {
      setSchemaInvalid(!isEmpty(errors));
    }, [errors, errors.properties, setSchemaInvalid]);

    const [activeView, setActiveView] = useState<SchemaViewType>(
      SchemaViewType.CUSTOM,
    );

    const renderContent = () => {
      switch (activeView) {
        case SchemaViewType.EVENT:
          return <EventSchemaEditor formMethods={formMethods} />;
        case SchemaViewType.ENTITY:
          return <EntitySchemaEditor formMethods={formMethods} />;
        case SchemaViewType.CUSTOM:
          return (
            <CustomSchemaEditor
              formMethods={formMethods}
              isReadonly={isReadonly}
              isShowingInputSchema={isShowingInputSchema}
              type={type}
            />
          );
      }
    };

    return (
      <div className="decideScrollbar h-full overflow-y-auto px-4 pt-5">
        {isEntitiesBaseEnabled &&
          type === "input" &&
          (featureGates.entitiesEnabled ||
            featureGates.featuresEventsEnabled) && (
            <SchemaToggles
              activeView={activeView}
              formMethods={formMethods}
              setActiveView={setActiveView}
            />
          )}
        <div ref={editorRef}>{renderContent()}</div>
      </div>
    );
  },
);
