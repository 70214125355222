import {
  faCalculatorSimple,
  faCube,
  faDiagramNested,
  faGear,
  faSearch,
  faTimer,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect } from "react";
import { isMacOs } from "react-device-detect";
import { Link, useOutletContext } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { FlowT } from "src/api/flowTypes";
import { Divider } from "src/base-components/Divider";
import { FeatureGated } from "src/base-components/FeatureGated";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { AppHeaderLogo } from "src/layout/AppHeaderLogo";
import { ClickableSearchBox } from "src/layout/ClickableSearchBox";
import { NavItem, NavSidebar } from "src/layout/NavSideBar";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import {
  DashboardPageParamsT,
  getUrlToEventsPage,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
  WsDashboardPageCode,
} from "src/router/urls";
import { useCurrentPage } from "src/router/useCurrentPage";
import { canAccessSettingsPage } from "src/settings/utils";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const MainSideBar = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const [collapsed, setCollapsed] = useLocalStorage(
    "mainSideBarNavigationCollapsed",
    false,
  );
  const context = useOutletContext();
  const featureGates = useWorkspaceFeatureGates();
  const { data: eventTypes } = useEventSchemas({
    enabled: featureGates.featuresEventsEnabled,
  });
  const { flow } = context as { flow: FlowT | undefined };

  const { showOmnibox } = useOmniboxActions();
  const capabilities = useCapabilities();
  const { isEntityViewPage, isFlowPage, isAuthoringPage, isSettingsPage } =
    useCurrentPage();

  const shouldBeCollapsed =
    isEntityViewPage || isFlowPage || isAuthoringPage || isSettingsPage;

  useEffect(() => {
    if (shouldBeCollapsed) {
      setCollapsed(true);
    }
  }, [shouldBeCollapsed, setCollapsed]);

  return (
    <div>
      <div className="mb-5 px-3.5">
        <Link
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            folderId: flow?.flow_folder_id,
          })}
        >
          <div className="mb-7 mt-4 flex items-center gap-x-2">
            <AppHeaderLogo dataLoc="header-taktile-logo" />
            {!collapsed && (
              <p className="text-gray-800 font-inter-semibold-13px">Taktile</p>
            )}
          </div>
        </Link>
        {collapsed ? (
          <Tooltip
            placement="bottom-end"
            title={
              <>
                <span className="mr-2">Search</span>
                <Pill size="sm" variant="dark-gray">
                  <Pill.Text fontType="text">
                    {isMacOs ? "⌘" : "Ctrl"} K
                  </Pill.Text>
                </Pill>
              </>
            }
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faSearch}
              size="xs"
              onClick={showOmnibox}
            />
          </Tooltip>
        ) : (
          <ClickableSearchBox
            dataLoc="main-sidebar-search"
            text="Search"
            onClick={showOmnibox}
          />
        )}
      </div>
      <div className="px-2.5">
        <Divider />
      </div>
      <NavSidebar
        collapsed={collapsed}
        setCollapsed={shouldBeCollapsed ? null : setCollapsed}
      >
        <NavItem
          end={false}
          icon={faDiagramNested}
          isActiveOverride={isAuthoringPage}
          title="Decision Flows"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Flows,
          })}
        />

        <FeatureGated requiredGate="entitiesEnabled">
          <NavItem
            end={false}
            icon={faCube}
            title="Entities"
            to={getUrlToWsDashboard({
              orgId,
              wsId,
              page: WsDashboardPageCode.Entities,
            })}
          />
        </FeatureGated>

        <FeatureGated requiredGate="featuresEventsEnabled">
          <NavItem
            end={false}
            icon={faTimer}
            title="Events"
            to={getUrlToEventsPage({
              orgId,
              wsId,
              eventType: eventTypes?.at(0)?.event_type,
            })}
          />
          <NavItem
            end={false}
            icon={faCalculatorSimple}
            title="Features"
            to={getUrlToWsDashboard({
              orgId,
              wsId,
              page: WsDashboardPageCode.Features,
            })}
          />
        </FeatureGated>

        {canAccessSettingsPage(capabilities) && (
          <>
            <Divider spacing="my-2" />
            <NavItem
              end={false}
              icon={faGear}
              title="Settings"
              to={getUrlToSettingsPage(orgId, wsId)}
            />
          </>
        )}
      </NavSidebar>
    </div>
  );
};
