import { InfiniteData } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";

import { useAllOrganizationUsers } from "src/api/taktile/queries";
import { Card } from "src/base-components/Card";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { TableComp as Table } from "src/base-components/Table";
import { OrganizationUser, SimpleRoleType } from "src/clients/taktile-api";
import { HeaderCell } from "src/dataTable/HeaderCell";
import { User } from "src/flow/User";
import { useCurrentUserId } from "src/store/AuthStore";
import { CreateInviteModal } from "src/userManagement/CreateInviteModal/CreateInviteModal";
import { Status } from "src/userManagement/Status";
import { UserActions } from "src/userManagement/UserActions";
import { WorkspaceRolesRenderer } from "src/userManagement/WorkspaceRolesRenderer";
import { CellRendererPropsT, UserRow } from "src/userManagement/types";

const roleLabels: Record<SimpleRoleType, string> = {
  ADMIN: "Admin",
  MEMBER: "Team Member",
};

const cellSpanClassName =
  "font-inter-normal-12px block max-w-90 truncate py-2 text-left";

const cellRenderer = ({ getValue }: CellRendererPropsT, dataLoc?: string) => {
  return (
    <span className={cellSpanClassName} data-loc={dataLoc}>
      {getValue()}
    </span>
  );
};

const UserRenderer = (props: CellRendererPropsT) => {
  const userId = useCurrentUserId();
  const value = props.getValue() || props.row.original.username;
  const user = props.row.original;
  return (
    <span className={cellSpanClassName} data-loc="user-name">
      <User
        avatar={user.avatar_url}
        name={userId === props.row.original.id ? `${value} (You)` : value}
      />
    </span>
  );
};

const PermissionsRenderer = ({
  getValue,
  ...props
}: CellRendererPropsT<SimpleRoleType>) => {
  const user = props.row.original;

  if (user.status === "deactivated") {
    return <></>;
  }

  return (
    <div className="pl-2 text-left">
      <Pill dataLoc={`org-role-${user.username}`} variant="gray">
        <Pill.Text>{roleLabels[getValue()]}</Pill.Text>
      </Pill>
    </div>
  );
};

const Columns: ColumnDef<UserRow, string>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: () => <HeaderCell className="pl-1">ID</HeaderCell>,
    cell: ({ cell }) => (
      <div className="flex">
        <CopyTextIcon
          dataLoc="user-id"
          tooltip="Copy user ID"
          value={cell.row.original.id}
        />
      </div>
    ),
    size: 50,
  },
  {
    header: () => <HeaderCell>User</HeaderCell>,
    accessorKey: "full_name",
    cell: UserRenderer,
  },
  {
    header: () => <HeaderCell>Email</HeaderCell>,
    accessorKey: "email",
    cell: cellRenderer,
  },
  {
    header: () => <HeaderCell>Status</HeaderCell>,
    accessorKey: "status",
    cell: ({ row: { original } }) => (
      <Status
        dataLoc={`org-user-status-${original.username}`}
        status={original.status}
      />
    ),
  },
  {
    header: () => (
      <HeaderCell>
        <span className="pl-4">Organization role</span>
      </HeaderCell>
    ),
    accessorKey: "role",
    cell: (info) => <PermissionsRenderer {...(info as CellRendererPropsT)} />,
    size: 160,
  },
  {
    header: () => (
      <HeaderCell classNameOverrides="max-w-none">
        <span className="pl-4">Workspace permissions</span>
      </HeaderCell>
    ),
    accessorKey: "workspaces_roles",
    cell: (info) => (
      <WorkspaceRolesRenderer {...(info as CellRendererPropsT)} />
    ),
  },
  {
    header: () => <HeaderCell />,
    accessorKey: "actions",
    cell: (info) =>
      ["invited", "active", "deactivated"].includes(
        info.row.original.status,
      ) && <UserActions user={info.row.original} />,
    size: 200,
  },
];

const UsersTable: React.FC<{
  users: OrganizationUser[];
  orgId: string;
}> = ({ users, orgId }) => {
  const userId = useCurrentUserId();
  const [editUser, setEditUser] = useState<Nullable<OrganizationUser>>(null);

  const rows = useMemo(() => {
    const inviteRows = users
      .filter((u) => !u.authenticated)
      .map(
        (invite) =>
          ({
            ...invite,
            status: "invited",
            onEdit: () => setEditUser(invite),
          }) as UserRow,
      );

    const activeRows = users
      .filter((u) => u.is_active && u.authenticated)
      .map(
        (user) =>
          ({
            ...user,
            status: "active",
            onEdit: () => setEditUser(user),
          }) as UserRow,
      );

    const inactiveRows = users
      .filter((u) => !u.is_active)
      .map(
        (user) =>
          ({
            ...user,
            status: "deactivated",
          }) as UserRow,
      );

    return [...activeRows, ...inviteRows, ...inactiveRows];
  }, [users]);

  return (
    <>
      <Card
        className="w-100 mb-auto space-y-3 p-4.5 pt-0"
        variant="inverted"
        shadow
      >
        <Card.Content className="text-center">
          <Table
            columns={Columns}
            data={rows}
            headerPropsGetter={() => ({ classNameOverrides: "first:relative" })}
            noScroll
          />
        </Card.Content>
      </Card>
      <CreateInviteModal
        editableOrgRole={editUser?.id !== userId}
        open={Boolean(editUser)}
        organizationId={orgId}
        user={editUser}
        onClose={() => setEditUser(null)}
      />
    </>
  );
};

export const UserList: React.FC<{ orgId: string }> = ({ orgId }) => {
  const usersResult = useAllOrganizationUsers(orgId, true);

  const renderUpdated = (users: InfiniteData<OrganizationUser[]>) => (
    <UsersTable orgId={orgId} users={users.pages.flat()} />
  );

  return (
    <LoadingView queryResult={usersResult} renderUpdated={renderUpdated} />
  );
};
