import { PropertyUIT } from "src/schema/schemaMappingUtils";

export const schemaContainsFeatures = (properties: PropertyUIT[]) => {
  return properties.some(
    (property) =>
      property.fieldName === "features" && property.type[0] === "object",
  );
};

export const FEATURES_PROPERTY: PropertyUIT = {
  fieldName: "features",
  required: false,
  sensitive: false,
  type: ["object", "null"],
};
