import { ResourceConfigT } from "src/api/connectApi/types";
import { makeConnectionTemplate } from "src/connections/types";

const inquiryTypeOptions: { key: string; value: string }[] = [
  { key: "O", value: "Open account" },
  { key: "C", value: "Line of credit" },
  { key: "I", value: "Installment loan" },
  { key: "R", value: "Revolving" },
];

const isSectionHidden =
  (sectionFlagName: string) =>
  (resourceConfig: ResourceConfigT): boolean => {
    if (!(sectionFlagName in resourceConfig.configuration)) return true;
    const section_flag = resourceConfig.configuration[sectionFlagName];
    return !section_flag;
  };

export const DataXTemplate = makeConnectionTemplate({
  provider: "data_x",
  secrets: [
    {
      name: "License key",
      key: "license_key",
      required: true,
    },
    {
      name: "Password",
      key: "password",
      required: true,
    },
  ],
  resources: {
    data_x_credit_report: {
      name: "Credit Report",
      configKeys: [
        {
          key: "query_type",
          name: "Business rule",
          required: true,
          type: "text",
          hint: `Member-specific business rule supplied by DataX`,
        },
        {
          key: "inquiry_type",
          name: "Inquiry type",
          required: true,
          type: "simple_selection",
          elements: inquiryTypeOptions,
          hint: `Choose the inquiry type for your credit check. This value determines how the inquiry is recorded in the consumer's credit report history at the bureau. DataX default is "Open account".`,
        },
        {
          key: "all_transactions_summary",
          name: `Include "All Transactions Summary" section in the report`,
          required: true,
          type: "switch",
        },
        {
          key: "include_inquiries",
          name: `Include "Inquiries" segment in the report`,
          required: true,
          type: "switch",
        },
        {
          key: "max_inquiries_count",
          name: "Maximum number of inquiries",
          required: false,
          type: "number",
          hint: `The maximum number of inquiries to include in the report. DataX default is 25.`,
          isHidden: isSectionHidden("include_inquiries"),
        },
        {
          key: "max_inquiries_days",
          name: "Maximum number of inquiry history days",
          required: false,
          type: "number",
          hint: `The maximum number of days to look back for inquiries. DataX default is 2555 (7 years).`,
          isHidden: isSectionHidden("include_inquiries"),
        },
        {
          key: "include_tradelines",
          name: `Include "Tradeline Detail" segment in the report`,
          required: true,
          type: "switch",
        },
        {
          key: "max_tradelines_count",
          name: "Maximum number of tradelines",
          required: false,
          type: "number",
          hint: `The maximum number of tradelines to include in the report. DataX default is 25.`,
          isHidden: isSectionHidden("include_tradelines"),
        },
        {
          key: "max_tradelines_days",
          name: "Maximum number of tradeline history days",
          required: false,
          type: "number",
          hint: `The maximum number of days to look back for tradelines. DataX default is 2555 (7 years).`,
          isHidden: isSectionHidden("include_tradelines"),
        },
        {
          key: "include_payments",
          name: `Include "Payments" section for tradelines in the report`,
          required: true,
          type: "switch",
        },
        {
          key: "max_payments_count",
          name: "Maximum number of payments per tradeline",
          required: false,
          type: "number",
          hint: `The maximum number of payments per tradeline to include in the report. DataX default is 25.`,
          isHidden: isSectionHidden("include_payments"),
        },
        {
          key: "max_payments_days",
          name: "Maximum number of payment history days",
          required: false,
          type: "number",
          hint: `The maximum number of days to look back for payments. DataX default is 2555 (7 years).`,
          isHidden: isSectionHidden("include_payments"),
        },
      ],
    },
  },
});
