import {
  faEye,
  faMagnifyingGlass,
  faSpinner,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { CustomPopover } from "src/base-components/Popover";
import {
  Cell,
  ColumnVisibilityDropdown,
  TableComp,
} from "src/base-components/Table";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { EntityCaseSummaryStatusEnum } from "src/clients/entities/api";
import { TruncatedIdPill } from "src/decisionsOverview/TruncatedIdPill";
import { EmptyState } from "src/design-system/EmptyState";
import { Assignees } from "src/entities/Assignees";
import { EntityReviewStatus } from "src/entities/EntityReviewStatus";
import { EntitySchemaEditorSidebar } from "src/entities/EntitySchemaEditorSidebar";
import {
  renderValue,
  TypeIcon,
} from "src/entities/entityView/EntityDetailsSidePane";
import {
  convertFilters,
  getOrderedProperties,
} from "src/entities/entityView/utils";
import {
  Cardinality,
  ENTITIES_PAGE_SIZE,
  EntityManualReviewCase,
  EntityManualReviewCases,
  EntityResource,
  EntitySchemaProperty,
  EntitySchemaResource,
  useEntities,
} from "src/entities/queries";
import { RelationshipPopoverContent } from "src/eventsCatalogue/SchemaEditor/RelationshipPopoverContent";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { FiltersDropdown } from "src/manualReview/Table";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getUrlToEntityDetailPage } from "src/router/urls";
import { getUserName } from "src/utils/user";

type EntityColumnFilters = Record<string, string[]>;

type EntitiesTableProps = {
  baseUrl: string;
  entitySchema: EntitySchemaResource;
  isEditing: boolean;
  onSidebarClose: () => void;
  idFilter: EntityColumnFilters;
  schemasLoading: boolean;
};

type PropertyHeaderProps = {
  property: EntitySchemaProperty;
  filters: Record<string, string[]>;
  propertyKey: string;
  onSelect: (values: string[]) => void;
  onResetFilters: (key: string) => void;
};

type RelationshipHeaderProps = {
  schemaId: string;
  property: EntitySchemaProperty;
  pluralDisplayName: string;
  singularDisplayName: string;
  cardinality?: Cardinality;
};

type SetFiltersFn = React.Dispatch<React.SetStateAction<EntityColumnFilters>>;

const reviewStatusMapping = {
  [EntityCaseSummaryStatusEnum.REVIEW_REQUIRED]: "Review required",
  [EntityCaseSummaryStatusEnum.NO_PENDING_REVIEW]: "No pending review",
};

const emptyStateDescription =
  "Make sure your search starts with the exact property value or includes it in full, or try searching a different entity";

const onSelect = (
  key: string,
  newValue: string[],
  setFilters: SetFiltersFn,
) => {
  setFilters((prev) => {
    if (newValue.length === 0) {
      const { [key]: _, ...rest } = prev;
      return rest;
    }

    return {
      ...prev,
      [key]: newValue,
    };
  });
};

const RelationshipHeader: React.FC<RelationshipHeaderProps> = ({
  schemaId,
  property,
  pluralDisplayName,
  singularDisplayName,
  cardinality = Cardinality.ONE,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <CustomPopover
      button={
        <div className="flex h-8 items-center gap-0.5">
          <TypeIcon cardinality={property._cardinality} type={property._type} />
          <p className="whitespace-nowrap pr-2">{property._display_name}</p>
        </div>
      }
      isOpen={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <RelationshipPopoverContent
        cardinality={cardinality}
        pluralDisplayName={pluralDisplayName}
        propertyDisplayName={property._display_name}
        relatedSchemaId={property._rel_schema as string}
        schemaId={schemaId}
        singularDisplayName={singularDisplayName}
      />
    </CustomPopover>
  );
};

const AssigneeFilter = ({
  filters,
  onResetFilters,
  setFilters,
}: {
  filters: EntityColumnFilters;
  onResetFilters: (key: string) => void;
  setFilters: SetFiltersFn;
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const { data: users } = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });

  const noAssignee = {
    key: "None",
    value: "No assignee",
  };
  const dropdownElements =
    users?.map((user) => ({
      key: user.id,
      value: getUserName(user) as string,
    })) ?? [];

  return (
    <FiltersDropdown
      description="Filter by Assignee"
      elements={[...dropdownElements, noAssignee]}
      isFiltering={filters["case.assignees"] !== undefined}
      placement="bottomLeft"
      selected={filters["case.assignees"]}
      itemsPortal
      multiple
      onResetRequest={() => onResetFilters("case.assignees")}
      onSelect={(values: string[]) =>
        onSelect("case.assignees", values, setFilters)
      }
    />
  );
};

const PropertyHeader: React.FC<PropertyHeaderProps> = ({
  property,
  filters,
  propertyKey,
  onSelect,
  onResetFilters,
}) => {
  const displayFilters = ["enum", "tags"].includes(property._type);
  return (
    <div className="flex h-8 items-center gap-0.5">
      <TypeIcon cardinality={property._cardinality} type={property._type} />
      <p className={twJoin("whitespace-nowrap", !displayFilters && "pr-2")}>
        {property._display_name}
      </p>
      {displayFilters && property._values && property._values.length > 0 && (
        <span className="pr-2" data-loc={`${propertyKey}-filter`}>
          <FiltersDropdown
            description={`Filter by ${property._display_name}`}
            elements={property._values.map((v) => ({
              key: v._internal_name,
              value: v._internal_name,
            }))}
            isFiltering={filters[propertyKey] !== undefined}
            placement="bottomLeft"
            selected={filters[propertyKey]}
            itemsPortal
            multiple
            onResetRequest={() => onResetFilters(propertyKey)}
            onSelect={onSelect}
          />
        </span>
      )}
    </div>
  );
};

const buildColumns = (entitySchema: EntitySchemaResource) => {
  const columnHelper = createColumnHelper<EntityResource>();
  const orderedProperties = getOrderedProperties(entitySchema).filter(
    (propertyKey) => propertyKey !== "id",
  );

  const idColumn = (() => {
    if (!("id" in entitySchema.properties)) {
      return false;
    }

    const idProperty = entitySchema.properties["id"];

    return columnHelper.accessor("properties.id", {
      meta: {
        displayName: idProperty._display_name,
      },
      header: () => (
        <div className="flex h-8 items-center gap-x-0.5 pl-1 pr-2">
          <Icon
            color="text-gray-500"
            icon={SCHEMA_TYPE_ICONS["string"]}
            size="2xs"
          />
          {idProperty._display_name}
        </div>
      ),
      size: 100,
      cell: (info) => (
        <Cell className="w-24" info={info}>
          <TruncatedIdPill id={info.row.original._id} />
        </Cell>
      ),
    });
  })();

  const reviewStatus = columnHelper.display({
    id: "review_status",
    header: (info) => {
      const actions = info.table.options.meta?.actions as EntityTableActions;
      const filters = actions.getFilters();
      return (
        <div className="flex items-center gap-1">
          <Icon icon={faSpinner} size="2xs" />
          <span className="whitespace-nowrap">Review Status</span>
          <span className="pr-2">
            <FiltersDropdown
              description="Filter by Review Status"
              elements={Object.values(EntityCaseSummaryStatusEnum).map(
                (status) => ({
                  key: status,
                  value: reviewStatusMapping[status],
                }),
              )}
              isFiltering={filters["case.status"] !== undefined}
              placement="bottomLeft"
              selected={filters["case.status"]}
              itemsPortal
              multiple
              onResetRequest={() => actions.resetFilters("case.status")}
              onSelect={(values: string[]) =>
                onSelect("case.status", values, actions.setFilters)
              }
            />
          </span>
        </div>
      );
    },
    cell: (info) => {
      const actions = info.table.options.meta?.actions as EntityTableActions;
      const id = info.row.original._id;
      const entityCases = actions.getCase(id);
      return <EntityReviewStatus status={entityCases.status} />;
    },
  });

  const assignedTo = columnHelper.display({
    id: "assigned_to",
    header: (info) => {
      const actions = info.table.options.meta?.actions as EntityTableActions;
      return (
        <div className="flex items-center gap-1">
          <Icon icon={faUser} size="2xs" />
          <span className="whitespace-nowrap">Assigned to</span>
          <span className="pr-2">
            <AssigneeFilter
              filters={actions.getFilters()}
              setFilters={actions.setFilters}
              onResetFilters={actions.resetFilters}
            />
          </span>
        </div>
      );
    },
    cell: (info) => {
      const actions = info.table.options.meta?.actions as EntityTableActions;
      const id = info.row.original._id;
      const entityCases = actions.getCase(id);
      return <Assignees assignees={entityCases.assignees} />;
    },
  });

  const entityColumns = orderedProperties.map((key) => {
    const value = entitySchema.properties[key];

    return columnHelper.accessor(`properties.${key}`, {
      meta: {
        displayName: value._display_name,
      },
      header: (info) => {
        const actions = info.table.options.meta?.actions as EntityTableActions;
        const isRelationship = value._type === "relation";
        return isRelationship ? (
          <RelationshipHeader
            cardinality={value._cardinality}
            pluralDisplayName={entitySchema._display_name_plural}
            property={value}
            schemaId={entitySchema._id}
            singularDisplayName={entitySchema._display_name_singular}
          />
        ) : (
          <PropertyHeader
            filters={actions.getFilters()}
            property={value}
            propertyKey={key}
            onResetFilters={actions.resetFilters}
            onSelect={(values: string[]) =>
              onSelect(key, values, actions.setFilters)
            }
          />
        );
      },

      cell: (info) => (
        <Cell info={info}>
          {renderValue({
            property: value,
            propertyValue: info.row.original.properties[key],
            isWindowPill: true,
            foreginKeyToPrimaryProperty: info.row.original._fk_pp,
          })}
        </Cell>
      ),
    });
  });

  const detailsColumn = columnHelper.display({
    id: "fields.options",
    meta: {
      sticky: "right",
    },
    header: (info) => (
      <div className="flex h-8 justify-end bg-white pr-1.5">
        <ColumnVisibilityDropdown min={3} table={info.table} />
      </div>
    ),
    size: 54,
    cell: (info) => (
      <Cell
        className="items-center justify-end group-hover:bg-gray-50"
        info={info}
      >
        <Icon
          color="text-gray-500 opacity-0 group-hover:opacity-100"
          icon={faEye}
          size="xs"
        />
      </Cell>
    ),
  });

  return [
    idColumn,
    ...entityColumns,
    reviewStatus,
    assignedTo,
    detailsColumn,
  ].filter(Boolean) as DisplayColumnDef<EntityResource, unknown>[];
};

type EntityTableActions = {
  setFilters: SetFiltersFn;
  getFilters: () => EntityColumnFilters;
  resetFilters: (key: string) => void;
  getCase: (id: string) => EntityManualReviewCase;
};

export const EntitiesTable: React.FC<EntitiesTableProps> = ({
  baseUrl,
  entitySchema,
  isEditing,
  onSidebarClose,
  idFilter,
  schemasLoading,
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const navigate = useNavigate();
  const [env] = useEnvironment();

  const [filters, setFilters] = useState<EntityColumnFilters>({});

  const { data, isLoading, fetchNextPage } = useEntities({
    schema: entitySchema._id,
    env,
    baseUrl,
    filters: convertFilters(filters),
    limit: ENTITIES_PAGE_SIZE,
  });

  const { entities, cases } = useMemo(
    () => ({
      entities:
        data?.pages.flatMap((page) => page.entities as EntityResource[]) ?? [],
      cases: (data?.pages.reduce(
        (acc, page) => ({
          ...acc,
          ...page.cases,
        }),
        {},
      ) ?? {}) as EntityManualReviewCases,
    }),
    [data?.pages],
  );

  const actions: EntityTableActions = useMemo(
    () => ({
      setFilters,
      getFilters: () => filters,
      resetFilters: (key: string) => {
        setFilters((prev) => {
          const { [key]: _, ...rest } = prev;
          return rest;
        });
      },
      getCase: (id: string) => cases[id],
    }),
    [setFilters, filters, cases],
  );

  const showEmptyState = !isLoading && (!data || entities.length === 0);
  const emptyStateHeadline = `No ${entitySchema._display_name_plural.toLowerCase()} found`;

  const columns = useMemo(() => buildColumns(entitySchema), [entitySchema]);

  useEffect(() => {
    onSelect("_id", idFilter._id ?? [], setFilters);
  }, [idFilter]);

  useEffect(() => {
    setFilters({});
  }, [entitySchema._id]);

  return (
    <FloatingWindowsProvider>
      <div
        className={twJoin(
          "m-5 min-w-[300px] overflow-hidden rounded-lg border border-gray-200 bg-white p-4",
          isEditing && "w-[calc(100%-640px)]",
        )}
      >
        <TableComp
          actions={actions}
          columns={columns}
          data={entities ?? []}
          frameClassName={twJoin(
            "min-h-0 w-full flex-1 pt-0",
            showEmptyState && "h-auto",
          )}
          headerPropsGetter={() => ({
            classNameOverrides: "z-40",
          })}
          isLoading={isLoading || schemasLoading}
          rowClassName="hover:bg-gray-50 cursor-pointer"
          rowPropsGetter={(row) => ({
            onClick: () =>
              navigate(
                getUrlToEntityDetailPage(
                  orgId,
                  workspace.id,
                  row.original._schema,
                  row.original._id,
                ),
              ),
          })}
          variant="compact"
          onScrolledToTheBottom={fetchNextPage}
        />
        {showEmptyState && (
          <div className="h-96">
            <EmptyState
              description={emptyStateDescription}
              headline={emptyStateHeadline}
              icon={faMagnifyingGlass}
            />
          </div>
        )}
      </div>
      {isEditing && (
        <div className="fixed right-0 top-[100px] z-10 h-[calc(100vh-100px)]">
          <EntitySchemaEditorSidebar
            schema={entitySchema}
            onClose={onSidebarClose}
          />
        </div>
      )}
    </FloatingWindowsProvider>
  );
};
