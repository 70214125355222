import { JSONSchema7TypeName } from "json-schema";
import { ReactNode } from "react";

import { IntegrationProviderResourceT } from "src/api/connectApi/types";
import { DropDownElementT } from "src/base-components/DropDown";
import { DataRetentionUnit } from "src/connections/types";
import type { ConnectNodeBaseT } from "src/constants/NodeDataTypes";

export const DEFAULT_OUTPUT_BE_NAME = "default" as const;

//BE types
export type OutputMappingBET = {
  id: string;
  active: boolean;
  mapped_to: string;
};

export type ConfigT = {
  caching: {
    active: boolean;
    max_age_seconds: string;
    // Used only for displaying in the UI
    // to properly convert seconds to the correct unit
    unit?: DataRetentionUnit;
  };
  timeout?: {
    active: boolean;
    timeout_seconds: string;
  };
  raw_requests: {
    has_raw_requests_enabled_in_node: boolean;
  };
  error: {
    allow_4xx: boolean;
    allow_5xx: boolean;
  };
  tenant_id?: SingleMappingBET;
};

export type SingleMappingBET = {
  id: string;
  expression: string;
};

export type InputMappingBET = {
  singles: { [key: string]: SingleMappingBET };
  groups: {
    [groupName: string]: InputMappingBET;
  };
  lists: {
    [listName: string]: InputMappingBET[];
  };
};

export type IntegrationResourceBET = {
  provider_resource: IntegrationProviderResourceT;
  connection_id: string;
  resource_config_id: string;
  input: InputMappingBET;
  output: {
    [DEFAULT_OUTPUT_BE_NAME]: OutputMappingBET;
    [key: string]: OutputMappingBET;
  };
  config: ConfigT;
};

export type renderConditionT = {
  key: string;
  value: string[];
};

type BaseInputT = {
  id: string;
  type: "text" | "dropDown";
  displayName: string;
  assignedTo: string;
  rules?: InputMappingRules;
  hint?: string;
  beType?: JSONSchema7TypeName | JSONSchema7TypeName[] | undefined;
  example?: string;
  renderConditions?: renderConditionT[];
};

export type StringInputT = BaseInputT & {
  type: "text";
};

export type DropDownInputT = BaseInputT & {
  type: "dropDown";
  elements: DropDownElementT<string>[];
};

export type InputMappingT = StringInputT | DropDownInputT;

/**
 * We imitate the react-hook-form rules here for future compatibility. However, we don't use the validation engine.
 * Instead, we use them to restrict the user input in the UI. E.g. disabling the "Delete" button if the field is required.
 */
export type InputMappingRules = {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
};

export type InputMappingListT = {
  displayName: string;
  rules?: InputMappingRules;
  getDefaultElement: () => { [key: string]: InputMappingT };
  elements: { [key: string]: InputMappingT }[];
  renderConditions?: renderConditionT[];
};

export type InputMappingGroupT = {
  getDefaultElements: () => { [key: string]: InputMappingT };
  rules?: InputMappingRules;
  displayName?: string;
  elements?: { [key: string]: InputMappingT };
  lists?: { [listName: string]: InputMappingListT };
  renderConditions?: renderConditionT[];
};

export type MultiSelectorT = {
  id: string;
  displayName: string;
  options: { key: string; value: string }[];
  selected: string[];
  hint?: string;
  renderConditions?: renderConditionT[];
};

export type InputMappingsT = {
  ungrouped: { [key: string]: InputMappingT };
  grouped: { [key: string]: InputMappingGroupT };
  lists: {
    [listName: string]: InputMappingListT;
  };
  multiselectors?: { [key: string]: MultiSelectorT };
};

export type OutputMappingT = {
  id: string;
  displayName: string;
  assignedTo: string;
  selected: boolean;
  hint?: string | ReactNode;
};

export type OutputMappingsT = {
  [DEFAULT_OUTPUT_BE_NAME]: OutputMappingT;
  insights: { [key: string]: OutputMappingT };
};

export type IntegrationResourceT = ConnectNodeBaseT & {
  providerResource: IntegrationProviderResourceT;
  input: InputMappingsT;
  output: OutputMappingsT;
  config: ConfigT;
  groupedGroupsAndLists?: boolean;
};
