/* eslint-disable no-console */
// CS will get support from engineering here
// so if it fails console statements help
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCalculatorSimple,
  faCode,
  faCube,
  faPencil,
  faPlus,
  faTimer,
} from "@fortawesome/pro-regular-svg-icons";
import { cloneDeep } from "lodash";
import { ReactNode, useEffect, useState } from "react";

import {
  useWorkspaceEntitySchemas,
  useWorkspaceEventTypes,
  useWorkspaceFeatureQueries,
  useWorkspaceFeatures,
} from "src/adminPanel/queries";
import {
  entitiesApi as entitiesApiBase,
  eventsApi as eventsApiBase,
  featureQueriesApi as featureQueriesApiBase,
  featuresApi as featuresApiBase,
} from "src/api/endpoints";
import { useUserOrganizations, useWorkspaces } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { CustomPopover } from "src/base-components/Popover";
import { Select } from "src/base-components/Select";
import { Spinner } from "src/base-components/Spinner";
import { PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum } from "src/clients/entities/api";
import {
  EventConfigEnriched,
  FeatureQuery,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";
import { createToast } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { Feature, formatAthenaSqlQuery } from "src/featureCatalogue/utils";
import { HighlightedSql } from "src/utils/highlighter";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedWs: WorkspaceDataplane;
};

export const BootstrapFeatureStoreConfig: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedWs,
}) => {
  const workspaceData = useWorkspaces();
  const organizationsData = useUserOrganizations();
  const [sourceWsId, setSourceWsId] = useState<string | null>(null);
  const [addEntityTypeModalOpen, setAddEntityTypeModalOpen] = useState(false);
  const [addEventTypeModalOpen, setAddEventTypeModalOpen] = useState(false);

  // Entity schemas
  const { data: existingEntitySchemas } = useWorkspaceEntitySchemas(
    sourceWsId && workspaceData.data
      ? workspaceData.data.find((ws) => ws.id === sourceWsId)!
      : null,
  );
  const [customEntitySchemas, setCustomEntitySchemas] = useState<
    Record<string, any>[]
  >([]);
  const [selectedEntitySchemas, setSelectedEntitySchemas] = useState<
    Record<string, any>[]
  >([]);
  const entitySchemas = [
    ...(existingEntitySchemas ?? []),
    ...customEntitySchemas,
  ];

  // Event types
  const { data: existingEventTypes } = useWorkspaceEventTypes(
    sourceWsId && workspaceData.data
      ? workspaceData.data.find((ws) => ws.id === sourceWsId)!
      : null,
  );
  const [customEventTypes, setCustomEventTypes] = useState<
    EventConfigEnriched[]
  >([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<
    EventConfigEnriched[]
  >([]);
  const eventTypes = [...(existingEventTypes ?? []), ...customEventTypes];

  // Features
  const { data: features } = useWorkspaceFeatures(
    sourceWsId && workspaceData.data
      ? workspaceData.data.find((ws) => ws.id === sourceWsId)!
      : null,
  );
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([]);

  // Feature queries
  const { data: featureQueries } = useWorkspaceFeatureQueries(
    sourceWsId && workspaceData.data
      ? workspaceData.data.find((ws) => ws.id === sourceWsId)!
      : null,
  );
  const [selectedFeatureQueries, setSelectedFeatureQueries] = useState<
    FeatureQuery[]
  >([]);

  // Clean up after new source ws is selected
  useEffect(() => {
    setSelectedEntitySchemas([]);
    setSelectedEventTypes([]);
    setSelectedFeatures([]);
    setSelectedFeatureQueries([]);
  }, [sourceWsId]);

  return (
    <Modal open={isOpen} size="xl" onClose={onClose}>
      <Modal.Header>Taktile 3.0 Workspace Setup Tool</Modal.Header>
      <Modal.Content>
        {!workspaceData.data || !organizationsData.data ? (
          <Spinner />
        ) : (
          <div className="flex flex-wrap gap-8">
            <div className="w-80">
              <Label>Source workspace</Label>
              <Select
                options={workspaceData.data
                  .map((ws) => ({
                    key: ws.id,
                    value: `${ws.name} (${
                      organizationsData.data.find(
                        (org) => org.id === ws.organization_id,
                      )?.name
                    })`,
                  }))
                  .filter((ws) => ws.key !== selectedWs.id)}
                value={sourceWsId}
                onChange={setSourceWsId}
              />
              <div className="mt-2"></div>
              <Label>Target workspace</Label>
              <Select
                options={[
                  {
                    key: selectedWs.id,
                    value: `${selectedWs.name} (${
                      organizationsData.data.find(
                        (org) => org.id === selectedWs.organization_id,
                      )?.name
                    })`,
                  },
                ]}
                value={selectedWs.id}
                disabled
              />
            </div>
            {sourceWsId && (
              <>
                <SectionContainer
                  icon={faCube}
                  loading={!existingEntitySchemas}
                  title="Entity types"
                  onAdd={() => {
                    setAddEntityTypeModalOpen(true);
                  }}
                  onSelectAll={() => {
                    setSelectedEntitySchemas(
                      selectedEntitySchemas.length === entitySchemas.length
                        ? []
                        : cloneDeep(entitySchemas),
                    );
                  }}
                >
                  {entitySchemas.map((entitySchema) => {
                    const selectedEntity = selectedEntitySchemas.find(
                      (es) => es._id === entitySchema._id,
                    );
                    const checked = !!selectedEntity;
                    return (
                      <SelectableItem
                        checked={checked}
                        id={entitySchema._id}
                        label={
                          <div className="flex flex-1 items-center justify-between">
                            <span className="font-mono text-green-600">
                              {entitySchema._id}
                            </span>
                            {checked && (
                              <ChangeJSON
                                value={JSON.stringify(selectedEntity, null, 2)}
                                onChange={(value) => {
                                  setSelectedEntitySchemas(
                                    selectedEntitySchemas.map((es) =>
                                      es._id === entitySchema._id
                                        ? JSON.parse(value)
                                        : es,
                                    ),
                                  );
                                }}
                              />
                            )}
                          </div>
                        }
                        onChange={(checked: boolean) => {
                          if (checked) {
                            setSelectedEntitySchemas([
                              ...selectedEntitySchemas,
                              cloneDeep(entitySchema),
                            ]);
                          } else {
                            setSelectedEntitySchemas(
                              selectedEntitySchemas.filter(
                                (es) => es._id !== entitySchema._id,
                              ),
                            );
                          }
                        }}
                      />
                    );
                  })}
                </SectionContainer>
                <SectionContainer
                  icon={faTimer}
                  loading={!existingEventTypes}
                  title="Event types"
                  onAdd={() => {
                    setAddEventTypeModalOpen(true);
                  }}
                  onSelectAll={() => {
                    setSelectedEventTypes(
                      selectedEventTypes.length === eventTypes.length
                        ? []
                        : cloneDeep(eventTypes),
                    );
                  }}
                >
                  {eventTypes.map((eventType) => {
                    const selectedEvent = selectedEventTypes.find(
                      (et) => et.event_type === eventType.event_type,
                    );
                    const checked = !!selectedEvent;
                    return (
                      <SelectableItem
                        checked={checked}
                        id={eventType.event_type}
                        label={
                          <div className="flex flex-1 items-center justify-between">
                            <span className="font-mono text-pink-600">
                              {eventType.event_type}
                            </span>
                            {checked && (
                              <ChangeJSON
                                value={JSON.stringify(selectedEvent, null, 2)}
                                onChange={(value) => {
                                  setSelectedEventTypes(
                                    selectedEventTypes.map((et) =>
                                      et.event_type === eventType.event_type
                                        ? JSON.parse(value)
                                        : et,
                                    ),
                                  );
                                }}
                              />
                            )}
                          </div>
                        }
                        onChange={(checked: boolean) => {
                          if (checked) {
                            setSelectedEventTypes([
                              ...selectedEventTypes,
                              cloneDeep(eventType),
                            ]);
                          } else {
                            setSelectedEventTypes(
                              selectedEventTypes.filter(
                                (et) => et.event_type !== eventType.event_type,
                              ),
                            );
                          }
                        }}
                      />
                    );
                  })}
                </SectionContainer>
                <SectionContainer
                  icon={faCalculatorSimple}
                  loading={!features}
                  title="Features"
                  onSelectAll={() => {
                    if (features) {
                      setSelectedFeatures(
                        selectedFeatures.length === features.length
                          ? []
                          : cloneDeep(features),
                      );
                    }
                  }}
                >
                  {features?.map((feature) => (
                    <SelectableItem
                      checked={
                        !!selectedFeatures.find((f) => f.key === feature.key)
                      }
                      id={feature.key}
                      label={
                        <Tooltip body={feature.description} placement="right">
                          <span className="cursor-default font-mono text-yellow-700">
                            {feature.key}
                          </span>
                        </Tooltip>
                      }
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setSelectedFeatures([
                            ...selectedFeatures,
                            cloneDeep(feature),
                          ]);
                        } else {
                          setSelectedFeatures(
                            selectedFeatures.filter(
                              (f) => f.key !== feature.key,
                            ),
                          );
                        }
                      }}
                    />
                  ))}
                </SectionContainer>
                <SectionContainer
                  icon={faCode}
                  loading={!featureQueries}
                  title="Feature queries"
                  onSelectAll={() => {
                    if (featureQueries) {
                      setSelectedFeatureQueries(
                        selectedFeatureQueries.length === featureQueries.length
                          ? []
                          : cloneDeep(featureQueries),
                      );
                    }
                  }}
                >
                  {featureQueries?.map((featureQuery) => (
                    <SelectableItem
                      checked={
                        !!selectedFeatureQueries.find(
                          (fq) => fq.id === featureQuery.id,
                        )
                      }
                      id={featureQuery.id!}
                      label={
                        <div className="flex gap-2">
                          <DisplaySql query={featureQuery.query!} />
                          <span className="font-mono text-pink-600">
                            {featureQuery.event}
                          </span>
                          {featureQuery.columns?.features &&
                            featureQuery.columns.features.map((f) => (
                              <span className="font-mono text-yellow-700">
                                {f.feature_key}
                              </span>
                            ))}
                          {featureQuery.columns?.entities &&
                            featureQuery.columns.entities.map((e) => (
                              <span className="font-mono text-green-700">
                                {e.entity_name}
                              </span>
                            ))}
                        </div>
                      }
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setSelectedFeatureQueries([
                            ...selectedFeatureQueries,
                            cloneDeep(featureQuery),
                          ]);
                        } else {
                          setSelectedFeatureQueries(
                            selectedFeatureQueries.filter(
                              (fq) => fq.id !== featureQuery.id,
                            ),
                          );
                        }
                      }}
                    />
                  ))}
                </SectionContainer>
              </>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="submit"
            disabled={
              !selectedEntitySchemas.length &&
              !selectedEventTypes.length &&
              !selectedFeatures.length &&
              !selectedFeatureQueries.length
            }
            variant="primary"
            onClick={async () => {
              await submit({
                targetWs: selectedWs,
                selectedEntitySchemas,
                selectedEventTypes,
                selectedFeatures,
                selectedFeatureQueries,
              });
              onClose();
            }}
          >
            Submit
          </Button>
        }
      />
      <AddTypeModal
        isOpen={addEntityTypeModalOpen}
        placeholder="e.g. Dispute"
        title="Add Entity Type"
        onClose={(id) => {
          setAddEntityTypeModalOpen(false);
          if (id) {
            const alreadyExists = entitySchemas.find((es) => es._id === id);
            if (alreadyExists) {
              console.log("Entity type already exists", id);
            } else {
              setCustomEntitySchemas([
                ...customEntitySchemas,
                {
                  _id: id,
                  _related: {},
                  _updated_at: 1738133585,
                  _display_name_plural: id + "s",
                  _display_name_singular: id,
                  _display_symbol: "",
                  _etag: 1,
                  _primary_property: "",
                  _secondary_property: "",
                  _schema: "Entity",
                  id: {
                    _type: "string",
                    _indexed: true,
                    _order: 1,
                    _display_name: "ID",
                  },
                },
              ]);
            }
          }
        }}
      />
      <AddTypeModal
        isOpen={addEventTypeModalOpen}
        placeholder="e.g. transaction"
        title="Add Event Type"
        onClose={(id) => {
          setAddEventTypeModalOpen(false);
          if (id) {
            const alreadyExists = eventTypes.find((et) => et.event_type === id);
            if (alreadyExists) {
              console.log("Event type already exists", id);
            } else {
              setCustomEventTypes([
                ...customEventTypes,
                {
                  event_type: id,
                  internal_name: id,
                  display_name_singular: id,
                  display_name_plural: id + "s",
                  display_symbol: "",
                  properties: {
                    id: {
                      type: PropertyDefinitionOutputTypeEnum.STRING,
                      display_name: "ID",
                      required: true,
                    },
                    timestamp: {
                      type: PropertyDefinitionOutputTypeEnum.DATETIME,
                      display_name: "Timestamp",
                      required: true,
                    },
                  },
                  json_schema: {},
                  entities_json_schema: {},
                },
              ]);
            }
          }
        }}
      />
    </Modal>
  );
};

const SectionContainer: React.FC<{
  icon: IconProp;
  title: string;
  children?: ReactNode;
  loading: boolean;
  onAdd?: () => void;
  onSelectAll: () => void;
}> = ({ children, loading, title, onSelectAll, icon, onAdd }) => {
  return (
    <div>
      <div className="mb-2 flex items-center justify-between gap-1">
        <Icon color="text-gray-600" icon={icon} size="xs" />
        <Label mb="mb-0">{title}</Label>
        {onAdd && (
          <Icon color="text-gray-500" icon={faPlus} size="xs" onClick={onAdd} />
        )}
        <button
          className="ml-auto text-gray-500 font-inter-medium-11px"
          onClick={onSelectAll}
        >
          Select all
        </button>
      </div>
      <div className="decideScrollbar max-h-[300px] min-h-[150px] min-w-[384px] max-w-[700px] overflow-y-auto rounded border border-gray-200 p-2">
        {loading ? (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

const SelectableItem: React.FC<{
  label: ReactNode;
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}> = ({ id, checked, onChange, label }) => {
  return (
    <div key={id} className="flex items-center gap-1 font-inter-medium-12px">
      <Checkbox
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.checked);
        }}
      ></Checkbox>
      {label}
    </div>
  );
};

const submit = async ({
  targetWs,
  selectedEntitySchemas,
  selectedEventTypes,
  selectedFeatures,
  selectedFeatureQueries,
}: {
  targetWs: WorkspaceDataplane;
  selectedEntitySchemas: Record<string, any>[];
  selectedEventTypes: EventConfigEnriched[];
  selectedFeatures: Feature[];
  selectedFeatureQueries: FeatureQuery[];
}) => {
  let toastId: string = "";
  try {
    toastId = createToast("loading")({
      title: "Creating feature store config",
      duration: Infinity,
    });
    const entitiesApi = entitiesApiBase(targetWs.base_url!);
    for (const entitySchema of selectedEntitySchemas) {
      console.log("Creating entity schema", entitySchema);
      await entitiesApi.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut({
        schema: PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum.ENTITY,
        entityId: entitySchema._id,
        body: entitySchema,
      });
    }
    const eventsApi = eventsApiBase(`${targetWs.base_url}`);
    for (const eventType of selectedEventTypes) {
      console.log("Creating event type", eventType);
      delete eventType.created_at;
      delete eventType.updated_at;
      await eventsApi.saveEventConfigEventsPost({
        // @ts-ignore
        eventConfig: eventType,
      });
    }
    const featuresApi = featuresApiBase(`${targetWs.base_url}`);
    for (const feature of selectedFeatures) {
      console.log("Creating feature", feature);
      const { description, key, name } = feature;
      await featuresApi.createFeatureHandlerFeaturesPost({
        featureCreate: { description, key, name },
      });
    }
    const featureQueriesApi = featureQueriesApiBase(`${targetWs.base_url}`);
    for (const featureQuery of selectedFeatureQueries) {
      console.log("Creating feature query", featureQuery);
      await featureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost({
        featureQueryCreate: featureQuery,
      });
    }
    createToast("success")({
      title: "Feature store config created successfully",
      id: toastId,
    });
  } catch (err) {
    createToast("error")({
      title: "Failed to create feature store config",
      id: toastId,
    });
    console.error(err);
    throw err;
  }
};

const DisplaySql: React.FC<{ query: string }> = ({ query }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomPopover
      button={
        <div className="w-60 truncate">
          <HighlightedSql sqlString={query} />
        </div>
      }
      className="h-[400px] w-[400px] border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <CodeEditor
          language="sql"
          value={formatAthenaSqlQuery(query)}
          lineWrapping
          readOnly
        />
      )}
    </CustomPopover>
  );
};

const ChangeJSON: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomPopover
      button={<Icon color="text-gray-400" icon={faPencil} size="xs" />}
      className="h-[400px] w-[400px] border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <CodeEditor
          language={undefined}
          value={value}
          lineWrapping
          onChange={onChange}
        />
      )}
    </CustomPopover>
  );
};

type AddTypeModalProps = {
  isOpen: boolean;
  onClose: (id?: string) => void;
  title: string;
  placeholder: string;
};

export const AddTypeModal: React.FC<AddTypeModalProps> = ({
  isOpen,
  onClose,
  title,
  placeholder,
}) => {
  const [id, setId] = useState("");

  useEffect(() => {
    if (isOpen) {
      setId("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Label>{title} ID</Label>
        <Input
          placeholder={placeholder}
          value={id}
          monospaced
          onChange={(event) => {
            setId(event.target.value);
          }}
        />
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="submit"
            variant="primary"
            onClick={() => {
              onClose(id);
            }}
          >
            Submit
          </Button>
        }
      />
    </Modal>
  );
};
