import { createColumnHelper } from "@tanstack/react-table";
import { isBoolean, isNull, times, capitalize } from "lodash";
import { useMemo } from "react";

import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { TableComp } from "src/base-components/Table";
import { FeatureType } from "src/clients/features-control";
import { Tooltip } from "src/design-system/Tooltip";
import { JobPreviewResult } from "src/jobs/api/queries";
import { TYPE_ICONS } from "src/utils/constants";
import { formatDate, isISO8601 } from "src/utils/datetime";
import {
  objectToSpeakPythonString,
  speakPythonPrimitive,
} from "src/utils/speakPython";

export const SQLPreviewTable: React.FC<{
  rows?: JobPreviewResult["data"]["result"];
  isLoading?: boolean;
}> = ({ rows = [], isLoading = false }) => {
  const columns = useMemo(() => getColumns(rows, isLoading), [rows, isLoading]);
  return (
    <div className="max-h-full min-h-0 max-w-full overflow-auto">
      <TableComp
        columns={columns}
        data={rows}
        dataLoc="sql-preview-table"
        frameClassName="w-full"
        isLoading={isLoading}
      />
    </div>
  );
};

const PreviewHeader: React.FC<{
  children: React.ReactNode;
  icon: React.ReactNode;
}> = ({ children, icon }) => (
  <div className="flex gap-x-0.5 border-r border-r-gray-100 bg-gray-50 py-2 pl-1 pr-2 font-inter-medium-12px">
    {icon}
    <span>{children}</span>
  </div>
);

const columnHelper = createColumnHelper<Record<string, any>>();

const getColumns = (
  data: JobPreviewResult["data"]["result"],
  isLoading: boolean,
) => {
  if (data.length) {
    const types = inferDataTypes(data);
    return Object.keys(data[0]).map((key) =>
      columnHelper.accessor(key, {
        header: ({ header }) => (
          <PreviewHeader
            icon={
              <Tooltip
                placement="top"
                title={
                  types[header.id] ? capitalize(types[header.id]) : "Unknown"
                }
              >
                <Icon
                  color="text-gray-500"
                  icon={TYPE_ICONS[types[header.id] ?? "any"]}
                  size="2xs"
                />
              </Tooltip>
            }
          >
            {header.id}
          </PreviewHeader>
        ),
        cell: ({ cell }) => (
          <div className="truncate border-r border-r-gray-100 py-2 pl-1.5 pr-2 font-inter-normal-12px">
            {renderValue(cell.getValue(), types[cell.column.id] ?? "any")}
          </div>
        ),
      }),
    );
  }

  if (isLoading)
    return times(5, (i) =>
      columnHelper.accessor(`loading_${i}}`, {
        header: () => (
          <PreviewHeader
            icon={<SkeletonPlaceholder height="h-4" width="w-4" />}
          >
            <SkeletonPlaceholder height="h-4" width="w-16" />
          </PreviewHeader>
        ),
      }),
    );

  return [];
};

const renderValue = (value: any, type: ColumnType) => {
  if (isNull(value) || isBoolean(value))
    return speakPythonPrimitive(String(value));

  if (type === "datetime") {
    return formatDate(value);
  }

  if (type === "array" || type === "object") {
    return objectToSpeakPythonString(value);
  }

  return value;
};

const inferColumnDataType = (value: unknown): FeatureType | undefined => {
  if (typeof value === "number") {
    return FeatureType.NUMBER;
  } else if (typeof value === "boolean") {
    return FeatureType.BOOLEAN;
  } else if (typeof value === "string") {
    return !isISO8601(value) ? FeatureType.STRING : FeatureType.DATETIME;
  } else if (Array.isArray(value)) {
    return FeatureType.ARRAY;
  } else if (typeof value === "object") {
    return FeatureType.OBJECT;
  } else {
    return undefined;
  }
};

export const inferDataTypes = (rows: JobPreviewResult["data"]["result"]) => {
  const types: Record<string, FeatureType | undefined> = {};
  const fieldsCount = Object.keys(rows.at(0) ?? {}).length;

  for (const row of rows) {
    for (const key in row) {
      if (row[key] !== null && !types[key]) {
        types[key] = inferColumnDataType(row[key]);
      }

      if (fieldsCount === Object.keys(types).length) {
        // Quit early if all types are detected
        return types;
      }
    }
  }

  return types;
};

type ColumnType = keyof typeof TYPE_ICONS;
