import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { useDatasetEditTableActions } from "src/datasets/DatasetTable/stores";
import {
  ButtonItem,
  ControllableDropdown,
} from "src/datasets/components/ControllableDropdown";
import {
  expandEntitySchema,
  getEntityTemplate,
} from "src/datasets/dataTemplateUtils";
import { findSchema } from "src/entities/entityView/utils";
import { useEntitySchemas } from "src/entities/queries";
import { useEntityRelatedFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { useWorkspaceContext } from "src/router/routerContextHooks";

export const RelationCellDropdown: React.FC<{
  isOpen: boolean;
  relation: string;
  onFill: (value: string) => void;
}> = ({ isOpen, relation, onFill }) => {
  const { enableEditingMode } = useDatasetEditTableActions();
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();
  const { data: schemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: { enabled: featureGates.entitiesEnabled },
  });
  const { data: features } = useEntityRelatedFeatures({
    options: {
      enabled: featureGates.featuresEventsEnabled,
    },
  });

  const onClick = () => {
    if (schemasData) {
      const relationSchema = findSchema(relation, schemasData);

      if (relationSchema) {
        const expandedSchema = expandEntitySchema(
          relationSchema,
          schemasData.entities,
          // Depth is 4 because we're already at the relation level
          4,
        );
        onFill(
          JSON.stringify(
            getEntityTemplate(expandedSchema, features ?? [], featureGates),
          ),
        );
        enableEditingMode();
      }
    }
  };

  return (
    <ControllableDropdown isOpen={isOpen}>
      <ButtonItem icon={faPlus} label={`New ${relation}`} onClick={onClick} />
    </ControllableDropdown>
  );
};
