import {
  ReviewCaseHighlightsElement,
  ReviewCaseHighlightsField,
} from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { mdService } from "src/base-components/TextEditor/MarkdownService";
import { ManualReviewFieldList } from "src/entities/entityView/DecisionsSidePane/ManualReviewFieldList";

type ProcessedHighlight =
  | Exclude<ReviewCaseHighlightsElement, ReviewCaseHighlightsField>
  | { type: "table"; tableRows: ReviewCaseHighlightsField[] };

const HighlightTable: React.FC<{ data: ReviewCaseHighlightsField[] }> = ({
  data,
}) => {
  const transformedData = data.map((row) => ({
    key: row.readable_name,
    value: row.value,
  }));

  return <ManualReviewFieldList data={transformedData} />;
};

const processHighlights = (highlights: ReviewCaseHighlightsElement[]) => {
  return highlights.reduce<ProcessedHighlight[]>((acc, current) => {
    if (current.type !== "field") {
      acc.push(current);
      return acc;
    }

    // If it's a field type, check if the previous item was a table
    const lastItem = acc[acc.length - 1];
    if (lastItem && lastItem.type === "table") {
      // Add to existing table
      lastItem.tableRows.push(current);
    } else {
      // Create new table
      acc.push({
        type: "table",
        tableRows: [current],
      });
    }

    return acc;
  }, []);
};

export const ManualReviewHighlights: React.FC<{
  highlights: ReviewCaseHighlightsElement[];
}> = ({ highlights }) => {
  const processedHighlights = processHighlights(highlights);

  return processedHighlights.map((highlight, index) => {
    if (highlight.type === "description") {
      return (
        <div
          //skipcq: JS-0440
          dangerouslySetInnerHTML={{
            __html: mdService.toHtml(highlight.value),
          }}
          key={index}
          className="review-case-description pb-6"
        />
      );
    }

    if (highlight.type === "divider") {
      return <Divider key={index} spacing="my-4" />;
    }

    return <HighlightTable key={index} data={highlight.tableRows} />;
  });
};
