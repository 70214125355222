import { Popover } from "@headlessui2/react";
import { isAxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { v4 } from "uuid";

import { NodeBET } from "src/api/flowTypes";
import { ErrorBaseInfo } from "src/api/types";
import { useFloatingWindowsActions } from "src/base-components/FloatingWindow/hooks";
import { FloatingWindowProps } from "src/base-components/FloatingWindow/store";
import { NodeConversationContextContextTypeEnum } from "src/clients/copilot";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { ConversationMessageList } from "src/copilot/ConversationMessageList";
import { EmptyState } from "src/copilot/EmptyState";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";
import { PromptBox } from "src/copilot/PromptBox";
import { TitleHelpContent } from "src/copilot/TitleHelpContent";
import { TitleRightContent } from "src/copilot/TitleRightContent";
import {
  conversationKey,
  useAskCopilot,
  useConversation,
  useFetchCompletion,
} from "src/copilot/queries";
import { useFirstDataSample } from "src/copilot/useFirstDataSample";
import {
  FloatingWindowInternal,
  useFloatingWindowPosition,
} from "src/datasets/DatasetTable/FloatingWindow";
import {
  NoCompletionDataAvailable,
  useNodeCompletionData,
} from "src/nodeEditor/api/queries";
import { useHandleApplyNodeSuggestion } from "src/nodeEditor/applyNodeSuggestion";
import { queryClient } from "src/queryClient";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { GraphTransform } from "src/utils/GraphUtils";
import { logger } from "src/utils/logger";

type WindowData = {
  nodeId: string;
  conversationId: string;
  resetConversationId: () => void;
  error?: ErrorBaseInfo;
};

const assertWindowData = (data: unknown): data is WindowData => {
  return Boolean(
    data &&
      typeof data === "object" &&
      "nodeId" in data &&
      "conversationId" in data &&
      "resetConversationId" in data,
  );
};

const CopilotTitle: React.FC = () => {
  return (
    <span className="text-gray-800 font-inter-normal-13px">
      Taktile Copilot
    </span>
  );
};

export const CopilotWindow: React.FC<FloatingWindowProps> = observer(
  ({ computePosition, id, data: windowData }) => {
    if (!assertWindowData(windowData)) {
      throw new Error("Invalid floating window data", windowData);
    }

    const { close: closeWindow } = useFloatingWindowsActions();
    const { position, windowRef } = useFloatingWindowPosition(computePosition);
    const { nodes, selectedNode, setSelectedNode } = useGraphStore();
    const node = nodes.get(windowData.nodeId) ?? null;
    const onApply = useHandleApplyNodeSuggestion();

    const { workspace, version } = useAuthoringContext();
    const listRef = useRef<HTMLUListElement>(null);
    const [prompt, setPrompt] = useState("");
    const [completionId, setCompletionId] = useState<string>(v4());
    const dataSample = useFirstDataSample({ node });
    const [indexToAnimate, setIndexToAnimate] = useState<number>(-1);
    const { data } = useConversation({
      conversationId: windowData.conversationId,
    });
    const { data: nodeTypingData } = useNodeCompletionData(
      version.id,
      node?.id,
      node?.type === NODE_TYPE.LOOP_NODE,
    );
    const {
      mutateAsync: askCopilot,
      isPending: isAskCopilotLoading,
      isError: isAskCopilotError,
      failureCount: askCopilotFailureCount,
      reset: resetAskCopilot,
      variables,
    } = useAskCopilot({
      conversationId: windowData.conversationId,
      baseUrl: workspace.base_url!,
    });
    const {
      mutateAsync: fetchCompletion,
      isPending: isCompletionLoading,
      isError: isCompletionError,
      failureCount: completionFailureCount,
    } = useFetchCompletion(windowData.conversationId);

    const messages = data?.messages ?? [];
    const isLoading = isAskCopilotLoading || isCompletionLoading;
    const isError = isAskCopilotError || isCompletionError;
    const failureCount = askCopilotFailureCount + completionFailureCount;

    const handlePromptClick = async (
      prompt: string | null,
      mentionedNodes: NodeBET[] = [],
    ) => {
      if (!node) return;

      const versionParameters = version.parameters?.reduce(
        (acc, p) => ({
          ...acc,
          [p.name]: p.value,
        }),
        {},
      );

      try {
        // set the index to animate the last message
        setIndexToAnimate(messages.length + 1);

        await askCopilot({
          nodeId: node.id,
          completionId,
          message: prompt,
          context: {
            node_definition: GraphTransform.nodeReverseMapper(node)!,
            input_schema: version.input_schema,
            output_schema: version.output_schema,
            data_sample: dataSample,
            parameters: versionParameters,
            data_typing:
              nodeTypingData === NoCompletionDataAvailable
                ? undefined
                : nodeTypingData,
            context_type: NodeConversationContextContextTypeEnum.NODE,
            mentioned_nodes:
              mentionedNodes.length > 0 ? mentionedNodes : undefined,
          },
        });
        setCompletionId(v4());
      } catch (e) {
        logger.error(e);

        // if the request failed due to a 503 timeout, we need start polling for completion
        if (isAxiosError(e)) {
          resetAskCopilot();
          try {
            await fetchCompletion({
              completionId,
              baseUrl: workspace.base_url!,
            });
          } catch (e) {
            logger.error(e);
          }

          setCompletionId(v4());
        }
      }
    };

    const handleClose = () => {
      closeWindow(id);
      setPrompt("");
      // setting the index to prevent any message from animating when
      // reopening the copilot window
      setIndexToAnimate(-1);
    };

    const handleReset = () => {
      queryClient.removeQueries({
        queryKey: conversationKey(windowData.conversationId),
      });
      resetAskCopilot();
      setPrompt("");
      windowData.resetConversationId();
    };

    const handleApply = (suggestion: NodeSuggestionType) => {
      if (!selectedNode || selectedNode.id !== windowData.nodeId) {
        setSelectedNode(windowData.nodeId);
      }

      // needs to be done in the next cycle to ensure the sidepane is open
      setTimeout(() => {
        onApply({
          nodeId: windowData.nodeId,
          suggestion,
          completionId: data?.completion_id!,
          conversationId: windowData.conversationId,
        });
      });
    };

    useEffect(() => {
      setIndexToAnimate(-1);
    }, [windowData.nodeId]);

    useEffect(() => {
      if (windowData.nodeId && windowData.error)
        setPrompt((oldPrompt) => {
          if (oldPrompt.length < 1 && windowData.error) {
            return `I'm receiving \`${windowData.error.msg}\`, can you suggest a fix?`;
          }
          return oldPrompt;
        });
    }, [windowData.error, windowData.nodeId]);

    useLayoutEffect(() => {
      if (listRef.current) {
        listRef.current.children[
          listRef.current.children.length - 1
        ].scrollIntoView({ behavior: "smooth" });
      }
    }, [messages.length]);

    if (!node) {
      return null;
    }

    return (
      <Popover>
        <FloatingWindowInternal
          ref={windowRef}
          clipOverflow={false}
          height="h-150"
          helpText={<TitleHelpContent />}
          isPinned={false}
          maximizable={false}
          style={{
            inset: "0px auto auto 0px",
            transform: position,
          }}
          title={<CopilotTitle />}
          titleRight={<TitleRightContent node={node} onReset={handleReset} />}
          draggable
          lockNodeEditor
          onClose={handleClose}
        >
          <div className="flex h-full flex-col">
            {data ? (
              <ConversationMessageList
                ref={listRef}
                conversation={data}
                indexToAnimate={indexToAnimate}
                loading={isLoading}
                node={node}
                regenerating={isLoading && variables?.message === null}
                retrying={isLoading && failureCount > 0}
                onApply={handleApply}
                onRegenerate={() => handlePromptClick(null)}
              />
            ) : (
              <EmptyState onPromptClick={handlePromptClick} />
            )}
            <div className="border-t border-t-gray-200 px-2 py-4">
              <PromptBox
                disabled={isLoading}
                errored={isError}
                minified={messages.length > 0}
                value={prompt}
                onChange={(v) => setPrompt(v)}
                onReset={handleReset}
                onSubmit={(content, mentionedNodes) => {
                  const trimmedContent = content.trim();
                  if (trimmedContent) {
                    handlePromptClick(trimmedContent, mentionedNodes);
                    setPrompt("");
                  }
                }}
              />
            </div>
          </div>
        </FloatingWindowInternal>
      </Popover>
    );
  },
);
