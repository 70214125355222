import {
  faFlask,
  faPlus,
  faWavePulse,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";

import { ManageFeatureModal } from "./ManageFeatureModal";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { useModal } from "src/design-system/Modal";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { FeatureCatalogueWrapper } from "src/featureCatalogue/FeatureCatalogue";
import { Features } from "src/featureCatalogue/Features/Features";
import { useCapabilities } from "src/hooks/useCapabilities";
import { SubHeaderSearchBox } from "src/layout/SubHeader";

const FeaturesHeader: React.FC<{
  onSearch: (query: string) => void;
  openCreateModal: () => void;
}> = ({ onSearch, openCreateModal }) => {
  const [environment, setEnvironment] = useEnvironment();
  const { featureCatalogue } = useCapabilities();

  const dropdownItems: OptionsDropdownElement[] = [
    {
      key: "Create Feature",
      icon: faPlus,
      action: openCreateModal,
      disabled: !featureCatalogue.canCreate
        ? "You don't have permission to create features"
        : undefined,
    },
    MENU_DIVIDER,
    {
      key: `Switch to ${environment === "sandbox" ? "Live" : "Sandbox"}`,
      icon: environment === "sandbox" ? faWavePulse : faFlask,
      action: () =>
        setEnvironment(environment === "sandbox" ? "live" : "sandbox"),
    },
  ] as const;

  return (
    <>
      <SubHeaderSearchBox
        dataLoc="features-search"
        placeholder="Search features..."
        onChange={onSearch}
      />
      <span className="border-l border-gray-200 p-3.5">
        <EllipsisOptionsDropdown
          buttonDataLoc="features-menu-dropdown"
          elements={dropdownItems}
          iconSize="sm"
          placement="bottom-start"
        />
      </span>
    </>
  );
};

export const FeaturesPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { openModal, closeModal, isOpen, afterLeave } = useModal();

  return (
    <FeatureCatalogueWrapper
      header={
        <FeaturesHeader openCreateModal={openModal} onSearch={setSearchQuery} />
      }
    >
      <Features openCreateModal={openModal} searchQuery={searchQuery} />
      <ManageFeatureModal
        afterLeave={afterLeave}
        isOpen={isOpen}
        mode="create"
        onClose={closeModal}
      />
    </FeatureCatalogueWrapper>
  );
};
