import {
  faCalculatorSimple,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CellContext,
  createColumnHelper,
  HeaderContext,
} from "@tanstack/react-table";
import { addSeconds } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { EntityFeatureValue } from "src/clients/features-retrieve/api";
import { JSONCell } from "src/dataTable/JSONCell";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { DisplayDescriptionCell } from "src/featureCatalogue/Features/Features";
import { useEntityFeatures } from "src/featureCatalogue/queries";
import { formatTimeWindow } from "src/featureCatalogue/utils";
import { WhitePane } from "src/jobs/common/WhitePane";
import { EntityViewParams, getUrlToFeaturesPage } from "src/router/urls";
import { TYPE_ICONS } from "src/utils/constants";
import { formatDate } from "src/utils/datetime";
import { useParamsDecode } from "src/utils/useParamsDecode";

const columnHelper = createColumnHelper<EntityFeatureValue>();

const COLUMNS = [
  columnHelper.accessor("name", {
    header: (info: HeaderContext<EntityFeatureValue, string>) => (
      <Header info={info}>Feature name</Header>
    ),
    cell: (info: CellContext<EntityFeatureValue, string>) => {
      const name = info.getValue();
      return (
        <Cell className="gap-x-0.5" info={info}>
          <Icon
            color="text-gray-500"
            icon={
              TYPE_ICONS[info.row.original.type as keyof typeof TYPE_ICONS] ??
              faMinus
            }
            size="xs"
          />
          <Tooltip placement="top" title={name} asChild>
            <div className="truncate">{info.getValue()}</div>
          </Tooltip>
        </Cell>
      );
    },
    size: 250,
    maxSize: 250,
  }),
  {
    accessorKey: "value",
    header: (
      info: HeaderContext<EntityFeatureValue, EntityFeatureValue["value"]>,
    ) => <Header info={info}>Value</Header>,
    cell: (
      info: CellContext<EntityFeatureValue, EntityFeatureValue["value"]>,
    ) => {
      let value = info.getValue();
      if (typeof value === "number" && !Number.isInteger(value)) {
        value = value.toFixed(2);
      }

      if (
        (value !== null && typeof value === "object") ||
        Array.isArray(value)
      ) {
        return (
          <JSONCell
            cell={info.cell}
            className="h-fit text-indigo-600 font-code-12"
            placement="top-start"
            value={value}
          />
        );
      }

      let tooltipTitle = info.getValue();

      if (typeof tooltipTitle === "undefined" || tooltipTitle === null) {
        tooltipTitle =
          "No corresponding event was received in the latest time window";
      }

      return (
        <Cell className="justify-end text-indigo-600 font-code-12" info={info}>
          <Tooltip
            disabled={value !== null}
            placement="top"
            title={tooltipTitle}
            asChild
          >
            <div className="truncate">
              {value === null ? "No value" : value}
            </div>
          </Tooltip>
        </Cell>
      );
    },
    maxSize: 100,
    minSize: 100,
  },
  columnHelper.accessor("description", {
    header: (info: HeaderContext<EntityFeatureValue, string>) => (
      <Header info={info}>Description</Header>
    ),
    cell: (info: CellContext<EntityFeatureValue, string>) => (
      <DisplayDescriptionCell info={info as any} />
    ),
    size: 300,
    maxSize: 300,
  }),
  columnHelper.accessor("key", {
    header: (info: HeaderContext<EntityFeatureValue, string>) => (
      <Header info={info}>Key</Header>
    ),
    cell: (info: CellContext<EntityFeatureValue, string>) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  }),
  {
    accessorKey: "query.event",
    header: (info: HeaderContext<EntityFeatureValue, string | null>) => (
      <Header info={info}>Event</Header>
    ),
    cell: (info: CellContext<EntityFeatureValue, string | null>) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "query.window_length",
    header: (info: HeaderContext<EntityFeatureValue, number | null>) => (
      <Header info={info}>Time window</Header>
    ),
    cell: (info: CellContext<EntityFeatureValue, number | null>) => (
      <Cell info={info}>{formatTimeWindow(info.getValue() ?? 0)}</Cell>
    ),
    size: 100,
  },
  {
    accessorKey: "timestamp",
    header: (
      info: HeaderContext<EntityFeatureValue, EntityFeatureValue["timestamp"]>,
    ) => <Header info={info}>Last Updated</Header>,
    cell: (
      info: CellContext<EntityFeatureValue, EntityFeatureValue["timestamp"]>,
    ) => {
      const lastUpdateTimestamp = info.getValue();

      if (!lastUpdateTimestamp) {
        return <Cell info={info}>No value</Cell>;
      }

      const windowHop = info.row.original.query.window_hop;
      const nextUpdate = addSeconds(
        new Date(lastUpdateTimestamp),
        windowHop ?? 0,
      );

      return (
        <Cell info={info}>
          <Tooltip
            placement="bottom"
            title={`Next update on ${formatDate(nextUpdate, "MMM d, yyyy 'at' h:mm a")}`}
          >
            <div>
              {formatDate(lastUpdateTimestamp, "MMM d, yyyy 'at' h:mm a")}
            </div>
          </Tooltip>
        </Cell>
      );
    },
    size: 150,
  },
];

export const Features: React.FC<{}> = () => {
  const [environment] = useEnvironment();
  const navigate = useNavigate();
  const {
    schema: entityType,
    id: entityId,
    orgId,
    wsId,
  } = useParamsDecode<EntityViewParams>();
  const { data: features, isLoading } = useEntityFeatures(
    environment,
    entityId,
    entityType,
  );

  return (
    <div className="p-5">
      <WhitePane noMinWidth>
        {features?.data?.length === 0 ? (
          <EmptyState
            actionButton={{
              text: "View Features",
              onClick: () => {
                navigate(getUrlToFeaturesPage(orgId, wsId));
              },
            }}
            dataLoc="features-empty-state"
            description="Ensure that features are configured and have data to process."
            headline="No feature values available yet"
            icon={faCalculatorSimple}
          />
        ) : (
          <TableComp
            columns={COLUMNS}
            data={features?.data ?? []}
            dataLoc="features-list"
            frameClassName="w-full p-4"
            isLoading={isLoading}
            variant="compact"
          />
        )}
      </WhitePane>
    </div>
  );
};
