import { PropertyValueT, SchemaT } from "src/api/flowTypes";
import {
  AiNodeConversationMessageRoleEnum,
  AiNodeJsonResponseConfigResponseTypeEnum,
  AiNodeTextResponseConfigResponseTypeEnum,
  AiNodeV2InferenceConfig,
  AiNodeV2Meta,
  AiNodeV2ResponseFormatConfigTypeEnum,
  AiNodeV2ConversationMessageFilesListContentBlock,
  AiNodeV2ConversationMessageTextContentBlock,
} from "src/clients/flow-api";
import { OutputMappingBET, SingleMappingBET } from "src/integrationNode/types";
import { PropertyUIT } from "src/schema/schemaMappingUtils";

export type BasicModelCard = {
  model_id: string;
  name: string;
};

export type AiNodeQuotaT = {
  global: {
    quota: number;
    used: number;
  };
};

type AiNodeResponseConfig =
  | {
      response_type: AiNodeTextResponseConfigResponseTypeEnum;
      output_field: OutputMappingBET;
      stop_sequences: string[];
    }
  | {
      response_type: AiNodeJsonResponseConfigResponseTypeEnum;
      output_field: OutputMappingBET;
      json_schema: SingleMappingBET;
    };

type AiNodeConversationMessageContentBlock = {
  text: SingleMappingBET;
};

type AiNodeConversationMessage = {
  role: AiNodeConversationMessageRoleEnum;
  content: AiNodeConversationMessageContentBlock[];
};

export type AiNodeForm = {
  model: {
    model_id: string;
    provider: string;
  };
  prompts: {
    system_prompt: SingleMappingBET;
    messages: AiNodeConversationMessage[];
  };
  response_config: AiNodeResponseConfig;
  inference_config: {
    temperature?: number;
  };
};

export type AiNodePropertyBET = PropertyValueT & {
  // `description` part of the JsonSchema standard
  description?: string;
  // `outputId` is a custom field that we use to tie together
  // the property with the output field
  outputId?: string;
};

export type AiNodeJsonSchemaBET = SchemaT<AiNodePropertyBET>;

export type AiNodeJsonSchemaPropertyUIT = PropertyUIT & {
  outputId: string;
  description?: string;
};

export type AiNodeJsonSchemaUIT = {
  $schema: string;
  properties: AiNodeJsonSchemaPropertyUIT[];
  type: "object";
};

/* --- Autogenerated types fix (START) --- */
/**
 * The autogenerated type results in a `never[]` type for the `content` field.
 * This causes a lot of headaches, so explicitly redefining the type here.
 */
export type AiNodeV2MessageContentBlock =
  | AiNodeV2ConversationMessageTextContentBlock
  | AiNodeV2ConversationMessageFilesListContentBlock;

export type AiNodeV2MetaFixed = Omit<AiNodeV2Meta, "prompts"> & {
  prompts: Omit<AiNodeV2Meta["prompts"], "messages"> & {
    messages: Array<
      Omit<AiNodeV2Meta["prompts"]["messages"][number], "content"> & {
        content: Array<AiNodeV2MessageContentBlock>;
      }
    >;
  };
};

/* --- Autogenerated types fix (END) --- */

// Redefining the json_schema field to reflect the structure we use on FE.
// BE is using the actual jsonschema format
export type AiNodeV2Form = Omit<AiNodeV2MetaFixed, "inference"> & {
  inference: Omit<AiNodeV2InferenceConfig, "response_format"> & {
    response_format: {
      type: AiNodeV2ResponseFormatConfigTypeEnum;
      json_schema?: AiNodeJsonSchemaUIT;
    };
  };
};

// A subset of "Input/Output" schema types we want to support
export const AiNodeSelectableSchemaTypes = {
  string: "string",
  boolean: "boolean",
  number: "number",
  integer: "integer",
  object: "object",
  array: "array",
  enum: "enum",
} as const;
