import { fromPairs } from "lodash";
import { v4 } from "uuid";

import { ResourceSample } from "src/api/connectApi/types";
import { GenericObjectT, SchemaTypesT } from "src/api/flowTypes";
import { FlowNodeMeta } from "src/clients/flow-api";
import { LoopNodeDataT } from "src/constants/NodeDataTypes";
import { VersionSchemas } from "src/datasets/DatasetTable/types";
import { FlowNodeForm, SchemaError } from "src/parentFlowNodes/flowNode/types";
import { LoopNodeForm } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import { LeftPaneOptions, SchemaOptions } from "src/router/SearchParams";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";
import { SchemaUIT } from "src/schema/schemaMappingUtils";

type UISchemas = { input?: SchemaUIT; output?: SchemaUIT };

// Flow and flow version queries answer with 403 after deletion while the version revision query answers 404
export const queryErrorLikelyBecauseChildFlowGotDeleted = (
  errorMessage: string,
) => errorMessage.endsWith("403") || errorMessage.endsWith("404");

export const validateInputSchemaMapping = (
  inputSchema: SchemaUIT | undefined,
  inputMapping: (FlowNodeMeta & LoopNodeDataT)["input_mappings"],
): SchemaError[] => {
  const errors: SchemaError[] = [];

  if (inputSchema && inputSchema.properties.length) {
    const fieldNameToId = buildFieldNameToIdMap(inputMapping);
    inputSchema.properties.forEach((field) => {
      const id = fieldNameToId[field.fieldName];
      if (field.required && (!id || !inputMapping[id]?.source)) {
        errors.push({
          type: "input",
          issue: "field-missing",
          fieldName: field.fieldName,
        });
      }
    });
  } else {
    errors.push({ type: "input", issue: "schema-missing" });
  }
  return errors;
};

export const validateFlowNodeMapping = (
  { input, output }: UISchemas,
  mappings: Pick<FlowNodeMeta, "input_mappings">,
): SchemaError[] => {
  const errors = validateInputSchemaMapping(input, mappings.input_mappings);

  if (!output || !output.properties.length) {
    errors.push({ type: "output", issue: "schema-missing" });
  }

  return errors;
};

export const buildFieldNameToIdMap = (
  values: FlowNodeMeta["input_mappings"] | FlowNodeMeta["output_mappings"],
  srcAsName = false,
): Record<string, string> =>
  Object.keys(values ?? {}).reduce((acc, id) => {
    const field = values[id];
    return {
      ...acc,
      [srcAsName ? field.source : field.destination]: id,
    };
  }, {});

type FieldToRemove = `input_mappings.${string}` | `output_mappings.${string}`;

export const setupFlowNodeForm = (
  data: FlowNodeForm,
  schemas: UISchemas,
): [FlowNodeForm, SchemaError[], FieldToRemove[]] => {
  const inputFieldNameToId = buildFieldNameToIdMap(data.input_mappings);
  const mergedInputMappings = fromPairs(
    schemas.input?.properties.map((field) => {
      const id = inputFieldNameToId[field.fieldName] ?? v4();

      return [
        id,
        {
          id,
          source: data.input_mappings?.[id]?.source ?? "",
          destination: field.fieldName,
        },
      ];
    }),
  );
  const inputFieldsToRemove = Object.keys(data.input_mappings)
    .filter((fieldId) => !mergedInputMappings[fieldId])
    .map((fieldId) => `input_mappings.${fieldId}` as FieldToRemove);

  const outputFieldNameToId = buildFieldNameToIdMap(data.output_mappings, true);
  const mergedOutputMappings = fromPairs(
    schemas.output?.properties.map((field) => {
      const id = outputFieldNameToId[field.fieldName] ?? v4();
      return [
        id,
        {
          id,
          source: field.fieldName,
          destination: data.output_mappings?.[id]?.destination ?? "",
        },
      ];
    }),
  );
  const outputFieldsToRemove = Object.keys(data.output_mappings)
    .filter((fieldId) => !mergedOutputMappings[fieldId])
    .map((fieldId) => `output_mappings.${fieldId}` as FieldToRemove);

  const updatedData = {
    ...data,
    input_mappings: mergedInputMappings,
    output_mappings: mergedOutputMappings,
  };

  return [
    updatedData,
    validateFlowNodeMapping(schemas, updatedData),
    inputFieldsToRemove.concat(outputFieldsToRemove),
  ];
};

type FieldToREmoveLoopNode = `input_mappings.${string}`;

export const setupLoopNodeForm = (
  data: LoopNodeForm,
  schemas: UISchemas,
): [LoopNodeForm, SchemaError[], FieldToREmoveLoopNode[]] => {
  const inputFieldNameToId = buildFieldNameToIdMap(data.input_mappings);
  const mergedInputMappings = fromPairs(
    schemas.input?.properties.map((field) => {
      const id = inputFieldNameToId[field.fieldName] ?? v4();

      return [
        id,
        {
          id,
          source: data.input_mappings?.[id]?.source ?? "",
          destination: field.fieldName,
        },
      ];
    }),
  );
  const inputFieldsToRemove = Object.keys(data.input_mappings)
    .filter((fieldId) => !mergedInputMappings[fieldId])
    .map((fieldId) => `input_mappings.${fieldId}` as FieldToREmoveLoopNode);

  const updatedData: LoopNodeForm = {
    ...data,
    input_mappings: mergedInputMappings,
  };

  return [
    updatedData,
    validateInputSchemaMapping(schemas.input, updatedData.input_mappings),
    inputFieldsToRemove,
  ];
};

export const buildSchemaTabUrl = (
  orgId: string,
  workspaceId: string,
  flowId: string,
  versionId: string,
  schemaOptions: SchemaOptions,
) =>
  `${getBaseUrl()}${getUrlToAuthoringPage(
    orgId,
    workspaceId,
    flowId,
    versionId,
    {
      leftPane: LeftPaneOptions.Schema,
      schemaOptions,
    },
  )}`;

export const getSampleReportsForFlowNode = (
  schemas: VersionSchemas,
): ResourceSample[] => {
  const fields = schemas?.output?.properties ?? [];
  const sampleReport: GenericObjectT = {};

  if (fields) {
    const defaults: Record<SchemaTypesT, any> = {
      boolean: true,
      integer: 1,
      number: 1.5,
      string: "example",
      datetime: new Date(),
      date: new Date(),
      "date-str": "",
      "datetime-str": "",
      any: "",
      enum: [],
      array: [],
      object: {},
    };

    for (const field of fields) {
      if (field.type[0] !== "enum") {
        sampleReport[field.fieldName] = defaults[field.type[0]];
      } else if (field.enum && typeof field.enum[0]?.value === "string") {
        try {
          sampleReport[field.fieldName] = JSON.parse(field.enum[0].value);
        } catch {
          sampleReport[field.fieldName] = "";
        }
      } else {
        sampleReport[field.fieldName] = defaults["string"];
      }
    }
  }

  return [{ name: "Decision Flow Node example", sample: sampleReport }];
};

export const getSampleReportsForLoopNode = (
  schemas: VersionSchemas,
): ResourceSample[] => {
  const fields = schemas?.output?.properties ?? [];
  const sampleReport: GenericObjectT = {};

  if (fields) {
    const defaults: Record<SchemaTypesT, any> = {
      boolean: true,
      integer: 1,
      number: 1.5,
      string: "example",
      datetime: new Date(),
      date: new Date(),
      "date-str": "",
      "datetime-str": "",
      any: "",
      enum: [],
      array: [],
      object: {},
    };

    for (const field of fields) {
      if (field.type[0] !== "enum") {
        sampleReport[field.fieldName] = defaults[field.type[0]];
      } else if (field.enum && typeof field.enum[0]?.value === "string") {
        try {
          sampleReport[field.fieldName] = JSON.parse(field.enum[0].value);
        } catch {
          sampleReport[field.fieldName] = "";
        }
      } else {
        sampleReport[field.fieldName] = defaults["string"];
      }
    }
  }

  return [
    {
      name: "Loop Node example",
      sample: sampleReport,
    },
  ];
};
