import { faPlus, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { map } from "lodash";
import { UseFormReturn } from "react-hook-form";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { InputMappingList } from "src/integrationNode/editorComponents/InputMappingList";
import { InputMappingRow } from "src/integrationNode/editorComponents/InputMappingRow";
import { InputMappingGroupT, InputMappingsT } from "src/integrationNode/types";

export type Props = {
  groupName: string;
  displayGroupName?: string;
  group: InputMappingGroupT;
  groupedGroupsAndLists?: boolean;
  onDelete: () => void;
  onAdd: () => void;
  groupClassName: string;
  immutable: boolean;
  formProps: UseFormReturn<InputMappingsT>;
  runFieldErrors?: FieldErrorsT;
};

// InputMappingGroup supports nested lists as well as single fields
export const InputMappingGroup: React.FC<Props> = ({
  group,
  groupClassName,
  groupName,
  groupedGroupsAndLists,
  onDelete,
  onAdd,
  immutable,
  formProps,
  runFieldErrors,
  displayGroupName,
}) => {
  const name = displayGroupName || groupName;
  if (group.elements) {
    return (
      <div className={groupClassName}>
        <div className="mb-4 flex justify-between border-b border-gray-200 pb-2">
          <div className="text-gray-700 font-inter-normal-12px">
            {name.toUpperCase()}
          </div>
          <div className="text-gray-700 font-inter-normal-12px">
            {!group.rules?.required && (
              <Button
                dataLoc={`delete-group-${groupName}`}
                disabled={immutable}
                iconLeft={faTrashCan}
                size="sm"
                variant="secondary"
                onClick={onDelete}
              >
                Delete {name.toLowerCase()}
              </Button>
            )}
          </div>
        </div>
        {map(group.elements, (inputMapping, beName) => (
          <InputMappingRow
            key={beName}
            beName={`${groupName}.${beName}`}
            formControl={formProps.control}
            immutable={immutable}
            inputMapping={inputMapping}
            inputProps={formProps.register(
              `grouped.${groupName}.elements.${beName}.assignedTo`,
            )}
            runFieldError={runFieldErrors?.[inputMapping.id]}
          />
        ))}
        {map(group.lists, (list, listName) => (
          <InputMappingList
            key={listName}
            formProps={formProps}
            groupedGroupsAndLists={groupedGroupsAndLists}
            immutable={immutable}
            list={list}
            listName={listName}
            nestedGroupName={groupName}
            runFieldErrors={runFieldErrors}
          />
        ))}
      </div>
    );
  } else if (!groupedGroupsAndLists) {
    return (
      <span key={groupName} className="mb-4 mr-1 inline-block">
        <Button
          dataLoc={`add-group-${groupName}`}
          disabled={immutable}
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={onAdd}
        >
          Add {name}
        </Button>
      </span>
    );
  }
};
