import {
  QueryState,
  useIsFetching,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { ListReviewCasesResponse } from "src/api/types";

export const useReviewCasesRefresh = () => {
  const queryClient = useQueryClient();
  const fetchingMetrics = useIsFetching({
    queryKey: ["reviewCases"],
    exact: false,
  });
  const [query, setQuery] =
    useState<QueryState<ListReviewCasesResponse> | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!fetchingMetrics) {
      setRefreshing(false);
      // TODO: Remove getting a not exact matching query here. Its hacky and discouraged.
      // check: https://github.com/TanStack/query/discussions/6166
      setQuery(
        (queryClient.getQueryCache().find({
          queryKey: ["reviewCases"],
          exact: false,
          type: "active",
        }) as unknown as QueryState<ListReviewCasesResponse>) ?? null,
      );
    }
  }, [fetchingMetrics, queryClient]);

  const refresh = () => {
    setRefreshing(true);

    queryClient.invalidateQueries({
      queryKey: ["reviewCases"],
    });
  };

  return { dataUpdatedAt: query?.dataUpdatedAt, refreshing, refresh };
};
