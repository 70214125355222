import { endOfDay, startOfDay } from "date-fns";

import { ResourceSample } from "src/api/connectApi/types";
import { type ListReviewCasesParams } from "src/api/endpoints";
import { GenericObjectT } from "src/api/flowTypes";
import { ReviewCaseStatusPending } from "src/api/types";
import { ManualReviewResponseFormFieldTypeEnum } from "src/clients/flow-api";
import { ManualReviewNode } from "src/constants/NodeDataTypes";
import { type ReviewQueueFilters } from "src/manualReview/types";

export const isDefaultFiltersState = (filters: ReviewQueueFilters) => {
  const isDefaultDateRange =
    filters.dateRange?.from === undefined &&
    filters.dateRange?.to === undefined;
  return (
    Object.entries(filters)
      .filter(([key]) => !["environment", "order", "dateRange"].includes(key))
      .every(([, value]) => value === undefined) && isDefaultDateRange
  );
};

// When filter is unset, we want to fetch only these statuses
const DEFAULT_STATUSES: ReviewCaseStatusPending[] = [
  "needs_review",
  "in_progress",
];

export const convertFiltersToParams = ({
  environment,
  order,
  flowVersionNames,
  assignees,
  statuses = DEFAULT_STATUSES,
  entityOrDecisionId: entity_or_decision_id,
  dateRange,
  nodeNames,
}: ReviewQueueFilters): ListReviewCasesParams => ({
  environment,
  order: order === "created_at_asc" ? "asc" : "desc",
  order_by: order === "created_at_asc" ? "created_at" : "decision_start_time",
  flow_version_names: flowVersionNames?.join(","),
  assignees: assignees?.join(","),
  statuses: statuses.join(","),
  entity_or_decision_id,
  before_timestamp:
    (dateRange?.to || dateRange?.from) &&
    endOfDay(dateRange?.to ?? dateRange?.from!).toISOString(),
  after_timestamp:
    dateRange?.from && startOfDay(dateRange?.from)?.toISOString(),
  node_names: nodeNames?.join(","),
});

export const getSampleReportsForManualReviewNode = (
  node: ManualReviewNode,
): ResourceSample[] => {
  const { fields } = node.data.response_form;
  const response_metadata_key = node.data.response_metadata_key;
  const sampleReport: GenericObjectT = {};

  if (fields) {
    const defaults: Record<
      Exclude<ManualReviewResponseFormFieldTypeEnum, "enum">,
      string | number | boolean
    > = {
      boolean: true,
      integer: 1,
      number: 1.5,
      string: "example",
    };

    for (const field of fields) {
      if (field.key) {
        if (field.type !== "enum") {
          sampleReport[field.key] = defaults[field.type];
        } else if (field.enum && typeof field.enum[0] === "string") {
          try {
            sampleReport[field.key] = JSON.parse(field.enum[0]);
          } catch {
            sampleReport[field.key] = "";
          }
        } else {
          sampleReport[field.key] = defaults["string"];
        }
      }
    }
  }

  if (response_metadata_key) {
    sampleReport[response_metadata_key] = {
      submitted_by: "00000000-0000-0000-0000-000000000000",
      submitted_by_username: "Sample User",
      submitted_by_fullname: "Sample User",
      submitted_at: "1900-01-01T00:00:00Z",
      submitted_by_email: "sample@user.com",
    };
  }

  return [{ name: "Manual review example", sample: sampleReport }];
};
