import { faCheck, faClone } from "@fortawesome/pro-regular-svg-icons";
import { m } from "framer-motion";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Icon, IconSizes } from "src/base-components/Icon";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip, TooltipProps } from "src/design-system/Tooltip";
import { copyTextToClipboard } from "src/utils/clipboard";
import { logger } from "src/utils/logger";

type CopyTextIconProps = {
  color?: string;
  withSidebar?: boolean;
  tooltip?: string;
  preventDefault?: boolean;
  dataLoc?: string;
  size?: IconSizes;
  feedback?: "toast" | "inline" | "inline-only-checkmark";
  tooltipPlacement?: TooltipProps["placement"];
} & (
  | { value: string | (() => string) }
  | {
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }
);

export const CopyTextIcon: React.FC<CopyTextIconProps> = ({
  color,
  withSidebar = false,
  tooltip,
  preventDefault = false,
  size = "xs",
  dataLoc,
  feedback = "toast",
  tooltipPlacement = "top",
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.stopPropagation();
      if (preventDefault) {
        // Prevents the form submission when copy is inside the form
        e.preventDefault();
      }
      if ("onClick" in props) {
        await props.onClick(e);
      } else {
        await copyTextToClipboard(
          typeof props.value === "function" ? props.value() : props.value,
        );
      }
      if (feedback === "toast") {
        toastActions.success({ title: "Copied to clipboard!", withSidebar });
      } else {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }
    } catch (error) {
      toastActions.failure({
        title: "Failed to copy to clipboard",
        description: "Check your browser's clipboard settings",
      });
      logger.error(error);
    }
  };

  if (copied) {
    return (
      <Tooltip
        disabled={feedback === "inline-only-checkmark"}
        placement={tooltipPlacement}
        title="Copied!"
        asChild
        open
      >
        <m.span
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.2 }}
        >
          <Icon color="text-green-500" icon={faCheck} size={size} />
        </m.span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      delay={200}
      disabled={!tooltip}
      placement={tooltipPlacement}
      title={tooltip}
      asChild
    >
      <span className="flex">
        <Icon
          color={twMerge("text-gray-500 hover:text-gray-800", color)}
          dataLoc={dataLoc}
          icon={faClone}
          size={size}
          onClick={handleCopy}
        />
      </span>
    </Tooltip>
  );
};
