import { Extension } from "micromark-extension-gfm";
import { markdownLineEnding } from "micromark-util-character";
import { codes } from "micromark-util-symbol/codes";
import { HtmlExtension, Code, Construct, Token } from "micromark-util-types";

type EmptyLineToken = "nbspToBr";

declare module "micromark-util-types" {
  interface TokenTypeMap {
    nbspToBr: EmptyLineToken;
  }
}

export const isEmptyLineOrHeading = (line: string) => {
  // Check if line is empty string
  if (line.length === 0) return false;

  // Match valid heading markers (1-3 #'s) optionally followed by a single space
  const headingPattern = /^#{1,3}( )?$/;
  if (headingPattern.test(line)) return true;

  // Match heading markers followed by partial/complete &nbsp;
  const headingWithEntityPattern = /^#{1,3} (&|&n|&nb|&nbs|&nbsp|&nbsp;)$/;
  if (headingWithEntityPattern.test(line)) return true;

  // Match partial/complete &nbsp; entity without heading
  const entityPattern = /^(&|&n|&nb|&nbs|&nbsp|&nbsp;)$/;
  return entityPattern.test(line);
};

const replaceEmptyLines: Construct = {
  tokenize: function (effects, ok, nok) {
    let chars = "";

    const resetChars = () => {
      chars = "";
    };

    const atLineStart = () => {
      return this.previous === null || markdownLineEnding(this.previous);
    };

    const start = (code: Code) => {
      if (
        atLineStart() &&
        (code === codes.ampersand || code === codes.numberSign)
      ) {
        effects.enter("nbspToBr");
        effects.consume(code);
        chars += String.fromCharCode(code);
        return inside;
      }

      resetChars();
      return nok(code);
    };

    const inside = (code: Code) => {
      if (
        code !== null &&
        isEmptyLineOrHeading(chars + String.fromCharCode(code))
      ) {
        effects.consume(code);
        chars += String.fromCharCode(code);
        return chars.endsWith("&nbsp;") ? end : inside;
      }

      resetChars();
      return nok(code);
    };

    const end = (code: Code) => {
      if (markdownLineEnding(code) || code === codes.eof) {
        effects.exit("nbspToBr");
        return ok(code);
      }
      // If the line doesn't end after '&nbsp;', it's not a valid '&nbsp;' line
      resetChars();
      effects.exit("nbspToBr");
      return nok(code);
    };
    return start;
  },
  continuation: {
    tokenize: (_effects, ok, _nok) => ok,
  },
  exit: () => {},
};

export const emptyLinesSyntaxExtension: Extension = {
  document: {
    [codes.ampersand]: replaceEmptyLines,
    [codes.numberSign]: replaceEmptyLines,
  },
};

// Custom HTML extension to convert recognized `&nbsp;` to `<br />`
export const emptyLinesHtmlExtension: HtmlExtension = {
  enter: {
    nbspToBr(token: Token) {
      const emptyLineData = this.sliceSerialize(token);
      const isHeading = emptyLineData.startsWith("#");

      if (emptyLineData.startsWith("&nbsp;")) {
        this.tag("<p>");
      } else if (isHeading) {
        this.tag("<h1>");
      }

      this.tag("<br />");
    },
  },
  exit: {
    nbspToBr(token: Token) {
      const emptyLineData = this.sliceSerialize(token);
      const isHeading = emptyLineData.startsWith("#");

      if (emptyLineData.startsWith("&nbsp;")) {
        this.tag("</p>");
      } else if (isHeading) {
        this.tag("</h1>");
      }

      this.lineEndingIfNeeded();
    },
  },
};
