import { faClock } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { useHistoryDecisionV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { Icon } from "src/base-components/Icon";
import { DecisionsSidepane } from "src/decisionsOverview/DecisionsSidepane";
import {
  DecisionSidepaneProps,
  ExtendedDecisionV2,
} from "src/decisionsOverview/DecisionsSidepaneWrapper";
import { OutputTabContent } from "src/decisionsOverview/OutputTabContent";
import { hasInputSchemaError } from "src/decisionsOverview/utils";
import { DecisionInputFieldsTree } from "src/entities/entityView/DecisionFieldsTree";
import { ManualReviewTabContent } from "src/entities/entityView/ManualReviewTabContent";
import { useWorkspaceContext } from "src/router/routerContextHooks";

export const DecisionSidepaneWrapper: React.FC<DecisionSidepaneProps> = ({
  decision,
  isOpen,
  onClose,
}) => {
  const { workspace } = useWorkspaceContext();
  const { data: decisionV2Fetch } = useHistoryDecisionV2({
    baseUrl: workspace.base_url!,
    decisionId: decision?.id!,
  });
  const decisionV2: ExtendedDecisionV2 = decisionV2Fetch?.isQueryError
    ? undefined
    : decisionV2Fetch?.decision;
  const isPendingDecision = decision?.status_code === "202";
  const isInputSchemaError = decisionV2
    ? hasInputSchemaError(decisionV2)
    : false;

  let tabs = [
    {
      label: "Input",
      key: "input-data-tab",
      content: <DecisionInputFieldsTree decisionId={decision?.id!} />,
    },
    {
      label: (
        <div className="flex flex-row items-center gap-x-1">
          <p className="text-gray-800">Manual Review</p>
          <Icon color="text-gray-500" icon={faClock} size="xs" />
        </div>
      ),
      key: "manual-review-data-tab",
      content: decision && (
        <ManualReviewTabContent
          decisionId={decision?.id}
          flowId={decision?.flow.id ?? ""}
          flowSlug={decision?.flow.slug ?? ""}
          onClose={onClose}
        />
      ),
    },
  ];

  if (!isInputSchemaError) {
    tabs.push({
      label: (
        <span className="flex items-center gap-x-1.5">
          Output
          {isPendingDecision && (
            <Icon color="text-gray-500" icon={faClock} size="xs" />
          )}
        </span>
      ),
      key: "output-data-tab",
      content: <OutputTabContent decision={decision} decisionV2={decisionV2} />,
    });
  }

  const hasDecisionCases = decision?.cases && decision?.cases.length > 0;
  if (
    !hasDecisionCases ||
    (hasDecisionCases && decision?.cases?.[0]?.status === "completed")
  ) {
    tabs = tabs.filter((tab) => tab.key !== "manual-review-data-tab");
  }
  const decisionDefaultTab =
    hasDecisionCases &&
    (decision?.cases?.[0]?.status === "in_progress" ||
      decision?.cases?.[0]?.status === "needs_review")
      ? "manual-review-data-tab"
      : "input-data-tab";

  return (
    <DecisionsSidepane
      decision={decision}
      defaultTab={decisionDefaultTab}
      isInputSchemaError={false}
      isOpen={isOpen}
      tabs={tabs}
      onClose={onClose}
    />
  );
};
