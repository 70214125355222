import { create } from "zustand";

import { RoleInDB, TokenWithUserAndKey } from "src/clients/taktile-api";
import { analytics } from "src/instrumentation/analytics";
import { loginWithRefreshToken } from "src/store/api";

export type LocalStorageValueT = string | undefined | null;

export const useAuthStore = create<
  ZustandStore<
    {
      refreshed: boolean;
      signed_in_user_id: string | undefined;
      signed_in_username: string | undefined;
      signed_in_avatar_url: string | undefined;
      signed_in_email: string | undefined;
      id_token: LocalStorageValueT;
      refresh_token: LocalStorageValueT;
      api_key: string | undefined;
      signed_in_full_name: string | undefined;
      hashed_email: string | undefined;
      roles: RoleInDB[] | undefined;
    },
    {
      resetAll: () => void;
      setup: () => void;
      refresh: () => Promise<TokenWithUserAndKey | void>;
      setCredentials: (
        signed_in_user_id: string,
        signed_in_username: string,
        signed_in_full_name: string | undefined,
        signed_in_email: string,
        signed_in_avatar_url: string | undefined,
        id_token: string,
        refresh_token: string,
        api_key: string,
        hashed_email?: string,
        roles?: RoleInDB[] | undefined,
      ) => void;
    }
  >
>((set, get) => ({
  refreshed: false,
  signed_in_username: undefined,
  signed_in_user_id: undefined,
  signed_in_avatar_url: undefined,
  signed_in_full_name: undefined,
  api_key: undefined,
  signed_in_email: undefined,

  id_token: localStorage.getItem("id_token"),
  refresh_token: localStorage.getItem("refresh_token"),
  hashed_email: undefined,
  roles: undefined,

  actions: {
    setup: async () => {
      set({ refreshed: false });
      await get().actions.refresh();
      set({ refreshed: true });
    },

    refresh: async () => {
      if (get().refresh_token) {
        const response = await loginWithRefreshToken();
        get().actions.setCredentials(
          response.user.id,
          response.user.username ?? "",
          response.user.full_name ?? undefined,
          response.user.email ?? "",
          response.user.avatar_url ?? undefined,
          response.id_token,
          response.refresh_token,
          response.api_key.api_key,
          response.user.hashed_email,
          response.user.roles,
        );
        return response;
      }
    },

    setCredentials: (
      signed_in_user_id: string,
      signed_in_username: string,
      signed_in_full_name: string | undefined,
      signed_in_email: string,
      signed_in_avatar_url: string | undefined,
      id_token: string,
      refresh_token: string,
      api_key: string,
      hashed_email?: string,
      roles?: RoleInDB[] | undefined,
    ) => {
      set({
        id_token,
        refresh_token,
        signed_in_user_id,
        signed_in_username,
        signed_in_full_name,
        signed_in_email,
        signed_in_avatar_url,
        api_key,
        hashed_email,
        roles,
      });
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("refresh_token", refresh_token);

      analytics.identify(signed_in_user_id, {
        username: signed_in_username,
        $set_once: { username: signed_in_username },
      });
    },

    resetAll: () => {
      localStorage.removeItem("id_token");
      localStorage.removeItem("refresh_token");

      set({
        refreshed: undefined,
        signed_in_user_id: undefined,
        signed_in_username: undefined,
        signed_in_avatar_url: undefined,
        id_token: undefined,
        refresh_token: undefined,
        api_key: undefined,
        signed_in_full_name: undefined,
        hashed_email: undefined,
        roles: undefined,
      });
    },
  },
}));

export const useIsUserRefreshed = () =>
  useAuthStore((state) => state.refreshed);

export const useCurrentUserId = () =>
  useAuthStore((state) => state.signed_in_user_id);

export const useUserData = () =>
  useAuthStore((state) => ({
    signed_in_full_name: state.signed_in_full_name,
    signed_in_username: state.signed_in_username,
    signed_in_avatar_url: state.signed_in_avatar_url,
    signed_in_user_id: state.signed_in_user_id,
    signed_in_email: state.signed_in_email,

    api_key: state.api_key,
    hashed_email: state.hashed_email,

    roles: state.roles,
  }));

export const useAuthActions = () => useAuthStore((state) => state.actions);

export const useIsFWDOrAdmin = () =>
  useAuthStore(
    (state) =>
      state.roles?.some((r) =>
        ["taktile_d_admin", "fwd_d", "oncall_write"].includes(r.name),
      ) ?? false,
  );
