import {
  faKeyboard,
  faClose,
  faSpinner,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { Listbox } from "@headlessui/react";
import type { Placement } from "@popperjs/core";
import { AnimatePresence, m } from "framer-motion";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";

export type Option = {
  key: string;
  value: React.ReactNode;
  disabled?: boolean | string;
  searchText?: string;
};

type TagsInputProps = {
  options?: Option[];
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  placement?: Placement;
  listMatchesButtonWidth?: boolean;
  errored?: boolean;
  dataLoc?: string;
  dropdownClassName?: string;
  fullWidth?: boolean;
  onChange?: (value: string[]) => void;
  value: string[];
  maxItems?: number;
};

type TagsInputStatesProps = {
  maxItemsError: boolean;
  setMaxItemsError: (t: boolean) => void;
};

export const TagsInput: React.FC<TagsInputProps> = (propsUnfiltered) => {
  const onChange = (value: string[]) => {
    propsUnfiltered.onChange?.(value);

    selectRef.current?.dispatchEvent(new Event("change", { bubbles: true }));
  };

  const props = {
    ...propsUnfiltered,
    options: propsUnfiltered.options,
    onChange,
  };

  const [item, setItem] = useState("");
  const [maxItemsError, setMaxItemsError] = useState(
    props.value.length > (props.maxItems ?? props.value.length),
  );
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    update: forcePopperUpdate,
  } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    placement: props.placement ?? "bottom-start",
  });

  const selectRef = React.useRef<HTMLSelectElement | null>(null);

  return (
    <>
      {/* Hidden native select element as workaround for
       * headless ui not using it and therefore not triggering
       * native events such as onChange in a form
       * https://github.com/tailwindlabs/headlessui/issues/2003
       */}
      <select ref={selectRef} style={{ display: "none" }} />
      <Listbox
        disabled={props.disabled}
        multiple={true}
        value={props.value}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <div
              ref={setReferenceElement}
              className={twJoin("flex", props.fullWidth && "w-full")}
            >
              <Listbox.Button
                className="w-full outline-none"
                data-loc={props.dataLoc}
              >
                <SelectButton
                  {...propsUnfiltered}
                  forcePopperUpdate={forcePopperUpdate}
                  maxItems={props.maxItems}
                  maxItemsError={maxItemsError}
                  open={open}
                  setMaxItemsError={setMaxItemsError}
                  onChange={onChange}
                />
              </Listbox.Button>
            </div>
            {ReactDOM.createPortal(
              <AnimatePresence
                initial={false}
                onExitComplete={() => {
                  setItem("");
                  setMaxItemsError(false);
                }}
              >
                {open && (
                  <div
                    ref={setPopperElement}
                    className={twJoin(
                      "z-50",
                      propsUnfiltered.dropdownClassName,
                    )}
                    style={
                      (props.listMatchesButtonWidth ?? true)
                        ? {
                            ...popperStyles.popper,
                            width: referenceElement?.scrollWidth,
                          }
                        : popperStyles.popper
                    }
                    {...popperAttributes.popper}
                    data-loc={`${props.dataLoc}-dropdown`}
                  >
                    <m.div
                      animate="visible"
                      className="rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                      exit="hidden"
                      initial="hidden"
                      transition={{
                        type: "tween",
                        ease: "easeOut",
                        duration: 0.15,
                      }}
                      variants={{
                        visible: {
                          opacity: 1,
                          scale: 1,
                        },
                        hidden: {
                          opacity: 0,
                          scale: 0.95,
                        },
                      }}
                    >
                      <DefineItems
                        {...props}
                        item={item}
                        maxItemsError={maxItemsError}
                        setItem={setItem}
                        setMaxItemsError={setMaxItemsError}
                      />
                    </m.div>
                  </div>
                )}
              </AnimatePresence>,
              document.body,
            )}
          </>
        )}
      </Listbox>
    </>
  );
};

const SelectButton: React.FC<
  TagsInputProps &
    TagsInputStatesProps & {
      open: boolean;
      forcePopperUpdate: (() => void) | null;
    }
> = (props) => {
  const { open, forcePopperUpdate, value } = props;
  useEffect(() => {
    if (open) forcePopperUpdate?.();
  }, [open, forcePopperUpdate, value]);

  const className = twMerge(
    "flex min-h-[32px] justify-between rounded-lg border border-gray-200 py-1 pl-3 pr-2 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
    props.options?.length === 0 && "cursor-default",
    props.errored && "border-red-600 ring-2 ring-red-400/25",
    props.open && "border-indigo-400 ring-2 ring-indigo-500/25",
    props.disabled ? "cursor-not-allowed bg-gray-50" : "bg-white",
  );

  const isValueSelected = props.value.length > 0;

  const placeholderElement = (
    <span className="text-gray-500 font-inter-normal-12px">
      {props.placeholder}
    </span>
  );

  const handleRemove = (removedOption: string) => {
    props.onChange?.(props.value.filter((v) => v !== removedOption));
    if (props.maxItems && props.maxItemsError) {
      props.setMaxItemsError(false);
    }
  };

  const selectedValuesElement = (
    <div className="flex min-w-0 flex-1 flex-wrap gap-1">
      {props.value.map((option, index) =>
        option ? (
          <Pill key={option} size="sm" variant="gray">
            <Pill.Text>{option}</Pill.Text>
            {!props.disabled && (
              <div
                className="flex items-center opacity-60 hover:opacity-100"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(option);
                }}
              >
                <Pill.Icon icon={faClose} />
              </div>
            )}
          </Pill>
        ) : (
          <SkeletonPlaceholder
            key={`skeleton-${index}`}
            height="h-5.5"
            width="w-24"
          />
        ),
      )}
    </div>
  );

  return (
    <div className={twJoin("flex items-center gap-2.5", className)}>
      {isValueSelected ? selectedValuesElement : placeholderElement}

      <div className="flex">
        <Icon
          color="text-gray-500"
          cursorType="pointer"
          icon={props.loading ? faSpinner : faKeyboard}
          size="xs"
          spin={props.loading}
        />
      </div>
    </div>
  );
};

const DefineItems: React.FC<
  TagsInputProps &
    TagsInputStatesProps & { item: string; setItem: (t: string) => void }
> = (props) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      props.item &&
      !props.value?.includes(props.item)
    ) {
      if (props.maxItems && props.value.length === props.maxItems) {
        props.setMaxItemsError(true);
      } else {
        props.onChange?.([...(props.value || []), props.item]);
      }
      props.setItem("");
    }
  };

  return (
    <div className="px-4 py-2">
      {props.maxItemsError && (
        <ErrorHint>Maximum {props.maxItems} items allowed</ErrorHint>
      )}
      <Input
        ref={(node) => node?.focus()} // Focus on first render
        data-loc="new-input"
        errored={props.maxItemsError}
        placeholder="Define new item and press Enter"
        suffixIcon={props.maxItemsError ? { icon: faWarning } : undefined}
        value={props.item}
        fullWidth
        onChange={(e) => props.setItem(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
