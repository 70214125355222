import { observer } from "mobx-react-lite";
import React from "react";
import { ReactFlowProvider } from "reactflow";

import { FlowVersionT } from "src/api/flowTypes";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { AuthoringUIProvider } from "src/flowContainer/AuthoringUIContext";
import { VersionPicker } from "src/flowContainer/FlowAndVersionName";
import { FlowHeaderButtons } from "src/flowContainer/FlowHeaderButtons";
import { RevisionHeader } from "src/flowContainer/RevisionHeader/RevisionHeader";
import { RevisionHeaderButtons } from "src/flowContainer/RevisionHeader/RevisionHeaderButtons";
import { RightPane } from "src/flowContainer/RightPane";
import { LastUpdatedInfo } from "src/flowContainer/versionEditedInfo/LastUpdateInfo";
import { DecisionGraph } from "src/flowGraph/DecisionGraph";
import { useCapabilities } from "src/hooks/useCapabilities";
import { AuthoringLayout } from "src/layout/AuthoringLayout";
import { LayoutHeader } from "src/layout/Header";
import { OmniboxAuthoring } from "src/omnibox/OmniboxAuthoring";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { getFlowVersionsUrl } from "src/router/urls";

const GraphAndRightSidePane: React.FC<{
  version: FlowVersionT;
}> = ({ version }) => {
  if (!(version.graph_id && version.graph)) {
    return <></>;
  }
  return (
    <>
      <DecisionGraph />
      <RightPane />
    </>
  );
};

const SideContent: React.FC = () => {
  const { version, isRevision } = useAuthoringContext();
  const { flowVersions } = useCapabilities();

  return (
    <div className="flex items-center gap-x-2">
      {isRevision ? (
        <RevisionHeaderButtons />
      ) : (
        <div className="flex flex-row items-center gap-x-2">
          {flowVersions.canEdit && <LastUpdatedInfo flowVersion={version} />}
          <FlowHeaderButtons />
        </div>
      )}
    </div>
  );
};

export const VersionAuthoringWrapper: React.FC = observer(() => {
  const { flow, version, workspace, orgId, isRevision } = useAuthoringContext();

  const header = (() => {
    return (
      <LayoutHeader
        breadcrumbs={[
          {
            key: "flow-versions",
            breadcrumb: (
              <LayoutHeader.Breadcrumb
                dataLoc="back-to-versions"
                href={getFlowVersionsUrl(orgId, workspace.id, flow.id)}
                label={flow.name}
              />
            ),
          },
          {
            key: "flow-version",
            breadcrumb: (
              <LayoutHeader.Breadcrumb>
                {isRevision ? (
                  <RevisionHeader changedBy={version.meta.updated_by_id} />
                ) : (
                  <VersionPicker flow={flow} />
                )}
              </LayoutHeader.Breadcrumb>
            ),
          },
        ]}
        border
      >
        <SideContent />
      </LayoutHeader>
    );
  })();

  return (
    <ReactFlowProvider>
      <OmniboxAuthoring />
      <AuthoringUIProvider>
        <FloatingWindowsProvider>
          <AuthoringLayout Header={header}>
            <div className="flex h-full w-full flex-row">
              <GraphAndRightSidePane version={version} />
            </div>
          </AuthoringLayout>
        </FloatingWindowsProvider>
      </AuthoringUIProvider>
    </ReactFlowProvider>
  );
});
