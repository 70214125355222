import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { RefObject } from "react";
import { v4 as uuidv4 } from "uuid";

import { FlowVersionT } from "src/api/flowTypes";
import { Dataset } from "src/api/types";
import { Button } from "src/base-components/Button";
import { ColumnMenu } from "src/datasets/DatasetTable/ColumnMenu";
import { DatasetEditTableRef } from "src/datasets/DatasetTable/DatasetEditTable";
import {
  useAvailableColumns,
  useAvailableIntegrationNodes,
  useColumnAddHandler,
} from "src/datasets/DatasetTable/hooks";
import {
  DatasetContext,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import { usePostRows } from "src/datasets/api/queries";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const useAppendRow = (
  flowId: string,
  dataset: Dataset,
  baseUrl?: string,
  editTableRef?: RefObject<DatasetEditTableRef>,
) => {
  const postRow = usePostRows(dataset.id, flowId, baseUrl);

  const appendRow = async () => {
    try {
      await postRow.mutateAsync({
        source: "blank",
        new_row_ids: [uuidv4()],
      });
      editTableRef?.current?.scrollToBottom();
    } catch (e) {
      toastActions.failure({
        title: `Failed to add row`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  return { appendRow, isLoading: postRow.isPending };
};

type DatasetControlButtonsProps = {
  dataset: Dataset;
  schemas: VersionSchemas;
  editTableRef: React.RefObject<DatasetEditTableRef>;
  version?: FlowVersionT;
  context: DatasetContext;
};

export const DatasetControlButtons: React.FC<DatasetControlButtonsProps> = ({
  dataset,
  schemas,
  editTableRef,
  context,
  version,
}) => {
  const { workspace, flow } = useFlowContext();

  const integrationNodes = useAvailableIntegrationNodes(version);
  const { appendRow, isLoading: appendRowIsLoading } = useAppendRow(
    flow.id,
    dataset,
    workspace?.base_url,
    editTableRef,
  );

  const {
    columnAddHandler,
    subMocksColumnAddHandler,
    isLoading: columnAddIsLoading,
  } = useColumnAddHandler({
    dataset,
    flowId: flow.id,
    baseUrl: workspace?.base_url,
    schemas,
  });

  const {
    availableInputColumns,
    availableOutputColumns,
    availableMockColumns,
    availableOutcomeColumns,
  } = useAvailableColumns(dataset, schemas, integrationNodes);

  return (
    <>
      <Button
        dataLoc="dataset-add-test-case"
        disabled={appendRowIsLoading}
        iconLeft={faPlus}
        variant="secondary"
        onClick={appendRow}
      >
        {context === "authoring" ? "Add test case" : "Add row"}
      </Button>
      <ColumnMenu
        button={
          <Button
            dataLoc="dataset-add-field"
            disabled={columnAddIsLoading}
            htmlType="button"
            iconLeft={faPlus}
            variant="secondary"
          >
            Add dataset field
          </Button>
        }
        buttonAs="div"
        context={context}
        input={availableInputColumns}
        mock={availableMockColumns}
        outcome={availableOutcomeColumns}
        output={availableOutputColumns}
        placement="bottom-end"
        visible
        onAdd={columnAddHandler}
        onAddAdditionalColumn={() =>
          editTableRef.current?.openAddAdditionalColumnModal()
        }
        onAddSubMocks={subMocksColumnAddHandler}
      />
    </>
  );
};
