import { FieldError, UseFormRegisterReturn } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { getSlugErrorMessage } from "src/flow/modals/utils";

export const SlugInput: React.FC<{
  error: FieldError | undefined;
  formProps: UseFormRegisterReturn;
  dataLoc?: string;
}> = ({ error, formProps, dataLoc }) => {
  return (
    <>
      <p className="mb-1 mt-3 text-gray-800 font-inter-semibold-13px">
        URL Identifier <RequiredAsterisk />
      </p>
      <div className="flex-shrink-0">
        <Input
          data-loc={dataLoc !== undefined ? dataLoc : "add-flow-slug"}
          errored={error !== undefined}
          placeholder="Enter URL identifier (this cannot be edited)"
          type="text"
          fullWidth
          {...formProps}
        />
      </div>
      {error && (
        <ErrorHint className="mb-2 mr-2" height="h-2">
          {getSlugErrorMessage(error.type)}
        </ErrorHint>
      )}
    </>
  );
};
