import { FlipProp, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, Fragment, HTMLProps, memo } from "react";
import { twJoin } from "tailwind-merge";

import { HeightSize, WidthSize } from "src/design-system/types";
import { assertUnreachable } from "src/utils/typeUtils";

// Link to the Figma file with the variants and sizes: https://www.figma.com/file/M6oWoeOynJBBK0xppg19h9/Decide-Redesign%3A-Deliverables?node-id=1473-6255&t=2LixZSZKq1NoiHy4-11

export type IconSizes =
  | "4xs" // 6px
  | "3xs" // 8px
  | "2xs" // 10px
  | "xs" // 12px
  | "sm" // 14px
  | "md" // 16px
  | "base" // 16px
  | "lg" // 18px
  | "xl" // 20px
  | "2xl"; // 24px
type CursorType = "pointer" | "text" | "default";

type BaseProps = {
  icon: IconProp;
  size?: IconSizes;
  fixedWidth?: boolean;
  color?: string;
  spin?: boolean;
  dataLoc?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDownCapture?: React.MouseEventHandler<HTMLButtonElement>;
  padding?: boolean;
  animate?: boolean;
  disabled?: boolean;
  flip?: FlipProp;
};

type ButtonIconProps = BaseProps & { cursorType?: never } & Pick<
    HTMLProps<HTMLButtonElement>,
    "onClick" | "disabled"
  >;

type DivIconProps = {
  onClick?: never;
  cursorType?: CursorType;
} & BaseProps;

export type IconProps = ButtonIconProps | DivIconProps;

export const Icon = memo(
  forwardRef<HTMLSpanElement, IconProps>(
    (
      {
        icon,
        size = "base",
        cursorType,
        color,
        fixedWidth = false,
        spin,
        dataLoc,
        onClick,
        onMouseDownCapture,
        padding = true,
        animate = true,
        flip,
        // spreading the remaining DOM props allows the use of asChild for library components like the tooltip:
        // https://github.com/radix-ui/primitives/issues/953
        ...restOfDomProps
      },
      ref,
    ) => {
      const Wrapper =
        onClick !== undefined || onMouseDownCapture !== undefined
          ? "button"
          : Fragment;

      const { width, height } = getIconSize(size);
      return (
        // The conditional passing of button props is required because they are not allowed to be set at all on a Fragment, not even to undefined.
        <Wrapper
          {...(Wrapper === "button" && {
            onClick,
            onMouseDownCapture,
            type: "button",
            tabIndex: 0,
            ...restOfDomProps,
          })}
        >
          <span
            ref={ref}
            className={twJoin(
              "flex justify-center",
              cursorType === "pointer" && "cursor-pointer",
              cursorType === "text" && "cursor-text",
              cursorType === "default" && "cursor-default",
              "disabled" in restOfDomProps &&
                restOfDomProps["disabled"] === true &&
                "cursor-not-allowed",
              padding && "p-1",
            )}
            {...(Wrapper === Fragment && { ...restOfDomProps })}
          >
            <FontAwesomeIcon
              className={twJoin(
                "aspect-square",
                animate && "transition-all",
                width,
                height,
                color,
              )}
              data-loc={dataLoc}
              fixedWidth={fixedWidth}
              flip={flip}
              icon={icon}
              spin={spin}
            />
          </span>
        </Wrapper>
      );
    },
  ),
);

export const getIconSize = (
  size: IconSizes,
): { width: WidthSize; height: HeightSize } => {
  switch (size) {
    case "4xs":
      return {
        width: "w-1.5",
        height: "h-1.5",
      };
    case "3xs":
      return {
        width: "w-2",
        height: "h-2",
      };
    case "2xs":
      return {
        width: "w-2.5",
        height: "h-2.5",
      };
    case "xs":
      return {
        width: "w-3",
        height: "h-3",
      };
    case "sm":
      return {
        width: "w-3.5",
        height: "h-3.5",
      };
    case "base":
    case "md":
      return {
        width: "w-4",
        height: "h-4",
      };
    case "lg":
      return {
        width: "w-4.5",
        height: "h-4.5",
      };
    case "xl":
      return {
        width: "w-5",
        height: "h-5",
      };
    case "2xl":
      return {
        width: "w-6",
        height: "h-6",
      };
    default:
      return assertUnreachable(size);
  }
};
