import { v4 } from "uuid";

import { getDefaultJsonSchemaAiNodeBE } from "src/aiNode/defaultValues";
import {
  AiNodeV2Form,
  BasicModelCard,
  AiNodeJsonSchemaUIT,
  AiNodeJsonSchemaBET,
  AiNodeJsonSchemaPropertyUIT,
  AiNodeV2MessageContentBlock,
  AiNodeV2MetaFixed,
} from "src/aiNode/types";
import { ResourceSample } from "src/api/connectApi/types";
import { GenericObjectT, SchemaTypesT } from "src/api/flowTypes";
import { AiNodeV2ConversationMessageFilesListContentBlockBlockTypeEnum } from "src/clients/flow-api";
import { AiNodeV2 } from "src/constants/NodeDataTypes";
import { SchemaOptions } from "src/router/SearchParams";
import { SchemaConverter } from "src/schema/schemaMappingUtils";

// The "text" output and insights which we don't want to cleanup
// when processing JSON schemas
const PROTECTED_RESPONSE_NAMES = ["default", "provider_response_time"];

export const getDefaultAiNodeModelId = (
  availableModels: BasicModelCard[],
): string => availableModels[0].model_id;

export const getAiNodeNewProperty = (): AiNodeJsonSchemaPropertyUIT => {
  return {
    fieldName: "",
    type: ["string", "null"],
    required: true,
    sensitive: false,
    outputId: v4(),
    description: "",
  };
};

const getDefaultFilesListContentBlock = () => ({
  block_type:
    AiNodeV2ConversationMessageFilesListContentBlockBlockTypeEnum.FILES_LIST,
  files: {
    id: v4(),
    active: false,
    expression: "",
  },
});

export const convertAiNodeJsonSchemaBETtoUIT = (
  schemaBE: AiNodeJsonSchemaBET,
): AiNodeJsonSchemaUIT => {
  const schemaUI = SchemaConverter.beToUI(schemaBE, SchemaOptions.Output);

  return {
    ...schemaUI,
    properties: schemaUI.properties.map((property) => ({
      ...property,
      description: schemaBE.properties[property.fieldName].description ?? "",
      // Backfilling the outputId in case it's missing so we can regenerate
      // the response fields down the line
      outputId: schemaBE.properties[property.fieldName].outputId ?? v4(),
    })),
  };
};

export const convertAiNodeJsonSchemaUITtoBET = (
  schemaUI: AiNodeJsonSchemaUIT,
): AiNodeJsonSchemaBET => {
  const schemaBE = SchemaConverter.uiToBE(schemaUI, SchemaOptions.Output);
  const propertiesMappingUI = Object.fromEntries(
    schemaUI.properties.map((property) => [
      property.fieldName,
      {
        outputId: property.outputId,
        description: property.description,
      },
    ]),
  );
  return {
    ...schemaBE,
    properties: Object.fromEntries(
      Object.entries(schemaBE.properties).map(([fieldName, property]) => [
        fieldName,
        {
          ...property,
          outputId: propertiesMappingUI[fieldName].outputId,
          description: propertiesMappingUI[fieldName]?.description,
        },
      ]),
    ),
  };
};

export const convertAiNodeV2FormToBE = (
  form: AiNodeV2Form,
): AiNodeV2MetaFixed => {
  const uiSchema = form.inference.response_format.json_schema;
  if (!uiSchema) {
    return {
      ...form,
      inference: {
        ...form.inference,
        response_format: {
          ...form.inference.response_format,
          json_schema: undefined, // TODO: return default?
        },
      },
    };
  }

  // JSONSchema is a source of truth for the response fields, therefore
  // we re-create the response object based on the schemas

  const backendSchema = convertAiNodeJsonSchemaUITtoBET(uiSchema);
  // Keep protected response field names (text output and insights)
  const response = Object.fromEntries(
    Object.entries(form.response).filter(([key]) =>
      PROTECTED_RESPONSE_NAMES.includes(key),
    ),
  );

  // Add output fields from schema
  Object.entries(backendSchema.properties).forEach(([fieldName, property]) => {
    response[fieldName] = {
      // We are backfilling the `outputId` if it's missing when parsing BE
      // data, so `outputId` will always be set at this point
      id: property.outputId!,
      active: true,
      mapped_to: fieldName,
    };
  });

  return {
    ...form,
    response,
    inference: {
      ...form.inference,
      response_format: {
        ...form.inference.response_format,
        json_schema: backendSchema,
      },
    },
  };
};

/**
 * Backfills the files_list content block in the messages if it's missing.
 * This is needed to make sure we don't need to handle the missing files_list
 * case down the line.
 */
export const backfillFilesListInMessages = (
  messages: AiNodeV2MetaFixed["prompts"]["messages"],
) => {
  return messages.map((message) => {
    const content = message.content as AiNodeV2MessageContentBlock[];

    const hasFilesList = content.some(
      (c) =>
        c.block_type ===
        AiNodeV2ConversationMessageFilesListContentBlockBlockTypeEnum.FILES_LIST,
    );

    if (!hasFilesList) {
      // Add a files_list content block to the list of content blocks
      // if it's missing
      content.push(getDefaultFilesListContentBlock());
    }

    return {
      ...message,
      content,
    };
  });
};

export const convertAiNodeV2BEToForm = (
  data: AiNodeV2MetaFixed,
): AiNodeV2Form => {
  const backendSchema = (data.inference.response_format.json_schema ??
    getDefaultJsonSchemaAiNodeBE()) as AiNodeJsonSchemaBET;

  const uiSchema = convertAiNodeJsonSchemaBETtoUIT(backendSchema);

  return {
    model: data.model,
    prompts: {
      ...data.prompts,
      messages: backfillFilesListInMessages(data.prompts.messages),
    },
    response: data.response,
    inference: {
      ...data.inference,
      response_format: {
        ...data.inference.response_format,
        json_schema: uiSchema,
      },
    },
    config: data.config,
  };
};

export const getSampleReportsForAiNode = (node: AiNodeV2): ResourceSample[] => {
  if (node.data.inference.response_format.type === "text") {
    return [{ name: "AI Node response", sample: { text: "example" } }];
  }

  const schema = node.data.inference.response_format
    .json_schema as AiNodeJsonSchemaBET;
  if (!schema) {
    return [{ name: "AI Node response", sample: {} }];
  }

  const uiSchema = convertAiNodeJsonSchemaBETtoUIT(schema);
  const properties = uiSchema.properties ?? [];
  const sampleReport: GenericObjectT = {};

  if (properties) {
    const defaults: Record<SchemaTypesT, any> = {
      boolean: true,
      integer: 1,
      number: 1.5,
      string: "example",
      datetime: new Date(),
      date: new Date(),
      "date-str": "",
      "datetime-str": "",
      any: "",
      enum: [],
      array: [],
      object: {},
    };

    for (const field of properties) {
      if (field.type[0] !== "enum") {
        sampleReport[field.fieldName] = defaults[field.type[0]];
      } else if (field.enum && typeof field.enum[0]?.value === "string") {
        try {
          sampleReport[field.fieldName] = JSON.parse(field.enum[0].value);
        } catch {
          sampleReport[field.fieldName] = "";
        }
      } else {
        sampleReport[field.fieldName] = defaults["string"];
      }
    }
  }

  return [
    {
      name: "AI Node response",
      sample: { json: sampleReport },
    },
  ];
};
