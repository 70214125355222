import { Icon } from "src/base-components/Icon";
import {
  EnumValue,
  EventConfigEnriched,
  PropertyDefinitionOutputTypeEnum,
  PropertyDefinitionOutput,
} from "src/clients/features-control/api";
import { Cardinality } from "src/entities/queries";
import { RelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { TYPE_ICONS } from "src/utils/constants";

type FormEnumValue = EnumValue & {
  _isNew: boolean;
};

type FormProperty = Omit<PropertyDefinitionOutput, "enum_schema"> & {
  enum_schema:
    | {
        values: Array<FormEnumValue>;
      }
    | null
    | undefined;
};

type SchemaProperties = EventConfigEnriched["properties"];

export type FormProperties = { [key: string]: FormProperty };

export const SchemaPropertyIcon: React.FC<{
  property: PropertyDefinitionOutputTypeEnum;
  cardinality?: Cardinality;
}> = ({ property, cardinality = Cardinality.ONE }) => {
  if (property === PropertyDefinitionOutputTypeEnum.RELATION) {
    return <RelationIcon cardinality={cardinality} size="xs" />;
  }

  return <Icon icon={PROPERTY_TYPES_TO_ICONS[property]} size="xs" />;
};

export const PROPERTY_TYPES_TO_ICONS = {
  string: TYPE_ICONS.string,
  boolean: TYPE_ICONS.boolean,
  number: TYPE_ICONS.number,
  integer: TYPE_ICONS.integer,
  object: TYPE_ICONS.object,
  list: TYPE_ICONS.tags,
  datetime: TYPE_ICONS.datetime,
  date: TYPE_ICONS.date,
  enum: TYPE_ICONS.enum,
  tags: TYPE_ICONS.tag,
  address: TYPE_ICONS.address,
  ip_address: TYPE_ICONS.ipAddress,
  lat_long: TYPE_ICONS.latLong,
  url: TYPE_ICONS.url,
  file: TYPE_ICONS.object,
  array: TYPE_ICONS.array,
} as const;

export const schemaPropertiesToFormProperties = (
  schemaProperties: SchemaProperties,
): FormProperties => {
  return Object.entries(schemaProperties).reduce((acc, [property, config]) => {
    if (
      (config.type === "enum" || config.type === "tags") &&
      config.enum_schema
    ) {
      acc[property] = {
        ...config,
        enum_schema: {
          ...config.enum_schema,
          values: config.enum_schema.values.map((value) => ({
            ...value,
            _isNew: false,
          })),
        },
      };
    } else {
      acc[property] = config as FormProperty;
    }
    return acc;
  }, {} as FormProperties);
};

export const formPropertiesToSchemaProperties = (
  formProperties: FormProperties,
): SchemaProperties => {
  return Object.entries(formProperties).reduce((acc, [property, config]) => {
    if (
      (config.type === "enum" || config.type === "tags") &&
      config.enum_schema
    ) {
      acc[property] = {
        ...config,
        enum_schema: {
          ...config.enum_schema,
          values: config.enum_schema.values.map(
            ({ _isNew, ...value }) => value,
          ),
        },
      };
    } else {
      acc[property] = config;
    }

    return acc;
  }, {} as SchemaProperties);
};
