import { faRefresh } from "@fortawesome/pro-regular-svg-icons";

import { DecisionsTabContent } from "src/entities/entityView/DecisionsTabContent";
import { EventsTabContent } from "src/entities/entityView/EventsTabContent";
import { Features } from "src/entities/entityView/Features";
import { RelatedEntities } from "src/entities/entityView/RelatedEntities";
import {
  EntityTabIds,
  useEntityTabRefresh,
} from "src/entities/entityView/useEntityTabRefresh";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import {
  useWorkspaceFeatureGates,
  WorkspaceFeatureGates,
} from "src/hooks/useWorkspaceFeatureGates";
import {
  SubHeader,
  SubHeaderButton,
  SubHeaderTab,
  SubHeaderTabs,
} from "src/layout/SubHeader";
import { EntityViewParams, getUrlToEntityDetailPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type Props = {
  selectedDecision: ExtendedHistoricalDecisionV3 | null;
  onSelectDecision: (decision: ExtendedHistoricalDecisionV3 | null) => void;
  tab: EntityTabIds;
};

const tabLabels = {
  [EntityTabIds.Decisions]: "Decisions",
  [EntityTabIds.Features]: "Features",
  [EntityTabIds.Events]: "Events",
  [EntityTabIds.RelatedEntities]: "Related Entities",
};

type TabDefinition = {
  id: EntityTabIds;
  label: string;
  requiredGate: keyof WorkspaceFeatureGates | null;
};

export const EntityTabs: React.FC<Props> = ({
  selectedDecision,
  onSelectDecision,
  tab,
}) => {
  const { refresh, isRefreshing } = useEntityTabRefresh(tab);
  const {
    orgId,
    wsId,
    schema,
    id: entityId,
  } = useParamsDecode<EntityViewParams>();
  const featureGates = useWorkspaceFeatureGates();

  const tabDefinitions: TabDefinition[] = [
    {
      id: EntityTabIds.Decisions,
      label: tabLabels[EntityTabIds.Decisions],
      requiredGate: null,
    },
    {
      id: EntityTabIds.Events,
      label: tabLabels[EntityTabIds.Events],
      requiredGate: "featuresEventsEnabled",
    },
    {
      id: EntityTabIds.Features,
      label: tabLabels[EntityTabIds.Features],
      requiredGate: "featuresEventsEnabled",
    },
    {
      id: EntityTabIds.RelatedEntities,
      label: tabLabels[EntityTabIds.RelatedEntities],
      requiredGate: null,
    },
  ];

  const content = {
    [EntityTabIds.Decisions]: (
      <DecisionsTabContent
        selectedDecision={selectedDecision}
        onSelectDecision={onSelectDecision}
      />
    ),
    [EntityTabIds.Features]: <Features />,
    [EntityTabIds.Events]: <EventsTabContent />,
    [EntityTabIds.RelatedEntities]: <RelatedEntities />,
  };

  return (
    <div className="w-full overflow-x-hidden">
      <SubHeader
        leftSlot={
          <SubHeaderTabs dataLoc="entity-tabs">
            {tabDefinitions.map(({ id, label, requiredGate }) => {
              if (requiredGate && !featureGates[requiredGate]) {
                return null;
              }

              return (
                <SubHeaderTab
                  key={id}
                  to={getUrlToEntityDetailPage(
                    orgId,
                    wsId,
                    schema,
                    entityId,
                    id,
                  )}
                >
                  {label}
                </SubHeaderTab>
              );
            })}
          </SubHeaderTabs>
        }
      >
        <SubHeaderButton
          dataLoc="entity-tabs-refresh"
          icon={faRefresh}
          spin={isRefreshing}
          tooltip="Refresh"
          onClick={refresh}
        />
      </SubHeader>
      {content[tab]}
    </div>
  );
};
