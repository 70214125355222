import { Column } from "@tanstack/react-table";

import { DatasetRow } from "src/api/types";
import { PonEditor } from "src/base-components/PONEditor";
import { Pill } from "src/base-components/Pill";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { FloatingWindow } from "src/datasets/DatasetTable/FloatingWindow";
import { getIcon } from "src/datasets/DatasetTable/cells";
import { useDatasetEditTableActions } from "src/datasets/DatasetTable/stores";
import {
  CellId,
  getNonProviderIntegrationNodeIcon,
  ponToJson,
  SUB_COLUMN_SEPARATOR,
} from "src/datasets/DatasetTable/utils";
import {
  validateAny,
  ValidationResult,
} from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import { JSONPill } from "src/datasets/components/JSONPill";
import { getErrorDetails } from "src/datasets/components/errorUtils";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { copyTextToClipboard } from "src/utils/clipboard";
import { logger } from "src/utils/logger";

export const JSONEditorCell: React.FC<{
  value: string;
  onChange: (value: string) => void;
  isOpen: boolean;
  disabled: boolean;
  integrationNode?: DatasetIntegrationNode;
  type: string;
  cellId: CellId;
  warning: boolean;
  invalid: boolean;
  column: Column<DatasetRow>;
  rowIndex: number;
  error?: ValidationResult | undefined;
  postfix?: React.ReactNode;
}> = ({
  value,
  onChange,
  isOpen,
  disabled,
  integrationNode,
  type,
  cellId,
  warning,
  invalid,
  column,
  rowIndex,
  error,
  postfix,
}) => {
  const { reset } = useDatasetEditTableActions();

  const children = (
    <CellWrapper
      cellId={cellId}
      disabled={disabled}
      error={getErrorDetails(error, value, onChange)}
      invalid={invalid}
      postfix={postfix}
      warning={warning}
    >
      <JSONPill
        // TODO: Use proper types for "type"
        type={type}
        value={value}
      />
    </CellWrapper>
  );

  const onCopy = async () => {
    try {
      const json = ponToJson(value);
      const isInvalid = validateAny(json);

      if (!isInvalid) {
        await copyTextToClipboard(json);
        toastActions.success({
          title: "Copied JSON to clipboard",
        });
      } else {
        toastActions.failure({
          title: "Failed to copy JSON to clipboard",
          description: "Value is not valid JSON",
        });
      }
    } catch (e) {
      logger.error(e);
      toastActions.failure({
        title: "Failed to copy JSON to clipboard",
      });
    }
  };

  if (disabled) {
    return children;
  }

  return (
    <FloatingWindow
      button={children}
      isOpen={isOpen}
      title={
        <>
          <div className="flex items-start gap-x-1.5">
            <Pill size="sm" variant="gray">
              <Pill.Text>#{rowIndex + 1}</Pill.Text>
            </Pill>
            <Pill size="sm" variant="gray">
              {integrationNode ? (
                <Pill.Icon
                  icon={getNonProviderIntegrationNodeIcon(
                    integrationNode.provider,
                  )}
                />
              ) : (
                <Pill.Icon
                  icon={getIcon(
                    column.columnDef.meta?.archetype?.desiredType ?? "any",
                  )}
                />
              )}
              <Pill.Text>
                {renderColumnName(column.columnDef.meta?.archetype?.name ?? "")}
              </Pill.Text>
            </Pill>
          </div>
        </>
      }
      onClose={() => reset("editingCellId")}
      onCopy={onCopy}
    >
      <div className="flex h-full flex-1 flex-col gap-y-1.5">
        {integrationNode?.provider === NODE_TYPE.LOOP_NODE && (
          <p className="text-gray-500 font-inter-normal-12px">
            For each iteration in the loop, mock response below will be used.
          </p>
        )}
        <PonEditor value={value} onChange={onChange} />
      </div>
    </FloatingWindow>
  );
};

const renderColumnName = (name: string) => {
  if (name.includes(SUB_COLUMN_SEPARATOR)) {
    return name.split(SUB_COLUMN_SEPARATOR)[1];
  }

  return name;
};
