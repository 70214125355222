import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { GenericObjectT } from "src/api/flowTypes";
import { FloatingWindowPlacement } from "src/dataTable/JSONCell";
import { ObjectDetailPane } from "src/dataTable/ObjectDetailPane";
import { HoverSafeArea } from "src/utils/HoverSafeArea";

type Props = {
  title?: string;
  position: FloatingWindowPlacement;
  positionStyles?: {
    bottom: number;
    right?: number;
    left?: number;
  };
  objectToDisplay: GenericObjectT;
  withSafeArea?: boolean;
};

// Need to be kept in sync
const smallFixedInnerWidth = 240 as const;
const smallDimensionClassName = "min-w-[240px]" as const;

export const ObjectDetailPopover: React.FC<Props> = ({
  positionStyles,
  title,
  position,
  objectToDisplay,
  withSafeArea = true,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const popupDiv = React.useRef<HTMLDivElement>(null);
  const [childBoundingDiv, setChildBoundingDiv] =
    useState<HTMLDivElement | null>(null);
  const [paneWidth, setPaneWidth] = useState<"small" | "wide">();

  useEffect(() => {
    if (childBoundingDiv) {
      const observer = new ResizeObserver(() => {
        const { width } = childBoundingDiv.getBoundingClientRect();
        if (width > smallFixedInnerWidth) {
          setPaneWidth("wide");
        } else {
          setPaneWidth("small");
        }
      });

      observer.observe(childBoundingDiv);
      return () => {
        observer.unobserve(childBoundingDiv);
        observer.disconnect();
      };
    }
  }, [childBoundingDiv]);

  return (
    <>
      <div
        ref={popupDiv}
        className={twJoin(
          "absolute z-50 rounded-2xl bg-white shadow-lg",
          isFirstRender && "invisible",
        )}
        style={positionStyles}
      >
        {title && (
          <div className="border-b border-gray-200 px-4 py-3 text-gray-800 font-inter-normal-13px">
            {title}
          </div>
        )}
        <div className="overflow-hidden p-4">
          <ObjectDetailPane
            containerRef={setChildBoundingDiv}
            innerDimensionClass={twJoin(
              "max-h-[320px]",
              paneWidth === "wide" && "w-[440px]",
              paneWidth === "small" && smallDimensionClassName,
            )}
            jsonObject={objectToDisplay}
          />
        </div>
      </div>
      {popupDiv.current && withSafeArea && (
        <HoverSafeArea
          popover={popupDiv.current}
          popoverOrientation={position === "top-start" ? "toLeft" : "toRight"}
        />
      )}
    </>
  );
};
