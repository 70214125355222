import { Root } from "@radix-ui/react-accordion";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useConnection } from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { useWorkspace } from "src/api/queries";
import { ErrorBaseInfo, FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import {
  CustomConnectionNode,
  CustomConnectionNodeDataT,
} from "src/constants/NodeDataTypes";
import { BodyEditor } from "src/customConnectionNode/editorComponents/BodyEditor";
import { ConfigPane } from "src/customConnectionNode/editorComponents/ConfigPane";
import { KeyValueArrayEditor } from "src/customConnectionNode/editorComponents/KeyValueArrayEditor";
import { ResponseMapping } from "src/customConnectionNode/editorComponents/ResponseMapping";
import { VerbPathSelector } from "src/customConnectionNode/editorComponents/VerbPathSelector";
import { CustomConnectionForm } from "src/customConnectionNode/types";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

const itemClassName = "pb-6";

type WrapperPropsT = {
  node: CustomConnectionNode;
  workspaceId: string;
} & NodeEditorBaseProps<CustomConnectionNodeDataT>;

export const CustomConnectionNodeEditor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const connection = useConnection({
    baseUrl: workspace.data?.base_url,
    id: node.data.connectionId,
  });
  return (
    <LoadingView
      queryResult={connection}
      renderUpdated={(connection: ConnectionT) => (
        <CustomConnectionNodeEditorInternal
          connection={connection}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

type PropsT = {
  connection: ConnectionT;
  immutable: boolean;
  node: CustomConnectionNode;
  displayedError: ErrorBaseInfo | undefined;
} & NodeEditorBaseProps<CustomConnectionNodeDataT>;

const CustomConnectionNodeEditorInternal: React.FC<PropsT> = ({
  connection,
  immutable,
  node,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const currentFormValues: CustomConnectionForm = {
    verb: node.data.verb,
    path: node.data.path,
    params: node.data.params,
    headers: node.data.headers,
    body: node.data.body,
    response: node.data.response,
    config: node.data.config,
  };

  const formProps = useForm<CustomConnectionForm>({
    defaultValues: currentFormValues,
    ...(isReactive && { values: currentFormValues }),
  });

  useSubmitForm({
    onChange: (data: CustomConnectionForm) => {
      onUpdate({ newData: data });
    },
    disabled: isReactive,
    previousValues: currentFormValues,
    watch: formProps.watch,
  });

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  const bodyDisablingVerbs = new Set(["get", "head", "options", undefined]);

  return (
    <FormProvider {...formProps}>
      <VerbPathSelector
        connection={connection}
        immutable={immutable}
        runFieldErrors={runFieldErrors}
      />
      <Root
        className={accordionRootClassName}
        defaultValue={["parameters", "headers", "body", "response", "config"]}
        type="multiple"
      >
        <AccordionItem
          className={itemClassName}
          dataLoc="cc-node-query-parameters"
          disabled={immutable || renderedInDiffView}
          title="Query parameters"
          value="parameters"
        >
          <Card className="w-full">
            <KeyValueArrayEditor
              addButtonText="Add parameter"
              fieldName="params"
              immutable={immutable}
              runFieldErrors={runFieldErrors}
            />
          </Card>
        </AccordionItem>
        <AccordionItem
          className={itemClassName}
          dataLoc="cc-node-headers"
          disabled={immutable || renderedInDiffView}
          title="Headers"
          value="headers"
        >
          <Card className="w-full">
            <KeyValueArrayEditor
              addButtonText="Add header"
              fieldName="headers"
              immutable={immutable}
              runFieldErrors={runFieldErrors}
            />
          </Card>
        </AccordionItem>
        {!bodyDisablingVerbs.has(formProps.getValues().verb) && (
          <AccordionItem
            className={itemClassName}
            dataLoc="cc-node-body"
            disabled={immutable || renderedInDiffView}
            title="Body"
            value="body"
          >
            <BodyEditor immutable={immutable} runFieldErrors={runFieldErrors} />
          </AccordionItem>
        )}
        <AccordionItem
          className={itemClassName}
          disabled={immutable || renderedInDiffView}
          title="Configure API response"
          value="response"
        >
          <ResponseMapping
            immutable={immutable}
            runFieldErrors={runFieldErrors}
          />
        </AccordionItem>
        <AccordionItem
          className={itemClassName}
          disabled={immutable || renderedInDiffView}
          title="Advanced settings"
          value="config"
        >
          <ConfigPane connection={connection} immutable={immutable} />
        </AccordionItem>
      </Root>
    </FormProvider>
  );
};
