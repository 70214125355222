import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { CustomConnectionAuthMethod } from "src/api/connectApi/types";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { ApiKeyFieldset as ConfigApiKeyFieldset } from "src/connections/config/ApiKeyFieldset";
import { ConfigAwsSignatureFieldset } from "src/connections/config/AwsSignatureFieldset";
import { BasicAuthFieldset } from "src/connections/config/BasicAuthFieldset";
import { ccCreationAuthMethods } from "src/connections/config/CCAuthMethodDropdownOptions";
import { ConfigFieldsetCard as FieldsetCard } from "src/connections/config/FieldsetCard";
import { GoogleOAuth2Fieldset } from "src/connections/config/GoogleOAuth2Fieldset";
import { KeyValuePairField } from "src/connections/config/KeyValuePairField";
import { NoAuthFieldset } from "src/connections/config/NoAuthFieldset";
import { OAuth2Fieldset as ConfigOAuthFieldset } from "src/connections/config/OAuth2Fieldset";
import { SSLConfigFields } from "src/connections/config/SSLConfigFields";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { defaultAuthMethodSecrets } from "src/connections/model/model";
import {
  AvailableEnvironmentPrefixes,
  ConnectionConfigInputsT,
} from "src/connections/types";
import { isValidHTTPURL } from "src/connections/urls";
import { assertUnreachable } from "src/utils/typeUtils";

const renderAuthFields = (
  authMethod: CustomConnectionAuthMethod,
  environmentPrefix: AvailableEnvironmentPrefixes,
) => {
  switch (authMethod) {
    case "api_key":
      return <ConfigApiKeyFieldset environmentPrefix={environmentPrefix} />;
    case "oauth2":
      return <ConfigOAuthFieldset environmentPrefix={environmentPrefix} />;
    case "no_auth":
      return <NoAuthFieldset environmentPrefix={environmentPrefix} />;
    case "aws_signature":
      return (
        <ConfigAwsSignatureFieldset environmentPrefix={environmentPrefix} />
      );
    case "basic":
      return <BasicAuthFieldset environmentPrefix={environmentPrefix} />;
    case "google_oauth2":
      return <GoogleOAuth2Fieldset environmentPrefix={environmentPrefix} />;
    default:
      assertUnreachable(authMethod);
  }
};

type PropsT = {
  environmentPrefix: AvailableEnvironmentPrefixes;
  labelsPrefix?: string;
};

export const EnvironmentCredentials: React.FC<PropsT> = ({
  environmentPrefix,
  labelsPrefix = "",
}) => {
  const { formState, getValues, setValue, control, watch, ...formMethods } =
    useFormContext<ConnectionConfigInputsT>();

  const errors =
    environmentPrefix === ""
      ? formState.errors
      : formState.errors.nonProdEnvConfigs?.sandbox;

  const authMethod = watch(`${environmentPrefix}authMethod`);
  const enableNonProdConfigs = watch("enableNonProdConfigs");

  // Handles adding a space after the prefix if it exists
  const prefix = labelsPrefix.length ? `${labelsPrefix} ` : "";

  useEffect(() => {
    // When the authMethod changes, ensure that the already populated secrets are kept
    // Also ensure the required secrets for the given authMethod are present.

    // We want to:
    //  - Keep the secrets that are coming from the BE and are not deleted
    //  - Keep the secrets that the users already inputted a value for before
    //  - Add any other required secrets for new authMethod that are not present
    const secretStatePath = `${environmentPrefix}secrets` as const;
    const currentSecrets = getValues(secretStatePath);
    const authMethodSecrets = defaultAuthMethodSecrets[authMethod];
    const authMethodSecretsKeys = new Set(authMethodSecrets.map((s) => s.key));

    // If there are no secrets, just set the default ones
    if (!currentSecrets || !currentSecrets.length) {
      setValue(secretStatePath, authMethodSecrets);
      return;
    }

    // We want to keep the secrets that:
    // - Have a value
    // - Are coming from the BE and are not deleted (secret is true)
    // - Are required for the new authMethod
    const secretsToKeep = currentSecrets
      .filter(
        (secret) =>
          secret.value ||
          (secret.secret && secret.value != null) ||
          authMethodSecretsKeys.has(secret.key),
      )
      .map((s) =>
        authMethodSecretsKeys.has(s.key)
          ? { ...s, required: !s.secret }
          : { ...s, required: false },
      );

    const presentSecretKeys = new Set(secretsToKeep.map((s) => s.key));

    setValue(secretStatePath, [
      ...secretsToKeep,
      // Don't add secrets that are already present
      ...authMethodSecrets.filter(
        (secret) => !presentSecretKeys.has(secret.key),
      ),
    ]);
  }, [environmentPrefix, authMethod, setValue, getValues]);

  return (
    <>
      <FormItem
        description="The base URL of the service you want to connect to"
        label={labelsPrefix.length ? `${labelsPrefix} base URL` : "Base URL"}
        isRequired
      >
        {Boolean(errors?.url) && <ErrorHint>{errors?.url?.message}</ErrorHint>}
        <Input
          data-loc={`${environmentPrefix}custom-connection-base-url`}
          errored={isFieldErrored(errors, "url")}
          {...formMethods.register(`${environmentPrefix}url`, {
            validate: (value) => isValidHTTPURL(value) || "Invalid URL",
          })}
          placeholder="URL"
          suffixIcon={
            isFieldErrored(errors, "url") ? { icon: faWarning } : undefined
          }
          fullWidth
        />
      </FormItem>
      <FormItem
        description="A URL to validate the Connection works"
        label={labelsPrefix.length ? `${labelsPrefix} probe URL` : "Probe URL"}
      >
        {Boolean(errors?.probeUrl) && (
          <ErrorHint>{errors?.probeUrl?.message}</ErrorHint>
        )}
        <Input
          errored={isFieldErrored(errors, "probeUrl")}
          {...formMethods.register(`${environmentPrefix}probeUrl`, {
            validate: (value) =>
              value.trim() === ""
                ? true
                : isValidHTTPURL(value) || "Invalid URL",
          })}
          placeholder="Probe URL"
          suffixIcon={
            isFieldErrored(errors, "probeUrl") ? { icon: faWarning } : undefined
          }
          fullWidth
        />
      </FormItem>
      <FormItem gap="sm">
        <FormItem.Label
          className="mb-4"
          description="A list of headers and their values to be used in requests"
        >
          {labelsPrefix.length ? `${labelsPrefix} headers` : "Headers"}
        </FormItem.Label>
        <FieldsetCard className="mb-4 bg-gray-100">
          <KeyValuePairField
            cta="Add header"
            keyPlaceholder="Key"
            name={`${environmentPrefix}headers`}
            valuePlaceholder="Value"
          />
        </FieldsetCard>
      </FormItem>
      <FormItem
        description="The authentication mechanism required for the service"
        label={`Select ${prefix.toLowerCase()}authentication type`}
      >
        <Controller
          control={control}
          name={`${environmentPrefix}authMethod`}
          render={(props) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc={`${environmentPrefix}custom-connection-auth-type`}
              className="h-8 w-full"
              elements={ccCreationAuthMethods}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select authentication type"
              placement="bottomLeft"
              selectedKey={String(props.field.value)}
              onSelect={(value) => {
                props.field.onChange(value);
              }}
            />
          )}
        />
      </FormItem>
      {renderAuthFields(authMethod, environmentPrefix)}
      <SSLConfigFields environmentPrefix={environmentPrefix} />
      {environmentPrefix === "" && (
        <>
          <FormItem
            label={
              environmentPrefix === "" && enableNonProdConfigs
                ? "Allow authors to hit production environment during testing?"
                : "Allow authors to hit this Connection during testing?"
            }
            isRequired
          >
            <div className="mb-4 text-gray-500 font-inter-normal-12px">
              This allows Decision Flow authors to invoke this endpoint in Test
              Runs. The provider of the Connection may charge a fee for such
              requests.
            </div>
            <Controller
              control={control}
              name="allowTestInvocations"
              render={(props) => (
                <SimpleRadioGroup
                  orientation="vertical"
                  value={String(props.field.value)}
                  onValueChange={(value) => {
                    props.field.onChange(value === "true");
                  }}
                >
                  <SimpleRadioGroup.Item
                    label="No, do not allow"
                    labelClassName="pl-2.5"
                    value="false"
                  />
                  <SimpleRadioGroup.Item
                    label="Yes, allow"
                    labelClassName="pl-2.5"
                    value="true"
                  />
                </SimpleRadioGroup>
              )}
            />
          </FormItem>
        </>
      )}
    </>
  );
};
