import { makeConnectionTemplate } from "src/connections/types";

export const SchufaTemplate = makeConnectionTemplate({
  provider: "schufa",
  secrets: [
    {
      name: "Account ID",
      key: "account_id",
      required: true,
    },
    {
      name: "Password",
      key: "password",
      required: true,
    },
  ],
  resources: {
    b2c_report: {
      name: "B2C Report - Credit Enquiry",
      configKeys: [
        {
          name: "B2C feature code",
          type: "simple_selection",
          key: "schufa_feature_code",
          required: true,
          elements: [
            { key: "A1", value: "(A1) Working capital loan for freelancers" },
            { key: "A2", value: "(A2) Working capital loan for SMEs" },
            {
              key: "A3",
              value: "(A3) Investment loan/leasing for freelancers",
            },
            { key: "A4", value: "(A4) Investment loan/leasing for SMEs" },
            { key: "A5", value: "(A5) Enquiry for freelancers" },
            { key: "A6", value: "(A6) Enquiry for SMEs" },
            { key: "A7", value: "(A7) Debt guarantee for SMEs" },
            { key: "AD", value: "(AD) Enquiry service" },
            {
              key: "AH",
              value:
                "(AH) Commercial enquiry due to delivery of goods or services",
            },
            { key: "AI", value: "(AI) Debt collection" },
            { key: "AU", value: "(AU) Feature check for private individuals" },
            { key: "FU", value: "(FU) Feature check for freelancers" },
            { key: "GU", value: "(GU) Feature check for SMEs" },
            { key: "KK", value: "(KK) Loan conditions" },
            {
              key: "OA",
              value: "(OA) Address request / updating a customer address",
            },
            { key: "AG", value: "(AG) Current account" },
            {
              key: "B1",
              value: "(B1) Business current account for freelancers",
            },
            { key: "B2", value: "(B2) Business current account for SMEs" },
            {
              key: "B3",
              value: "(B3) Leasing for business purposes for freelancers",
            },
            { key: "B4", value: "(B4) Leasing for business purposes for SMEs" },
            {
              key: "B5",
              value: "(B5) Credit for business purposes for freelancers",
            },
            { key: "B6", value: "(B6) Credit for business purposes for SMEs" },
            {
              key: "B7",
              value:
                "(B7) Investment credit for business purposes for freelancers",
            },
            {
              key: "B8",
              value: "(B8) Investment credit for business purposes for SMEs",
            },
            {
              key: "AB",
              value:
                "(AB) Security for unsecured building society loans, loans not secured by a charge on real estate, or movable equipment leasing/hire purchase",
            },
            {
              key: "AL",
              value:
                "(AL) Unsecured building loan or loan not secured by a charge on real estate",
            },
            {
              key: "AR",
              value: "(AR) Credit card with revolving line of credit",
            },
            { key: "AW", value: "(AW) Loan secured by charge on real estate" },
            { key: "KH", value: "(KH) Terms of real estate loan" },
            {
              key: "KL",
              value: "(KL) Terms of movable equipment leasing/hire purchase",
            },
          ],
        },
      ],
    },
    schufa_b2c_basic_account_and_exemption_from_execution_account_report: {
      name: "B2C Report - Basic Account and Exemption from Execution Account",
      configKeys: [
        {
          name: "B2C target product",
          type: "simple_selection",
          key: "schufa_target_product",
          required: true,
          elements: [
            { key: "AnfrageBasiskonto", value: "Basic Account report" },
            {
              key: "AnfragePfaendungsschutzkonto",
              value: "Exemption from Execution Account report",
            },
          ],
        },
      ],
    },
    schufa_b2c_monitor: {
      name: "B2C Report - Monitor",
      configKeys: [
        {
          name: "Specification of Account ID",
          type: "simple_selection",
          key: "collection_method",
          required: true,
          hint: "Indicates whether Schufa has configured the account ID used for the connection as a collective ID for multiple recipients or as a single ID for an individual recipient. When multiple recipients are involved, the account ID represents the collective ID assigned to all recipients.",
          elements: [
            { key: "einzelabruf", value: "Single account ID" },
            { key: "sammelabruf", value: "Collective account ID" },
          ],
        },
      ],
    },
    schufa_fraud_pre_check: {
      name: "Fraud Pre-Check",
      configKeys: [],
    },
    b2b_report_cascading: {
      name: "B2B Report Cascading",
      configKeys: [
        {
          name: "B2B feature code",
          type: "simple_selection",
          key: "schufa_feature_code",
          required: true,
          elements: [
            { key: "X1", value: "Credit enquiry" },
            { key: "X2", value: "Initial business contact" },
            { key: "X3", value: "Credit check" },
            { key: "X4", value: "Debt claims" },
          ],
        },
      ],
    },
    schufa_b2c_furnish: {
      name: "B2C Report - Furnish",
      configKeys: [],
    },
    schufa_b2c_manual_retrieval: {
      name: "B2C Report - Manual Retrieval",
      configKeys: [],
    },
    schufa_b2c_update_address: {
      name: "B2C Report - Update Address",
      configKeys: [],
    },
  },
});
