import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { getConnectDocsLink } from "src/constants/ExternalLinks";
import { IntegrationNode, ManualReviewNode } from "src/constants/NodeDataTypes";
import { assertUnreachable } from "src/utils/typeUtils";

type IntegrationPropertyT = {
  description: string;
  docsUrl?: string;
};

export const getIntegrationNodeProperties = (
  node: IntegrationNode | ManualReviewNode,
): IntegrationPropertyT => {
  const providerResource = node.data.providerResource;
  const defaultIntegrationProps: IntegrationPropertyT = {
    description: "",
    docsUrl: getConnectDocsLink(providerResource) || undefined,
  };

  if (providerInConfigMap(providerResource.provider)) {
    const providerMap = CONNECTIONS_MAP[providerResource.provider];
    // @ts-ignore
    const resourceMap = providerMap.resources[providerResource.resource];
    return resourceMap.integrationProperties || defaultIntegrationProps;
  }

  switch (providerResource.provider) {
    case "schufa": {
      switch (providerResource.resource) {
        case "b2c_report": {
          return {
            ...defaultIntegrationProps,
            description:
              "This report contains basic consumer information and can be given with or without a Schufa Score. It is the least expensive report and is a good start for consumer credit information.",
          };
        }
        case "schufa_b2c_basic_account_and_exemption_from_execution_account_report": {
          return {
            ...defaultIntegrationProps,
            description:
              "This resource can be used to obtain reports on basic accounts or on exemption from execution accounts",
          };
        }
        case "schufa_b2c_monitor": {
          return {
            ...defaultIntegrationProps,
            description:
              "This resource enables the retrieval of daily follow-up reports on consumers, automatically generated by SCHUFA when new or updated information is available in their database",
          };
        }
        case "schufa_fraud_pre_check": {
          return defaultIntegrationProps;
        }
        case "b2b_report_cascading": {
          return defaultIntegrationProps;
        }
        case "schufa_b2c_furnish": {
          return {
            ...defaultIntegrationProps,
            description:
              "This resource enables the furnishing of events to Schufa, such as the opening of a new account or the closing of an existing account",
          };
        }
        case "schufa_b2c_manual_retrieval": {
          return {
            ...defaultIntegrationProps,
            description:
              "This resource enables the retrieval of B2C reports where SCHUFA indicated they must be manually retrieved",
          };
        }
        case "schufa_b2c_update_address": {
          return {
            ...defaultIntegrationProps,
            description:
              "This resource can be used to update a consumer's address",
          };
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "boniversum": {
      if (providerResource.resource === "consumer_report") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "codat": {
      switch (providerResource.resource) {
        case "company_info": {
          return defaultIntegrationProps;
        }
        case "company_identification": {
          return defaultIntegrationProps;
        }
        case "enhanced_profit_and_loss_accounts": {
          return defaultIntegrationProps;
        }
        case "enhanced_balance_sheet_accounts": {
          return defaultIntegrationProps;
        }
        case "aged_creditors_report": {
          return defaultIntegrationProps;
        }
        case "aged_debtors_report": {
          return defaultIntegrationProps;
        }
        default: {
          return assertUnreachable(providerResource);
        }
      }
    }
    case "data_x": {
      if (providerResource.resource === "data_x_credit_report") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "dnb": {
      switch (providerResource.resource) {
        case "identity_resolution": {
          return defaultIntegrationProps;
        }
        case "credit_report": {
          return defaultIntegrationProps;
        }
        case "custom_report": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "crif_b2b": {
      switch (providerResource.resource) {
        case "full_report": {
          return defaultIntegrationProps;
        }
        case "credit_report": {
          return defaultIntegrationProps;
        }
        case "search_report": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "crif_b2c": {
      switch (providerResource.resource) {
        case "identity_check": {
          return defaultIntegrationProps;
        }
        case "credit_check": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "crif_high_mark": {
      if (providerResource.resource === "credit_report") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "equifax_uk": {
      if (providerResource.resource === "gateway") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "equifax_us": {
      switch (providerResource.resource) {
        case "scores_and_attributes": {
          return defaultIntegrationProps;
        }
        case "mla_report": {
          return defaultIntegrationProps;
        }
        case "one_view_report": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }

    case "equifax_us_commercial_risk": {
      if (
        providerResource.resource === "equifax_us_commercial_risk_credit_report"
      ) {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "experian_us": {
      if (providerResource.resource === "experian_us_credit_report") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "experian_us_b2b": {
      switch (providerResource.resource) {
        case "experian_us_b2b_business_search": {
          return defaultIntegrationProps;
        }
        case "experian_us_b2b_small_business_credit_share_profiles_report": {
          return defaultIntegrationProps;
        }
        case "experian_us_b2b_small_business_credit_share_scores": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "experian_us_clarity_services": {
      if (providerResource.resource === "experian_us_clarity_services_report") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    case "creditsafe": {
      switch (providerResource.resource) {
        case "company_search": {
          return defaultIntegrationProps;
        }
        case "company_credit_report": {
          return defaultIntegrationProps;
        }
        default:
          return assertUnreachable(providerResource);
      }
    }
    case "webhook": {
      if (providerResource.resource === "review_event") {
        return defaultIntegrationProps;
      } else {
        return assertUnreachable(providerResource);
      }
    }
    default:
      // TODO(INT-2556): Handle manifest connections
      // @ts-ignore
      return assertUnreachable(providerResource.provider);
  }
};
