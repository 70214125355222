import { useSortable } from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo } from "react";
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  FieldError,
  Merge,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { useEntitySchemas } from "src/entities/queries";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { useFeatures } from "src/featureCatalogue/queries";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitySchemaHint } from "src/schema/EntitySchemaHint";
import { SchemaAdvancedOptions } from "src/schema/SchemaAdvancedOptions";
import { SchemaEnumRows } from "src/schema/SchemaEnumRows";
import { SchemaGlobalFeatures } from "src/schema/SchemaGlobalFeatures";
import { SchemaNameInput } from "src/schema/SchemaNameInput";
import { SchemaSensitiveToggle } from "src/schema/SchemaSensitiveToggle";
import { SchemaTypeSelector } from "src/schema/SchemaTypeSelector";
import { decodeJsonRefPath, isJsonRefPath } from "src/schema/jsonRefUtils";
import {
  getFeaturesByEntity,
  PropertyUIT,
  SchemaUIT,
} from "src/schema/schemaMappingUtils";

type PropsT = {
  property: FieldArrayWithId<
    {
      schemaFields: PropertyUIT[];
    },
    "schemaFields",
    "id"
  >;
  errorStatus?: Merge<FieldError, FieldErrorsImpl<PropertyUIT>>;
  onDelete: () => void;
  fieldIndex: number;
  checkNameUnique: (name: string) => boolean;
  readonly?: boolean;
  displaySensitiveToggle: boolean;
  type: SchemaOptions;
  id: string;
};

export const SchemaEditRow: React.FC<PropsT> = ({
  readonly = false,
  errorStatus,
  onDelete,
  fieldIndex,
  checkNameUnique,
  displaySensitiveToggle,
  type,
  id,
}) => {
  const { register, control } = useFormContext<SchemaUIT>();
  const { workspace } = useWorkspaceContext();
  const featureGates = useWorkspaceFeatureGates();

  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: id,
    });

  const style = {
    transform: CSS.Transform.toString(
      transform ? ({ ...transform, scaleY: 1 } as Transform) : null,
    ),
    transition,
  };

  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.entitiesEnabled,
    },
  });

  const { data: eventTypes } = useEventSchemas({
    enabled:
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
      featureGates.featuresEventsEnabled,
  });

  const { data: features } = useFeatures({
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.featuresEventsEnabled,
    },
  });

  const property = useWatch({
    control,
    name: `properties.${fieldIndex}`,
  });
  const propertyEnum = property?.enum;
  const propertyType = property?.type;
  const propTypeFirstComponent = propertyType[0];
  const isRefPath = isJsonRefPath(propTypeFirstComponent);
  const decodedRef = decodeJsonRefPath(propTypeFirstComponent);
  const showGlobalFeatures =
    isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
    featureGates.featuresEventsEnabled &&
    propTypeFirstComponent === "object" &&
    property.fieldName === "features";
  const selectedEntity =
    decodedRef?.metaType === "entity"
      ? entitySchemas?.entities.find((entity) => entity._id === decodedRef.type)
      : undefined;
  const selectedEvent =
    decodedRef?.metaType === "event"
      ? eventTypes?.find(
          (eventType) => eventType.internal_name === decodedRef.type,
        )
      : undefined;
  const featuresByEntity = useMemo(
    () => getFeaturesByEntity(features ?? []),
    [features],
  );

  return (
    <div
      ref={setNodeRef}
      className="mt-3"
      data-loc={`schema-edit-row-${fieldIndex}`}
      style={style}
    >
      <div
        className={twJoin(
          "flex w-full flex-row items-center gap-x-2",
          isRefPath && "mb-3",
        )}
      >
        <ReorderHandle
          color="text-gray-500"
          immutable={readonly}
          listeners={listeners}
        />
        <div className="grow">
          <SchemaNameInput
            disabled={readonly}
            errorStatus={errorStatus}
            formProps={register(`properties.${fieldIndex}.fieldName`, {
              required: true,
              //match anything not containing a '"' or a '\'
              pattern: /^[^\\"]*$/,
              validate: {
                unique: checkNameUnique,
              },
            })}
          />
        </div>
        <div className={isRefPath ? "w-[167px]" : "w-[140px]"}>
          <SchemaTypeSelector
            disabled={readonly}
            index={fieldIndex}
            rowName={`properties.${fieldIndex}`}
            type={type}
          />
        </div>
        <div className="flex w-auto items-center gap-x-2">
          {displaySensitiveToggle && !isJsonRefPath(propertyType[0]) && (
            <SchemaSensitiveToggle
              disabled={readonly}
              rowName={`properties.${fieldIndex}`}
            />
          )}
          <SchemaAdvancedOptions
            disabled={readonly}
            rowName={`properties.${fieldIndex}`}
          />
          <Icon
            color="text-gray-500 hover:text-red-600"
            dataLoc="schema-field-delete"
            disabled={readonly}
            icon={faTrashAlt}
            size="xs"
            onClick={onDelete}
          />
        </div>
      </div>
      {Array.isArray(propertyEnum) && !isDragging && (
        <SchemaEnumRows
          enumFormLocation={`properties.${fieldIndex}.enum`}
          errorStatus={errorStatus?.enum}
          immutable={readonly}
          withValidation
        />
      )}
      {entitySchemas && (
        <EntitySchemaHint
          entitySchema={selectedEntity}
          entitySchemas={entitySchemas.entities}
          eventType={selectedEvent}
          featuresByEntity={featuresByEntity}
          rootPath={`data.${property?.fieldName}`}
        />
      )}
      {showGlobalFeatures && <SchemaGlobalFeatures />}
    </div>
  );
};
