import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";
import { v4 } from "uuid";

import { FieldErrorsT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { RuleNodeV2FormT } from "src/ruleNodeV2Editor/types";
import {
  mergeFieldIdAndName,
  RunErrorFieldNames,
} from "src/utils/FieldErrorUtils";

type PropsT = {
  onRemove?: () => void;
  index: number;
  immutable: boolean;
  lazyInputs: boolean;
  fieldError: string | undefined;
};

const Field: React.FC<PropsT> = ({
  index,
  immutable,
  lazyInputs,
  onRemove,
  fieldError,
}) => {
  const { control } = useFormContext<RuleNodeV2FormT>();
  return (
    <div className="flex items-center space-x-3">
      <Controller
        control={control}
        name={`default_effects.${index}.target`}
        render={({ field }) => (
          <AutocompleteCodeInput
            dataLoc={`rule-node-v2-output-field-name-${index}`}
            disabled={immutable}
            error={fieldError}
            lazy={lazyInputs}
            prefix="data."
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {onRemove && (
        <Icon
          color={twJoin(
            !immutable && "text-gray-500 hover:text-gray-700",
            immutable && "text-gray-400",
          )}
          dataLoc={`rule-node-v2-output-field-${index}-delete`}
          disabled={immutable}
          icon={faTrashAlt}
          size="xs"
          onClick={onRemove}
        />
      )}
    </div>
  );
};

type AddFieldsCardPropsT = {
  immutable: boolean;
  lazyInputs: boolean;
  fieldErrors: FieldErrorsT | undefined;
  hideActionButtons?: boolean;
};

export const AddFieldsCard: React.FC<AddFieldsCardPropsT> = ({
  immutable,
  lazyInputs,
  fieldErrors,
  hideActionButtons,
}) => {
  const { control } = useFormContext<RuleNodeV2FormT>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "default_effects",
  });
  const defaultEffects = useWatch({ control, name: "default_effects" });
  return (
    <Card className="w-full space-y-3">
      <span className="text-gray-700 font-inter-medium-12px">
        Add or modify these fields
      </span>
      <fieldset className="space-y-3">
        {fields.map((field, index) => (
          <Field
            key={field.id}
            fieldError={
              fieldErrors?.[
                mergeFieldIdAndName(
                  defaultEffects.at(index)?.id,
                  RunErrorFieldNames.LEFT,
                )
              ]
            }
            immutable={immutable}
            index={index}
            lazyInputs={lazyInputs}
            onRemove={fields.length > 1 ? () => remove(index) : undefined}
          />
        ))}
        {!hideActionButtons && (
          <Button
            dataLoc="rule-node-v2-add-output-field"
            disabled={immutable}
            iconLeft={faPlus}
            size="sm"
            variant="secondary"
            onClick={() => append({ id: v4(), value: "None", target: "" })}
          >
            Add field
          </Button>
        )}
      </fieldset>
    </Card>
  );
};
