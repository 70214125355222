import {
  IconDefinition,
  faArrowLeft,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCopy,
  faEye,
  faShareNodes,
  faUserCircle,
  faWavePulse,
} from "@fortawesome/pro-regular-svg-icons";
import { UseQueryResult } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { cloneDeep, get, isEqual, isObject } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";

import { CasePatchBody } from "src/api/endpoints";
import {
  useReviewCase,
  useReviewCaseMutation,
  useReviewCaseSubmit,
  useReviewCases,
} from "src/api/queries";
import { useWorkspaceUsers } from "src/api/taktile/queries";
import {
  ReviewCaseResponseData,
  ReviewCaseStatusPending,
  ReviewCaseWithDecisionData,
} from "src/api/types";
import { Button } from "src/base-components/Button";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { isValidEntityId } from "src/decisionsOverview/utils";
import { EmptyState } from "src/design-system/EmptyState";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { DetailHeader } from "src/layout/DetailHeader";
import { StatusPicker } from "src/manualReview/StatusPicker";
import { UserPicker } from "src/manualReview/UserPicker";
import { Card } from "src/manualReview/reviewCaseCommon/Card";
import {
  Form as FormBase,
  MR_FORM_ELEMENTS_PREFIX,
} from "src/manualReview/reviewCaseCommon/Form";
import { FormSide } from "src/manualReview/reviewCaseCommon/FormSide";
import { SkeletonLine } from "src/manualReview/reviewCaseCommon/SkeletonLine";
import { SubHeader as SubHeaderBase } from "src/manualReview/reviewCaseCommon/SubHeader";
import {
  DEFAULT_FILTERS,
  useReviewQueueFilters,
} from "src/manualReview/useReviewFilters";
import {
  convertFiltersToParams,
  isDefaultFiltersState,
} from "src/manualReview/utils";
import { queryClient } from "src/queryClient";
import {
  useFlowContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import {
  DashboardPageParamsT,
  ManualReviewPageParamsT,
  getBaseUrl,
  getUrlFlowDecisionHistory,
  getUrlToDecisionsOverview,
  getUrlToHistoricDecisionFromCase,
  getUrlToReviewQueue,
} from "src/router/urls";
import { copyTextToClipboard } from "src/utils/clipboard";
import { dateFromNow } from "src/utils/datetime";
import { logger } from "src/utils/logger";
import {
  isBadRequest,
  isConflictError,
  isPreconditionError,
} from "src/utils/predicates";
import { useParamsDecode } from "src/utils/useParamsDecode";

const useSiblingCases = (
  reviewCase: ReviewCaseWithDecisionData | undefined,
  flowSlug: string,
) => {
  const { workspace } = useWorkspaceContext();
  const [_filters] = useReviewQueueFilters();
  const [filters, setFilters] = useState(_filters);
  const cases = useReviewCases(
    workspace.base_url,
    flowSlug,
    {
      ...convertFiltersToParams(filters),
      environment: reviewCase?.decision.is_sandbox ? "sandbox" : "prod",
      limit: 200,
    },
    { enabled: Boolean(reviewCase) },
  );

  const allCases = cases.data?.pages.flatMap((page) => page.cases) ?? [];
  const caseIndex = allCases.findIndex((rCase) => rCase.id === reviewCase?.id);

  useEffect(() => {
    if (caseIndex === -1 && !cases.isFetching && cases.hasNextPage) {
      cases.fetchNextPage();
    }
    // Fallback to default filters if for some reason we couldn't find the case
    if (
      caseIndex === -1 &&
      !cases.isFetching &&
      !cases.hasNextPage &&
      !isDefaultFiltersState(filters)
    ) {
      setFilters(DEFAULT_FILTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseIndex,
    cases.isFetching,
    cases.hasNextPage,
    cases.fetchNextPage,
    filters,
  ]);

  return {
    prevCaseId: caseIndex > 0 ? allCases[caseIndex - 1].id : null,
    nextCaseId:
      caseIndex < allCases.length - 1 && caseIndex !== -1
        ? allCases[caseIndex + 1].id
        : null,
  } as const;
};

const SiblingButtons: React.FC<{
  to: string;
  icon: IconDefinition;
  disabled: boolean;
}> = ({ to, icon, disabled }) => (
  <Link
    className={twJoin(
      "rounded-md border border-gray-200 p-[2px]",
      disabled && "pointer-events-none opacity-50",
    )}
    relative="path"
    to={to}
  >
    <Icon color="text-gray-500" icon={icon} size="2xs" />
  </Link>
);

export const ReviewCaseContent: React.FC = () => {
  const { workspace, flow } = useFlowContext();
  const { case_id: caseId, orgId } = useParamsDecode<ManualReviewPageParamsT>();

  const reviewCaseQuery = useReviewCase(workspace.base_url, flow.slug, caseId);
  const { nextCaseId } = useSiblingCases(reviewCaseQuery.data, flow.slug);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onSubmit = (updatedCase?: ReviewCaseWithDecisionData) => {
    searchParams.set(
      "environment",
      updatedCase?.decision.is_sandbox ? "sandbox" : "prod",
    );
    if (nextCaseId) {
      navigate(`../${nextCaseId}?${searchParams.toString()}`, {
        relative: "path",
      });
    } else {
      // Navigate back to the queue
      navigate(`../?${searchParams.toString()}`, { relative: "path" });
    }
  };

  return (
    <FloatingWindowsProvider>
      <div className="flex h-full flex-1 flex-col divide-y divide-gray-200">
        {(() => {
          if (reviewCaseQuery.error) {
            return (
              <NotFoundCaseContent
                flowId={flow.id}
                orgId={orgId}
                workspaceId={workspace.id}
              />
            );
          }

          if (reviewCaseQuery.data?.status === "completed") {
            return (
              <CompletedCaseContent
                flowId={flow.id}
                orgId={orgId}
                reviewCaseQuery={reviewCaseQuery}
                workspaceId={workspace.id}
              />
            );
          }

          return (
            <>
              <Header
                flowSlug={flow.slug}
                orgId={orgId}
                reviewCaseQuery={reviewCaseQuery}
                wsId={workspace.id}
              />
              <div className="flex min-h-0 flex-1">
                <div className="flex flex-1 flex-col bg-gray-100">
                  <SubHeader reviewCaseQuery={reviewCaseQuery} />
                  <Card
                    externalData={reviewCaseQuery.data?.external_data}
                    highlights={reviewCaseQuery.data?.highlights ?? []}
                    inspectData={reviewCaseQuery.data?.inspect_data}
                    isLoading={!reviewCaseQuery.data}
                  />
                </div>
                <FormSide
                  description={
                    reviewCaseQuery.data?.response.schema.description
                  }
                  isLoading={!reviewCaseQuery.data}
                  nodeName={reviewCaseQuery.data?.node_name}
                >
                  {reviewCaseQuery.data && (
                    <ReviewCaseActionForm
                      key={reviewCaseQuery.data.id}
                      flowSlug={flow.slug}
                      reviewCase={reviewCaseQuery.data}
                      onSubmit={onSubmit}
                    />
                  )}
                </FormSide>
              </div>
            </>
          );
        })()}
      </div>
    </FloatingWindowsProvider>
  );
};

const openEntityDecisionsView = ({
  orgId,
  workspaceId,
  entityId,
}: {
  orgId: string;
  workspaceId: string;
  entityId: string;
}) => {
  window.open(
    getBaseUrl() +
      getUrlToDecisionsOverview(orgId, workspaceId, {
        entityId,
      }),
    "_blank",
  );
};

const Header: React.FC<{
  reviewCaseQuery: UseQueryResult<ReviewCaseWithDecisionData, Error>;
  orgId: string;
  wsId: string;
  flowSlug: string;
}> = ({ reviewCaseQuery, orgId, wsId, flowSlug }) => {
  const { search } = useLocation();
  const { prevCaseId, nextCaseId } = useSiblingCases(
    reviewCaseQuery.data,
    flowSlug,
  );
  const { decisionHistory } = useCapabilities();
  const { entitiesEnabled } = useWorkspaceFeatureGates();

  return (
    <DetailHeader
      actions={
        reviewCaseQuery.data && (
          <div className="flex space-x-2">
            <CustomPopover
              button={
                <Button iconLeft={faShareNodes} size="sm" variant="secondary">
                  {" "}
                  Share
                </Button>
              }
            >
              <CustomPopover.Button className="w-60 cursor-pointer py-2">
                {[
                  { name: "link", value: window.location.href },
                  !entitiesEnabled &&
                    !!reviewCaseQuery.data.decision.entity_id && {
                      name: "Entity ID",
                      value: reviewCaseQuery.data.decision.entity_id,
                    },
                  {
                    name: "Decision ID",
                    value: reviewCaseQuery.data.decision.id,
                  },
                ]
                  .filter(Boolean as unknown as ExcludesFalse)
                  .map((element) => (
                    <div
                      key={element.name}
                      className="flex h-12 items-center justify-between px-4 py-2.5 text-gray-800 font-inter-normal-13px hover:bg-gray-50"
                      onClick={() => {
                        copyTextToClipboard(element.value);
                        toastActions.success({ title: "Copied to clipboard!" });
                      }}
                    >
                      Copy {element.name}
                      <Icon
                        color="text-gray-500 hover:text-gray-700"
                        icon={faCopy}
                        size="xs"
                      />
                    </div>
                  ))}
              </CustomPopover.Button>
            </CustomPopover>
            {decisionHistory.canAccess && (
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to={getUrlToHistoricDecisionFromCase(
                  orgId,
                  wsId,
                  reviewCaseQuery.data,
                )}
              >
                <Button iconLeft={faEye} size="sm" variant="secondary">
                  Trace decision
                </Button>
              </Link>
            )}
            {!entitiesEnabled &&
              reviewCaseQuery.data.decision.entity_id &&
              isValidEntityId(reviewCaseQuery.data.decision.entity_id) && (
                <EllipsisOptionsDropdown
                  elements={[
                    {
                      key: "nav",
                      nav: "View related decisions",
                      subtitle: (
                        <>
                          Show all decisions with the same Entity ID{" "}
                          <Pill size="sm" variant="gray" maxWidth>
                            <Pill.Text fontType="code">
                              {reviewCaseQuery.data.decision.entity_id}
                            </Pill.Text>
                          </Pill>
                        </>
                      ),
                      action: () => {
                        reviewCaseQuery.data.decision.entity_id &&
                          openEntityDecisionsView({
                            orgId,
                            workspaceId: wsId,
                            entityId: reviewCaseQuery.data.decision.entity_id,
                          });
                      },
                    },
                  ]}
                />
              )}
          </div>
        )
      }
      main={
        reviewCaseQuery.data ? (
          <>
            <Link relative="path" to={`..${search}`}>
              <Icon icon={faArrowLeft} padding={false} size="xs" />
            </Link>
            <p className="ml-4 font-inter-semibold-13px">
              #{reviewCaseQuery.data.number}
            </p>
            <div className="ml-4 flex gap-x-2">
              <SiblingButtons
                disabled={!nextCaseId}
                icon={faChevronDown}
                to={`../${nextCaseId}${search}`}
              />
              <SiblingButtons
                disabled={!prevCaseId}
                icon={faChevronUp}
                to={`../${prevCaseId}${search}`}
              />
            </div>
          </>
        ) : (
          <SkeletonLine className="ml-4.5 h-3 w-56" />
        )
      }
    />
  );
};

const SubHeader: React.FC<{
  reviewCaseQuery: UseQueryResult<ReviewCaseWithDecisionData, Error>;
}> = ({ reviewCaseQuery }) => {
  const { workspace, flow, orgId } = useFlowContext();
  const { case_id: caseId } = useParamsDecode<ManualReviewPageParamsT>();

  const users = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });

  const caseMutation = useReviewCaseMutation(
    workspace.base_url,
    flow.slug,
    caseId,
  );

  const updateCase = async (data: CasePatchBody) => {
    try {
      await caseMutation.mutateAsync({
        ...data,
        etag: reviewCaseQuery.data?.etag,
      });
    } catch (e) {
      const fieldName = data.assignee ? "assignee" : "status";
      if (isPreconditionError(e)) {
        await queryClient.invalidateQueries({
          queryKey: ["reviewCase", workspace.base_url, flow.slug, caseId],
        });
        toastActions.failure({
          title: `Failed to update ${fieldName}`,
          description: "Please try again",
        });
      } else {
        toastActions.failure({
          title: `Failed to update ${fieldName}`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
      logger.error(e);
    }
  };

  const updateAssignee = async (userId: Nullable<string>) =>
    updateCase({ assignee: userId });
  const updateStatus = async (status: ReviewCaseStatusPending) =>
    updateCase({ status });

  if (!(reviewCaseQuery.data && users.isFetched)) {
    return <SubHeaderBase isLoading />;
  }

  return (
    <SubHeaderBase
      assignedTo={
        <UserPicker
          users={users.data ?? []}
          value={reviewCaseQuery.data.assignee}
          workspaceId={workspace.id}
          onChange={updateAssignee}
        />
      }
      requestTime={
        <p className="truncate">
          {format(
            parseISO(reviewCaseQuery.data.decision.start_time),
            "dd/MM/yyyy HH:mm:ss",
          )}
        </p>
      }
      status={
        <StatusPicker
          value={reviewCaseQuery.data.status}
          onChange={updateStatus}
        />
      }
      timeSinceRequest={<p>{dateFromNow(reviewCaseQuery.data.created_at)}</p>}
    />
  );
};

/*
This form has two main events: save and submit.
Save is done automatically when the form changes
and submit needs to be explicitly triggered and confirmed.

Some edge cases arise:

1. More saving is needed when other save action is still pending
  Solution: We maintain a "needsSave" flag and a useEffect will trigger
  save if the flag is set and the mutation is not loading

2. Save is triggered after the form has been submitted (as saves are debounced and submit is not)
  Solution: On submit the pending invocations to the debounced callback are cancelled

3. Submit is triggered while a save is still ongoing
  Solution: We maintain a "needsSubmit" flag which is similar to the #1
  but it also has a callback that needs to be called on submit's end
  for the confirmation modal to close
*/
export const ReviewCaseActionForm: React.FC<{
  reviewCase: ReviewCaseWithDecisionData;
  flowSlug: string;
  onSubmit: (updatedCase?: ReviewCaseWithDecisionData) => void;
}> = ({ reviewCase, flowSlug, onSubmit }) => {
  const { decisionHistory } = useCapabilities();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { workspace } = useWorkspaceContext();
  const { orgId } = useParamsDecode<DashboardPageParamsT>();

  const caseMutation = useReviewCaseMutation(
    workspace.base_url,
    flowSlug,
    reviewCase.id,
  );
  const caseSubmit = useReviewCaseSubmit(
    workspace.base_url,
    flowSlug,
    reviewCase.id,
  );

  const form = useForm<ReviewCaseResponseData>({
    defaultValues: reviewCase.response.data ?? undefined,
  });

  useEffect(() => {
    const erroredFields = Object.keys(form.formState.errors);
    if (erroredFields.length > 0 && !isConfirmationModalOpen) {
      const erroredElements = erroredFields
        .map((field) =>
          document.getElementById(`${MR_FORM_ELEMENTS_PREFIX}_${field}`),
        )
        .filter((element) => element !== null) as HTMLElement[];

      erroredElements[0]?.scrollIntoView({ behavior: "smooth" });
    }
  }, [form.formState.errors, isConfirmationModalOpen]);

  const saveMutateAsync = caseMutation.mutateAsync;
  const saveForm = useCallback(
    (data: ReviewCaseResponseData = form.getValues()) => {
      const responseData = cloneDeep(data);

      if (isEqual(responseData, reviewCase.response.data)) {
        return Promise.resolve(reviewCase);
      }

      return saveMutateAsync({
        response_data: responseData,
        etag: reviewCase.etag,
      });
    },
    [form, reviewCase, saveMutateAsync],
  );

  const [needsSave, setNeedsSave] = useState(false);

  const handleOnChange = useDebouncedCallback(() => {
    if (caseMutation.isPending) {
      setNeedsSave(true);
    } else {
      saveForm();
    }
  }, 1000);

  useEffect(() => {
    // Check if a save event was triggered while
    // the last save was in progress
    if (needsSave && !caseMutation.isPending) {
      setNeedsSave(false);
      saveForm();
    }
  }, [caseMutation.isPending, needsSave, saveForm]);

  const refineResponseData = (
    data: ReviewCaseResponseData,
  ): ReviewCaseResponseData => {
    const result: ReviewCaseResponseData = {};

    for (const [key, schemaProperty] of Object.entries(
      reviewCase.response.schema.properties,
    )) {
      const value = get(data, key);

      if (value === undefined) {
        continue;
      }

      // Empty strings should not make it to the final response
      // these empty strings come from empty input fields
      // and if we got to this point is because they are not required
      if (
        "type" in schemaProperty &&
        ["number", "integer", "string"].includes(schemaProperty.type) &&
        typeof value === "string" &&
        value.trim() === ""
      ) {
        continue;
      }
      // We don't validate on save so we have to delay the parsing of
      // input field values as numbers until we submit
      else if (
        "type" in schemaProperty &&
        ["number", "integer"].includes(schemaProperty.type)
      ) {
        result[key] = parseFloat(value as string);
      } else {
        result[key] = value;
      }
    }

    return result;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [needsSubmit, setNeedsSubmit] = useState<
    { status: false } | { status: true; onSubmitEnd: () => void }
  >({ status: false });

  const submitHandler: SubmitHandler<ReviewCaseResponseData> = async (data) => {
    setIsSubmitting(true);
    setNeedsSave(false);

    // We cancel pending saves as we'll
    // do a refined data save before submitting
    handleOnChange.cancel();

    if (caseMutation.isPending) {
      return new Promise<undefined>((resolve) => {
        setNeedsSubmit({ status: true, onSubmitEnd: () => resolve(undefined) });
      });
    }
    try {
      const updatedCase = await saveForm(refineResponseData(data));
      await caseSubmit.mutateAsync({
        etag: updatedCase.etag,
      });
      onSubmit(updatedCase);
    } catch (e) {
      if (isPreconditionError(e)) {
        await queryClient.invalidateQueries({
          queryKey: ["reviewCase", workspace.base_url, flowSlug, reviewCase.id],
        });
        toastActions.failure({
          title: `Failed to submit the case`,
          description: "Please try again",
        });
      } else if (isConflictError(e)) {
        toastActions.failure({
          title: `Failed to submit the case`,
          description: "This case has already been submitted",
        });
      } else if (isBadRequest(e)) {
        // This is fallback, it should not possible to reach this point
        // Because we should have the same validations on the frontend side
        toastActions.failure({
          title: "Review case validation failed",
          description:
            isObject(e.response?.data) && "detail" in e.response.data
              ? (e.response.data.detail as string)
              : "Unknown error",
        });
        logger.error("Unexpected review case validation failure", e);
      } else {
        toastActions.failure({
          title: `Failed to submit the case`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
      logger.error(e);
    } finally {
      setIsSubmitting(false);
      if (needsSubmit.status) {
        needsSubmit.onSubmitEnd();
      }
    }
  };

  const handleSubmit = form.handleSubmit(submitHandler);

  useEffect(() => {
    // Check if a submit event is waiting
    // for a save to finish
    if (needsSubmit.status && !caseMutation.isPending) {
      setNeedsSubmit({ status: false });
      handleSubmit();
    }
  }, [caseMutation.isPending, handleSubmit, needsSubmit.status]);

  const getError = (error: unknown) => {
    if (!error) return;

    if (isPreconditionError(error)) {
      return "Autosaving failed. Refresh to see the most recent state";
    }

    return "We couldn't save the last state of the form";
  };

  // Keep the relative, otherwise it creates overflow outside of the html element
  // even if the overflow is contained by the parent element
  return (
    <>
      <FormBase
        disabled={isSubmitting}
        error={getError(caseMutation.error)}
        form={form}
        schema={reviewCase.response.schema}
        onChange={handleOnChange}
        onClickSubmit={() => setIsConfirmationModalOpen(true)}
      />
      <ConfirmationModal
        cancelationButtonText="Cancel"
        confirmationButtonText="Submit"
        open={isConfirmationModalOpen}
        title="Submit manual review input?"
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={async () => {
          try {
            await handleSubmit();
          } finally {
            setIsConfirmationModalOpen(false);
          }
        }}
      >
        <p className="text-gray-500 font-inter-normal-13px">
          The input cannot be changed once the review is submitted.
          {decisionHistory.canAccess && (
            <>
              {" "}
              To further track this decision,{" "}
              <Link
                className="text-indigo-500"
                rel="noopener noreferrer"
                target="_blank"
                to={getUrlToHistoricDecisionFromCase(
                  orgId,
                  workspace.id,
                  reviewCase,
                )}
              >
                find it in the decision history
              </Link>
              .
            </>
          )}
        </p>
      </ConfirmationModal>
    </>
  );
};

const CompletedCaseContent: React.FC<{
  orgId: string;
  workspaceId: string;
  flowId: string;
  reviewCaseQuery: UseQueryResult<ReviewCaseWithDecisionData, Error>;
}> = ({ orgId, workspaceId, flowId, reviewCaseQuery }) => {
  const { decisionHistory } = useCapabilities();
  const { search } = useLocation();

  return (
    <div className="h-screen">
      <EmptyState
        actionButton={
          decisionHistory.canAccess
            ? {
                text: "Go to the decision history",
                icon: faWavePulse,
                linkTo: getUrlFlowDecisionHistory(orgId, workspaceId, flowId, {
                  decisionId: reviewCaseQuery.data?.decision.id,
                }),
              }
            : {
                text: "Go to the review queue",
                icon: faUserCircle,
                linkTo: getUrlToReviewQueue(orgId, workspaceId, flowId, search),
              }
        }
        dataLoc="completed-case"
        description={
          decisionHistory.canAccess && (
            <>
              To further track the related decision
              <br /> find it in the decision history
            </>
          )
        }
        headline={
          <>
            This Manual review item was
            <br /> already submitted
          </>
        }
        icon={faCheckCircle}
      />
    </div>
  );
};

const NotFoundCaseContent: React.FC<{
  orgId: string;
  workspaceId: string;
  flowId: string;
}> = ({ orgId, workspaceId, flowId }) => {
  const { search } = useLocation();

  return (
    <div className="h-screen">
      <EmptyState
        actionButton={{
          text: "Go to the review queue",
          icon: faUserCircle,
          linkTo: getUrlToReviewQueue(orgId, workspaceId, flowId, search),
        }}
        dataLoc="not-found-case"
        description={
          <>
            To see all manual review items, go to the
            <br /> review queue
          </>
        }
        headline={
          <>
            This Manual review item was
            <br /> not found
          </>
        }
        icon={faUserCircle}
      />
    </div>
  );
};
