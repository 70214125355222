import { useQuery, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { v4 as uuidV4 } from "uuid";

import {
  ExecutionMode,
  FlowRouterEndpoint,
  outgoingWebhooksApi,
} from "src/api/endpoints";
import { GenericObjectT } from "src/api/flowTypes";
import { EventType, ResourceType, Webhook } from "src/clients/flow-api";
import { queryClient } from "src/queryClient";
import { WEBHOOK_FLOW_SLUG } from "src/webhooks/HistorySidebar/HistorySidebar";

const queryKeys = {
  all: ["outgoingWebhooks"] as const,
  flow: (flowId: string) => [queryKeys.all, ResourceType.FLOW, flowId] as const,
};

export const BROWSER_EVENT_SOURCE = "browser";

export const useFlowOutgoingWebhooks = (flowId: string) => {
  return useQuery<Webhook[], AxiosError>({
    queryKey: queryKeys.flow(flowId),
    queryFn: async () =>
      (
        await outgoingWebhooksApi.listWebhooksApiV1WebhooksGet(
          ResourceType.FLOW,
          flowId,
        )
      ).data,
  });
};

export const usePutOutgoingWebhook = () => {
  return useMutation({
    mutationFn: async (webhook: Webhook) => {
      return (
        await outgoingWebhooksApi.setWebhookApiV1WebhooksKeyPut(
          webhook.key,
          webhook,
        )
      ).data;
    },
    onSuccess: (_returnData, createParams) => {
      if (createParams.resource_type === ResourceType.FLOW) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.flow(createParams.resource_id),
        });
      }
    },
  });
};

export const useDeleteOutgoingWebhook = () => {
  return useMutation({
    mutationFn: (webhook: Webhook) => {
      return outgoingWebhooksApi.deleteWebhooksApiV1WebhooksKeyDelete(
        webhook.key,
      );
    },
    onSuccess: (_returnData, params) => {
      if (params.resource_type === ResourceType.FLOW) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.flow(params.resource_id),
        });
      }
    },
  });
};

export const OutgoingWebhookTestDetailType = "WebhookTest" as const;
// See https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-events-structure.html
type WebhookInvocationData = {
  version: string;
  id: string;
  "detail-type": typeof OutgoingWebhookTestDetailType;
  source: string;
  account: string;
  /**
   * Datetime iso
   */
  time: string;
  region: string;
  resources: string[];
  detail: { event_version: string } & GenericObjectT;
};
const getTestInvocationData = (
  workspaceSlug: string,
): WebhookInvocationData => {
  const currentDateTime = new Date();
  currentDateTime.setMilliseconds(0);
  const dateISOString = currentDateTime.toISOString().replace(".000Z", "Z");
  return {
    version: "0",
    id: uuidV4(),
    "detail-type": OutgoingWebhookTestDetailType,
    source: BROWSER_EVENT_SOURCE,
    account: "",
    time: dateISOString,
    region: "",
    resources: [],
    detail: {
      event_version: "1.0",
      workspace: workspaceSlug,
    },
  };
};

// The real webhook types + the test one that can also
// appear in the history sidebar
export type WebhookHistoryEventType =
  | EventType
  | typeof OutgoingWebhookTestDetailType;

export type OutgoingWebhookResponse = {
  request_body?: GenericObjectT;
  request_headers: GenericObjectT;
  response_headers: GenericObjectT;
  response_body: GenericObjectT;
  status: number;
  source: string;
  "detail-type"?: WebhookHistoryEventType;
};

export const webhookTriggerNameMap: Record<WebhookHistoryEventType, string> = {
  DecisionOutcome: "Decision outcome",
  JobRunExecution: "Job Run execution",
  ManualReviewCaseCreation: "Manual Review Case creation",
  WebhookTest: "Test",
};

export const useTestOutgoingWebhook = () =>
  useMutation({
    mutationFn: (params: {
      baseUrl: string | undefined;
      webhookKey: string;
      workspaceSlug: string;
    }) => {
      if (params.baseUrl !== undefined) {
        return FlowRouterEndpoint.sandboxDecide<OutgoingWebhookResponse>({
          baseUrl: params.baseUrl,
          flowSlug: WEBHOOK_FLOW_SLUG,
          versionName: params.webhookKey,
          executionMode: ExecutionMode.SYNC,
          data: getTestInvocationData(params.workspaceSlug),
        });
      } else {
        throw Error("No base URL provided");
      }
    },
  });

export const useRetryOutgoingWebhook = () =>
  useMutation({
    mutationFn: (params: {
      baseUrl: string | undefined;
      webhookKey: string;
      workspaceSlug: string;
      original: OutgoingWebhookResponse;
    }) => {
      if (params.baseUrl !== undefined) {
        // See https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-events-structure.html
        const invocationData: WebhookInvocationData = {
          version: "0", // AWS Event version (not flow_version!)
          time: params.original.request_body?.time || "",
          detail: {
            ...params.original.request_body?.event,
            event_version: params.original.request_body?.version || "",
          },
          "detail-type": params.original.request_body?.type,
          id: uuidV4(),
          source: BROWSER_EVENT_SOURCE,
          region: "",
          account: "",
          resources: [],
        };
        return FlowRouterEndpoint.sandboxDecide<OutgoingWebhookResponse>({
          baseUrl: params.baseUrl,
          flowSlug: WEBHOOK_FLOW_SLUG,
          versionName: params.webhookKey,
          executionMode: ExecutionMode.SYNC,
          data: invocationData,
          entityId: params.original.request_body?.event?.decision_id,
        });
      } else {
        throw Error("No base URL provided");
      }
    },
  });
