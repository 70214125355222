import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";

import { Pill } from "src/base-components/Pill";
import { EntityDetailsTable } from "src/dataTable/EntityDetailsTable";
import {
  FloatingWindow,
  FloatingWindowProps,
} from "src/datasets/DatasetTable/FloatingWindow";
import { EntityDetailsSidePane } from "src/entities/entityView/EntityDetailsSidePane";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";
import { EntityResourceProperties } from "src/entities/queries";

type BaseProps = {
  name: string;
  schemaId: string;
  entityIcon?: IconProp;
  fullWidth?: boolean;
};

type EntityIdProps = BaseProps & {
  id: string;
  entityData?: never;
};

type EntityDataProps = BaseProps & {
  id?: never;
  entityData: EntityResourceProperties;
};

type Props = EntityIdProps | EntityDataProps;

type EntityDetailsFloatingWindowProps = Props &
  Omit<FloatingWindowProps, "title" | "children">;

export const EntityDetailsFloatingWindow: React.FC<
  EntityDetailsFloatingWindowProps
> = ({
  schemaId,
  entityIcon,
  name,
  id,
  entityData,
  ...floatingWindowProps
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <FloatingWindow
        draggable={true}
        height={"h-auto" as `h-${number}`}
        maximizable={false}
        pinnable={false}
        placement="auto"
        title={
          <LinkedEntityPill
            entityIcon={entityIcon}
            id={(entityData?.properties.id as string) ?? id}
            name={name}
            schemaId={schemaId}
          />
        }
        {...floatingWindowProps}
      >
        <div className="decideScrollbar mt-2 h-full max-h-[560px] overflow-hidden">
          {entityData ? (
            <EntityDetailsTable entityData={entityData} schemaId={schemaId} />
          ) : (
            <EntityDetailsSidePane
              entityId={id}
              schemaId={schemaId}
              showHeader={false}
            />
          )}
        </div>
      </FloatingWindow>
    </div>
  );
};

export const EntityDetailsWindowPill: React.FC<Props> = ({
  name,
  id,
  schemaId,
  entityIcon = faQuestionCircle,
  entityData,
}) => {
  const [pillHovered, setPillHovered] = useState<boolean>(false);
  const [windowHovered, setWindowHovered] = useState<boolean>(false);

  const shouldWindowBeOpen = pillHovered || windowHovered;

  const floatingWindowProps = entityData
    ? { entityData, id: undefined }
    : { id, entityData: undefined };

  return (
    <EntityDetailsFloatingWindow
      button={
        <div
          className="cursor-default"
          onMouseLeave={() => {
            setTimeout(() => {
              setPillHovered(false);
            }, 100);
          }}
          onMouseOver={() => {
            setPillHovered(true);
          }}
        >
          <Pill hovered={shouldWindowBeOpen} size="sm" variant="gray" maxWidth>
            <Pill.Icon icon={entityIcon} />
            <Pill.Text>{name}</Pill.Text>
          </Pill>
        </div>
      }
      entityIcon={entityIcon}
      isOpen={shouldWindowBeOpen}
      name={name}
      schemaId={schemaId}
      onMouseLeave={() => setWindowHovered(false)}
      onMouseOver={() => setWindowHovered(true)}
      {...floatingWindowProps}
    />
  );
};
