import { useMutation, useQueries, useQuery } from "@tanstack/react-query";

import { flowVersionReviewsApi } from "src/api/endpoints";
import { flowKeys } from "src/api/queries";
import "src/api/types";
import { changeHistoryKeys } from "src/changeHistory/queries";
import { ReviewDb, ReviewUpsert } from "src/clients/flow-api";
import { queryClient } from "src/queryClient";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";

const flowReviewKey = {
  all: ["flowReviews"] as const,
  flowVersion: (flowVersionId: string) =>
    [...flowReviewKey.all, flowVersionId] as const,
};

const fetchReview = wrapWithAxiosResponseErrorHandler(
  async (flowVersionId: string) => {
    const response =
      await flowVersionReviewsApi.getReviewApiV1ReviewsGet(flowVersionId);

    if (response.status === 204) {
      return null;
    }

    return response.data;
  },
);

export const useFlowVersionReview = (flowVersionId: string) => {
  return useQuery<ReviewDb | null, Error>({
    queryKey: flowReviewKey.flowVersion(flowVersionId!),
    queryFn: () => fetchReview(flowVersionId),
  });
};

export const useFlowVersionReviews = (flowVersionIds: string[]) =>
  useQueries({
    queries: flowVersionIds.map((id) => ({
      queryKey: flowReviewKey.flowVersion(id),
      queryFn: () => fetchReview(id),
    })),
  });

export const useFlowVersionReviewPatch = () => {
  return useMutation({
    mutationFn: wrapWithAxiosResponseErrorHandler(
      async ({
        reviewId,
        data,
      }: {
        reviewId: string;
        data: ReviewUpsert;
        flowId: string;
      }) => {
        const response =
          await flowVersionReviewsApi.patchReviewsApiV1ReviewsReviewIdPatch(
            reviewId,
            data,
          );
        return response.data;
      },
    ),
    onSettled: (response, _, requestData) => {
      // Data about the review state is saved denormalized in the flow so we need to refetch it
      queryClient.invalidateQueries({
        queryKey: flowKeys.detail(requestData.flowId),
      });

      if (response?.flow_version_id) {
        queryClient.invalidateQueries({
          queryKey: flowReviewKey.flowVersion(response.flow_version_id),
        });

        queryClient.invalidateQueries({
          queryKey: changeHistoryKeys.version(response.flow_version_id),
        });
      }
    },
  });
};
