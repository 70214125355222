import { useForm } from "react-hook-form";
import { v4 } from "uuid";

import { useCreateOrganization } from "src/adminPanel/queries";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { OrganizationCreate } from "src/clients/taktile-api";
import { Modal } from "src/design-system/Modal";

export const CreateOrganizationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const uuid4 = v4().split("-")[0];
  const form = useForm<Pick<OrganizationCreate, "name" | "principal">>({
    defaultValues: { principal: uuid4 },
  });
  const createOrganization = useCreateOrganization();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Create an Organization</Modal.Header>
      <form
        onSubmit={form.handleSubmit((data) => {
          createOrganization.mutate(data);
        })}
      >
        <Modal.Content>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center justify-between gap-2">
              <Label>Organization Display Name</Label>
              <Input
                {...form.register("name", { required: true })}
                placeholder="Organization name"
              />
            </div>
            <div className="flex flex-row items-center justify-between gap-2">
              <Label>
                <div>Organization principal</div>
                <div>
                  Note that this should not contain any revealing information
                  about the organization.
                </div>
              </Label>
              <Input
                {...form.register("principal", { required: true })}
                placeholder="Organization principal"
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button htmlType="submit" loading={createOrganization.isPending}>
              Create
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
