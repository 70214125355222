import { faCog } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { SchemaNullableSwitch } from "src/schema/SchemaNullableSwitch";
import { SchemaRequiredSwitch } from "src/schema/SchemaRequiredSwitch";
import {
  CommonSchemaEditRowPropsT,
  SchemaUIT,
} from "src/schema/schemaMappingUtils";
import { useOpenOnHover } from "src/utils/useOpenOnHover";

export const SchemaAdvancedOptions: React.FC<CommonSchemaEditRowPropsT> = ({
  rowName,
  disabled,
}) => {
  const hoverProps = useOpenOnHover<HTMLDivElement, HTMLDivElement>(50);

  const { control } = useFormContext<SchemaUIT>();
  const { type, required } = useWatch({ control, name: rowName });
  return (
    <div className="relative">
      <div
        onMouseEnter={hoverProps.onMouseEnter}
        onMouseLeave={hoverProps.onMouseLeave}
      >
        <Icon
          color={twJoin(
            !disabled && "cursor-pointer",
            "text-gray-500 hover:text-gray-700",
          )}
          dataLoc={`schema-field-advanced-trigger-${type[1]}-${required}`}
          icon={faCog}
          size="xs"
        />
      </div>
      {hoverProps.isOpen && (
        <div
          className="absolute -right-3 z-50 w-[9.5rem] rounded-lg bg-white px-3 py-4 text-gray-800 shadow-lg font-inter-normal-13px"
          data-loc="schema-field-advanced-popup"
          onMouseEnter={hoverProps.onMouseEnter}
          onMouseLeave={hoverProps.onMouseLeave}
        >
          <div className="mb-4 flex items-center justify-between">
            <span>Required</span>
            <SchemaRequiredSwitch disabled={disabled} rowName={rowName} />
          </div>
          <div className="flex items-center justify-between">
            <span>Nullable</span>
            <SchemaNullableSwitch disabled={disabled} rowName={rowName} />
          </div>
        </div>
      )}
    </div>
  );
};
