import { m } from "framer-motion";
import React, { useState } from "react";

import { Dataset, DatasetFileFormat } from "src/api/types";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  useCreateDownloadDatasetJob,
  useCreateDuplicateDatasetJob,
} from "src/datasets/api/queries";
import { separateFilenameExtension } from "src/datasets/utils";
import { toastActions } from "src/design-system/Toast/utils";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useSetEditDatasetId } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

type Props = {
  dataset: Dataset;
  onDelete: () => void;
  shouldShowEllipsis: boolean;
  disableDownloads: boolean;
};

const DOWNLOAD_PENDING_MSG = "There is a download in progress already";

export const ItemDropdown: React.FC<Props> = ({
  dataset,
  onDelete,
  shouldShowEllipsis,
  disableDownloads,
}) => {
  const { workspace, flow } = useAuthoringContext();
  const { name: fileName } = separateFilenameExtension(dataset.name);
  const { datasets } = useCapabilities();
  const { mutateAsync: downloadFile, isPending: creatingDownloadJob } =
    useCreateDownloadDatasetJob(workspace.base_url!);

  const { mutateAsync: duplicate } = useCreateDuplicateDatasetJob(
    workspace.base_url!,
  );

  const setEditDatasetId = useSetEditDatasetId();

  const handleDownload = async (format: DatasetFileFormat) => {
    try {
      const job = await downloadFile({
        flow_id: flow.id,
        request: { dataset_id: dataset.id, format },
      });
      toastActions.loading({
        id: job.id,
        title: "Preparing your download...",
        description:
          "This could take a few minutes. The process will continue in the background even when this notification is dismissed.",
        duration: Infinity,
      });
    } catch (err) {
      logger.error("Failed to create download dataset job", err);
      toastActions.failure({
        title: `Something went wrong when downloading ${fileName}`,
      });
    }
  };

  const handleDuplicate = async () => {
    try {
      await duplicate({
        flow_id: flow.id,
        request: {
          source_dataset_id: dataset.id,
          new_dataset_name: dataset.name,
        },
      });
    } catch (err) {
      logger.error("Failed to duplicate dataset", err);
      toastActions.failure({
        title: `Something went wrong when duplicating ${fileName}`,
      });
    }
  };

  const [optionsAreOpen, setOptionsAreOpen] = useState(false);

  const renderOptions = shouldShowEllipsis || optionsAreOpen;

  return (
    <>
      {renderOptions && (
        <m.div
          animate={{
            opacity: 1,
            transition: {
              type: "tween",
              ease: "easeOut",
              delay: 0.15,
            },
          }}
          exit={{ opacity: 0, transition: { duration: 0.08 } }}
          initial={{ opacity: 0 }}
          transition={{ type: "tween", ease: "easeOut" }}
          layout
        >
          <EllipsisOptionsDropdown
            buttonDataLoc={`dataset-item-${dataset.name}-options`}
            elements={[
              { key: "Edit", action: () => setEditDatasetId(dataset.id) },
              {
                key: "Download as JSON",
                action: () => handleDownload("json"),
                disabled:
                  disableDownloads || creatingDownloadJob
                    ? DOWNLOAD_PENDING_MSG
                    : false,
              },
              {
                key: "Download as CSV",
                action: () => handleDownload("csv"),
                disabled:
                  disableDownloads || creatingDownloadJob
                    ? DOWNLOAD_PENDING_MSG
                    : false,
              },
              {
                key: "Download as XLSX",
                action: () => handleDownload("xlsx"),
                disabled:
                  disableDownloads || creatingDownloadJob
                    ? DOWNLOAD_PENDING_MSG
                    : false,
              },
              { key: "Duplicate", action: handleDuplicate },
              datasets.canDelete && { key: "Delete", action: onDelete },
            ].filter(Boolean as unknown as ExcludesFalse)}
            iconSize="xs"
            onOpenChanged={setOptionsAreOpen}
          />
        </m.div>
      )}
    </>
  );
};
