import { faArrowTurnDownRight } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "src/base-components/Icon";

type FieldAccessInfoProps = {
  schemaName: string;
  inputType: "event" | "entity";
};

export const FieldAccessInfo: React.FC<FieldAccessInfoProps> = ({
  schemaName,
  inputType,
}) => {
  return (
    <div className="mb-4 ml-4 mt-2 flex gap-x-1">
      <Icon color="text-gray-500" icon={faArrowTurnDownRight} size="sm" />
      <p className="font-inter-normal-12px">
        You can access this {inputType} in your decision flow via{" "}
        <span className="text-indigo-600">data.</span>
        <span className="text-green-600">{schemaName}</span> and global features
        via <span className="text-indigo-600">data.</span>
        <span className="text-green-600">features.</span>
      </p>
    </div>
  );
};
