import {
  faXmark,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faBook,
} from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  useAiNodeQuotas,
  useConnection,
  useProviderManifest,
} from "src/api/connectApi/queries";
import { ConnectionT, ResourceConfigT } from "src/api/connectApi/types";
import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { BetaPill } from "src/base-components/BetaPill";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { ResourceType } from "src/clients/flow-api/api";
import { isBetaConnection } from "src/connections/CreateConnectionGrid";
import {
  IntegrationNode,
  BeMappedNode,
  ManifestConnectionNode,
} from "src/constants/NodeDataTypes";
import { getNodeIconFromNode } from "src/constants/NodeIcons";
import { getNodeTitle } from "src/constants/NodeProperties";
import { NAMED_NODE_TYPES, NODE_TYPE } from "src/constants/NodeTypes";
import { CopilotButton } from "src/copilot/CopilotButton";
import { useCopilotWindow } from "src/copilot/useCopilotWindow";
import { getIsCopilotEnabled } from "src/copilot/utils";
import { MOCK_EXTERNAL_DATA_DOCS_URL } from "src/datasets/utils";
import { Callout } from "src/design-system/Callout";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { EditorLock } from "src/multiplayer/EditorLock";
import { CreateChildDatasetPill } from "src/nodeEditor/CreateChildDatasetPill";
import { NodeTitleEditor } from "src/nodeEditor/NodeTitleEditor";
import { SidePaneHelpButton } from "src/nodeEditor/SidePaneHelpButton";
import { useChildVersionLinkFromNode } from "src/parentFlowNodes/useGetChildVersionLink";
import {
  useAuthoringContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import { getBaseUrl, getWsUrl } from "src/router/urls";
import { useGraphStore } from "src/store/StoreProvider";
import { getNodeRunState } from "src/store/runState/RunState";
import { isAiNode, isParentNode } from "src/utils/predicates";

type ManifestConnectionDisplayedTitlePropsT = {
  provider: string;
  manifestVersion?: string;
  resourceConfig?: ResourceConfigT;
};

const ManifestConnectionDisplayedTitle: React.FC<
  ManifestConnectionDisplayedTitlePropsT
> = ({ provider, manifestVersion, resourceConfig }) => {
  const { workspace } = useWorkspaceContext();

  const manifest = useProviderManifest(
    workspace.base_url,
    provider,
    manifestVersion,
  ).data;
  return (
    <>
      {manifest?.display_name || provider}
      <span className="text-3xs"> • </span>
      {resourceConfig?.name}
    </>
  );
};

const getNodeTitleFromSelectedNode = (
  selectedNode: BeMappedNode,
  manifest?: ManifestIntegrationProvider,
): string | undefined => {
  if (
    NAMED_NODE_TYPES.includes(
      selectedNode.type as (typeof NAMED_NODE_TYPES)[number],
    )
  ) {
    const nodeType = selectedNode.type as (typeof NAMED_NODE_TYPES)[number];
    if (
      nodeType === NODE_TYPE.INTEGRATION_NODE ||
      nodeType === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE
    ) {
      return getNodeTitle({
        nodeType: nodeType,
        provider: (selectedNode as IntegrationNode).data.providerResource
          .provider,
      });
    }
    if (nodeType === NODE_TYPE.MANIFEST_CONNECTION_NODE) {
      return (
        manifest?.display_name ||
        (selectedNode as ManifestConnectionNode).data.providerResource.provider
      );
    } else {
      return getNodeTitle({ nodeType: nodeType });
    }
  }
};

type PropsT = {
  selectedNode: BeMappedNode;
  onCloseButtonClick: () => void;
  onToggleFullscreen?: () => void;
  isFullscreen: boolean;
};

const getApiDocsLink = (selectedNode: BeMappedNode, wsBaseUrl?: string) => {
  if (!wsBaseUrl) return;

  if (selectedNode.type === NODE_TYPE.REVIEW_CONNECTION_NODE) {
    return `https://${wsBaseUrl}/review/api/v1/docs`;
  }
};

export const getDisplayedTitle = (
  node: BeMappedNode,
  connection?: ConnectionT,
) => {
  if (
    node.type !== NODE_TYPE.INTEGRATION_NODE &&
    node.type !== NODE_TYPE.MANIFEST_CONNECTION_NODE &&
    node.type !== NODE_TYPE.WEBHOOK_CONNECTION_NODE &&
    node.type !== NODE_TYPE.SQL_DATABASE_CONNECTION_NODE &&
    node.type !== NODE_TYPE.CUSTOM_CONNECTION_NODE
  ) {
    return getNodeTitleFromSelectedNode(node);
  }
  // Display the resource config name if the node is an integration node
  let resourceConfig: ResourceConfigT | undefined;
  if (connection) {
    if (node.type === NODE_TYPE.CUSTOM_CONNECTION_NODE) {
      return <span>Custom Connection • {connection.name}</span>;
    }
    resourceConfig = connection.resource_configs.find(
      (rc) => rc.id === (node as IntegrationNode).data.resourceConfigId,
    );

    if (node.type === NODE_TYPE.MANIFEST_CONNECTION_NODE) {
      const provider = (node as ManifestConnectionNode).data.providerResource
        .provider;
      const manifestVersion = (node as ManifestConnectionNode).data
        .providerResource.manifest_version;
      return (
        <ManifestConnectionDisplayedTitle
          manifestVersion={manifestVersion}
          provider={provider}
          resourceConfig={resourceConfig}
        />
      );
    }
  }

  return (
    <>
      {getNodeTitleFromSelectedNode(node)}
      {resourceConfig?.name && (
        <>
          <span className="text-3xs"> • </span>
          <span>{resourceConfig?.name}</span>
        </>
      )}
    </>
  );
};

export const SidePaneHeader: React.FC<PropsT> = observer(
  ({ selectedNode, onCloseButtonClick, onToggleFullscreen, isFullscreen }) => {
    const { integrationNodes } = useGraphStore();
    const { version } = useAuthoringContext();
    const { orgId, workspace } = useAuthoringContext();
    const runState = getNodeRunState(selectedNode.id, version.id);
    const connection = useConnection({
      baseUrl: workspace.base_url,
      id: (selectedNode as IntegrationNode).data?.connectionId,
    });

    const aiNodeQuotas = useAiNodeQuotas(workspace.base_url, {
      enabled: isAiNode(selectedNode),
    });

    const apiDocsLink = getApiDocsLink(selectedNode, workspace.base_url);
    const childVersionUrl = useChildVersionLinkFromNode(
      selectedNode,
      orgId,
      workspace.id,
    );

    const { openCopilot } = useCopilotWindow();

    const { lockOwner, lockedByOtherUser } = useVersionResourceLock(
      ResourceType.NODE,
      selectedNode?.id,
    );

    const isBetaIntegrationNode = () => {
      if (selectedNode.type === NODE_TYPE.MANIFEST_CONNECTION_NODE) {
        // TODO INT-2765 - Get beta status from manifest
        return true;
      } else if (selectedNode.type === NODE_TYPE.INTEGRATION_NODE) {
        const provider = (selectedNode as IntegrationNode).data.providerResource
          .provider;
        return isBetaConnection(provider);
      }
      return false;
    };

    const hasNodeIcon = ![NODE_TYPE.INPUT_NODE, NODE_TYPE.OUTPUT_NODE].includes(
      selectedNode.type,
    );

    const isDuplicateIntegrationNode = useMemo(() => {
      if (
        selectedNode.type === NODE_TYPE.INTEGRATION_NODE ||
        selectedNode.type === NODE_TYPE.MANIFEST_CONNECTION_NODE ||
        selectedNode.type === NODE_TYPE.WEBHOOK_CONNECTION_NODE ||
        selectedNode.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE ||
        selectedNode.type === NODE_TYPE.CUSTOM_CONNECTION_NODE ||
        selectedNode.type === NODE_TYPE.AI_NODE_V2
      ) {
        return integrationNodes.some(
          (node) =>
            node.id !== selectedNode.id &&
            node.data.label === selectedNode.data.label,
        );
      }
      return false;
    }, [integrationNodes, selectedNode]);

    const connectionUrl = `${getBaseUrl()}${getWsUrl(orgId, workspace.id)}settings/connections?connectionId=${connection.data?.id}`;

    const capabilities = useCapabilities();
    const canAccessConnections = capabilities.connections.canAccess;

    return (
      <div className="pl-6 pr-4">
        <div className="relative flex items-center justify-between pb-2">
          {hasNodeIcon && (
            <div className="h-12 w-12 shrink-0">
              <div className="h-full w-full">
                {getNodeIconFromNode(selectedNode, "lg")}
              </div>
            </div>
          )}
          <div className="grow overflow-hidden pl-3 pr-4 pt-0.5">
            <div className="justify-left flex flex-row items-center text-gray-500 font-inter-normal-12px">
              <div className="flex">
                <div className="shrink-0">
                  {getDisplayedTitle(selectedNode, connection.data)}
                </div>
                {connection.data?.is_sandbox && (
                  <div className="ml-2 flex">
                    <Pill size="sm" variant="yellow">
                      Sandbox connection
                    </Pill>
                  </div>
                )}
                {isBetaIntegrationNode() && (
                  <div className="ml-2 flex">
                    <BetaPill tooltipPlacement="bottom" />
                  </div>
                )}
                {lockOwner && lockedByOtherUser && (
                  <div className="max-w-122">
                    <EditorLock lockOwner={lockOwner} />
                  </div>
                )}
              </div>
            </div>
            <NodeTitleEditor key={selectedNode?.id} />
          </div>
          <div className="flex items-center gap-x-2 self-start">
            {getIsCopilotEnabled(selectedNode) && (
              <CopilotButton
                disabled={lockedByOtherUser}
                onClick={() => openCopilot()}
              />
            )}
            {connection.data?.id && canAccessConnections && (
              <Tooltip
                placement="bottom-end"
                title="Open Connection in new tab"
              >
                <ExternalLink
                  className="text-gray-500"
                  data-loc="open-connection-button"
                  href={connectionUrl}
                >
                  <Icon icon={faUpRightFromSquare} size="2xs" />
                </ExternalLink>
              </Tooltip>
            )}
            {isAiNode(selectedNode) && (
              <Tooltip
                body={
                  <div className="flex flex-col gap-y-2 text-gray-300 font-inter-normal-12px">
                    <p>
                      The following limits apply to AI Nodes across your
                      workspace:
                    </p>
                    <ul className="ml-4 list-disc">
                      <li>
                        Monthly limit:{" "}
                        <b className="text-gray-100">
                          {aiNodeQuotas.data?.global.used} /{" "}
                          {aiNodeQuotas.data?.global.quota} invocations
                        </b>
                      </li>
                      <li>
                        Test runs:{" "}
                        <b className="text-gray-100">Maximum of 10 rows</b>
                      </li>
                    </ul>
                  </div>
                }
                placement="bottom-end"
                title="AI Node usage limits"
              >
                <div className="text-gray-800 font-inter-semibold-13px">
                  Usage limits
                </div>
              </Tooltip>
            )}
            {apiDocsLink && (
              <Tooltip placement="top" title="View API documentation">
                <a href={apiDocsLink} rel="noreferrer" target="_blank">
                  <Icon
                    color="text-gray-500 hover:text-gray-700"
                    icon={faBook}
                    size="xs"
                  />
                </a>
              </Tooltip>
            )}
            <SidePaneHelpButton selectedNode={selectedNode} />
            {childVersionUrl && (
              <Tooltip placement="bottom-end" title="Open in new tab">
                <ExternalLink className="text-gray-500" href={childVersionUrl}>
                  <Icon icon={faUpRightFromSquare} size="xs" />
                </ExternalLink>
              </Tooltip>
            )}
            {onToggleFullscreen && !isFullscreen && (
              <Icon
                color="text-gray-500 hover:text-gray-700"
                icon={faArrowUpRightAndArrowDownLeftFromCenter}
                size="2xs"
                onClick={onToggleFullscreen}
              />
            )}
            <Icon
              color="text-gray-500 hover:text-gray-700"
              dataLoc="close-node-editor"
              icon={faXmark}
              size="xs"
              onClick={onCloseButtonClick}
            />
          </div>
        </div>
        {isDuplicateIntegrationNode && (
          <Callout
            action={{
              text: "Read more",
              onClick: () => window.open(MOCK_EXTERNAL_DATA_DOCS_URL, "_blank"),
            }}
            type="warning"
          >
            Duplicate node name detected. Integration nodes with the same name
            will use the same mock data during test runs. Consider choosing a
            unique name.
          </Callout>
        )}
        {selectedNode &&
          isParentNode(selectedNode) &&
          runState?.type === "test-run" && (
            <CreateChildDatasetPill
              nodeData={selectedNode.data}
              testRunResult={runState.testResult}
            />
          )}
        <div className="mt-2 h-px w-full rounded-full bg-gray-100"></div>
      </div>
    );
  },
);
