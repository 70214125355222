import { faCircleNotch, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { times } from "lodash";
import { useCallback, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { DecisionEnvironment } from "src/api/types";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { Icon } from "src/base-components/Icon";
import { SearchBox } from "src/base-components/SearchBox";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { DropdownItem } from "src/design-system/Dropdown";
import { EmptyState } from "src/design-system/EmptyState";

type ResourceSearchFilters = {
  environment: DecisionEnvironment;
  searchQuery: string;
};

type ResourceSearchItem = {
  label: string;
  key: string;
  isFetching: boolean;
};

type ResourceSearchPaneProps = {
  items: ResourceSearchItem[];
  resourceName: string;
  isLoading: boolean;
  filters: ResourceSearchFilters;
  onChangeFilters: (filters: ResourceSearchFilters) => void;
  onSelect: (item: ResourceSearchItem) => void;
  searchPlaceholder: string;
};

export const ResourceSearchPane: React.FC<ResourceSearchPaneProps> = ({
  items,
  resourceName,
  isLoading = false,
  filters,
  onChangeFilters,
  onSelect,
  searchPlaceholder,
}) => {
  const [searchQuery, setSearchQuery] = useState(filters.searchQuery);
  const _onChangeFilters = useCallback(
    (searchQuery: string) => {
      onChangeFilters({
        ...filters,
        searchQuery,
      });
    },
    [onChangeFilters, filters],
  );
  const debouncedChangeSearchQuery = useDebounceCallback(_onChangeFilters, 250);

  return (
    <div className="flex flex-col">
      <div className="px-4 py-2">
        <SearchBox
          placeholder={searchPlaceholder}
          value={searchQuery}
          autoFocus
          onChange={(value) => {
            setSearchQuery(value);
            debouncedChangeSearchQuery(value);
          }}
        />
      </div>
      <div className="flex justify-between gap-2 px-4 py-2.5">
        <div className="text-gray-800 font-inter-semibold-13px">
          {resourceName}
        </div>
        <div>
          <EnvironmentPill
            value={filters.environment}
            onChange={(env) => {
              onChangeFilters({
                ...filters,
                environment: env,
              });
            }}
          />
        </div>
      </div>
      <div className="pb-4">
        <ResourceItemsList
          isLoading={isLoading}
          items={items}
          resourceName={resourceName}
          searchQuery={filters.searchQuery}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};

const ResourceItemsList: React.FC<{
  isLoading?: boolean;
  items: ResourceSearchItem[];
  searchQuery: string;
  resourceName: string;
  onSelect: (item: ResourceSearchItem) => void;
}> = ({ items, isLoading, searchQuery, resourceName, onSelect }) => {
  if (isLoading) {
    return times(5, (i) => <ResourceItem key={i} isLoading />);
  }

  if (items.length === 0) {
    return (
      <EmptyState
        description={
          searchQuery.length > 0
            ? "Try different search query"
            : `No ${resourceName} records available`
        }
        headline={`No ${resourceName} found`}
        icon={faSearch}
      />
    );
  }

  return items.map((item) => (
    <ResourceItem
      key={item.key}
      isFetching={item.isFetching}
      onClick={() => onSelect(item)}
    >
      {item.label}
    </ResourceItem>
  ));
};

export const ResourceItem: React.FC<{
  children?: React.ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  isFetching?: boolean;
}> = ({ children, isLoading = false, onClick, isFetching }) => (
  <DropdownItem onClick={onClick}>
    {isLoading ? <SkeletonPlaceholder height="h-5" width="w-42" /> : children}
    {isFetching && <Icon icon={faCircleNotch} size="xs" spin />}
  </DropdownItem>
);
