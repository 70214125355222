import { faTimer } from "@fortawesome/pro-regular-svg-icons";
import { noop } from "lodash";
import { Navigate } from "react-router-dom";

import { EmptyState } from "src/design-system/EmptyState";
import { EventsSubHeader } from "src/eventsCatalogue/EventsSubHeader";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { WhitePane } from "src/jobs/common/WhitePane";
import { PageHeader } from "src/layout/PageHeader";
import { DashboardPageParamsT, getUrlToEventsPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const EventsCatalogueIndex = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { data: eventTypes, isSuccess, isLoading } = useEventSchemas();

  if (isLoading) {
    return null;
  }

  if (isSuccess && eventTypes.length) {
    // If at least one event type is available, redirect to the first event type
    return (
      <Navigate
        to={getUrlToEventsPage({
          orgId,
          wsId,
          eventType: eventTypes?.at(0)?.event_type,
        })}
      />
    );
  }

  return (
    <div>
      <PageHeader title="Events" />
      <EventsSubHeader
        eventTypes={eventTypes ?? []}
        onRefresh={noop}
        onSearchQueryChange={noop}
      />
      <div className="p-5">
        <WhitePane>
          <EmptyState
            dataLoc="events-empty-state"
            description="Request events creation from Taktile team"
            headline="No events found"
            icon={faTimer}
          />
        </WhitePane>
      </div>
    </div>
  );
};
