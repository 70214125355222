import React from "react";

import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import { ExtendedDecisionV2 } from "src/decisionsOverview/DecisionsSidepaneWrapper";
import { ErrorTabContent } from "src/decisionsOverview/ErrorTabContent";
import { PendingOutputTabContent } from "src/decisionsOverview/PendingOutputTabContent";

type OutputTabContentProps = {
  decision: HistoricalDecisionV3 | null;
  decisionV2?: ExtendedDecisionV2;
};

export const OutputTabContent: React.FC<OutputTabContentProps> = ({
  decision,
  decisionV2,
}) => {
  const isPendingDecision = decision?.status_code === "202";

  if (decision?.status_code === "200" && !decisionV2?.is_error) {
    return <DecisionFields decisionId={decision.id} type="response" />;
  }

  if (isPendingDecision && decisionV2) {
    return <PendingOutputTabContent decision={decisionV2} />;
  }

  if (decisionV2?.is_error) {
    return <ErrorTabContent decision={decisionV2} />;
  }

  return null;
};
