import { PillToggle } from "src/base-components/PillToggle";

export enum PerformanceSource {
  API = "api",
  JOBS = "jobs",
}

type PerformanceSourcePillProps = {
  value: PerformanceSource;
  onChange: (source: PerformanceSource) => void;
};

export const PerformanceSourcePill: React.FC<PerformanceSourcePillProps> = ({
  value,
  onChange,
}) => {
  return (
    <PillToggle value={value} onChange={onChange}>
      <PillToggle.Button value="api">API</PillToggle.Button>
      <PillToggle.Button value="jobs">Jobs</PillToggle.Button>
    </PillToggle>
  );
};
