import { clamp } from "lodash";
import React, { createContext, useContext, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

type SidepaneContextType = {
  width: number;
  ref: React.RefObject<HTMLDivElement>;
  ResizeHandler: () => JSX.Element;
  isResizing: boolean;
};

const SidepaneContext = createContext<SidepaneContextType | undefined>(
  undefined,
);

export const useSidepaneContext = () => {
  const context = useContext(SidepaneContext);
  if (!context) {
    throw new Error(
      "useSidepaneContext must be used within a SidepaneProvider",
    );
  }
  return context;
};

export const SidepaneProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const resizeHandle = useRef<HTMLButtonElement | null>(null);
  const minWidth = 420;
  const maxWidth = 800;
  const [width, setWidth] = useState<number>(minWidth);
  const [isResizing, setIsResizing] = useState(false);

  useEventListener("mousedown", (event: MouseEvent) => {
    if (ref.current && resizeHandle.current === event.target) {
      setIsResizing(true);
    }
  });

  useEventListener("mousemove", (event: MouseEvent) => {
    if (ref.current && isResizing) {
      setWidth(clamp(window.innerWidth - event.clientX, minWidth, maxWidth));
    }
  });

  useEventListener("mouseup", () => {
    if (isResizing) {
      setIsResizing(false);
    }
  });

  const ResizeHandler = () => (
    <button
      ref={resizeHandle}
      className="absolute bottom-0 left-0 h-full w-1 cursor-col-resize"
    />
  );

  return (
    <SidepaneContext.Provider value={{ width, ref, ResizeHandler, isResizing }}>
      {children}
    </SidepaneContext.Provider>
  );
};
