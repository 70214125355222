import {
  acceptCompletion,
  autocompletion,
  Completion,
  CompletionContext,
  completionKeymap,
  CompletionSection,
  completionStatus,
} from "@codemirror/autocomplete";
import {
  Extension,
  keymap,
  Prec,
  tooltips,
  ViewPlugin,
  ViewUpdate,
} from "@uiw/react-codemirror";
import { useMemo } from "react";
import { createRoot } from "react-dom/client";
import { twJoin } from "tailwind-merge";

import {
  NoAutocompleteDataStatus,
  OutdatedAutocompleteDataStatus,
} from "src/base-components/CodeInput/Autocomplete/AutocompleteStatusContainer";
import {
  getCompletionSource,
  startCompletions,
} from "src/base-components/CodeInput/Autocomplete/getCompletionSource";
import { dollarInvoker } from "src/base-components/CodeInput/CodeEditor";
import { isDataPrefixed } from "src/base-components/CodeInput/CodeInput";
import { isFieldInLoopIteration } from "src/base-components/CodeInput/EditorCodeInput";
import { Pill } from "src/base-components/Pill";
import { getIconFromPythonType } from "src/base-components/TypeIcons";
import {
  EntityTypePrefix,
  EventTypePrefix,
} from "src/dataTable/typingDecoding";
import {
  useWorkspaceFeatureGates,
  WorkspaceFeatureGates,
} from "src/hooks/useWorkspaceFeatureGates";
import {
  getCompletionData,
  getSchemaCompletionData,
  NoCompletionDataAvailable,
  useNodeCompletionData,
  useSchemaCompletionData,
} from "src/nodeEditor/api/queries";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

const completionSectionClass = "completion-section" as const;

const HIDDEN_AUTOCOMPLETION_SUGGESTION_ID = "hidden" as const;
const HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME =
  "hidden-completion-mrP2X8" as const;

const getHeader = (section: CompletionSection) => {
  const container = document.createElement("div");
  const root = createRoot(container);
  root.render(<SectionHeader name={section.name} />);
  return container;
};

const SECTIONS: Record<string, CompletionSection> = {
  entities: {
    name: "Entities",
    rank: 1,
    header: getHeader,
  },
  events: {
    name: "Events",
    rank: 2,
    header: getHeader,
  },
  properties: {
    name: "Properties",
    rank: 3,
    header: getHeader,
  },
  features: {
    name: "Features",
    rank: 4,
    header: getHeader,
  },
} as const;

const isCompletionSection = (
  section: string | CompletionSection,
): section is CompletionSection =>
  typeof section === "object" && section !== null && "name" in section;

const getNumberOfSectionOptions = (sectionName: string) =>
  document.querySelectorAll(`.${completionSectionClass}-${sectionName}`).length;

const getSectionCounts = () => {
  const counts = new Map<string, number>();
  Object.values(SECTIONS).forEach((section) => {
    counts.set(section.name, getNumberOfSectionOptions(section.name));
  });
  return counts;
};

const SectionHeader: React.FC<{
  name: string;
}> = ({ name }) => {
  const sectionCounts = getSectionCounts();
  const count = sectionCounts.get(name) ?? 0;

  // Find the first section with count > 0 by rank order
  const firstActiveSectionName = Object.values(SECTIONS)
    .sort((a, b) => (a.rank as number) - (b.rank as number))
    .find((section) => (sectionCounts.get(section.name) ?? 0) > 0)?.name;

  return (
    <div
      className={twJoin(
        completionSectionClass,
        "flex justify-between px-3 py-2",
        name !== firstActiveSectionName && "border-t border-gray-200",
      )}
    >
      <p className="font-inter-medium-12px">{name}</p>
      <Pill size="sm" variant="gray">
        <Pill.Text fontType="code">{count}</Pill.Text>
      </Pill>
    </div>
  );
};

const organizeCompletionOptions = (
  options: readonly Completion[],
  featureGates: WorkspaceFeatureGates,
) => {
  // First filter out entity_id if the feature flag is enabled
  const filteredOptions = options.filter(
    (option) => option.label !== "entity_id",
  );

  // Then organize the remaining options into sections
  return filteredOptions.map((option) => {
    let section;
    switch (true) {
      case option.label === "features" && featureGates.featuresEventsEnabled:
        section = SECTIONS.features;
        break;
      case option.type?.startsWith(EntityTypePrefix):
        section = SECTIONS.entities;
        break;
      case option.type?.startsWith(EventTypePrefix) &&
        featureGates.featuresEventsEnabled:
        section = SECTIONS.events;
        break;
      default:
        section = SECTIONS.properties;
    }

    return { ...option, section };
  });
};

const getTypePill: React.FC<{ type: string }> = ({ type }) => {
  const typeIcon = getIconFromPythonType(type);
  return (
    <Pill dataLoc="type-pill" size="sm" variant="gray">
      <div className="flex flex-row items-center gap-0.5">
        {typeIcon && <Pill.Icon icon={typeIcon} padding={false} />}
        <Pill.Text fontType="code">{type}</Pill.Text>
      </div>
    </Pill>
  );
};

const buildAutocompleteExtension = (
  versionId: string,
  nodeId: string | undefined,
  featureGates: WorkspaceFeatureGates,
): Extension => {
  const getTypingInfo = () => {
    if (nodeId === undefined) return undefined;
    const completionData = getCompletionData(versionId, nodeId);
    const schemaCompletionData = isFeatureFlagEnabled(
      FEATURE_FLAGS.autocomplete,
    )
      ? getSchemaCompletionData(versionId)
      : undefined;

    const hasCompletionData =
      completionData !== undefined &&
      completionData !== NoCompletionDataAvailable;
    const hasSchemaCompletionData =
      schemaCompletionData !== undefined &&
      schemaCompletionData !== NoCompletionDataAvailable;

    if (hasCompletionData && hasSchemaCompletionData) {
      return {
        ...completionData,
        ...schemaCompletionData,
        properties: {
          ...completionData?.properties,
          data: {
            ...completionData?.properties?.data,
            ...schemaCompletionData?.properties?.data,
          },
        },
      };
    }

    if (hasSchemaCompletionData) {
      return schemaCompletionData;
    }

    if (hasCompletionData) {
      return completionData;
    }

    return undefined;
  };

  const customCompletionKeymap = [
    ...completionKeymap,
    { key: "Tab", run: acceptCompletion },
  ];
  const completionKeymapExtension = Prec.highest(
    keymap.of(customCompletionKeymap),
  );

  return [
    // This is needed to make the autocompletion work on the fullscreen node editors as well
    tooltips({
      position: "fixed",
      parent: document.body,
    }),
    completionKeymapExtension,
    autocompletion({
      // We provide a custom keymap
      defaultKeymap: false,
      icons: false,
      override: [
        // The completion source that provides the actual completions
        (context: CompletionContext) => {
          const result = getCompletionSource(
            getTypingInfo,
            !!context.state.field(isDataPrefixed, false),
            !!context.state.field(isFieldInLoopIteration, false),
            context.state.field(dollarInvoker, false),
          )(context);

          if (!result) return null;

          return {
            ...result,
            options:
              isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
              featureGates.entitiesEnabled
                ? organizeCompletionOptions(result.options, featureGates)
                : result.options,
          };
        },
        // The completion source that provides the information that the flow needs to be run first for autocompletion to work
        (context: CompletionContext) => {
          if (nodeId) {
            const typingInfo = getCompletionData(versionId, nodeId);
            const fieldIsEmpty = context.matchBefore(/^$/);
            // Show this information if we are sure that there is NoDataAvaiable and the field is empty or if the user explicitly requested the completion
            if (
              typingInfo === NoCompletionDataAvailable &&
              (fieldIsEmpty || context.explicit)
            ) {
              // Return a static completion result using the hidden completion id which will be used to open the tooltip but then be hidden via css
              return {
                from: context.pos,
                options: [
                  {
                    label: HIDDEN_AUTOCOMPLETION_SUGGESTION_ID,
                    detail: HIDDEN_AUTOCOMPLETION_SUGGESTION_ID,
                    apply: () => {},
                  },
                ],
              };
            }
          }
          return null;
        },
      ],
      optionClass: (completion) => {
        // Hide the completion if it is the no autocomplete data completion
        if (
          completion.label === HIDDEN_AUTOCOMPLETION_SUGGESTION_ID &&
          completion.detail === HIDDEN_AUTOCOMPLETION_SUGGESTION_ID
        ) {
          return `!h-0 invisible ${HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME}`;
        }

        // We want to color special keywords differently
        const isKeywordCompletion = [...startCompletions].some(
          (base) => completion.label === base.label,
        );

        if (isKeywordCompletion) {
          return "!text-indigo-600";
        }
        return "!text-green-600";
      },
      addToOptions: [
        {
          render: (completion) => {
            if (!completion.type) return null;

            const typePill = getTypePill({ type: completion.type });
            const container = document.createElement("div");

            if (completion.section && isCompletionSection(completion.section)) {
              container.classList.add(
                `${completionSectionClass}-${completion.section.name}`,
              );
            }
            // Hack to avoid losing focus on code editor,
            // since suggestions are outside of table container
            container.addEventListener("click", (e) => e.preventDefault());
            document.body.appendChild(container);
            const root = createRoot(container);
            root.render(typePill);
            return container;
          },
          // Behind the completion name (pos 50)
          position: 60,
        },
      ],
    }),
  ];
};

export const useAutocompleteExtension = ({
  versionId,
  selectedNodeId,
  isLoopNode,
}: {
  versionId: string;
  selectedNodeId?: string;
  isLoopNode?: boolean;
}) => {
  const featureGates = useWorkspaceFeatureGates();
  // Fetch the autocompletion data to make use of it in completion extension
  useNodeCompletionData(versionId, selectedNodeId, isLoopNode);
  useSchemaCompletionData(
    versionId,
    isFeatureFlagEnabled(FEATURE_FLAGS.autocomplete),
  );

  return useMemo(() => {
    const defaultCompletion = buildAutocompleteExtension(
      versionId,
      selectedNodeId,
      featureGates,
    );
    return {
      defaultCompletion,
    };
  }, [versionId, selectedNodeId, featureGates]);
};

export const showUpdatedSuggestionsPlugin = ViewPlugin.define(() => {
  return {
    update: (viewUpdate: ViewUpdate) => {
      // If a completion is opened append the outdated information to the completion list in the dom
      if (
        !completionStatus(viewUpdate.startState) &&
        completionStatus(viewUpdate.state)
      ) {
        const popupFindingInterval = setInterval(() => {
          const completionPopup = document.querySelector(
            ".cm-tooltip-autocomplete.cm-tooltip",
          );

          if (completionPopup) {
            clearInterval(popupFindingInterval);
            completionPopup.appendChild(
              document.querySelector(
                `.${HIDDEN_AUTOCOMPLETION_SUGGESTION_CLASSNAME}`,
              )
                ? NoAutocompleteDataStatus
                : OutdatedAutocompleteDataStatus,
            );
          }
        }, 20);
      }
    },
  };
});
