import { useBoolean } from "usehooks-ts";

import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";

export const ClampedItems: React.FC<{ items: JSX.Element[] }> = ({ items }) => {
  const { value: isOpen, setFalse, setTrue } = useBoolean(false);

  if (items.length < 4) {
    return items;
  }

  const [firstTwo, rest] = [items.slice(0, 2), items.slice(2)];
  const button = (
    <Pill size="sm" variant="gray">
      <Pill.Text>+{rest.length}</Pill.Text>
    </Pill>
  );

  return (
    <>
      {firstTwo}
      <CustomPopover
        button={button}
        isOpen={isOpen}
        offsetX={0}
        onMouseEnter={setTrue}
        onMouseLeave={setFalse}
      >
        <div className="flex flex-col items-start gap-y-5 px-4 py-2">
          {rest}
        </div>
      </CustomPopover>
    </>
  );
};
