import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";

import { entitiesApi } from "src/api/endpoints";
import { SubSchemaT } from "src/api/flowTypes";
import {
  EntityCaseSummaryStatusEnum,
  EntityResourcePage as EntityResourcePageGenerated,
  GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum,
  GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum,
  GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum,
  GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum,
  ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum,
  ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum,
  PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum,
  Relation,
} from "src/clients/entities/api";
import { PropertyDefinitionOutputTypeEnum } from "src/clients/features-control";
import { RelatedEntityConfig } from "src/entities/entityView/EntityDetailsSidePane";
import {
  entityIcons,
  getEntityKeys,
  transformRelations,
  transformToFrontendEntity,
} from "src/entities/entityView/utils";
import { Environment } from "src/eventsCatalogue/useEnvironment";
import { queryClient } from "src/queryClient";
import { logger } from "src/utils/logger";

export const ENTITIES_PAGE_SIZE = 100;

type UseEntitiesParams = {
  schema: string;
  env: Environment;
  baseUrl: string;
  filters?: string[];
  limit?: number;
};

type UseEntityNavigationParams = UseEntitiesParams & {
  pageToken?: string;
  options?: { enabled: boolean };
};

export type EntityFieldType = PropertyDefinitionOutputTypeEnum;

export type EntityFieldUrlType = {
  name?: string;
  url: string;
};

export enum Cardinality {
  ONE = "1",
  ONE_TO_MANY = "1-to-many",
}

export type EntityEnumValue = { _internal_name: string; _color: string };

export type EntitySchemaProperty = {
  _type: EntityFieldType;
  _display_name: string;
  _indexed: boolean;
  _rel_schema?: string;
  _cardinality?: Cardinality;
  _values?: EntityEnumValue[];
  _order?: number;
};

export type EntityEnumSchemaProperty = EntitySchemaProperty & {
  _type: PropertyDefinitionOutputTypeEnum.ENUM;
  _values: EntityEnumValue[];
};

export type EntityPropertyValue = string | string[] | null | object;

export type TableRow = EntitySchemaProperty & {
  value: EntityPropertyValue;
  isId?: boolean;
};

export type EntitySchemaResourceDataplane = {
  _id: string;
  _schema: "Entity";
  _etag: number;
  _related: Record<string, unknown>;
  _updatedAt: string;
  _display_name_singular: string;
  _display_name_plural: string;
  _display_symbol: keyof typeof entityIcons;
  _primary_property: string;
  _secondary_property: string;
  _order: number;
};

export type EntitySchemaResource = EntitySchemaResourceDataplane & {
  properties: {
    [key: string]: EntitySchemaProperty;
  };
};

export type EntityResourceDataplane = {
  _id: string;
  _schema: string;
  _etag: number;
  _related: Record<string, unknown>;
  _updated_at: string;
  _fk_pp?: Record<`${string}.${string}`, string>; // foreginKeyToPrimaryProperty
};

export type EntityResource = EntityResourceDataplane & {
  properties: {
    [key: string]: EntityPropertyValue;
  };
};

export type EntityResourceProperties = Pick<EntityResource, "properties">;

// Extend the generated type to get correct types not just "objects"
type EntityResourcePage = Omit<EntityResourcePageGenerated, "entities"> & {
  entities: EntityResource[];
};

// When we fetch schemas we have no cases
export type EntitySchemaResourcePage = Omit<
  EntityResourcePageGenerated,
  "entities" | "cases"
> & {
  entities: EntitySchemaResource[];
  next_page_token: string;
  previous_page_token?: string;
};

type useEntityParams = UseEntitiesParams & {
  entityId: string;
  enabled?: boolean;
};

export type TransformedRelatedEntities = {
  schema: EntitySchemaResource;
  entities: EntityResource[];
};

export type RelatedEntitiesDataPlane = {
  entities: Record<
    string,
    EntityResourceDataplane | EntitySchemaResourceDataplane
  >;
};

export type GroupedEntity = {
  schema: EntitySchemaResourceDataplane;
  entities: EntityResourceDataplane[];
};

export type RelationDataPlane = {
  property: Relation;
  entity: EntityResource;
};

export type RelatedEntitiesPage = {
  relations: GroupedRelation[];
  next_page_token?: string;
};

export type GroupedRelation = {
  schemaId: string;
  entitySchemaId: string;
  property: string;
  entities: EntityResource[];
  schema?: EntitySchemaResource;
  relatedSchema?: EntitySchemaResource;
  entitySchema?: EntitySchemaResource;
};

export type EntityManualReviewCase = {
  assignees: string[];
  status: EntityCaseSummaryStatusEnum;
};

export type EntityManualReviewCases = { [key: string]: EntityManualReviewCase };

const transformToFrontendTypes = <S extends "schemas" | "entity">(
  data: EntityResourcePageGenerated,
) => {
  return {
    ...data,
    entities: data.entities.map((entity) =>
      getEntityKeys(
        entity as EntitySchemaResourceDataplane | EntityResourceDataplane,
      ),
    ),
  } as S extends "schemas" ? EntitySchemaResourcePage : EntityResourcePage;
};

const queryKeys = {
  entitySchemas: (baseUrl: string) => ["entitySchemas", baseUrl],
  entitySchemasJsonSchema: (baseUrl: string) => [
    "entitySchemasJsonSchema",
    baseUrl,
  ],
};

export const useEntitySchemas = ({
  baseUrl,
  options,
}: {
  baseUrl: string;
  options?: { enabled: boolean };
}) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery<
    EntityResourcePageGenerated,
    AxiosError,
    EntitySchemaResourcePage
  >({
    queryKey: queryKeys.entitySchemas(baseUrl),
    queryFn: async () => {
      const response = await apiClient.listSchemasEntitiesApiV1SchemasSchemaGet(
        {
          schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum.ENTITY,
          limit: 100,
        },
      );

      return response.data;
    },
    select: (data) => {
      return transformToFrontendTypes<"schemas">(data);
    },
    enabled: baseUrl !== "" && options?.enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useEntities = ({
  schema,
  env,
  baseUrl,
  filters,
  limit,
}: UseEntitiesParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useInfiniteQuery<
    EntityResourcePageGenerated,
    AxiosError,
    InfiniteData<EntityResourcePage>,
    QueryKey,
    string | undefined
  >({
    queryKey: ["entities", schema, env, baseUrl, filters],
    getNextPageParam: (lastPage) => lastPage.next_page_token ?? undefined,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }) => {
      const response =
        await apiClient.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet({
          env: env as unknown as ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum,
          schema,
          limit,
          pageToken: pageParam,
          filter: filters,
        });

      return response.data;
    },
    select: (data) => {
      return {
        pages: data.pages.map((page) =>
          transformToFrontendTypes<"entity">(page),
        ),
        pageParams: data.pageParams,
      };
    },
  });
};

export const useEntityRelatedEntities = (
  relatedEntities: Record<string, RelatedEntityConfig>,
  baseUrl: string,
  env: Environment,
) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQueries({
    queries: Object.entries(relatedEntities).map(
      ([key, { entityType, entityId }]) => ({
        queryKey: ["entity", entityType, entityId],
        queryFn: async () => {
          const response =
            await apiClient.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(
              {
                env: env as unknown as GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum,
                schema: entityType,
                entityId: entityId,
              },
            );
          return { data: response.data, key };
        },
      }),
    ),
  });
};

export const useEnrichedEntityData = ({
  entitySchema,
  entityId,
  env,
  baseUrl,
}: {
  entitySchema: string;
  entityId: string;
  env: Environment;
  baseUrl: string;
}) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: ["entityEnrichmentData", env, entitySchema, entityId],
    queryFn: async () => {
      const response =
        await apiClient.getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(
          {
            env: env as unknown as GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum,
            schema: entitySchema,
            entityId: entityId,
          },
        );
      return response.data;
    },
    enabled: !!entityId,
  });
};

/**
 * This query is used for the Entity Details header to handle the navigation
 * It calls the Entity list endpoint, filtering by id and limit 1 to fetch the current entity
 * After that, 2 additional calls are made to fetch the previous and next entities
 * using the page tokens from the first call and without any filters
 */
export const useEntityNavigation = ({
  schema,
  env,
  baseUrl,
  filters,
  pageToken,
  options,
}: UseEntityNavigationParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: ["entityNavigation", schema, env, baseUrl, filters, pageToken],
    enabled: options?.enabled,
    queryFn: async () => {
      const response =
        await apiClient.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet({
          env: env as unknown as ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum,
          schema,
          limit: 1,
          pageToken,
          filter: pageToken ? undefined : filters,
        });

      return response.data;
    },
    select: (data) => {
      return transformToFrontendTypes<"entity">(data);
    },
  });
};

const transformEntity = (data: EntityResourceDataplane) => {
  return getEntityKeys(data) as EntityResource;
};

export const useEntity = ({
  schema,
  env,
  baseUrl,
  entityId,
  enabled,
}: useEntityParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery<
    EntityResourceDataplane,
    AxiosError,
    EntityResource | undefined
  >({
    queryKey: ["entity", schema, env, baseUrl, entityId],
    queryFn: async () => {
      return (
        await apiClient.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(
          {
            env: env as unknown as GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum,
            schema,
            entityId,
          },
        )
      ).data as EntityResourceDataplane;
    },
    enabled,
    select: transformEntity,
  });
};

export const useRelatedEntities = ({
  schema,
  env,
  baseUrl,
  entityId,
}: useEntityParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useInfiniteQuery<
    RelatedEntitiesPage,
    Error,
    InfiniteData<RelatedEntitiesPage>,
    QueryKey,
    string | undefined
  >({
    queryKey: ["relatedEntities", schema, env, baseUrl],
    getNextPageParam: (lastPage) => lastPage.next_page_token ?? undefined,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }) => {
      try {
        const response =
          await apiClient.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(
            {
              schema,
              env: env as unknown as GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum,
              entityId,
              limit: 20,
              pageToken: pageParam,
            },
          );

        if (response.data) {
          return transformRelations(response.data);
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};

export const useEntitySchemasJsonSchema = ({
  baseUrl,
  options,
}: {
  baseUrl: string;
  options?: Pick<UseQueryOptions<Record<string, SubSchemaT>>, "enabled">;
}) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);
  return useQuery<Record<string, SubSchemaT>, Error>({
    queryKey: queryKeys.entitySchemasJsonSchema(baseUrl),
    queryFn: async () => {
      try {
        const response =
          await apiClient.getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(
            {
              schema:
                GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum.ENTITY,
            },
          );
        if (response.data) {
          return response.data as Record<string, SubSchemaT>;
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    staleTime: 1000 * 60 * 60, // 1 hour
    enabled: options?.enabled,
  });
};

export const useUpdateEntitySchema = ({ baseUrl }: { baseUrl: string }) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["entitySchemas", baseUrl] });
    },
    mutationFn: async (schema: EntitySchemaResource) => {
      try {
        const { properties, ...baseFields } = schema;
        const response =
          await apiClient.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut({
            schema:
              PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum.ENTITY,
            entityId: schema._id,
            body: {
              ...baseFields,
              ...properties,
              _etag: 1,
            },
          });
        if (response.data) {
          return transformToFrontendEntity<"schemas">(response.data);
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};
