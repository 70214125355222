const log = (...args: any): void => {
  // eslint-disable-next-line no-console
  console.log(...args);
};

const warn = (...args: any): void => {
  // eslint-disable-next-line no-console
  console.warn(...args);
};

const error = (...args: any): void => {
  extractAndReportError(args);
  // eslint-disable-next-line no-console
  console.error(...args);
};

export const logger = {
  log,
  warn,
  error,
};

const extractAndReportError = (args: any[]): void => {
  if (!reportFirstError(args)) {
    try {
      // try stringifying
      reportError(new Error(JSON.stringify(args)));
    } catch {
      // try first element
      if (typeof args[0] === "string") {
        reportError(new Error(args[0]));
      } else {
        try {
          reportError(new Error(JSON.stringify(args[0])));
        } catch {
          reportError(new Error("Unknown error"));
        }
      }
    }
  }
};

const reportFirstError = (args: any[]): boolean =>
  args.some((arg) => arg instanceof Error && reportError(arg));

export const reportError = (error: Error): void => {
  try {
    // @ts-ignore window is not fully typed but is save to ignore due to the catch block
    window.cwr("recordError", error);
  } catch {
    // eslint-disable-next-line no-console
    console.warn("Could not log error to AWS RUM");
  }
};
