import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useLocation } from "react-router-dom";

import { useReviewCases } from "src/api/queries";
import {
  AccordionRoot,
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import { ManualReviewHighlights } from "src/entities/entityView/Highlights";
import { ManualReviewInspectData } from "src/entities/entityView/InspectData";
import { ReviewCaseDetails } from "src/entities/entityView/ReviewCaseDetails";
import { decisionsQueryKeys } from "src/entities/entityView/useEntityTabRefresh";
import { ReviewCaseActionForm } from "src/manualReview/ReviewCaseContent";
import { FormSide } from "src/manualReview/reviewCaseCommon/FormSide";
import { ManualReviewAccordionHeader } from "src/manualReviewNode/ManualReviewNodeEditor";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  EntityViewParams,
  getBaseUrl,
  getUrlToReviewCase,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type ManualReviewTabProps = {
  flowSlug: string;
  decisionId: string;
  flowId: string;
  onClose: () => void;
};

export const ManualReviewTabContent: React.FC<ManualReviewTabProps> = ({
  flowSlug,
  decisionId,
  flowId,
  onClose,
}) => {
  const { orgId } = useParamsDecode<EntityViewParams>();
  const { workspace } = useWorkspaceContext();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const { data: reviewCases, isLoading } = useReviewCases(
    workspace.base_url,
    flowSlug,
    {
      entity_or_decision_id: decisionId,
    },
  );
  // we are getting the review cases based on a decision id, so there should only be one review case
  const reviewCase = reviewCases?.pages[0]?.cases[0];

  const url =
    getBaseUrl() +
    getUrlToReviewCase(
      orgId,
      workspace.id,
      flowId,
      reviewCase?.id ?? "",
      search,
    );

  const onSubmit = () => {
    onClose();

    decisionsQueryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries({
        queryKey,
      });
    });
  };

  return (
    <AccordionRoot
      className={accordionRootClassName}
      defaultValue={["manual-review"]}
      type="multiple"
    >
      <AccordionItem
        title={
          <ManualReviewAccordionHeader
            action={
              reviewCase?.id && (
                <Tooltip placement="left" title="Open Review Case in new tab">
                  <Icon
                    icon={faArrowUpRightFromSquare}
                    size="xs"
                    onClick={() => {
                      window.open(url, "_blank");
                    }}
                  />
                </Tooltip>
              )
            }
            title="Review Case Details"
          />
        }
        value="manual-review"
        headerAsChild
      >
        <ReviewCaseDetails
          flowSlug={flowSlug}
          isLoading={isLoading}
          reviewCase={reviewCase}
        />
      </AccordionItem>
      <AccordionItem title="Highlights" value="highlights">
        <ManualReviewHighlights highlights={reviewCase?.highlights ?? []} />
      </AccordionItem>
      <AccordionItem title="Inspect Data" value="inspect-data">
        <ManualReviewInspectData
          baseUrl={workspace.base_url}
          flowSlug={flowSlug}
          id={reviewCase?.id ?? ""}
        />
      </AccordionItem>
      <AccordionItem className="p-0" title="Actions" value="actions">
        <FormSide
          description={reviewCase?.response.schema.description}
          isLoading={isLoading}
          nodeName={reviewCase?.node_name}
          noBorders
        >
          {reviewCase && (
            <ReviewCaseActionForm
              flowSlug={flowSlug}
              reviewCase={reviewCase}
              onSubmit={onSubmit}
            />
          )}
        </FormSide>
      </AccordionItem>
    </AccordionRoot>
  );
};
