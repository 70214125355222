import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  useCreateConnection,
  useEditConnection,
  useManifests,
  useProviderManifest,
} from "src/api/connectApi/queries";
import {
  ConnectionCreateT,
  ConnectionT,
  ProviderT,
  isGenericProvider,
  isSQLDatabaseProvider,
  LegacyIntegrationProviderT,
} from "src/api/connectApi/types";
import { useWorkspaceWithSettings } from "src/api/queries";
import { BetaPill } from "src/base-components/BetaPill";
import { isBetaConnection } from "src/connections/CreateConnectionGrid";
import { getProviderName } from "src/connections/ProviderResourceProperties";
import { CustomConnectionConfigForm } from "src/connections/config/CustomConnectionConfigForm";
import { ProviderIcon } from "src/connections/config/Icon";
import {
  IntegrationProviderConnection,
  ProviderConfigForm,
} from "src/connections/config/ProviderConfigForm";
import { ProviderSelectForm } from "src/connections/config/ProviderSelectForm";
import { SQLDatabaseConnectionConfigForm } from "src/connections/config/database/SQLDatabaseConnectionConfigForm";
import { InboundWebhookConnectionConfigForm } from "src/connections/config/inboundWebhook/InboundWebhookConnectionConfigForm";
import { InboundWebhookConnectionCreatedForm } from "src/connections/config/inboundWebhook/InboundWebhookConnectionCreatedForm";
import { WebhookURLsT } from "src/connections/config/inboundWebhook/InboundWebhookURLField";
import { ManifestForm } from "src/connections/config/manifest/ManifestForm";
import { RetoolConnectionConfigForm } from "src/connections/config/retool/RetoolConnectionConfigForm";
import {
  connectionInputsDefaultValues,
  connectionInputsToCreateConnection,
  connectionToConnectionInputs,
  connectionToProviderSelectInput,
  providerDefaultValues,
} from "src/connections/model/model";
import { useConnectionManagementActions } from "src/connections/store/connectionManagementStore";
import {
  ConnectionConfigInputsT,
  ConnectionInputsT,
  ProviderSelectInputsT,
} from "src/connections/types";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { isConflictError } from "src/utils/predicates";
import { assertUnreachable } from "src/utils/typeUtils";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

export type PropsT = {
  open: boolean;
  onClose: () => void;
  workspaceId: string;
  afterLeave?: () => void;
  connectionToEdit?: ConnectionT;
};

export type CurrentFormT = keyof ConnectionInputsT;

const defaultForm = "selectProviderForm";
const EMPTY_ARRAY = [] as const;

export const EditConnectionModal: React.FC<PropsT> = ({
  open,
  onClose,
  workspaceId,
  afterLeave,
  connectionToEdit,
}) => {
  const workspace = useWorkspaceWithSettings(workspaceId);
  const { setConnectionToEdit, setModalOpen } =
    useConnectionManagementActions();
  const [currentForm, setCurrentForm] = useState<CurrentFormT>(defaultForm);
  const [webhookURLs, setWebhookURLs] = useState<WebhookURLsT | null>(null);
  const createConnection = useCreateConnection(workspace.data?.base_url);

  const editConnection = useEditConnection(
    workspace.data?.base_url,
    connectionToEdit?.id,
  );
  const selectProviderForm = useForm<ProviderSelectInputsT>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: providerDefaultValues(),
  });
  const configFormMethods = useForm<ConnectionConfigInputsT>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: connectionInputsDefaultValues(),
  });
  const providerManifest = useProviderManifest(
    workspace.data?.base_url,
    connectionToEdit?.provider,
    connectionToEdit?.manifest_version,
  );

  const resetFormStates = useCallback(() => {
    setWebhookURLs(null);
    if (connectionToEdit != null) {
      setCurrentForm("configConnectionForm");
      if (connectionToEdit.provider === "custom") {
        const selectValues = connectionToProviderSelectInput(connectionToEdit);
        selectProviderForm.reset(selectValues);
        const configValues = connectionToConnectionInputs(connectionToEdit);
        configFormMethods.reset(configValues);
      } else {
        configFormMethods.reset(connectionInputsDefaultValues());
        selectProviderForm.reset({
          provider: connectionToEdit.provider,
          isManifestProvider: Boolean(connectionToEdit.manifest_version),
        });
      }
    } else {
      setCurrentForm("selectProviderForm");
      selectProviderForm.reset(providerDefaultValues());
      configFormMethods.reset(connectionInputsDefaultValues());
    }
  }, [setCurrentForm, selectProviderForm, configFormMethods, connectionToEdit]);

  useEffect(() => {
    // reset form when modal opens
    if (open) {
      resetFormStates();
    }
  }, [open, resetFormStates]);

  const webhookOnClose = (webhookURLs: WebhookURLsT | null) => {
    if (webhookURLs == null) {
      onClose();
      return;
    }
    // Keeping the modal open when webhook connection is created
    // so that we can show the webhook URL to the user. Close otherwise
    setWebhookURLs(webhookURLs);
  };

  const handleEditConnection = useCallback(
    // Only used for CC
    async (
      payload: ConnectionCreateT,
      connectionToEdit: ConnectionT,
    ): Promise<boolean> => {
      try {
        await editConnection.mutateAsync({
          id: connectionToEdit.id,
          payload,
        });

        toastActions.success({
          title: "Connection edited",
          description: `Connection ${connectionToEdit.name} was edited successfully`,
          withSidebar: true,
        });
        return true;
      } catch {
        toastActions.failure({
          title: "Connection edit failed",
          description: `Connection ${connectionToEdit.name} could not be edited`,
          withSidebar: true,
        });
        return false;
      }
    },
    [editConnection],
  );

  const handleCreateConnection = useCallback(
    // Only used for CC
    async (payload: ConnectionCreateT): Promise<boolean> => {
      try {
        const result = await createConnection.mutateAsync(payload);
        const toastId = toastActions.success({
          title: "Connection created",
          description: "Connection was created successfully",
          withSidebar: true,
          actionText: "View connection",
          onActionClick: () => {
            toastActions.dismiss(toastId);
            setConnectionToEdit(result);
            setModalOpen(true);
          },
        });
        return true;
      } catch (e) {
        if (isConflictError(e)) {
          toastActions.failure({
            title: "Connection creation failed",
            description: (e as AxiosError<any>).response?.data.detail,
            duration: Infinity,
          });
        } else {
          toastActions.failure({
            title: "Connection creation failed",
            description: "Connection could not be created.",
            withSidebar: true,
          });
        }
        return false;
      }
    },
    [createConnection, setConnectionToEdit, setModalOpen],
  );

  const submitData = useCallback(
    // Only used for CC
    async (data: ConnectionInputsT) => {
      const payload = connectionInputsToCreateConnection(data);
      let success = false;
      if (connectionToEdit != null) {
        success = await handleEditConnection(payload, connectionToEdit);
      } else {
        success = await handleCreateConnection(payload);
      }
      if (success) {
        resetFormStates();
        onClose();
      }
    },
    [
      handleCreateConnection,
      handleEditConnection,
      connectionToEdit,
      resetFormStates,
      onClose,
    ],
  );
  const selectedProvider = selectProviderForm.getValues("provider");
  const isManifestProvider = selectProviderForm.getValues("isManifestProvider");
  const workspaceData = useWorkspaceAndOrg();
  const manifestIntegrationProviders =
    useManifests(workspaceData.workspace?.base_url).data || EMPTY_ARRAY;

  const manifest = useMemo(() => {
    // For editing a connection, we fetch the manifest with the exact version the connection has
    if (!isManifestProvider) return undefined;
    if (connectionToEdit) return providerManifest.data;
    return manifestIntegrationProviders.find(
      (providerManifest) => providerManifest.name === selectedProvider,
    );
  }, [
    manifestIntegrationProviders,
    selectedProvider,
    isManifestProvider,
    connectionToEdit,
    providerManifest,
  ]);

  const renderCurrentForm = (currentForm: CurrentFormT) => {
    switch (currentForm) {
      case "selectProviderForm":
        return (
          <Modal.Content>
            <FormProvider {...selectProviderForm}>
              <ProviderSelectForm
                onSubmit={() => {
                  setCurrentForm("configConnectionForm");
                }}
              />
            </FormProvider>
          </Modal.Content>
        );
      case "configConnectionForm":
        if (!workspace.data) return null;

        if (selectedProvider && isSQLDatabaseProvider(selectedProvider)) {
          return (
            <SQLDatabaseConnectionConfigForm
              connection={connectionToEdit}
              provider={selectedProvider}
              workspaceUrl={workspace.data.base_url!}
              onClose={onClose}
            />
          );
        }
        if (selectedProvider === "custom") {
          if (!workspace.data) return null;
          return (
            <FormProvider {...configFormMethods}>
              <CustomConnectionConfigForm
                workspace={workspace.data}
                onSubmit={async (data) => {
                  const selectProviderData = selectProviderForm.getValues();
                  // Do not finish the form submission until
                  // the submitData function finishes.
                  await submitData({
                    selectProviderForm: selectProviderData,
                    configConnectionForm: data,
                  });
                }}
              />
            </FormProvider>
          );
        } else if (selectedProvider === "retool") {
          return (
            <RetoolConnectionConfigForm
              connection={connectionToEdit}
              workspace={workspace.data}
              onClose={onClose}
            />
          );
        } else if (selectedProvider === "webhook") {
          return (
            <InboundWebhookConnectionConfigForm
              connection={connectionToEdit}
              workspace={workspace.data}
              onClose={webhookOnClose}
            />
          );
        } else if (isManifestProvider) {
          if (!manifest) {
            return null;
          }
          return (
            <ManifestForm
              connectionToEdit={connectionToEdit}
              manifest={manifest}
              resetFormStates={resetFormStates}
              workspace={workspace.data}
              onCancelClick={() => {
                if (connectionToEdit) {
                  onClose();
                } else {
                  setCurrentForm("selectProviderForm");
                }
                resetFormStates();
              }}
              onClose={onClose}
            />
          );
        } else {
          return connectionToEdit &&
            !isSQLDatabaseProvider(connectionToEdit.provider) &&
            !isGenericProvider(connectionToEdit.provider) ? (
            <ProviderConfigForm
              connection={
                connectionToEdit as IntegrationProviderConnection<LegacyIntegrationProviderT>
              }
              type="update"
              workspace={workspace.data}
              onCancelClick={onClose}
              onClose={onClose}
            />
          ) : (
            selectedProvider != null && (
              <ProviderConfigForm
                provider={selectedProvider as LegacyIntegrationProviderT}
                type="create"
                workspace={workspace.data}
                onCancelClick={() => setCurrentForm("selectProviderForm")}
                onClose={onClose}
              />
            )
          );
        }
      default:
        assertUnreachable(currentForm);
    }
  };

  let modalTitle = "Create new Connection";
  let modalProvider: ProviderT | string | undefined;

  if (currentForm !== "selectProviderForm") {
    if (connectionToEdit) {
      modalTitle = connectionToEdit.name;
      modalProvider = connectionToEdit.provider;
    } else if (selectedProvider && !isManifestProvider) {
      modalTitle = `Add a ${getProviderName(
        selectedProvider as ProviderT,
      )} connection`;
      modalProvider = selectedProvider;
    } else if (isManifestProvider && manifest) {
      modalTitle = `Add a ${manifest.display_name} connection`;
      modalProvider = manifest.name;
    }
  }

  if (webhookURLs && selectedProvider === "webhook") {
    return (
      <Modal open={open} onClose={onClose}>
        <Modal.Header>
          {getProviderName(selectedProvider)} created!
        </Modal.Header>
        <Modal.Content>
          <div className="px-6 pb-6">
            <InboundWebhookConnectionCreatedForm {...webhookURLs} />
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Modal
      afterLeave={afterLeave}
      open={open}
      size={currentForm === "selectProviderForm" ? "md" : "sm"}
      onClose={onClose}
    >
      <Modal.Header
        description={
          currentForm === "selectProviderForm"
            ? "Select a provider to connect to"
            : undefined
        }
        hideCloseIcon={currentForm !== "selectProviderForm"}
        icon={
          modalProvider && <ProviderIcon provider={modalProvider} size="lg" />
        }
        onClickBack={
          currentForm !== "selectProviderForm" && !connectionToEdit
            ? () => {
                setCurrentForm("selectProviderForm");
              }
            : undefined
        }
      >
        <div className="flex items-center gap-x-2">
          {modalTitle}

          {modalProvider !== undefined && isBetaConnection(modalProvider) && (
            <BetaPill tooltipPlacement="right" />
          )}
        </div>
      </Modal.Header>
      {renderCurrentForm(currentForm)}
    </Modal>
  );
};
