import {
  faCheck,
  faChevronDown,
  faClose,
  faDatabase,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import slugify from "slugify";
import { twJoin } from "tailwind-merge";

import { S3BucketIcon } from "./SourceDropdown";
import { useConnections } from "src/api/connectApi/queries";
import { isSQLDatabaseProvider } from "src/api/connectApi/types";
import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { Select } from "src/base-components/Select";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { ProviderIcon } from "src/connections/config/Icon";
import { EmptyState } from "src/design-system/EmptyState";
import { Modal, useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import {
  useCreateJobDestination,
  useDeleteJobDestination,
  useJobDestinations,
  useUpdateJob,
  useUpdateJobDestination,
} from "src/jobs/api/queries";
import { ConnectionIcon } from "src/jobs/common/ConnectionIcon";
import { DestinationActions } from "src/jobs/common/DestinationActions";
import { connectionHasSandboxCredentials } from "src/jobs/common/SQLSourceModal/SQLSourceModal";
import { useUsedVersions } from "src/jobs/common/hooks";
import { handlePreconditionError } from "src/jobs/jobUtils";
import {
  Job,
  JobDestination,
  JobDestinationDB,
  JobDestinationS3Bucket,
} from "src/jobs/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useFlowContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToSettingsPage } from "src/router/urls";
import { SettingsSubpages } from "src/settings/SettingsPage";
import { assertUnreachable } from "src/utils/typeUtils";

export const UNTITLED_DESTINATION = "Untitled destination";

const mapOutput = (versions: FlowVersionFlowChild[]) => {
  const properties = versions.flatMap((version) =>
    Object.keys(version.output_schema?.properties ?? {}),
  );

  return Array.from(new Set(properties)).map((field) => ({
    db_field: field,
    flow_output_field: field,
  }));
};

const isJobDestinationDB = (
  destination: JobDestination,
): destination is JobDestinationDB => {
  return destination.configuration.type === "sql";
};

const isJobDestinationS3Bucket = (
  destination: JobDestination,
): destination is JobDestinationS3Bucket => {
  return destination.configuration.type === "s3_bucket";
};

export const DestinationDropdown: React.FC<{ job: Job }> = ({ job }) => {
  const { workspace } = useFlowContext();
  const { jobs: jobCapabilities } = useCapabilities();
  const { data, isLoading: isLoadingDestinations } = useJobDestinations(
    workspace.base_url!,
    job.id,
  );
  const jobUpdateMutation = useUpdateJob(workspace.base_url!, job);
  const deleteJobDestinationMutation = useDeleteJobDestination(
    workspace.base_url!,
    job.id,
  );

  const createJobDestinationMutation = useCreateJobDestination(
    workspace.base_url!,
    job.id,
  );

  const usedVersions = useUsedVersions(job);

  const {
    isOpen: isEditDBDestinationModalOpen,
    data: destinationToEdit,
    openModal: openEditDBDestinationModal,
    closeModal: closeEditDBDestinationModal,
  } = useModal<string>();

  const {
    isOpen: isDeleteDBDestinationModalOpen,
    data: destinationToDelete,
    openModal: openDeleteDBDestinationModal,
    closeModal: closeDeleteDBDestinationModal,
  } = useModal<string>();

  const createJobDestination = async () => {
    try {
      const destination = await createJobDestinationMutation.mutateAsync({
        name: "",
        configuration: {
          type: "sql",
          credentials_for_sandbox_run: "live_credentials",
          table_prefix: slugify(job.name, {
            lower: true,
            strict: true,
            replacement: "_",
          }),
          mapping: mapOutput(usedVersions),
        },
      });

      await jobUpdateMutation.mutateAsync({
        active_destination: destination.id,
        etag: job.etag,
      });

      openEditDBDestinationModal(destination.id);
    } catch (e) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        e,
      );
      if (!isPreconditionError) {
        toastActions.failure({
          title: "Destination creation failed",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  // Update destination mapping when the flow version changes
  const { mutateAsync: updateJobDestinationMutation } = useUpdateJobDestination(
    workspace.base_url!,
    job.id,
  );
  const usedVersionIds = usedVersions.map((v) => v.id);
  useEffect(
    () => {
      if (
        job.active_destination &&
        isJobDestinationDB(job.active_destination)
      ) {
        const newMapping = mapOutput(usedVersions);

        if (isEqual(newMapping, job.active_destination.configuration.mapping))
          return;

        updateJobDestinationMutation({
          id: job.active_destination.id,
          etag: job.active_destination.etag,
          configuration: {
            ...job.active_destination.configuration,
            mapping: newMapping,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usedVersionIds, updateJobDestinationMutation],
  );

  const createS3DestinationSingelton = async () => {
    const destination = await createJobDestinationMutation.mutateAsync({
      name: "S3 Bucket",
      configuration: {
        type: "s3_bucket",
      },
    });
    await jobUpdateMutation.mutateAsync({
      active_destination: destination.id,
      etag: job.etag,
    });
  };

  const onDeleteModalConfirm = async () => {
    if (destinationToDelete) {
      await deleteJobDestinationMutation.mutateAsync(destinationToDelete);
    }
    closeDeleteDBDestinationModal();
  };

  if (!data) return <SkeletonPlaceholder height="h-4" width="w-16" />;

  const handleSelect = async (destinationId: string | null) => {
    try {
      await jobUpdateMutation.mutateAsync({
        active_destination: destinationId,
        etag: job.etag,
      });

      // Update mapping to the new destination
      const destination = data.destinations.find(
        (destination) => destination.id === destinationId,
      );
      if (destination && isJobDestinationDB(destination)) {
        await updateJobDestinationMutation({
          id: destination.id,
          etag: destination.etag,
          configuration: {
            ...destination.configuration,
            mapping: mapOutput(usedVersions),
          },
        });
      }
    } catch (error) {
      const isPreconditionError = await handlePreconditionError(
        workspace,
        job,
        error,
      );

      if (!isPreconditionError)
        toastActions.failure({
          title: "Failed to change the active destination of the Job",
          description: TAKTILE_TEAM_NOTIFIED,
        });
    }
  };

  const disabled = !jobCapabilities.canEdit || job.status === "active";
  const dbDestinationToEditHelper = data.destinations.find(
    (destination) => destination.id === destinationToEdit,
  );
  const showCreateS3SingletonDestinationCreate =
    isFeatureFlagEnabled(FEATURE_FLAGS.jobS3BucketSourceAndDestination) &&
    !isLoadingDestinations &&
    !data.destinations.find((destination) =>
      isJobDestinationS3Bucket(destination),
    );

  const dbDestinationToEdit =
    dbDestinationToEditHelper && isJobDestinationDB(dbDestinationToEditHelper)
      ? dbDestinationToEditHelper
      : undefined;

  return (
    <>
      <div className="flex items-center gap-x-1">
        <FixedPositionedDropdown
          buttonClassName="border-none active:ring-0 focus:ring-0 ring-0"
          buttonDataLoc="set-destination-dropdown-button"
          dataLoc="set-destination-dropdown"
          disabled={disabled}
          elements={data.destinations.map((destination) => ({
            key: destination.id,
            value: destination,
          }))}
          itemsClassNames="max-h-[min(500px,50vh)] overflow-auto w-[283px] z-30"
          placement="bottomLeft"
          placementOffsetX={-20}
          renderButtonValue={(selectedValue) => {
            if (!selectedValue)
              return (
                <div className="text-indigo-600 font-inter-medium-12px">
                  Set a Destination
                </div>
              );

            return (
              <div className="flex items-center">
                <div className="mr-1">
                  <DestinationIcon destination={selectedValue} size="sm" />
                </div>
                <p className="ml-0.5 max-w-44 truncate text-gray-800 font-inter-medium-12px">
                  {selectedValue.name || UNTITLED_DESTINATION}
                </p>
                {!disabled && (
                  <Icon color="text-gray-500" icon={faChevronDown} size="2xs" />
                )}
              </div>
            );
          }}
          renderFooter={() => (
            <div
              className={twJoin(
                "bg-white",
                data.destinations.length && "border-t border-gray-200 pt-1.5",
              )}
            >
              <button
                className="flex w-full items-center gap-x-1 py-3.5 pl-5 pr-6 text-gray-800 font-inter-normal-12px hover:bg-gray-50"
                data-loc="create-db-destination"
                onClick={createJobDestination}
              >
                <Icon color="text-gray-500" icon={faPlus} size="2xs" />
                <p>Database Connection</p>
              </button>
              {showCreateS3SingletonDestinationCreate && (
                <button
                  className="flex w-full items-center gap-x-1 py-3.5 pl-5 pr-6 text-gray-800 font-inter-normal-12px hover:bg-gray-50"
                  data-loc="create-db-destination"
                  onClick={createS3DestinationSingelton}
                >
                  <Icon color="text-gray-500" icon={faPlus} size="2xs" />
                  <p>S3 Destination</p>
                </button>
              )}
            </div>
          )}
          renderValue={({ value: destination, selected }) => (
            <div className="group flex items-center gap-x-2 px-5 py-3.5 font-inter-normal-12px">
              <div className="flex-0">
                <DestinationIcon destination={destination} />
              </div>
              <div className="flex min-w-0 flex-1 items-center">
                <p className="ml-1 truncate">
                  {destination.name || UNTITLED_DESTINATION}
                </p>
              </div>
              <div className="flex flex-row items-center">
                {selected && (
                  <Icon
                    color="text-indigo-600 group-hover:hidden"
                    icon={faCheck}
                    size="xs"
                  />
                )}
                <div className="hidden items-center space-x-3 group-hover:flex">
                  <DestinationActions
                    destination={destination}
                    onDelete={
                      isJobDestinationDB(destination)
                        ? () => openDeleteDBDestinationModal(destination.id)
                        : undefined
                    }
                    onEdit={
                      isJobDestinationDB(destination)
                        ? () => openEditDBDestinationModal(destination.id)
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          )}
          selected={job.active_destination?.id}
          listMatchesButtonWidth
          onSelect={handleSelect}
        />
        {!disabled && job.active_destination && (
          <Icon
            color="text-gray-500"
            icon={faClose}
            size="2xs"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelect(null);
            }}
          />
        )}
      </div>
      <ConfirmationModal
        confirmationButtonText="Yes, delete"
        open={isDeleteDBDestinationModalOpen}
        title={
          destinationToDelete
            ? `Delete ${
                data.destinations.find(
                  (destination) => destination.id === destinationToDelete,
                )?.name || UNTITLED_DESTINATION
              }`
            : ""
        }
        variant="danger"
        onClose={closeDeleteDBDestinationModal}
        onConfirm={onDeleteModalConfirm}
      >
        <div className="text-gray-500 font-inter-normal-12px">
          Are you sure you want to delete this destination? This action cannot
          be undone.
        </div>
      </ConfirmationModal>
      <EditDestinationModal
        destination={dbDestinationToEdit}
        isOpen={isEditDBDestinationModalOpen}
        jobId={job.id}
        onClose={closeEditDBDestinationModal}
      />
    </>
  );
};

const DestinationIcon: React.FC<{
  destination: JobDestination;
  size?: "sm" | "md";
}> = ({ destination, size = "md" }) => {
  if (isJobDestinationS3Bucket(destination)) {
    return <S3BucketIcon />;
  }

  if (isJobDestinationDB(destination)) {
    return (
      <ConnectionIcon
        connectionId={destination.configuration.connection_id}
        fallbackIcon={faDatabase}
        size={size}
      />
    );
  }

  assertUnreachable(destination);
};

type DestinationForm = Pick<JobDestinationDB, "name" | "configuration">;

const EditDestinationModal: React.FC<{
  jobId: string;
  destination?: JobDestinationDB;
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose, destination, jobId }) => {
  const { workspace, orgId } = useFlowContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<DestinationForm>({
    mode: "onBlur",
    values: {
      name: destination?.name || "",
      configuration: {
        type: "sql",
        connection_id: destination?.configuration.connection_id,
        table_prefix: destination?.configuration.table_prefix || "",
        credentials_for_sandbox_run:
          destination?.configuration.credentials_for_sandbox_run ||
          "live_credentials",
        mapping: destination?.configuration.mapping || [],
      },
    },
  });
  const updateJobDestinationMutation = useUpdateJobDestination(
    workspace.base_url!,
    jobId,
  );

  const onSubmit = async (data: DestinationForm) => {
    if (destination) {
      try {
        await updateJobDestinationMutation.mutateAsync({
          ...data,
          id: destination.id,
          etag: destination.etag,
        });
        onClose();
      } catch {
        toastActions.failure({
          title: "Failed to save the destination",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };
  const connections = useConnections(workspace.base_url);
  const dbConnections =
    connections.data?.filter(
      (connection) =>
        isSQLDatabaseProvider(connection.provider) &&
        (connection.provider === "postgresql" ||
          connection.provider === "mysql" ||
          connection.provider === "snowflake" ||
          connection.provider === "redshift"),
    ) ?? [];
  const { connections: connectionsCapabilities } = useCapabilities();

  const currentConnectionId = watch("configuration.connection_id");
  const currentDbConnection = dbConnections.find(
    (connection) => connection.id === currentConnectionId,
  );
  const allowSelectingSandboxCredentials =
    connectionHasSandboxCredentials(currentDbConnection);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Database destination</Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className="flex flex-col gap-y-6">
            <div>
              <Label required>Name</Label>
              <Input
                fullWidth
                {...register("name", { required: true })}
                errored={!!errors.name}
                placeholder="Database destination name"
              />
            </div>
            <div>
              <Label required>Database</Label>
              <Controller
                control={control}
                name="configuration.connection_id"
                render={({ field }) => (
                  <Select
                    dataLoc="db-connection-select"
                    dropdownPlaceholder={
                      <EmptyState
                        actionButton={
                          connectionsCapabilities.canAccess && {
                            icon: faPlus,
                            text: "Add Connection",
                            onClick: () => {
                              window.open(
                                getBaseUrl() +
                                  getUrlToSettingsPage(
                                    orgId,
                                    workspace.id,
                                    SettingsSubpages.Connections,
                                  ),
                              );
                            },
                          }
                        }
                        dataLoc="no-db-connection"
                        description={
                          connectionsCapabilities.canAccess
                            ? "Please add a new DB connection in order to add a SQL destination"
                            : "Please ask a person with sufficient permissions to create a SQL destination for you"
                        }
                        headline="No database connection found"
                        icon={faDatabase}
                      />
                    }
                    errored={!!errors.configuration?.connection_id}
                    loading={connections.isLoading}
                    options={dbConnections.map((connection) => ({
                      key: connection.id,
                      value: (
                        <div className="flex items-center gap-x-2.5">
                          <ProviderIcon provider={connection.provider} />
                          {connection.name}
                        </div>
                      ),
                    }))}
                    placeholder="Select DB connection"
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);

                      // If we are currently using sandbox credentials
                      // for sandbox runs we need to check whether the
                      // new selected connection has sandbox credentials.
                      // If it doesn't, we reset it to live credentials
                      const configurationIsUsingSandboxCredentials =
                        getValues().configuration
                          .credentials_for_sandbox_run ===
                        "sandbox_credentials";

                      if (configurationIsUsingSandboxCredentials) {
                        const selectedConnection = dbConnections.find(
                          (connection) => connection.id === value,
                        );

                        const selectedConnectionHasSandboxCredentials =
                          connectionHasSandboxCredentials(selectedConnection);

                        if (!selectedConnectionHasSandboxCredentials) {
                          setValue(
                            "configuration.credentials_for_sandbox_run",
                            "live_credentials",
                          );
                        }
                      }
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            <div>
              <Label required>Prefix</Label>
              <Input
                errored={!!errors.configuration?.table_prefix}
                fullWidth
                {...register("configuration.table_prefix", { required: true })}
              />
            </div>
            <div>
              <Label>For sandbox run, use</Label>
              <Controller
                control={control}
                name="configuration.credentials_for_sandbox_run"
                render={({ field }) => (
                  <Select
                    options={[
                      {
                        key: "sandbox_credentials",
                        value: (
                          <Tooltip
                            disabled={allowSelectingSandboxCredentials}
                            footerAction={
                              connectionsCapabilities.canAccess
                                ? {
                                    text: "Go to connections",
                                    onClick: () => {
                                      window.open(
                                        getBaseUrl() +
                                          getUrlToSettingsPage(
                                            orgId,
                                            workspace.id,
                                            SettingsSubpages.Connections,
                                          ),
                                      );
                                    },
                                  }
                                : undefined
                            }
                            offset={65}
                            placement="right"
                            title="Configure a Sandbox connection to select this option"
                          >
                            Sandbox Connection
                          </Tooltip>
                        ),
                        disabled: !allowSelectingSandboxCredentials,
                      },
                      { key: "live_credentials", value: "Live Connection" },
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button htmlType="submit" loading={false} variant="primary">
              Save
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
