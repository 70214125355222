import { useState, useEffect } from "react";

import { useIsEditingCell } from "src/datasets/DatasetTable/stores";
import { CellId } from "src/datasets/DatasetTable/utils";

export const useShowDefaultEditor = (cellId: CellId, value: string) => {
  const [showDefaultEditor, setShowDefaultEditor] = useState(false);
  const editing = useIsEditingCell(cellId);
  const valueIsNotEmpty = value.length > 0;

  useEffect(() => {
    if (!editing) {
      setShowDefaultEditor(valueIsNotEmpty);
    }
  }, [editing, valueIsNotEmpty]);

  return [
    valueIsNotEmpty || showDefaultEditor,
    () => setShowDefaultEditor(true),
  ] as const;
};
