export const JsonRefPathPrefix = "#/$defs/";
export const JsonRefPathPrefixEntities = "#/$defs/Entities@";
export const JsonRefPathPrefixEvents = "#/$defs/Events@";

export type JsonRefPath = `${typeof JsonRefPathPrefix}${string}`;
export const isJsonRefPath = (path: unknown): path is JsonRefPath => {
  return typeof path === "string" && path.startsWith(JsonRefPathPrefix);
};

export const entityNameToJsonRefPath = (entityName: string): JsonRefPath => {
  return `${JsonRefPathPrefixEntities}${entityName}`;
};
export const eventNameToJsonRefPath = (eventName: string): JsonRefPath => {
  return `${JsonRefPathPrefixEvents}${eventName}`;
};

export type DecodedJsonRefPath = {
  metaType: "entity" | "event";
  type: string;
};

export const decodeJsonRefPath = (
  path: string,
): DecodedJsonRefPath | undefined => {
  if (path.startsWith(JsonRefPathPrefixEntities)) {
    return {
      metaType: "entity",
      type: path.slice(JsonRefPathPrefixEntities.length),
    };
  }
  if (path.startsWith(JsonRefPathPrefixEvents)) {
    return {
      metaType: "event",
      type: path.slice(JsonRefPathPrefixEvents.length),
    };
  }
  return undefined;
};

export const isReferencingEntity = (path: JsonRefPath): boolean => {
  return path.startsWith(JsonRefPathPrefixEntities);
};

export const isReferencingEvent = (path: JsonRefPath): boolean => {
  return path.startsWith(JsonRefPathPrefixEvents);
};
