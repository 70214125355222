import { Pill } from "src/base-components/Pill";
import { ApiKeyType } from "src/clients/taktile-api";

type Props = {
  value: ApiKeyType[];
};

export const KeyType: React.FC<Props> = ({ value }) => {
  const isLive = value.some((v) => v === ApiKeyType.LIVE);
  const isSandbox = value.some((v) => v === ApiKeyType.SANDBOX);
  const isJobAdmin = value.some((v) => v === ApiKeyType.JOB_ADMIN);
  const isHistoryAdmin = value.some((v) => v === ApiKeyType.HISTORY_ADMIN);
  const isAuditLogs = value.some((v) => v === ApiKeyType.AUDIT_LOGS);

  return (
    <div className="flex flex-wrap gap-2 py-2.5 pr-4">
      {isLive && (
        <Pill variant="green">
          <Pill.Text>Live</Pill.Text>
        </Pill>
      )}
      {isSandbox && (
        <Pill variant="gray">
          <Pill.Text>Sandbox</Pill.Text>
        </Pill>
      )}
      {isJobAdmin && (
        <Pill variant="gray">
          <Pill.Text>Jobs</Pill.Text>
        </Pill>
      )}
      {isHistoryAdmin && (
        <Pill variant="gray">
          <Pill.Text>History</Pill.Text>
        </Pill>
      )}
      {isAuditLogs && (
        <Pill variant="gray">
          <Pill.Text>Audit Logs</Pill.Text>
        </Pill>
      )}
    </div>
  );
};
