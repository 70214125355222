import { capitalize } from "lodash";

import { Pill } from "src/base-components/Pill";
import { isFeatureFlagEnabled } from "src/router/featureFlags";

export const OmniboxFeatureFlagLabel: React.FC<{
  featureFlag: string;
}> = ({ featureFlag }) => {
  const isEnabled = isFeatureFlagEnabled(featureFlag);
  return (
    <div className="flex items-center gap-x-2">
      {capitalize(featureFlag).replace("-", " ")}
      {isEnabled && (
        <Pill>
          <Pill.Text>enabled</Pill.Text>
        </Pill>
      )}
    </div>
  );
};
