import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  faInfoCircle,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { Select } from "src/base-components/Select";
import { EnumColor } from "src/clients/features-control/api";
import { EntityEnumSchemaProperty } from "src/entities/queries";
import { enumColorOptions } from "src/eventsCatalogue/SchemaEditor/EnumValuesEditor";

export type EnumsForm = {
  properties: {
    [propertyName: string]: EntityEnumSchemaProperty;
  };
};

const VALIDATION_ERROR = "Value must be a string in quotes";

export const EnumValuesEditor = ({
  propertyName,
}: {
  propertyName: string;
}) => {
  const { formState } = useFormContext<EnumsForm>();
  const { fields, append, remove, move } = useFieldArray<EnumsForm>({
    name: `properties.${propertyName}._values`,
  });
  const [editableEnumValues, setEditableEnumValues] = useState([] as string[]);

  const fieldsRef = useRef(fields);

  fieldsRef.current = fields;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const fieldToMove = fields.findIndex((field) => field.id === active.id);
      const fieldToInsert = fields.findIndex((field) => field.id === over.id);
      if (fieldToMove !== -1 && fieldToInsert !== -1) {
        move(fieldToMove, fieldToInsert);
      }
    }
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setEditableEnumValues([]);
    }
  }, [formState.submitCount, formState.isSubmitSuccessful]);

  return (
    <div className="ml-2.5 flex flex-col gap-y-3 border-l border-gray-200 pl-4">
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={fields.map((field) => field.id)}>
          {fields.map((field, index) => (
            <EnumValueFields
              key={field.id}
              id={field.id}
              immutable={!editableEnumValues.includes(field.id)}
              index={index}
              propertyName={propertyName}
              onRemove={remove}
            />
          ))}
        </SortableContext>
      </DndContext>
      <div>
        <Button
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          disabled
          onClick={() => {
            append(
              {
                _internal_name: "",
                _color: EnumColor.GRAY,
              },
              {
                focusName: `properties.${propertyName}._values.${fields.length}._internal_name`,
              },
            );

            setTimeout(() => {
              const lastField = fieldsRef.current.at(-1);
              if (lastField) {
                setEditableEnumValues([...editableEnumValues, lastField.id]);
              }
            });
          }}
        >
          Add value
        </Button>
      </div>
    </div>
  );
};

const EnumValueFields = ({
  id,
  propertyName,
  index,
  immutable,
  onRemove,
}: {
  id: string;
  propertyName: string;
  index: number;
  immutable: boolean;
  onRemove: (index: number) => void;
}) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} className="flex items-start gap-x-2" style={style}>
      <div className="pt-1">
        <ReorderHandle color="text-gray-400" listeners={listeners} size="xs" />
      </div>
      <div>
        <Controller<EnumsForm, `properties.${string}._values.${number}._color`>
          name={`properties.${propertyName}._values.${index}._color`}
          render={({ field }) => (
            <Select
              disabled={immutable}
              options={enumColorOptions}
              value={field.value ?? EnumColor.GRAY}
              fullWidth
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className="flex-1 self-start">
        <Controller<
          EnumsForm,
          `properties.${string}._values.${number}._internal_name`
        >
          name={`properties.${propertyName}._values.${index}._internal_name`}
          render={({ field, fieldState }) => (
            <>
              <Input
                ref={field.ref}
                disabled={immutable}
                errored={!immutable && !!fieldState.error}
                suffixIcon={
                  immutable
                    ? undefined
                    : {
                        icon: faInfoCircle,
                        helpText:
                          "Newly added values cannot be changed once saved.",
                      }
                }
                value={field.value}
                fullWidth
                monospaced
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
              {!immutable && fieldState.error && (
                <ErrorHint>{fieldState.error.message}</ErrorHint>
              )}
            </>
          )}
          rules={{
            validate: (value, formValues) => {
              if (!value) return "Value is required";
              try {
                const parsedValue = JSON.parse(value);
                if (
                  typeof parsedValue !== "string" &&
                  typeof parsedValue !== "number"
                ) {
                  return VALIDATION_ERROR;
                }
              } catch {
                return VALIDATION_ERROR;
              }

              const allEnumValues =
                formValues.properties[propertyName]._values ?? [];
              // Check for duplicate values
              const thereIsDuplicate = allEnumValues.some(
                (enumValue) =>
                  enumValue._internal_name === value &&
                  enumValue !== allEnumValues[index],
              );
              if (thereIsDuplicate) {
                return "Value must be unique";
              }
            },
          }}
        />
      </div>
      <div className="pt-1">
        {!immutable && (
          <Icon
            color="text-gray-500 hover:text-red-600"
            dataLoc="enum-field-delete"
            icon={faTrashAlt}
            size="xs"
            onClick={() => onRemove(index)}
          />
        )}
      </div>
    </div>
  );
};
