import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { capitalize, get, groupBy, keyBy, noop } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";

import { getSampleReportsForAiNode } from "src/aiNode/utils";
import { ResourceSample } from "src/api/connectApi/types";
import {
  FlowT,
  FlowVersionFlowChild,
  FlowVersionT,
  SchemaTypesT,
} from "src/api/flowTypes";
import { useFlowVersionMockableNodes } from "src/api/flowVersionQueries";
import {
  Dataset,
  DatasetColumnGroupToRowDataGroupMap,
  DatasetColumnGroupValues,
  DatasetColumnGroups,
  DatasetRow,
  DesiredType,
  DesiredTypeValues,
  ExtendedDatasetColumnGroups,
  GLOBAL_FEATURES_TYPE,
} from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { EnvironmentsConfigT } from "src/baseConnectionNode/types";
import {
  EventConfigEnriched,
  PropertyDefinitionOutput,
} from "src/clients/features-control";
import { DatasetMockableNodes } from "src/constants/NodeDataTypes";
import { RowIndexCell } from "src/datasets/DatasetTable/RowIndexCell";
import {
  GroupHeader,
  Header,
  NoMenuHeader,
  MockColumn,
  SubGroupHeader,
} from "src/datasets/DatasetTable/cells";
import {
  stringifySelection,
  useDatasetEditTableActions,
} from "src/datasets/DatasetTable/stores";
import {
  DatasetContext,
  JSONValue,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import {
  ENTITY_ID_FIELD_NAME,
  MOCK_COLUMN_DISABLED_TOOLTIP,
  SUB_COLUMN_SEPARATOR,
  getAvailableColumns,
  getGroupColumns,
  getOutcomeDatasetColumns,
} from "src/datasets/DatasetTable/utils";
import {
  DeleteColumnPayload,
  PutColumnPayload,
  usePutColumns,
} from "src/datasets/api/queries";
import {
  DatasetIssue,
  DatasetIssues,
  DatasetIntegrationNode,
  isIntegrationNodeWithLiveConnection,
  buildUiSchemas,
  schemaTypeToDesiredType,
  getRefSchema,
} from "src/datasets/utils";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { findSchema, getSchemaIcon } from "src/entities/entityView/utils";
import {
  EntitySchemaProperty,
  EntitySchemaResource,
  useEntitySchemas,
} from "src/entities/queries";
import { PROPERTY_TYPES_TO_ICONS } from "src/eventsCatalogue/SchemaEditor/utils";
import { useEventSchemas } from "src/eventsCatalogue/queries";
import { findEventSchema, getEventIcon } from "src/eventsCatalogue/utils";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { getMockableNode } from "src/jobs/jobUtils";
import { getSampleReportsForManualReviewNode } from "src/manualReview/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { OutcomeType } from "src/outcomes/types";
import {
  getSampleReportsForFlowNode,
  getSampleReportsForLoopNode,
} from "src/parentFlowNodes/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  useFlowContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import {
  decodeJsonRefPath,
  isJsonRefPath,
  JsonRefPath,
  JsonRefPathPrefix,
} from "src/schema/jsonRefUtils";
import {
  EnumValueObject,
  SchemaUIT,
  SubSchemaUIT,
} from "src/schema/schemaMappingUtils";
import { logger } from "src/utils/logger";
import {
  isCustomConnectionNode,
  isInboundWebhookConnectionNode,
  isManualReviewConnectionNode,
  isDatabaseConnectionNode,
  isFlowNode,
  isLoopNode,
  isParentNode,
  isAiNode,
} from "src/utils/predicates";

const columnHelper = createColumnHelper<DatasetRow>();

const getColumnSize = (name: string) => name.length * 9;

const getWarningTooltip = (
  issue: DatasetIssue | undefined,
  flow: FlowT,
  version: FlowVersionT,
) => {
  if (issue?.issueType === "type-mismatch") {
    const schemaTypeString = issue.complexType
      ? `${capitalize(issue.complexType.metaType)}: ${issue.complexType.type} (${issue.schemaType})`
      : issue.schemaType;

    return {
      title: `This column cannot be used for testing [${flow.name}, ${version.name}]. The column type is \`${issue.datasetType}\`, schema input type is \`${schemaTypeString}\``,
    };
  }
};

const getColumnValueAccessor = (path: string) => (row: DatasetRow) =>
  get(row, path, undefined);

const useLocalSampleReports = (
  nodes: DatasetMockableNodes[],
  version?: FlowVersionFlowChild,
) => {
  return useMemo(() => {
    if (!version) {
      return {};
    }

    const schemasByNodeId = nodes.reduce(
      (acc, node) =>
        isParentNode(node)
          ? {
              ...acc,
              [node.id]: (() => {
                const childVersion = version.child_versions?.find(
                  (item) => item.id === node.data.child_flow_version_id,
                );
                if (childVersion) {
                  return buildUiSchemas(childVersion as FlowVersionT);
                }
                return { input: undefined, output: undefined };
              })(),
            }
          : acc,
      {} as Record<string, VersionSchemas>,
    );

    return nodes.reduce(
      (acc, node) => ({
        ...acc,
        [node.id]: (() => {
          if (isManualReviewConnectionNode(node)) {
            return getSampleReportsForManualReviewNode(node);
          }

          if (isFlowNode(node)) {
            return getSampleReportsForFlowNode(schemasByNodeId[node.id]);
          }

          if (isLoopNode(node)) {
            return getSampleReportsForLoopNode(schemasByNodeId[node.id]);
          }

          if (isAiNode(node)) {
            return getSampleReportsForAiNode(node);
          }

          return null;
        })(),
      }),
      {} as Record<string, ResourceSample[] | null>,
    );
  }, [nodes, version?.child_versions]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useAvailableIntegrationNodes = (
  version?: FlowVersionFlowChild,
): DatasetIntegrationNode[] => {
  const mockableNodes = useMemo(
    () => (version ? getMockableNode(version) : []),
    [version],
  );
  const childFlowVersionIds = useMemo(
    () =>
      mockableNodes
        .map((node) =>
          isParentNode(node)
            ? {
                nodeId: node.id,
                flowVersionId: node.data.child_flow_version_id,
              }
            : null,
        )
        .filter((value) => value && value.flowVersionId) as {
        nodeId: string;
        flowVersionId: string;
      }[],
    [mockableNodes],
  );

  const childMockableNodes = useFlowVersionMockableNodes(childFlowVersionIds);
  const allMockableNodes = useMemo(
    () => [
      ...mockableNodes,
      ...(childMockableNodes ? Object.values(childMockableNodes).flat() : []),
    ],
    [mockableNodes, childMockableNodes],
  );
  const localSampleReports = useLocalSampleReports(allMockableNodes, version);

  return useMemo(() => {
    const buildConfig = (
      node: DatasetMockableNodes,
    ): DatasetIntegrationNode => {
      return {
        id: node.id,
        name: node.data.label,
        provider:
          isParentNode(node) || isAiNode(node)
            ? node.type
            : node.data.providerResource.provider,
        resource:
          isParentNode(node) || isAiNode(node)
            ? null
            : node.data.providerResource.resource,
        mediaKey:
          (isCustomConnectionNode(node) ||
            isInboundWebhookConnectionNode(node)) &&
          node.data.mediaKey
            ? node.data.mediaKey
            : null,
        testingConfig: isCustomConnectionNode(node)
          ? node.data.config.testing
          : null,
        environmentsConfig:
          isCustomConnectionNode(node) ||
          isDatabaseConnectionNode(node) ||
          isInboundWebhookConnectionNode(node) ||
          isAiNode(node)
            ? (node.data.config.environments_config as EnvironmentsConfigT) // AiNodeV2 is using an untyped record here, hence the type conversion
            : null,
        localSampleReports: localSampleReports[node.id],
        mockableChildNodes: null,
      };
    };

    return mockableNodes.map((node) => {
      return {
        ...buildConfig(node),
        mockableChildNodes: isParentNode(node)
          ? (childMockableNodes?.[node.id]?.map((node) => buildConfig(node)) ??
            [])
          : null,
      };
    });
  }, [mockableNodes, childMockableNodes, localSampleReports]);
};

export const INDEX_COLUMN_ID = "3f40e3f6-e8e7-43a5-ad0d-8ed7604ab073";
export const INDEX_COLUMN_GROUP_ID = `row_index_group_${INDEX_COLUMN_ID}`;

export const useDatasetColumns = ({
  context,
  dataset,
  schemas,
  version,
  onColumnsDelete,
  onColumnRename,
  onColumnAdd,
  onAddSubMocks,
  onDeleteSubMocks,
  onColumnTypeChange,
  issues,
  onColumnFill,
  onAddAdditionalColumn,
  outcomeTypes,
}: {
  context: DatasetContext;
  dataset: Dataset;
  schemas: VersionSchemas;
  version?: FlowVersionT;
  outcomeTypes?: OutcomeType[];
  onColumnsDelete: (payloads: DeleteColumnPayload) => void;
  onColumnRename: (
    group: DatasetColumnGroups,
    oldName: string,
    newName: string,
    desiredType: DesiredType,
    hasSubflowMocks: boolean,
  ) => void;
  onColumnAdd: (name: string, group: DatasetColumnGroups) => void;
  onDeleteSubMocks: (
    subMockName: string | null,
    mockColumnName: string,
  ) => void;
  onAddSubMocks: (
    subMockNames: string[],
    mockColumnName: string,
    desiredType: Extract<DesiredType, "object" | "any">,
  ) => void;
  onColumnTypeChange: (
    name: string,
    group: DatasetColumnGroups,
    desiredType: DesiredType,
  ) => void;
  onColumnFill: (
    name: string,
    group: DatasetColumnGroups,
    value: JSONValue,
  ) => void;
  issues: DatasetIssues;
  onAddAdditionalColumn: () => void;
}) => {
  const { flow, workspace } = useFlowContext();
  const integrationNodes = useAvailableIntegrationNodes(version);
  const featureGates = useWorkspaceFeatureGates();
  const subflowMockColumns = useMemo(
    () =>
      dataset.mock_columns
        .filter((col) => col.use_subflow_mocks)
        .map((col) => col.name),
    [dataset.mock_columns],
  );

  const { input, auxiliary } = useMemo(
    () => getGroupColumns(dataset.input_columns, schemas.input, featureGates),
    [dataset.input_columns, schemas.input, featureGates],
  );

  const {
    availableInputColumns,
    availableMockColumns,
    availableOutputColumns,
    availableOutcomeColumns,
  } = useAvailableColumns(dataset, schemas, integrationNodes);

  const rowIndexCell: (props: CellContext<DatasetRow, unknown>) => ReactNode =
    useCallback(
      ({ row, table }) => (
        <RowIndexCell
          context={context}
          datasetId={dataset.id}
          index={row.index}
          readonly={!!table.options.meta?.readonly}
          row={row.original}
          scrollToBottom={table.options.meta?.scrollToBottom}
          subflowMockColumns={subflowMockColumns}
          versionName={version?.name}
        />
      ),
      [context, dataset.id, version?.name, subflowMockColumns],
    );

  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url!,
    options: {
      enabled:
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.entitiesEnabled,
    },
  });

  const { data: eventSchemas } = useEventSchemas({
    enabled:
      isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
      featureGates.featuresEventsEnabled,
  });

  return useMemo(() => {
    const isAuthoringDatasetContext = context === "authoring";

    const columns = [
      columnHelper.group({
        id: INDEX_COLUMN_GROUP_ID,
        header: () => <div className="h-8 w-full bg-gray-50" />,
        columns: [
          columnHelper.display({
            id: INDEX_COLUMN_ID,
            header: () => (
              <div className="flex h-8 w-full items-center justify-center" />
            ),
            cell: rowIndexCell,
            minSize: 28,
            maxSize: 28,
            enableResizing: false,
          }),
        ],
      }),
    ];
    const inputColumns = keyBy(dataset.input_columns, "name");
    const outputColumns = keyBy(dataset.output_columns, "name");
    const outcomeColumns = keyBy(dataset.outcome_columns, "name");
    const inputProperties = keyBy(schemas.input?.properties ?? [], "fieldName");
    const outputProperties = keyBy(
      schemas.output?.properties ?? [],
      "fieldName",
    );
    const outcomeProperties = getOutcomeDatasetColumns(outcomeTypes ?? []);

    const duplicatedIntegrationNodeNames = integrationNodes
      .filter((node) =>
        integrationNodes.some((n) => node.id !== n.id && node.name === n.name),
      )
      .map((node) => node.name);

    const outputSchemaIsEmpty = !schemas.output?.properties.length;

    const isColumnInTheOutputSchema = (name: string) => {
      const properties = schemas.output?.properties ?? [];

      return properties.map((p) => p.fieldName).includes(name);
    };

    if (input.length > 0) {
      columns.push(
        columnHelper.group({
          id: "input_data",
          header: () => <GroupHeader variant="gray">Input</GroupHeader>,
          columns: input.map((name) => {
            const columnIssue = issues[name];
            const id = `input_data.${name}` as const;
            if (
              isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
              (featureGates.featuresEventsEnabled ||
                featureGates.entitiesEnabled) &&
              schemas.input &&
              inputProperties[name]?.type.some(isJsonRefPath) &&
              inputColumns[name].desired_type === DesiredTypeValues.object
            ) {
              const jsonRefPath = inputProperties[name].type.find(
                isJsonRefPath,
              ) as JsonRefPath;
              const groupColumnDecodedJsonRefPath =
                decodeJsonRefPath(jsonRefPath);
              const entityOrEvent = getRefSchema(
                schemas.input?.$defs ?? {},
                jsonRefPath,
              );

              const isEventSchema =
                featureGates.featuresEventsEnabled &&
                isEventSchemaPath(jsonRefPath);

              let preparedJSONSchema;
              if (isEventSchema) {
                preparedJSONSchema = expandEventsEnums(
                  entityOrEvent,
                  schemas.input?.$defs ?? {},
                );
              } else if (featureGates.entitiesEnabled) {
                preparedJSONSchema = entityOrEvent;
              } else {
                preparedJSONSchema = { properties: [] };
              }

              const entityOrEventColumns = preparedJSONSchema.properties.map(
                (property) => {
                  const currentPropertyDecodedJsonRefPath = decodeJsonRefPath(
                    property.type[0],
                  );
                  const isRelationSchema = isRelationSchemaPath(
                    property.type[0],
                  );

                  return {
                    fieldName: property.fieldName,
                    desired_type: schemaTypeToDesiredType(property.type[0]),
                    required: property.required,
                    nullable:
                      property.fieldName === ENTITY_ID_FIELD_NAME &&
                      !isEventSchema
                        ? true
                        : property.type[1] === "null",
                    options: property.enum?.map((enumItem) => enumItem.value),
                    relation: isRelationSchema
                      ? currentPropertyDecodedJsonRefPath?.type
                      : undefined,
                    icon: isRelationSchema ? (
                      <ResourceIcon type={property.type[0]} />
                    ) : groupColumnDecodedJsonRefPath ? (
                      <ResoursePropertyIcon
                        fallbackType={property.type[0]}
                        property={property.fieldName}
                        resourceName={groupColumnDecodedJsonRefPath.type}
                        resourceType={groupColumnDecodedJsonRefPath.metaType}
                      />
                    ) : undefined,
                  };
                },
              );

              const subColumns =
                isEntitySchemaPath(jsonRefPath) &&
                featureGates.featuresEventsEnabled
                  ? [
                      ...entityOrEventColumns,
                      {
                        fieldName: "features",
                        desired_type: DesiredTypeValues.object,
                        required: false,
                        nullable: true,
                        options: undefined,
                        relation: undefined,
                        icon: undefined,
                      },
                    ]
                  : entityOrEventColumns;

              return columnHelper.group({
                id,
                header: ({ table }) => (
                  <SubGroupHeader
                    cellId={`-2_${id}`}
                    customIcon={<ResourceIcon type={jsonRefPath} />}
                    input={availableInputColumns}
                    integrationNode={null}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    typeChange={{
                      selectedType: inputColumns[name].desired_type,
                      onChangeType: (selectedType) =>
                        onColumnTypeChange(name, "input_columns", selectedType),
                      compatibleType:
                        columnIssue?.issueType === "type-mismatch"
                          ? columnIssue.schemaType
                          : undefined,
                    }}
                    warning={
                      // TODO: Do we want to check some specific case
                      undefined
                    }
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() => {
                      onColumnsDelete([{ name, group: "input_columns" }]);
                    }}
                    onDeleteSubMocks={noop}
                    onRename={(newName: string) =>
                      onColumnRename(
                        "input_columns",
                        name,
                        newName,
                        inputColumns[name].desired_type,
                        false,
                      )
                    }
                  >
                    {name}
                  </SubGroupHeader>
                ),
                meta: {
                  customId: id,
                  isColumnReorderable: true,
                  resource:
                    groupColumnDecodedJsonRefPath &&
                    (groupColumnDecodedJsonRefPath.metaType === "entity"
                      ? findSchema(
                          groupColumnDecodedJsonRefPath.type,
                          entitySchemas,
                        )
                      : findEventSchema(
                          groupColumnDecodedJsonRefPath.type,
                          eventSchemas,
                        )),
                },
                columns: subColumns.map((subColumn) => {
                  return columnHelper.accessor(
                    getColumnValueAccessor(`${id}.${subColumn.fieldName}`),
                    {
                      id: `${id}.${subColumn.fieldName}`,
                      header: () => (
                        <NoMenuHeader
                          icon={subColumn.icon}
                          type={subColumn.desired_type}
                          warning={
                            // TODO: Warnings?
                            undefined
                          }
                        >
                          {subColumn.fieldName}
                        </NoMenuHeader>
                      ),
                      size: getColumnSize(subColumn.fieldName),
                      meta: {
                        isColumnReorderable: false,
                        archetype: {
                          name: subColumn.fieldName,
                          desiredType: subColumn.desired_type,
                          nullable: subColumn.nullable,
                          required: subColumn.required,
                          disabled: false,
                          options: subColumn.options,
                          relationSchema: subColumn.relation,
                        },
                      },
                    },
                  );
                }),
              });
            } else {
              return columnHelper.accessor(getColumnValueAccessor(id), {
                id,
                header: ({ table }) => (
                  <Header
                    cellId={`-1_${id}`}
                    context={context}
                    input={availableInputColumns}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    type={inputColumns[name].desired_type}
                    typeChange={{
                      selectedType: inputColumns[name].desired_type,
                      onChangeType: (selectedType) =>
                        onColumnTypeChange(name, "input_columns", selectedType),
                      compatibleType:
                        columnIssue?.issueType === "type-mismatch"
                          ? columnIssue.schemaType
                          : undefined,
                    }}
                    warning={
                      version
                        ? getWarningTooltip(issues[name], flow, version)
                        : undefined
                    }
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() =>
                      onColumnsDelete([{ name, group: "input_columns" }])
                    }
                    onRename={(newName: string) =>
                      onColumnRename(
                        "input_columns",
                        name,
                        newName,
                        inputColumns[name].desired_type,
                        false,
                      )
                    }
                  >
                    {name}
                  </Header>
                ),
                size: getColumnSize(name),
                meta: {
                  isColumnReorderable: true,
                  archetype: {
                    name,
                    desiredType: inputColumns[name].desired_type,
                    nullable: inputProperties[name]?.type[1] === "null",
                    required: inputProperties[name]?.required ?? false,
                    options:
                      inputProperties[name]?.enum?.map(
                        (enumItem) => enumItem.value,
                      ) ?? null,
                    columnIssue: issues[name],
                  },
                },
              });
            }
          }),
        }),
      );
    }

    if (isAuthoringDatasetContext && dataset.mock_columns.length > 0) {
      const mockColumns = keyBy(dataset.mock_columns, "name");
      const childColumns = dataset.mock_columns.filter((childColumn) =>
        dataset.mock_columns.find(
          (parentColumn) =>
            parentColumn.use_subflow_mocks &&
            childColumn.name.startsWith(
              `${parentColumn.name}${SUB_COLUMN_SEPARATOR}`,
            ),
        ),
      );
      const topLevelColumns = dataset.mock_columns.filter(
        (col) => !childColumns.includes(col),
      );

      const buildAccessor = (
        name: string,
        desiredType: DesiredType,
        prefix?: string,
      ) => {
        const parentIntegrationNode = prefix
          ? integrationNodes.find((node) => node.name === prefix)
          : undefined;
        const matchingIntegrationNode = (() => {
          if (prefix) {
            const parentIntegrationNode = integrationNodes.find(
              (node) => node.name === prefix,
            );
            const [_, nonPrefixedName] = name.split(SUB_COLUMN_SEPARATOR);
            return parentIntegrationNode?.mockableChildNodes?.find(
              (node) => node.name === nonPrefixedName,
            );
          } else {
            return integrationNodes.find((node) => node.name === name);
          }
        })();

        const qualifiedName = name;
        const id = `mock_data.${qualifiedName}`;
        return columnHelper.accessor((row) => row.mock_data?.[qualifiedName], {
          id,
          header: ({ table }) => {
            const columnDisabled =
              !table.options.meta?.readonly &&
              isIntegrationNodeWithLiveConnection(matchingIntegrationNode);
            return (
              <Header
                cellId={`-1_${id}`}
                context={context}
                disabled={columnDisabled}
                fillMockColumn={
                  matchingIntegrationNode
                    ? {
                        integrationNode: matchingIntegrationNode,
                        onFill: (value) => {
                          onColumnFill(qualifiedName, "mock_columns", value);
                        },
                      }
                    : undefined
                }
                input={availableInputColumns}
                mock={availableMockColumns}
                outcome={availableOutcomeColumns}
                output={availableOutputColumns}
                readonly={!!table.options.meta?.readonly}
                type={desiredType}
                warning={
                  columnDisabled
                    ? MOCK_COLUMN_DISABLED_TOOLTIP
                    : duplicatedIntegrationNodeNames.includes(name)
                      ? {
                          title: `More than one Integration Node is called "${name}". Nodes with the same name share mock data.`,
                        }
                      : undefined
                }
                onAddAdditionalColumn={onAddAdditionalColumn}
                onAddSubMocks={onAddSubMocks}
                onColumnAdd={onColumnAdd}
                onDelete={() => {
                  if (prefix) {
                    onDeleteSubMocks(name, prefix);
                  } else {
                    onColumnsDelete([
                      {
                        name: qualifiedName,
                        group: "mock_columns",
                      },
                    ]);
                  }
                }}
                onRename={(newName: string) => {
                  onColumnRename(
                    "mock_columns",
                    name,
                    prefix
                      ? `${prefix}${SUB_COLUMN_SEPARATOR}${newName}`
                      : newName,
                    mockColumns[name].desired_type,
                    mockColumns[name].use_subflow_mocks,
                  );
                }}
              >
                {prefix ? name.split(SUB_COLUMN_SEPARATOR)[1] : name}
              </Header>
            );
          },
          size: getColumnSize(qualifiedName),
          meta: {
            isColumnReorderable: true,
            archetype: {
              name: qualifiedName,
              desiredType,
              nullable: false,
              required: false,
              matchingIntegrationNode,
              matchingParentIntegrationNode: parentIntegrationNode,
              disabled: isIntegrationNodeWithLiveConnection(
                matchingIntegrationNode,
              ),
            },
          },
        });
      };

      columns.push(
        columnHelper.group({
          id: "mock_data",
          header: () => (
            <GroupHeader variant="indigo">Mock external data</GroupHeader>
          ),
          columns: topLevelColumns.map((topLevelColumn) => {
            const ownChildColumns = childColumns.filter(
              (childColumn) =>
                childColumn.name.startsWith(topLevelColumn.name) &&
                childColumn.desired_type === topLevelColumn.desired_type,
            );
            if (
              ownChildColumns.length > 0 &&
              topLevelColumn.use_subflow_mocks
            ) {
              const parentColumn = topLevelColumn;
              const id =
                ownChildColumns.length > 0
                  ? `mock_data.${parentColumn.name}`
                  : `placeholder_mock_data-${parentColumn.name}`;
              return columnHelper.group({
                id,
                header: ({ table }) => {
                  const matchingIntegrationNode = integrationNodes.find(
                    (node) => node.name === parentColumn.name,
                  );
                  return (
                    <SubGroupHeader
                      cellId={`-2_mock_data.${parentColumn.name}`}
                      desiredType={parentColumn.desired_type}
                      input={availableInputColumns}
                      integrationNode={matchingIntegrationNode ?? null}
                      mock={availableMockColumns}
                      outcome={availableOutcomeColumns}
                      output={availableOutputColumns}
                      readonly={!!table.options.meta?.readonly}
                      warning={
                        duplicatedIntegrationNodeNames.includes(
                          parentColumn.name,
                        )
                          ? {
                              title: `More than one Integration Node is called "${parentColumn.name}". Nodes with the same name share mock data.`,
                            }
                          : undefined
                      }
                      onAddAdditionalColumn={onAddAdditionalColumn}
                      onColumnAdd={onColumnAdd}
                      onDelete={() =>
                        onColumnsDelete([
                          {
                            name: parentColumn.name,
                            group: "mock_columns",
                          },
                        ])
                      }
                      onDeleteSubMocks={() =>
                        onDeleteSubMocks(null, parentColumn.name)
                      }
                      onRename={(newName: string) => {
                        onColumnRename(
                          "mock_columns",
                          parentColumn.name,
                          newName,
                          parentColumn.desired_type,
                          true,
                        );
                      }}
                    >
                      {parentColumn.name}
                    </SubGroupHeader>
                  );
                },
                meta: {
                  isColumnReorderable: false,
                  customId: id,
                },
                columns:
                  ownChildColumns.length > 0
                    ? ownChildColumns.map((childColumn) =>
                        buildAccessor(
                          childColumn.name,
                          childColumn.desired_type,
                          parentColumn.name,
                        ),
                      )
                    : [
                        buildAccessor(
                          parentColumn.name,
                          parentColumn.desired_type,
                        ),
                      ],
              });
            } else {
              return buildAccessor(
                topLevelColumn.name,
                topLevelColumn.desired_type,
              );
            }
          }),
        }),
      );
    }

    if (isAuthoringDatasetContext && dataset.output_columns.length > 0) {
      columns.push(
        columnHelper.group({
          id: "output_data",
          header: () => (
            <GroupHeader variant="green">Expected Output</GroupHeader>
          ),
          columns: dataset.output_columns.map(({ name }) => {
            const columnIssue = issues[name];
            const id = `output_data.${name}`;
            return columnHelper.accessor(
              getColumnValueAccessor(`output_data.${name}`),
              {
                id,
                header: ({ table }) => (
                  <Header
                    cellId={`-1_${id}`}
                    context={context}
                    input={availableInputColumns}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    type={outputColumns[name].desired_type}
                    typeChange={{
                      selectedType: outputColumns[name].desired_type,
                      onChangeType: (selectedType) =>
                        onColumnTypeChange(
                          name,
                          "output_columns",
                          selectedType,
                        ),
                      compatibleType:
                        columnIssue?.issueType === "type-mismatch"
                          ? columnIssue.schemaType
                          : undefined,
                    }}
                    warning={
                      !outputSchemaIsEmpty && !isColumnInTheOutputSchema(name)
                        ? {
                            title: `"${name}" is absent from your output schema. It won't be included in the flow's output and will fail to match the expected output`,
                          }
                        : undefined
                    }
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() =>
                      onColumnsDelete([{ name, group: "output_columns" }])
                    }
                    onRename={(newName: string) =>
                      onColumnRename(
                        "output_columns",
                        name,
                        newName,
                        outputColumns[name].desired_type,
                        false,
                      )
                    }
                  >
                    {name}
                  </Header>
                ),
                size: getColumnSize(name),
                meta: {
                  isColumnReorderable: true,
                  archetype: {
                    name,
                    desiredType: outputColumns[name].desired_type,
                    nullable: true,
                    required: false,
                    options:
                      outputProperties[name]?.enum?.map(
                        (enumItem) => enumItem.value,
                      ) ?? null,
                    columnIssue: issues[name],
                  },
                },
              },
            );
          }),
        }),
      );
    }

    if (auxiliary.length > 0) {
      columns.push(
        columnHelper.group({
          id: "auxiliary_data",
          header: () => (
            <GroupHeader variant="dark-gray">
              Additional fields
              <Tooltip
                body={
                  isAuthoringDatasetContext
                    ? 'When an input column name does not match the name of any field in the input schema it becomes an "Additional field". This data is not available inside the flow but is viewable inside the Inspect Data table for each node.'
                    : 'When an input column name does not match the name of any field in the input schema of the job decision flow version, it becomes an "Additional field". This data is not available inside the flow.'
                }
                footerAction={
                  isAuthoringDatasetContext
                    ? {
                        text: "Read more",
                        onClick: () =>
                          window.open(
                            "https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1",
                            "_blank",
                          ),
                      }
                    : undefined
                }
                placement="bottom"
                asChild
              >
                <Icon
                  color="text-gray-500 hover:text-gray-700"
                  icon={faInfoCircle}
                  size="xs"
                />
              </Tooltip>
            </GroupHeader>
          ),
          columns: auxiliary.map((name) => {
            const id = `input_data.${name}`;

            return columnHelper.accessor(
              getColumnValueAccessor(`input_data.${name}`),
              {
                id,
                header: ({ table }) => (
                  <Header
                    cellId={`-1_${id}`}
                    context={context}
                    input={availableInputColumns}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    type={inputColumns[name].desired_type}
                    typeChange={{
                      selectedType: inputColumns[name].desired_type,
                      onChangeType: (selectedType) =>
                        onColumnTypeChange(name, "input_columns", selectedType),
                    }}
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() =>
                      onColumnsDelete([{ name, group: "input_columns" }])
                    }
                    onRename={(newName: string) =>
                      onColumnRename(
                        "input_columns",
                        name,
                        newName,
                        inputColumns[name].desired_type,
                        false,
                      )
                    }
                  >
                    {name}
                  </Header>
                ),
                size: getColumnSize(name),
                meta: {
                  isColumnReorderable: true,
                  archetype: {
                    name,
                    desiredType: inputColumns[name].desired_type,
                    nullable: true,
                    required: false,
                    columnIssue: issues[name],
                  },
                },
              },
            );
          }),
        }),
      );
    }

    if (
      isAuthoringDatasetContext &&
      (dataset.outcome_columns ?? []).length > 0
    ) {
      const outcomesByTypeKey = groupBy(
        outcomeColumns,
        (outcome) => outcome.name.split(SUB_COLUMN_SEPARATOR)[0],
      );
      columns.push(
        columnHelper.group({
          id: "outcome_data",
          header: () => <GroupHeader variant="green">Outcomes</GroupHeader>,
          columns: Object.entries(outcomesByTypeKey).map(
            ([name, outcomeColumns]) => {
              const id = `outcome_data.${name}`;

              return columnHelper.group({
                id,
                header: ({ table }) => (
                  <SubGroupHeader
                    cellId={`-2_${id}`}
                    input={availableInputColumns}
                    integrationNode={null}
                    mock={availableMockColumns}
                    outcome={availableOutcomeColumns}
                    output={availableOutputColumns}
                    readonly={!!table.options.meta?.readonly}
                    warning={
                      // If outcome types has no this key
                      !outcomeProperties.some(
                        (column) => column.outcomeKey === name,
                      )
                        ? {
                            title:
                              "This outcome key is not defined in your Flow Outcome types",
                            body: "This outcome key is not defined in your Flow Outcome types. Add it to your Flow's Outcome types or delete if you do not use it.",
                          }
                        : undefined
                    }
                    onAddAdditionalColumn={onAddAdditionalColumn}
                    onColumnAdd={onColumnAdd}
                    onDelete={() => {
                      onColumnsDelete(
                        outcomeColumns.map((outcomeColumn) => ({
                          name: outcomeColumn.name,
                          group: "outcome_columns",
                        })),
                      );
                    }}
                    onDeleteSubMocks={noop}
                  >
                    {name}
                  </SubGroupHeader>
                ),
                meta: {
                  isColumnReorderable: false,
                  customId: id,
                },
                columns: outcomeColumns.map((outcomeColumn) => {
                  const [_outcomeKey, outcomeField] =
                    outcomeColumn.name.split(SUB_COLUMN_SEPARATOR);

                  const outcomeProperty = outcomeProperties.find(
                    (property) => property.columnName === outcomeColumn.name,
                  )?.property;

                  return columnHelper.accessor(
                    getColumnValueAccessor(
                      `outcome_data.${outcomeColumn.name}`,
                    ),
                    {
                      id: `outcome_data.${outcomeColumn.name}`,
                      header: () => {
                        return (
                          <NoMenuHeader
                            type={outcomeColumn.desired_type}
                            warning={
                              !outcomeProperty
                                ? {
                                    title: `"${outcomeField}" is absent from your outcome types`,
                                  }
                                : undefined
                            }
                          >
                            {outcomeField}
                          </NoMenuHeader>
                        );
                      },
                      size: getColumnSize(outcomeField),
                      meta: {
                        isColumnReorderable: true,
                        archetype: {
                          name: outcomeField,
                          desiredType: outcomeColumn.desired_type,
                          nullable: false,
                          required: false,
                          disabled: false,
                          options: outcomeProperty?.enum?.map(
                            (enumItem) => enumItem.value,
                          ),
                        },
                      },
                    },
                  );
                }),
              });
            },
          ),
        }),
      );
    }

    return {
      columns,
      availableInputColumns,
      availableMockColumns,
      availableOutputColumns,
    };
  }, [
    context,
    rowIndexCell,
    dataset.input_columns,
    dataset.output_columns,
    dataset.outcome_columns,
    dataset.mock_columns,
    schemas.input,
    schemas.output?.properties,
    outcomeTypes,
    integrationNodes,
    input,
    auxiliary,
    availableInputColumns,
    availableMockColumns,
    availableOutputColumns,
    issues,
    availableOutcomeColumns,
    onAddAdditionalColumn,
    onColumnAdd,
    onColumnsDelete,
    version,
    flow,
    onColumnTypeChange,
    onColumnRename,
    onAddSubMocks,
    onColumnFill,
    onDeleteSubMocks,
  ]);
};

type UseColumnAddHandlerArgs = {
  dataset?: Dataset;
  flowId: string;
  baseUrl?: string;
  schemas: VersionSchemas;
};
export const useColumnAddHandler = ({
  dataset,
  flowId,
  baseUrl,
  schemas,
}: UseColumnAddHandlerArgs) => {
  const putColumns = usePutColumns(dataset?.id ?? "", flowId, baseUrl);
  const { selectCell } = useDatasetEditTableActions();
  const featureGates = useWorkspaceFeatureGates();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId,
  });

  const columnAddHandler = useCallback(
    async (
      name: string,
      group: ExtendedDatasetColumnGroups,
      desiredType?: DesiredType,
    ) => {
      let putColumnPayload: PutColumnPayload | PutColumnPayload[];
      const isGlobalFeatures =
        name === GLOBAL_FEATURES_TYPE &&
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
        featureGates.featuresEventsEnabled;
      if (group === "input_columns" || group === "output_columns") {
        const schemaKey = group === "input_columns" ? "input" : "output";
        const schemaProperty = schemas[schemaKey]?.properties.find(
          (property) => property.fieldName === name,
        );
        if (isGlobalFeatures) {
          desiredType = DesiredTypeValues.object;
        } else if (!schemaProperty) {
          logger.error("could not find column to add in the schema");
          return;
        } else {
          desiredType =
            desiredType ?? schemaTypeToDesiredType(schemaProperty.type[0]);
        }

        putColumnPayload = [
          {
            column: {
              name,
              desired_type: desiredType,
              use_subflow_mocks: false,
            },
            group,
          },
        ];
      } else if (group === DatasetColumnGroupValues.mock_columns) {
        putColumnPayload = [
          {
            column: {
              name,
              desired_type: desiredType ?? DesiredTypeValues.object,
              use_subflow_mocks: false,
            },
            group,
          },
        ];
      } else if (group === "additional_columns") {
        if (!desiredType) {
          logger.error("desiredType must be provided for additional columns");
          return;
        }
        // additional column is basically input column which is not in the schema
        putColumnPayload = [
          {
            column: {
              name,
              desired_type: desiredType,
              use_subflow_mocks: false,
            },
            group: DatasetColumnGroupValues.input_columns,
          },
        ];
      } else if (group === DatasetColumnGroupValues.outcome_columns) {
        if (!dataset) {
          logger.error("dataset not found");
          return;
        }

        const outcomeColumns = getOutcomeDatasetColumns(outcomeTypes ?? []);

        const columnsToAdd = outcomeColumns.filter(
          (col) =>
            col.outcomeKey === name &&
            !dataset?.outcome_columns.some((c) => c.name === col.columnName),
        );

        putColumnPayload = columnsToAdd.map((column) => {
          const desiredType = column.property.type[0] ?? "string";

          return {
            column: {
              name: column.columnName,
              desired_type: desiredType as DesiredType,
              use_subflow_mocks: false,
            },
            group,
          };
        });
      } else {
        throw Error("Invalid column group type provided");
      }
      try {
        await putColumns.mutateAsync(putColumnPayload);

        const columnGroup =
          group === "additional_columns" ? "input_columns" : group;
        const prefix = DatasetColumnGroupToRowDataGroupMap[columnGroup];
        const cellIdToJumpTo = stringifySelection(-1, `${prefix}.${name}`);
        selectCell(cellIdToJumpTo);
      } catch (e) {
        toastActions.failure({
          title: `Failed to add column "${name}"`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      putColumns.mutateAsync,
      schemas.input,
      outcomeTypes,
      dataset?.outcome_columns,
    ],
  );

  const subMocksColumnAddHandler = useCallback(
    async (
      subMockNames: string[],
      mockColumnName: string,
      desiredType: Extract<DesiredType, "object" | "any">,
    ) => {
      const putColumnPayload: PutColumnPayload = [
        {
          column: {
            name: mockColumnName,
            desired_type: desiredType,
            use_subflow_mocks: subMockNames.length > 0,
          },
          group: DatasetColumnGroupValues.mock_columns,
        },
      ];

      const subMockColumns: PutColumnPayload = subMockNames.map((name) => ({
        column: {
          name: `${mockColumnName}${SUB_COLUMN_SEPARATOR}${name}`,
          desired_type: desiredType,
          use_subflow_mocks: false,
        },
        group: DatasetColumnGroupValues.mock_columns,
      }));

      try {
        await putColumns.mutateAsync([...putColumnPayload, ...subMockColumns]);
        const prefix = DatasetColumnGroupToRowDataGroupMap.mock_columns;
        const cellIdToJumpTo = stringifySelection(
          -1,
          `${prefix}${SUB_COLUMN_SEPARATOR}${mockColumnName}`,
        );
        selectCell(cellIdToJumpTo);
      } catch (e) {
        toastActions.failure({
          title: `Failed to add column "${mockColumnName}"`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [putColumns.mutateAsync, schemas.input],
  );

  return {
    columnAddHandler,
    subMocksColumnAddHandler,
    isLoading: putColumns.isPending,
  };
};

export const useAvailableColumns = (
  dataset: Dataset,
  schemas: VersionSchemas,
  integrationNodes: DatasetIntegrationNode[],
) => {
  const { flow } = useFlowContext();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });
  const featureGates = useWorkspaceFeatureGates();
  const { inputAvailable, outputAvailable, mockAvailable, outcomeAvailable } =
    useMemo(
      () =>
        getAvailableColumns(
          dataset,
          schemas,
          integrationNodes,
          outcomeTypes ?? [],
          featureGates,
        ),
      [dataset, integrationNodes, schemas, outcomeTypes, featureGates],
    );

  const mockAvailableWithProvider: MockColumn[] = useMemo(() => {
    const integrationNodesByName = keyBy(integrationNodes, "name");
    const findMockableChildNode = (parentName: string, childName: string) => {
      return integrationNodesByName[parentName].mockableChildNodes?.find(
        (childNode) => childNode.name === childName,
      );
    };
    // Make this unique so a node name doesn't
    // appear more than once
    return mockAvailable.map((mockColumn) => {
      return {
        name: mockColumn.name,
        provider: integrationNodesByName[mockColumn.name]?.provider,
        mediaKey: integrationNodesByName[mockColumn.name]?.mediaKey,
        subMocks:
          mockColumn.subMocks?.map((subMockName) => ({
            name: subMockName,
            provider: findMockableChildNode(mockColumn.name, subMockName)
              ?.provider!,
            mediaKey: findMockableChildNode(mockColumn.name, subMockName)
              ?.mediaKey!,
          })) ?? null,
      };
    });
  }, [integrationNodes, mockAvailable]);

  return useMemo(() => {
    const availableInputColumns = {
      inputFields: inputAvailable,
      schemaIsEmpty: schemas.input?.properties.length === 0,
    };
    const availableMockColumns = {
      mockFields: mockAvailableWithProvider,
      integrationNodesExist: integrationNodes.length !== 0,
    };
    const availableOutputColumns = {
      outputFields: outputAvailable,
      schemaIsEmpty: schemas.output?.properties.length === 0,
    };
    const availableOutcomeColumns = {
      outcomeFields: outcomeAvailable,
      thereAreNoOutcomes: outcomeTypes?.length === 0,
    };

    return {
      availableInputColumns,
      availableMockColumns,
      availableOutputColumns,
      availableOutcomeColumns,
    };
  }, [
    inputAvailable,
    schemas.input?.properties.length,
    schemas.output?.properties.length,
    mockAvailableWithProvider,
    integrationNodes.length,
    outputAvailable,
    outcomeAvailable,
    outcomeTypes?.length,
  ]);
};

/**
 * Event schema is referenced sub-schema in input schema.
 * But it also can have its own sub-schemas with enums,
 * so we need to expand it to include the enums.
 */
const expandEventsEnums = (
  schema: SubSchemaUIT,
  schemasDefs: SchemaUIT["$defs"],
) => ({
  ...schema,
  properties: schema.properties.map((property) => {
    const subSchema =
      isJsonRefPath(property.type[0]) &&
      getRefSchema(schemasDefs, property.type[0]);

    if (subSchema && "enum" in subSchema && subSchema.enum) {
      return {
        ...property,
        type: ["enum", property.type[1]] as const,
        enum: subSchema.enum.map(
          (enumItem) =>
            ({
              value: JSON.stringify(enumItem),
            }) as EnumValueObject,
        ),
      };
    }
    return property;
  }),
});

const isEntitySchemaPath = (refPath: string) => {
  return refPath.startsWith(`${JsonRefPathPrefix}Entities@`);
};

const isEventSchemaPath = (refPath: string) => {
  return refPath.startsWith(`${JsonRefPathPrefix}Events@`);
};

const isRelationSchemaPath = (refPath: string) => {
  return isEntitySchemaPath(refPath) || isEventSchemaPath(refPath);
};

const ResoursePropertyIcon: React.FC<{
  resourceType: string;
  resourceName: string;
  property: string;
  fallbackType: SchemaTypesT;
}> = ({ resourceType, resourceName, property, fallbackType }) => {
  const { workspace } = useWorkspaceContext();
  const isEntity = resourceType === "entity";
  const { data: entitiesSchemasData, isLoading: isEntitiesSchemasLoading } =
    useEntitySchemas({
      baseUrl: workspace.base_url!,
      options: {
        enabled: isEntity,
      },
    });
  const { data: eventsSchemasData, isLoading: isEventsSchemasLoading } =
    useEventSchemas({
      enabled: !isEntity,
    });

  const schema = isEntity
    ? findSchema(resourceName, entitiesSchemasData)
    : eventsSchemasData?.find((event) => event.event_type === resourceName);

  const propertyType = isEntity
    ? (schema?.properties[property] as EntitySchemaProperty | undefined)?._type
    : (schema?.properties[property] as PropertyDefinitionOutput | undefined)
        ?.type;

  if (isEntitiesSchemasLoading || isEventsSchemasLoading) {
    return <SkeletonPlaceholder height="h-2.5" width="w-2.5" />;
  }

  const icon =
    PROPERTY_TYPES_TO_ICONS[
      propertyType as keyof typeof PROPERTY_TYPES_TO_ICONS
    ] ??
    PROPERTY_TYPES_TO_ICONS[
      fallbackType as keyof typeof PROPERTY_TYPES_TO_ICONS
    ];

  if (!icon) {
    // Fallback if for some reason couldn't find the property type
    return null;
  }

  return <Icon color="text-gray-400" icon={icon} padding={false} size="2xs" />;
};

const ResourceIcon: React.FC<{ type: string }> = ({ type }) => {
  const [resourceType, resourceName] = type
    .slice(JsonRefPathPrefix.length)
    .split("@");

  const { workspace } = useWorkspaceContext();
  const { data: entitiesSchemasData, isLoading: isEntitiesSchemasLoading } =
    useEntitySchemas({
      baseUrl: workspace.base_url!,
      options: {
        enabled: isEntitySchemaPath(type),
      },
    });
  const { data: eventsSchemasData, isLoading: isEventsSchemasLoading } =
    useEventSchemas({
      enabled: isEventSchemaPath(type),
    });

  const schema = isEntitySchemaPath(type)
    ? findSchema(resourceName, entitiesSchemasData)
    : eventsSchemasData?.find((event) => event.event_type === resourceName);

  const schemaIcon =
    schema &&
    (isEntitySchemaPath(type)
      ? getSchemaIcon(schema as EntitySchemaResource)
      : getEventIcon(schema as EventConfigEnriched));

  if (!schemaIcon && !isEntitiesSchemasLoading && !isEventsSchemasLoading) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      title={`${
        {
          Events: "Event",
          Entities: "Entity",
        }[resourceType]
      }: ${resourceName}`}
    >
      {isEntitiesSchemasLoading || isEventsSchemasLoading || !schemaIcon ? (
        <SkeletonPlaceholder height="h-2.5" width="w-2.5" />
      ) : (
        <Icon
          color="text-gray-400"
          icon={schemaIcon}
          padding={false}
          size="2xs"
        />
      )}
    </Tooltip>
  );
};
