import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

import { useNodeResultData } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DataRow, ErroredRow, NodeExecutionMetadata } from "src/api/types";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { getAccessedKeys } from "src/dataTable/DetailedView/DetailedView";
import { InspectData } from "src/dataTable/DetailedView/tabs/InspectData";
import { hasNodeAccessedFields } from "src/dataTable/DetailedView/tabs/utils";
import { useFieldToSourceNodeMappings } from "src/databaseDebugPopover/utils";
import { useRightPane } from "src/flowContainer/hooks/useRightPane";
import { IntermediateResultTableWrapper } from "src/nodeEditor/IntermediateResultTableWrapper";
import { RunInformationPill } from "src/nodeEditor/RunInformationPill";
import { NodeEditorOptions } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { useFlowOuput, useNodeRunStates } from "src/store/runState/RunState";

type Props = {
  showErrorInEditor: (error: ErroredRow) => void;
  openEditor: () => void;
};

export const InspectDataPanelV2: React.FC<Props> = observer(
  ({ showErrorInEditor, openEditor }) => {
    const { selectedNode } = useGraphStore();
    const allRunStates = useNodeRunStates();
    const flowOutput = useFlowOuput();

    const { fieldNameToSourceNode } = useFieldToSourceNodeMappings();
    const { setRightPaneTab } = useRightPane();

    const runState =
      selectedNode?.type === NODE_TYPE.OUTPUT_NODE && flowOutput
        ? flowOutput
        : allRunStates.get(selectedNode?.id ?? "");

    const { workspace } = useAuthoringContext();

    const nodeResultDataQuery = useNodeResultData({
      baseUrl: workspace.base_url!,
      decisionId:
        runState && "decisionId" in runState ? runState.decisionId : "",
      nodeId: selectedNode?.id ?? "",
    });
    if (
      runState &&
      (runState.type === "historical-data" ||
        runState.type === "historical-error" ||
        (runState.type === "historical-pending" &&
          selectedNode?.type === NODE_TYPE.LOOP_NODE))
    ) {
      let tableData: DataRow = {};
      const nodeExecutionMetadata: NodeExecutionMetadata | undefined =
        runState?.nodeExecutionMetadata;
      if (
        runState.type === "historical-data" ||
        runState.type === "historical-pending"
      ) {
        tableData = nodeResultDataQuery.data ?? {};
      } else {
        tableData =
          isEmpty(nodeResultDataQuery.data) &&
          selectedNode?.type !== NODE_TYPE.LOOP_NODE
            ? (runState.error.data ?? {})
            : (nodeResultDataQuery.data ?? {});
      }

      return (
        <div className="px-6 py-4">
          <RunInformationPill
            openSqlResponseTab={() =>
              setRightPaneTab(NodeEditorOptions.SqlResponse)
            }
          />
          {selectedNode && (
            <div className="mt-2">
              <InspectData
                accessedFields={getAccessedKeys(
                  nodeExecutionMetadata?.[selectedNode.id],
                )}
                isFetching={nodeResultDataQuery.isLoading}
                rowData={tableData}
                withAccessedFields={hasNodeAccessedFields(selectedNode)}
                isHistorical
              />
            </div>
          )}
        </div>
      );
    }

    if (!runState || runState.type !== "test-run") {
      return (
        <div className="px-6 pt-6">
          <RunInformationPill />
        </div>
      );
    }

    return (
      <div className="flex h-full flex-col pl-6 pt-6">
        <IntermediateResultTableWrapper
          fieldNameToSourceNode={fieldNameToSourceNode}
          openEditor={openEditor}
          showErrorInEditor={showErrorInEditor}
          testResult={runState.testResult}
        />
      </div>
    );
  },
);
