import { isKeyboardEvent } from "@dnd-kit/utilities";
import { useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";

import { DesiredType } from "src/api/types";
import { useIsEditingCell } from "src/datasets/DatasetTable/stores";
import { useCustomScrollEmitter } from "src/datasets/DatasetTable/useCustomScroll";
import { CellId } from "src/datasets/DatasetTable/utils";
import { ValidationResult } from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import { getErrorDetails } from "src/datasets/components/errorUtils";

type DefaultInputCellProps = {
  cellId: CellId;
  disabled: boolean;
  error: ValidationResult | undefined;
  value: string;
  onChange: (value: string) => void;
  onSetLocalValue: (value: string) => void;
  onResetError: () => void;
  warning: boolean;
  postfix?: React.ReactNode;
  desiredType: DesiredType;
};

export const DefaultInputCell: React.FC<DefaultInputCellProps> = ({
  cellId,
  disabled,
  error,
  value,
  onChange,
  onSetLocalValue,
  onResetError,
  warning,
  postfix,
  desiredType,
}) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const editing = useIsEditingCell(cellId);

  useEffect(() => {
    if (editing && inputRef.current) {
      const inputEl = inputRef.current;
      inputEl.focus();
    }
  }, [editing]);

  /*
   * If the cell type is string we want to start a string
   * for the user on cell focus
   */
  useEffect(() => {
    if (desiredType === "string" && editing && value.trim() === "") {
      onSetLocalValue('""');
    }
    // Deliberately miss value here so its only run on cell focus
    // and not if the user removes the entire content of the cell
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, desiredType]);

  // Put the cursor between the quotes
  // when building a string
  useEffect(() => {
    if (editing && value === '""') {
      inputRef.current?.setSelectionRange(1, 1);
    }
  }, [editing, value]);

  useCustomScrollEmitter(inputRef, cellRef, { editing });

  return (
    <CellWrapper
      ref={cellRef}
      cellId={cellId}
      disabled={disabled}
      error={getErrorDetails(error, value, onChange)}
      invalid={!!(error && value)}
      postfix={postfix}
      warning={warning}
    >
      <input
        ref={inputRef}
        className={twJoin(
          "h-full w-full bg-transparent py-1.5 outline-none",
          "placeholder:invisible placeholder:text-gray-500 group-[.is-empty]:placeholder:visible",
          (disabled || !editing) && "pointer-events-none select-none",
          !editing && "truncate",
        )}
        disabled={disabled}
        placeholder="Enter value"
        type="text"
        value={value}
        onBlur={() => {
          onChange(value);
        }}
        onInput={(e) => {
          // If we come from an empty cell, we auto-close the quote
          if (
            (e.nativeEvent as InputEvent).data === '"' &&
            value.trim() === ""
          ) {
            onSetLocalValue('""');
          } else {
            onSetLocalValue(e.currentTarget.value);
          }
          onResetError();
        }}
        onSelectCapture={(e) => {
          // Can be different types of events
          // We're looking only for keyboard events and Enter key
          if (isKeyboardEvent(e.nativeEvent) && e.nativeEvent.key === "Enter") {
            const inputEl = e.target as HTMLInputElement;
            // If we are in an empty string cell, we want to put the cursor exactly between the two quotes if applicable
            if (inputEl.value === '""') {
              inputEl.setSelectionRange(1, 1);
            } else {
              const valueLength = inputEl.value.length;
              inputEl.setSelectionRange(valueLength, valueLength);
            }
            inputEl.scrollLeft = inputEl.scrollWidth;
          }
        }}
      />
    </CellWrapper>
  );
};
