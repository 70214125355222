/* tslint:disable */
/* eslint-disable */
/**
 * Control API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ColumnMappingInput
 */
export interface ColumnMappingInput {
    /**
     * 
     * @type {Array<EntityMapping>}
     * @memberof ColumnMappingInput
     */
    'entities'?: Array<EntityMapping>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColumnMappingInput
     */
    'grouping_keys'?: Array<string>;
    /**
     * 
     * @type {Array<FeatureMapping>}
     * @memberof ColumnMappingInput
     */
    'features'?: Array<FeatureMapping>;
}
/**
 * 
 * @export
 * @interface ColumnMappingOutput
 */
export interface ColumnMappingOutput {
    /**
     * 
     * @type {Array<EntityMapping>}
     * @memberof ColumnMappingOutput
     */
    'entities'?: Array<EntityMapping>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColumnMappingOutput
     */
    'grouping_keys'?: Array<string>;
    /**
     * 
     * @type {Array<FeatureMapping>}
     * @memberof ColumnMappingOutput
     */
    'features'?: Array<FeatureMapping>;
}
/**
 * 
 * @export
 * @interface EntityMapping
 */
export interface EntityMapping {
    /**
     * 
     * @type {string}
     * @memberof EntityMapping
     */
    'column_name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityMapping
     */
    'entity_name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum EnumColor {
    GRAY = 'gray',
    INDIGO_LIGHT = 'indigo-light',
    GREEN = 'green',
    RED = 'red',
    ORANGE = 'orange',
    YELLOW = 'yellow'
}


/**
 * 
 * @export
 * @interface EnumSchemaInput
 */
export interface EnumSchemaInput {
    /**
     * 
     * @type {Array<EnumValue>}
     * @memberof EnumSchemaInput
     */
    'values': Array<EnumValue>;
}
/**
 * 
 * @export
 * @interface EnumSchemaOutput
 */
export interface EnumSchemaOutput {
    /**
     * 
     * @type {Array<EnumValue>}
     * @memberof EnumSchemaOutput
     */
    'values': Array<EnumValue>;
}
/**
 * 
 * @export
 * @interface EnumValue
 */
export interface EnumValue {
    /**
     * 
     * @type {string}
     * @memberof EnumValue
     */
    'value': string;
    /**
     * 
     * @type {EnumColor}
     * @memberof EnumValue
     */
    'color': EnumColor;
}


/**
 * 
 * @export
 * @interface EventConfigCreate
 */
export interface EventConfigCreate {
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'event_type': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'internal_name': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'display_name_singular': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'display_name_plural': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigCreate
     */
    'display_symbol': string | null;
    /**
     * 
     * @type {{ [key: string]: PropertyDefinitionInput; }}
     * @memberof EventConfigCreate
     */
    'properties': { [key: string]: PropertyDefinitionInput; };
}
/**
 * 
 * @export
 * @interface EventConfigEnriched
 */
export interface EventConfigEnriched {
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'event_type': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'internal_name': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'display_name_singular': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'display_name_plural': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfigEnriched
     */
    'display_symbol': string | null;
    /**
     * 
     * @type {{ [key: string]: PropertyDefinitionOutput; }}
     * @memberof EventConfigEnriched
     */
    'properties': { [key: string]: PropertyDefinitionOutput; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EventConfigEnriched
     */
    'related_schemas'?: { [key: string]: object; };
    /**
     * 
     * @type {object}
     * @memberof EventConfigEnriched
     */
    'entities_json_schema': object;
    /**
     * 
     * @type {JsonSchema}
     * @memberof EventConfigEnriched
     */
    'json_schema': JsonSchema;
}
/**
 * 
 * @export
 * @interface EventConfigPatch
 */
export interface EventConfigPatch {
    /**
     * 
     * @type {string}
     * @memberof EventConfigPatch
     */
    'internal_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventConfigPatch
     */
    'display_name_singular'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventConfigPatch
     */
    'display_name_plural'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventConfigPatch
     */
    'display_symbol'?: string | null;
    /**
     * 
     * @type {{ [key: string]: PropertyDefinitionInput; }}
     * @memberof EventConfigPatch
     */
    'properties'?: { [key: string]: PropertyDefinitionInput; } | null;
}
/**
 * 
 * @export
 * @interface ExploreFeatureQueryRequest
 */
export interface ExploreFeatureQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof ExploreFeatureQueryRequest
     */
    'event': string;
}
/**
 * 
 * @export
 * @interface FeatureCreate
 */
export interface FeatureCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureCreate
     */
    'feature_type'?: FeatureType;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'query_column'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FeatureCreate
     */
    'feature_default'?: any;
}


/**
 * 
 * @export
 * @interface FeatureMapping
 */
export interface FeatureMapping {
    /**
     * 
     * @type {string}
     * @memberof FeatureMapping
     */
    'column_name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureMapping
     */
    'feature_key': string;
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureMapping
     */
    'feature_type'?: FeatureType;
    /**
     * 
     * @type {any}
     * @memberof FeatureMapping
     */
    'feature_default'?: any;
}


/**
 * 
 * @export
 * @interface FeaturePatch
 */
export interface FeaturePatch {
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'query_column'?: string | null;
    /**
     * 
     * @type {FeatureType}
     * @memberof FeaturePatch
     */
    'feature_type'?: FeatureType | null;
    /**
     * 
     * @type {any}
     * @memberof FeaturePatch
     */
    'feature_default'?: any;
}


/**
 * 
 * @export
 * @interface FeatureQuery
 */
export interface FeatureQuery {
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'backfilling_query': string;
    /**
     * 
     * @type {ColumnMappingOutput}
     * @memberof FeatureQuery
     */
    'columns': ColumnMappingOutput;
    /**
     * 
     * @type {number}
     * @memberof FeatureQuery
     */
    'window_hop': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureQuery
     */
    'window_length': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: FeatureQueryEnvironmentSetting; }}
     * @memberof FeatureQuery
     */
    'environment_settings'?: { [key: string]: FeatureQueryEnvironmentSetting; };
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'finalized_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'event': string;
}
/**
 * 
 * @export
 * @interface FeatureQueryCreate
 */
export interface FeatureQueryCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryCreate
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryCreate
     */
    'backfilling_query': string;
    /**
     * 
     * @type {ColumnMappingInput}
     * @memberof FeatureQueryCreate
     */
    'columns': ColumnMappingInput;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryCreate
     */
    'window_hop': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryCreate
     */
    'window_length': number;
}
/**
 * 
 * @export
 * @interface FeatureQueryEnvironmentSetting
 */
export interface FeatureQueryEnvironmentSetting {
    /**
     * 
     * @type {FeatureQueryStatus}
     * @memberof FeatureQueryEnvironmentSetting
     */
    'status'?: FeatureQueryStatus;
}


/**
 * 
 * @export
 * @interface FeatureQueryPut
 */
export interface FeatureQueryPut {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryPut
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryPut
     */
    'backfilling_query': string;
    /**
     * 
     * @type {ColumnMappingInput}
     * @memberof FeatureQueryPut
     */
    'columns': ColumnMappingInput;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryPut
     */
    'window_hop': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryPut
     */
    'window_length': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FeatureQueryStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}


/**
 * 
 * @export
 * @interface FeatureResponse
 */
export interface FeatureResponse {
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {FeatureType}
     * @memberof FeatureResponse
     */
    'feature_type'?: FeatureType;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'query_column'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FeatureResponse
     */
    'feature_default'?: any;
    /**
     * 
     * @type {FeatureQuery}
     * @memberof FeatureResponse
     */
    'query'?: FeatureQuery | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureResponse
     */
    'entity_types'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export enum FeatureType {
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    STRING = 'string',
    DATETIME = 'datetime',
    ARRAY = 'array',
    OBJECT = 'object',
    DATE = 'date'
}


/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface JsonSchema
 */
export interface JsonSchema {
}
/**
 * 
 * @export
 * @interface PreviewQueryRequest
 */
export interface PreviewQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof PreviewQueryRequest
     */
    'query': string;
    /**
     * 
     * @type {number}
     * @memberof PreviewQueryRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewQueryRequest
     */
    'window_length': number;
    /**
     * 
     * @type {string}
     * @memberof PreviewQueryRequest
     */
    'environment': PreviewQueryRequestEnvironmentEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PreviewQueryRequestEnvironmentEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}

/**
 * 
 * @export
 * @interface PreviewQueryResponse
 */
export interface PreviewQueryResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof PreviewQueryResponse
     */
    'data': Array<object>;
}
/**
 * 
 * @export
 * @interface PropertyDefinitionInput
 */
export interface PropertyDefinitionInput {
    /**
     * 
     * @type {string}
     * @memberof PropertyDefinitionInput
     */
    'type': PropertyDefinitionInputTypeEnum;
    /**
     * 
     * @type {EnumSchemaInput}
     * @memberof PropertyDefinitionInput
     */
    'enum_schema'?: EnumSchemaInput | null;
    /**
     * 
     * @type {RelationSchema}
     * @memberof PropertyDefinitionInput
     */
    'relation_schema'?: RelationSchema | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyDefinitionInput
     */
    'display_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDefinitionInput
     */
    'required'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PropertyDefinitionInputTypeEnum {
    STRING = 'string',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    INTEGER = 'integer',
    OBJECT = 'object',
    ARRAY = 'array',
    DATETIME = 'datetime',
    DATE = 'date',
    ENUM = 'enum',
    TAGS = 'tags',
    ADDRESS = 'address',
    RELATION = 'relation',
    IP_ADDRESS = 'ip_address',
    LAT_LONG = 'lat_long',
    URL = 'url',
    FILE = 'file'
}

/**
 * 
 * @export
 * @interface PropertyDefinitionOutput
 */
export interface PropertyDefinitionOutput {
    /**
     * 
     * @type {string}
     * @memberof PropertyDefinitionOutput
     */
    'type': PropertyDefinitionOutputTypeEnum;
    /**
     * 
     * @type {EnumSchemaOutput}
     * @memberof PropertyDefinitionOutput
     */
    'enum_schema'?: EnumSchemaOutput | null;
    /**
     * 
     * @type {RelationSchema}
     * @memberof PropertyDefinitionOutput
     */
    'relation_schema'?: RelationSchema | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyDefinitionOutput
     */
    'display_name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDefinitionOutput
     */
    'required'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PropertyDefinitionOutputTypeEnum {
    STRING = 'string',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    INTEGER = 'integer',
    OBJECT = 'object',
    ARRAY = 'array',
    DATETIME = 'datetime',
    DATE = 'date',
    ENUM = 'enum',
    TAGS = 'tags',
    ADDRESS = 'address',
    RELATION = 'relation',
    IP_ADDRESS = 'ip_address',
    LAT_LONG = 'lat_long',
    URL = 'url',
    FILE = 'file'
}

/**
 * 
 * @export
 * @interface RelationSchema
 */
export interface RelationSchema {
    /**
     * 
     * @type {string}
     * @memberof RelationSchema
     */
    'entity_type': string;
}
/**
 * 
 * @export
 * @interface SetFeatureQueryStatusRequest
 */
export interface SetFeatureQueryStatusRequest {
    /**
     * 
     * @type {FeatureQueryStatus}
     * @memberof SetFeatureQueryStatusRequest
     */
    'status': FeatureQueryStatus;
    /**
     * 
     * @type {string}
     * @memberof SetFeatureQueryStatusRequest
     */
    'environment': SetFeatureQueryStatusRequestEnvironmentEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SetFeatureQueryStatusRequestEnvironmentEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Event Configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventConfigsEventsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Event Config
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventConfigEventsEventTypeGet: async (eventType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('getEventConfigEventsEventTypeGet', 'eventType', eventType)
            const localVarPath = `/events/{event_type}`
                .replace(`{${"event_type"}}`, encodeURIComponent(String(eventType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventConfigCreate} eventConfigCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEventConfigEventsPost: async (eventConfigCreate: EventConfigCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventConfigCreate' is not null or undefined
            assertParamExists('saveEventConfigEventsPost', 'eventConfigCreate', eventConfigCreate)
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventConfigCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Event Config
         * @param {string} eventType 
         * @param {EventConfigPatch} eventConfigPatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventConfigEventsEventTypePatch: async (eventType: string, eventConfigPatch: EventConfigPatch, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('updateEventConfigEventsEventTypePatch', 'eventType', eventType)
            // verify required parameter 'eventConfigPatch' is not null or undefined
            assertParamExists('updateEventConfigEventsEventTypePatch', 'eventConfigPatch', eventConfigPatch)
            const localVarPath = `/events/{event_type}`
                .replace(`{${"event_type"}}`, encodeURIComponent(String(eventType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventConfigPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Event Configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEventConfigsEventsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventConfigEnriched>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEventConfigsEventsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getAllEventConfigsEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Event Config
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventConfigEventsEventTypeGet(eventType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventConfigEnriched>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventConfigEventsEventTypeGet(eventType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getEventConfigEventsEventTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventConfigCreate} eventConfigCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEventConfigEventsPost(eventConfigCreate: EventConfigCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventConfigEnriched>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEventConfigEventsPost(eventConfigCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.saveEventConfigEventsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Event Config
         * @param {string} eventType 
         * @param {EventConfigPatch} eventConfigPatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventConfigEventsEventTypePatch(eventType: string, eventConfigPatch: EventConfigPatch, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventConfigEnriched>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventConfigEventsEventTypePatch(eventType, eventConfigPatch, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.updateEventConfigEventsEventTypePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Event Configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventConfigsEventsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<EventConfigEnriched>> {
            return localVarFp.getAllEventConfigsEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Event Config
         * @param {EventsApiGetEventConfigEventsEventTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventConfigEventsEventTypeGet(requestParameters: EventsApiGetEventConfigEventsEventTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EventConfigEnriched> {
            return localVarFp.getEventConfigEventsEventTypeGet(requestParameters.eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventsApiSaveEventConfigEventsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEventConfigEventsPost(requestParameters: EventsApiSaveEventConfigEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<EventConfigEnriched> {
            return localVarFp.saveEventConfigEventsPost(requestParameters.eventConfigCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Event Config
         * @param {EventsApiUpdateEventConfigEventsEventTypePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventConfigEventsEventTypePatch(requestParameters: EventsApiUpdateEventConfigEventsEventTypePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<EventConfigEnriched> {
            return localVarFp.updateEventConfigEventsEventTypePatch(requestParameters.eventType, requestParameters.eventConfigPatch, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEventConfigEventsEventTypeGet operation in EventsApi.
 * @export
 * @interface EventsApiGetEventConfigEventsEventTypeGetRequest
 */
export interface EventsApiGetEventConfigEventsEventTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventConfigEventsEventTypeGet
     */
    readonly eventType: string
}

/**
 * Request parameters for saveEventConfigEventsPost operation in EventsApi.
 * @export
 * @interface EventsApiSaveEventConfigEventsPostRequest
 */
export interface EventsApiSaveEventConfigEventsPostRequest {
    /**
     * 
     * @type {EventConfigCreate}
     * @memberof EventsApiSaveEventConfigEventsPost
     */
    readonly eventConfigCreate: EventConfigCreate
}

/**
 * Request parameters for updateEventConfigEventsEventTypePatch operation in EventsApi.
 * @export
 * @interface EventsApiUpdateEventConfigEventsEventTypePatchRequest
 */
export interface EventsApiUpdateEventConfigEventsEventTypePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiUpdateEventConfigEventsEventTypePatch
     */
    readonly eventType: string

    /**
     * 
     * @type {EventConfigPatch}
     * @memberof EventsApiUpdateEventConfigEventsEventTypePatch
     */
    readonly eventConfigPatch: EventConfigPatch

    /**
     * 
     * @type {string}
     * @memberof EventsApiUpdateEventConfigEventsEventTypePatch
     */
    readonly ifMatch?: string | null
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Get All Event Configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getAllEventConfigsEventsGet(options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getAllEventConfigsEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Event Config
     * @param {EventsApiGetEventConfigEventsEventTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventConfigEventsEventTypeGet(requestParameters: EventsApiGetEventConfigEventsEventTypeGetRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventConfigEventsEventTypeGet(requestParameters.eventType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save Event Config
     * @param {EventsApiSaveEventConfigEventsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public saveEventConfigEventsPost(requestParameters: EventsApiSaveEventConfigEventsPostRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).saveEventConfigEventsPost(requestParameters.eventConfigCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Event Config
     * @param {EventsApiUpdateEventConfigEventsEventTypePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public updateEventConfigEventsEventTypePatch(requestParameters: EventsApiUpdateEventConfigEventsEventTypePatchRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).updateEventConfigEventsEventTypePatch(requestParameters.eventType, requestParameters.eventConfigPatch, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureQueriesApi - axios parameter creator
 * @export
 */
export const FeatureQueriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueryCreate} featureQueryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureQueryHandlerFeatureQueriesPost: async (featureQueryCreate: FeatureQueryCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureQueryCreate' is not null or undefined
            assertParamExists('createFeatureQueryHandlerFeatureQueriesPost', 'featureQueryCreate', featureQueryCreate)
            const localVarPath = `/feature_queries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureQueryCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {number | null} [heartbeatSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executionHeartbeatFeatureQueriesHeartbeatPost: async (heartbeatSeconds?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature_queries/heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (heartbeatSeconds !== undefined) {
                localVarQueryParameter['heartbeat_seconds'] = heartbeatSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Explore Query
         * @param {ExploreFeatureQueryRequest} exploreFeatureQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreQueryFeatureQueriesExplorePost: async (exploreFeatureQueryRequest: ExploreFeatureQueryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exploreFeatureQueryRequest' is not null or undefined
            assertParamExists('exploreQueryFeatureQueriesExplorePost', 'exploreFeatureQueryRequest', exploreFeatureQueryRequest)
            const localVarPath = `/feature_queries/explore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exploreFeatureQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {string} id 
         * @param {number} numberOfWindows 
         * @param {string} timestampStart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBackfillQueryFeatureQueriesBackfillQueryPost: async (id: string, numberOfWindows: number, timestampStart: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'id', id)
            // verify required parameter 'numberOfWindows' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'numberOfWindows', numberOfWindows)
            // verify required parameter 'timestampStart' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'timestampStart', timestampStart)
            const localVarPath = `/feature_queries/backfill_query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (numberOfWindows !== undefined) {
                localVarQueryParameter['number_of_windows'] = numberOfWindows;
            }

            if (timestampStart !== undefined) {
                localVarQueryParameter['timestamp_start'] = timestampStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueriesFeatureQueriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature_queries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueryFeatureQueriesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureQueryFeatureQueriesIdGet', 'id', id)
            const localVarPath = `/feature_queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview Query
         * @param {PreviewQueryRequest} previewQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewQueryFeatureQueriesPreviewPost: async (previewQueryRequest: PreviewQueryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewQueryRequest' is not null or undefined
            assertParamExists('previewQueryFeatureQueriesPreviewPost', 'previewQueryRequest', previewQueryRequest)
            const localVarPath = `/feature_queries/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previewQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Feature Query Status Handler
         * @param {string} id 
         * @param {SetFeatureQueryStatusRequest} setFeatureQueryStatusRequest 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost: async (id: string, setFeatureQueryStatusRequest: SetFeatureQueryStatusRequest, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost', 'id', id)
            // verify required parameter 'setFeatureQueryStatusRequest' is not null or undefined
            assertParamExists('setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost', 'setFeatureQueryStatusRequest', setFeatureQueryStatusRequest)
            const localVarPath = `/feature_queries/{id}/:set_status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFeatureQueryStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {string} id 
         * @param {FeatureQueryPut} featureQueryPut 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureQueryHandlerFeatureQueriesIdPut: async (id: string, featureQueryPut: FeatureQueryPut, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeatureQueryHandlerFeatureQueriesIdPut', 'id', id)
            // verify required parameter 'featureQueryPut' is not null or undefined
            assertParamExists('updateFeatureQueryHandlerFeatureQueriesIdPut', 'featureQueryPut', featureQueryPut)
            const localVarPath = `/feature_queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureQueryPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureQueriesApi - functional programming interface
 * @export
 */
export const FeatureQueriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureQueriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueryCreate} featureQueryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureQueryHandlerFeatureQueriesPost(featureQueryCreate: FeatureQueryCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureQueryHandlerFeatureQueriesPost(featureQueryCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {number | null} [heartbeatSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executionHeartbeatFeatureQueriesHeartbeatPost(heartbeatSeconds?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executionHeartbeatFeatureQueriesHeartbeatPost(heartbeatSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.executionHeartbeatFeatureQueriesHeartbeatPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Explore Query
         * @param {ExploreFeatureQueryRequest} exploreFeatureQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exploreQueryFeatureQueriesExplorePost(exploreFeatureQueryRequest: ExploreFeatureQueryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exploreQueryFeatureQueriesExplorePost(exploreFeatureQueryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.exploreQueryFeatureQueriesExplorePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {string} id 
         * @param {number} numberOfWindows 
         * @param {string} timestampStart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchBackfillQueryFeatureQueriesBackfillQueryPost(id: string, numberOfWindows: number, timestampStart: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchBackfillQueryFeatureQueriesBackfillQueryPost(id, numberOfWindows, timestampStart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.fetchBackfillQueryFeatureQueriesBackfillQueryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureQuery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureQueriesFeatureQueriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.getFeatureQueriesFeatureQueriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureQueryFeatureQueriesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureQueryFeatureQueriesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.getFeatureQueryFeatureQueriesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Preview Query
         * @param {PreviewQueryRequest} previewQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewQueryFeatureQueriesPreviewPost(previewQueryRequest: PreviewQueryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewQueryFeatureQueriesPreviewPost(previewQueryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.previewQueryFeatureQueriesPreviewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set Feature Query Status Handler
         * @param {string} id 
         * @param {SetFeatureQueryStatusRequest} setFeatureQueryStatusRequest 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(id: string, setFeatureQueryStatusRequest: SetFeatureQueryStatusRequest, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(id, setFeatureQueryStatusRequest, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {string} id 
         * @param {FeatureQueryPut} featureQueryPut 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureQueryHandlerFeatureQueriesIdPut(id: string, featureQueryPut: FeatureQueryPut, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureQueryHandlerFeatureQueriesIdPut(id, featureQueryPut, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.updateFeatureQueryHandlerFeatureQueriesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureQueriesApi - factory interface
 * @export
 */
export const FeatureQueriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureQueriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureQueryHandlerFeatureQueriesPost(requestParameters: FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.createFeatureQueryHandlerFeatureQueriesPost(requestParameters.featureQueryCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters: FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters.heartbeatSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Explore Query
         * @param {FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreQueryFeatureQueriesExplorePost(requestParameters: FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PreviewQueryResponse> {
            return localVarFp.exploreQueryFeatureQueriesExplorePost(requestParameters.exploreFeatureQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters: FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters.id, requestParameters.numberOfWindows, requestParameters.timestampStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureQuery>> {
            return localVarFp.getFeatureQueriesFeatureQueriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueryFeatureQueriesIdGet(requestParameters: FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.getFeatureQueryFeatureQueriesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview Query
         * @param {FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewQueryFeatureQueriesPreviewPost(requestParameters: FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PreviewQueryResponse> {
            return localVarFp.previewQueryFeatureQueriesPreviewPost(requestParameters.previewQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Feature Query Status Handler
         * @param {FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(requestParameters: FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(requestParameters.id, requestParameters.setFeatureQueryStatusRequest, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureQueryHandlerFeatureQueriesIdPut(requestParameters: FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.updateFeatureQueryHandlerFeatureQueriesIdPut(requestParameters.id, requestParameters.featureQueryPut, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFeatureQueryHandlerFeatureQueriesPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest
 */
export interface FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest {
    /**
     * 
     * @type {FeatureQueryCreate}
     * @memberof FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPost
     */
    readonly featureQueryCreate: FeatureQueryCreate
}

/**
 * Request parameters for executionHeartbeatFeatureQueriesHeartbeatPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest
 */
export interface FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPost
     */
    readonly heartbeatSeconds?: number | null
}

/**
 * Request parameters for exploreQueryFeatureQueriesExplorePost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest
 */
export interface FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest {
    /**
     * 
     * @type {ExploreFeatureQueryRequest}
     * @memberof FeatureQueriesApiExploreQueryFeatureQueriesExplorePost
     */
    readonly exploreFeatureQueryRequest: ExploreFeatureQueryRequest
}

/**
 * Request parameters for fetchBackfillQueryFeatureQueriesBackfillQueryPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest
 */
export interface FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly numberOfWindows: number

    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly timestampStart: string
}

/**
 * Request parameters for getFeatureQueryFeatureQueriesIdGet operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest
 */
export interface FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for previewQueryFeatureQueriesPreviewPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest
 */
export interface FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest {
    /**
     * 
     * @type {PreviewQueryRequest}
     * @memberof FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPost
     */
    readonly previewQueryRequest: PreviewQueryRequest
}

/**
 * Request parameters for setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest
 */
export interface FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost
     */
    readonly id: string

    /**
     * 
     * @type {SetFeatureQueryStatusRequest}
     * @memberof FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost
     */
    readonly setFeatureQueryStatusRequest: SetFeatureQueryStatusRequest

    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost
     */
    readonly ifMatch?: string | null
}

/**
 * Request parameters for updateFeatureQueryHandlerFeatureQueriesIdPut operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest
 */
export interface FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {FeatureQueryPut}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPut
     */
    readonly featureQueryPut: FeatureQueryPut

    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPut
     */
    readonly ifMatch?: string | null
}

/**
 * FeatureQueriesApi - object-oriented interface
 * @export
 * @class FeatureQueriesApi
 * @extends {BaseAPI}
 */
export class FeatureQueriesApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature Query Handler
     * @param {FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public createFeatureQueryHandlerFeatureQueriesPost(requestParameters: FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).createFeatureQueryHandlerFeatureQueriesPost(requestParameters.featureQueryCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Execution Heartbeat
     * @param {FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters: FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest = {}, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters.heartbeatSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Explore Query
     * @param {FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public exploreQueryFeatureQueriesExplorePost(requestParameters: FeatureQueriesApiExploreQueryFeatureQueriesExplorePostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).exploreQueryFeatureQueriesExplorePost(requestParameters.exploreFeatureQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Backfill Query
     * @param {FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters: FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters.id, requestParameters.numberOfWindows, requestParameters.timestampStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).getFeatureQueriesFeatureQueriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Query
     * @param {FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public getFeatureQueryFeatureQueriesIdGet(requestParameters: FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).getFeatureQueryFeatureQueriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview Query
     * @param {FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public previewQueryFeatureQueriesPreviewPost(requestParameters: FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).previewQueryFeatureQueriesPreviewPost(requestParameters.previewQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Feature Query Status Handler
     * @param {FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(requestParameters: FeatureQueriesApiSetFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(requestParameters.id, requestParameters.setFeatureQueryStatusRequest, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Feature Query Handler
     * @param {FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public updateFeatureQueryHandlerFeatureQueriesIdPut(requestParameters: FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPutRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).updateFeatureQueryHandlerFeatureQueriesIdPut(requestParameters.id, requestParameters.featureQueryPut, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureHandlerFeaturesPost: async (featureCreate: FeatureCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureCreate' is not null or undefined
            assertParamExists('createFeatureHandlerFeaturesPost', 'featureCreate', featureCreate)
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureHandlerFeaturesKeyGet: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getFeatureHandlerFeaturesKeyGet', 'key', key)
            const localVarPath = `/features/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Features
         * @param {string | null} [queryId] 
         * @param {string | null} [entityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFeaturesGet: async (queryId?: string | null, entityTypes?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (queryId !== undefined) {
                localVarQueryParameter['query_id'] = queryId;
            }

            if (entityTypes !== undefined) {
                localVarQueryParameter['entity_types'] = entityTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {string} key 
         * @param {FeaturePatch} featurePatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureHandlerFeaturesKeyPatch: async (key: string, featurePatch: FeaturePatch, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchFeatureHandlerFeaturesKeyPatch', 'key', key)
            // verify required parameter 'featurePatch' is not null or undefined
            assertParamExists('patchFeatureHandlerFeaturesKeyPatch', 'featurePatch', featurePatch)
            const localVarPath = `/features/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featurePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureHandlerFeaturesPost(featureCreate: FeatureCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureHandlerFeaturesPost(featureCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.createFeatureHandlerFeaturesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureHandlerFeaturesKeyGet(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureHandlerFeaturesKeyGet(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.getFeatureHandlerFeaturesKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Features
         * @param {string | null} [queryId] 
         * @param {string | null} [entityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesFeaturesGet(queryId?: string | null, entityTypes?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesFeaturesGet(queryId, entityTypes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.getFeaturesFeaturesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {string} key 
         * @param {FeaturePatch} featurePatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeatureHandlerFeaturesKeyPatch(key: string, featurePatch: FeaturePatch, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeatureHandlerFeaturesKeyPatch(key, featurePatch, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.patchFeatureHandlerFeaturesKeyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeaturesApiCreateFeatureHandlerFeaturesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureHandlerFeaturesPost(requestParameters: FeaturesApiCreateFeatureHandlerFeaturesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.createFeatureHandlerFeaturesPost(requestParameters.featureCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureHandlerFeaturesKeyGet(requestParameters: FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.getFeatureHandlerFeaturesKeyGet(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Features
         * @param {FeaturesApiGetFeaturesFeaturesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFeaturesGet(requestParameters: FeaturesApiGetFeaturesFeaturesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureResponse>> {
            return localVarFp.getFeaturesFeaturesGet(requestParameters.queryId, requestParameters.entityTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureHandlerFeaturesKeyPatch(requestParameters: FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.patchFeatureHandlerFeaturesKeyPatch(requestParameters.key, requestParameters.featurePatch, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFeatureHandlerFeaturesPost operation in FeaturesApi.
 * @export
 * @interface FeaturesApiCreateFeatureHandlerFeaturesPostRequest
 */
export interface FeaturesApiCreateFeatureHandlerFeaturesPostRequest {
    /**
     * 
     * @type {FeatureCreate}
     * @memberof FeaturesApiCreateFeatureHandlerFeaturesPost
     */
    readonly featureCreate: FeatureCreate
}

/**
 * Request parameters for getFeatureHandlerFeaturesKeyGet operation in FeaturesApi.
 * @export
 * @interface FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest
 */
export interface FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeatureHandlerFeaturesKeyGet
     */
    readonly key: string
}

/**
 * Request parameters for getFeaturesFeaturesGet operation in FeaturesApi.
 * @export
 * @interface FeaturesApiGetFeaturesFeaturesGetRequest
 */
export interface FeaturesApiGetFeaturesFeaturesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeaturesFeaturesGet
     */
    readonly queryId?: string | null

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeaturesFeaturesGet
     */
    readonly entityTypes?: string | null
}

/**
 * Request parameters for patchFeatureHandlerFeaturesKeyPatch operation in FeaturesApi.
 * @export
 * @interface FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest
 */
export interface FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly key: string

    /**
     * 
     * @type {FeaturePatch}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly featurePatch: FeaturePatch

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly ifMatch?: string | null
}

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature Handler
     * @param {FeaturesApiCreateFeatureHandlerFeaturesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public createFeatureHandlerFeaturesPost(requestParameters: FeaturesApiCreateFeatureHandlerFeaturesPostRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).createFeatureHandlerFeaturesPost(requestParameters.featureCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Handler
     * @param {FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatureHandlerFeaturesKeyGet(requestParameters: FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatureHandlerFeaturesKeyGet(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Features
     * @param {FeaturesApiGetFeaturesFeaturesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeaturesFeaturesGet(requestParameters: FeaturesApiGetFeaturesFeaturesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeaturesFeaturesGet(requestParameters.queryId, requestParameters.entityTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Feature Handler
     * @param {FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public patchFeatureHandlerFeaturesKeyPatch(requestParameters: FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).patchFeatureHandlerFeaturesKeyPatch(requestParameters.key, requestParameters.featurePatch, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



