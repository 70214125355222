import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { isString } from "lodash";
import React, { useEffect, useRef } from "react";

import { Icon } from "src/base-components/Icon";

export type SearchBoxProps = {
  placeholder: string;
  onChange: (input: string) => void;
  defaultValue?: string;
  value?: string;
  dataLoc?: string;
  autoFocus?: boolean;
};

export const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder,
  onChange,
  defaultValue,
  value,
  dataLoc = "search-box",
  autoFocus = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className="flex h-8 w-full items-center gap-x-1 rounded-lg border border-gray-200 bg-white px-2 py-1 focus-within:border-indigo-600 focus-within:ring-2 focus-within:ring-indigo-600/25">
      <Icon
        color="text-gray-500"
        dataLoc={`${dataLoc}-magnifying-glass`}
        icon={faMagnifyingGlass}
        size="2xs"
      />
      <input
        ref={inputRef}
        className="w-full bg-white font-inter-normal-12px placeholder:text-gray-500 focus:outline-none"
        data-loc={dataLoc}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {/* Only show the clear button if there is a value and is controlled component */}
      {onChange && isString(value) && value.length > 0 && (
        <Icon
          color="text-gray-500 hover:text-gray-800"
          dataLoc={`${dataLoc}-clear-button`}
          icon={faClose}
          size="xs"
          onClick={() => onChange("")}
        />
      )}
    </div>
  );
};
