import {
  faSearch,
  faArrowTurnDownLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { Dialog, DialogPanel } from "@headlessui2/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useDebounceCallback } from "usehooks-ts";

import { Icon } from "src/base-components/Icon";
import { EmptyState } from "src/design-system/EmptyState";
import { useFuseSearch } from "src/utils/useFuseSearch";

export type MenuItem = {
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  type: string;
  subType?: string;
  value: string;
  details: string;
  onSelect?: (query: string) => void;
  pattern?: RegExp;
  disabled?: boolean;
  dataLoc?: string;
};

type OmniboxBaseProps = {
  open: boolean;
  menuItems: MenuItem[];
  onClose: () => void;
  onQuery?: (query: string) => void;
  placeholder: string;
};

export const OmniboxBase: React.FC<OmniboxBaseProps> = ({
  open,
  menuItems,
  placeholder,
  onClose,
  onQuery,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const [focused, setFocused] = useState(0);
  const [isVisible, setIsVisible] = useState(open);

  // Debounced function to handle input changes
  const handleInputChange = useDebounceCallback((value: string) => {
    setQuery(value);
    onQuery?.(value);
  }, 50);

  const search = useFuseSearch(menuItems, {
    keys: ["label", "details", "type"],
    shouldSort: true,
    threshold: 0.2,
  });
  const filteredMenu = search(query);

  const rowVirtualizer = useVirtualizer({
    enabled: isVisible,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) =>
      index === 0 || filteredMenu[index - 1].type !== filteredMenu[index].type
        ? 72
        : 40,
    count: filteredMenu.length,
    overscan: 5,
    getItemKey: (index) => filteredMenu[index].value,
  });

  // Update focused item index when filtered menu changes
  useEffect(() => {
    setFocused(Math.max(Math.min(focused, filteredMenu.length - 1), 0));
  }, [filteredMenu.length, focused]);

  const getNextIndex = (currentIndex: number, direction: 1 | -1) => {
    for (
      let i = currentIndex + direction;
      i >= 0 && i < filteredMenu.length;
      i += direction
    ) {
      if (!filteredMenu[i].disabled) {
        return i;
      }
    }

    // if no non-disabled element is found return the current index
    return currentIndex;
  };

  // Handle key events
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "ArrowDown":
        setFocused((prev) => getNextIndex(prev, 1));
        break;
      case "ArrowUp":
        setFocused((prev) => getNextIndex(prev, -1));
        break;
      case "Enter": {
        const focusedItem = filteredMenu[focused];
        if (!focusedItem?.disabled) {
          focusedItem?.onSelect?.(query);
          onClose(); // Close the dialog after selection
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    rowVirtualizer.scrollToIndex(focused, {
      align: "auto",
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, rowVirtualizer.scrollToIndex]);

  return (
    <Dialog
      className="relative z-50"
      open={open}
      autoFocus
      onClose={onClose}
      onTransitionEnd={() => {
        if (open) {
          inputRef.current?.focus();
          setIsVisible(true);
        } else {
          setQuery("");
          setIsVisible(false);
        }
      }}
    >
      <div className="fixed inset-0 justify-center p-4">
        <DialogPanel
          className="mx-auto mt-48 flex w-150 max-w-lg flex-col rounded-lg bg-white pt-4 shadow-2xl ring-1 ring-gray-100 ring-opacity-50 transition duration-100 ease-out data-[closed]:scale-90 data-[closed]:opacity-0"
          transition
        >
          <div className="flex gap-2 px-4">
            <Icon color="text-gray-400" icon={faSearch} />
            <input
              ref={inputRef}
              className="w-full bg-transparent text-gray-900 outline-none font-inter-normal-13px placeholder:text-gray-400"
              data-loc="omnibox-input"
              placeholder={placeholder}
              spellCheck="false"
              onBlur={() => null}
              onChange={(event) => handleInputChange(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mt-3 h-px w-full bg-gray-200 bg-opacity-50"></div>
          {filteredMenu.length > 0 ? (
            <div
              ref={parentRef}
              className="decideScrollbar max-h-[23.5rem] px-2 pb-2 pt-2"
            >
              <div
                className="relative"
                style={{ height: `${rowVirtualizer.getTotalSize() || 376}px` }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                  const item = filteredMenu[virtualItem.index];

                  const showSection =
                    virtualItem.index === 0 ||
                    filteredMenu[virtualItem.index - 1].type !== item.type;

                  return (
                    <Fragment key={virtualItem.key}>
                      {showSection && (
                        <p
                          className="absolute left-0 top-0 h-8 w-full px-2 py-2 font-bold text-gray-600 font-inter-medium-11px"
                          style={{
                            transform: `translateY(${virtualItem.start}px)`,
                          }}
                        >
                          {item.type}s
                        </p>
                      )}
                      <OmniboxItem
                        disabled={item.disabled}
                        focused={focused === virtualItem.index}
                        startPosition={
                          showSection
                            ? virtualItem.start + 32
                            : virtualItem.start
                        }
                        onClick={(e) => {
                          if (item && item.onSelect && !item.disabled) {
                            item.onSelect(query);
                            if (!e.metaKey) {
                              onClose();
                            }
                          }
                        }}
                        onMouseMove={() => setFocused(virtualItem.index)}
                        {...item}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          ) : (
            <EmptyState
              description="No items match your search or selection criteria."
              headline="No Results Found"
              icon={faSearch}
            />
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

const OmniboxItem: React.FC<
  {
    focused?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseMove: () => void;
    startPosition: number;
  } & MenuItem
> = ({
  focused,
  value,
  icon,
  type,
  label,
  subType,
  disabled,
  dataLoc,
  onClick,
  onMouseMove,
  startPosition,
}) => (
  <button
    key={value}
    className={twJoin(
      "flex w-full items-center justify-between gap-4 overflow-hidden rounded-md p-2 text-gray-900",
      "absolute left-0 top-0",
      focused && !disabled && "bg-gray-100 bg-opacity-80 text-gray-900",
      disabled && "cursor-not-allowed opacity-40",
    )}
    data-loc={`${dataLoc}${disabled ? "-disabled" : ""}`}
    disabled={disabled}
    style={{
      transform: `translateY(${startPosition}px)`,
    }}
    onClick={onClick}
    onMouseMove={onMouseMove}
  >
    <div className="flex w-full items-center gap-x-2">
      {icon && <div className="h-6 w-6 shrink-0">{icon}</div>}
      <p className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap text-left font-inter-medium-13px">
        {label}
      </p>
    </div>
    <div className="flex h-auto items-center">
      {focused && !disabled ? (
        <Icon color="text-gray-400" icon={faArrowTurnDownLeft} size="xs" />
      ) : (
        <p className="w-20 shrink-0 text-right text-gray-400 font-inter-normal-12px">
          {subType ? subType : type}
        </p>
      )}
    </div>
  </button>
);
