import {
  faDiagramNext,
  faPlug,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { Column } from "@tanstack/react-table";
import { difference, isEmpty } from "lodash";

import { EnumOptionsBET } from "src/api/flowTypes";
import {
  Dataset,
  DatasetRow,
  DesiredType,
  GLOBAL_FEATURES_TYPE,
} from "src/api/types";
import { EventConfigEnriched } from "src/clients/features-control/api";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { VersionSchemas } from "src/datasets/DatasetTable/types";
import { REQUIRED_ERROR_MESSAGE } from "src/datasets/DatasetTable/validationErrorMessages";
import {
  ValidationResult,
  validateAny,
  validateArray,
  validateBoolean,
  validateDate,
  validateDatetime,
  validateEnum,
  validateInteger,
  validateNumber,
  validateObject,
  validateString,
} from "src/datasets/DatasetTable/validators";
import { DatasetIntegrationNode } from "src/datasets/utils";
import { EntitySchemaResource } from "src/entities/queries";
import { WorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { OutcomeType } from "src/outcomes/types";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { PropertyUIT, SchemaConverter } from "src/schema/schemaMappingUtils";
import { assertUnreachable } from "src/utils/typeUtils";

export const NON_PROVIDER_ICONS = {
  [NODE_TYPE.FLOW_NODE]: faDiagramNext,
  [NODE_TYPE.LOOP_NODE]: faRotateRight,
  [NODE_TYPE.AI_NODE_V2]: faSparkles,
};

export type CellId = `${number}_${string}`;

export const SUB_COLUMN_SEPARATOR = "##" as const;

export const parseCellId = (cellId: CellId): [number, string] => {
  const [rowId, colId] = cellId.split(/_(.*)/s, 2);
  return [parseInt(rowId), colId];
};

const getSchemaFields = (
  schemaData?: VersionSchemas["input"] | VersionSchemas["output"],
) => schemaData?.properties.map((prop) => prop.fieldName) ?? [];

export const getEnrichedSchemaInputFields = (
  schemaData: VersionSchemas["input"],
) =>
  schemaData?.properties.map((prop) => {
    return {
      name: prop.fieldName,
      type: prop.type[0].split("@")[0].split("/").pop(),
      id: prop.type[0].split("@")[1],
    };
  }) ?? [];

export const getEnrichedSchemaOutputFields = (
  schemaData: VersionSchemas["output"],
) =>
  schemaData?.properties.map((prop) => {
    return {
      name: prop.fieldName,
      type: prop.type[0],
    };
  }) ?? [];

export type AvailableOutcomeColumns = {
  outcomeName: string;
  outcomeKey: string;
  columnName: string;
  property: PropertyUIT;
};

export const getAvailableColumns = (
  dataset: Dataset,
  schemas: VersionSchemas,
  integrationNodes: DatasetIntegrationNode[],
  outcomeTypes: OutcomeType[],
  featureGates: WorkspaceFeatureGates,
) => {
  const originalSchemaFields = getSchemaFields(schemas.input);

  const inputSchemaFields = [
    ...originalSchemaFields,
    ...(isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
    featureGates.featuresEventsEnabled &&
    !originalSchemaFields.includes(GLOBAL_FEATURES_TYPE)
      ? [GLOBAL_FEATURES_TYPE]
      : []),
  ];
  const outputSchemaFields = getSchemaFields(schemas.output);
  const allOutcomeColumns = getOutcomeDatasetColumns(outcomeTypes);
  const availableOutcomeColumns: AvailableOutcomeColumns[] =
    allOutcomeColumns.filter(
      (column) =>
        !dataset.outcome_columns.some((c) => c.name === column.columnName),
    );

  return {
    outputAvailable: difference(
      outputSchemaFields,
      dataset.output_columns.map((column) => column.name),
    ),
    inputAvailable: difference(
      inputSchemaFields,
      dataset.input_columns.map((column) => column.name),
    ),
    mockAvailable: integrationNodes
      .map((integrationNode) => {
        const matchingMockColumn = dataset.mock_columns.find(
          (column) => column.name === integrationNode.name,
        );

        if (matchingMockColumn && integrationNode.mockableChildNodes) {
          if (!matchingMockColumn.use_subflow_mocks) {
            return null;
          }

          const prefix = `${matchingMockColumn.name}${SUB_COLUMN_SEPARATOR}`;
          const definedSubMocks = dataset.mock_columns
            .filter((col) => col.name.startsWith(prefix))
            .map((subMock) => subMock.name.replace(prefix, ""));
          const possibleSubMocks = integrationNode.mockableChildNodes.map(
            (childNode) => childNode.name,
          );
          const diff = difference(possibleSubMocks, definedSubMocks);

          return diff.length > 0
            ? {
                name: integrationNode.name,
                subMocks: diff,
              }
            : null;
        }

        return matchingMockColumn
          ? null
          : { name: integrationNode.name, subMocks: null };
      })
      .filter((mock): mock is Exclude<typeof mock, null | undefined> =>
        Boolean(mock),
      ),
    outcomeAvailable: availableOutcomeColumns,
  };
};

export const getOutcomeDatasetColumns = (
  outcomeTypes: OutcomeType[],
): AvailableOutcomeColumns[] => {
  return outcomeTypes.flatMap((outcome) => {
    const schema = SchemaConverter.beToUI(
      outcome.payload_schema,
      SchemaOptions.Output,
    );

    return schema.properties.map((prop) => ({
      outcomeName: outcome.name,
      outcomeKey: outcome.key,
      columnName: `${outcome.key}${SUB_COLUMN_SEPARATOR}${prop.fieldName}`,
      property: prop,
    }));
  });
};

const isPartOfInputSchema = (
  columnName: string,
  inputSchemaFields: string[],
  featureGates: WorkspaceFeatureGates,
) =>
  (isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
    featureGates.featuresEventsEnabled &&
    columnName === GLOBAL_FEATURES_TYPE) ||
  inputSchemaFields.includes(columnName);

export const getGroupColumns = (
  datasetInputColumns: Dataset["input_columns"],
  inputSchemas: VersionSchemas["input"],
  featureGates: WorkspaceFeatureGates,
) => {
  const inputSchemaFields = getSchemaFields(inputSchemas);
  const inputColumnsNames = datasetInputColumns.map((column) => column.name);

  const input = inputColumnsNames.filter((columnName) =>
    isPartOfInputSchema(columnName, inputSchemaFields, featureGates),
  );
  const auxiliary = inputColumnsNames.filter(
    (columnName) =>
      !isPartOfInputSchema(columnName, inputSchemaFields, featureGates),
  );

  return {
    input,
    auxiliary,
  };
};

export const validateCellValue = (
  value: string,
  {
    type,
    nullable,
    required,
    enumValues,
  }: {
    type: DesiredType;
    nullable: boolean;
    required: boolean;
    enumValues?: EnumOptionsBET | null;
  },
): ValidationResult | undefined => {
  if (value.trim() === "") {
    if (required) {
      return REQUIRED_ERROR_MESSAGE;
    }

    return undefined;
  }

  if (nullable) {
    const trimmedValue = value.trim();

    if (trimmedValue === "null") {
      return undefined;
    }
  }

  switch (type) {
    case "boolean":
      return validateBoolean(value);
    case "integer":
      return validateInteger(value);
    case "number":
      return validateNumber(value);
    case "array":
      return validateArray(value);
    case "object":
      return validateObject(value);
    case "any":
      return validateAny(value);
    case "date":
      return validateDate(value);
    case "datetime":
      return validateDatetime(value);
    case "string":
      return validateString(value);
    case "enum":
      return validateEnum(value, enumValues);
    default:
      assertUnreachable(type);
      return undefined;
  }
};

export const isRowEmpty = (row: DatasetRow) =>
  isEmpty(row.mock_data) && isEmpty(row.output_data) && isEmpty(row.input_data);

export const MOCK_COLUMN_DISABLED_TOOLTIP = {
  title:
    'This Node is set to use a provider\'s live environment during test runs. If you would like to use mock external data instead adjust this setting under the "Advanced settings" tab on the Node.',
  action: {
    label: "Read more",
    onClick: () =>
      window.open(
        "https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1/edit-datasets-and-resolve-incompatibility#external-mock-data",
        "_blank",
      ),
  },
};

const replaceAllKeywords = (
  value: string,
  keywords: Record<string, string>,
): string => {
  const val = value.trim();
  if (keywords[val]) {
    return value.replace(val, keywords[val]);
  }

  /**
   * The first part of the regex (\\\\"|"(?:\\\\"|[^"])*"|) neutralizes parts of the string surrounded
   * by quotes as explained in https://stackoverflow.com/a/23667311
   * The second one (:\\s*(${keyword})\\s*[,}]|) looks for the keyword in the values of a json object.
   * We account for the possible whitespace before and after and we look for the comma or the closing
   * bracket after it.
   * The third one ([\\[,]\\s*(${keyword})\\s*(?=[,\\]])) looks for the keyword in a json array.
   * We start looking for a comma or a opening bracket, then we account for the whitespace before and after
   * and finally look for the comma or the closing bracket after it. That last step is done in lookahead
   * to allow consecutive matches to be done.
   */
  const keywordsRegexString = Object.keys(keywords).join("|");
  const regex = new RegExp(
    `\\\\"|"(?:\\\\"|[^"])*"|:\\s*(${keywordsRegexString})\\s*[,}]|[\\[,]\\s*(${keywordsRegexString})\\s*(?=[,\\]])`,
    "g",
  );

  return value.replace(regex, (match, group1, group2) => {
    // The first part of the regex can match but has no groups
    if (!group1 && !group2) return match;
    // If group1 is not undefined the second part must have matched
    // then we take the match and replace the keyword
    else {
      const group = group1 ?? group2;
      return match.replace(group, keywords[group]);
    }
  });
};

export const jsonToPon = (json: string): string => {
  const keywords = {
    true: "True",
    false: "False",
    null: "None",
  };

  return replaceAllKeywords(json, keywords);
};

export const ponToJson = (pon: string): string => {
  const keywords = {
    True: "true",
    False: "false",
    None: "null",
  };

  return replaceAllKeywords(pon, keywords);
};

export const isLoopIntegrationNode = (node: DatasetIntegrationNode) => {
  return node.provider === NODE_TYPE.LOOP_NODE;
};

export const isFlowIntegrationNode = (node: DatasetIntegrationNode) => {
  return node.provider === NODE_TYPE.FLOW_NODE;
};

export const isParentIntegrationNode = (node: DatasetIntegrationNode) => {
  return isLoopIntegrationNode(node) || isFlowIntegrationNode(node);
};

export const isAiIntegrationNode = (node: DatasetIntegrationNode) => {
  return node.provider === NODE_TYPE.AI_NODE_V2;
};

export const isNonProviderIntegrationNode = (node: DatasetIntegrationNode) => {
  return isParentIntegrationNode(node) || isAiIntegrationNode(node);
};

export const getDesiredType = (node: DatasetIntegrationNode) =>
  isLoopIntegrationNode(node) ? "any" : "object";

export const getNonProviderIntegrationNodeIcon = (provider: string) => {
  if (
    provider !== NODE_TYPE.LOOP_NODE &&
    provider !== NODE_TYPE.FLOW_NODE &&
    provider !== NODE_TYPE.AI_NODE_V2
  ) {
    return faPlug;
  }

  return NON_PROVIDER_ICONS[provider];
};

/**
 * Tries to parse the value as JSON and returns the result.
 * If the value is not valid JSON, it returns the value as is.
 *
 * @returns The parsed value or the original value if the parsing fails.
 */
export const parseJsonOrReturnAsIs = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const ENTITY_ID_FIELD_NAME = "id";
export const EVENT_ID_FIELD_NAME = "id";

export const isEntityColumn = (
  column:
    | DatasetRowColumn
    | ColumnWithEntityParentResource
    | ColumnWithEventParentResource,
): column is ColumnWithEntityParentResource => {
  const resource = column.parent?.columnDef.meta?.resource;

  if (!resource) {
    return false;
  }

  return "_schema" in resource && resource._schema === "Entity";
};

export const isEntityIdColumn = (
  column: Column<DatasetRow, unknown>,
): column is ColumnWithEntityParentResource =>
  isEntityColumn(column) && column.id.endsWith(`.${ENTITY_ID_FIELD_NAME}`);

export const isEventColumn = (
  column:
    | DatasetRowColumn
    | ColumnWithEntityParentResource
    | ColumnWithEventParentResource,
): column is ColumnWithEventParentResource => {
  const resource = column.parent?.columnDef.meta?.resource;

  if (!resource) {
    return false;
  }

  return "event_type" in resource;
};

type DatasetRowColumn = Column<DatasetRow, unknown>;
type ColumnWithEntityParentResource = DatasetRowColumn & {
  parent: DatasetRowColumn & {
    columnDef: { meta: { resource: EntitySchemaResource } };
  };
};
type ColumnWithEventParentResource = DatasetRowColumn & {
  parent: DatasetRowColumn & {
    columnDef: { meta: { resource: EventConfigEnriched } };
  };
};

export const isEntityPrimaryOrSecondaryProperty = (
  column: Column<DatasetRow, unknown>,
): column is ColumnWithEntityParentResource => {
  if (!isEntityColumn(column)) {
    return false;
  }

  const columnName = column.columnDef.meta?.archetype?.name;
  const isPrimaryColumn =
    column.parent.columnDef.meta?.resource?._primary_property === columnName;
  const isSecondaryColumn =
    column.parent.columnDef.meta?.resource?._secondary_property === columnName;

  return isPrimaryColumn || isSecondaryColumn;
};

export const isEventIdColumn = (
  column: Column<DatasetRow, unknown>,
): column is ColumnWithEventParentResource =>
  isEventColumn(column) && column.id.endsWith(`.${EVENT_ID_FIELD_NAME}`);

export const getColumnResource = (column: Column<DatasetRow, unknown>) =>
  column.parent?.columnDef.meta?.resource;
