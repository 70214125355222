import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { isObject } from "lodash";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import {
  DataList,
  DataListRow,
  DataValue as DataValueBase,
} from "src/base-components/DataList";
import { PillToggle } from "src/base-components/PillToggle";
import { INTERNAL_DATA_KEYS } from "src/constants/InternalDataKeys";
import { DecisionDataTree } from "src/dataTable/DetailedView/tabs/DecisionDataTree";
import {
  ExpectedDataPopover,
  MatchPill,
  MismatchPill,
} from "src/dataTable/ExpectedDataCell";
import { hasExpectedData } from "src/dataTable/TableUtils";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { EmptyState } from "src/design-system/EmptyState";
import { loadingTreeData } from "src/entities/entityView/Tree";
import { useSelectedResultsRowIndex } from "src/flowContainer/AuthoringUIContext";
import { useWorkspaceFeatureGates } from "src/hooks/useWorkspaceFeatureGates";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { isISO8601 } from "src/utils/datetime";

type InspectDataProps = {
  row?: ResultDataAndAuxRowV2 | undefined;
  rowData: Record<string, unknown> | undefined;
  accessedFields: string[];
  isFetching: boolean;
  isHistorical?: boolean;
  withAccessedFields: boolean;
  selectedRowIndex: number | null;
  dividers?: boolean;
  borders?: boolean;
};

export const InspectData: React.FC<
  Omit<InspectDataProps, "selectedRowIndex">
> = (props) => {
  const selectedRowIndex = useSelectedResultsRowIndex();

  return <InspectDataBase {...props} selectedRowIndex={selectedRowIndex} />;
};
enum InspectDataListView {
  ALL_FIELDS = "all_fields",
  ACCESSIBLE_FIELDS = "accessible_fields",
}

export const InspectDataBase: React.FC<InspectDataProps> = ({
  row,
  rowData,
  accessedFields,
  isFetching,
  isHistorical,
  withAccessedFields,
  selectedRowIndex,
  dividers = false,
  borders = false,
}) => {
  const featureGates = useWorkspaceFeatureGates();
  const isOutputRow = row && hasExpectedData(row);
  const isLoading = isFetching || !rowData;
  const [selectedField, setSelectedField] =
    useSessionStorage<InspectDataListView>(
      "inspect-data-list-view",
      InspectDataListView.ALL_FIELDS,
    );

  const displayDataRows: DataListRow[] = Object.entries(rowData ?? {})
    .filter(([key]) => !INTERNAL_DATA_KEYS.includes(key))
    .map(([key, value]) => [
      key,
      <DataValue
        key={key}
        expectedOutputData={isOutputRow ? row?.expectedOutputData : undefined}
        expectedOutputKeysMismatch={
          isOutputRow ? row?.expectedOutputKeysMismatch : undefined
        }
        field={key}
        value={value}
      />,
    ]);

  const filteredRows = withAccessedFields
    ? displayDataRows.filter(
        ([id]) =>
          selectedField === InspectDataListView.ALL_FIELDS ||
          (selectedField === InspectDataListView.ACCESSIBLE_FIELDS &&
            accessedFields?.includes(id)),
      )
    : displayDataRows;
  const filteredRowData = withAccessedFields
    ? Object.fromEntries(
        Object.entries(rowData ?? {}).filter(
          ([key]) =>
            selectedField === InspectDataListView.ALL_FIELDS ||
            (selectedField === InspectDataListView.ACCESSIBLE_FIELDS &&
              accessedFields?.includes(key)),
        ),
      )
    : rowData;

  const dataList =
    isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
    (featureGates.featuresEventsEnabled || featureGates.entitiesEnabled) ? (
      <DecisionDataTree data={filteredRowData} loading={isLoading} />
    ) : (
      <DataList borders={borders} dividers={dividers} rows={filteredRows} />
    );

  const loadingList =
    isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) &&
    (featureGates.featuresEventsEnabled || featureGates.entitiesEnabled) ? (
      <DecisionDataTree data={loadingTreeData} loading={true} />
    ) : (
      <DataList borders={borders} dividers={dividers} isLoading={true} />
    );

  return (
    <div
      className="flex h-full min-h-0 flex-1 flex-col gap-y-2"
      data-loc="inspect-data-fields"
    >
      {withAccessedFields && (
        <PillToggle value={selectedField} onChange={setSelectedField}>
          <PillToggle.Button value={InspectDataListView.ALL_FIELDS}>
            All fields
          </PillToggle.Button>
          <PillToggle.Button value={InspectDataListView.ACCESSIBLE_FIELDS}>
            Accessed fields
          </PillToggle.Button>
        </PillToggle>
      )}

      <div
        className={twJoin(
          "decideScrollbar h-full min-h-0 flex-1 pr-4",
          dividers && "divide-y divide-gray-100",
        )}
      >
        {isLoading ? (
          loadingList
        ) : filteredRows.length > 0 ? (
          dataList
        ) : selectedField === InspectDataListView.ACCESSIBLE_FIELDS ? (
          <EmptyState
            description={
              isHistorical
                ? `This node did not access any fields for this decision`
                : `This node did not access any fields for #${
                    (selectedRowIndex || 0) + 1
                  } test case during the last test run`
            }
            headline="No fields accessed"
            icon={faSearch}
          />
        ) : (
          <EmptyState
            description={
              isHistorical
                ? "This decision had no fields for this decision"
                : "This test case had no fields during the last test run"
            }
            headline="No fields found"
            icon={faSearch}
          />
        )}
      </div>
    </div>
  );
};

const DataValue: React.FC<{
  field: string;
  value: unknown;
  expectedOutputData?: Record<string, unknown>;
  expectedOutputKeysMismatch?: string[];
}> = ({ field, value, expectedOutputData, expectedOutputKeysMismatch }) => {
  const isRowWithExpectedData = expectedOutputData?.[field] !== undefined;
  const isMatch =
    isRowWithExpectedData && !expectedOutputKeysMismatch?.includes(field);

  if (Array.isArray(value) || isObject(value)) {
    return (
      <div className="flex w-full flex-col items-start gap-y-1.5">
        <DataValueBase field={field} value={value} />

        {isRowWithExpectedData && (
          <ExpectedDataPopover
            actualOutput={{
              value: JSON.stringify(value),
              isMatch,
            }}
            expectedOutput={{
              value: JSON.stringify(expectedOutputData?.[field]),
              isMatch,
            }}
          >
            {isMatch ? <MatchPill /> : <MismatchPill />}
          </ExpectedDataPopover>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full items-center justify-between">
      <DataValueBase
        field={field}
        value={
          typeof value === "string" && !isISO8601(value)
            ? JSON.stringify(value)
            : value
        }
      />

      {isRowWithExpectedData && (
        <ExpectedDataPopover
          actualOutput={{
            value: String(value),
            isMatch,
          }}
          expectedOutput={{
            value: String(expectedOutputData?.[field]),
            isMatch,
          }}
        >
          {isMatch ? <MatchPill /> : <MismatchPill />}
        </ExpectedDataPopover>
      )}
    </div>
  );
};
