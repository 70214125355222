import {
  faDatabase,
  faBracketsCurly,
  faFlagAlt,
  faSliders,
  faWavePulse,
  faChartSimple,
} from "@fortawesome/pro-regular-svg-icons";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import { useEventListener } from "usehooks-ts";

import { Icon } from "src/base-components/Icon";
import { GroupIcon, getNodeIconFromNode } from "src/constants/NodeIcons";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { useDatasets } from "src/datasets/api/queries";
import { useIsTaktileOrgUser } from "src/hooks/useIsTaktileOrgUser";
import { OmniboxBase, MenuItem } from "src/omnibox/OmniboxBase";
import { OmniboxFeatureFlagLabel } from "src/omnibox/OmniboxFeatureFlagLabel";
import {
  useOmniboxActions,
  useIsOmniboxVisible,
} from "src/omnibox/OmniboxStore";
import { LeftPaneOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { getParentGroup } from "src/utils/GraphUtils";
import { logger } from "src/utils/logger";

export const OmniboxAuthoring = () => {
  const [_, setSearchParams] = useSearchParams();

  const { workspace, flow } = useAuthoringContext();
  const isOmniboxVisible = useIsOmniboxVisible();
  const { showOmnibox, hideOmnibox } = useOmniboxActions();
  const isTaktileUser = useIsTaktileOrgUser();
  const document = useRef(window.document);
  const { fitView, getNode } = useReactFlow();
  const graphStore = useGraphStore();
  const { setSelectedNode, toggleGroupNode } = useGraphStore();

  const handleKeydown = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyK" && isTaktileUser) {
      showOmnibox();
    }
    if (isOmniboxVisible && e.key === "Escape") {
      hideOmnibox();
    }
  };

  useEventListener("keydown", handleKeydown, document);

  // Fetch the relevant data for the omnibox items: list of nodes, node-groups, feature flags, datasets, and left-pane actions
  const nodes = graphStore.nodesArray;
  const groupNodes = graphStore.groupsArray;
  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });
  const datasets = datasetsQuery.data || [];

  const leftPaneOptions: {
    key: LeftPaneOptions;
    text: string;
    icon: React.ReactNode;
  }[] = [
    {
      key: LeftPaneOptions.Datasets,
      text: "Go to Test data",
      icon: <Icon color="text-gray-500" icon={faDatabase} size="xs" />,
    },
    {
      key: LeftPaneOptions.Schema,
      text: "Go to Schema",
      icon: <Icon color="text-gray-500" icon={faBracketsCurly} size="xs" />,
    },
    {
      key: LeftPaneOptions.Parameters,
      text: "Go to Parameters",
      icon: <Icon color="text-gray-500" icon={faSliders} size="xs" />,
    },
    {
      key: LeftPaneOptions.DecisionHistory,
      text: "Go to Decision history",
      icon: <Icon color="text-gray-500" icon={faWavePulse} size="xs" />,
    },
    {
      key: LeftPaneOptions.Analytics,
      text: "Go to Analytics",
      icon: <Icon color="text-gray-500" icon={faChartSimple} size="xs" />,
    },
  ];

  // Create omnibox items list for nodes, node-groups, feature flags, datasets, and left-pane actions
  const nodeItems: MenuItem[] = nodes.reduce(
    (nodeMenuItems: MenuItem[], node) => {
      let icon;
      const nodeType = node.type;

      switch (nodeType) {
        case NODE_TYPE.RULE_NODE_V2:
        case NODE_TYPE.CODE_NODE:
        case NODE_TYPE.SPLIT_NODE_V2:
        case NODE_TYPE.ML_NODE:
        case NODE_TYPE.LOOP_NODE:
        case NODE_TYPE.DECISION_TABLE_NODE:
        case NODE_TYPE.ASSIGNMENT_NODE:
        case NODE_TYPE.SCORECARD_NODE:
        case NODE_TYPE.FLOW_NODE:
        case NODE_TYPE.REVIEW_CONNECTION_NODE:
        case NODE_TYPE.INTEGRATION_NODE:
        case NODE_TYPE.CUSTOM_CONNECTION_NODE:
        case NODE_TYPE.AI_NODE_V2:
          icon = getNodeIconFromNode(node, "sm");
          break;
        default:
          // If node type is not of the above types, then we filter it out, and don't show it to the user.
          return nodeMenuItems;
      }

      nodeMenuItems.push({
        icon,
        label: node.data.label,
        details: node.type,
        value: node.id,
        onSelect: () => selectItem(node.id, "Node"),
        type: "Node",
      });

      return nodeMenuItems;
    },
    [],
  );
  const datasetItems: MenuItem[] = datasets.map((dataset) => ({
    label: dataset.name,
    icon: (
      <div className="flex h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50">
        <Icon color="text-gray-500" icon={faDatabase} size="xs" />
      </div>
    ),
    type: "Dataset",
    details: "",
    value: `edit-dataset-id=${dataset.id}`,
    onSelect: () =>
      setSearchParams({
        "edit-dataset-id": dataset.id,
      }),
  }));
  const groupNodeItems: MenuItem[] = groupNodes.map((group) => ({
    label: group.data.label,
    icon: (
      <div className="ml-0.5 mt-0.5 pl-0.5 pt-px">
        <GroupIcon groupId={group.id} size="sm" />
      </div>
    ),
    type: "Node Groups",
    details: "",
    value: group.id,
    onSelect: () => selectItem(group.id, "Node Groups"),
  }));
  const leftPaneItems = leftPaneOptions.map(({ key, icon, text }) => ({
    label: text,
    icon: (
      <div className="h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50 pl-px pt-0.5">
        {icon}
      </div>
    ),
    type: "Action",
    details: "",
    value: key,
    onSelect: () => selectItem(key, "Action"),
  }));
  const featureFlagItems: MenuItem[] = Object.entries(FEATURE_FLAGS).map(
    ([key, value]) => ({
      label: <OmniboxFeatureFlagLabel featureFlag={value} />,
      icon: (
        <div className="flex h-6 w-6 items-center justify-center rounded-md bg-gray-200 bg-opacity-50">
          <Icon color="text-gray-500" icon={faFlagAlt} size="xs" />
        </div>
      ),
      type: "Feature Flag",
      details: value,
      value: key,
      onSelect: () => selectItem(value, "Feature Flag"),
    }),
  );

  const selectItem = (
    value: string,
    type: "Node" | "Feature Flag" | "Node Groups" | "Action" | "Dataset",
  ) => {
    try {
      switch (type) {
        case "Node": {
          const nodeDetails = getNode(value);
          if (nodeDetails) {
            setSelectedNode(value);
            fitView({
              padding: 0.2,
              includeHiddenNodes: false,
              maxZoom: 1,
              minZoom: 0.4,
              duration: 300,
              nodes: [nodeDetails],
            });
          } else {
            const parentNode = getParentGroup(graphStore.groups, value);
            if (parentNode && !parentNode.data.uiState.expanded) {
              toggleGroupNode(parentNode.id, true);
              setTimeout(() => {
                const childNode = getNode(value);
                setSelectedNode(value);
                if (childNode) {
                  fitView({
                    padding: 0.2,
                    includeHiddenNodes: false,
                    maxZoom: 1,
                    minZoom: 0.4,
                    duration: 300,
                    nodes: [childNode],
                  });
                }
              }, 100);
            }
          }
          break;
        }

        case "Feature Flag":
          setSearchParams((searchParams) => {
            if (searchParams.has(value)) {
              searchParams.delete(value);
            } else {
              searchParams.append(value, "true");
            }
            return searchParams;
          });
          break;

        case "Node Groups": {
          toggleGroupNode(value, true);
          const groupNodeDetails = getNode(value);
          if (groupNodeDetails) {
            setSelectedNode(null);
            fitView({
              padding: 0.2,
              includeHiddenNodes: false,
              maxZoom: 1,
              minZoom: 0.4,
              duration: 300,
              nodes: [groupNodeDetails],
            });
          }
          break;
        }

        case "Dataset":
          setSearchParams({
            "edit-dataset-id": value.split("=")[1],
          });
          break;

        case "Action":
          setSearchParams({ "left-pane": value });
          break;

        default:
          break;
      }
    } catch (error) {
      logger.error("Error selecting item:", error);
    }
  };

  const menuItems = isTaktileUser
    ? [
        ...nodeItems,
        ...groupNodeItems,
        ...leftPaneItems,
        ...datasetItems,
        ...featureFlagItems,
      ]
    : [...nodeItems, ...groupNodeItems, ...leftPaneItems, ...datasetItems];

  return (
    <OmniboxBase
      data-loc="omnibox-canvas"
      menuItems={menuItems}
      open={isOmniboxVisible}
      placeholder="Search for nodes or actions..."
      onClose={hideOmnibox}
    />
  );
};
