import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Tabs, TabT } from "src/base-components/Tabs";
import { DecisionSidepaneProps } from "src/decisionsOverview/DecisionsSidepaneWrapper";
import { TraceDecisionButton } from "src/decisionsOverview/TraceDecisionButton";
import { TruncatedIdPill } from "src/decisionsOverview/TruncatedIdPill";
import { useSidepaneContext } from "src/entities/entityView/DecisionsSidePane/SidePaneContext";

type Props = Omit<DecisionSidepaneProps, "baseUrl"> & {
  tabs: TabT[];
  isInputSchemaError: boolean;
  defaultTab: string;
};

const Header: React.FC<Pick<Props, "decision" | "onClose">> = ({
  decision,
  onClose,
}) => {
  const flowQuery = useFlow(decision?.flow.id);
  return (
    <div className="flex w-full items-center justify-between">
      {decision && (
        <h3 className="flex items-center gap-x-1 font-inter-semibold-13px">
          Decision ID
          <div className="w-32">
            <TruncatedIdPill dataLoc="decision-id-pill" id={decision.id} />
          </div>
        </h3>
      )}
      <div className="flex items-center gap-x-2">
        <LoadingView
          queryResult={flowQuery}
          renderUpdated={(flow: FlowT) =>
            decision ? (
              <div className="ml-3">
                <TraceDecisionButton decision={decision} flow={flow} />
              </div>
            ) : (
              <></>
            )
          }
          renderUpdating={() => <></>}
        />
        <div className="flex gap-x-1.5 text-gray-500">
          <Icon
            dataLoc="webhook-logs-close"
            icon={faTimes}
            size="xs"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export type DecisionRequest = { data: Record<string, any> };

export const DecisionsSidepane: React.FC<Props> = ({
  isOpen,
  decision,
  onClose,
  tabs,
  isInputSchemaError,
  defaultTab,
}) => {
  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  // reset active tab when decision changes
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [decision, defaultTab]);

  const { width, ref, ResizeHandler } = useSidepaneContext();

  return (
    <FloatingWindowsProvider>
      <div
        ref={ref}
        className={twJoin(
          "right-0 top-[49px] ml-auto flex h-[calc(100vh-49px)] flex-col border-l border-gray-200 bg-white p-5 transition-opacity",
          isOpen ? "fixed" : "hidden",
        )}
        data-loc="decision-sidepane"
        style={{
          width: `${width}px`,
        }}
      >
        <ResizeHandler />
        <Header decision={decision} onClose={onClose} />
        <hr className="mb-2 mt-4 border-gray-100" />
        <Tabs
          containerClassName="flex h-full flex-col overflow-hidden"
          panelsClassName="decideScrollbar flex-1 overflow-y-auto flex flex-col"
          selectedKey={isInputSchemaError ? "input-tab-data" : activeTab}
          tabClassName="px-2.5"
          tabListClassName="border-b border-gray-100 mb-6 sticky bg-white"
          tabs={tabs}
          onChange={setActiveTab}
        />
      </div>
    </FloatingWindowsProvider>
  );
};
