import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  useConnections,
  useConnectionStatus,
  useDeleteConnection,
} from "src/api/connectApi/queries";
import { ConnectionT, isSQLDatabaseProvider } from "src/api/connectApi/types";
import { useWorkspace } from "src/api/queries";
import { LoadingView } from "src/base-components/LoadingView";
import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { toastMultiItems } from "src/base-components/ToastMultiState/ToastMultiState";
import { ToastMultiStateItem } from "src/base-components/ToastMultiState/types";
import { EditConnectionModal } from "src/connections/EditModal";
import { ConnectionList } from "src/connections/List";
import {
  useConnectionManagementActions,
  useConnectionToDelete,
  useConnectionToEdit,
  useConnectionToTest,
  useModalOpen,
} from "src/connections/store/connectionManagementStore";
import { toastActions } from "src/design-system/Toast/utils";
import { SubHeader } from "src/flow/SubHeader";
import { DashboardContent } from "src/layout/DashboardContent";

const extractProbeErrorDetails = (error: any) => {
  return error?.response ? error.response.data?.detail?.[0] : error;
};

const getConnectionAvailableProbeEnvironments = (connection: ConnectionT) => {
  if (isSQLDatabaseProvider(connection.provider)) {
    if (connection.enable_non_prod_configs) {
      return ["production", "sandbox"];
    }
    return ["production"];
  }

  const environments: string[] = [];

  if (connection.configuration.probe_url?.trim()) {
    environments.push("production");
  }

  if (
    connection.enable_non_prod_configs &&
    connection.non_prod_env_configs?.sandbox?.probe_url?.trim()
  ) {
    environments.push("sandbox");
  }

  return environments;
};

const ProbeSuccessMessage = ({ env }: { env: string }) => (
  <span>
    {`Test passed for `}
    <strong>{env}</strong>
    {` credentials.`}
  </span>
);

const ProbeErrorMessage = ({
  env,
  message,
}: {
  env: string;
  message: string;
}) => (
  <div>
    <span>
      {`Test failed for `}
      <strong>{env}</strong>
      {` credentials.`}
    </span>
    <br />
    {`Error: ${message}`}
  </div>
);

const handleTestResponse =
  (resultType: "passed" | "failed") =>
  async (connection: ConnectionT | undefined, error?: any) => {
    if (connection === undefined)
      throw new Error(
        "Impossible: Connection can't be undefined in the test response handler.",
      );

    if (resultType === "passed") {
      toastActions.success({
        title: "Connection test passed",
        description: (
          <>
            {`Connection `}
            <strong>{connection.name}</strong>
            {` test ran successfully.`}
          </>
        ),
      });
      return;
    }

    const probeErrorDetails = extractProbeErrorDetails(error);
    const { ctx } = probeErrorDetails || {};

    if (!ctx?.msg) {
      toastActions.failure({
        title: "Connection test failed",
        description: (
          <>
            {`Connection `}
            <strong>{connection.name}</strong>
            {` test did not run successfully.`}
          </>
        ),
      });
      return;
    }

    let jsonMessage: Record<string, string>;

    try {
      jsonMessage = JSON.parse(ctx.msg);
    } catch {
      // If parsing the message as JSON failed, then the error
      // is not the specific error thrown when some environments fail probing
      toastActions.failure({
        title: "Connection test failed",
        description: (
          <>
            {`Connection `}
            <strong>{connection.name}</strong>
            {` test did not run successfully.`}
          </>
        ),
      });
      return;
    }

    // The error returned for probe is a JSON string
    // this is wrapped in a try...catch to handle cases
    // where the error is a different one
    const toastItems: ToastMultiStateItem[] =
      getConnectionAvailableProbeEnvironments(connection).map((env) => {
        const envFailedProbing = env in jsonMessage;
        if (envFailedProbing) {
          return {
            variant: "error",
            description: (
              <ProbeErrorMessage env={env} message={jsonMessage[env]} />
            ),
          };
        }

        return {
          variant: "success",
          description: <ProbeSuccessMessage env={env} />,
        };
      });

    toastMultiItems({
      title: "Connection Test completed",
      items: toastItems,
    });
  };
const handleTestPass = handleTestResponse("passed");
const handleTestFail = handleTestResponse("failed");

export const ConnectionOverview: React.FC<{
  workspaceId: string;
}> = ({ workspaceId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workspace = useWorkspace(workspaceId);
  const baseUrl = workspace.data?.base_url;
  const connections = useConnections(baseUrl, {
    hideManualReviewConnection: true,
  });
  const connectionToTest = useConnectionToTest();
  const {
    setConnectionToEdit,
    setConnectionToDelete,
    setConnectionToTest,
    setModalOpen,
  } = useConnectionManagementActions();

  const { refetch: refetchConnStatus } = useConnectionStatus(
    baseUrl,
    connectionToTest,
    {
      onTestPass: async (connection) => {
        await handleTestPass(connection);
        setConnectionToTest(undefined);
      },
      onTestFail: async (connection, error) => {
        await handleTestFail(connection, error);
        setConnectionToTest(undefined);
      },
    },
  );

  useEffect(() => {
    const connectionId = searchParams.get("connectionId");
    if (connectionId) {
      if (connections.data && connections.data.length > 0) {
        const connection = connections.data.find((c) => c.id === connectionId);
        if (connection) {
          setConnectionToEdit(connection);
          setModalOpen(true);
        } else {
          setSearchParams({});
          toastActions.failure({
            title: "Not found",
            description: `Connection ${connectionId} not found.`,
          });
        }
      } else {
        setConnectionToEdit(undefined);
      }
    }
  }, [
    setConnectionToEdit,
    setModalOpen,
    searchParams,
    setSearchParams,
    connections.data,
  ]);

  const renderConnectionList = (connections: ConnectionT[]) => {
    return (
      <ConnectionList
        connections={connections}
        testingConnection={connectionToTest}
        onDelete={(id) => {
          setConnectionToDelete(connections.find((c) => c.id === id));
        }}
        onEdit={(id) => {
          setConnectionToEdit(connections.find((c) => c.id === id));
          setModalOpen(true);
        }}
        onTest={(id) => {
          const connection = connections.find((c) => c.id === id);
          if (!connection) return;

          if (id === connectionToTest?.id) {
            refetchConnStatus();
          }
          setConnectionToTest(connection);
        }}
      />
    );
  };
  return (
    <DashboardContent
      Header={
        <SubHeader title="Connections">
          <SubHeader.Button
            dataLoc="create-connection-button"
            icon={faPlus}
            tooltip="Create Connection"
            onClick={() => setModalOpen(true)}
          />
        </SubHeader>
      }
    >
      <LoadingView
        queryResult={connections}
        renderUpdated={renderConnectionList}
      />
      <ConnectionManagementModals workspaceId={workspaceId} />
    </DashboardContent>
  );
};

const ConnectionManagementModals = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const connectionToDelete = useConnectionToDelete();
  const connectionToEdit = useConnectionToEdit();
  const modalOpen = useModalOpen();
  const [, setSearchParams] = useSearchParams();
  const { setConnectionToEdit, setConnectionToDelete, setModalOpen } =
    useConnectionManagementActions();

  const workspace = useWorkspace(workspaceId);
  const baseUrl = workspace.data?.base_url;
  const deleteConnection = useDeleteConnection(baseUrl);

  const handleDelete = useCallback(async () => {
    if (connectionToDelete == null) return;
    const { id, name } = connectionToDelete;
    try {
      await deleteConnection.mutateAsync(id);

      toastActions.success({
        title: "Connection deleted",
        description: `Connection ${name} was deleted.`,
      });
    } catch (e) {
      const isConnectionUsedInFlow =
        e instanceof AxiosError && e.response?.data?.detail?.flow_ids;
      const errorDescription = isConnectionUsedInFlow
        ? `Connection ${name} is used in at least one flow. Remove the respective nodes before deleting the Connection.`
        : `Connection ${name} could not be deleted.`;

      toastActions.failure({
        title: "Failed to delete connection",
        description: errorDescription,
      });
    }
  }, [connectionToDelete, deleteConnection]);

  return (
    <>
      <EditConnectionModal
        afterLeave={() => {
          setConnectionToEdit(undefined);
          setSearchParams({});
        }}
        connectionToEdit={connectionToEdit}
        open={modalOpen}
        workspaceId={workspaceId}
        onClose={() => setModalOpen(false)}
      />

      <TextConfirmationModal
        challengeText={connectionToDelete?.name}
        description="Are you sure you want to delete this connection? This action is not reversible, and might affect your existing API integrations."
        label="Type Connection name"
        open={connectionToDelete !== undefined}
        title={`Confirm delete ${connectionToDelete?.name}`}
        onClose={() => setConnectionToDelete(undefined)}
        onConfirm={handleDelete}
      />
    </>
  );
};
