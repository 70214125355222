import { Root } from "@radix-ui/react-accordion";
import { keyBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Control,
  Controller,
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { useRevision } from "src/changeHistory/queries";
import { LoopNode, LoopNodeDataT } from "src/constants/NodeDataTypes";
import { useSchemas } from "src/datasets/utils";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { completionQueryKeys } from "src/nodeEditor/api/queries";
import { AdvancedSettings } from "src/parentFlowNodes/loopNode/AdvancedSettings";
import { ChildFlowNotAvailable } from "src/parentFlowNodes/loopNode/ChildNotAvailable";
import { FlowSelectionModal } from "src/parentFlowNodes/loopNode/FlowSelectionModal";
import { InputOutputMappings } from "src/parentFlowNodes/loopNode/InputOutputMappings";
import { NoDecisionFlowSelected } from "src/parentFlowNodes/loopNode/NoDecisionFlowSelected";
import { SelectedVersionDisplay } from "src/parentFlowNodes/loopNode/SelectedVersionDisplay";
import { useUpdateKnownEtag } from "src/parentFlowNodes/useUpdateKnownEtag";
import {
  queryErrorLikelyBecauseChildFlowGotDeleted,
  setupLoopNodeForm,
} from "src/parentFlowNodes/utils";
import { queryClient } from "src/queryClient";
import { ErrorContent } from "src/router/ErrorPage";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  selectedNode: LoopNode;
} & NodeEditorBaseProps<LoopNodeDataT>;

export type LoopNodeForm = LoopNodeDataT;

const useRequestNewTypingData = (
  nodeId: string,
  control: Control<LoopNodeForm, any>,
) => {
  /*
   * We need to invalidate the query when the target_list_expression changes
   * because the typing data is based on the target list
   */
  const target_list = useWatch({
    control: control,
    name: "target_list_expression.value",
  });

  const { version } = useAuthoringContext();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: completionQueryKeys.node(version.id, nodeId),
    });
  }, [nodeId, target_list, version.id]);
};

export const LoopNodeEditor: React.FC<PropsT> = ({
  selectedNode,
  isReactive,
  onUpdate,
  immutable,
  displayedError,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const fieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors, true)
    : undefined;

  const nodeData = selectedNode.data;
  const childFlowQuery = useFlow(nodeData.child_flow_id ?? "", {
    refetchInterval: 5000,
  });
  const childVersionRevisionQuery = useRevision({
    versionId: selectedNode.data.child_flow_version_id ?? undefined,
    etag: selectedNode.data.child_flow_version_known_etag ?? undefined,
    keepPreviousData: true,
  });
  const childVersionSchemas = useSchemas(
    childFlowQuery.data?.versions.find(
      (version) => version.id === selectedNode.data.child_flow_version_id,
    ),
  );
  const handleSubmitRef = useRef<VoidFunction>(() => {});

  const [defaultValues, schemaMappingErrors, fieldsToRemove] = useMemo(() => {
    // Only setup the form once the child flow has been fetched
    if (immutable || childFlowQuery.data === undefined) {
      return [nodeData, [], []];
    } else {
      return setupLoopNodeForm(nodeData, childVersionSchemas);
    }
  }, [immutable, childFlowQuery.data, nodeData, childVersionSchemas]);

  const inputMappingErrors = schemaMappingErrors.filter(
    (e) => e.type === "input",
  );

  const formMethods = useForm<LoopNodeForm>({
    defaultValues,
    ...(isReactive && { values: defaultValues }),
  });

  // keep refs up to date
  handleSubmitRef.current = formMethods.handleSubmit((data) => {
    onUpdate({
      nodeId: selectedNode.id,
      newData: {
        ...nodeData,
        ...data,
      },
    });
  });

  const { unregister } = formMethods;
  useEffect(() => {
    // Prevent unnecessary submits because they e.g. result in change history entries
    if (fieldsToRemove.length > 0) {
      unregister(fieldsToRemove);
      if (!isReactive && !immutable) {
        handleSubmitRef.current();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsToRemove.join(","), isReactive, unregister, immutable]);

  useUpdateKnownEtag({
    nodeId: selectedNode.id,
    nodeData,
    immutable,
    isReactive,
    onUpdate,
    etag: childFlowQuery.data?.versions.find(
      (version) => version.id === selectedNode.data.child_flow_version_id,
    )?.etag,
  });

  const [flowSelectionOpen, setFlowSelectionOpen] = useState(false);

  const { watch } = formMethods;

  useSubmitForm({
    onChange: (data: LoopNodeForm) => {
      onUpdate({ newData: { ...selectedNode.data, ...data } });
    },
    disabled: isReactive,
    previousValues: defaultValues,
    watch: watch,
  });
  useRequestNewTypingData(selectedNode.id, formMethods.control);

  const noFlowSelected =
    !formMethods.getValues().child_flow_id ||
    !formMethods.getValues().child_flow_version_id;

  return (
    <FormProvider {...formMethods}>
      <div className="mt-4 px-6">
        <Root
          className={accordionRootClassName}
          defaultValue={[
            "list",
            "decisionFlow",
            "mappings",
            "advancedSettings",
          ]}
          type="multiple"
        >
          <AccordionItem
            disabled={renderedInDiffView}
            title="Select the list you want to loop over"
            value="list"
          >
            <div className="pb-8">
              <Card>
                <Card.Title title="Loop over every item in" />
                <Card.Content className="mt-2">
                  <Controller
                    control={formMethods.control}
                    name="target_list_expression.value"
                    render={({ field }) => (
                      <AutocompleteCodeInput
                        dataLoc="target-list-expression"
                        disabled={immutable}
                        placeholder="data.field"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Card.Content>
              </Card>
            </div>
          </AccordionItem>
          <AccordionItem
            dataLoc="select-decision-flow"
            disabled={renderedInDiffView}
            title="Select the Decision Flow you want to apply"
            value="decisionFlow"
          >
            <div className="pb-4">
              {noFlowSelected ? (
                <NoDecisionFlowSelected
                  handleSelectDecisionFlow={() => setFlowSelectionOpen(true)}
                  immutable={immutable}
                />
              ) : (
                <LoadingView
                  queryResult={childFlowQuery}
                  renderErrored={(message) => {
                    if (queryErrorLikelyBecauseChildFlowGotDeleted(message))
                      return (
                        <ChildFlowNotAvailable
                          handleSelectDecisionFlow={() =>
                            setFlowSelectionOpen(true)
                          }
                          immutable={immutable}
                        />
                      );
                    else return <ErrorContent message={message} />;
                  }}
                  renderUpdated={(childFlow: FlowT) => {
                    if (
                      !childFlow.versions.some(
                        (version) =>
                          version.id ===
                          formMethods.getValues().child_flow_version_id,
                      )
                    )
                      return (
                        <ChildFlowNotAvailable
                          handleSelectDecisionFlow={() =>
                            setFlowSelectionOpen(true)
                          }
                          immutable={immutable}
                        />
                      );
                    return (
                      <SelectedVersionDisplay
                        childFlow={childFlow}
                        childFlowVersionId={
                          formMethods.getValues().child_flow_version_id
                        }
                        handleChangeDecisionFlow={() =>
                          setFlowSelectionOpen(true)
                        }
                        immutable={immutable}
                      />
                    );
                  }}
                />
              )}
            </div>
          </AccordionItem>
          <AccordionItem
            dataLoc="mappings"
            disabled={renderedInDiffView}
            title="Map inputs and outputs of Decision Flows"
            value="mappings"
          >
            <div className="pb-4">
              {noFlowSelected ? (
                <NoDecisionFlowSelected
                  handleSelectDecisionFlow={() => setFlowSelectionOpen(true)}
                  immutable={immutable}
                />
              ) : (
                <InputOutputMappings
                  childFlowQuery={childFlowQuery}
                  childVersionRevisionQuery={childVersionRevisionQuery}
                  fieldErrors={fieldErrors}
                  fieldMappingErrors={keyBy(
                    inputMappingErrors.filter(
                      (error) => error.issue === "field-missing",
                    ),
                    "fieldName",
                  )}
                  immutable={immutable}
                  setFlowSelectionOpen={setFlowSelectionOpen}
                />
              )}
            </div>
          </AccordionItem>
          <AccordionItem
            disabled={renderedInDiffView}
            title="Advanced settings"
            value="advancedSettings"
          >
            <div className="pb-4">
              <AdvancedSettings
                firstOutputFieldName={
                  childVersionSchemas.output?.properties[0]?.fieldName
                }
                immutable={immutable}
              />
            </div>
          </AccordionItem>
        </Root>
      </div>
      <FlowSelectionModal
        handleClose={() => setFlowSelectionOpen(false)}
        handleSubmit={(version: FlowVersionT, flow: FlowT) => {
          // When a flow version is selected we want to immediately update the node (and not rely on the form submit handler to do it later, because we might not have a lock acquired)
          const newData = {
            ...selectedNode.data,
            ...formMethods.getValues(),
            child_flow_slug: flow.slug,
            child_flow_version_known_etag: version.etag,
            child_flow_id: flow.id,
            child_flow_version_id: version.id,
            is_stale: false,
          };
          onUpdate({
            newData,
          });
        }}
        open={flowSelectionOpen}
      />
    </FormProvider>
  );
};
