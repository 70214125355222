import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

import { useCreateAWSAccount, useUpdateAWSAccount } from "./queries";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { AWSAccount, WorkspaceDataplane } from "src/clients/flow-api";
import { Modal } from "src/design-system/Modal";

interface AwsAccountsSettingsProps {
  awsAccounts: AWSAccount[];
  workspaces: WorkspaceDataplane[];
}

export const AwsAccountsSettings: React.FC<AwsAccountsSettingsProps> = ({
  awsAccounts,
  workspaces,
}) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { mutate: updateAWSAccount } = useUpdateAWSAccount();
  const { mutate: createAWSAccount } = useCreateAWSAccount();
  return (
    <div className="mx-1">
      {awsAccounts.length > 0 ? (
        <div className="space-y-4">
          {awsAccounts.map((account) => (
            <div
              key={account.id}
              className="rounded-lg border border-gray-50 bg-white p-4 shadow-base"
              style={{
                opacity: account.is_enabled ? 1 : 0.5,
              }}
            >
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="font-inter-semibold-16px">{account.name}</h3>
                  <p className="text-gray-600 font-inter-normal-13px">
                    Account ID: {account.account_id}
                  </p>
                  <p className="text-gray-600 font-inter-normal-13px">
                    Used by{" "}
                    {
                      workspaces.filter(
                        (ws) => ws.aws_account_id === account.id,
                      ).length
                    }{" "}
                    workspace(s)
                  </p>
                  {account.description && (
                    <p className="mt-1 text-gray-500 font-inter-normal-13px">
                      {account.description}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <button
                    className={`rounded px-2 py-0.5 text-xs ${
                      account.is_default
                        ? "text-blue-700 bg-green-100 font-inter-semibold-13px"
                        : "bg-gray-100 text-gray-700 font-inter-normal-13px"
                    }`}
                    onClick={() => {
                      updateAWSAccount({
                        ...account,
                        is_default: !account.is_default,
                      });
                    }}
                  >
                    {account.is_default ? "Default" : "Non-Default"}
                  </button>
                  <button
                    className={`rounded px-2 py-0.5 text-xs ${
                      account.is_enabled
                        ? "bg-green-100 text-green-700 font-inter-semibold-13px"
                        : "bg-red-100 text-red-700 font-inter-normal-13px"
                    }`}
                    onClick={() => {
                      updateAWSAccount({
                        ...account,
                        is_enabled: !account.is_enabled,
                      });
                    }}
                  >
                    {account.is_enabled ? "Enabled" : "Disabled"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="h-60"></div>
      )}

      <div className="mt-4">
        <Button
          iconLeft={faPlus}
          variant="primary"
          onClick={() => setIsAddModalOpen(true)}
        >
          Add AWS Account
        </Button>
      </div>

      {isAddModalOpen && (
        <Modal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
          <Modal.Header>Add AWS Account</Modal.Header>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const newAccount: Partial<AWSAccount> = {
                name: formData.get("name") as string,
                account_id: formData.get("account_id") as string,
                description:
                  (formData.get("description") as string) || undefined,
                is_enabled: true,
                is_default: false,
              };

              createAWSAccount(newAccount as AWSAccount);
              setIsAddModalOpen(false);
            }}
          >
            <Modal.Content>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <Label required>Account Name</Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="New AWS Account name"
                    type="text"
                    fullWidth
                    required
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Label required>Account ID</Label>
                  <Input
                    id="account_id"
                    maxLength={12}
                    minLength={12}
                    name="account_id"
                    pattern="[0-9]{12}"
                    placeholder="123456789012"
                    title="AWS Account ID must be exactly 12 digits"
                    type="text"
                    fullWidth
                    required
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Label>Description (Optional)</Label>
                  <textarea
                    className="rounded border border-gray-300 px-3 py-2"
                    id="description"
                    name="description"
                    placeholder="Description of this AWS account"
                    rows={3}
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Footer
              primaryButton={
                <Button htmlType="submit" variant="primary">
                  Add Account
                </Button>
              }
            />
          </form>
        </Modal>
      )}
    </div>
  );
};
