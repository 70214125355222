import { getDefaultEquifaxUSCommercialRiskCreditReport } from "./EquifaxUSCommercialRiskResources";
import { IntegrationProviderResourceT } from "src/api/connectApi/types";
import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { getDefaultBoniversumConsumerReport } from "src/integrationNode/integrationResources/BoniversumResources";
import {
  getDefaultCRIFB2BCreditReport,
  getDefaultCRIFB2BFullReport,
  getDefaultCRIFB2BSearchReport,
} from "src/integrationNode/integrationResources/CRIFB2BResources";
import { getDefaultCRIFHighMarkCreditReport } from "src/integrationNode/integrationResources/CRIFHighMarkResources";
import {
  getDefaultCodatAgedCreditorsReport,
  getDefaultCodatAgedDebtorsReport,
  getDefaultCodatCompanyIdentification,
  getDefaultCodatCompanyInfo,
  getDefaultCodatEnhancedBalanceSheetAccounts,
  getDefaultCodatEnhancedProfitAndLossAccounts,
} from "src/integrationNode/integrationResources/CodatResources";
import {
  getDefaultCreditsafeCompanyCreditReport,
  getDefaultCreditsafeCompanySearch,
} from "src/integrationNode/integrationResources/CreditsafeResources";
import {
  getDefaultCrifB2CCreditCheck,
  getDefaultCrifB2CIdentityCheck,
} from "src/integrationNode/integrationResources/CrifB2CResources";
import { getDefaultDataXCreditReportReport } from "src/integrationNode/integrationResources/DataXResources";
import {
  getDefaultDnbCreditReport,
  getDefaultDnbIdentityResolution,
} from "src/integrationNode/integrationResources/DnbResources";
import { getDefaultEquifaxUKGateway } from "src/integrationNode/integrationResources/EquifaxUKResources";
import {
  getDefaultEquifaxUSMLAReport,
  getDefaultEquifaxUSOneViewReport,
  getDefaultEquifaxUSScoresAndAttributes,
} from "src/integrationNode/integrationResources/EquifaxUSResources";
import { getDefaultExperianUSCreditReport } from "src/integrationNode/integrationResources/ExperianResources";
import {
  getDefaultExperianUSB2BBusinessSearch,
  getDefaultExperianUSB2BSmallBusinessCreditShareProfilesReport,
  getDefaultExperianUSB2BSmallBusinessCreditShareScores,
} from "src/integrationNode/integrationResources/ExperianUSB2BResources";
import { getDefaultExperianUSClarityServicesReport } from "src/integrationNode/integrationResources/ExperianUSClarityServicesResources";
import {
  getDefaultSchufaB2BReport,
  getDefaultSchufaB2CReport,
  getDefaultSchufaFraudPreCheck,
  getDefaultSchufaB2CFurnish,
  getDefaultSchufaB2CUpdateAddress,
  getDefaultSchufaB2CBasicAccountAndExemptionFromExecutionAccountReport,
  getDefaultSchufaB2CMonitorReport,
  getDefaultSchufaB2CManualRetrieval,
} from "src/integrationNode/integrationResources/SchufaResources";
import { IntegrationResourceT } from "src/integrationNode/types";
import { assertUnreachable } from "src/utils/typeUtils";

export const getDefaultResource = (
  providerResource: IntegrationProviderResourceT,
  connectionId: string,
  resourceConfigId: string,
): IntegrationResourceT => {
  // Default case to satisfy Typescript. The assertUnreachables assure that it will not remain unedited.
  let integrationResource = getDefaultSchufaB2CReport();

  if (providerInConfigMap(providerResource.provider)) {
    const providerMap = CONNECTIONS_MAP[providerResource.provider];
    if (providerResource.resource in providerMap.resources) {
      // @ts-ignore
      const resourceMap = providerMap.resources[providerResource.resource];
      integrationResource = resourceMap.integrationResource();
      return {
        ...integrationResource,
        connectionId,
        resourceConfigId,
      };
    }
  }

  switch (providerResource.provider) {
    case "schufa": {
      switch (providerResource.resource) {
        case "b2c_report": {
          integrationResource = getDefaultSchufaB2CReport();
          break;
        }
        case "schufa_b2c_monitor": {
          integrationResource = getDefaultSchufaB2CMonitorReport();
          break;
        }
        case "schufa_fraud_pre_check": {
          integrationResource = getDefaultSchufaFraudPreCheck();
          break;
        }
        case "schufa_b2c_basic_account_and_exemption_from_execution_account_report": {
          integrationResource =
            getDefaultSchufaB2CBasicAccountAndExemptionFromExecutionAccountReport();
          break;
        }
        case "b2b_report_cascading": {
          integrationResource = getDefaultSchufaB2BReport();
          break;
        }
        case "schufa_b2c_furnish": {
          integrationResource = getDefaultSchufaB2CFurnish();
          break;
        }
        case "schufa_b2c_manual_retrieval": {
          integrationResource = getDefaultSchufaB2CManualRetrieval();
          break;
        }
        case "schufa_b2c_update_address": {
          integrationResource = getDefaultSchufaB2CUpdateAddress();
          break;
        }
        default:
          assertUnreachable(providerResource);
      }
      break;
    }
    case "data_x": {
      if (providerResource.resource === "data_x_credit_report") {
        integrationResource = getDefaultDataXCreditReportReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "dnb": {
      switch (providerResource.resource) {
        case "identity_resolution": {
          integrationResource = getDefaultDnbIdentityResolution();
          break;
        }
        case "credit_report": {
          integrationResource = getDefaultDnbCreditReport({ isCustom: false });
          break;
        }
        case "custom_report": {
          integrationResource = getDefaultDnbCreditReport({ isCustom: true });
          break;
        }
        default:
          assertUnreachable(providerResource);
      }
      break;
    }
    case "boniversum": {
      if (providerResource.resource === "consumer_report") {
        integrationResource = getDefaultBoniversumConsumerReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "codat": {
      switch (providerResource.resource) {
        case "company_info": {
          integrationResource = getDefaultCodatCompanyInfo();
          break;
        }
        case "company_identification": {
          integrationResource = getDefaultCodatCompanyIdentification();
          break;
        }
        case "enhanced_profit_and_loss_accounts": {
          integrationResource = getDefaultCodatEnhancedProfitAndLossAccounts();
          break;
        }
        case "enhanced_balance_sheet_accounts": {
          integrationResource = getDefaultCodatEnhancedBalanceSheetAccounts();
          break;
        }
        case "aged_creditors_report": {
          integrationResource = getDefaultCodatAgedCreditorsReport();
          break;
        }
        case "aged_debtors_report": {
          integrationResource = getDefaultCodatAgedDebtorsReport();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "crif_b2b": {
      switch (providerResource.resource) {
        case "credit_report": {
          integrationResource = getDefaultCRIFB2BCreditReport();
          break;
        }
        case "full_report": {
          integrationResource = getDefaultCRIFB2BFullReport();
          break;
        }
        case "search_report": {
          integrationResource = getDefaultCRIFB2BSearchReport();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "crif_b2c": {
      switch (providerResource.resource) {
        case "credit_check": {
          integrationResource = getDefaultCrifB2CCreditCheck();
          break;
        }
        case "identity_check": {
          integrationResource = getDefaultCrifB2CIdentityCheck();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "crif_high_mark": {
      if (providerResource.resource === "credit_report") {
        integrationResource = getDefaultCRIFHighMarkCreditReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "equifax_uk": {
      if (providerResource.resource === "gateway") {
        integrationResource = getDefaultEquifaxUKGateway();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "equifax_us": {
      switch (providerResource.resource) {
        case "scores_and_attributes": {
          integrationResource = getDefaultEquifaxUSScoresAndAttributes();
          break;
        }
        case "mla_report": {
          integrationResource = getDefaultEquifaxUSMLAReport();
          break;
        }
        case "one_view_report": {
          integrationResource = getDefaultEquifaxUSOneViewReport();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "equifax_us_commercial_risk": {
      if (
        providerResource.resource === "equifax_us_commercial_risk_credit_report"
      ) {
        integrationResource = getDefaultEquifaxUSCommercialRiskCreditReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }

    case "experian_us": {
      if (providerResource.resource === "experian_us_credit_report") {
        integrationResource = getDefaultExperianUSCreditReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "experian_us_b2b": {
      switch (providerResource.resource) {
        case "experian_us_b2b_business_search": {
          integrationResource = getDefaultExperianUSB2BBusinessSearch();
          break;
        }
        case "experian_us_b2b_small_business_credit_share_profiles_report": {
          integrationResource =
            getDefaultExperianUSB2BSmallBusinessCreditShareProfilesReport();
          break;
        }
        case "experian_us_b2b_small_business_credit_share_scores": {
          integrationResource =
            getDefaultExperianUSB2BSmallBusinessCreditShareScores();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "experian_us_clarity_services": {
      if (providerResource.resource === "experian_us_clarity_services_report") {
        integrationResource = getDefaultExperianUSClarityServicesReport();
      } else {
        assertUnreachable(providerResource);
      }
      break;
    }
    case "creditsafe": {
      switch (providerResource.resource) {
        case "company_search": {
          integrationResource = getDefaultCreditsafeCompanySearch();
          break;
        }
        case "company_credit_report": {
          integrationResource = getDefaultCreditsafeCompanyCreditReport();
          break;
        }
        default: {
          assertUnreachable(providerResource);
        }
      }
      break;
    }
    case "bkr":
    case "creditreform":
    case "crs":
    case "equifax_canada":
    case "experian_germany":
    case "plaid":
    case "socure":
    case "sardine":
    case "sentilink":
    case "thomson_reuters":
    case "trans_union": {
      throw Error("No default resources for this provider");
    }
    default:
      // @ts-ignore
      // TODO(INT-2556): fix
      assertUnreachable(providerResource);
  }
  integrationResource.connectionId = connectionId;
  integrationResource.resourceConfigId = resourceConfigId;
  return integrationResource;
};
