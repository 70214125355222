import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { produce } from "immer";

import {
  featureQueriesApi as featureQueriesApiCreator,
  featuresApi as featuresApiCreator,
  retrieveFeaturesApi,
} from "src/api/endpoints";
import {
  FeatureQuery,
  FeatureQueryCreate,
  FeatureQueryPut,
  FeatureResponse,
  PreviewQueryRequestEnvironmentEnum,
  PreviewQueryResponse,
  SetFeatureQueryStatusRequest,
} from "src/clients/features-control";
import { GetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetEnvironmentEnum } from "src/clients/features-retrieve/api";
import { Environment } from "src/eventsCatalogue/useEnvironment";
import { FeatureForm } from "src/featureCatalogue/Features/ManageFeatureModal";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const PREFIX_CACHE_KEY = "features";

type UseFeaturesParams = {
  environment?: Environment;
  options?: Pick<
    UseQueryOptions<FeatureResponse[], AxiosError<{ detail: string }>>,
    "enabled" | "select"
  >;
};

export const useFeatures = ({
  environment,
  options,
}: UseFeaturesParams = {}) => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);

  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, workspace.id, { environment }],
    queryFn: async () => (await featuresApi.getFeaturesFeaturesGet()).data,
    staleTime: 5 * 60 * 1000,
    ...options,
  });
};

const selectEntityRelatedFeatures = (features: FeatureResponse[]) => {
  return features.filter((feature) => feature.entity_types?.length !== 0);
};

export const useEntityRelatedFeatures = (options: UseFeaturesParams) => {
  return useFeatures({
    options: {
      select: selectEntityRelatedFeatures,
      ...options,
    },
  });
};

export const useCreateFeature = () => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feature: FeatureForm) =>
      featuresApi.createFeatureHandlerFeaturesPost({
        featureCreate: {
          key: feature.key,
          name: feature.name,
          description: feature.description,
          query_id: null,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

export const useUpdateFeature = () => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      key,
      feature,
      etag,
    }: {
      key: string;
      feature: Partial<Omit<FeatureForm, "key">>;
      etag: string;
    }) =>
      featuresApi.patchFeatureHandlerFeaturesKeyPatch({
        key,
        featurePatch: {
          name: feature.name,
          description: feature.description,
        },
        ifMatch: etag,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

const FEATURE_QUERIES_PREFIX_CACHE_KEY = "feature-queries";

export const useFeatureQueries = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);

  return useQuery({
    queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
    queryFn: async () =>
      (await featureQueriesApi.getFeatureQueriesFeatureQueriesGet()).data,
  });
};

export const useFeatureQuery = (id: string) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  return useQuery({
    queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id, id],
    queryFn: async () =>
      (
        await featureQueriesApi.getFeatureQueryFeatureQueriesIdGet({
          id,
        })
      ).data,
    enabled: !!id,
  });
};

export type UseFeatureQueryPreviewMutationArgs = {
  queryString: string;
  windowLength: number;
  environment: PreviewQueryRequestEnvironmentEnum;
};
export const useFeatureQueryPreview = ({
  onSuccess,
}: {
  onSuccess?: (data: PreviewQueryResponse["data"]) => void;
} = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);

  return useMutation<
    PreviewQueryResponse["data"],
    AxiosError<{ detail: string }>,
    UseFeatureQueryPreviewMutationArgs
  >({
    mutationKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id, "preview"],
    mutationFn: async ({ queryString, environment, windowLength = 300 }) => {
      return (
        await featureQueriesApi.previewQueryFeatureQueriesPreviewPost({
          previewQueryRequest: {
            query: queryString,
            limit: 100,
            window_length: windowLength,
            environment,
          },
        })
      ).data.data;
    },
    onSuccess,
    onError: (error, variables) => {
      if (error.response?.status === 500) {
        logger.error(
          "Unexpected error: Feature query preview failed: ",
          error,
          "Query: ",
          variables,
        );
      }
    },
    throwOnError: false,
  });
};

export const useExploreEventTable = (eventType: string | null) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);

  return useQuery({
    queryKey: [
      FEATURE_QUERIES_PREFIX_CACHE_KEY,
      workspace.id,
      eventType,
      "explore",
    ],
    queryFn: async () =>
      (
        await featureQueriesApi.exploreQueryFeatureQueriesExplorePost({
          exploreFeatureQueryRequest: {
            event: eventType!,
          },
        })
      ).data.data.at(0),
    enabled: !!eventType,
  });
};

export const useCreateFeatureQuery = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    FeatureQueryCreate
  >({
    mutationFn: async (query: FeatureQueryCreate) => {
      return (
        await featureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost({
          featureQueryCreate: query,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
  });
};

type UseUpdateFeatureQueryProps = {
  onError?: (error: AxiosError<{ detail: string }>) => void;
};

export const useUpdateFeatureQuery = ({
  onError,
}: UseUpdateFeatureQueryProps = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    FeatureQueryPut & { id: string; etag: string }
  >({
    mutationFn: async ({ id, etag, ...query }) => {
      return (
        await featureQueriesApi.updateFeatureQueryHandlerFeatureQueriesIdPut({
          id,
          featureQueryPut: query,
          ifMatch: etag,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
    onError,
  });
};

export const useSetFeatureQueryStatus = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    SetFeatureQueryStatusRequest & { id: string; etag: string },
    { prevFeatureQueries: FeatureQuery[] | undefined }
  >({
    mutationFn: async ({ id, etag, ...query }) => {
      return (
        await featureQueriesApi.setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(
          {
            id,
            setFeatureQueryStatusRequest: query,
            ifMatch: etag,
          },
        )
      ).data;
    },
    onMutate: async ({ id, status, environment }) => {
      const featureQueriesKey = [
        FEATURE_QUERIES_PREFIX_CACHE_KEY,
        workspace.id,
      ];
      // Cancel the query to prevent race condition
      await queryClient.cancelQueries({
        queryKey: featureQueriesKey,
      });

      // Get the previous data
      const prevFeatureQueries =
        queryClient.getQueryData<FeatureQuery[]>(featureQueriesKey);

      // Set the optimistically updated data
      queryClient.setQueryData(
        featureQueriesKey,
        produce(prevFeatureQueries, (draft) => {
          if (draft) {
            const featureQueryToUpdate = draft.find((c) => c.id === id);

            if (featureQueryToUpdate) {
              if (!featureQueryToUpdate.environment_settings) {
                featureQueryToUpdate.environment_settings = {};
              }

              featureQueryToUpdate.environment_settings[environment].status =
                status;
            }
          }
        }),
      );

      // Return the previous data to be used in the onError callback
      return { prevFeatureQueries };
    },
    onError: (error, variables, context) => {
      // Rollback the optimistic update if the mutation fails
      queryClient.setQueryData(
        [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
        context?.prevFeatureQueries,
      );
    },
    onSettled: () => {
      // Invalidate the query to reflect the latest data
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
  });
};

export const useEntityFeatures = (
  environment: Environment,
  entityId: string,
  entityType: string,
) => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = retrieveFeaturesApi(workspace.base_url!);

  return useQuery({
    queryKey: ["features", workspace.id, environment, entityId, entityType],
    queryFn: () =>
      featuresApi.getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet({
        environment:
          environment as GetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetEnvironmentEnum,
        entityType,
        entityId,
      }),
  });
};
