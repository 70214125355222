import { MutationOptions } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";

import {
  UploadModelContextT,
  useParsedModelData,
  useUploadModelFile,
} from "src/api/queries";
import { ParsedModelDataSuccessT, UploadModelDataT } from "src/api/types";

type UseUploadModelProps = {
  baseUrl?: string;
  flowSlug: string;
  onMutate: MutationOptions<
    any,
    any,
    UploadModelDataT,
    UploadModelContextT
  >["onMutate"];
  onError: (context?: UploadModelContextT) => void;
  onSuccess: (data: ParsedModelDataSuccessT) => void;
};

/**
 * Uploads a model file and the peridocally checks the status of the parsing.
 * Check while the parsing status is pending.
 *
 * @param baseUrl - Flow router base url
 * @param flowSlug - Flow slug
 * @param onMutate - Hook that is called right before uploading
 * @param onSuccess - Hook that is called when the parsing is successful
 * @param onError - Hook that is called when the parsing is failed
 * @returns An object {
 *   isLoading, // Parsing status is pending
 *   onFileSelect, // Callback to upload a file
 *   data, // Parsed model data
 *   error // Error object
 * }
 */
export const useUploadModel = ({
  onMutate,
  onSuccess,
  onError,
  baseUrl,
  flowSlug,
}: UseUploadModelProps) => {
  const uploadModel = useUploadModelFile({
    onMutate,
    onError: (_err, _variables, context) => {
      onError(context);
    },
  });

  const parsedModel = useParsedModelData(
    baseUrl,
    flowSlug,
    uploadModel.data?.modelId,
  );

  useEffect(() => {
    if (parsedModel.data?.type === "success") {
      onSuccess?.(parsedModel.data);
    }
    // We dont rely on onSuccess being stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedModel.data]);

  useEffect(() => {
    if (parsedModel.isError) {
      onError(uploadModel.context);
    }
    // We dont rely on onError being stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedModel.isError, uploadModel.context]);

  const isModelUploading =
    uploadModel.isPending ||
    parsedModel.isFetching ||
    (!parsedModel.isError && parsedModel.data?.type === "pending");

  const onFileSelect = useCallback(
    (file: File) => {
      uploadModel.mutate({
        baseUrl: baseUrl!,
        flowSlug: flowSlug!,
        file,
      });
    },
    [baseUrl, flowSlug, uploadModel],
  );

  return {
    isLoading: isModelUploading,
    onFileSelect,
    data: parsedModel.data?.type === "success" ? parsedModel.data : undefined,
    error: uploadModel.error || parsedModel.error,
  };
};
