import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { startOfToday, endOfToday, startOfDay, endOfDay } from "date-fns";
import { useMemo } from "react";
import { SelectRangeEventHandler, DateRange } from "react-day-picker";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { TableComp } from "src/base-components/Table";
import { EmptyState } from "src/design-system/EmptyState";
import { useEntitySchemas } from "src/entities/queries";
import {
  EventsSearchKeys,
  EventsSubHeader,
} from "src/eventsCatalogue/EventsSubHeader";
import {
  useEventSchemas,
  useEventSchema,
  useEvents,
  useEvent,
  DeserializedEvent,
} from "src/eventsCatalogue/queries";
import { getColumns } from "src/eventsCatalogue/tableConfig";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { WhitePane } from "src/jobs/common/WhitePane";
import { PageHeader } from "src/layout/PageHeader";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EventsCatalogueParams, getUrlToEventsPage } from "src/router/urls";
import { useTimeWindow } from "src/utils/timeWindow";
import { useParamsDecode } from "src/utils/useParamsDecode";

const EVENT_SEARCH_PARAM_KEY = "q";

export const EventsCatalogue = () => {
  const [environment] = useEnvironment();
  const context = useWorkspaceContext();
  const {
    event_id: selectedEventId,
    event_type,
    orgId,
    wsId,
  } = useParamsDecode<EventsCatalogueParams>();
  const { workspace } = useWorkspaceContext();
  const { data: eventTypes } = useEventSchemas();
  const { data: schema, isLoading: isLoadingSchema } =
    useEventSchema(event_type);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: entitiesSchemasData } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });
  const currentEvent = eventTypes?.find(
    (event) => event.event_type === event_type,
  );
  const eventDisplayName = (
    currentEvent?.display_name_plural ?? "events"
  ).toLowerCase();

  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      {
        from: startOfToday(),
        to: endOfToday(),
      },
      "events-time-window",
    );

  const onTimeWindowChange: SelectRangeEventHandler = (
    range: DateRange | undefined,
  ) => {
    onDateRangePickerChange(range);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (range?.from && range?.to) {
        newParams.set(
          EventsSearchKeys.From,
          startOfDay(range.from).toISOString(),
        );
        newParams.set(EventsSearchKeys.To, endOfDay(range.to).toISOString());
      } else {
        newParams.delete(EventsSearchKeys.From);
        newParams.delete(EventsSearchKeys.To);
      }

      return newParams;
    });

    const currentPath = window.location.pathname;
    if (currentPath.includes("/event/")) {
      navigate(
        getUrlToEventsPage({
          orgId,
          wsId,
          eventType: event_type,
        }),
      );
    }
  };

  const searchQuery = searchParams.get(EVENT_SEARCH_PARAM_KEY);

  const {
    data: events,
    isLoading: isEventsLoading,
    refetch: refreshEvents,
    isRefetching: isRefetchingEvents,
  } = useEvents({
    eventType: event_type,
    environment,
    timeWindow: {
      from: timeWindow.from.toISOString(),
      to: timeWindow.to.toISOString(),
    },
  });

  const {
    data: event,
    isFetching: isEventFetching,
    isLoading: isEventLoading,
  } = useEvent(
    {
      eventType: event_type,
      eventId: searchQuery ?? "",
      environment,
    },
    {
      retry: (failureCount, error) => error.status !== 404 && failureCount < 3,
    },
  );

  const memoizedColumns = useMemo(() => {
    return schema ? getColumns(schema, entitiesSchemasData) : [];
  }, [schema, entitiesSchemasData]);

  const handleViewClick = (row: DeserializedEvent) => {
    navigate(
      getUrlToEventsPage({
        orgId,
        wsId,
        eventType: event_type,
        eventId:
          selectedEventId === row.payload.id ? undefined : row.payload.id,
      }),
    );
  };

  const mappedEvents = searchQuery?.length && event ? [event] : events || [];
  const onSearchQueryChange = (query: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (query.length) {
        newParams.set(EVENT_SEARCH_PARAM_KEY, query);
      } else {
        newParams.delete(EVENT_SEARCH_PARAM_KEY);
      }
      return newParams;
    });
  };

  const isTableLoading = isEventsLoading || isLoadingSchema || isEventFetching;

  return (
    <div className="flex h-full flex-col">
      <PageHeader title="Events" />
      <EventsSubHeader
        dateRangePickerValue={dateRangePickerValue}
        eventTypes={eventTypes ?? []}
        isRefreshing={isRefetchingEvents}
        searchQuery={searchQuery}
        onRefresh={refreshEvents}
        onSearchQueryChange={onSearchQueryChange}
        onTimeWindowChange={onTimeWindowChange}
      />
      <div className="flex min-h-0 flex-1">
        <FloatingWindowsProvider>
          <div className="flex w-full shrink flex-col overflow-x-auto p-5">
            <WhitePane noMinWidth>
              <div className="flex h-full w-full flex-col">
                {(!isEventsLoading && mappedEvents?.length === 0) ||
                (searchQuery?.length && !isEventLoading && !event) ? (
                  <EmptyState
                    dataLoc="events-empty-state"
                    description="Try adjusting the search or filter to find what you are looking for"
                    headline="No events found"
                    icon={faSearch}
                  />
                ) : (
                  <div className="relative flex h-full w-full flex-col p-4">
                    <TableComp
                      columns={memoizedColumns}
                      data={mappedEvents}
                      dataLoc="events-table"
                      frameClassName="w-full"
                      isLoading={isTableLoading}
                      rowPropsGetter={(row) => ({
                        onClick: () => handleViewClick(row.original),
                        classNameOverrides: twJoin(
                          row.original.payload.id === selectedEventId
                            ? "selected bg-gray-50"
                            : "hover:bg-gray-50",
                          "cursor-pointer",
                        ),
                      })}
                      uiStoreKey={getTableStoreKey(event_type)}
                      variant="compact"
                    />
                    {!isTableLoading &&
                      !searchQuery?.length &&
                      mappedEvents?.length === 200 && (
                        <div className="pt-3 text-gray-500 font-inter-medium-11px">
                          Listing the last{" "}
                          <span className="text-gray-800">200</span>{" "}
                          {eventDisplayName}. Older {eventDisplayName} are not
                          shown on this page.
                        </div>
                      )}
                  </div>
                )}
              </div>
            </WhitePane>
          </div>
          <Outlet context={context} />
        </FloatingWindowsProvider>
      </div>
    </div>
  );
};

export const getTableStoreKey = (eventType: string) => `events-${eventType}`;
