import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { DropDown } from "src/base-components/DropDown";
import { EnvironmentDropdownButton } from "src/baseConnectionNode/EnvironmentDropdownButton";
import { ConnectionDataSources } from "src/baseConnectionNode/types";
import { Tooltip } from "src/design-system/Tooltip";

type EnvironmentSelectPropsT<T> = {
  title: string;
  description: React.ReactNode;
  options: Array<{ key: string; value: T }>;
  disabled: boolean;
  defaultOption?: string;
  tooltipTitle?: string;
  environment: ConnectionDataSources;
  docsLink: string;
  dataLocPrefix: string;
  getOptionLabel: (value: T) => string | undefined;
};

export const EnvironmentSelect = <T extends string>({
  title,
  description,
  options,
  defaultOption,
  environment,
  disabled,
  tooltipTitle,
  docsLink,
  dataLocPrefix,
  getOptionLabel,
}: EnvironmentSelectPropsT<T>) => {
  const { control, watch, setValue } = useFormContext();
  const fieldName = `config.environments_config.${environment}` as const;

  const selectedEnvironmentDataSource = watch(fieldName);

  useEffect(() => {
    const optionStillAvailable = options.find(
      (option) => option.key === selectedEnvironmentDataSource,
    );

    if (!optionStillAvailable) {
      setValue(fieldName, defaultOption);
    }
  }, [
    options,
    selectedEnvironmentDataSource,
    setValue,
    fieldName,
    defaultOption,
  ]);

  return (
    <div className="mb-4 flex h-full w-full flex-row last:mb-0">
      <div className="flex h-full grow flex-col">
        <h3 className="text-gray-800 font-inter-medium-12px">{title}</h3>
        <span className="text-gray-500 font-inter-normal-12px">
          {description}
        </span>
      </div>
      <div className="max-w-50 grow">
        <Controller
          control={control}
          name={fieldName}
          render={({ field }) => (
            <Tooltip
              disabled={!(disabled && Boolean(tooltipTitle))}
              footerAction={{
                text: "Read more",
                onClick: () => window.open(docsLink, "_blank"),
              }}
              placement="top-end"
              title={tooltipTitle}
              asChild
            >
              <div className="w-full">
                <DropDown<T>
                  dataLoc={`${dataLocPrefix}-${fieldName}`}
                  disabled={disabled}
                  elements={options}
                  itemsClassNames="w-full"
                  renderButtonValue={(value) =>
                    value && (
                      <EnvironmentDropdownButton
                        value={getOptionLabel(value)}
                      />
                    )
                  }
                  renderValue={({ key, value }) => (
                    <div
                      className={twJoin(
                        "flex items-center px-5 py-4",
                        value === "mock_data" && "border-b",
                      )}
                      data-loc={`dropdown-item-${key}`}
                    >
                      <span className="text inline-block">
                        {getOptionLabel(value)}
                      </span>
                    </div>
                  )}
                  selectedKey={field.value || defaultOption}
                  onSelect={(selected) => field.onChange(selected)}
                />
              </div>
            </Tooltip>
          )}
        />
      </div>
    </div>
  );
};
