import React from "react";

import { ConnectionT } from "src/api/connectApi/types";
import { Pill } from "src/base-components/Pill";
import { EnvironmentSelect } from "src/baseConnectionNode/EnvironmentSelect";
import {
  ConnectionDataSources,
  ConnectionDataSourcesValues,
} from "src/baseConnectionNode/types";
import {
  useEnvironmentAvailableConfigs,
  PRODUCTION_OPTION,
} from "src/baseConnectionNode/useEnvironmentAvailableConfigs";

type EnvironmentConfigPropsT = {
  connection: ConnectionT;
  disabled: boolean;
  type?: "webhook" | "default";
};

const LIVE_CONNECTION_LABEL = "Live Connection";

const CC_DOCS_LINK =
  "https://docs.taktile.com/third-party-connections/custom-connection-node/custom-connection#environment-specific-credentials";

const OPTIONS_LABEL_MAP: Record<ConnectionDataSourcesValues, string> = {
  [ConnectionDataSourcesValues.mock_data]: "Mock data",
  [ConnectionDataSourcesValues.empty_response]: "Empty response",
  [ConnectionDataSourcesValues.sandbox]: "Sandbox credentials",
  [ConnectionDataSourcesValues.production]: "Production credentials",
};

const getEnvironmentOptionLabel =
  (isSandboxEnabled: boolean) =>
  (value: ConnectionDataSourcesValues | undefined) => {
    // When sandbox credentials are not enabled,
    // we want to display Live Testing instead of Production Credentials
    if (!value) return undefined;

    if (!isSandboxEnabled && value === ConnectionDataSourcesValues.production) {
      return LIVE_CONNECTION_LABEL;
    }

    return OPTIONS_LABEL_MAP[value];
  };

export const EnvironmentDataSourceConfig: React.FC<EnvironmentConfigPropsT> = ({
  connection,
  disabled,
  type = "default",
}) => {
  const { enable_non_prod_configs, non_prod_env_configs } = connection;
  const allowSandboxCredentials =
    enable_non_prod_configs && Boolean(non_prod_env_configs?.["sandbox"]);

  const { testRunSettings, sandboxSettings } = useEnvironmentAvailableConfigs(
    connection,
    type,
  );

  return (
    <div className="flex flex-col flex-wrap">
      <EnvironmentSelect
        dataLocPrefix="cc-node" // TODO: fix
        description="Test runs using the button in the UI"
        environment={ConnectionDataSources.authoring_mode_data_source}
        title="For test runs, use"
        {...testRunSettings}
        disabled={disabled || testRunSettings.disabled}
        docsLink={CC_DOCS_LINK}
        getOptionLabel={getEnvironmentOptionLabel(allowSandboxCredentials)}
      />
      <EnvironmentSelect
        dataLocPrefix="cc-node" // TODO: fix
        description={
          <>
            API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/sandbox/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        environment={ConnectionDataSources.sandbox_mode_data_source}
        title="For sandbox API calls, use"
        {...sandboxSettings}
        disabled={disabled || sandboxSettings.disabled}
        docsLink={CC_DOCS_LINK}
        getOptionLabel={getEnvironmentOptionLabel(allowSandboxCredentials)}
      />
      {/* The live setting remains the same no matter what */}
      <EnvironmentSelect
        dataLocPrefix="cc-node" // TODO: fix
        defaultOption={PRODUCTION_OPTION["key"]}
        description={
          <>
            Production API calls using the{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>/decide</Pill.Text>
            </Pill>{" "}
            endpoint
          </>
        }
        docsLink={CC_DOCS_LINK}
        environment={ConnectionDataSources.live}
        getOptionLabel={getEnvironmentOptionLabel(allowSandboxCredentials)}
        options={[PRODUCTION_OPTION]}
        title="For live API calls, use"
        tooltipTitle="For live API calls to the /decide endpoint, Taktile always uses production credentials for external calls. If caching is enabled, Taktile will use cached responses for this node."
        disabled
      />
    </div>
  );
};
