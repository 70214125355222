import { useSortable } from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { ReorderHandle } from "src/base-components/ReorderHandle";
import { Select } from "src/base-components/Select";
import { ManualReviewFormT } from "src/manualReviewNode/ManualReviewNodeEditor";
import { RowAdvancedOptions } from "src/manualReviewNode/RowAdvancedOptions";
import { type SchemaEnumErrorStatus } from "src/schema/SchemaEnumRow";
import { SchemaEnumRows } from "src/schema/SchemaEnumRows";

type SchemaRowProps = {
  id: string;
  immutable: boolean;
  handleRemove: () => void;
  index: number;
};

export const SchemaRow: React.FC<SchemaRowProps> = ({
  id,
  immutable,
  handleRemove,
  index,
}) => {
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id,
    });
  const style = {
    transform: CSS.Transform.toString(
      transform ? ({ ...transform, scaleY: 1 } as Transform) : null,
    ),
    transition,
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ManualReviewFormT>();

  const propertyEnum = useWatch({
    control,
    name: `response_form.fields.${index}.enum`,
  });

  return (
    <li
      ref={setNodeRef}
      className="w-full"
      data-loc={`manual-review-response-schema-${index}`}
      style={style}
    >
      <div className="flex w-full items-center gap-x-2">
        <ReorderHandle immutable={immutable} listeners={listeners} />
        <div className="flex min-w-0 flex-1 items-center gap-x-2">
          <div className="w-4/12 min-w-0">
            <Input
              placeholder="Name the field"
              fullWidth
              {...register(`response_form.fields.${index}.name`)}
              data-loc="manual-review-field-name"
              disabled={immutable}
              errored={!!errors?.response_form?.fields?.[index]?.name}
              onFocus={(e) =>
                e.target.scrollIntoView({
                  behavior: "smooth",
                  inline: "nearest",
                  block: "nearest",
                })
              }
            />
          </div>
          <div className="w-5/12 min-w-0 flex-1">
            <Controller
              control={control}
              name={`response_form.fields.${index}.key`}
              render={({ field }) => (
                <AutocompleteCodeInput
                  dataLoc="manual-review-field-location"
                  disabled={immutable}
                  error={errors?.response_form?.fields?.[index]?.key?.message}
                  placeholder="field"
                  prefix="data."
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="w-3/12">
            <Controller
              control={control}
              name={`response_form.fields.${index}.type`}
              render={(props) => (
                <Select
                  dataLoc="manual-review-field-type"
                  disabled={immutable}
                  dropdownClassName={LOCK_NODE_EDITOR_CLASSNAME}
                  options={SCHEMA_TYPES}
                  value={props.field.value}
                  onChange={(value) => {
                    if (value === "enum" && props.field.value !== "enum") {
                      setValue(`response_form.fields.${index}.enum`, [
                        { value: '"value_1"' },
                      ]);
                    }
                    if (value !== "enum" && props.field.value === "enum") {
                      setValue(`response_form.fields.${index}.enum`, undefined);
                    }
                    if (value !== "string") {
                      // Unset display_as if type is not string
                      setValue(
                        `response_form.fields.${index}.display_as`,
                        undefined,
                      );
                    }
                    props.field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <RowAdvancedOptions
          control={control}
          disabled={immutable}
          name={`response_form.fields.${index}`}
        />
        <Icon
          color="text-gray-400 hover:text-gray-700"
          icon={faTrashCan}
          size="sm"
          onClick={!immutable ? handleRemove : undefined}
        />
      </div>
      {Array.isArray(propertyEnum) && !isDragging && (
        <SchemaEnumRows
          enumFormLocation={`response_form.fields.${index}.enum`}
          errorStatus={
            errors?.response_form?.fields?.[index]
              ?.enum as SchemaEnumErrorStatus
          }
          immutable={immutable}
          withValidation
        />
      )}
    </li>
  );
};

const SCHEMA_TYPES = [
  { key: "boolean", value: "Boolean" },
  { key: "enum", value: "Enum" },
  { key: "integer", value: "Integer" },
  { key: "number", value: "Number" },
  { key: "string", value: "String" },
];
