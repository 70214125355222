import React, { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  ProviderResourceT,
  ManifestIntegrationProviderResourceT,
} from "src/api/connectApi/types";
import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { Input } from "src/base-components/Input";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { Switch } from "src/base-components/Switch";
import { TimeoutInput } from "src/baseConnectionNode/TimeoutInput";
import { DataRetentionUnit } from "src/clients/flow-api";
import { DataRetention } from "src/connections/types";
import { DOCS_RETENTION_POLICIES } from "src/constants/ExternalLinks";
import { Callout } from "src/design-system/Callout";
import { Tooltip } from "src/design-system/Tooltip";
import { SubFormBaseProps } from "src/integrationNode/IntegrationNodeEditor";
import { ConfigT } from "src/integrationNode/types";
import { DataRetentionUnitDropdown } from "src/layout/WorkspaceFormModal/DataRetentionUnitDropdown";
import {
  convertPeriodValueToSeconds,
  convertSecondsToRetentionPeriodValue,
  retentionPeriodToMinutes,
} from "src/layout/WorkspaceFormModal/utils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type CachingDisabledProviderResourceT = ProviderResourceT & {
  cacheDisableMessage: string;
};

const CACHE_DISABLED_DUE_TO_LARGE_RESPONSE =
  "Caching is not supported for resources with large API responses";
const CACHE_DISABLED_DUE_TO_PII_IN_RESPONSE =
  "Caching is not supported for resources with large amounts of PII";
const CACHE_DISABLED_DUE_TO_DISABLED_FOR_CONNECTION =
  "This Connection does not have caching enabled. Contact your workspace admin to enable caching for this Connection.";
const CACHE_DISABLED_FOR_RESOURCE =
  "Caching is not supported for this resource";

const PResourcesWithCachingDisabled: CachingDisabledProviderResourceT[] = [
  {
    provider: "plaid",
    resource: "asset_report",
    cacheDisableMessage: CACHE_DISABLED_DUE_TO_LARGE_RESPONSE,
  },
  {
    provider: "plaid",
    resource: "plaid_cra_base_report",
    cacheDisableMessage: CACHE_DISABLED_DUE_TO_PII_IN_RESPONSE,
  },
  {
    provider: "plaid",
    resource: "plaid_cra_income_insights_report",
    cacheDisableMessage: CACHE_DISABLED_DUE_TO_PII_IN_RESPONSE,
  },
  {
    provider: "thomson_reuters",
    resource: "thomson_reuters_person_report",
    cacheDisableMessage: CACHE_DISABLED_DUE_TO_LARGE_RESPONSE,
  },
];

const hasCachingDisabled = (providerResource: ProviderResourceT): boolean => {
  return PResourcesWithCachingDisabled.some(
    (pResource) =>
      pResource.provider === providerResource.provider &&
      pResource.resource === providerResource.resource,
  );
};

const getCacheDisableMessage = (
  providerResource: ProviderResourceT,
): string => {
  const resource = PResourcesWithCachingDisabled.find(
    (pResource) =>
      pResource.provider === providerResource.provider &&
      pResource.resource === providerResource.resource,
  );
  return resource
    ? resource.cacheDisableMessage
    : CACHE_DISABLED_DUE_TO_LARGE_RESPONSE;
};

type ConfigPanePropsT = {
  config: ConfigT;
  updateConfig: (updatedConfig: ConfigT) => void;
  providerResource: ProviderResourceT;
  showTenantIDField: boolean;
  hasRawProviderRequestEnabled?: boolean;
  runFieldErrors?: FieldErrorsT;
  dataRetention?: DataRetention;
  manifest?: ManifestIntegrationProvider;
} & SubFormBaseProps;

export const ConfigPane: React.FC<ConfigPanePropsT> = ({
  config,
  updateConfig,
  providerResource,
  immutable,
  isReactive,
  showTenantIDField,
  runFieldErrors,
  dataRetention,
  hasRawProviderRequestEnabled,
  manifest,
}) => {
  const cachingIsDisabledOnConnection = dataRetention?.value === 0;
  const cachingIsDisabledOnManifest =
    manifest &&
    (providerResource as ManifestIntegrationProviderResourceT).manifest_version
      ? !manifest.resources[providerResource.resource].allow_caching
      : false;
  const isCachingDisabled =
    hasCachingDisabled(providerResource) ||
    cachingIsDisabledOnConnection ||
    cachingIsDisabledOnManifest;

  const formProps = useForm({
    defaultValues: config,
    ...(isReactive && { values: config }),
  });

  useSubmitForm({
    onChange: (data: ConfigT) => {
      updateConfig(data);
    },
    disabled: isReactive,
    previousValues: config,
    watch: formProps.watch,
  });

  const tenantRunFieldError =
    runFieldErrors?.[formProps.getValues("tenant_id.id")];

  const isTimeoutActive = formProps.getValues("timeout.active");

  const timePeriodValidation = useCallback(
    (maxAgeSeconds: string) => {
      const connectionRetentionPeriod = retentionPeriodToMinutes(
        dataRetention!,
      );
      const connectRetentionPeriod = parseInt(maxAgeSeconds) / 60;
      if (connectRetentionPeriod > connectionRetentionPeriod) {
        return "Larger time period than specified in the Connection retention policy";
      }

      return true;
    },
    [dataRetention],
  );

  return (
    <FormProvider {...formProps}>
      {showTenantIDField && (
        <Card className="mb-2">
          <div className="flex w-full flex-row items-center">
            <div className="w-[168px] text-gray-700 font-inter-normal-12px">
              Tenant ID*
            </div>

            <Tooltip
              body={tenantRunFieldError}
              disabled={!tenantRunFieldError}
              placement="top"
              title="Invalid input"
              asChild
            >
              <div className="flex-grow">
                <MonospacedInput
                  data-loc="integration-node-tenant-id"
                  disabled={immutable}
                  errored={tenantRunFieldError !== undefined}
                  formProps={formProps.register("tenant_id.expression")}
                  placeholder="data.field"
                  codeColors
                  errorIconOnError
                />
              </div>
            </Tooltip>
          </div>
        </Card>
      )}
      <div className="mb-2.5 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            {manifest !== undefined
              ? "Make use of cached responses"
              : "Make use of cached responses when published"}
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Use stored reports when available for faster, cost-free data
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="caching.active"
          render={(props) =>
            isCachingDisabled ? (
              <span className="ml-1 mt-1 inline-block">
                <Tooltip
                  body={
                    cachingIsDisabledOnManifest
                      ? CACHE_DISABLED_FOR_RESOURCE
                      : cachingIsDisabledOnConnection
                        ? CACHE_DISABLED_DUE_TO_DISABLED_FOR_CONNECTION
                        : getCacheDisableMessage(providerResource)
                  }
                  placement="top"
                  asChild
                >
                  <div>
                    <Switch
                      dataLoc="integration-node-caching-active"
                      disabled={true}
                      enabled={false}
                      onChange={() => {}}
                    />
                  </div>
                </Tooltip>
              </span>
            ) : (
              <Switch
                dataLoc="integration-node-caching-active"
                disabled={immutable || cachingIsDisabledOnConnection}
                enabled={props.field.value}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            )
          }
        />
      </div>
      {formProps.getValues("caching.active") && (
        <>
          <div className="mb-2 w-full items-center rounded-lg bg-gray-50 px-5 py-4">
            <div className="flex items-center gap-x-2">
              <div className="mr-2 flex flex-1 text-gray-700 font-inter-normal-12px">
                Use cached responses from the past
              </div>
              <div className="max-w-32 flex-1">
                <Controller
                  control={formProps.control}
                  name="caching.max_age_seconds"
                  render={({ fieldState, field: { value, onChange } }) => {
                    const unit = formProps.getValues("caching.unit") ?? "days";

                    return (
                      <Input
                        data-loc="integration-node-caching-max-age-seconds"
                        disabled={immutable}
                        errored={Boolean(fieldState.error)}
                        type="number"
                        value={convertSecondsToRetentionPeriodValue(
                          parseInt(value),
                          unit,
                        )}
                        fullWidth
                        onChange={(e) => {
                          onChange(
                            String(
                              convertPeriodValueToSeconds(
                                parseInt(e.target.value),
                                unit,
                              ),
                            ),
                          );
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: true,
                    min: 0,
                    validate: {
                      timePeriod: timePeriodValidation,
                    },
                  }}
                />
              </div>
              <div className="w-1/4 shrink-0">
                <Controller
                  control={formProps.control}
                  name="caching.unit"
                  render={({ field: { value, onChange } }) => {
                    const handleChange = (unit: string) => {
                      // As we persist value in seconds, we need to convert it to the new unit
                      const maxAgeSeconds = formProps.getValues(
                        "caching.max_age_seconds",
                      );
                      const currentPeriodValue =
                        convertSecondsToRetentionPeriodValue(
                          parseInt(maxAgeSeconds),
                          value ?? "days",
                        );
                      const newMaxAgeSeconds = convertPeriodValueToSeconds(
                        currentPeriodValue,
                        unit as DataRetentionUnit,
                      );

                      onChange(unit);
                      formProps.setValue(
                        "caching.max_age_seconds",
                        String(newMaxAgeSeconds),
                      );
                    };
                    return (
                      <DataRetentionUnitDropdown
                        dataLoc="integration-node-caching-max-age-unit"
                        disabled={immutable}
                        value={value ?? "days"}
                        onChange={handleChange}
                      />
                    );
                  }}
                  rules={{
                    deps: ["caching.max_age_seconds"],
                  }}
                />
              </div>
            </div>
          </div>

          {formProps.formState.errors?.caching?.max_age_seconds?.type ===
            "timePeriod" && (
            <div className="mt-3">
              <Callout
                action={{
                  text: "Read more",
                  onClick: () => window.open(DOCS_RETENTION_POLICIES, "_blank"),
                }}
                type="warning"
              >
                <div>
                  You've selected a larger time period than specified in the
                  Connection caching retention policy (
                  <span className="font-inter-medium-12px">
                    {dataRetention?.value} {dataRetention?.unit}
                  </span>
                  ). Contact your workspace admin if you'd like to change the
                  caching policy.
                </div>
              </Callout>
            </div>
          )}
        </>
      )}
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Use custom request timeout
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Enter the maximum number of seconds before timing out the request to
            the provider
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="timeout.active"
          render={(props) => (
            <Switch
              dataLoc="timeout.active"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      {isTimeoutActive && (
        <div className="mb-2 flex w-full flex-row items-center justify-between rounded-lg bg-gray-50 px-5 py-4">
          <div className="mr-2 flex text-gray-700 font-inter-normal-12px">
            Time out after (seconds)
          </div>
          <TimeoutInput
            dataLoc="timeout.timeout_seconds"
            formProps={formProps.register("timeout.timeout_seconds")}
            immutable={immutable}
          />
        </div>
      )}
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with 4xx responses
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of 4xx responses, the flow will continue executing
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="error.allow_4xx"
          render={(props) => (
            <Switch
              dataLoc="integration-node-allow-4xx"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Do not error with 5xx responses
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            In case of 5xx responses, the flow will continue executing
          </span>
        </div>
        <Controller
          control={formProps.control}
          name="error.allow_5xx"
          render={(props) => (
            <Switch
              dataLoc="integration-node-allow-5xx"
              disabled={immutable}
              enabled={props.field.value}
              onChange={() => {
                props.field.onChange(!props.field.value);
              }}
            />
          )}
        />
      </div>
      {!(providerResource as ManifestIntegrationProviderResourceT)
        .manifest_version && (
        <div className="mt-6 flex items-center justify-between">
          <div>
            <h3 className="text-gray-800 font-inter-medium-12px">
              Include raw request to provider
            </h3>
            <span className="text-gray-500 font-inter-normal-12px">
              Include headers, parameters, and body of the request to the
              provider in the Taktile API response
            </span>
          </div>
          <Controller
            control={formProps.control}
            name="raw_requests.has_raw_requests_enabled_in_node"
            render={(props) => {
              return !hasRawProviderRequestEnabled ? (
                <Tooltip
                  placement="top"
                  title="This Connection does not have raw requests enabled. Contact your workspace admin to enable raw requests for this Connection."
                >
                  <Switch
                    dataLoc="integration-node-raw-requests-enabled"
                    disabled={true}
                    enabled={false}
                    onChange={() => {}}
                  />
                </Tooltip>
              ) : (
                <Switch
                  dataLoc="integration-node-raw-requests-enabled"
                  disabled={immutable || !hasRawProviderRequestEnabled}
                  enabled={props.field.value}
                  onChange={() => {
                    props.field.onChange(!props.field.value);
                  }}
                />
              );
            }}
          />
        </div>
      )}
    </FormProvider>
  );
};
