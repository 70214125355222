import { capitalize } from "lodash";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import {
  SidepaneProvider,
  useSidepaneContext,
} from "src/entities/entityView/DecisionsSidePane/SidePaneContext";
import { EntityDetailsSidePane } from "src/entities/entityView/EntityDetailsSidePane";
import { EntityTabs } from "src/entities/entityView/EntityTabs";
import { EntityViewSidePane } from "src/entities/entityView/EntityViewSidePane";
import { EntityTabIds } from "src/entities/entityView/useEntityTabRefresh";
import { findSchema } from "src/entities/entityView/utils";
import {
  useEntity,
  useEntityNavigation,
  useEntitySchemas,
} from "src/entities/queries";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { PageHeader } from "src/layout/PageHeader";
import { ErrorPage } from "src/router/ErrorPage";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  EntityViewParams,
  getUrlToEntityDetailPage,
  getUrlToSchemaEntitiesPage,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type EntityViewProps = {
  tab: EntityTabIds;
};

export const EntityViewWrapper = ({ tab }: EntityViewProps) => {
  return (
    <SidepaneProvider>
      <EntityView tab={tab} />
    </SidepaneProvider>
  );
};

export const EntityView = ({ tab }: EntityViewProps) => {
  const [selectedDecision, setSelectedDecision] =
    useState<ExtendedHistoricalDecisionV3 | null>(null);
  const {
    schema: schemaId,
    id: entityId,
    orgId,
    wsId,
  } = useParamsDecode<EntityViewParams>();
  const { workspace } = useWorkspaceContext();
  const baseUrl = workspace.base_url ?? "";
  const location = useLocation();
  const [env] = useEnvironment();

  const { error, isSuccess: isEntityLoadSuccess } = useEntity({
    schema: schemaId,
    env,
    baseUrl: workspace.base_url ?? "",
    entityId,
  });

  const { data, isLoading: entitiesLoading } = useEntityNavigation({
    baseUrl,
    schema: schemaId,
    env,
    filters: [`_id:${entityId}`],
    options: { enabled: isEntityLoadSuccess },
  });

  const { data: schemasData, isSuccess: isSchemasLoadSuccess } =
    useEntitySchemas({
      baseUrl,
      options: { enabled: !!baseUrl && isEntityLoadSuccess },
    });

  const { data: prevPageData } = useEntityNavigation({
    baseUrl,
    schema: schemaId,
    env,
    pageToken: data?.previous_page_token,
    options: { enabled: isEntityLoadSuccess && !!data?.previous_page_token },
  });

  const { data: nextPageData } = useEntityNavigation({
    baseUrl,
    schema: schemaId,
    env,
    pageToken: data?.next_page_token,
    options: { enabled: isEntityLoadSuccess && !!data?.next_page_token },
  });

  const entity = data?.entities[0];
  const prevEntity = prevPageData?.entities[0];
  const nextEntity = nextPageData?.entities[0];

  const schema = findSchema(schemaId, schemasData);

  const buildBreadcrumbUrl = (schema: string) =>
    getUrlToSchemaEntitiesPage(orgId, wsId, schema);

  const { width, isResizing } = useSidepaneContext();

  const getEntityUrl = (entityId: string) =>
    getUrlToEntityDetailPage(orgId, wsId, schemaId, entityId);

  const breadcrumbs = [
    {
      key: "entity-schema",
      breadcrumb: (
        <PageHeader.Breadcrumb
          dataLoc="entity-schema"
          href={buildBreadcrumbUrl(schemaId)}
          label={capitalize(schema?._display_name_plural ?? "")}
          hideIcon
        />
      ),
    },
    {
      key: "entity-name",
      breadcrumb: entitiesLoading ? (
        <SkeletonPlaceholder height="h-4" width="w-24" />
      ) : (
        <PageHeader.Breadcrumb
          dataLoc="entity-name"
          href={`${location.pathname}${location.search}`}
          label={
            (entity &&
              schema &&
              entity?.properties[schema._primary_property]?.toString()) ||
            ""
          }
          active
        />
      ),
    },
  ];

  const navigation = {
    previous: {
      url: prevEntity?._id ? getEntityUrl(prevEntity._id) : "",
      disabled: !prevEntity?._id,
    },
    next: {
      url: nextEntity?._id ? getEntityUrl(nextEntity._id) : "",
      disabled: !nextEntity?._id,
    },
  };

  const schemaNotFound = !schema && isSchemasLoadSuccess;
  const entityNotFound = error?.status === 404;
  if (schemaNotFound || entityNotFound) {
    return <ErrorPage message="404: Page not found" />;
  }

  return (
    <FloatingWindowsProvider>
      <div
        className={twMerge(
          "flex h-full flex-col",
          isResizing && "cursor-col-resize",
        )}
      >
        <PageHeader
          breadcrumbs={breadcrumbs}
          navigation={entitiesLoading ? undefined : navigation}
        />
        <div className="flex min-h-0 flex-1">
          <div className="h-full w-90 shrink-0 border-l border-gray-200 bg-white p-5">
            <EntityDetailsSidePane
              entityId={entityId}
              schemaId={schemaId}
              isWindowPill
            />
          </div>
          <EntityTabs
            selectedDecision={selectedDecision}
            tab={tab}
            onSelectDecision={setSelectedDecision}
          />
          {Boolean(selectedDecision) && (
            // placeholder for the sidepane, assigning width to sync with the resizable sidepane
            <div className="flex-shrink-0 p-5" style={{ width }} />
          )}
          <EntityViewSidePane
            selectedDecision={selectedDecision}
            setSelectedDecision={setSelectedDecision}
          />
        </div>
      </div>
    </FloatingWindowsProvider>
  );
};
