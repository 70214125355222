import { useCallback, useState } from "react";

import { CommentDb } from "src/clients/flow-api";
import { useUpdateComment } from "src/comments/queries";

export const useCommentActions = (
  comment: CommentDb | undefined,
  handleDelete: (comment: CommentDb) => void,
) => {
  const [isEditing, setEditing] = useState(false);
  const { mutate: updateComment, isPending: isUpdating } = useUpdateComment(
    comment?.id ?? "",
  );

  const onResolve = useCallback(
    () => updateComment({ resolved: true }),
    [updateComment],
  );
  const onUnresolve = useCallback(
    () => updateComment({ resolved: false }),
    [updateComment],
  );
  const onEdit = useCallback(() => setEditing(true), []);
  const onCancelEdit = useCallback(() => setEditing(false), []);
  const onSave = useCallback(
    (content: string) => {
      updateComment({ content });
      setEditing(false);
    },
    [updateComment, setEditing],
  );

  const onDelete = useCallback(
    () => comment && handleDelete(comment),
    [handleDelete, comment],
  );

  return {
    isEditing,
    isUpdating,
    disabled: isUpdating,
    onResolve,
    onUnresolve,
    onEdit,
    onSave,
    onCancelEdit,
    onDelete,
  };
};
