import {
  faEdit,
  faFlask,
  faWavePulse,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "usehooks-ts";

import { useWorkspace } from "src/api/queries";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { EntitiesTable } from "src/entities/EntitiesTable";
import { getSchemaIcon } from "src/entities/entityView/utils";
import { useEntitySchemas } from "src/entities/queries";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { PageHeader } from "src/layout/PageHeader";
import {
  SubHeader,
  SubHeaderSearchBox,
  SubHeaderTab,
  SubHeaderTabs,
} from "src/layout/SubHeader";
import { ErrorPage } from "src/router/ErrorPage";
import { EntitiesParams, getUrlToSchemaEntitiesPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const getBeginsWithFilter = (value: string) => (value ? [`${value}*`] : []);

const LoadingTabs = () => (
  <div className="flex h-full items-center gap-2">
    {Array(7)
      .fill(null)
      .map((_, index) => (
        <SkeletonPlaceholder key={index} height="h-2/3" width="w-15" />
      ))}
  </div>
);

export const Entities = () => {
  const [environment, setEnvironment] = useEnvironment();
  const { wsId, orgId, schema } = useParamsDecode<EntitiesParams>();
  const workspace = useWorkspace(wsId);
  const baseUrl = workspace.data?.base_url ?? "";
  const { value: editingSchema, toggle: toggleEditSchema } = useBoolean(false);
  const { data, isLoading } = useEntitySchemas({ baseUrl });
  const [idFilter, setIdFilter] = useState({});
  const navigate = useNavigate();

  const dropdownItems: OptionsDropdownElement[] = [
    {
      key: "Edit Schema",
      icon: faEdit,
      action: toggleEditSchema,
    },
    MENU_DIVIDER,
    {
      key: `Switch to ${environment === "sandbox" ? "Live" : "Sandbox"}`,
      icon: environment === "sandbox" ? faWavePulse : faFlask,
      action: () =>
        setEnvironment(environment === "sandbox" ? "live" : "sandbox"),
    },
  ] as const;

  const foundSchema = data?.entities.find((entity) => entity._id === schema);

  useEffect(() => {
    if (!schema && data?.entities.length) {
      navigate(getUrlToSchemaEntitiesPage(orgId, wsId, data.entities[0]._id), {
        replace: true,
      });
    }
  }, [data?.entities, schema, orgId, wsId, navigate]);

  if (schema && !foundSchema && !isLoading) {
    return <ErrorPage message="404: Page not found" />;
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader title="Entities" />
      <SubHeader
        leftSlot={
          <SubHeaderTabs dataLoc="entities-tabs">
            {isLoading ? (
              <LoadingTabs />
            ) : (
              data?.entities.map((schema) => (
                <SubHeaderTab
                  key={schema._id}
                  icon={getSchemaIcon(schema)}
                  to={getUrlToSchemaEntitiesPage(orgId, wsId, schema._id)}
                >
                  <p className="pr-1 capitalize">
                    {schema._display_name_plural}
                  </p>
                </SubHeaderTab>
              ))
            )}
          </SubHeaderTabs>
        }
      >
        <SubHeaderSearchBox
          dataLoc="entities-search-box"
          placeholder="Search by ID"
          onChange={(value) => setIdFilter({ _id: getBeginsWithFilter(value) })}
        />
        <span className="border-l border-gray-200 p-3.5">
          <EllipsisOptionsDropdown
            elements={dropdownItems}
            iconSize="sm"
            placement="bottom-start"
            renderMenuInPlace
          />
        </span>
      </SubHeader>
      {foundSchema && (
        <EntitiesTable
          baseUrl={baseUrl}
          entitySchema={foundSchema}
          idFilter={idFilter}
          isEditing={editingSchema}
          schemasLoading={isLoading}
          onSidebarClose={toggleEditSchema}
        />
      )}
    </div>
  );
};
