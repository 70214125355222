import { isObject, times } from "lodash";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { JSONValueViewer } from "src/dataTable/DetailedView/JSONValueViewer";
import { Tooltip } from "src/design-system/Tooltip";
import { formatDate, isISO8601 } from "src/utils/datetime";
import { speakPythonPrimitive } from "src/utils/speakPython";

const ROW_CLASS_NAME = "flex max-w-full items-start gap-x-2 text-gray-800";
const LEFT_COLUMN_CLASS_NAME = "w-2/5 shrink-0 truncate p-1.5";
const RIGHT_COLUMN_CLASS_NAME =
  "flex min-w-0 flex-1 p-1.5 font-inter-normal-12px";

export type DataListRow = [string, ReactNode];

type DataListProps =
  | {
      rows: DataListRow[];
      dividers?: boolean;
      borders?: boolean;
      isLoading?: false;
      loadingRows?: number;
      keyEmphasized?: boolean;
    }
  | {
      rows?: never;
      dividers?: boolean;
      borders?: boolean;
      isLoading: true;
      loadingRows?: number;
      keyEmphasized?: boolean;
    };

export const DataList: React.FC<DataListProps> = (props) => (
  <div
    className={twJoin(
      "h-full min-h-0 flex-1 bg-white",
      props.dividers && "divide-y divide-gray-100",
      props.borders && "border border-gray-100",
    )}
  >
    {props.isLoading
      ? times(props.loadingRows ?? 10, (index) => (
          <LoadingRow key={index} dividers={props.dividers} />
        ))
      : props.rows.map(([key, value]) => (
          <div
            key={key}
            className={twJoin(
              ROW_CLASS_NAME,
              props.borders && "divide-x divide-gray-100",
            )}
          >
            <div
              className={twJoin(
                LEFT_COLUMN_CLASS_NAME,
                props.keyEmphasized
                  ? "font-inter-medium-12px"
                  : "font-inter-normal-12px",
              )}
            >
              <Tooltip
                delay={500}
                disabled={typeof key !== "string"}
                headerAction={
                  <CopyTextIcon
                    color="text-white hover:text-gray-300"
                    feedback="inline-only-checkmark"
                    value={key}
                  />
                }
                placement="top"
                title={key}
                asChild
              >
                <span className="block w-full truncate">{key}</span>
              </Tooltip>
            </div>
            <div className={RIGHT_COLUMN_CLASS_NAME}>{value}</div>
          </div>
        ))}
  </div>
);

const LoadingRow: React.FC<{ dividers?: boolean }> = ({ dividers = false }) => {
  return (
    <div
      className={twJoin(
        ROW_CLASS_NAME,
        "min-h-[32px]",
        dividers && "divide-x divide-gray-100",
      )}
      data-loc="data-list-loading-row"
    >
      <div className={twJoin(LEFT_COLUMN_CLASS_NAME, "py-2")}>
        <SkeletonPlaceholder height="h-4" width="w-24" />
      </div>
      <div className={twJoin(RIGHT_COLUMN_CLASS_NAME, "py-2")}>
        <SkeletonPlaceholder height="h-4" width="w-80" />
      </div>
    </div>
  );
};

export const DataValue: React.FC<{
  field: string;
  value: unknown;
}> = ({ field, value }) => {
  if (Array.isArray(value) || isObject(value)) {
    return <JSONValueViewer field={field} value={value} />;
  }

  const displayValue = (() => {
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "boolean" || value === null) {
      return speakPythonPrimitive(String(value));
    }

    if (typeof value === "string" && isISO8601(value)) {
      // Naive check for date vs datetime
      if (value.length === 10) {
        return formatDate(value, "d MMMM yyyy");
      }

      return formatDate(value, "d MMMM yyyy, h:mm aaa");
    }

    return value;
  })();

  return (
    <Tooltip
      delay={500}
      headerAction={
        <CopyTextIcon
          color="text-white hover:text-gray-300"
          feedback="inline-only-checkmark"
          value={String(displayValue)}
        />
      }
      placement="top"
      title={String(displayValue)}
      asChild
    >
      <span className="line-clamp-5">{String(displayValue)}</span>
    </Tooltip>
  );
};
