import { faCheck, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Column, Row } from "@tanstack/react-table";
import { get, isPlainObject } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useEventListener } from "usehooks-ts";

import { EnumOptionsBET } from "src/api/flowTypes";
import { DatasetRow, DesiredType } from "src/api/types";
import { FixedPositionedDropdown } from "src/base-components/FixedPositionedDropDown";
import { Icon } from "src/base-components/Icon";
import { RowUpdateFunction } from "src/datasets/DatasetTable/DatasetEditTable";
import {
  useIsEditingCell,
  useIsSelectedCell,
} from "src/datasets/DatasetTable/stores";
import { JSONValue } from "src/datasets/DatasetTable/types";
import {
  CellId,
  ENTITY_ID_FIELD_NAME,
  isEntityIdColumn,
  isEntityPrimaryOrSecondaryProperty,
  isEventIdColumn,
  jsonToPon,
  parseJsonOrReturnAsIs,
  ponToJson,
  validateCellValue,
} from "src/datasets/DatasetTable/utils";
import { ValidationResult } from "src/datasets/DatasetTable/validators";
import { DefaultInputCell } from "src/datasets/components/DefaultInputCell";
import { FloatingEntityEditor } from "src/datasets/components/FloatingEntityEditorCell";
import { JSONEditorCell } from "src/datasets/components/JSONEditorCell";
import { RelationJSONEditorCell } from "src/datasets/components/RelationJSONEditor";
import { SearchableEntityCell } from "src/datasets/components/SearchableEntityCell";
import { SearchableEventCell } from "src/datasets/components/SearchableEventCell";
import { DatasetIntegrationNode, DatasetIssue } from "src/datasets/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { logger } from "src/utils/logger";

type EditableCellProps = {
  onChange: RowUpdateFunction;
  value: JSONValue | undefined;
  row: Row<DatasetRow>;
  column: Column<DatasetRow>;
  desiredType: DesiredType;
  columnIssue?: DatasetIssue;
  nullable: boolean;
  integrationNode?: DatasetIntegrationNode;
  required: boolean;
  disabled: boolean;
  cellId: CellId;
  options?: EnumOptionsBET | null;
  relationSchema?: string;
};

const forgivingStringify = (value: JSONValue | undefined) =>
  JSON.stringify(value) ?? "";

export const EditableCell: React.FC<EditableCellProps> = ({
  onChange,
  value: receivedValue,
  row,
  column,
  desiredType,
  columnIssue,
  nullable,
  required,
  integrationNode,
  disabled,
  cellId,
  options: enumValues,
  relationSchema: relation,
}) => {
  const propValue =
    // If the value is undefined and the column is an entity ID column
    // and the parent column value is null, we want to set the visual value of this cell to null
    receivedValue === undefined &&
    isEntityIdColumn(column) &&
    get(row.original, column.parent.id) === null
      ? null
      : receivedValue;

  const selected = useIsSelectedCell(cellId);
  const editing = useIsEditingCell(cellId);

  const isRelationJsonEditorCell = ["object"].includes(desiredType) && relation;
  const isJsonEditorCell =
    ["object", "array", "any"].includes(desiredType) &&
    !isRelationJsonEditorCell;

  const [value, setValue] = useState(() =>
    jsonToPon(forgivingStringify(propValue)),
  );
  const [error, setError] = useState<ValidationResult | undefined>(undefined);

  const isSearchableEntityColumn = isEntityPrimaryOrSecondaryProperty(column);
  const isSearchableEventColumn = isEventIdColumn(column);

  useEffect(() => {
    setValue(jsonToPon(forgivingStringify(propValue)));
  }, [propValue]);

  useEffect(() => {
    setError(
      validateCellValue(ponToJson(value), {
        type: desiredType,
        nullable,
        required,
        enumValues,
      }),
    );
    // We're checking value and enumValues as strings
    // enumValues do not change actually, but to make sure we don't have
    // unnecessary re-renders we convert them to string
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desiredType, nullable, required, value, String(enumValues)]);

  // Update error state for Entity ID columns
  // on change of the parent column value
  useEffect(() => {
    if (isEntityIdColumn(column)) {
      const parentColumnValue = get(row.original, column.parent.id);

      if (isPlainObject(parentColumnValue)) {
        if (
          !parentColumnValue[ENTITY_ID_FIELD_NAME] &&
          Object.values(parentColumnValue).some((value) => value !== undefined)
        ) {
          setError({
            message: "Entity ID must exist if at least one column is filled",
          });
          return;
        }

        if (
          parentColumnValue[ENTITY_ID_FIELD_NAME] ||
          Object.values(parentColumnValue).every((value) => value === undefined)
        ) {
          setError(undefined);
        }
      }
    }
  }, [row.original, column]);

  useEventListener("paste", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      const newValue = e.clipboardData?.getData("text");

      if (newValue) {
        setValue(newValue);
        validateAndSave(newValue);
      }
    }
  });

  useEventListener("copy", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      e.clipboardData?.setData("text", ponToJson(value));
    }
  });

  useEventListener("cut", (e) => {
    if (selected && !editing) {
      e.preventDefault();

      const valueToCopy = ponToJson(value);

      if (e.clipboardData) {
        e.clipboardData.setData("text", valueToCopy);

        setValue("");
        validateAndSave("");
      }
    }
  });

  const validateAndSave = useCallback(
    (ponValue: string) => {
      const jsonValue = ponToJson(ponValue);
      const error = validateCellValue(jsonValue, {
        type: desiredType,
        nullable,
        required,
        enumValues,
      });
      setError(error);

      try {
        // Handle special case for entity fields
        if (isEntityIdColumn(column)) {
          // If this is the entity ID field, and the new value is "None"(null)
          // we empty the whole entity value to null
          if (jsonValue === "null") {
            onChange(row, column.parent.id, null);
            return;
          }
        }

        if (jsonValue.trim() === "") {
          onChange(row, column.id, undefined);
        } else {
          onChange(row, column.id, JSON.parse(jsonValue));
          setValue(jsonToPon(jsonValue));
        }
      } catch (e) {
        setError(
          error ?? {
            message: "Invalid JSON",
          },
        );
        logger.log(e);
      }
    },
    [desiredType, nullable, required, enumValues, column, onChange, row],
  );

  const onChangeHandler = (value: string) => {
    setValue(value);
    validateAndSave(value);
  };

  if (isRelationJsonEditorCell) {
    // Floating entity editor requires more work to be done
    // so we're hiding it behind a feature flag for now
    if (isFeatureFlagEnabled(FEATURE_FLAGS.floatingEntityEditor)) {
      return (
        <FloatingEntityEditor
          cellId={cellId}
          disabled={disabled}
          error={error}
          invalid={!!(error && value)}
          propValue={propValue}
          relationSchema={relation}
          type={Array.isArray(propValue) ? "array" : typeof propValue}
          value={value}
          warning={columnIssue?.issueType === "type-mismatch"}
          onChange={onChangeHandler}
        />
      );
    } else {
      return (
        <RelationJSONEditorCell
          cellId={cellId}
          column={column}
          disabled={disabled}
          integrationNode={integrationNode}
          invalid={!!(error && value)}
          propValue={propValue}
          relationSchema={relation}
          rowIndex={row.index}
          type={Array.isArray(propValue) ? "array" : typeof propValue}
          value={value}
          warning={columnIssue?.issueType === "type-mismatch"}
          onChange={onChangeHandler}
        />
      );
    }
  }

  if (isJsonEditorCell) {
    return (
      <JSONEditorCell
        cellId={cellId}
        column={column}
        disabled={disabled}
        integrationNode={integrationNode}
        invalid={!!(error && value)}
        isOpen={editing}
        postfix={
          !!enumValues?.length && (
            <EnumPicker
              cellIsSelected={selected}
              options={enumValues}
              selectedValues={
                Array.isArray(propValue)
                  ? (propValue as any[]).map((item) => JSON.stringify(item))
                  : undefined
              }
              onSelect={(selectedValue) => {
                // Handle multiselect for tags
                if (desiredType === "array") {
                  const parsedCellValue = parseJsonOrReturnAsIs(
                    ponToJson(value),
                  );
                  const valueToAdd = JSON.parse(selectedValue);
                  if (Array.isArray(parsedCellValue)) {
                    const updatedValue = parsedCellValue.includes(valueToAdd)
                      ? parsedCellValue.filter((item) => item !== valueToAdd)
                      : [...parsedCellValue, valueToAdd];
                    const newValue = jsonToPon(JSON.stringify(updatedValue));

                    onChangeHandler(newValue);
                  } else {
                    const newValue = jsonToPon(JSON.stringify([valueToAdd]));
                    onChangeHandler(newValue);
                  }
                  return;
                }

                onChangeHandler(selectedValue);
              }}
            />
          )
        }
        rowIndex={row.index}
        type={Array.isArray(propValue) ? "array" : typeof propValue}
        value={value}
        warning={columnIssue?.issueType === "type-mismatch"}
        onChange={onChangeHandler}
      />
    );
  }

  // If the column is searchable, we want to use the searchable cell
  if (isSearchableEntityColumn) {
    return (
      <SearchableEntityCell
        cellId={cellId}
        column={column}
        desiredType={desiredType}
        disabled={disabled}
        error={error}
        isOpen={editing}
        postfix={
          !!enumValues?.length && (
            <EnumPicker
              cellIsSelected={selected}
              options={enumValues}
              selectedValues={
                Array.isArray(propValue)
                  ? (propValue as any[]).map((item) => JSON.stringify(item))
                  : undefined
              }
              onSelect={(selectedValue) => {
                // Handle multiselect for tags
                if (desiredType === "array") {
                  const parsedCellValue = parseJsonOrReturnAsIs(
                    ponToJson(value),
                  );
                  const valueToAdd = JSON.parse(selectedValue);
                  if (Array.isArray(parsedCellValue)) {
                    const updatedValue = parsedCellValue.includes(valueToAdd)
                      ? parsedCellValue.filter((item) => item !== valueToAdd)
                      : [...parsedCellValue, valueToAdd];
                    const newValue = jsonToPon(JSON.stringify(updatedValue));

                    setValue(newValue);
                    validateAndSave(newValue);
                  } else {
                    const newValue = jsonToPon(JSON.stringify([valueToAdd]));
                    setValue(newValue);
                    validateAndSave(newValue);
                  }
                  return;
                }

                setValue(selectedValue);
                validateAndSave(selectedValue);
              }}
            />
          )
        }
        value={value}
        warning={columnIssue?.issueType === "type-mismatch"}
        onChange={onChangeHandler}
        onChangeEntityValue={(value: JSONValue) =>
          onChange(row, column.parent.id, value)
        }
        onResetError={() => setError(undefined)}
        onSetLocalValue={setValue}
      />
    );
  }

  if (isSearchableEventColumn) {
    return (
      <SearchableEventCell
        cellId={cellId}
        column={column}
        desiredType={desiredType}
        disabled={disabled}
        error={error}
        isOpen={editing}
        postfix={undefined}
        value={value}
        warning={false}
        onChange={onChangeHandler}
        onChangeEventValue={(value) => {
          onChange(row, column.parent.id, value);
        }}
        onResetError={() => setError(undefined)}
        onSetLocalValue={setValue}
      />
    );
  }

  return (
    <DefaultInputCell
      cellId={cellId}
      desiredType={desiredType}
      disabled={disabled}
      error={error}
      postfix={
        !!enumValues?.length && (
          <EnumPicker
            cellIsSelected={selected}
            options={enumValues}
            selectedValues={
              Array.isArray(propValue)
                ? (propValue as any[]).map((item) => JSON.stringify(item))
                : undefined
            }
            onSelect={(selectedValue) => {
              // Handle multiselect for tags
              if (desiredType === "array") {
                const parsedCellValue = parseJsonOrReturnAsIs(ponToJson(value));
                const valueToAdd = JSON.parse(selectedValue);
                if (Array.isArray(parsedCellValue)) {
                  const updatedValue = parsedCellValue.includes(valueToAdd)
                    ? parsedCellValue.filter((item) => item !== valueToAdd)
                    : [...parsedCellValue, valueToAdd];
                  const newValue = jsonToPon(JSON.stringify(updatedValue));

                  onChangeHandler(newValue);
                } else {
                  const newValue = jsonToPon(JSON.stringify([valueToAdd]));
                  onChangeHandler(newValue);
                }
                return;
              }

              onChangeHandler(selectedValue);
            }}
          />
        )
      }
      value={value}
      warning={columnIssue?.issueType === "type-mismatch"}
      onChange={onChangeHandler}
      onResetError={() => setError(undefined)}
      onSetLocalValue={setValue}
    />
  );
};

const EnumPicker: React.FC<{
  options: EnumOptionsBET;
  onSelect: (value: string) => void;
  cellIsSelected: boolean;
  selectedValues?: string[];
}> = ({ options, onSelect, cellIsSelected, selectedValues }) => {
  return (
    <FixedPositionedDropdown
      buttonClassName="bg-transparent border-0 focus:ring-0 ring-0"
      className={twJoin("hidden group-hover:block", cellIsSelected && "block")}
      dataLoc="dataset-enum-items"
      elements={options.map((value) => ({
        key: String(value),
        value: String(value),
      }))}
      itemsClassNames="overflow-y-auto max-h-80 decideScrollbar"
      renderButtonValue={() => (
        <Icon
          color="text-gray-500"
          dataLoc="dataset-enum-dropdown"
          icon={faChevronDown}
          size="2xs"
        />
      )}
      renderValue={(element) => (
        <div className="flex min-w-[160px] max-w-56 items-center justify-between gap-x-2 truncate px-4 py-2.5">
          {element.value}
          {Array.isArray(selectedValues) &&
            selectedValues.includes(element.value) && (
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            )}
        </div>
      )}
      onSelect={onSelect}
    />
  );
};
