import {
  InfiniteData,
  keepPreviousData as keepPreviousDataReactQuery,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueries,
  useQuery,
} from "@tanstack/react-query";
import { useEffect } from "react";

import { flowVersionV2ToFlowVersionT } from "src/api";
import { versionsApi } from "src/api/endpoints";
import { FlowVersionT } from "src/api/flowTypes";
import { Operation } from "src/api/flowVersionUpdateIndex";
import { NEVER_REFETCH_OPTIONS } from "src/api/queries";
import { ChangeLogDb } from "src/clients/flow-api";
import { useCommentsSummary } from "src/comments/queries";

type PaginationParams = {
  limit: number;
  skip: number;
  beforeEtag: string | undefined;
  afterEtag: string | undefined;
};

export const etag_including_comments = (
  flow_version_etag: string | undefined,
) => {
  if (!flow_version_etag) return undefined;
  if (flow_version_etag.length !== 8) throw new Error("Invalid etag");
  return `${flow_version_etag}_9`;
};

export const changeHistoryKeys = {
  all: ["changeHistory"] as const,
  version: (versionId: string) =>
    [...changeHistoryKeys.all, versionId] as const,
  etag: (versionId: string, etag: string) =>
    [...changeHistoryKeys.version(versionId), etag] as const,
};

const DEFAULT_ETAG = "00000000";

export const canFetchNextPage = ({
  hasNextPage,
  isFetchingNextPage,
}: {
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
}) => (hasNextPage ?? false) && !isFetchingNextPage;

export const useFetchAllChanges = (
  changeHistoryQuery: UseInfiniteQueryResult<
    InfiniteData<ChangeLogDb[], unknown>,
    Error
  >,
) => {
  useEffect(() => {
    /**
     * Always fetch all changes for grouped display mode and to always
     */
    if (
      canFetchNextPage({
        hasNextPage: changeHistoryQuery.hasNextPage,
        isFetchingNextPage: changeHistoryQuery.isFetchingNextPage,
      })
    ) {
      changeHistoryQuery.fetchNextPage();
    }

    // We don't want to include changeHistoryQuery in the dep array because it changes identity on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changeHistoryQuery.hasNextPage,
    changeHistoryQuery.isFetchingNextPage,
    changeHistoryQuery.data,
    changeHistoryQuery.fetchNextPage,
  ]);
};

type VersionIdEtagPair = {
  versionId: string;
  etag: string;
};
/**
 * Gets the last change history for each version id and etag pair.
 * Need for knowing the last effective etag for each version.
 * @param versionIdEtagPairs - An array of version id and etag pairs.
 * @returns An array of change history data.
 */
export const useChangeHistories = (versionIdEtagPairs: VersionIdEtagPair[]) => {
  return useQueries({
    queries: versionIdEtagPairs.map(({ versionId, etag }) => ({
      queryKey: ["lastChangeHistoryEtag", versionId, etag],
      queryFn: async (): Promise<ChangeLogDb[]> => {
        const response =
          await versionsApi.getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(
            versionId,
            undefined,
            etag,
            0,
            1,
          );
        return fixCopyVersionEntries(response.data);
      },
      select: (data: ChangeLogDb[]) => data[0],
    })),
  });
};

/**
 * Fix for flow-api writing the old versions data into the diff of the duplicated version change log.
 * Needs to be kept in sync with the duplicate endpoint handles this
 */
const fixCopyVersionEntries = (data: ChangeLogDb[]) => {
  return data.map((change) => {
    if (change.diff?.operation === Operation.VERSION_DUPLICATED) {
      change.diff = {
        ...change.checkpoint,
        operation: Operation.VERSION_DUPLICATED,
        locks: {},
      };
    }
    return change;
  });
};

const pageSizeLimit = 40;
export const useLoadChangeHistory = (
  versionId: string | undefined,
  etag: string | undefined,
  {
    afterEtag = undefined,
    includeReviewComments = false,
    includeLogic = true,
  }: {
    afterEtag?: string;
    includeReviewComments?: boolean;
    includeLogic?: boolean;
  } = {},
) => {
  const { data: commentCount } = useCommentsSummary(versionId);
  const initialPageParams: PaginationParams = {
    limit: pageSizeLimit,
    skip: 0,
    beforeEtag: undefined,
    afterEtag: afterEtag,
  };
  return useInfiniteQuery<
    ChangeLogDb[],
    Error,
    InfiniteData<ChangeLogDb[]>,
    QueryKey,
    PaginationParams
  >({
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) =>
        page.filter(
          (change) =>
            (change.diff && includeLogic) ||
            (change.comment && includeReviewComments),
        ),
      ),
    }),
    getNextPageParam: (lastPage): PaginationParams | undefined => {
      if (lastPage.length !== 0) {
        return {
          beforeEtag:
            lastPage.slice(-1)[0].etag > DEFAULT_ETAG
              ? lastPage.slice(-1)[0].etag
              : DEFAULT_ETAG,
          limit: pageSizeLimit,
          // we already have the change with the last etag, so we need to skip it
          skip: 1,
          afterEtag: undefined,
        };
      } else {
        return undefined; // last returned page empty, no new pages
      }
    },
    queryFn: async ({ pageParam }) => {
      const response =
        await versionsApi.getFlowVersionChangesApiV2FlowVersionsFlowVersionIdChangesGet(
          versionId!,
          afterEtag,
          pageParam.beforeEtag === undefined ? etag : pageParam.beforeEtag,
          pageParam.skip,
          /* limit */ undefined,
          /* aggregation time */ undefined,
          // If we always subsribe to everything, we can filter clientside to serve
          // both review and logic changes.
          // The advantage is we do not cache two different queries
          /*includeLogic*/ true,
          /*includeReviewComments*/ true,
        );
      return fixCopyVersionEntries(response.data);
    },
    initialPageParam: initialPageParams,
    queryKey: [
      ...changeHistoryKeys.etag(versionId ?? "", etag ?? ""),
      afterEtag,
      /* TODO: change to comment summary etag, AUTH-543*/ commentCount,
    ],
    // By adding the etag to the query key we refetch every time flow version gets updated, which makes sense. However during the refetch we don't have a cache,
    // since we just set a new query key. The old data is however only outdated, not invalid. So it makes sense to keep it until the next fetch completes.
    placeholderData: keepPreviousDataReactQuery,
    enabled: !!versionId,
  });
};

export const useRevision = ({
  versionId = "",
  etag = "",
  keepPreviousData = false,
}: {
  versionId?: string;
  etag?: string;
  keepPreviousData?: boolean;
}) => {
  return useQuery<FlowVersionT, Error>({
    queryKey: ["flowVersionRevision", versionId, etag],
    queryFn: async () => {
      const response =
        await versionsApi.getFlowVersionRevisionApiV2FlowVersionsFlowVersionIdRevisionEtagGet(
          versionId,
          etag,
        );
      return flowVersionV2ToFlowVersionT(response.data);
    },
    ...NEVER_REFETCH_OPTIONS,
    placeholderData: keepPreviousData ? keepPreviousDataReactQuery : undefined,
    enabled: versionId !== "" && etag !== "",
  });
};
