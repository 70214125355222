import { faFile, faPlus, faTag } from "@fortawesome/pro-regular-svg-icons";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { ExporterDatasetsEndpoint } from "src/api/endpoints";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { Select, SELECT_DIVIDER } from "src/base-components/Select";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import { getDesiredType } from "src/datasets/DatasetTable/utils";
import {
  useDatasets,
  usePostRows,
  useCreateDatasetFromScratchMutation,
  useDeleteRowMutation,
} from "src/datasets/api/queries";
import { useInputColumnsFromSchema } from "src/datasets/utils";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

type Props = {
  decisionId: string | null;
  onClose: () => void;
};

export const TestCaseModal: React.FC<Props> = ({
  decisionId,
  onClose: _onClose,
}) => {
  const { flow, workspace, version } = useAuthoringContext();
  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });

  const testRunDatasets = datasetsQuery.data
    ? datasetsQuery.data.filter((d) => !d.purpose || d.purpose === "test_run")
    : undefined;

  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null,
  );

  const [newDatasetName, setNewDatasetName] = useState("");
  const [isLoadingNewDataset, setIsLoadingNewDataset] = useState(false);
  const onClose = () => {
    setNewDatasetName("");
    setIsLoadingNewDataset(false);
    _onClose();
  };
  const createDatasetFromScratch = useCreateDatasetFromScratchMutation(
    workspace.base_url,
  );

  const deleteRowMutation = useDeleteRowMutation(
    selectedDatasetId ?? "",
    flow.id,
    workspace.base_url,
  );

  useEffect(() => {
    if (!selectedDatasetId && testRunDatasets?.at(0)) {
      setSelectedDatasetId(testRunDatasets[0].id);
    } else if (!selectedDatasetId && !testRunDatasets?.at(0)) {
      setSelectedDatasetId("new");
    }
  }, [testRunDatasets, selectedDatasetId]);

  const postRows = usePostRows(
    selectedDatasetId ?? "",
    flow.id,
    workspace.base_url,
  );

  const inputColumns = useInputColumnsFromSchema(version);
  const integrationNodes = useAvailableIntegrationNodes(version);

  const handleSubmit = async () => {
    if (!decisionId) return;

    try {
      if (selectedDatasetId === "new") {
        setIsLoadingNewDataset(true);
        const newDataset = await createDatasetFromScratch.mutateAsync({
          name: newDatasetName,
          flow_id: flow.id,
          input_columns: inputColumns,
          mock_columns: uniqBy(integrationNodes, "name").map((node) => ({
            name: node.name,
            desired_type: getDesiredType(node),
            use_subflow_mocks: false,
          })),
          purpose: "test_run",
        });
        setSelectedDatasetId(newDataset.id);

        const datasetWithRows = await ExporterDatasetsEndpoint.getDataset(
          workspace.base_url!,
          newDataset.id,
        );
        const newRow = datasetWithRows.rows[0];
        if (newRow) {
          await deleteRowMutation.mutateAsync(newRow.id);
        }

        await postRows.mutateAsync({
          source: "decision_id",
          decision_id: decisionId,
          new_row_ids: [newRow ? newRow.id : uuidv4()],
        });
        setIsLoadingNewDataset(false);
        setNewDatasetName("");
      } else {
        await postRows.mutateAsync({
          source: "decision_id",
          decision_id: decisionId,
          new_row_ids: [uuidv4()],
        });
      }

      toastActions.success({ title: "Test case successfully added" });
      onClose();
    } catch (e) {
      toastActions.failure({
        title: "Failed to add test case",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const datasetOptions = (testRunDatasets ?? []).map((dataset) => ({
    key: dataset.id,
    value: (
      <div className="flex items-center gap-x-2">
        <Icon
          color="text-indigo-500"
          icon={dataset.source === "file" ? faFile : faTag}
          padding={false}
          size="xs"
        />
        {dataset.name}
      </div>
    ),
  }));

  const options = [
    ...datasetOptions,
    ...(datasetOptions.length > 0 ? [SELECT_DIVIDER] : []),
    {
      key: "new",
      value: (
        <div className="flex items-center gap-x-2 text-indigo-500">
          <Icon icon={faPlus} padding={false} size="2xs" />
          New dataset
        </div>
      ),
    },
  ];

  return (
    <Modal open={!!decisionId} onClose={onClose}>
      <Modal.Header description="Add this test case to an existing test dataset">
        Select a test dataset
      </Modal.Header>
      <Modal.Content>
        <>
          <div className="mb-1 mt-3 text-gray-800 font-inter-semibold-13px">
            Select dataset <RequiredAsterisk />
          </div>
          <Select
            options={options}
            value={selectedDatasetId}
            onChange={setSelectedDatasetId}
          />
          {selectedDatasetId === "new" && (
            <div className="mt-2">
              <div className="mb-1 mt-3 text-gray-800 font-inter-semibold-13px">
                Dataset name <RequiredAsterisk />
              </div>
              <div className="mt-2">
                <Input
                  fullWidth={true}
                  placeholder="Enter dataset name"
                  type="text"
                  value={newDatasetName}
                  onChange={(e) => setNewDatasetName(e.target.value)}
                />
              </div>
            </div>
          )}
        </>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="save"
            disabled={!selectedDatasetId && !newDatasetName.trim()}
            loading={isLoadingNewDataset || postRows.isPending}
            variant="primary"
            onClick={handleSubmit}
          >
            Add to dataset
          </Button>
        }
      />
    </Modal>
  );
};
