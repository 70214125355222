import { observer } from "mobx-react-lite";
import React from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { useUpdateSchema } from "src/api/flowVersionQueries";
import {
  SearchParamsTabView,
  SearchParamsComponentT,
} from "src/base-components/SearchParamsTabView";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { refetchSchemaCompletionData } from "src/nodeEditor/api/queries";
import { SchemaOptions, URLKeys } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { SchemaEditor } from "src/schema/schemaEditor/SchemaEditor";
import { defaultSchema } from "src/schema/schemaMappingUtils";
import { useGraphStore } from "src/store/StoreProvider";
import {
  useInputSchemaError,
  useOutputSchemaError,
} from "src/store/runState/inputOutputErrors";

type PropsT = {
  flowVersion: FlowVersionT;
  setSchemaInvalid: (errored: boolean) => void;
};

export const SchemaTabView: React.FC<PropsT> = observer(
  ({ flowVersion, setSchemaInvalid }) => {
    const { graphHasChanged } = useGraphStore();
    const inputSchemaError = useInputSchemaError();
    const outputSchemaError = useOutputSchemaError();
    const canEditFlowVersion = useCanAuthoringEditFlowVersion();
    const { mutate: mutateSchema } = useUpdateSchema(flowVersion.id, () => {
      graphHasChanged();

      if (isFeatureFlagEnabled(FEATURE_FLAGS.autocomplete)) {
        refetchSchemaCompletionData(flowVersion.id);
      }
    });

    const tabs: SearchParamsComponentT[] = [
      {
        title: "Input",
        dataLoc: "schema-input-tab",
        searchParamValue: SchemaOptions.Input,
        component: (
          <SchemaEditor
            key={`${flowVersion.id}-input`}
            flowVersionId={flowVersion.id}
            immutable={!canEditFlowVersion}
            mutateSchema={mutateSchema}
            schema={flowVersion.input_schema ?? defaultSchema}
            setSchemaInvalid={setSchemaInvalid}
            type={SchemaOptions.Input}
          />
        ),
        errored: inputSchemaError,
      },
      {
        title: "Output",
        dataLoc: "schema-output-tab",
        searchParamValue: SchemaOptions.Output,
        component: (
          <SchemaEditor
            key={`${flowVersion.id}-output`}
            flowVersionId={flowVersion.id}
            immutable={!canEditFlowVersion}
            mutateSchema={mutateSchema}
            schema={flowVersion.output_schema ?? defaultSchema}
            setSchemaInvalid={setSchemaInvalid}
            type={SchemaOptions.Output}
          />
        ),
        errored: outputSchemaError,
      },
    ];
    return (
      <SearchParamsTabView
        containerClassName="pr-10"
        defaultValue={SchemaOptions.Input}
        searchParamKey={URLKeys.Schema}
        tabs={tabs}
      />
    );
  },
);
