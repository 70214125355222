import { Pill } from "src/base-components/Pill";

const Item: React.FC<{
  title: string;
  active?: boolean;
  count?: number;
  onClick: () => void;
}> = ({ title, active = false, count, onClick }) => {
  return (
    <Pill size="lg" variant={active ? "indigo" : "white"} onClick={onClick}>
      <Pill.Text>
        {title}
        {typeof count === "number" ? ` (${count})` : ""}
      </Pill.Text>
    </Pill>
  );
};

export enum ManualReviewFilters {
  ALL = "ALL",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
}

type Props = {
  value: ManualReviewFilters;
  onChange: (value: ManualReviewFilters) => void;
};

export const ManualReviewFilter: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className="flex gap-x-2">
      <Item
        active={value === ManualReviewFilters.ALL}
        title="All"
        onClick={() => onChange(ManualReviewFilters.ALL)}
      />
      <Item
        active={value === ManualReviewFilters.NEEDS_REVIEW}
        title="Needs manual review"
        onClick={() => onChange(ManualReviewFilters.NEEDS_REVIEW)}
      />
      <Item
        active={value === ManualReviewFilters.ASSIGNED_TO_ME}
        title="Assigned to me"
        onClick={() => onChange(ManualReviewFilters.ASSIGNED_TO_ME)}
      />
    </div>
  );
};
