import { times } from "lodash";
import { twJoin } from "tailwind-merge";

import { mdService } from "src/base-components/TextEditor/MarkdownService";
import { SkeletonLine } from "src/manualReview/reviewCaseCommon/SkeletonLine";

export const FormSide: React.FC<{
  isLoading?: boolean;
  children: React.ReactNode;
  nodeName: string | undefined;
  description: string | undefined;
  noBorders?: boolean;
}> = ({
  isLoading,
  children,
  nodeName,
  description = "",
  noBorders = false,
}) => {
  return (
    <div
      className={twJoin(
        "flex min-w-[362px] max-w-150 basis-[33vw] flex-col",
        !noBorders && "border-l border-r border-gray-200",
      )}
    >
      <div
        className="max-h-90 overflow-auto py-4 pl-6"
        data-loc="manual-review-task-description"
      >
        {!isLoading ? (
          <>
            <p className="text-gray-800 font-inter-semibold-13px">{nodeName}</p>
            <div
              //skipcq: JS-0440
              dangerouslySetInnerHTML={{
                __html: mdService.toHtml(description),
              }}
              className="review-case-description"
            />
          </>
        ) : (
          <>
            <SkeletonLine className="h-4 w-[117px]" />
            <SkeletonLine className="mb-7 mt-3 h-4 w-[250px]" />
          </>
        )}
      </div>
      <div className="mx-3.5 border-t border-gray-200" />
      <div className="min-h-0 flex-1 overflow-auto px-6">
        {!isLoading ? (
          children
        ) : (
          <div className="mt-8 space-y-[38px]">
            {times(3, (index) => (
              <div key={index}>
                <SkeletonLine className="h-3 w-[153px]" />
                <SkeletonLine className="mt-3 h-8 w-[490px]" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
