import { faInfoCircle, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { JSONValue } from "@segment/analytics-next";
import { Column } from "@tanstack/react-table";
import { endOfToday, startOfToday, subMonths } from "date-fns";
import { isNull, isPlainObject } from "lodash-es";
import { ReactNode, useState, useMemo } from "react";
import { useSessionStorage } from "usehooks-ts";

import { DatasetRow, DecisionEnvironment, DesiredType } from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { EventConfigEnriched } from "src/clients/features-control";
import { CellId, getColumnResource } from "src/datasets/DatasetTable/utils";
import { ValidationResult } from "src/datasets/DatasetTable/validators";
import { CellWrapper } from "src/datasets/components/CellWrapper";
import {
  ButtonItem,
  ControllableDropdown,
} from "src/datasets/components/ControllableDropdown";
import { DefaultInputCell } from "src/datasets/components/DefaultInputCell";
import { ResourceSearchPane } from "src/datasets/components/ResourceSearchPane";
import {
  ErrorDetails,
  getErrorDetails,
} from "src/datasets/components/errorUtils";
import { useShowDefaultEditor } from "src/datasets/components/useShowDefaultEditor";
import { Tooltip } from "src/design-system/Tooltip";
import { useEvent, useEvents } from "src/eventsCatalogue/queries";
import { formatDate } from "src/utils/datetime";

/**
 * A cell component for searching and selecting events from the event catalog.
 * Allows users to search for events by their properties and select them for use in datasets.
 */
export const SearchableEventCell: React.FC<{
  isOpen: boolean;
  cellId: CellId;
  column: Column<DatasetRow>;
  disabled: boolean;
  desiredType: DesiredType;
  error: ValidationResult | undefined;
  value: string;
  onChange: (value: string) => void;
  onResetError: () => void;
  onSetLocalValue: (value: string) => void;
  warning: boolean;
  postfix: ReactNode;
  onChangeEventValue: (value: JSONValue) => void;
}> = ({
  isOpen,
  cellId,
  column,
  disabled,
  desiredType,
  error,
  value,
  onChange,
  onResetError,
  onSetLocalValue,
  warning,
  postfix,
  onChangeEventValue,
}) => {
  const [showDefaultEditor, setShowDefaultEditor] = useShowDefaultEditor(
    cellId,
    value,
  );
  const resource = getColumnResource(column);

  if (!resource || showDefaultEditor) {
    return (
      <DefaultInputCell
        cellId={cellId}
        desiredType={desiredType}
        disabled={disabled}
        error={error}
        postfix={postfix}
        value={value}
        warning={warning}
        onChange={onChange}
        onResetError={onResetError}
        onSetLocalValue={onSetLocalValue}
      />
    );
  }

  return (
    <SearchableEventRenderer
      cellId={cellId}
      column={column}
      error={getErrorDetails(error, value, onChange)}
      eventConfig={resource as EventConfigEnriched}
      isOpen={isOpen}
      onChangeEventValue={(value) => {
        onChangeEventValue(value);
        setShowDefaultEditor();
      }}
      onNewValue={setShowDefaultEditor}
    />
  );
};

const SearchableEventRenderer: React.FC<{
  cellId: CellId;
  isOpen: boolean;
  onNewValue: () => void;
  onChangeEventValue: (value: JSONValue) => void;
  column: Column<DatasetRow>;
  eventConfig: EventConfigEnriched;
  error: ErrorDetails | undefined;
}> = ({
  cellId,
  isOpen,
  onNewValue,
  column,
  eventConfig,
  onChangeEventValue,
  error,
}) => {
  const [env, setEnv] = useSessionStorage<DecisionEnvironment>(
    "datasets-searchable-event-env",
    DecisionEnvironment.LIVE,
  );
  const [searchQuery, setSearchQuery] = useState("");

  const userIsSearching = searchQuery.length > 0;

  // Use the events query to fetch events based on the event type
  const { data: eventsData, isLoading: isEventsLoading } = useEvents(
    {
      eventType: eventConfig.event_type,
      environment: env,
      timeWindow: {
        from: subMonths(startOfToday(), 1).toISOString(),
        to: endOfToday().toISOString(),
      },
    },
    {
      enabled: !userIsSearching,
    },
  );

  const { data: eventData, isLoading: isEventLoading } = useEvent(
    {
      eventType: eventConfig.event_type,
      eventId: searchQuery,
      environment: env,
    },
    {
      enabled: userIsSearching,
    },
  );

  const eventsToDisplay = useMemo(() => {
    const singleEventData = eventData ? [eventData] : undefined;
    const data = userIsSearching ? singleEventData : eventsData;

    if (!data) return [];

    return data;
  }, [eventData, userIsSearching, eventsData]);

  const handleEventSelect = (eventId: string) => {
    const selectedEvent = eventsData?.find(
      (event) => event.payload.id === eventId,
    );

    if (selectedEvent) {
      onChangeEventValue(removeInternalProperties(selectedEvent.payload));
    }
  };

  return (
    <CellWrapper cellId={cellId} error={error}>
      <ControllableDropdown
        isOpen={isOpen}
        placeholder="Select event"
        postfix={
          <Tooltip
            title="Select an event or search from your event catalog. Other fields in the dataset will auto-fill based on the event's properties."
            asChild
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
          </Tooltip>
        }
        width="w-96"
      >
        <ButtonItem icon={faPlus} label="New value" onClick={onNewValue} />
        <Divider spacing="my-2" />
        <ResourceSearchPane
          filters={{
            environment: env,
            searchQuery,
          }}
          isLoading={userIsSearching ? isEventLoading : isEventsLoading}
          items={eventsToDisplay.map((event) => ({
            label: formatDate(event.payload.timestamp, "MMMM d, yyyy, hh:mm a"),
            key: event.payload.id,
            isFetching: false,
          }))}
          resourceName={eventConfig.display_name_plural}
          searchPlaceholder={`Search for ${eventConfig.display_name_singular} by ${column.columnDef.meta?.archetype?.name || "ID"}`}
          onChangeFilters={(filters) => {
            setEnv(filters.environment);
            setSearchQuery(filters.searchQuery);
          }}
          onSelect={(item) => handleEventSelect(item.key)}
        />
      </ControllableDropdown>
    </CellWrapper>
  );
};

// We get enriched event here, so what we need is
// just remove internal properties before inserting into the dataset
const removeInternalProperties = (payload: JSONValue) => {
  if (isPlainObject(payload) && !isNull(payload)) {
    return Object.entries(payload).reduce(
      (acc, [key, value]) => {
        if (!key.startsWith("_")) {
          acc[key] = isPlainObject(value)
            ? removeInternalProperties(value)
            : value;
        }
        return acc;
      },
      {} as Record<string, JSONValue>,
    );
  }

  return payload;
};
