import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { InfiniteData } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import {
  CommentDb,
  FlowVersionStatus,
  ReviewCommentStatus,
  ReviewDb,
} from "src/clients/flow-api";
import { CommentCardWithActions } from "src/comments/CommentCard";
import { CommentEditor } from "src/comments/CommentEditor";
import { useCommentReplies, useCreateComment } from "src/comments/queries";
import { useRerequestReview } from "src/flowReview/useRerequestReview";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";

type Props = {
  comment: CommentDb;
  onDelete: (comment: CommentDb) => Promise<boolean | void>;
  isVisible: boolean;
  review?: ReviewDb;
};

export const CommentReplies: React.FC<Props> = observer(
  ({ isVisible, comment, onDelete, review }) => {
    const { version, flow, orgId } = useAuthoringContext();
    const reviewerList = flow.review_configuration?.default_reviewer_list ?? [];
    const userId = useCurrentUserId();
    const [hasReplies, setHasReplies] = useState(false);
    const replies = useCommentReplies(comment.id, isVisible);
    const { mutate: createComment, isPending: isCreating } = useCreateComment();
    const [isReplying, setReplying] = useState(false);
    const { rerequestReview, isLoading: isRerequesting } = useRerequestReview();
    const replyCount = useMemo(
      () => replies.data?.pages.flatMap((page) => page).length || 0,
      [replies.data],
    );

    useEffect(() => {
      setHasReplies(replyCount > 0);
    }, [replyCount]);

    const handleCreate = (content: string) => {
      createComment({
        id: v4(),
        userId: userId!,
        commentCreate: {
          content,
          flow_version: version.id,
          parent: comment.id,
        },
      });
      setReplying(false);
      setHasReplies(true);
      tracker.emit(
        trackingEvents.createNewComment({
          flow_id: flow.id,
          flow_version_id: version.id,
          is_reply: true,
          organization_id: orgId,
          parent_comment_id: comment.id,
        }),
      );
    };

    const handleDelete = async (reply: CommentDb) => {
      const deleted = await onDelete(reply);

      if (deleted && replyCount === 1) {
        setHasReplies(false);
      }
    };

    const handleRerequestReview = () => {
      if (!review) {
        throw new Error("Review is required to re-request review");
      }
      rerequestReview({
        review,
        version,
        requestedFrom: [comment.author],
      });
    };

    const requestable =
      comment.author !== userId &&
      reviewerList.includes(comment.author) &&
      version.status === FlowVersionStatus.DRAFT &&
      (comment.reviewer_status === ReviewCommentStatus.COMMENTED ||
        comment.reviewer_status === ReviewCommentStatus.APPROVED ||
        comment.reviewer_status === ReviewCommentStatus.REQUESTED_CHANGES);

    const actions = (
      <div className="flex gap-2">
        <Button
          dataLoc="add-reply-button"
          loading={isCreating}
          size="sm"
          variant="secondary"
          onClick={() => setReplying(true)}
        >
          Add reply
        </Button>
        {requestable && (
          <Button
            loading={isRerequesting}
            size="sm"
            variant="secondary"
            onClick={handleRerequestReview}
          >
            <div className="flex">
              <Icon icon={faEye} size="xs"></Icon>
              Re-request review
            </div>
          </Button>
        )}
      </div>
    );

    if (!hasReplies && !isReplying) {
      return actions;
    }

    return (
      <Card>
        <Card.Content className="space-y-4 border-l border-indigo-50 pl-3">
          <LoadingView
            queryResult={replies}
            renderUpdated={(data: InfiniteData<CommentDb[]>) => (
              <>
                {data.pages
                  .flatMap((page) => page)
                  .map((reply) => (
                    <CommentCardWithActions
                      key={reply.id}
                      comment={reply}
                      isReply
                      onDelete={handleDelete}
                    />
                  ))}
              </>
            )}
            renderUpdating={() => <></>}
          />

          {!isReplying && replies.hasNextPage && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => replies.fetchNextPage()}
            >
              Load more
            </Button>
          )}

          {isReplying && (
            <CommentEditor
              buttonText="Add"
              dataLoc="reply-editor"
              isSaving={isCreating}
              autoFocus
              clearOnSave
              onCancel={() => setReplying(false)}
              onSave={handleCreate}
            />
          )}
          {!isReplying && actions}
        </Card.Content>
      </Card>
    );
  },
);
