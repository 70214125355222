import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";

import { copilotApi } from "src/api/endpoints";
import {
  ConversationMessage,
  ConversationMessageRoleEnum,
  ConverseRequest,
  ConverseResponse,
  NodeConversationContext,
} from "src/clients/copilot";
import { failureMessage, NODE_SUGGESTION_OPEN } from "src/copilot/utils";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { queryClient } from "src/queryClient";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { PartialBy } from "src/utils/typing";

const validationErrorMessage =
  "A conversation must alternate between user and copilot roles";
const converseKey = (conversationId: string) => ["converse", conversationId];
export const conversationKey = (conversationId: string) => [
  "conversation",
  conversationId,
];

export const useConverse = (args: {
  conversationId: string;
  baseUrl: string;
}) => {
  return useMutation({
    mutationKey: converseKey(args.conversationId),
    mutationFn: async (
      request: PartialBy<ConverseRequest, "conversation_id">,
    ) => {
      const converseRequest = {
        conversation_id: args.conversationId,
        ...request,
      };
      const response = await copilotApi(
        args.baseUrl,
      ).converseCopilotApiV1ConversePost({
        converseRequest,
      });
      if (response.data) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
    },
  });
};

export const useConversation = (args: { conversationId: string }) => {
  return useQuery<ConverseResponse | null>({
    queryKey: conversationKey(args.conversationId),
    queryFn: () => null,
    enabled: false,
  });
};

type AskCopilotParams = {
  nodeId: string;
  completionId: string;
  message: string | null;
  context: NodeConversationContext;
};

const getConvo = (conversationId: string) =>
  queryClient.getQueryData<ConverseResponse>(
    conversationKey(conversationId),
  ) ?? { messages: [], completion_id: "", conversation_id: conversationId };

const appendMessage = (
  conversationId: string,
  message: ConversationMessage,
  completionId?: string,
) => {
  const previousConvo = getConvo(conversationId);
  const newConvo = {
    ...previousConvo,
    completion_id: completionId ?? previousConvo.completion_id,
    messages: [...previousConvo.messages, message],
  };

  queryClient.setQueryData<ConverseResponse>(
    conversationKey(conversationId),
    newConvo,
  );

  return newConvo;
};

const validateMessages = (messages: ConversationMessage[]) => {
  let lastRole: ConversationMessageRoleEnum | null = null;

  for (const message of messages) {
    if (lastRole === message.role) {
      return false;
    }
    lastRole = message.role;
  }

  return lastRole === ConversationMessageRoleEnum.USER;
};

const removeLastMessage = (conversationId: string) => {
  const previousConvo = getConvo(conversationId);
  const newConvo = {
    ...previousConvo,
    messages: previousConvo.messages.slice(0, -1),
  };

  queryClient.setQueryData<ConverseResponse>(
    conversationKey(conversationId),
    newConvo,
  );

  return newConvo;
};

export const useAskCopilot = (args: {
  conversationId: string;
  baseUrl: string;
}) => {
  const { mutateAsync } = useConverse(args);
  const { orgId, version } = useAuthoringContext();

  return useMutation({
    mutationKey: conversationKey(args.conversationId),
    onMutate: (params: AskCopilotParams) => {
      // append user message to the conversation immediately
      if (params.message) {
        appendMessage(args.conversationId, {
          role: ConversationMessageRoleEnum.USER,
          content: params.message,
        });
      } else {
        removeLastMessage(args.conversationId);
      }
    },
    mutationFn: async (params: AskCopilotParams) => {
      // since user message is already appended with onMutate,
      // we can get the conversation with the user message
      const { messages } = getConvo(args.conversationId);

      const converseRequest = {
        completion_id: params.completionId,
        conversation_id: args.conversationId,
        context: params.context,
        messages,
      };

      try {
        if (!validateMessages(messages)) {
          throw new Error(validationErrorMessage);
        }

        const response = await mutateAsync(converseRequest);

        // update conversation
        appendMessage(
          args.conversationId,
          response.messages[0],
          response.completion_id,
        );

        // track copilot completion
        tracker.emit(
          trackingEvents.copilotCompletion({
            organization_id: orgId,
            node_id: params.nodeId,
            completion_id: response.completion_id,
            flow_version_id: version.id,
            includes_node_suggestion:
              response.messages[0].content.includes(NODE_SUGGESTION_OPEN),
          }),
        );
      } catch (e) {
        logger.error(e);
        throw e;
      }
    },
    retry: (failureCount: number, error: AxiosError) => {
      const errorCode = isAxiosError(error) && error.status;
      return errorCode === 424 && failureCount < 5;
    },
    onError: (error) => {
      if (error.message === validationErrorMessage) {
        appendMessage(args.conversationId, {
          role: ConversationMessageRoleEnum.COPILOT,
          content: failureMessage,
        });
      }
    },
  });
};

type FetchCompletionParams = {
  completionId: string;
  baseUrl: string;
};

export const useFetchCompletion = (conversationId: string) => {
  return useMutation({
    retryDelay: 3000,
    mutationFn: async (params: FetchCompletionParams) => {
      try {
        const response = await copilotApi(
          params.baseUrl,
        ).getConversationOutputCopilotApiV1ConverseCompletionIdGet({
          completionId: params.completionId,
        });
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.statusText);
        }
      } catch (e) {
        logger.error(e);
        throw e;
      }
    },
    onSuccess: (response) => {
      // update conversation
      appendMessage(
        conversationId,
        response.messages[0],
        response.completion_id,
      );
    },
    retry: (failureCount: number, error: AxiosError) => {
      const shouldRetry = isAxiosError(error) && failureCount < 10;

      if (!shouldRetry) {
        appendMessage(conversationId, {
          role: ConversationMessageRoleEnum.COPILOT,
          content: failureMessage,
        });
      }

      return shouldRetry;
    },
  });
};
