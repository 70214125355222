import { useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";

import { analytics, loadAnalytics } from "src/instrumentation/analytics";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const PageViewTracker: React.FC = () => {
  // automatically tracks individual paths of our SPA
  // as individual page view events in analytics.js
  // see https://posthog.com/tutorials/single-page-app-pageview
  loadAnalytics();
  const location = useLocation();
  const { orgId } = useParamsDecode<DashboardPageParamsT>();

  useEffect(() => {
    analytics.ready(() => {
      // analytics.page also logs the whole URL in the background irrespective of arguments.
      // This means we save the search even if not explicitly passing it.
      // For data consistency reasons we stayed with explicitly just logging the pathname.
      analytics.page(location.pathname, { $group_id: orgId });
    });
  }, [location.pathname, location.search, orgId]);

  return <Outlet />;
};
