/* tslint:disable */
/* eslint-disable */
/**
 * Entities API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DatasetJobGenericError
 */
export interface DatasetJobGenericError {
    /**
     * 
     * @type {string}
     * @memberof DatasetJobGenericError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface EntityCaseSummary
 */
export interface EntityCaseSummary {
    /**
     * 
     * @type {Set<string>}
     * @memberof EntityCaseSummary
     */
    'assignees'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityCaseSummary
     */
    'status'?: EntityCaseSummaryStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityCaseSummaryStatusEnum {
    REVIEW_REQUIRED = 'review_required',
    NO_PENDING_REVIEW = 'no_pending_review'
}

/**
 * 
 * @export
 * @interface EntityMap
 */
export interface EntityMap {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EntityMap
     */
    'entities': { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface EntityResourcePage
 */
export interface EntityResourcePage {
    /**
     * 
     * @type {Array<object>}
     * @memberof EntityResourcePage
     */
    'entities': Array<object>;
    /**
     * 
     * @type {{ [key: string]: EntityCaseSummary; }}
     * @memberof EntityResourcePage
     */
    'cases'?: { [key: string]: EntityCaseSummary; };
    /**
     * 
     * @type {string}
     * @memberof EntityResourcePage
     */
    'next_page_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityResourcePage
     */
    'previous_page_token'?: string;
}
/**
 * 
 * @export
 * @interface EntityUploadDatasetJob
 */
export interface EntityUploadDatasetJob {
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof EntityUploadDatasetJob
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'status': EntityUploadDatasetJobStatusEnum;
    /**
     * 
     * @type {DatasetJobGenericError}
     * @memberof EntityUploadDatasetJob
     */
    'error'?: DatasetJobGenericError;
    /**
     * 
     * @type {boolean}
     * @memberof EntityUploadDatasetJob
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJob
     */
    'type': EntityUploadDatasetJobTypeEnum;
    /**
     * 
     * @type {EntityUploadDatasetJobRequest}
     * @memberof EntityUploadDatasetJob
     */
    'request': EntityUploadDatasetJobRequest;
    /**
     * 
     * @type {EntityUploadDatasetJobResponse}
     * @memberof EntityUploadDatasetJob
     */
    'response'?: EntityUploadDatasetJobResponse;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityUploadDatasetJobStatusEnum {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityUploadDatasetJobTypeEnum {
    ENTITY_UPLOAD = 'entity_upload'
}

/**
 * 
 * @export
 * @interface EntityUploadDatasetJobRequest
 */
export interface EntityUploadDatasetJobRequest {
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJobRequest
     */
    'environment': EntityUploadDatasetJobRequestEnvironmentEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJobRequest
     */
    'entity_schema': EntityUploadDatasetJobRequestEntitySchemaEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJobRequest
     */
    'entity_id': string;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityUploadDatasetJobRequestEnvironmentEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityUploadDatasetJobRequestEntitySchemaEnum {
    ENTITY = 'Entity'
}

/**
 * 
 * @export
 * @interface EntityUploadDatasetJobRequestBody
 */
export interface EntityUploadDatasetJobRequestBody {
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJobRequestBody
     */
    'file_name': string;
}
/**
 * 
 * @export
 * @interface EntityUploadDatasetJobResponse
 */
export interface EntityUploadDatasetJobResponse {
    /**
     * 
     * @type {string}
     * @memberof EntityUploadDatasetJobResponse
     */
    's3_presigned_url': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityUploadDatasetJobResponse
     */
    's3_presigned_fields': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface RelatedEntity
 */
export interface RelatedEntity {
    /**
     * 
     * @type {Relation}
     * @memberof RelatedEntity
     */
    'property': Relation;
    /**
     * 
     * @type {object}
     * @memberof RelatedEntity
     */
    'entity': object;
}
/**
 * 
 * @export
 * @interface RelatedListPage
 */
export interface RelatedListPage {
    /**
     * 
     * @type {Array<RelatedEntity>}
     * @memberof RelatedListPage
     */
    'relations': Array<RelatedEntity>;
    /**
     * 
     * @type {string}
     * @memberof RelatedListPage
     */
    'next_page_token'?: string;
}
/**
 * 
 * @export
 * @interface Relation
 */
export interface Relation {
    /**
     * 
     * @type {string}
     * @memberof Relation
     */
    'env': string;
    /**
     * 
     * @type {string}
     * @memberof Relation
     */
    'entity_schema': string;
    /**
     * 
     * @type {string}
     * @memberof Relation
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof Relation
     */
    'property': string;
    /**
     * 
     * @type {number}
     * @memberof Relation
     */
    'index'?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet: async (schema: string, entityId: string, env: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet', 'entityId', entityId)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet', 'env', env)
            const localVarPath = `/entities/api/v1/entities/{env}/{schema}/{entity_id}/related`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet: async (schema: string, entityId: string, env?: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet', 'entityId', entityId)
            const localVarPath = `/entities/api/v1/entities/{schema}/{entity_id}/related`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet: async (schema: string, entityId: string, env: GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet', 'entityId', entityId)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet', 'env', env)
            const localVarPath = `/entities/api/v1/entity/{env}/{schema}/{entity_id}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet: async (schema: string, entityId: string, env?: GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet', 'entityId', entityId)
            const localVarPath = `/entities/api/v1/entity/{schema}/{entity_id}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum} env 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet: async (schema: string, entityId: string, env: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum, limit?: number, pageToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet', 'entityId', entityId)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet', 'env', env)
            const localVarPath = `/entities/api/v1/entities/{env}/{schema}/{entity_id}/related_entities`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum} [env] 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet: async (schema: string, entityId: string, env?: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum, limit?: number, pageToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet', 'entityId', entityId)
            const localVarPath = `/entities/api/v1/entities/{schema}/{entity_id}/related_entities`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Schema Json Schema
         * @param {GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet: async (schema: GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet', 'schema', schema)
            const localVarPath = `/entities/api/v1/schemas/{schema}/json_schema`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {string} schema 
         * @param {ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum} env 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {Array<string>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet: async (schema: string, env: ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum, limit?: number, pageToken?: string, filter?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet', 'schema', schema)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet', 'env', env)
            const localVarPath = `/entities/api/v1/entities/{env}/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {string} schema 
         * @param {number} [limit] 
         * @param {ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum} [env] 
         * @param {string} [pageToken] 
         * @param {Array<string>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet: async (schema: string, limit?: number, env?: ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum, pageToken?: string, filter?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet', 'schema', schema)
            const localVarPath = `/entities/api/v1/entities/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Schemas
         * @param {ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum} schema 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchemasEntitiesApiV1SchemasSchemaGet: async (schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum, limit?: number, pageToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('listSchemasEntitiesApiV1SchemasSchemaGet', 'schema', schema)
            const localVarPath = `/entities/api/v1/schemas/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum} env 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut: async (schema: string, entityId: string, env: PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut', 'entityId', entityId)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut', 'env', env)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut', 'body', body)
            const localVarPath = `/entities/api/v1/entity/{env}/{schema}/{entity_id}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {object} body 
         * @param {PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut: async (schema: string, entityId: string, body: object, env?: PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut', 'entityId', entityId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut', 'body', body)
            const localVarPath = `/entities/api/v1/entity/{schema}/{entity_id}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Entity Upload Job
         * @param {string} entityId 
         * @param {PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum} env 
         * @param {EntityUploadDatasetJobRequestBody} entityUploadDatasetJobRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut: async (entityId: string, env: PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum, entityUploadDatasetJobRequestBody: EntityUploadDatasetJobRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut', 'entityId', entityId)
            // verify required parameter 'env' is not null or undefined
            assertParamExists('putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut', 'env', env)
            // verify required parameter 'entityUploadDatasetJobRequestBody' is not null or undefined
            assertParamExists('putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut', 'entityUploadDatasetJobRequestBody', entityUploadDatasetJobRequestBody)
            const localVarPath = `/entities/api/v1/entity/{env}/Entity/{entity_id}/file_upload`
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityUploadDatasetJobRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Schemas
         * @param {PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum} schema 
         * @param {string} entityId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSchemasEntitiesApiV1SchemaSchemaEntityIdPut: async (schema: PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum, entityId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('putSchemasEntitiesApiV1SchemaSchemaEntityIdPut', 'schema', schema)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('putSchemasEntitiesApiV1SchemaSchemaEntityIdPut', 'entityId', entityId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putSchemasEntitiesApiV1SchemaSchemaEntityIdPut', 'body', body)
            const localVarPath = `/entities/api/v1/schema/{schema}/{entity_id}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(schema: string, entityId: string, env: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(schema, entityId, env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(schema: string, entityId: string, env?: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(schema, entityId, env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(schema: string, entityId: string, env: GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(schema, entityId, env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(schema: string, entityId: string, env?: GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(schema, entityId, env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum} env 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(schema: string, entityId: string, env: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum, limit?: number, pageToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelatedListPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(schema, entityId, env, limit, pageToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum} [env] 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(schema: string, entityId: string, env?: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum, limit?: number, pageToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelatedListPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(schema, entityId, env, limit, pageToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Schema Json Schema
         * @param {GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(schema: GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(schema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {string} schema 
         * @param {ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum} env 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {Array<string>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(schema: string, env: ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum, limit?: number, pageToken?: string, filter?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityResourcePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(schema, env, limit, pageToken, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {string} schema 
         * @param {number} [limit] 
         * @param {ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum} [env] 
         * @param {string} [pageToken] 
         * @param {Array<string>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(schema: string, limit?: number, env?: ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum, pageToken?: string, filter?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityResourcePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(schema, limit, env, pageToken, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Schemas
         * @param {ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum} schema 
         * @param {number} [limit] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSchemasEntitiesApiV1SchemasSchemaGet(schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum, limit?: number, pageToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityResourcePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSchemasEntitiesApiV1SchemasSchemaGet(schema, limit, pageToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listSchemasEntitiesApiV1SchemasSchemaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum} env 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(schema: string, entityId: string, env: PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(schema, entityId, env, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {string} schema 
         * @param {string} entityId 
         * @param {object} body 
         * @param {PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(schema: string, entityId: string, body: object, env?: PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(schema, entityId, body, env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put Entity Upload Job
         * @param {string} entityId 
         * @param {PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum} env 
         * @param {EntityUploadDatasetJobRequestBody} entityUploadDatasetJobRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(entityId: string, env: PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum, entityUploadDatasetJobRequestBody: EntityUploadDatasetJobRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityUploadDatasetJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(entityId, env, entityUploadDatasetJobRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put Schemas
         * @param {PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum} schema 
         * @param {string} entityId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(schema: PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum, entityId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(schema, entityId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(requestParameters: DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityMap> {
            return localVarFp.getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Enriched Entity By Schema
         * @param {DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(requestParameters: DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityMap> {
            return localVarFp.getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(requestParameters: DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity By Schema
         * @param {DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(requestParameters: DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(requestParameters: DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RelatedListPage> {
            return localVarFp.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Related Entities By Schema
         * @param {DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(requestParameters: DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RelatedListPage> {
            return localVarFp.getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Schema Json Schema
         * @param {DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(requestParameters: DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(requestParameters.schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(requestParameters: DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityResourcePage> {
            return localVarFp.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(requestParameters.schema, requestParameters.env, requestParameters.limit, requestParameters.pageToken, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Entities By Schema
         * @param {DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(requestParameters: DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityResourcePage> {
            return localVarFp.listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(requestParameters.schema, requestParameters.limit, requestParameters.env, requestParameters.pageToken, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Schemas
         * @param {DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchemasEntitiesApiV1SchemasSchemaGet(requestParameters: DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityResourcePage> {
            return localVarFp.listSchemasEntitiesApiV1SchemasSchemaGet(requestParameters.schema, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(requestParameters: DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Entity By Schema
         * @param {DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(requestParameters: DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.body, requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Entity Upload Job
         * @param {DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(requestParameters: DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityUploadDatasetJob> {
            return localVarFp.putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(requestParameters.entityId, requestParameters.env, requestParameters.entityUploadDatasetJobRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Schemas
         * @param {DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(requestParameters: DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest
 */
export interface DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox' | 'schemas'}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet
     */
    readonly env: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum
}

/**
 * Request parameters for getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest
 */
export interface DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox' | 'schemas'}
     * @memberof DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet
     */
    readonly env?: GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum
}

/**
 * Request parameters for getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest
 */
export interface DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet
     */
    readonly env: GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum
}

/**
 * Request parameters for getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest
 */
export interface DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet
     */
    readonly env?: GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum
}

/**
 * Request parameters for getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest
 */
export interface DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet
     */
    readonly env: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet
     */
    readonly pageToken?: string
}

/**
 * Request parameters for getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest
 */
export interface DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet
     */
    readonly env?: GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum

    /**
     * 
     * @type {number}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet
     */
    readonly pageToken?: string
}

/**
 * Request parameters for getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest
 */
export interface DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest {
    /**
     * 
     * @type {'Entity'}
     * @memberof DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet
     */
    readonly schema: GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum
}

/**
 * Request parameters for listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest
 */
export interface DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet
     */
    readonly schema: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet
     */
    readonly env: ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum

    /**
     * 
     * @type {number}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet
     */
    readonly filter?: Array<string>
}

/**
 * Request parameters for listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest
 */
export interface DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet
     */
    readonly schema: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet
     */
    readonly limit?: number

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet
     */
    readonly env?: ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet
     */
    readonly filter?: Array<string>
}

/**
 * Request parameters for listSchemasEntitiesApiV1SchemasSchemaGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest
 */
export interface DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest {
    /**
     * 
     * @type {'Entity'}
     * @memberof DefaultApiListSchemasEntitiesApiV1SchemasSchemaGet
     */
    readonly schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum

    /**
     * 
     * @type {number}
     * @memberof DefaultApiListSchemasEntitiesApiV1SchemasSchemaGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DefaultApiListSchemasEntitiesApiV1SchemasSchemaGet
     */
    readonly pageToken?: string
}

/**
 * Request parameters for putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest
 */
export interface DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut
     */
    readonly env: PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum

    /**
     * 
     * @type {object}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut
     */
    readonly body: object
}

/**
 * Request parameters for putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest
 */
export interface DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut
     */
    readonly schema: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut
     */
    readonly entityId: string

    /**
     * 
     * @type {object}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut
     */
    readonly body: object

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut
     */
    readonly env?: PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum
}

/**
 * Request parameters for putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest
 */
export interface DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut
     */
    readonly entityId: string

    /**
     * 
     * @type {'live' | 'sandbox'}
     * @memberof DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut
     */
    readonly env: PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum

    /**
     * 
     * @type {EntityUploadDatasetJobRequestBody}
     * @memberof DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut
     */
    readonly entityUploadDatasetJobRequestBody: EntityUploadDatasetJobRequestBody
}

/**
 * Request parameters for putSchemasEntitiesApiV1SchemaSchemaEntityIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest
 */
export interface DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest {
    /**
     * 
     * @type {'Entity'}
     * @memberof DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPut
     */
    readonly schema: PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPut
     */
    readonly entityId: string

    /**
     * 
     * @type {object}
     * @memberof DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPut
     */
    readonly body: object
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get Enriched Entity By Schema
     * @param {DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(requestParameters: DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Enriched Entity By Schema
     * @param {DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(requestParameters: DefaultApiGetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity By Schema
     * @param {DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(requestParameters: DefaultApiGetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity By Schema
     * @param {DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(requestParameters: DefaultApiGetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Related Entities By Schema
     * @param {DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(requestParameters: DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Related Entities By Schema
     * @param {DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(requestParameters: DefaultApiGetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGet(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Schema Json Schema
     * @param {DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(requestParameters: DefaultApiGetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(requestParameters.schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Entities By Schema
     * @param {DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(requestParameters: DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(requestParameters.schema, requestParameters.env, requestParameters.limit, requestParameters.pageToken, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Entities By Schema
     * @param {DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(requestParameters: DefaultApiListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listEntitiesBySchemaEntitiesApiV1EntitiesSchemaGet(requestParameters.schema, requestParameters.limit, requestParameters.env, requestParameters.pageToken, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Schemas
     * @param {DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSchemasEntitiesApiV1SchemasSchemaGet(requestParameters: DefaultApiListSchemasEntitiesApiV1SchemasSchemaGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSchemasEntitiesApiV1SchemasSchemaGet(requestParameters.schema, requestParameters.limit, requestParameters.pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Entity By Schema
     * @param {DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(requestParameters: DefaultApiPutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.env, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Entity By Schema
     * @param {DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(requestParameters: DefaultApiPutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.body, requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Entity Upload Job
     * @param {DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(requestParameters: DefaultApiPutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPut(requestParameters.entityId, requestParameters.env, requestParameters.entityUploadDatasetJobRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Schemas
     * @param {DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(requestParameters: DefaultApiPutSchemasEntitiesApiV1SchemaSchemaEntityIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putSchemasEntitiesApiV1SchemaSchemaEntityIdPut(requestParameters.schema, requestParameters.entityId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox',
    SCHEMAS = 'schemas'
}
/**
  * @export
  * @enum {string}
  */
export enum GetEnrichedEntityBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox',
    SCHEMAS = 'schemas'
}
/**
  * @export
  * @enum {string}
  */
export enum GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum GetEntityBySchemaEntitiesApiV1EntitySchemaEntityIdGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedEntitiesGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum GetRelatedEntitiesBySchemaEntitiesApiV1EntitiesSchemaEntityIdRelatedEntitiesGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum {
    ENTITY = 'Entity'
}
/**
  * @export
  * @enum {string}
  */
export enum ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum ListEntitiesBySchemaEntitiesApiV1EntitiesSchemaGetEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum {
    ENTITY = 'Entity'
}
/**
  * @export
  * @enum {string}
  */
export enum PutEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdPutEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum PutEntityBySchemaEntitiesApiV1EntitySchemaEntityIdPutEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum PutEntityUploadJobEntitiesApiV1EntityEnvEntityEntityIdFileUploadPutEnvEnum {
    LIVE = 'live',
    SANDBOX = 'sandbox'
}
/**
  * @export
  * @enum {string}
  */
export enum PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum {
    ENTITY = 'Entity'
}


