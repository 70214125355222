import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntityViewParams } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export enum EntityTabIds {
  Decisions = "decisions",
  Events = "events",
  Features = "features",
  RelatedEntities = "related-entities",
}

export const decisionsQueryKeys = [
  ["flows"],
  ["decision", "v3"],
  ["decision-overview"],
];

export const useEntityTabRefresh = (activeTab: EntityTabIds) => {
  const [isManualRefresh, setIsManualRefresh] = useState(false); // state to differentiate between manual refresh triggered by user and background refreshes/initial mount fetch
  const queryClient = useQueryClient();
  const { id: entityId, schema } = useParamsDecode<EntityViewParams>();
  const [environment] = useEnvironment();
  const { workspace } = useWorkspaceContext();

  const queryKeysMappings: Record<EntityTabIds, string[][]> = {
    events: [["events", environment, entityId]],
    decisions: decisionsQueryKeys,
    features: [["features", workspace.id, environment, entityId, schema]],
    "related-entities": [
      ["relatedEntities", schema, environment, workspace.base_url ?? ""],
    ],
  };

  const queryKey = queryKeysMappings[activeTab];

  const isFetching = useIsFetching({
    queryKey: queryKey[0],
    exact: false,
  });

  useEffect(() => {
    if (!isFetching && isManualRefresh) {
      setIsManualRefresh(false);
    }
  }, [isFetching, isManualRefresh]);

  const refresh = () => {
    setIsManualRefresh(true);
    queryKey.forEach((key) => {
      queryClient.invalidateQueries({
        queryKey: key,
      });
    });
  };

  return {
    isRefreshing: isManualRefresh,
    refresh,
  };
};
