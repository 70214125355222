import { useMutation, useQuery } from "@tanstack/react-query";

import {
  featureQueriesApi,
  featuresApi,
  workspacesApi,
  eventsApi,
  entitiesApi,
  awsAccountsApi,
} from "src/api/endpoints";
import { userOrganizationsQueryKey } from "src/api/queries";
import { rolesApi, usersApi, organizationsApi } from "src/api/taktileApi";
import { ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum } from "src/clients/entities";
import { AWSAccount, WorkspaceDataplane } from "src/clients/flow-api";
import {
  User,
  OrganizationInDB,
  RoleCreate,
  OrganizationCreate,
} from "src/clients/taktile-api";
import { queryClient } from "src/queryClient";

export const usePatchWorkspace = () => {
  return useMutation({
    mutationKey: ["patchWorkspace"],
    mutationFn: (workspace: WorkspaceDataplane) => {
      return workspacesApi.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(
        workspace.id,
        {
          ...(workspace.config && { config: workspace.config }),
          ...(workspace.feature_gates && {
            feature_gates: workspace.feature_gates,
          }),
        },
      );
    },
  });
};

export const useCreateOrganization = () => {
  return useMutation({
    mutationKey: ["createOrganization"],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: userOrganizationsQueryKey });
    },
    mutationFn: ({
      name,
      principal,
    }: Pick<OrganizationCreate, "name" | "principal">) => {
      return organizationsApi.addOrganizationApiV1OrganizationsPost({
        name,
        principal,
        allows_signup: false,
        allows_decide_access: true,
        whitelabel: false,
      });
    },
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationKey: ["updateUsers"],
    mutationFn: (user: User) => {
      return usersApi.updateUserApiV1UsersIdUserIdPut(user.id, user);
    },
  });
};

export const useCreateRole = () => {
  return useMutation({
    mutationKey: ["createRole"],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allUsers"] });
    },
    mutationFn: (roleCreate: RoleCreate) =>
      rolesApi.createRoleApiV1RolesPost(roleCreate),
  });
};

export const useDeleteRole = () => {
  return useMutation({
    mutationKey: ["deleteRole"],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allUsers"] });
    },
    mutationFn: (roleId: string) =>
      rolesApi.deleteRoleApiV1RolesRoleIdDelete(roleId),
  });
};

export const useAllUsers = () => {
  return useQuery<User[], Error>({
    queryKey: ["allUsers"],
    queryFn: async () => {
      let skip = 0;
      const limit = 100;
      let result: User[] = [];

      while (true) {
        const response = await usersApi.getUsersApiV1UsersGet(skip, limit);
        result = result.concat(response.data);
        if (response.data.length < limit) {
          break;
        }
        skip += limit;
      }

      return result;
    },
  });
};

export const useAllOrgs = () => {
  return useQuery<OrganizationInDB[], Error>({
    queryKey: ["allOrgs"],
    queryFn: async () => {
      let skip = 0;
      const limit = 30;
      let result: OrganizationInDB[] = [];

      while (true) {
        const response =
          await organizationsApi.getOrganizationsApiV1OrganizationsAllGet(
            skip,
            limit,
          );
        result = result.concat(response.data);
        if (response.data.length < limit) {
          break;
        }
        skip += limit;
      }
      return result;
    },
  });
};

export const useWorkspaceEventTypes = (
  workspace: WorkspaceDataplane | null,
) => {
  return useQuery({
    queryKey: ["workspaceEventTypes", workspace?.id],
    queryFn: async () => {
      const api = eventsApi(`${workspace?.base_url}`);
      const response = await api.getAllEventConfigsEventsGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};

export const useWorkspaceFeatures = (workspace: WorkspaceDataplane | null) => {
  return useQuery({
    queryKey: ["workspaceFeatures", workspace?.id],
    queryFn: async () => {
      const api = featuresApi(`${workspace?.base_url}`);
      const response = await api.getFeaturesFeaturesGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};

export const useWorkspaceFeatureQueries = (
  workspace: WorkspaceDataplane | null,
) => {
  return useQuery({
    queryKey: ["workspaceFeatureQueries", workspace?.id],
    queryFn: async () => {
      const api = featureQueriesApi(`${workspace?.base_url}`);
      const response = await api.getFeatureQueriesFeatureQueriesGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};

export const useWorkspaceEntitySchemas = (
  workspace: WorkspaceDataplane | null,
) => {
  return useQuery({
    queryKey: ["workspaceEntitySchemas", workspace?.id],
    queryFn: async () => {
      const api = entitiesApi(`${workspace?.base_url}`);
      const response = await api.listSchemasEntitiesApiV1SchemasSchemaGet({
        schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum.ENTITY,
        limit: 100,
      });
      return response.data.entities as Record<string, any>[];
    },
    enabled: !!workspace,
  });
};

export const useAllAWSAccounts = (includeDisabled: boolean = false) => {
  return useQuery<AWSAccount[], Error>({
    queryKey: ["awsAccounts"],
    queryFn: async () => {
      const response = await awsAccountsApi.getAwsAccountsApiV1AwsAccountsGet(
        0,
        100,
        includeDisabled,
      );
      return response.data;
    },
  });
};

export const useUpdateAWSAccount = () => {
  return useMutation({
    mutationKey: ["awsAccounts"],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["awsAccounts"] });
    },
    mutationFn: (account: AWSAccount) => {
      return awsAccountsApi.updateAwsAccountApiV1AwsAccountsAccountIdPatch(
        account.id,
        account,
      );
    },
  });
};

export const useCreateAWSAccount = () => {
  return useMutation({
    mutationKey: ["awsAccounts"],
    mutationFn: (account: AWSAccount) => {
      return awsAccountsApi.createAwsAccountApiV1AwsAccountsPost(account);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["awsAccounts"] });
    },
  });
};
