import { useCallback } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";

import { WorkspaceDataplane } from "src/clients/flow-api";
import { toastActions } from "src/design-system/Toast/utils";

// In case a workspace is still running on workspaces services < 03.01.2024
const FALLBACK_MAX_FILE_SIZE = 5242880; // 5 MB in Bytes

type PropsT = {
  onFileSelect: (file: File) => void;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  workspace: WorkspaceDataplane;
};

const onnxFileValidator = (file: File) => {
  if ("name" in file) {
    const extension = file.name.split(".").pop();
    if (extension !== "onnx") {
      return {
        code: ErrorCode.FileInvalidType,
        message: "File is not an .onnx file",
      };
    }
  }
  return null;
};

const getErrorMessage = (
  errorCode: ErrorCode | string,
  maxFileSizeBytes: number,
) => {
  switch (errorCode) {
    case ErrorCode.FileInvalidType:
      return "File is not an .onnx file";
    case ErrorCode.FileTooLarge: {
      const megabytesRaw = maxFileSizeBytes / 1024 / 1024;
      const megabytesRounded = Math.floor(megabytesRaw * 10) / 10;
      return `File is larger than ${megabytesRounded}MB`;
    }
    case ErrorCode.TooManyFiles:
      return "Please only upload a single file";
    case ErrorCode.FileTooSmall:
      return "File is too small";
    default:
      return "Error uploading File";
  }
};

export const ModelDropzoneController: React.FC<PropsT> = ({
  onFileSelect,
  children,
  className,
  disabled = false,
  workspace,
}) => {
  const maxFileSizeBytes =
    workspace.config?.ml_model_max_size_bytes ?? FALLBACK_MAX_FILE_SIZE;
  const handleOnDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length) {
        // We allow to upload only 1 file
        onFileSelect(acceptedFiles[0]);
      }
      rejectedFiles.forEach((rejection) => {
        const errorMessage = getErrorMessage(
          rejection.errors[0].code,
          maxFileSizeBytes,
        );
        toastActions.failure({ title: errorMessage });
      });
    },
    [maxFileSizeBytes, onFileSelect],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    useFsAccessApi: false,
    maxFiles: 1,
    maxSize: maxFileSizeBytes,
    validator: onnxFileValidator,
    disabled,
  });

  return (
    <div {...getRootProps({ className, "data-loc": "model-upload-dropzone" })}>
      {children}
      <input {...getInputProps()} data-loc="model-upload" />
    </div>
  );
};
