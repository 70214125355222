import { capitalize } from "lodash";

import { Pill } from "src/base-components/Pill";
import { FeatureQueryStatus } from "src/clients/features-control";

export const QueryStatusPill: React.FC<{
  status: FeatureQueryStatus;
}> = ({ status }) => {
  return (
    <Pill
      size="sm"
      variant={status === FeatureQueryStatus.ACTIVE ? "green" : "gray"}
    >
      {capitalize(status)}
    </Pill>
  );
};
